import React, { useState, useEffect, useCallback } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { AlertCircle, Bell, Home, UserPlus, Info } from "react-feather";
import useSidebar from "../../hooks/useSidebar";
import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarUser from "./NavbarUser";
import Pusher from "pusher-js";
import * as usersApi from "@api/usersApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
// import Echo from "laravel-echo";

const NavbarComponent = ({ user }) => {
    window.Pusher = Pusher;

    const [notifications, setNotifications] = useState([]);

    const { isOpen, setIsOpen } = useSidebar();

    // let _baseURL = "";
    // let _webSocket = "";

    // var base_url = window.location.origin;

    // if (base_url.includes("localhost")) {
    //     _baseURL = "http://localhost:8000";
    //     _webSocket = process.env.REACT_APP_DEV_WEBSOCKET_KEY;
    // } else if (base_url.includes("staging")) {
    //     _baseURL = "https://moe-ecms-api-staging.intellisolution.tech";
    //     _webSocket = process.env.REACT_APP_STG_WEBSOCKET_KEY;
    // } else {
    //     _baseURL = "https://moe-ecms-api.intellisolution.tech";
    //     _webSocket = process.env.REACT_APP_PRO_WEBSOCKET_KEY;
    // }

    if (user !== null) {
        // window.Echo = new Echo({
        //     authEndpoint: `${_baseURL}/broadcasting/auth`,
        //     broadcaster: "pusher",
        //     key: _webSocket,
        //     cluster: "ap1",
        //     // encrypted: true,
        //     forceTLS: true,
        //     auth: {
        //         headers: {
        //             Authorization: `Bearer ${accessToken}`,
        //         },
        //     },
        // });
        // echo.private(`user.${user.id}`).listen(
        //     ".private-inspection-event",
        //     (e) => {
        //         getUserNotifications();
        //     }
        // );
        // echo.private("App.Models.User." + user.id).notification(
        //     (notification) => {
        //         getUserNotifications();
        //     }
        // );
        // window.Echo.connector.pusher.connection.bind("connected", () => {
        //     // window.Echo.channel(`testChannel`).listen(".testChannel", (e) => {
        //     //     getUserNotifications();
        //     //     //unsubscribe channel
        //     //     window.Echo.leave(`testChannel`);
        //     // });
        //     window.Echo.private(`user.${user.id}`).listen(
        //         ".testChannel",
        //         (e) => {
        //             getUserNotifications();
        //         }
        //     );
        // });
    }

    const getUserNotifications = useCallback(async () => {
        try {
            const response = await usersApi.userUnreadNotifications();

            setNotifications(response.data.data);
        } catch (error) {
            // notyf.open({
            //     type: "danger",
            //     message: error,
            // });
        }
    }, []);

    // useEffect(() => {
    //     getUserNotifications();
    // }, [getUserNotifications]);

    return (
        <>
            <Navbar variant="light" expand className="navbar-bg">
                <span
                    className="sidebar-toggle d-flex"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <FontAwesomeIcon icon={faBars} size="2x" />
                </span>

                <Navbar.Collapse>
                    <Nav className="navbar-align">
                        <NavbarDropdown
                            header="New notifications"
                            footer="Show all notifications"
                            showBadge={notifications.length > 0 ? true : false}
                            icon={Bell}
                            count={
                                notifications.length > 0 && notifications.length
                            }
                        >
                            {notifications.length > 0 &&
                                notifications?.map((item, key) => {
                                    let icon = (
                                        <Info size={18} className="text-info" />
                                    );

                                    if (item.data.type === "important") {
                                        icon = (
                                            <AlertCircle
                                                size={18}
                                                className="text-danger"
                                            />
                                        );
                                    }

                                    if (item.data.type === "login") {
                                        icon = (
                                            <Home
                                                size={18}
                                                className="text-primary"
                                            />
                                        );
                                    }

                                    if (item.data.type === "request") {
                                        icon = (
                                            <UserPlus
                                                size={18}
                                                className="text-success"
                                            />
                                        );
                                    }

                                    return (
                                        <NavbarDropdownItem
                                            key={key}
                                            icon={icon}
                                            title={item.data.title}
                                            description={item.data.description}
                                            time={item.data.time}
                                            url={item.data.url + item.data.id}
                                            id={item.id}
                                            api={() => getUserNotifications()}
                                        />
                                    );
                                })}
                        </NavbarDropdown>

                        <NavbarUser />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};

export default NavbarComponent;

import React, { useCallback, useContext, useState, useEffect } from "react";

import Select from "react-select";
import download from "downloadjs";
import {
    Button,
    Card,
    Col,
    Container,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faSearch } from "@fortawesome/free-solid-svg-icons";
import * as dashboardApi from "@api/dashboardApi";

import NotyfContext from "contexts/NotyfContext";
import DatePicker from "react-datepicker";
import { LoadingContext } from "App";
import { UnderConstruction } from "components/UnderConstruction";
import { ApvTabs } from "./Components/ApvTabs";
import { ApvTable } from "./Components/ApvTable";
import * as schoolApi from "@api/schoolApi";

export const DashboardAPV = () => {
    const notyf = useContext(NotyfContext);
    const { tab } = useParams();
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;

    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
    });

    //
    // States
    //

    const [searchParams, setSearchParams] = useState({});
    const [apv, setApv] = useState({});
    const [schools, setSchools] = useState([]);

    //
    // Functions
    //

    const getApv = useCallback(
        async (param = null) => {
            try {
                if (!schools.length) return;

                setIsLoadingActive(true);

                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);

                let startDateParam = urlParams.get("start_date");
                let endDateParam = urlParams.get("end_date");
                let schoolParam = urlParams.get("school");

                if (!param) {
                    param = {
                        start_date: startDateParam || param?.start_date,
                        end_date: endDateParam || param?.end_date,
                        school_id: schoolParam || param?.school_id,
                    };
                }

                const response = await dashboardApi.getApv(param);
                const data = response.data.data;

                setApv(data);

                if (!param?.isNewFilter) {
                    const school = schools?.find(
                        (o) => o?.value === (schoolParam || "")
                    );

                    reset({
                        start_date: startDateParam
                            ? new Date(startDateParam)
                            : new Date(data.start_date),
                        end_date: endDateParam
                            ? new Date(endDateParam)
                            : new Date(data.end_date),
                        school: school,
                    });

                    data.school = school;
                    const searchValue = setSearchValue(data);
                    setSearchParams(searchValue);
                }

                setIsLoadingActive(false);
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, schools]
    );

    const exportApv = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);

                const searchValue = setSearchValue(param);
                const response = await dashboardApi.exportApv(searchValue);

                if (response.size) {
                    download(response, "APV", "application/xlsx");
                }

                setIsLoadingActive(false);
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const search = (data) => {
        const searchValue = setSearchValue(data);
        searchValue.isNewFilter = true;

        setSearchParams(searchValue);
        getApv(searchValue);
    };

    const setSearchValue = (data) => {
        return {
            school_id: data.school ? data.school.value : "",
            start_date: data?.start_date
                ? new Date(data?.start_date).toLocaleDateString("en-CA")
                : "",
            end_date: data?.end_date
                ? new Date(data?.end_date).toLocaleDateString("en-CA")
                : "",
        };
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getApv();
    }, [schools]);

    useEffect(() => {
        getSchools();
    }, []);

    return (
        <React.Fragment>
            <Helmet title="Dashboard - APV" />
            <Container fluid className="p-0">
                <h4 className="h4">Dashboard - APV</h4>
                <Card>
                    <Card.Body>
                        {apv ? (
                            <>
                                <Row className="mb-4">
                                    <Col md={12} sm={10} xs={10}>
                                        <Row>
                                            <Col md={3} sm={4} xs={4}>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="school"
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <Select
                                                            inputRef={ref}
                                                            classNamePrefix="react-select"
                                                            options={schools}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            value={value}
                                                            onChange={onChange}
                                                            placeholder="Select school"
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col md={3} sm={3} xs={3}>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="start_date"
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <DatePicker
                                                            dateFormat="yyyy-MM-dd"
                                                            onChange={onChange}
                                                            className={`form-box form-control ${
                                                                isMobile &&
                                                                "w-100"
                                                            }`}
                                                            placeholderText="Start date"
                                                            selected={value}
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col md={3} sm={3} xs={3}>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="end_date"
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <DatePicker
                                                            dateFormat="yyyy-MM-dd"
                                                            onChange={onChange}
                                                            className={`form-box form-control ${
                                                                isMobile &&
                                                                "w-100"
                                                            }`}
                                                            placeholderText="End date"
                                                            selected={value}
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                            </Col>

                                            <Col md={2} sm={2} xs={2}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip
                                                            id={`tooltip-top`}
                                                        >
                                                            Search APV
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button
                                                        variant="primary"
                                                        className="mb-1 me-2 form-box"
                                                        onClick={handleSubmit(
                                                            search
                                                        )}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faSearch}
                                                        />
                                                    </Button>
                                                </OverlayTrigger>

                                                {apv?.schools?.SCHOOL ||
                                                apv?.schools?.MOE ? (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip
                                                                id={`tooltip-top`}
                                                            >
                                                                Export APV
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button
                                                            variant="primary"
                                                            className="mb-1 form-box"
                                                            onClick={handleSubmit(
                                                                exportApv
                                                            )}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFileExcel
                                                                }
                                                            />
                                                        </Button>
                                                    </OverlayTrigger>
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <ApvTabs
                                    module="apv"
                                    start_date={searchParams?.start_date || ""}
                                    end_date={searchParams?.end_date || ""}
                                    school={searchParams?.school_id || ""}
                                />

                                <ApvTable
                                    defects={apv?.schools || {}}
                                    type={tab.toLocaleLowerCase()}
                                />
                            </>
                        ) : (
                            <UnderConstruction />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

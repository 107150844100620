import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotyfContext from "contexts/NotyfContext";
import {
    faCheckCircle,
    faCheckDouble,
    faHourglass,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";

import { Tabs } from "./Components/Tabs";
import { LoadingContext } from "App";
import { UnderConstruction } from "components/UnderConstruction";
import { months, years } from "config";
import { toTitleCaseCheckAllText } from "utils/utilities";
import { ChecklistSummaryLegend } from "./Components/ChecklistSummaryLegend";
import * as dashboardApi from "@api/dashboardApi";

export const DashboardChecklistSummary = () => {
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const notyf = useContext(NotyfContext);
    const { tab } = useParams();
    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
    });

    //
    // States
    //

    const [checklistSummary, setChecklistSummary] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    //
    // Functions
    //

    const getDashboardChecklistSummary = async (param = null) => {
        try {
            setIsLoadingActive(true);
            const response = await dashboardApi.getChecklistSummary(param);

            if (response.status === 200) {
                setIsLoadingActive(false);
                setChecklistSummary(response.data.data);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const search = (data) => {
        const searchValue = {
            category: tab.toUpperCase(),
            month: data.mo
                ? months?.find((o) => o?.value === data.mo.value)?.value
                : "",
            year: data.yr
                ? years?.find((o) => o?.value === data.yr.value)?.value
                : "",
        };

        monthYearSetterForNavigateParams(data?.mo?.value, data?.yr?.value);

        getDashboardChecklistSummary(searchValue);
    };

    const lookUpType = (type) => {
        const lookup = {
            "building-maintenance": "Building maintenance",
            "safety-audit-inspection": "Safety audit inspection",
            "turfing-and-planting-maintenance":
                "Turfing and planting maintenance",
        };

        return lookup[type];
    };

    const monthYearResetForSearch = (month, year) => {
        const getMonth = months?.find(
            (o) => parseInt(o?.value) === parseInt(month)
        );
        const getYear = years?.find(
            (o) => parseInt(o?.value) === parseInt(year)
        );

        reset({
            mo: getMonth,
            yr: getYear,
        });
    };

    const monthYearSetterForNavigateParams = (month, year) => {
        const getLastDayOfMonth = new Date(year, month, 0).getDate();

        setStartDate(`${month}-01-${year}`);
        setEndDate(`${month}-${getLastDayOfMonth}-${year}`);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        const newDate = new Date();
        let month = newDate.getMonth() + 1;
        const year = newDate.getFullYear();

        // Reset to current month and year for search filter
        monthYearResetForSearch(month, year);

        // Set dates for search navigate to inpsection module
        monthYearSetterForNavigateParams(month, year);

        getDashboardChecklistSummary({
            year: year,
            month: month,
            category: tab.toUpperCase(),
        });
    }, []);

    //
    // Constants
    //

    const checklistNameHeaders = Object.values(checklistSummary)[0];

    return (
        <React.Fragment>
            <Helmet title="Dashboard - Checklist Summary" />
            <Container fluid className="p-0">
                <h4 className="h4">Dashboard - Checklist Summary</h4>
                <Card>
                    <Card.Body>
                        <Tabs module="checklist-summary" />
                        <hr />

                        {checklistSummary ? (
                            <>
                                <Row>
                                    <Col md={10}>
                                        <h5 className="mb-4">
                                            {lookUpType(tab)}
                                        </h5>
                                        <Row className="mb-4">
                                            <Col md={10} sm={10} xs={10}>
                                                <Row>
                                                    <Col md={3} sm={3} xs={3}>
                                                        <Controller
                                                            control={control}
                                                            defaultValue=""
                                                            name="mo"
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                },
                                                            }) => (
                                                                <Select
                                                                    className="is-invalid react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    options={
                                                                        months
                                                                    }
                                                                    isSearchable
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    menuPlacement="bottom"
                                                                    placeholder="Select month"
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col md={3} sm={3} xs={3}>
                                                        <Controller
                                                            control={control}
                                                            defaultValue=""
                                                            name="yr"
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                },
                                                            }) => (
                                                                <Select
                                                                    className="is-invalid react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    options={
                                                                        years
                                                                    }
                                                                    isSearchable
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    menuPlacement="bottom"
                                                                    placeholder="Select year"
                                                                />
                                                            )}
                                                        />
                                                    </Col>

                                                    <Col md={3} sm={2} xs={2}>
                                                        <Button
                                                            variant="primary"
                                                            className="me-1 mb-1 form-box"
                                                            onClick={handleSubmit(
                                                                search
                                                            )}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faSearch}
                                                            />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={2}>
                                        <ChecklistSummaryLegend />
                                    </Col>
                                </Row>

                                <Table
                                    bordered
                                    responsive
                                    className="table-layout-fixed"
                                >
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} width="250">
                                                Schools
                                            </th>

                                            {checklistNameHeaders &&
                                            Object.keys(checklistNameHeaders)
                                                .length ? (
                                                Object.values(
                                                    checklistNameHeaders
                                                ).map((value, key) => {
                                                    return (
                                                        <React.Fragment
                                                            key={key}
                                                        >
                                                            <th
                                                                colSpan={4}
                                                                width="400"
                                                            >
                                                                {
                                                                    Object.keys(
                                                                        checklistNameHeaders
                                                                    )[key]
                                                                }
                                                            </th>
                                                        </React.Fragment>
                                                    );
                                                })
                                            ) : (
                                                <></>
                                            )}
                                        </tr>
                                        <tr>
                                            {checklistNameHeaders &&
                                            Object.keys(checklistNameHeaders)
                                                .length ? (
                                                Object.values(
                                                    checklistNameHeaders
                                                ).map((value, key) => {
                                                    return (
                                                        <React.Fragment
                                                            key={key}
                                                        >
                                                            <th>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faHourglass
                                                                    }
                                                                    className="align-middle me-1"
                                                                    size="lg"
                                                                />
                                                            </th>
                                                            <th>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faCheckCircle
                                                                    }
                                                                    className="align-middle me-1"
                                                                    size="lg"
                                                                />
                                                            </th>
                                                            <th>
                                                                {" "}
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faCheckDouble
                                                                    }
                                                                    className="align-middle me-1"
                                                                    size="lg"
                                                                />
                                                            </th>
                                                            <th>Total</th>
                                                        </React.Fragment>
                                                    );
                                                })
                                            ) : (
                                                <></>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(checklistSummary)
                                            .length ? (
                                            Object.values(checklistSummary).map(
                                                (value, key) => {
                                                    return (
                                                        <React.Fragment
                                                            key={key}
                                                        >
                                                            <tr key={key}>
                                                                <td>
                                                                    {toTitleCaseCheckAllText(
                                                                        Object.keys(
                                                                            checklistSummary
                                                                        )[key]
                                                                    )}
                                                                </td>
                                                                {value &&
                                                                Object.keys(
                                                                    value
                                                                ).length ? (
                                                                    Object.values(
                                                                        value
                                                                    ).map(
                                                                        (
                                                                            childValue,
                                                                            childKey
                                                                        ) => {
                                                                            const schoolSlug =
                                                                                toTitleCaseCheckAllText(
                                                                                    Object.keys(
                                                                                        checklistSummary
                                                                                    )[
                                                                                        key
                                                                                    ]
                                                                                );
                                                                            const checklistName =
                                                                                Object.keys(
                                                                                    value
                                                                                )[
                                                                                    childKey
                                                                                ];
                                                                            return (
                                                                                <React.Fragment
                                                                                    key={
                                                                                        childKey
                                                                                    }
                                                                                >
                                                                                    <th width="300">
                                                                                        <a
                                                                                            href={`/inspection-checklist/inspection-servicing?status=pendingTC&school=${schoolSlug}&checklist_name=${checklistName}&start_date=${startDate}&end_date=${endDate}&category=${tab}`}
                                                                                            target="_blank"
                                                                                            rel="noreferrer"
                                                                                            className="btn-link pe-1 text-dark"
                                                                                        >
                                                                                            {childValue.DRAFT ||
                                                                                                0}
                                                                                        </a>
                                                                                    </th>
                                                                                    <th width="300">
                                                                                        <a
                                                                                            href={`/inspection-checklist/inspection-servicing?status=SUBMITTED&school=${schoolSlug}&checklist_name=${checklistName}&start_date=${startDate}&end_date=${endDate}&category=${tab}`}
                                                                                            target="_blank"
                                                                                            rel="noreferrer"
                                                                                            className="btn-link pe-1 text-dark"
                                                                                        >
                                                                                            {childValue.SUBMITTED ||
                                                                                                0}
                                                                                        </a>
                                                                                    </th>
                                                                                    <th width="300">
                                                                                        <a
                                                                                            href={`/inspection-checklist/inspection-servicing?status=APPROVED&school=${schoolSlug}&checklist_name=${checklistName}&start_date=${startDate}&end_date=${endDate}&category=${tab}`}
                                                                                            target="_blank"
                                                                                            rel="noreferrer"
                                                                                            className="btn-link pe-1 text-dark"
                                                                                        >
                                                                                            {childValue.APPROVED ||
                                                                                                0}
                                                                                        </a>
                                                                                    </th>
                                                                                    <th width="300">
                                                                                        {
                                                                                            childValue.TOTAL
                                                                                        }
                                                                                    </th>
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <tr>
                                                <td>No data</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <UnderConstruction />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

import React, { useContext, useEffect, useState } from "react";
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";
import NotyfContext from "contexts/NotyfContext";
import { useCallback } from "react";
import { DeleteModal } from "../../../components/DeleteModal";
import * as attachmentApi from "@api/attachmentApi";
import * as inspectionTreeChecklistApi from "@api/inspectionTreeChecklistApi";
import { LoadingContext } from "../../../App";
import { toYMD } from "../../../utils/dateHelper";
import useLocalStorage from "hooks/useLocalStorage";

export const TreeInspectionCreateEvidenceForm = () => {
    const { tree_inspection_id, tree_checklist_item_id } = useParams();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
    });
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [beforePhotos, setBeforePhotos] = useState([]);
    const [cameraBeforePhotos, setCameraBeforePhotos] = useState([]);
    const [afterPhotos, setAfterPhotos] = useState([]);
    const [cameraAfterPhotos, setCameraAfterPhotos] = useState([]);

    const [cameraSupportingImages, setCameraSupportingImages] = useState([]);
    const [supportingPhotos, setSupportingPhotos] = useState([]);

    const [supportingDocuments, setSupportingDocuments] = useState([]);
    const [beforeDocuments, setBeforeDocuments] = useState([]);
    const [afterDocuments, setAfterDocuments] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const notyf = useContext(NotyfContext);
    const [inspectionSchedule, setInspectionSchedule] = useState([]);
    const [inspectionFileName, setInspectionFileName] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [rerender, setRerender] = useState(false);
    // eslint-disable-next-line
    const [treeChecklistStorage, setTreeChecklistStorage] = useLocalStorage(
        "treeChecklist",
        [0]
    );
    const [sectionName, setSectionName] = useLocalStorage("");
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;

    const saveHandler = async (data) => {
        setDisabled(true);
        setIsLoadingActive(true);
        await getInspectionSchedule();
        let beforePhotosData = [];
        let afterPhotosData = [];
        let supportingPhotosData = [];

        if (beforePhotos?.files) {
            beforePhotosData = beforePhotos?.getFilesData()?.length
                ? beforePhotos?.getFilesData()
                : [];
        }
        if (afterPhotos?.files) {
            afterPhotosData = afterPhotos?.getFilesData()?.length
                ? afterPhotos?.getFilesData()
                : [];
        }
        if (supportingPhotos?.files) {
            supportingPhotosData = supportingPhotos?.getFilesData()?.length
                ? supportingPhotos?.getFilesData()
                : [];
        }
        const sectionDetails = inspectionSchedule?.tree_checklist_items?.find(
            (treeChecklistItem) => {
                return treeChecklistItem?.id === tree_checklist_item_id;
            }
        );

        sectionDetails.defect = data?.defect;
        sectionDetails.remarks = data?.remarks;
        setInspectionSchedule({ ...inspectionSchedule, sectionDetails });

        const formData = new FormData();
        formData.append("tree_checklist_id", tree_inspection_id);
        formData.append("tree_checklist_item_id", tree_checklist_item_id);
        formData.append(
            "tree_checklist_items",
            JSON.stringify(inspectionSchedule?.tree_checklist_items)
        );

        if (supportingPhotosData?.length) {
            supportingPhotosData?.forEach((file) => {
                formData.append("inspect_photos[]", file.rawFile);
            });
        }

        if (cameraSupportingImages?.length) {
            cameraSupportingImages?.forEach((file) => {
                formData.append("inspect_photos[]", file);
            });
        }

        if (beforePhotosData?.length) {
            beforePhotosData?.forEach((file) => {
                formData.append("before_photos[]", file.rawFile);
            });
        }

        if (cameraBeforePhotos?.length) {
            cameraBeforePhotos?.forEach((file) => {
                formData.append("before_photos[]", file);
            });
        }

        if (afterPhotosData?.length) {
            afterPhotosData?.forEach((file) => {
                formData.append("after_photos[]", file.rawFile);
            });
        }

        if (cameraAfterPhotos?.length) {
            cameraAfterPhotos?.forEach((file) => {
                formData.append("after_photos[]", file);
            });
        }

        try {
            //defect api
            const result =
                await inspectionTreeChecklistApi.updateInspectionTreeChecklist(
                    tree_inspection_id,
                    formData
                );
            if (result?.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully updated inspection checklist`,
                });
                // navigate(
                //     `/inspection-checklist/tree-inspections/${tree_inspection_id}`
                // );
                setDisabled(false);
                setIsLoadingActive(false);
                setRerender(!rerender);
                resetPhotoComponent();
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setDisabled(false);
            setIsLoadingActive(false);
        }
    };

    const resetPhotoComponent = () => {
        beforePhotos.clearAll();
        afterPhotos.clearAll();
        supportingPhotos.clearAll();
    };

    const setFormatAttachments = (attachments) => {
        if (
            attachments["s" + tree_checklist_item_id]?.inspect_photo !==
                undefined &&
            attachments["s" + tree_checklist_item_id]?.inspect_photo.length
        ) {
            setSupportingDocuments(
                attachments["s" + tree_checklist_item_id]?.inspect_photo
            );
        } else {
            setSupportingDocuments([]);
        }

        if (
            attachments["s" + tree_checklist_item_id]?.before_photos !==
                undefined &&
            attachments["s" + tree_checklist_item_id]?.before_photos.length
        ) {
            setBeforeDocuments(
                attachments["s" + tree_checklist_item_id]?.before_photos
            );
        } else {
            setBeforeDocuments([]);
        }

        if (
            attachments["s" + tree_checklist_item_id]?.after_photos !==
                undefined &&
            attachments["s" + tree_checklist_item_id]?.after_photos.length
        ) {
            setAfterDocuments(
                attachments["s" + tree_checklist_item_id]?.after_photos
            );
        } else {
            setAfterDocuments([]);
        }
    };

    const getInspectionSchedule = useCallback(async () => {
        try {
            const response =
                await inspectionTreeChecklistApi.getInspectionTreeChecklist(
                    tree_inspection_id
                );

            if (response.status === 200) {
                const data = response.data.data;
                const sectionDetails = data?.tree_checklist_items?.find(
                    (treeChecklistItem) =>
                        treeChecklistItem?.id === tree_checklist_item_id
                );

                formatSectionName(sectionDetails?.fields, data?.school?.zone);

                const sectionFileNames = sectionDetails?.fields
                    ?.filter((item) => {
                        return item.type === "file";
                    })
                    .map((item) => item?.name);

                setInspectionFileName(sectionFileNames);
                setFormatAttachments(data.attachments);
                setInspectionSchedule(data);
                reset({
                    defect: sectionDetails?.defect,
                    remarks: sectionDetails?.remarks,
                });
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
        // eslint-disable-next-line
    }, [tree_inspection_id, notyf]);

    const formatSectionName = (data, zone) => {
        let name = "";

        if (zone?.toUpperCase() === "NORTH_EAST") {
            data?.map((item, index, allItems) => {
                const array = allItems.find(
                    (item) => item.type === "custom_input"
                );
                return (name = `${filterInput(
                    array,
                    "tree_id"
                )} - ${filterInput(array, "tree_name:")}`);
            });
        } else if (zone?.toUpperCase() === "SOUTH") {
            data?.map((item, index, allItems) => {
                const array = allItems.find(
                    (item) => item.type === "custom_input"
                );
                return (name = filterInput(array, "tree_name:"));
            });
        }

        setSectionName(name);
    };

    const filterInput = (array, name) => {
        return array?.inputs
            ?.filter((item) => {
                return item.name === name;
            })
            .map((item) => item.value);
    };

    const deleteApi = async (id) => {
        try {
            const response = await attachmentApi.deleteAttachment(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "Successfully deleted file",
                });
                setRerender(!rerender);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    useEffect(() => {
        getInspectionSchedule();
    }, [getInspectionSchedule, tree_inspection_id, reset, rerender]);

    const isReadOnly =
        inspectionSchedule?.status?.toUpperCase() !== "DRAFT" ? true : false;

    return (
        <React.Fragment>
            <Helmet title="Create evidence | Tree inspection checklist" />
            <Row>
                <Col md={6}>
                    <h4 className="h4 mb-3">Tree Inspections</h4>
                </Col>
                <Col md={6}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClick={() => {
                                navigate(
                                    `/inspection-checklist/tree-inspections`
                                );
                                setTreeChecklistStorage([0]);
                            }}
                        >
                            Tree Inspections
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClick={() => {
                                navigate(
                                    `/inspection-checklist/tree-inspections/${tree_inspection_id}`
                                );
                            }}
                        >
                            {inspectionSchedule?.school?.name}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Create evidence
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Container fluid className="p-0">
                <Card>
                    <Card.Body>
                        <Row style={{ marginBottom: "20px" }}>
                            <Col md={6} sm={12}>
                                <h4 className="h4 mb-3">
                                    {inspectionSchedule?.school?.name}
                                </h4>
                                <h5>{sectionName}</h5>
                            </Col>
                            <Col
                                md={6}
                                sm={12}
                                className={!isMobile && "text-end"}
                            >
                                <h5 className="h5 mb-3">
                                    Inspection on:{" "}
                                    {toYMD(
                                        inspectionSchedule?.tree_inspected_at
                                    )}
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Label className="font-weight-bold">
                                                Defects
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="defect"
                                                defaultValue="No"
                                                render={({
                                                    field: {
                                                        value,
                                                        onChange,
                                                        onBlur,
                                                    },
                                                }) => (
                                                    <>
                                                        <Form.Check
                                                            label="Yes"
                                                            type="radio"
                                                            value={value}
                                                            checked={
                                                                value === "Yes"
                                                            }
                                                            onChange={(e) => {
                                                                const checkValue =
                                                                    e.target
                                                                        .checked
                                                                        ? "Yes"
                                                                        : "No";
                                                                setValue(
                                                                    "defect",
                                                                    checkValue
                                                                );
                                                            }}
                                                            disabled={
                                                                isReadOnly
                                                            }
                                                        />
                                                        <Form.Check
                                                            label="No"
                                                            type="radio"
                                                            value={value}
                                                            checked={
                                                                value === "No"
                                                            }
                                                            onChange={(e) => {
                                                                const checkValue =
                                                                    e.target
                                                                        .checked
                                                                        ? "No"
                                                                        : "Yes";
                                                                setValue(
                                                                    "defect",
                                                                    checkValue
                                                                );
                                                            }}
                                                            disabled={
                                                                isReadOnly
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                            <Form.Group className="mb-4">
                                                <Form.Label className="font-weight-bold">
                                                    Remarks
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="remarks"
                                                    defaultValue=""
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                            onBlur,
                                                        },
                                                    }) => (
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            className={
                                                                errors.description &&
                                                                "is-invalid"
                                                            }
                                                            disabled={
                                                                isReadOnly
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />

                                    {(inspectionFileName?.includes(
                                        "before_photo"
                                    ) ||
                                        inspectionFileName?.includes(
                                            "after_photo"
                                        )) && (
                                        <Row>
                                            {inspectionFileName?.includes(
                                                "before_photo"
                                            ) && (
                                                <Col md={6}>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label className="font-weight-bold">
                                                            Images Before Repair
                                                        </Form.Label>
                                                        <FileUploaderSyncfusion
                                                            setUpload={
                                                                setBeforePhotos
                                                            }
                                                            setCameraUpload={
                                                                setCameraBeforePhotos
                                                            }
                                                            cameraPhotos={
                                                                cameraBeforePhotos
                                                            }
                                                            setModalInfo={
                                                                setModalInfo
                                                            }
                                                            document={
                                                                beforeDocuments
                                                            }
                                                            action={
                                                                tree_inspection_id
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )}
                                            {inspectionFileName?.includes(
                                                "after_photo"
                                            ) && (
                                                <Col md={6}>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label className="font-weight-bold">
                                                            Images After Repair
                                                        </Form.Label>
                                                        <FileUploaderSyncfusion
                                                            setUpload={
                                                                setAfterPhotos
                                                            }
                                                            setModalInfo={
                                                                setModalInfo
                                                            }
                                                            document={
                                                                afterDocuments
                                                            }
                                                            action={
                                                                tree_inspection_id
                                                            }
                                                            setCameraUpload={
                                                                setCameraAfterPhotos
                                                            }
                                                            cameraPhotos={
                                                                cameraAfterPhotos
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )}
                                        </Row>
                                    )}
                                    {inspectionFileName?.includes(
                                        "inspect_photo"
                                    ) && (
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-4">
                                                    <Form.Label className="font-weight-bold">
                                                        Inspection images
                                                        {}
                                                    </Form.Label>
                                                    {inspectionSchedule?.status?.toUpperCase() ===
                                                    "SUBMITTED" ? (
                                                        <FileUploaderSyncfusion
                                                            setUpload={
                                                                setSupportingPhotos
                                                            }
                                                            setModalInfo={
                                                                setModalInfo
                                                            }
                                                            document={
                                                                supportingDocuments
                                                            }
                                                            action={
                                                                tree_inspection_id
                                                            }
                                                            setCameraUpload={
                                                                setCameraSupportingImages
                                                            }
                                                            cameraPhotos={
                                                                cameraSupportingImages
                                                            }
                                                            canUpload={false}
                                                            canDelete={false}
                                                        />
                                                    ) : (
                                                        <FileUploaderSyncfusion
                                                            setUpload={
                                                                setSupportingPhotos
                                                            }
                                                            setModalInfo={
                                                                setModalInfo
                                                            }
                                                            document={
                                                                supportingDocuments
                                                            }
                                                            action={
                                                                tree_inspection_id
                                                            }
                                                            setCameraUpload={
                                                                setCameraSupportingImages
                                                            }
                                                            cameraPhotos={
                                                                cameraSupportingImages
                                                            }
                                                            canUpload={
                                                                !(
                                                                    inspectionSchedule?.status !==
                                                                    "Draft"
                                                                )
                                                            }
                                                            canDelete={
                                                                !(
                                                                    inspectionSchedule?.status !==
                                                                    "Draft"
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )}
                                </Form>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row className="pt-4">
                            <Col className="text-end">
                                {inspectionSchedule?.status?.toUpperCase() ===
                                    "DRAFT" && (
                                    <Button
                                        disabled={disabled}
                                        variant="primary"
                                        className="me-2"
                                        onClick={handleSubmit(saveHandler)}
                                    >
                                        Submit
                                    </Button>
                                )}
                                <Button
                                    variant="link"
                                    className="me-2"
                                    onClick={() =>
                                        navigate(
                                            `/inspection-checklist/tree-inspections/${tree_inspection_id}`
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Container>
            <DeleteModal
                api={deleteApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
        </React.Fragment>
    );
};

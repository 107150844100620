export const category = [
    { value: "schedule_maintenance", label: "Scheduled maintenance" },
    { value: "general_r_and_r", label: "General R&R" },
    { value: "custodial_repairs", label: "Custodial repairs" },
    { value: "school_field", label: "School field" },
    {
        value: "landscape_exclude_school_field",
        label: "Landscape exclude school field",
    },
    { value: "synthetic_field", label: "Synthetic field" },
    { value: "landscape_pond", label: "Landscape pond" },
    { value: "landscape_garden", label: "Landscape garden" },
];

export const type = [
    { value: "Addition", label: "Addition" },
    { value: "Omission", label: "Omission" },
];

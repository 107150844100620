import React, { useCallback, useContext, useEffect, useState, createContext } from "react";

import FullCalendar from "@fullcalendar/react";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import NotyfContext from "contexts/NotyfContext";
import * as schoolApi from "@api/schoolApi";
import * as maintenanceSchedule from "@api/maintenanceSchedule";
import { MaintenanceScheduleModal } from "components/common/works-order/modal/MaintenanceScheduleModal"

export const Calendar = () => {
    const notyf = useContext(NotyfContext);

    //
    // States
    //

    const [schools, setSchools] = useState([]);
    const [calendarData, setCalendarData] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [maintenanceId, setMaintenanceId] = useState();
    const [responseMessage, setResponseMessage] = useState(null);
    const [isMaintenanceScheduleLoaded, setIsMaintenanceScheduleLoaded] = useState(false);
    const [showMaintenance, setShowMaintenance] = useState({
        open: false,
        action: "Add",
    });

    //
    // Functions
    //

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const getMaintenanceSchedule = useCallback(async () => {
        try {
            const response = await maintenanceSchedule.getMaintenanceSchedule();

            if (response.status === 200) {
                setCalendarData(response.data.data);
                setIsMaintenanceScheduleLoaded(true);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const createOrUpdateMaintenanceApi = useCallback(async (data) => {
        try {
            const response = data.old_sched ?
                await maintenanceSchedule.updateMaintenanceSchedule(
                    maintenanceId,
                    data
                )
                : await maintenanceSchedule.createMaintenanceSchedule(
                    data
                )
            if (response.status === 201) {
                setResponseMessage(response.data.message)
                setRerender(true);
                notyf.open({
                    type: "success",
                    message: "Request has been processed",
                });
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf, maintenanceId]);

    const deleteApi = useCallback(async (data) => {
        try {
            const response = await maintenanceSchedule.deleteMaintenanceSchedule(
                maintenanceId,
                data
            )

            if (response.status !== 500) {
                setResponseMessage(response.data.data)
                setRerender(true);
                notyf.open({
                    type: "success",
                    message: "Request has been processed",
                });
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf, maintenanceId]);


    const handleDateClick = (arg) => { // bind with an arrow function
        setShowMaintenance({
            open: true,
            action: "Add",
        })
    }

    const handleEventClick = (arg) => { // bind with an arrow function
        setMaintenanceId(arg.event.id);
        setShowMaintenance({
            open: true,
            action: "Edit",
            title: arg.event.title,
            school: schools.find(v => v.value == arg.event.extendedProps.school_id),
            color: arg.event.backgroundColor,
            date: arg.event.startStr,
        })
    }

    //
    // UseEffects
    //

    useEffect(() => {
        getSchools();
        getMaintenanceSchedule();
        setRerender(false)
    }, [rerender]);

    useEffect(() => {
        if (responseMessage != null && isMaintenanceScheduleLoaded == true) {
            notyf.open({
                type: "success",
                message: responseMessage,
            });

        }
        setIsMaintenanceScheduleLoaded(false);
        setResponseMessage(null);
    }, [isMaintenanceScheduleLoaded]);


    return (
        <React.Fragment>
            <Helmet title="Maintenance schedule" />
            <Row className="pb-3">
                <Col md={9}>
                    <label className="d-inline-block ms-3 me-5 font-weight-bold">
                        <span className="circle background-blue" />
                        Building Maintenance
                    </label>
                    <label className="d-inline-block ms-3 me-5 font-weight-bold">
                        <span className="circle background-green" />
                        Turf & Plant
                    </label>
                    <label className="d-inline-block ms-3 me-5 font-weight-bold">
                        <span className="circle background-orange" />
                        Specialist
                    </label>
                </Col>
            </Row>

            <Row className="maintenance-schedule">
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <FullCalendar
                                plugins={[
                                    bootstrapPlugin,
                                    dayGridPlugin,
                                    timeGridPlugin,
                                    interactionPlugin
                                ]}
                                themeSystem="bootstrap"
                                initialView="dayGridMonth"
                                customButtons={{
                                    myCustomButton: {
                                        text: 'Add schedule',
                                        click: handleDateClick,
                                    },
                                }}
                                headerToolbar={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "myCustomButton",
                                }}
                                bootstrapFontAwesome={false}
                                events={calendarData}
                                eventClick={handleEventClick}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <MaintenanceScheduleModal
                api={{createOrUpdate: createOrUpdateMaintenanceApi, delete: deleteApi}}
                modalInfo={showMaintenance}
                setModalInfo={setShowMaintenance}
                schools={schools}
            />
        </React.Fragment>
    );
};

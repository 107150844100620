import api from "../utils/api";

export const pendingForApproval = () => {
    return api.get("/quotations/pending-approvals");
};
export const createQuotation = (data) => {
    return api.post("/quotations", data);
};

export const updateQuotation = (id, data) => {
    return api.post("/quotations/" + id, data);
};

export const getQuotations = (data) => {
    return api.get("/quotations", { params: data });
};

export const getQuotation = (id) => {
    return api.get("/quotations/" + id);
};

export const deleteQuotation = (id) => {
    return api.delete("/quotations/" + id);
};

export const voidQuotation = (id, data) => {
    return api.post(`/quotations/${id}/void`, data);
};

export const uploadQuotationDocuments = (id, data) => {
    return api.post(`/quotations/${id}/upload-quotation-documents`, data);
};

export const maQuotationApproval = (id, data) => {
    return api.post(`/quotations/${id}/ma-approval`, data);
};

export const finalQuotationApproval = (id, data) => {
    return api.post(`/quotations/${id}/final-approvals`, data);
};

export const getQuotationConfig = (id, data) => {
    return api.get(`/config/quotation`, data);
};

export const getClaimQuotationsConfig = (id, data) => {
    return api.get(`/config/claim-quotations`, data);
};

export const createWorkCompletion = (id, data) => {
    return api.post(`/quotations/${id}/update-work-completion`, data);
};

export const getPendingClaims = (data) => {
    return api.get(`/quotations/pending-claims`, { params: data });
};

export const getWorkServiceOrder = (id) => {
    return api.get(`work-service-order/${id}/get`);
};

export const updateWorkServiceOrder = (id, data) => {
    return api.post(`work-service-order/${id}/update`, data);
};

export const updateWorkServiceOrderMA = (id, data) => {
    return api.post(`work-service-order/${id}/ma-approval`, data);
};

export const updateWorkServiceOrderOM = (id, data) => {
    return api.post(`work-service-order/${id}/final-approvals`, data);
};

export const updateFinalQuotationStatus = (id, data) => {
    return api.post(`quotations/${id}/update-final-quotation-status`, data);
};

export const previewQuotation = (id, data) => {
    return api.get(`quotations/${id}/preview`, data);
};

export const pendingForWorkOrderApproval = (data) => {
    return api.get(`work-service-order/pending-approvals`, data);
};

export const cloneQuotation = (id, data) => {
    return api.post(`quotations/${id}/clone`, data);
};

export const rejectQuotation = (id, data) => {
    return api.post(`quotations/${id}/reject`, data);
};

export const moePostApproval = (id, data) => {
    return api.post(`quotations/${id}/moe-fund-approve`, data);
};

export const regenerateReport = (id) => {
    return api.get(`quotations/${id}/report-generation`);
};

import React from "react";

import { Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export const Tabs = ({ module }) => {
    const { tab } = useParams();

    return (
        <Row>
            <Col md={4}>
                <a
                    href={`/dashboard/${module}/building-maintenance`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "building-maintenance"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Building maintenance
                    </Button>
                </a>
            </Col>
            <Col md={4}>
                <a
                    href={`/dashboard/${module}/turfing-and-planting-maintenance`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "turfing-and-planting-maintenance"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Turfing and planting maintenance
                    </Button>
                </a>
            </Col>
        </Row>
    );
};

import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Row } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DynamicTable from "components/ui/DynamicTable";
import * as checklistApi from "@api/checklistApi";
import NotyfContext from "contexts/NotyfContext";
import { formatConfigFields } from "utils/utilities";
import { ChecklistButtons } from "./table-info/ChecklistButtons";
import {
    checklist_category,
    checklist_frequency,
    checklist_type,
} from "./checklist-config";
import { LoadingContext } from "App";

export const RerenderContextDocument = createContext();

export const BMTPChecklist = () => {
    const notyf = useContext(NotyfContext);

    //
    // States
    //

    const [checklists, setChecklists] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [searchValue, setSearchValue] = useState({
        frequency: "",
    });
    const [configColumn, setFieldColumn] = useState([]);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    let isDesktop = width > 1400;

    const actionField = {
        Header: "Actions",
        width: isDesktop ? 70 : 120,
        Cell: ({ row }) => <ChecklistButtons row={row} />,
    };

    //
    // Functions
    //

    const getChecklists = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await checklistApi.getChecklists(param);

                setChecklists(response.data);
                setIsLoadingActive(false);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        // eslint-disable-next-line
        [notyf]
    );

    const search = () => {
        getChecklists(searchValue);
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await checklistApi.getChecklistConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    //
    // UseEffects
    //

    useEffect(() => {
        getChecklists();
        getConfig();
    }, [rerender, getChecklists, getConfig]);

    return (
        <>
            <RerenderContextDocument.Provider value={[setRerender, rerender]}>
                <h4 className="h4 mb-3">BM & TP checklists</h4>
                <Helmet title="Checklists" />
                <Card className="p-0">
                    <Card.Header>
                        <Row>
                            <Col md={10} sm={12} xs={12}>
                                <Row>
                                    <Col md={3} sm={12} xs={12}>
                                        <Select
                                            className={`${isMobile && "mt-2"}`}
                                            classNamePrefix="react-select "
                                            options={checklist_category}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    category: e?.value,
                                                })
                                            }
                                            placeholder="Select category"
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Select
                                            className={`${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            classNamePrefix="react-select "
                                            options={checklist_frequency}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    frequency: e?.value,
                                                })
                                            }
                                            placeholder="Select frequency "
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Select
                                            className={`${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            classNamePrefix="react-select "
                                            options={checklist_type}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    type: e?.value,
                                                })
                                            }
                                            placeholder="Select type"
                                        />
                                    </Col>
                                    <Col md={1} sm={12} xs={12}>
                                        <Button
                                            variant="primary"
                                            className={`me-1 mb-1 form-box ${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            onClick={() => search()}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive &&
                            configColumn &&
                            checklists?.data && (
                                <DynamicTable
                                    dataPagination={checklists}
                                    columns={formatConfigFields(
                                        configColumn,
                                        actionField
                                    )}
                                    className="table-layout-fixed"
                                    api={getChecklists}
                                    searchValue={searchValue}
                                />
                            )}
                    </Card.Body>
                </Card>
            </RerenderContextDocument.Provider>
        </>
    );
};

import React, {
    useState,
    useCallback,
    useContext,
    useEffect,
    createContext,
} from "react";

import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Container,
    Row,
    OverlayTrigger,
    Tooltip,
    Form,
} from "react-bootstrap";

import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import { LoadingContext } from "App";
import { formatConfigFields } from "utils/utilities";
import { faPen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import * as liquidatedDamagesApi from "@api/liquidatedDamagesApi";
import * as schoolApi from "@api/schoolApi";

export const RerenderContextLiquidatedDamages = createContext();

export const LiquidatedDamages = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const notyf = useContext(NotyfContext);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    let isDesktop = width > 1400;
    let isMobile = width < 768;

    const { handleSubmit, control } = useForm({
        mode: "onTouched",
    });

    //
    // States
    //

    const [liquidatedDamages, setLiquidatedDamages] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [configColumn, setFieldColumn] = useState([]);
    const [schools, setSchools] = useState([]);

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: (cell) => (
            <div>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit Liquidated damages</Tooltip>}
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faPen}
                            className="align-middle me-1"
                            onClick={() =>
                                navigate(
                                    location.pathname +
                                        "/" +
                                        cell.row.original.id
                                )
                            }
                            size="lg"
                        />
                    </span>
                </OverlayTrigger>
            </div>
        ),
    };

    //
    // Functions
    //

    const getLiquidatedDamages = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response =
                    await liquidatedDamagesApi.getLiquidatedDamages(param);

                if (response.status === 200) {
                    setLiquidatedDamages(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const search = (data) => {
        const searchValue = {
            school_id: data?.school?.value || "",
            mo: data.mo || "",
            yr: data.yr || "",
        };

        getLiquidatedDamages(searchValue);
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response =
                    await liquidatedDamagesApi.getLiquidatedDamagesConfig(
                        param
                    );
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    //
    // UseEffects
    //

    useEffect(() => {
        getLiquidatedDamages();
        getConfig();
    }, [getLiquidatedDamages, rerender, getConfig]);

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    return (
        <RerenderContextLiquidatedDamages.Provider
            value={[setRerender, rerender]}
        >
            <Helmet title="Liquidated damages" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Liquidated damages</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={10} sm={10} xs={10}>
                                <Row>
                                    <Col md={4} sm={4} xs={4}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="school"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                    ref,
                                                },
                                            }) => (
                                                <Select
                                                    className={`${
                                                        isMobile && "mt-2"
                                                    }`}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    options={schools}
                                                    placeholder="Select school"
                                                />
                                            )}
                                        />
                                    </Col>

                                    <Col md={2} sm={3} xs={3}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="mo"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Form.Control
                                                    onChange={onChange}
                                                    value={value}
                                                    type="text"
                                                    placeholder="Month"
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={2} sm={3} xs={3}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="yr"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Form.Control
                                                    onChange={onChange}
                                                    value={value}
                                                    type="text"
                                                    placeholder="Year"
                                                />
                                            )}
                                        />
                                    </Col>

                                    <Col md={3} sm={2} xs={2}>
                                        <Button
                                            variant="primary"
                                            className="me-1 mb-1 form-box"
                                            onClick={handleSubmit(search)}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <h1 className="h3 mb-3">
                                    <Button
                                        variant="primary"
                                        className="float-end mb-2 form-box"
                                        onClick={() =>
                                            navigate(`/cop/ld/create`)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </h1>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive &&
                            configColumn &&
                            liquidatedDamages.data && (
                                <DynamicTable
                                    dataPagination={liquidatedDamages}
                                    columns={formatConfigFields(
                                        configColumn,
                                        actionField
                                    )}
                                    className="table-layout-fixed"
                                    api={getLiquidatedDamages}
                                />
                            )}
                    </Card.Body>
                </Card>
            </Container>
        </RerenderContextLiquidatedDamages.Provider>
    );
};

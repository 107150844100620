import React, { useCallback, useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Container, Row, Button, Col, Breadcrumb } from "react-bootstrap";
import { Card, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import Select from "react-select";
import { months } from "config";
import { years } from "config";
import * as liquidatedDamagesApi from "@api/liquidatedDamagesApi";
import * as schoolApi from "@api/schoolApi";

export const LiquidatedDamagesForm = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const create = action === "create";
    const notyf = useContext(NotyfContext);

    //
    // States
    //

    const [schoolsOriginal, setSchoolsOriginal] = useState([]);
    const [schools, setSchools] = useState([]);
    const schema = yup.object().shape({
        school_id: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        mo: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        yr: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        amount: yup.number().nullable().required("This field is required."),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const liquidatedDamagesApiCall = async (data) => {
        data.school_id = data.school_id.value;
        data.mo = data.mo.value;
        data.yr = data.yr.value;

        try {
            create
                ? await liquidatedDamagesApi.createLiquidatedDamages(data)
                : await liquidatedDamagesApi.updateLiquidatedDamage(
                      action,
                      data
                  );

            notyf.open({
                type: "success",
                message: `Successfully ${
                    create ? "created" : "updated"
                } Liquidated damage`,
            });
            navigate(`/cop/ld`);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const schoolsMapped = response.data.data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchoolsOriginal(response.data.data);
            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    //
    // UseEffects
    //

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    useEffect(() => {
        const getLiquidatedDamages = async () => {
            try {
                const response = await liquidatedDamagesApi.getLiquidatedDamage(
                    action
                );
                if (response.status === 200) {
                    const data = response.data.data;

                    reset({
                        school_id: schools?.find(
                            (o) => o?.value === data.school_id
                        ),
                        mo: months?.find((o) => o?.value === data.mo),
                        yr: years?.find((o) => o?.value === data.yr),
                        amount: data.amount,
                    });
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        };

        if (action !== "create" && schools.length) {
            getLiquidatedDamages();
        }
    }, [action, schools]);

    return (
        <React.Fragment>
            <Helmet title={`${create ? "Create" : "Edit"} SOI`} />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">
                            {create ? "Create" : "Edit"} Liquidated damages
                        </h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate("/cop/ld")}
                            >
                                Liquidated damages
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {create ? "Create" : "Edit"}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        School
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="school_id"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <>
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={schools}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    value={value}
                                                    onChange={onChange}
                                                    menuPlacement="top"
                                                />
                                            </>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="school_id"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Month
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="mo"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select"
                                                options={months}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="bottom"
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="mo"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Year
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="yr"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select"
                                                options={years}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="bottom"
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="yr"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Amount
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="amount"
                                        defaultValue={0}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Form.Control
                                                onChange={onChange}
                                                value={value}
                                                type="number"
                                                className={
                                                    errors.header &&
                                                    "is-invalid"
                                                }
                                            />
                                        )}
                                    />
                                </Form.Group>
                                <ErrorMessage
                                    errors={errors}
                                    name="amount"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col md={12} className="text-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    type="submit"
                                    onClick={handleSubmit(
                                        liquidatedDamagesApiCall
                                    )}
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={() => navigate(`/cop/ld`)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

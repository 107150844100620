import React, { useContext, useState, useCallback, useEffect } from "react";

import NotyfContext from "contexts/NotyfContext";
import DynamicTable from "components/ui/DynamicTable";
import { formatConfigFields } from "utils/utilities";
import * as quotationsApi from "@api/quotationsApi";

export const QuotationListForm = ({
    fundType,
    jobType,
    pendingClaims,
    setSelectedRows,
    defaultSelectedRows,
    withCheckbox = true,
}) => {
    const notyf = useContext(NotyfContext);
    const projectExists =
        jobType && jobType.some((obj) => obj.value === "project_work");

    //
    // States
    //

    const [configColumn, setFieldColumn] = useState([]);

    //
    // Functions
    //

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await quotationsApi.getClaimQuotationsConfig(
                    param
                );
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getRowId = (row, relativeIndex, parent) => {
        return parent ? [parent.id, row.id].join(".") : row.id;
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    return (
        <>
            <DynamicTable
                dataPagination={
                    pendingClaims.data
                        ? pendingClaims
                        : {
                              data: [],
                              links: "",
                              meta: "",
                          }
                }
                columns={formatConfigFields(configColumn, "")}
                className="table-layout-fixed"
                setSelectedRows={setSelectedRows}
                withCheckbox={withCheckbox}
                singleSelectCheckbox={
                    fundType?.value === "SCHOOL" ||
                    ((fundType?.value === "MOE" ||
                        fundType?.value === "95-5") &&
                        !projectExists)
                }
                defaultSelectedRows={defaultSelectedRows}
                getRowId={getRowId}
                // isPaginated={false}
            />
        </>
    );
};

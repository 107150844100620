import React, { useContext, useEffect } from "react";
import { LoadingContext } from "App";

import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";

import NotyfContext from "contexts/NotyfContext";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RerenderContextClaim } from "pages/claims/claims-list/ClaimsList";
import { errorMessage } from "utils/utilities";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import * as claimsApi from "@api/claimsApi";

export const UpdateAttachmentsModal = ({ modalInfo, setModalInfo }) => {
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [rerender, setRerender] = useContext(RerenderContextClaim);
    const notyf = useContext(NotyfContext);

    let schema = yup.object().shape({
        attachments: yup.array(
            yup.object().shape({
                file_name: yup
                    .string()
                    .required("This item is required")
                    .nullable(),
                additional_fields: yup.object().shape({
                    order: yup.string().when(["mime"], {
                        is: (mime) => {
                            if (mime === "application/pdf") {
                                return false;
                            }
                            return true;
                        },
                        then: yup
                            .string()
                            .required("This item is required")
                            .nullable(),
                    }),
                    report: yup
                        .boolean()
                        .required("This item is required")
                        .nullable(),
                }),
            })
        ),
    });

    //
    // Functions
    //

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { fields } = useFieldArray({
        control,
        name: "attachments",
    });

    const closeModal = () => {
        setModalInfo({ open: false });
    };

    const documentManagementApiCall = async (data) => {
        try {
            const response = await claimsApi.updateAttachments(
                modalInfo.id,
                data
            );

            if (response.status === 201 || response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "Successfully updated attachments",
                });

                setIsLoadingActive(false);
                setRerender(!rerender);
                closeModal();
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setIsLoadingActive(false);
        }
    };

    useEffect(() => {
        let parsedAttachments = [];

        Object.entries(modalInfo?.documents).forEach(([key, value]) => {
            if (value && key === "tc") {
                const valueData = value.map((v) => ({
                    ...v,
                    uploader: key.toUpperCase(),
                }));
                parsedAttachments = [...parsedAttachments, ...valueData];
            }
        });

        // let sortOrder = 1;

        // parsedAttachments?.forEach((obj, key) => {
        //     if (!obj.additional_fields) {
        //         obj.additional_fields = { order: key + 1, report: true };
        //     }

        //     if (!obj.additional_fields.hasOwnProperty("order")) {
        //         obj.additional_fields.order = sortOrder || "";
        //         sortOrder++;
        //     }

        //     if (!obj.additional_fields.hasOwnProperty("report")) {
        //         obj.additional_fields.report = true || "";
        //     }
        // });

        reset({
            attachments: parsedAttachments,
        });
        // eslint-disable-next-line
    }, [modalInfo?.documents]);

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "add" })}
            size="xl"
            centered
        >
            <Modal.Header closeButton>Update attachments</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Table bordered size="md">
                        <tbody>
                            <tr>
                                <th>Filename</th>
                                <th className="text-end">Created at</th>
                                <th width="100">View</th>
                                <th className="text-end" width="100">
                                    Order
                                </th>
                                <th width="150">Include in report</th>
                            </tr>
                            {fields.length ? (
                                fields.map((data, key) => {
                                    if (
                                        (data?.additional_fields?.order ===
                                            "-1" &&
                                            data?.additional_fields?.type ===
                                                "INVOICE") ||
                                        data?.mime !== "application/pdf"
                                    ) {
                                        return (
                                            <tr key={key}>
                                                <td>{data?.file_name}</td>
                                                <td className="text-end">
                                                    {data?.created_at}
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon
                                                        icon={faEye}
                                                        className="align-middle me-1"
                                                        size="lg"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.open(
                                                                data?.full_path
                                                            );
                                                        }}
                                                    />
                                                </td>
                                                <td className="text-end">-</td>
                                                <td>
                                                    {data?.mime ===
                                                    "application/pdf"
                                                        ? "Yes"
                                                        : "No"}
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return (
                                        <tr key={key}>
                                            <td>
                                                <Controller
                                                    control={control}
                                                    name={`attachments.${key}.file_name`}
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <Form.Control
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            className={`form-box ${
                                                                errors.length &&
                                                                errors
                                                                    ?.attachments[
                                                                    key
                                                                ]?.file_name &&
                                                                "is-invalid"
                                                            }`}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`attachments.${key}.file_name`}
                                                    render={({ message }) =>
                                                        errorMessage(message)
                                                    }
                                                />
                                            </td>
                                            <td className="text-end">
                                                {data?.created_at}
                                            </td>
                                            <td>
                                                <FontAwesomeIcon
                                                    icon={faEye}
                                                    className="align-middle me-1"
                                                    size="lg"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.open(
                                                            data?.full_path
                                                        );
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Controller
                                                    defaultValue=""
                                                    control={control}
                                                    name={`attachments.${key}.additional_fields.order`}
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <Form.Control
                                                            className={`text-end form-box ${
                                                                errors.length &&
                                                                errors
                                                                    ?.attachments[
                                                                    key
                                                                ]
                                                                    ?.additional_fields
                                                                    ?.order &&
                                                                "is-invalid"
                                                            }`}
                                                            type="text"
                                                            onChange={onChange}
                                                            value={value || ""}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`attachments.${key}.additional_fields.order`}
                                                    render={({ message }) =>
                                                        errorMessage(message)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Controller
                                                    defaultValue=""
                                                    control={control}
                                                    name={`attachments.${key}.additional_fields.report`}
                                                    render={({
                                                        field: { value },
                                                    }) => (
                                                        <>
                                                            <Form.Check
                                                                label="Yes"
                                                                type="radio"
                                                                value={
                                                                    value || ""
                                                                }
                                                                checked={
                                                                    value ===
                                                                    true
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const checkValue =
                                                                        e.target
                                                                            .checked
                                                                            ? true
                                                                            : false;

                                                                    setValue(
                                                                        `attachments.${key}.additional_fields.report`,
                                                                        checkValue
                                                                    );
                                                                }}
                                                            />
                                                            <Form.Check
                                                                label="No"
                                                                type="radio"
                                                                value={
                                                                    value || ""
                                                                }
                                                                checked={
                                                                    value ===
                                                                    false
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const checkValue =
                                                                        e.target
                                                                            .checked
                                                                            ? false
                                                                            : true;
                                                                    setValue(
                                                                        `attachments.${key}.additional_fields.report`,
                                                                        checkValue
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`attachments.${key}.additional_fields.report`}
                                                    render={({ message }) =>
                                                        errorMessage(message)
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        No documents
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        <Button
                            className="me-2"
                            variant="primary"
                            type="submit"
                            onClick={handleSubmit(documentManagementApiCall)}
                        >
                            Submit
                        </Button>
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

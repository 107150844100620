import { useState } from "react";
import { ListGroupItem, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const CardList = (props) => {
    const { title, data } = props;
    const [activeVersion, setActiveVersion] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const canSingleSendEmail = params.get("canSingleSendEmail");

    return (
        <>
            <div style={{ width: "100%", float: "right" }}>
                <h4>{title}</h4>
                <div
                    className="card"
                    style={{
                        boxShadow: "none",
                        border: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                >
                    {data?.length > 0 ? (
                        <ul className="list-group list-group-flush">
                            {data.map((field, index) => {
                                const navigateTo = `${field?.link}${
                                    canSingleSendEmail === "true"
                                        ? "?canSingleSendEmail=true"
                                        : ""
                                }`;

                                return (
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            field?.title.length > 20 ? (
                                                <Tooltip>
                                                    {field?.title}
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    >
                                        <ListGroupItem
                                            active={
                                                activeVersion === field?.link
                                            }
                                            key={index}
                                            onClick={() => {
                                                navigate(navigateTo);
                                                setActiveVersion(field?.link);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {field?.title.length > 30
                                                ? Array.from(field?.title)
                                                      .slice(0, 29)
                                                      .join("") + "..."
                                                : field?.title}
                                        </ListGroupItem>
                                    </OverlayTrigger>
                                );
                            })}
                        </ul>
                    ) : (
                        <ul className="list-group list-group-flush">
                            <ListGroupItem>None</ListGroupItem>
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
};

export default CardList;

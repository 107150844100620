import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "react-bootstrap";

import { months, status } from "../config";

export const errorMessage = (message) => {
    return <small className="text-danger">{message}</small>;
};

export const toTitleCase = (str) => {
    if (!str) return;
    str = str.replace(/[-_]/, " ");
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
};

// Created separate function from above (toTitleCase) to avoid breaking changes
export const toTitleCaseCheckAllText = (str) => {
    if (!str) return;
    str = str.replace(/[-_]/g, " ");

    return str
        .split(" ")
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const formatFileName = (file_name) => {
    //remove timestamp
    return file_name;
};

export const parseFloatToFixed = (data) => {
    return parseFloat(data).toFixed(2);
};

export const commaSeparateNumber = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getObjectValue = (arr, obj) => {
    const [first, ...rest] = arr;
    return obj[first] && typeof obj[first] === "object"
        ? getObjectValue(rest, obj[first])
        : obj[first]
        ? obj[first]
        : "-";
};

export const parseJson = (json) => {
    const categories = JSON.parse(json);
    return categories.map((data, key) => {
        return (
            <span className="pe-1" key={key}>
                <Badge pill bg={"secondary"}>
                    {data.value}
                </Badge>
            </span>
        );
    });
};

export const formatConfigFields = (fields, actionField = null) => {
    const formateFields = [];
    const width = window.innerWidth;
    let isDesktop = width > 1400;

    if (actionField) {
        formateFields.push(actionField);
    }
    fields?.forEach((item) => {
        const temp = {};
        // if the header have class or formats
        if (item.header.hasOwnProperty("label")) {
            temp.Header = () => (
                <>
                    <div className={item.header?.class}>
                        {item.header.label}
                    </div>
                </>
            );
        } else {
            // if there is no special formats
            temp.Header = item.header;
        }
        temp.accessor = item.accessor;
        if (item?.width) {
            temp.width = isDesktop ? item?.width : 120;
        }

        // if there is  there is spacial format cell
        if (item?.cell) {
            temp.Cell = ({ row }) => {
                if (item.cell?.noFormer && item.cell?.name) {
                    return getObjectValue(
                        item?.cell?.name?.split("."),
                        row.original
                    ).replace(/former/gi, "");
                }

                if (
                    row?.original?.is_star_rate &&
                    item.cell?.name === "item_number"
                ) {
                    return (
                        <p className="font-weight-bold text-end">
                            <FontAwesomeIcon
                                icon={faStar}
                                size="lg"
                                className="me-2"
                            />
                            Star rate
                        </p>
                    );
                }

                if (item.cell?.isMonth && item.cell?.name) {
                    return (
                        months?.find(
                            (o) =>
                                o?.value ===
                                getObjectValue(
                                    item.cell.name?.split("."),
                                    row.original
                                ).toString()
                        )?.label || "-"
                    );
                }

                if (item.cell?.toTitleCase && item.cell?.name) {
                    return toTitleCase(
                        getObjectValue(item.cell.name?.split("."), row.original)
                    );
                }

                if (item.cell?.isClaimQuotation && item.cell?.name) {
                    return (
                        <p className="text-end">
                            <a
                                href={`${window.location.origin}/works-order/quotation-list/${row.original.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                                style={{
                                    color: "#3F80EA",
                                }}
                            >
                                {getObjectValue(
                                    item.cell.name?.split("."),
                                    row.original
                                )}
                            </a>
                        </p>
                    );
                }

                if (item.cell?.isWsoQuotation && item.cell?.name) {
                    return row.original.quotation_id ? (
                        <p className="text-end">
                            <a
                                href={`${window.location.origin}/works-order/quotation-list/${row.original.quotation_id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                                style={{
                                    color: "#3F80EA",
                                }}
                            >
                                {getObjectValue(
                                    item.cell.name?.split("."),
                                    row.original
                                )}
                            </a>
                        </p>
                    ) : (
                        <p className="text-end">-</p>
                    );
                }

                if (item.cell?.isBadge && item.cell?.name) {
                    return (
                        <>
                            {item.cell?.isJson ? (
                                parseJson(
                                    getObjectValue(
                                        item.cell.name?.split("."),
                                        row.original
                                    )
                                )
                            ) : (
                                <div className={item.cell?.class}>
                                    {getObjectValue(
                                        item.cell.name?.split("."),
                                        row.original
                                    ) !== "-" ? (
                                        <Badge
                                            className="text-wrap"
                                            pill
                                            bg={status(
                                                getObjectValue(
                                                    item.cell.name?.split("."),
                                                    row.original
                                                )?.toUpperCase()
                                            )}
                                        >
                                            {getObjectValue(
                                                item.cell.name?.split("."),
                                                row.original
                                            )}
                                        </Badge>
                                    ) : (
                                        "-"
                                    )}

                                    <br />

                                    {item.cell.appendFields && (
                                        <small className="text-center">
                                            {getObjectValue(
                                                item.cell.appendFields?.split(
                                                    "."
                                                ),
                                                row.original
                                            )}
                                            {item.cell.appendFieldText &&
                                                ` ( ${item.cell.appendFieldText} )`}
                                        </small>
                                    )}
                                </div>
                            )}
                        </>
                    );
                }

                return (
                    <div className={item.cell?.class}>
                        {item.cell?.isNumber && (
                            <>
                                {getObjectValue(
                                    item.cell.name?.split("."),
                                    row.original
                                ) !== "-"
                                    ? commaSeparateNumber(
                                          parseFloatToFixed(
                                              getObjectValue(
                                                  item.cell.name?.split("."),
                                                  row.original
                                              )
                                          )
                                      )
                                    : "-"}
                            </>
                        )}
                        {!item.cell?.isNumber &&
                            getObjectValue(
                                item.cell.name?.split("."),
                                row.original
                            )}

                        <br />
                        {item.cell?.appendFieldText}
                    </div>
                );
            };
        }
        formateFields.push(temp);
    });
    return formateFields;
};

export const toMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
        month: "long",
    });
};

export const roundToTwoDecimal = (value) => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const roundToThreeDecimal = (value) => {
    return Math.round((value + Number.EPSILON) * 1000) / 1000;
}

export const moneyFormatter = (value) => {
    return commaSeparateNumber(parseFloat(value).toFixed(2));
};

export const checkSequenceDuplicates = (objToUpdate) => {
    const serialNos = new Set();
    let hasDuplicates = false;

    objToUpdate.some((item) => {
        if (item.header) return;

        if (serialNos.has(item.serial_no)) {
            hasDuplicates = true;
            return true; // stop iterating
        } else {
            serialNos.add(item.serial_no);
        }
    });

    return hasDuplicates;
};

export const calculateQuotationItemAmounts = (quotationItemsStorageClone) => {
    return quotationItemsStorageClone.map((row, index) => {
        row.rate = row?.rate?.replace(/,/g, "");
        let totalRowAmount = 0;

        totalRowAmount = parseFloat(
            roundToTwoDecimal(row?.rate * row.quantity)
        ).toFixed(2);

        if (row.minimum) {
            row.minimum = parseInt(row.minimum);
            totalRowAmount =
                totalRowAmount < row.minimum ? row.minimum : totalRowAmount;
        }

        return {
            ...quotationItemsStorageClone[index],
            amount: totalRowAmount,
        };
    });
};

export const constructReport = (
    bucket,
    file_path,
    name = false,
    isPreview = false
) => {
    const base_url = window.location.origin;
    let downloadUrl = "";

    switch (true) {
        case base_url.includes("localhost"):
            downloadUrl = `http://moe-ecms.local/download?bucket=${bucket}&file_path=${file_path}${
                name ? `&name=${name}` : ""
            }${isPreview ? "&preview=1" : ""}`;
            break;
        case base_url.includes("staging"):
            downloadUrl = `https://moe-ecms-api-staging.intellisolution.tech/download?bucket=${bucket}&file_path=${file_path}${
                name ? `&name=${name}` : ""
            }${isPreview ? "&preview=1" : ""}`;
            break;
        default:
            downloadUrl = `https://moe-ecms-api.intellisolution.tech/download?bucket=${bucket}&file_path=${file_path}${
                name ? `&name=${name}` : ""
            }${isPreview ? "&preview=1" : ""}`;
            break;
    }

    return downloadUrl;
};

import api from "../utils/api";

export const createLiquidatedDamages = (data) => {
    return api.post("/cop-ld", data);
};

export const updateLiquidatedDamage = (id, data) => {
    return api.patch("/cop-ld/" + id, data);
};

export const getLiquidatedDamages = (data) => {
    return api.get("/cop-ld", { params: data });
};

export const getLiquidatedDamage = (id) => {
    return api.get("/cop-ld/" + id);
};

export const getLiquidatedDamagesConfig = (data) => {
    return api.get(`/config/liquidated-damages`, data);
};

import React, { useContext } from "react";

import {
    Col,
    Form,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import {
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotyfContext from "contexts/NotyfContext";

import * as schoolApi from "@api/schoolApi";

export const ManagingAgentsAssignments = ({
    control,
    managingAgentsFields,
    managingAgentRemove,
    rerender,
    setRerender,
    setIsLoadingActive
}) => {
    const notyf = useContext(NotyfContext);

    const handleDelete = async (id) => {
        try {
            setIsLoadingActive(true);
            const response = await schoolApi.deleteSchoolAssignment(
                id,
            );
            if (response.status === 200) {
                // setRerender(!rerender)
                setIsLoadingActive(false);
                notyf.open({
                    type: "success",
                    message: "Successfully deleted user school assignment",
                });
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };
    const onActiveChange = async (action, id) => {
        try {
            setIsLoadingActive(true);
            const formData = new FormData();
            formData.append("_method", "PATCH")
            formData.append("is_active", action.target.checked)

            const response = await schoolApi.updateSchoolAssignment(
                id,
                formData
            );

            if (response.status === 200) {
                // setRerender(!rerender)
                setIsLoadingActive(false);
                notyf.open({
                    type: "success",
                    message: "Successfully updated user school assignment",
                });
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    return (
        <>
            <Row>
                <Form>
                    <Row className="font-weight-bold mb-2">
                        <h5>Managing agents</h5>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table
                                className="ma-user-org-table"
                                bordered
                                responsive
                                striped
                            >
                                <thead>
                                    <tr>
                                        <th>Actions</th>
                                        <th>User</th>
                                        <th
                                            style={{
                                                width: "100px",
                                            }}
                                        >
                                            Is Active
                                        </th>
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th>Last updated by</th>
                                        <th>Last updated at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {managingAgentsFields.length ? (
                                        managingAgentsFields.map(
                                            (item, index) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip>
                                                                        Delete
                                                                        assignment
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                        className="align-middle me-1 text-danger"
                                                                        size="lg"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            managingAgentRemove(index)
                                                                            handleDelete(item.id)
                                                                        }}
                                                                    />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            {item.managing_agent.full_name}
                                                            <br />
                                                            
                                                        </td>
                                                        <td>
                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name={`managing_agents[${index}].is_active`}
                                                                defaultValue=""
                                                                render={({
                                                                    field: {
                                                                        value,
                                                                        onChange,
                                                                        onBlur,
                                                                    },
                                                                }) => (
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        value={
                                                                            value ||
                                                                            ""
                                                                        }
                                                                        checked={
                                                                            value ||
                                                                            ""
                                                                        }
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            onActiveChange(e, item.id); // Log the value to the console
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </td>
                                                        <td>
                                                            {item.start_date}
                                                        </td>
                                                        <td>
                                                            {item.end_date}
                                                        </td>
                                                        
                                                        <td>
                                                            {item.updater.full_name}
                                                        </td>
                                                        <td>
                                                            {item.updated_at}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={6}
                                                className="text-center"
                                            >
                                                No managing agents assigned
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Form>
            </Row>
            
        </>
    );
};

export const export_status_options_quotation = [
    { value: "APPROVED", label: "Approved (Quotation)" },
    { value: "COMPLETED", label: "Completed (Quotation)" },
    { value: "REJECTED", label: "Rejected (Quotation)" },
    { value: "VOID", label: "Void (Quotation)" },
    { value: "PENDING_MA", label: "Pending MA (Quotation)" },
    { value: "PENDING_TC", label: "Pending TC (Quotation)" },
];

export const export_current_status_options_quotation = [
    { value: "WSO_PENDING_CREATION", label: "Works Completed (TC to Create WSO)" },
    { value: "WSO_PENDING_MA", label: "Pending MA (WSO)" },
    { value: "WSO_PENDING_OM", label: "Pending OM (WSO)" },
    { value: "WSO_REJECTED", label: "Rejected (WSO)" },
];

export const export_status_options_wso = [
    { value: "PENDING", label: "Pending (WSO)" },
    { value: "APPROVED", label: "Approved (WSO)" },
    { value: "REJECTED", label: "Rejected (WSO)" },
];

export const export_status_options_inspection = [
    { value: "SUBMITTED", label: "Submitted (Inspection)" },
    { value: "APPROVED", label: "Approved (Inspection)" },
    { value: "REJECTED", label: "Rejected (Inspection)" },
    { value: "ACKNOWLEDGED", label: "Acknowldged (Inspection)" },
];

export const export_status_options_claims = [
    { value: "PENDING", label: "Pending (Claims)" },
    { value: "APPROVED", label: "Approved (Claims)" },
    { value: "COMPLETED", label: "Completed (Quotation)" },
    { value: "REJECTED", label: "Rejected (Claims)" },
];

export const export_current_status_options_claims = [
    { value: "PENDING_MA", label: "Pending MA (Claims)" },
    { value: "PENDING_E_INVOICE", label: "Pending E-invoice (Claims)" },
    { value: "E_INVOICE_REJECTED", label: "E-invoice Rejected (Claims)" },
    { value: "APPROVED", label: "Approved (Claims)" },
    { value: "COMPLETED", label: "Completed (Claims)" },
    { value: "REJECTED", label: "Rejected (Claims)" },
];

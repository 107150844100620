import React from "react";
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    ListGroup,
    ListGroupItem,
    Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

export const DefectsView = () => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet title="Add evidence" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">Add evidence</h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() =>
                                    navigate("/works-order/quotation-list")
                                }
                            >
                                Quotation list
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                onClick={() =>
                                    navigate(
                                        "/works-order/quotation-list/create"
                                    )
                                }
                            >
                                Create
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                onClick={() =>
                                    navigate(
                                        "/works-order/quotation-list/create/add-defect"
                                    )
                                }
                            >
                                Add evidence
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                View defect
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Header className="pb-0">
                        <h5>Yishun Primary School</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={9}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className="font-weight-bold">
                                                    Inspection Type
                                                </span>
                                            </Form.Label>
                                            <p>
                                                Visual Inspection (Occupied
                                                Complexes)
                                            </p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className="font-weight-bold">
                                                    Frequency
                                                </span>
                                            </Form.Label>
                                            <p>Weekly</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className="font-weight-bold">
                                                    Facilities
                                                </span>
                                            </Form.Label>
                                            <p>Plumbing & Sanitation</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className="font-weight-bold">
                                                    Elements
                                                </span>
                                            </Form.Label>
                                            <p>Drainage System</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className="font-weight-bold">
                                                    Location
                                                </span>
                                            </Form.Label>
                                            <p>
                                                Design System for Bootstrap -
                                                the world’s most popular
                                                framework for building
                                                responsive, mobile-first sites.
                                            </p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className="font-weight-bold">
                                                    Description
                                                </span>
                                            </Form.Label>
                                            <p>
                                                Design System for Bootstrap -
                                                the world’s most popular
                                                framework for building
                                                responsive, mobile-first sites.
                                            </p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className="font-weight-bold">
                                                    Images
                                                </span>
                                            </Form.Label>
                                            <p>No images</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className="font-weight-bold">
                                                    Status
                                                </span>
                                            </Form.Label>
                                            <p>Inspected and Repaired</p>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <ListGroup>
                                    <ListGroupItem>
                                        Drainage System
                                    </ListGroupItem>
                                    <ListGroupItem>Glass Doors</ListGroupItem>
                                    <ListGroupItem>Walls</ListGroupItem>
                                    <ListGroupItem>
                                        Partition walls and frameworks
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        Roller shutters, gates & turnstiles
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        External school signs, banners and
                                        signwall
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        Grilles (Doors and Windows)
                                    </ListGroupItem>
                                    <ListGroupItem>Floor joints</ListGroupItem>
                                    <ListGroupItem>
                                        Suspension Systems and Trusses
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>
                        </Row>
                        <Row className="pt-4">
                            <Col md={12} className="text-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={() =>
                                        navigate(
                                            "/works-order/quotation-list/create/add-defect"
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
};

import React, { useContext, useState } from "react";

import Select from "react-select";
import NotyfContext from "contexts/NotyfContext";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { AsyncPaginate } from "react-select-async-paginate";

import { errorMessage, roundToTwoDecimal } from "utils/utilities";
import { ErrorMessage } from "@hookform/error-message";
import * as fsrsApi from "@api/fsrsApi";
import * as yup from "yup";

export const InsertItemModal = ({ modalInfo, setModalInfo, view }) => {
    let schema = yup.object().shape({
        position: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .nullable()
            .required("This field is required."),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // States
    //

    const [hasMore, setHasMore] = useState(true);
    const [option, setOption] = useState(true);
    const notyf = useContext(NotyfContext);

    //
    // Functions
    //

    const closeModal = () => {
        setModalInfo({ open: false });
        setOption(true);
        reset({
            position: "",
        });
    };

    const saveModal = (data) => {
        if (!option?.value) {
            notyf.open({
                type: "warning",
                message: "Please select an FSR item to proceed",
            });
            return;
        }

        let optionsClone = { ...option?.value };

        optionsClone["rate"] = optionsClone["rate"]?.replace(/,/g, "");
        optionsClone["amount"] = parseFloat(
            roundToTwoDecimal(optionsClone.rate * optionsClone.quantity)
        ).toFixed(2);
        optionsClone["serial_no"] = modalInfo?.row?.original?.serial_no;
        optionsClone["minimum"] = false;
        delete optionsClone["hasHeader"];

        setModalInfo({
            open: false,
            newValue: optionsClone,
            position: data.position.value,
            row: modalInfo?.row,
        });
        setOption(true);
        reset({
            position: "",
        });
    };

    const handleLoadMore = async (search, { page }) => {
        const response = await fsrsApi.getFsrOptions({
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response.data;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const onChangeSelected = (selectedRows) => {
        setOption(selectedRows);
    };

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => closeModal()}
            size="lg"
            centered
        >
            <Modal.Header closeButton>Insert FSR item</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        {!view && (
                            <>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="fsr">
                                            FSR items
                                        </Form.Label>
                                        <AsyncPaginate
                                            isClearable
                                            loadOptions={handleLoadMore}
                                            hasMore={hasMore}
                                            additional={{
                                                page: 1,
                                            }}
                                            onChange={(value) =>
                                                onChangeSelected(value)
                                            }
                                            menuPosition="fixed"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <>
                                            <Form.Label htmlFor="fsr">
                                                Position
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="position"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Select
                                                        classNamePrefix="react-select"
                                                        options={[
                                                            {
                                                                value: "UP",
                                                                label: "Up",
                                                            },
                                                            {
                                                                value: "DOWN",
                                                                label: "Down",
                                                            },
                                                        ]}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={onChange}
                                                        value={value}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="position"
                                                render={({ message }) =>
                                                    errorMessage(message)
                                                }
                                            />
                                        </>
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        {!view && (
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit(saveModal)}
                            >
                                Submit
                            </Button>
                        )}
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import Select from "react-select";
import { Helmet } from "react-helmet-async";
import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import { LoadingContext } from "App";
import { toTitleCase } from "utils/utilities";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { zones } from "config";
import { faUser, faListAlt } from "@fortawesome/free-regular-svg-icons";
import { Controller, useForm } from "react-hook-form";
import * as schoolApi from "@api/schoolApi";
import * as usersApi from "@api/usersApi";

export const RerenderContextUserOrganization = createContext();

export const UserOrganization = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const location = useLocation();
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    const { hasRole, user } = useAuth();
    let isDesktop = width > 1400;
    let isMobile = width < 768;
    const { control, handleSubmit } = useForm({});

    const hasRoleCollection = {
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isQS: hasRole("MA-QS"),
        isAdmin: hasRole("ADMIN"),
        isInvoiceTeam: hasRole("INVOICE_TEAM"),
    };

    //
    // States
    //

    const [schools, setSchools] = useState([]);
    const [schoolsMapped, setSchoolsMapped] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [managingAgents, setManagingAgents] = useState([]);
    const [operationManagers, setOperationManagers] = useState([]);
    const [configColumn, setFieldColumn] = useState([]);

    const actionFields = [
        {
            Header: "Actions",
            accessor: "actions",
            width: isDesktop ? 70 : 120,
            Cell: ({ cell }) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Manage users</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className="align-middle me-1"
                                    onClick={() =>
                                        navigate(
                                            location.pathname +
                                                "/manage-users/" +
                                                cell.row.original.id
                                        )
                                    }
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                        {hasRoleCollection?.isAdmin && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Manage assignments</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faListAlt}
                                        className="align-middle me-1"
                                        onClick={() =>
                                            navigate(
                                                location.pathname +
                                                    "/manage-assignments/" +
                                                    cell.row.original.id
                                            )
                                        }
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    </>
                );
            },
        },
        {
            Header: "School name",
            accessor: "name",
        },
        {
            Header: "Zone",
            accessor: "zone",
            Cell: ({ row }) => {
                return <p>{toTitleCase(row.original.zone)}</p>;
            },
        },
        {
            Header: "Operation managers",
            accessor: "oms",
            Cell: ({ row }) => {
                return row.original.oms.map((user, index) => {
                    return (
                        <Badge
                            pill
                            key={index}
                            bg="primary"
                            className="text-center m-1"
                        >
                            {user?.full_name}
                        </Badge>
                    );
                });
            },
        },
        {
            Header: "Managing agents",
            accessor: "mas",
            Cell: ({ row }) => {
                return row.original.mas.map((user, index) => {
                    return (
                        <Badge
                            pill
                            key={index}
                            bg="primary"
                            className="text-center m-1"
                        >
                            {user?.full_name}
                        </Badge>
                    );
                });
            },
        },
    ];

    //
    // Functions
    //

    const getSchools = useCallback(
        async (param = null, canSetSchoolOptions = true) => {
            try {
                setIsLoadingActive(true);
                const response = await schoolApi.getSchools(false, param);

                if (response.status === 200) {
                    setSchools(response.data);
                    setIsLoadingActive(false);

                    const schoolsMapped = response.data.data.map(
                        (field, index) => {
                            return { label: field.name, value: field.id };
                        }
                    );

                    canSetSchoolOptions && setSchoolsMapped(schoolsMapped);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await schoolApi.getSchoolsConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const search = (data) => {
        let schoolSlugs = [];
        let oms = [];
        let mas = [];

        data?.schools && convertAsArrayParams(data.schools, schoolSlugs);
        data?.oms && convertAsArrayParams(data.oms, oms);
        data?.mas && convertAsArrayParams(data.mas, mas);

        const params = {
            schools: schoolSlugs,
            zone: data?.zone ? data.zone.value : "",
            oms: oms,
            mas: mas,
        };

        getSchools(params, false);
    };

    const convertAsArrayParams = (data, array) => {
        data.forEach((item) => {
            array.push(item?.value);
        });
    };

    const getUsers = async (roleId, callback) => {
        try {
           
            let params = {
                role_id: roleId,
                no_paginate: true
            };

            // Add zone to params if the user has the Term Contractor role
            if (hasRoleCollection.isTermContractor) {
                params.zone = user?.zone_in_charge[0]?.value;
            }
           
            const response = await usersApi.getUsers(params);


            if (response.status === 200) {
                let temp = response?.data.data.map((user) => {
                    return {
                        value: user?.id,
                        label: user?.full_name,
                        email: user?.email,
                    };
                });
                temp.sort((a, b) => a.label.localeCompare(b.label));
                callback(temp);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getSchools();
        getConfig();
    }, [getSchools, getConfig, rerender]);

    useEffect(() => {
        // Todo: make dynamic
        const omId = "97572b91-cd21-4aea-82ff-e1b8836d9521";
        const maId = "97572baa-ac84-480d-a622-92805d257dab";

        getUsers(omId, setOperationManagers);
        getUsers(maId, setManagingAgents);
    }, []);

    return (
        <RerenderContextUserOrganization.Provider
            value={[setRerender, rerender]}
        >
            <Helmet title="User organaztion" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">User organization</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <Row>
                                    <Col
                                        md={3}
                                        sm={12}
                                        xs={12}
                                        className="mb-2"
                                    >
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="schools"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className={`${
                                                        isMobile && "mt-2"
                                                    }`}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    options={schoolsMapped}
                                                    placeholder="Select school"
                                                    value={value}
                                                    isMulti
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        <Controller
                                            control={control}
                                            name="oms"
                                            defaultValue=""
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Select
                                                    classNamePrefix="react-select"
                                                    options={operationManagers}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    placeholder="Select operation manager"
                                                    value={value}
                                                    isMulti
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={4} className="mb-2">
                                        <Controller
                                            control={control}
                                            name="mas"
                                            defaultValue=""
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Select
                                                    classNamePrefix="react-select"
                                                    options={managingAgents}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    placeholder="Select managing agent"
                                                    value={value}
                                                    isMulti
                                                />
                                            )}
                                        />
                                    </Col>
                                    {hasRole("ADMIN") && (
                                        <Col
                                            md={4}
                                            sm={12}
                                            xs={12}
                                            className="mb-2"
                                        >
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="zone"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Select
                                                        isClearable={true}
                                                        onChange={onChange}
                                                        options={zones}
                                                        placeholder="Select zone"
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    )}

                                    <Col md={1} sm={12} xs={12}>
                                        <Button
                                            variant="primary"
                                            className={`me-1 form-box ${
                                                isMobile && "w-100"
                                            }`}
                                            onClick={handleSubmit(search)}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && schools.data && (
                            <DynamicTable
                                dataPagination={{
                                    data: schools.data,
                                    links: "",
                                    meta: "",
                                }}
                                columns={actionFields}
                                className="table-layout-fixed"
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </RerenderContextUserOrganization.Provider>
    );
};

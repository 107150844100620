import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import DatePicker from "react-datepicker";
import NotyfContext from "contexts/NotyfContext";
import Select from "react-select";
import DynamicTable from "components/ui/DynamicTable";
import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Container,
    Col,
    OverlayTrigger,
    Row,
    Tooltip,
    Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDollarSign,
    faDownload,
    faEject,
    faFilePdf,
    faFlagCheckered,
    faFolderOpen,
    faPen,
    faPlus,
    faRecycle,
    faSearch,
    faSpinner,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "hooks/useAuth";
import { DeleteModal } from "components/DeleteModal";
import { LoadingContext } from "App";
import { constructReport, formatConfigFields } from "utils/utilities";
import { PostApproval } from "components/common/works-order/modal/PostApproval";
import { PaymentStatusModal } from "../modal/PaymentStatusModal";
import { UpdateAttachmentsModal } from "components/common/works-order/modal/UpdateAttachmentsModal";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { status_options_claims } from "config";
import { AddRemarkModal } from "../modal/AddRemarkModal";
import { RejectClaimModal } from "../modal/RejectClaimModal";
import * as schoolApi from "@api/schoolApi";
import * as claimsApi from "@api/claimsApi";

export const RerenderContextClaim = createContext();

export const ClaimsList = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const location = useLocation();

    const { hasRole, user } = useAuth();

    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    const hasRoleCollection = {
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isQS: hasRole("MA-QS"),
        isAdmin: hasRole("ADMIN"),
        isInvoiceTeam: hasRole("INVOICE_TEAM"),
    };

    //
    // States
    //

    const [claims, setClaims] = useState([]);
    const [configColumn, setConfigColumn] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [invoiceInprogress, setInvoiceInprogress] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [searchValue, setSearchValue] = useState({
        reference_number: "",
        school: "",
        status: "",
        job: "",
        start_date: null,
        end_date: null,
    });
    const [schools, setSchools] = useState([]);

    const [modalSupportingDocument, setModalSupportingDocument] = useState({
        id: null,
        open: false,
        severity: "danger",
    });

    const [modalUpdateAttachments, setModalUpdateAttachments] = useState({
        id: null,
        open: false,
        severity: "danger",
        documents: [],
    });

    const [modalPaymentStatus, setModalPaymentStatus] = useState({
        id: null,
        open: false,
        severity: "danger",
        imte: {},
    });

    const [modalConfirmation, setModalConfirmation] = useState({
        notifMsg: "",
        open: false,
        api: "",
    });
    const [modalReject, setModalReject] = useState({
        id: null,
        open: false,
        severity: "danger",
    });

    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: "150px",
        Cell: ({ row, cell }) => {
            const maUserValidation = row.original?.pending_approval[user?.id];

            const canTcEdit =
                ["REJECTED", "DRAFT"].includes(row.original.status) &&
                hasRoleCollection.isTermContractor;

            const canTcSign =
                row.original.status === "PENDING" &&
                !row.original?.approvals?.MA?.status === "ENDORSED" &&
                hasRoleCollection.isTermContractor &&
                !maUserValidation;

            const canTcView =
                (row.original.status !== "REJECTED" &&
                    row.original.status !== "DRAFT" &&
                    row.original.status === "PENDING" &&
                    hasRoleCollection.isTermContractor) ||
                ["COMPLETED", "VOID"].includes(row.original.status);

            const canInvoiceView =
                (row.original.status !== "REJECTED" &&
                    row.original.status !== "DRAFT" &&
                    row.original.status === "PENDING" &&
                    hasRoleCollection.isInvoiceTeam) ||
                ["COMPLETED", "VOID"].includes(row.original.status);

            const canMaView =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isManagingAgent &&
                !maUserValidation;

            const canOMView =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isOM;

            const canMaSign =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isManagingAgent &&
                maUserValidation;

            const canAdminView =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isAdmin;

            const isApproved = row.original.status === "APPROVED";

            let downloadUrl = constructReport(
                row.original.file_info?.bucket,
                row.original.file_info?.file_path,
                `${row.original?.reference_number}-${row.original?.date}`,
                false
            );

            let previewUrl = constructReport(
                row.original.file_info?.bucket,
                row.original.file_info?.file_path,
                `${row.original?.reference_number}-${row.original?.date}`,
                true
            );

            return (
                <>
                    {(canTcView ||
                        canMaView ||
                        isApproved ||
                        canAdminView ||
                        canInvoiceView ||
                        canOMView) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View claim</Tooltip>}
                        >
                            <a
                                href={`${location.pathname}/${cell.row.original.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                                style={{
                                    color: "#3F80EA",
                                }}
                            >
                                View
                            </a>
                        </OverlayTrigger>
                    )}

                    {(canTcSign || canMaSign) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Sign claims</Tooltip>}
                        >
                            <a
                                href={`${location.pathname}/${cell.row.original.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faPen}
                                        className="align-middle me-1"
                                        size="lg"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                    )}
                    {canTcEdit && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit claim</Tooltip>}
                        >
                            <a
                                href={`${location.pathname}/${cell.row.original.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faPen}
                                        className="align-middle me-1"
                                        size="lg"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                    )}
                    {row.original.status === "DRAFT" &&
                        (hasRoleCollection.isTermContractor ||
                            hasRoleCollection.isAdmin) && (
                            <OverlayTrigger
                                overlay={<Tooltip>Delete claim</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            openDeleteModal(
                                                cell.row.original.id,
                                                "delete"
                                            );
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {row.original.file_info?.bucket &&
                        row.original.file_info?.file_path && (
                            <OverlayTrigger
                                overlay={<Tooltip>Download report</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        className="align-middle me-1"
                                        onClick={() => window.open(downloadUrl)}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {row.original.file_info?.bucket &&
                        row.original.file_info?.file_path && (
                            <OverlayTrigger
                                overlay={<Tooltip>Preview report</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        className="align-middle me-1"
                                        onClick={() => window.open(previewUrl)}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {hasRoleCollection.isInvoiceTeam &&
                        ["COMPLETED", "APPROVED"].includes(
                            row.original.status
                        ) && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip>Edit invoice details</Tooltip>
                                }
                            >
                                <a
                                    href={`${location.pathname}/${cell.row.original.id}/invoiceEdit`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link pe-1"
                                >
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            className="align-middle me-1"
                                            size="lg"
                                        />
                                    </span>
                                </a>
                            </OverlayTrigger>
                        )}
                    {hasRoleCollection.isInvoiceTeam &&
                        row?.original?.current_status ===
                            "PENDING_E_INVOICE" && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip>
                                        Update invoice to pending in progress
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            setInvoiceInprogress({
                                                id: cell.row.original.id,
                                                open: true,
                                                severity: "primary",
                                            });
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}

                    {hasRoleCollection.isInvoiceTeam &&
                        ["INVOICE_IN_PROGRESS", "E_INVOICE_REJECTED"].includes(
                            row?.original?.current_status
                        ) && (
                            <OverlayTrigger
                                overlay={<Tooltip>Post approval</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faFlagCheckered}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            setModalSupportingDocument({
                                                id: cell.row.original.id,
                                                open: true,
                                                severity: "primary",
                                            });
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}

                    {hasRoleCollection.isInvoiceTeam &&
                        row.original.status === "APPROVED" && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip>Update payment status</Tooltip>
                                }
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faDollarSign}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            setModalPaymentStatus({
                                                id: cell.row.original.id,
                                                open: true,
                                                severity: "primary",
                                                item: cell.row.original,
                                            });
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}

                    {Boolean(
                        Object.keys(row?.original?.documents?.tc).length &&
                            hasRoleCollection.isTermContractor &&
                            row.original.status !== "COMPLETED"
                    ) && (
                        <OverlayTrigger
                            overlay={<Tooltip>Document management</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faFolderOpen}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        setModalUpdateAttachments({
                                            id: cell.row.original.id,
                                            open: true,
                                            severity: "primary",
                                            documents: row?.original?.documents,
                                        });
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                    {(hasRoleCollection.isTermContractor ||
                        hasRoleCollection.isInvoiceTeam) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Regenerate report</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faRecycle}
                                    className="align-middle me-1"
                                    onClick={() =>
                                        setModalConfirmation({
                                            notifMsg:
                                                "Are you sure you want to generate this report?",
                                            subNotifMsg:
                                                "Note: Report will take an estimate of 3 minutes to be generated.",
                                            open: true,
                                            api: () =>
                                                regenerateReportApi(
                                                    cell?.row?.original?.id
                                                ),
                                        })
                                    }
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                    {["PENDING_MA", "PENDING_E_INVOICE"].includes(
                        row?.original?.current_status
                    ) &&
                        (hasRoleCollection.isTermContractor ||
                            hasRoleCollection.isAdmin) && (
                            <OverlayTrigger
                                overlay={<Tooltip>Reject claim</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faEject}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            setModalReject({
                                                id: cell?.row?.original.id,
                                                open: true,
                                                severity: "primary",
                                            });
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                </>
            );
        },
    };

    //
    // Functions
    //

    const search = () => {
        getClaims(searchValue);
    };

    const deleteClaim = async (id) => {
        try {
            const response = await claimsApi.deleteClaim(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: response.data.message,
                });
                setRerender(!rerender);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const getClaims = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);

                if (param) {
                    param = { ...param };

                    if (param?.status) {
                        const formattedStatus = formatStatus(param?.status);
                        param.status = formattedStatus;
                    }
                    param.start_date = param?.start_date
                        ? new Date(param?.start_date).toLocaleDateString(
                              "en-CA"
                          )
                        : "";
                    param.end_date = param?.end_date
                        ? new Date(param?.end_date).toLocaleDateString("en-CA")
                        : "";
                    param.invoice_start_date = param?.invoice_start_date
                        ? new Date(
                              param?.invoice_start_date
                          ).toLocaleDateString("en-CA")
                        : "";
                    param.invoice_end_date = param?.invoice_end_date
                        ? new Date(param?.invoice_end_date).toLocaleDateString(
                              "en-CA"
                          )
                        : "";
                }

                const response = await claimsApi.getClaims(param);

                if (response.status === 200) {
                    setClaims(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await claimsApi.getClaimsConfig(param);
                setConfigColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const regenerateReportApi = async (id) => {
        try {
            setIsLoadingActive(true);
            const response = await claimsApi.regenerateReport(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message:
                        "Successfully regenerated report. Please wait for 3 minutes.",
                });
                // CookieService.set(`report-${id}`, id, 180);
                setRerender(!rerender);
                setIsLoadingActive(false);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: error,
            });
        }
    };

    const formatStatus = (data) => {
        let statusValues = [];

        data?.forEach((status) => {
            statusValues.push(status?.value);
        });

        return statusValues;
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getClaims();
    }, [getClaims, rerender]);

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    useEffect(() => {
        if (hasRoleCollection.isOM && user?.school_id) {
            setSearchValue({
                ...searchValue,
                school: user?.school_id,
            });
        }
    }, []);

    return (
        <RerenderContextClaim.Provider value={[rerender, setRerender]}>
            <Helmet title="Claims" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Claims</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <Row>
                                    <Col md={3} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className={`form-box ${
                                                isMobile && "w-100"
                                            }`}
                                            placeholder="Reference number"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    reference_number:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Select
                                            isClearable={true}
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    school: data
                                                        ? data.value
                                                        : "",
                                                });
                                            }}
                                            options={schools}
                                            placeholder="Select school"
                                            className={`${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            defaultValue={
                                                hasRoleCollection.isOM &&
                                                user?.school_id
                                                    ? {
                                                          label: user?.school
                                                              ?.details
                                                              ?.school_name,
                                                          value: user?.school_id,
                                                      }
                                                    : ""
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Select
                                            isClearable={true}
                                            onChange={(data) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    status: data,
                                                })
                                            }
                                            options={status_options_claims}
                                            placeholder="Select status"
                                            className={`${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            isMulti
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className={`form-box ${
                                                isMobile && "mt-2"
                                            }`}
                                            placeholder="Search job"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    job: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={2} sm={12} xs={12}>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    start_date: data || "",
                                                });
                                            }}
                                            className={`form-box form-control mt-2 ${
                                                isMobile && "w-100"
                                            }`}
                                            placeholderText="Claim start date"
                                            selected={searchValue?.start_date}
                                        />
                                    </Col>
                                    <Col md={2} sm={12} xs={12}>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    end_date: data || "",
                                                });
                                            }}
                                            className={`form-box form-control mt-2 ${
                                                isMobile && "w-100"
                                            }`}
                                            placeholderText="Claim end date"
                                            selected={searchValue?.end_date}
                                        />
                                    </Col>
                                    <Col md={2} sm={12} xs={12}>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    invoice_start_date:
                                                        data || "",
                                                });
                                            }}
                                            className={`form-box form-control mt-2 ${
                                                isMobile && "w-100"
                                            }`}
                                            placeholderText="Invoice start date"
                                            selected={
                                                searchValue?.invoice_start_date
                                            }
                                        />
                                    </Col>
                                    <Col md={2} sm={12} xs={12}>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    invoice_end_date:
                                                        data || "",
                                                });
                                            }}
                                            className={`form-box form-control mt-2 ${
                                                isMobile && "w-100"
                                            }`}
                                            placeholderText="Invoice end date"
                                            selected={
                                                searchValue?.invoice_end_date
                                            }
                                        />
                                    </Col>
                                    <Col md={1} sm={12} xs={12}>
                                        <Button
                                            variant="primary"
                                            className={`me-1 form-box mt-2 ${
                                                isMobile && "w-100"
                                            }`}
                                            onClick={search}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                    {hasRoleCollection.isTermContractor && (
                                        <Col md={3} sm={12} xs={12}>
                                            <h1 className="h3 mb-3">
                                                <Button
                                                    variant="primary"
                                                    className={`float-end form-box mt-2 ${
                                                        isMobile && "w-100"
                                                    }`}
                                                    onClick={() =>
                                                        navigate(
                                                            `/works-order/claims/create`
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                    />
                                                </Button>
                                            </h1>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && claims?.data && (
                            <DynamicTable
                                dataPagination={claims}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                                searchValue={searchValue}
                                api={getClaims}
                                hasTopHorizontalScroll
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteClaim}
            />

            <PostApproval
                modalInfo={modalSupportingDocument}
                setModalInfo={setModalSupportingDocument}
                module="claims"
            />

            <PaymentStatusModal
                modalInfo={modalPaymentStatus}
                setModalInfo={setModalPaymentStatus}
            />
            {modalUpdateAttachments?.id && (
                <UpdateAttachmentsModal
                    modalInfo={modalUpdateAttachments}
                    setModalInfo={setModalUpdateAttachments}
                />
            )}
            <ConfirmationModal
                modalInfo={modalConfirmation}
                setModalInfo={setModalConfirmation}
            />

            <RejectClaimModal
                modalInfo={modalReject}
                setModalInfo={setModalReject}
            />

            <AddRemarkModal
                modalInfo={invoiceInprogress}
                setModalInfo={setInvoiceInprogress}
                api={deleteClaim}
                rerender={rerender}
                setRerender={setRerender}
            />
        </RerenderContextClaim.Provider>
    );
};

import React from "react";

import { Badge, Table } from "react-bootstrap";
import { moneyFormatter, toTitleCase } from "utils/utilities";

export const QuotationTable = ({ title, type, zone = null, data }) => {
    let pending = 0;
    let approved = 0;
    let completed = 0;
    let paid = 0;
    let rejected = 0;

    const status = {
        pending: "PENDING",
        approved: "APPROVED",
        completed: "COMPLETED",
        paid: "PAID",
        rejected: "REJECTED",
    };

    const typeSearchKey = type === "Zone" ? "school" : "date";

    return (
        <>
            <h6 className="h6 mb-3">
                {title}
                <Badge pill bg="primary" className="ms-1 me-1">
                    {type}
                </Badge>
                {zone && (
                    <Badge pill bg="secondary" className="me-1">
                        {zone}
                    </Badge>
                )}
            </h6>
            <Table bordered responsive className="table-layout-fixed">
                <thead>
                    <tr>
                        <th width="150">{type}</th>
                        <th className="text-end" width="150">
                            Pending
                        </th>
                        <th className="text-end" width="150">
                            Approved
                        </th>
                        <th className="text-end" width="150">
                            Completed
                        </th>
                        <th className="text-end" width="150">
                            Paid
                        </th>
                        <th className="text-end" width="150">
                            Rejected
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(data).map((value, key) => {
                        approved += +value?.approved;
                        completed += +value?.completed;
                        paid += +value?.paid;
                        pending += +value?.pending;
                        rejected += +value?.rejected;

                        return (
                            <tr key={key}>
                                <td>
                                    {type === "Month"
                                        ? value?.label
                                        : toTitleCase(Object.keys(data)[key])}
                                </td>
                                <td className="text-end currency">
                                    {parseFloat(value?.pending) ? (
                                        <a
                                            href={`/works-order/quotation-list?status=${
                                                status.pending
                                            }&${typeSearchKey}=${
                                                Object.keys(data)[key]
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {moneyFormatter(value?.pending)}
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td className="text-end currency">
                                    {parseFloat(value?.approved) ? (
                                        <a
                                            href={`/works-order/quotation-list?status=${
                                                status.approved
                                            }&${typeSearchKey}=${
                                                Object.keys(data)[key]
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {moneyFormatter(value?.approved)}
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td className="text-end currency">
                                    {parseFloat(value?.completed) ? (
                                        <a
                                            href={`/works-order/quotation-list?status=${
                                                status.completed
                                            }&${typeSearchKey}=${
                                                Object.keys(data)[key]
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {moneyFormatter(value?.completed)}
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td className="text-end currency">
                                    {parseFloat(value?.paid) ? (
                                        <a
                                            href={`/works-order/quotation-list?status=${
                                                status.paid
                                            }&${typeSearchKey}=${
                                                Object.keys(data)[key]
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {moneyFormatter(value?.paid)}
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td className="text-end currency">
                                    {parseFloat(value?.rejected) ? (
                                        <a
                                            href={`/works-order/quotation-list?status=${
                                                status.rejected
                                            }&${typeSearchKey}=${
                                                Object.keys(data)[key]
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {moneyFormatter(value?.rejected)}
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </td>
                            </tr>
                        );
                    })}

                    <tr className="text-end bg-warning text-black">
                        <td className="text-end">Total</td>
                        <td className="text-end currency">
                            {pending ? (
                                <a
                                    href={`/works-order/quotation-list?status=${status.pending}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link pe-1 text-dark"
                                >
                                    {moneyFormatter(pending)}
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>
                        <td className="text-end currency">
                            {approved ? (
                                <a
                                    href={`/works-order/quotation-list?status=${status.approved}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link pe-1 text-dark"
                                >
                                    {moneyFormatter(approved)}
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>
                        <td className="text-end currency">
                            {completed ? (
                                <a
                                    href={`/works-order/quotation-list?status=${status.completed}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link pe-1 text-dark"
                                >
                                    {moneyFormatter(completed)}
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>
                        <td className="text-end currency">
                            {paid ? (
                                <a
                                    href={`/works-order/quotation-list?status=${status.paid}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link pe-1 text-dark"
                                >
                                    {moneyFormatter(paid)}
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>
                        <td className="text-end currency">
                            {rejected ? (
                                <a
                                    href={`/works-order/quotation-list?status=${status.rejected}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link pe-1 text-dark"
                                >
                                    {moneyFormatter(rejected)}
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
};

import api from "../utils/api";

export const getSchools = (includeAllZone = false, data) => {
    return api.get(`/school?all=1&include_all=${includeAllZone}`, {
        params: data,
    });
};

export const getSchool = (id) => {
    return api.get("/school/" + id);
};

export const getSchoolGroupType = () => {
    return api.get("/school/group-by-type", {});
};

export const getSchoolsConfig = (id, data) => {
    return api.get(`/config/schools`, data);
};

export const getSchoolContract = (id) => {
    return api.get(`/school-contracts/${id}`);
};

export const updateSchoolContract = (data) => {
    return api.post(`/school-contracts/update`, data);
};

export const getSchoolUserOrganization = (id) => {
    return api.get(`/school/${id}/user-organization`);
};

export const getAssignmentsBySchoolId = (id) => {
    return api.get(`/school-assignments/${id}/get`);
};

export const updateSchoolAssignment = (id, data) => {
    return api.post(`/school-assignments/${id}`, data);
};

export const deleteSchoolAssignment = (id) => {
    return api.delete(`/school-assignments/${id}`);
};

export const createTreeInspectionPhoto = (data) => {
    return api.post(`/school/tree-inspection-photo`, data);
};

export const getTreeInspectionPhoto = (id) => {
    return api.get(`/school/tree-inspection-photo/` + id);
};

export const deleteTreeInspectionPhoto = (id) => {
    return api.delete(`/school/tree-inspection-photo/` + id);
};
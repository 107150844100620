import React, { createContext, useState } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { AuthProvider } from "./contexts/JWTContext";

import { registerLicense } from "@syncfusion/ej2-base";

import "./assets/custom-style.css";
import "./assets/responsive.css";

import LoadingOverlay from "react-loading-overlay";

// Registering Syncfusion license key
registerLicense(
    "ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkxhUH1cdXdURGdVUUI="
);

export const LoadingContext = createContext();

const App = () => {
    const content = useRoutes(routes);
    const [isLoadingActive, setIsLoadingActive] = useState(false);

    LoadingOverlay.propTypes = undefined;

    return (
        <HelmetProvider>
            <Helmet
                titleTemplate="%s | Ministry of Education"
                defaultTitle="Ministry of Education | Singapore"
            />
            <Provider store={store}>
                <LoadingContext.Provider
                    value={[isLoadingActive, setIsLoadingActive]}
                >
                    <LoadingOverlay active={isLoadingActive} spinner>
                        <ThemeProvider>
                            <SettingsProvider>
                                <SidebarProvider>
                                    <LayoutProvider>
                                        <AuthProvider>{content}</AuthProvider>
                                    </LayoutProvider>
                                </SidebarProvider>
                            </SettingsProvider>
                        </ThemeProvider>
                    </LoadingOverlay>
                </LoadingContext.Provider>
            </Provider>
        </HelmetProvider>
    );
};

export default App;

import { Form } from "react-bootstrap";
import { roundToTwoDecimal } from "utils/utilities";

export const TableInput = (props) => {
  const { data, column, row, cell, updateData, canViewRow } = props;

  const onChange = (value) => updateData(data, row.index, column.id, value);

  if (row.depth === 0) {
    const totalRowAmount = parseFloat(
      roundToTwoDecimal(row.original.rate * row.original.quantity)
    ).toFixed(2);

    const value =
      row.original.minimum && totalRowAmount < parseInt(row.original.minimum)
        ? "Minimum"
        : cell.value;

    return (
      <>
        {data[row.index]["view"] || canViewRow ? (
          <p
            style={{
              textAlign: "right",
            }}
          >
            {value}
          </p>
        ) : (
          <>
            <Form.Control
              // min={0}
              onChange={(e) => {
                // const min = 0;
                let value = e.target.value;

                // if (value < min) value = min;

                onChange(value);
              }}
              type="number"
              value={cell.value || ""}
              // style={{
              //     textAlign: "right",
              // }}
              step=".01"
              autoFocus={row.original.is_focus}
            />
            {Boolean(row.original.minimum) && (
              <>
                <br />
                <small className="text-warning mt-2">
                  Minimum charge is S$ {row.original.minimum}
                </small>
              </>
            )}
          </>
        )}
      </>
    );
  } else if (row.depth === 1) {
    return <div className="text-end">{cell.value || ""}</div>;
  }
};

import moment from 'moment';

export const prettyHoursMinutes = ($minutes) => {
    return Math.floor($minutes / 60) + ' Hours ' + ($minutes % 60) + ' Minutes';
};

export const dateTomorrow = () => {
    const today = new Date();
    const tomorrow = new Date();

    return tomorrow.setDate(today.getDate() + 1);
};

export const toMMMMY = (date) => {
    if (!date) return;
    return moment(date).format('MMMM y');
};
export const toYMD = (date) => {
    if (!date) return;
    return moment(date).format('YYYY-MM-DD');
};

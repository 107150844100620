import React from "react";

import DatePicker from "react-datepicker";
import Select from "react-select";
import { ErrorMessage } from "@hookform/error-message";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { Controller, useWatch } from "react-hook-form";

import useAuth from "hooks/useAuth";
import { errorMessage } from "utils/utilities";
import { zone_codes } from "config";

export const ClaimsDetailsForm = ({
    control,
    errors,
    fundType,
    view,
    schools,
    schoolsOriginal,
    jobType,
    populateOMInfo,
}) => {
    const { user, config } = useAuth();

    const zone = useWatch({
        control,
        name: "zone",
    });

    const optionsFund = [
        {
            label: "School",
            value: "SCHOOL",
        },
        {
            label: "MOE",
            value: "MOE",
        },
        {
            label: "95-5",
            value: "95-5",
        },
    ];

    const job_type_options = config.quotation_job_type;

    //
    // Conditions
    //

    const isProjectWork =
        jobType && jobType.some((obj) => obj.value === "project_work");

    const canSelectSchool =
        fundType?.value === "SCHOOL" ||
        fundType === "SCHOOL" ||
        ((fundType?.value === "MOE" ||
            fundType === "MOE" ||
            fundType?.value === "95-5" ||
            fundType === "95-5") &&
            !isProjectWork);

    const canZone =
        fundType?.value === "MOE" ||
        fundType?.value === "95-5" ||
        fundType === "MOE" ||
        fundType === "95-5";

    return (
        <>
            <Row>
                <Col md={9}>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Type of fund
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="fund_type"
                                    render={({
                                        field: { value, onChange, onBlur, ref },
                                    }) => (
                                        <>
                                            {view &&
                                            value &&
                                            optionsFund.length ? (
                                                <p>
                                                    {
                                                        optionsFund.find(
                                                            (o) =>
                                                                o.value ===
                                                                value
                                                        )?.label
                                                    }
                                                </p>
                                            ) : (
                                                <Select
                                                    isClearable={true}
                                                    inputRef={ref}
                                                    options={optionsFund}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="fund_type"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Trade
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="trade"
                                    render={({
                                        field: { value, onChange, ref },
                                    }) => (
                                        <>
                                            {view && value ? (
                                                <p>
                                                    {user?.trade?.find(
                                                        (o) => o.value === value
                                                    )?.label || "-"}
                                                </p>
                                            ) : (
                                                <Select
                                                    isClearable={true}
                                                    inputRef={ref}
                                                    onChange={onChange}
                                                    options={user?.trade}
                                                    value={value}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="trade"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Job type
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="job_type"
                                    defaultValue=""
                                    render={({
                                        field: { value, onChange, ref },
                                    }) => (
                                        <>
                                            {view && value?.length ? (
                                                value?.map((element, key) => {
                                                    return (
                                                        <div key={key}>
                                                            <Badge
                                                                pill
                                                                bg="secondary"
                                                                className="me-2"
                                                            >
                                                                {element.label}
                                                            </Badge>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <Select
                                                    className={`react-select-container ${
                                                        errors.job_type &&
                                                        "is-invalid"
                                                    }`}
                                                    classNamePrefix="react-select"
                                                    isClearable={true}
                                                    inputRef={ref}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    onChange={onChange}
                                                    onBlur={(event) =>
                                                        event.preventDefault()
                                                    }
                                                    options={job_type_options}
                                                    value={value}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="job_type"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            {canSelectSchool && (
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        School
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="school"
                                        render={({
                                            field: { value, ref, onChange },
                                        }) => (
                                            <>
                                                {view &&
                                                value &&
                                                schools?.length ? (
                                                    <p>
                                                        {
                                                            schools?.find(
                                                                (o) =>
                                                                    o.value ===
                                                                    value
                                                            )?.label
                                                        }
                                                    </p>
                                                ) : (
                                                    <Select
                                                        isClearable
                                                        inputRef={ref}
                                                        onChange={(e) => {
                                                            e &&
                                                                populateOMInfo(
                                                                    schoolsOriginal?.find(
                                                                        (o) =>
                                                                            o.id ===
                                                                            e.value
                                                                    )
                                                                );
                                                            onChange(e);
                                                        }}
                                                        options={schools}
                                                        value={value}
                                                    />
                                                )}
                                            </>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="school"
                                        render={({ message }) =>
                                            errorMessage(message)
                                        }
                                    />
                                </Form.Group>
                            )}
                        </Col>

                        {canZone && (
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Zone
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="zone"
                                        defaultValue=""
                                        render={({
                                            field: { value, ref, onChange },
                                        }) => (
                                            <>
                                                {view && value ? (
                                                    <p>
                                                        {value
                                                            ? value.label
                                                            : "-"}
                                                    </p>
                                                ) : (
                                                    <Select
                                                        isClearable={true}
                                                        inputRef={ref}
                                                        onChange={onChange}
                                                        options={
                                                            user?.zone_in_charge
                                                        }
                                                        value={value}
                                                    />
                                                )}
                                            </>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="zone"
                                        render={({ message }) =>
                                            errorMessage(message)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        )}

                        {zone && canZone && (
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Zone code
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="zone_code"
                                        defaultValue=""
                                        render={({
                                            field: { value, ref, onChange },
                                        }) => {
                                            const zoneCodeOption = zone_codes(
                                                zone.value
                                            );

                                            return (
                                                <>
                                                    {view && value ? (
                                                        <p>
                                                            {value
                                                                ? value.label
                                                                : "-"}
                                                        </p>
                                                    ) : (
                                                        <Select
                                                            isClearable={true}
                                                            inputRef={ref}
                                                            onChange={onChange}
                                                            options={
                                                                zoneCodeOption
                                                            }
                                                            value={value}
                                                        />
                                                    )}
                                                </>
                                            );
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="zone_code"
                                        render={({ message }) =>
                                            errorMessage(message)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        )}
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Attention
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="contacts.attention"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            {view && value ? (
                                                <p>{value} </p>
                                            ) : (
                                                <Form.Control
                                                    type="text"
                                                    value={value || ""}
                                                    onChange={onChange}
                                                    className={`form-box ${
                                                        errors?.contacts
                                                            ?.attention &&
                                                        "is-invalid"
                                                    }`}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.attention"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    cc
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="contacts.cc"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>{value || "-"}</p>
                                            ) : (
                                                <Form.Control
                                                    type="text"
                                                    value={value || ""}
                                                    onChange={onChange}
                                                    className={`form-box ${
                                                        errors?.contacts?.cc &&
                                                        "is-invalid"
                                                    }`}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.cc"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Telephone
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="contacts.tel"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            {view && value ? (
                                                <p>{value} </p>
                                            ) : (
                                                <Form.Control
                                                    type="text"
                                                    value={value || ""}
                                                    onChange={onChange}
                                                    className={`form-box ${
                                                        errors?.contacts?.tel &&
                                                        "is-invalid"
                                                    }`}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.tel"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Fax
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="contacts.fax"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>{value || "-"}</p>
                                            ) : (
                                                <Form.Control
                                                    type="text"
                                                    value={value || ""}
                                                    onChange={onChange}
                                                    className={`form-box ${
                                                        errors?.contacts?.fax &&
                                                        "is-invalid"
                                                    }`}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.fax"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Claim date
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="claim_date"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            {view && value ? (
                                                <p>
                                                    {new Date(
                                                        value
                                                    ).toLocaleDateString(
                                                        "en-CA"
                                                    )}
                                                </p>
                                            ) : (
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={value}
                                                    onChange={onChange}
                                                    className={`form-box form-control ${
                                                        errors?.claim_date &&
                                                        "is-invalid"
                                                    }`}
                                                    value={value}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="claim_date"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="contacts.location"
                                    className="font-weight-bold"
                                >
                                    Location
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="contacts.location"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            {view && value ? (
                                                <p>{value} </p>
                                            ) : (
                                                <AutoGrowTextarea
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    fieldValue={value}
                                                    errors={errors?.location}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.location"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="job"
                                    className="font-weight-bold"
                                >
                                    Job
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="job"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            {view && value ? (
                                                <p>{value} </p>
                                            ) : (
                                                <AutoGrowTextarea
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    fieldValue={value}
                                                    errors={errors.job}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="job"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Date of site measurement
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="date_of_site_measurement"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            {view && value ? (
                                                <p>
                                                    {new Date(
                                                        value
                                                    ).toLocaleDateString(
                                                        "en-CA"
                                                    )}
                                                </p>
                                            ) : (
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={value}
                                                    onChange={onChange}
                                                    value={value}
                                                    className={`form-box form-control ${
                                                        errors?.date_of_site_measurement &&
                                                        "is-invalid"
                                                    }`}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="date_of_site_measurement"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

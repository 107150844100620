import React, { useCallback, useContext, useEffect, useState } from "react";

import Select from "react-select";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSearch } from "@fortawesome/free-solid-svg-icons";

import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import { DefectTable } from "./Components/DefectTable";
import { Tabs } from "./Components/Tabs";
import { UnderConstruction } from "components/UnderConstruction";
import { months, years } from "config";
import { LoadingContext } from "App";
import * as dashboardApi from "@api/dashboardApi";
import * as schoolApi from "@api/schoolApi";

export const DashboardDefect = () => {
    const notyf = useContext(NotyfContext);
    const { tab } = useParams();
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
    });

    const { hasRole, user } = useAuth();

    //
    // States
    //

    const [defects, setDefects] = useState({});
    const [schools, setSchools] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    //
    // Functions
    //

    const getDefect = useCallback(
        async (param = {}) => {
            try {
                setIsLoadingActive(true);

                param.inspection_category = tab;

                const response = await dashboardApi.getDefects(param);

                if (Boolean(param?.is_export)) {
                    setIsLoadingActive(false);
                    return window.open(response.data);
                }
                const data = response.data.data;

                setDefects(data);

                if (param) {
                    const getLastDayOfMonth = daysInMonth(
                        param.month,
                        param.year
                    );

                    setStartDate(`${param.month}-01-${param.year}`);
                    setEndDate(
                        `${param.month}-${getLastDayOfMonth}-${param.year}`
                    );

                    reset({
                        mo: months?.find(
                            (o) => parseInt(o?.value) === parseInt(data.month)
                        ),
                        yr: years?.find(
                            (o) =>
                                parseInt(o?.value) ===
                                parseInt(new Date().getFullYear())
                        ),
                    });
                } else {
                    const getLastDayOfMonth = daysInMonth(
                        data.month,
                        new Date().getFullYear()
                    );

                    setStartDate(
                        `${data.month}-01-${new Date().getFullYear()}`
                    );
                    setEndDate(
                        `${
                            data.month
                        }-${getLastDayOfMonth}-${new Date().getFullYear()}`
                    );

                    reset({
                        mo: months?.find(
                            (o) => parseInt(o?.value) === parseInt(data.month)
                        ),
                        yr: years?.find(
                            (o) =>
                                parseInt(o?.value) ===
                                parseInt(new Date().getFullYear())
                        ),
                        school:
                            hasRole("OPERATIONS-MANAGER") && user?.school_id
                                ? {
                                      label: user?.school?.details?.school_name,
                                      value: user?.school_id,
                                  }
                                : "",
                    });
                }

                setIsLoadingActive(false);
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const lookUpType = (type) => {
        const lookup = {
            "building-maintenance": "Building maintenance",
            "safety-audit-inspection": "Safety audit inspection",
            "turfing-and-planting-maintenance":
                "Turfing and planting maintenance",
        };

        return lookup[type];
    };

    const search = (data, isExport = false) => {
        const searchValue = {
            school_id: data.school ? data.school.value : "",
            month: data.mo
                ? months?.find((o) => o?.value === data.mo.value)?.value
                : "",
            year: data.yr
                ? years?.find((o) => o?.value === data.yr.value)?.value
                : "",
            is_export: isExport,
        };

        getDefect(searchValue);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getDefect();
        getSchools();
    }, []);

    return (
        <>
            <React.Fragment>
                <Helmet title="Dashboard - Defect" />
                <Container fluid className="p-0">
                    <h4 className="h4">Dashboard - Defect</h4>
                    <Card>
                        <Card.Body>
                            <Tabs module="defect" />
                            <hr />

                            {defects?.schools &&
                            Object.keys(defects?.schools).length ? (
                                <>
                                    <h5 className="mb-4">{lookUpType(tab)}</h5>
                                    <Row className="mb-4">
                                        <Col md={12} sm={10} xs={10}>
                                            <Row>
                                                <Col
                                                    md={3}
                                                    sm={12}
                                                    xs={12}
                                                    className="mb-2"
                                                >
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="school"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                                ref,
                                                            },
                                                        }) => (
                                                            <Select
                                                                inputRef={ref}
                                                                classNamePrefix="react-select"
                                                                options={
                                                                    schools
                                                                }
                                                                isSearchable={
                                                                    true
                                                                }
                                                                isClearable={
                                                                    true
                                                                }
                                                                value={value}
                                                                onChange={
                                                                    onChange
                                                                }
                                                                placeholder="Select school"
                                                            />
                                                        )}
                                                    />
                                                </Col>

                                                <Col md={3} sm={12} xs={12}>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="mo"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <Select
                                                                className="is-invalid react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={months}
                                                                isSearchable={
                                                                    true
                                                                }
                                                                isClearable={
                                                                    true
                                                                }
                                                                isMulti={false}
                                                                value={value}
                                                                onChange={
                                                                    onChange
                                                                }
                                                                menuPlacement="bottom"
                                                                placeholder="Select month"
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                                <Col md={3} sm={12} xs={12}>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="yr"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <Select
                                                                className="is-invalid react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={years}
                                                                isSearchable={
                                                                    true
                                                                }
                                                                isClearable={
                                                                    true
                                                                }
                                                                isMulti={false}
                                                                value={value}
                                                                onChange={
                                                                    onChange
                                                                }
                                                                menuPlacement="bottom"
                                                                placeholder="Select year"
                                                            />
                                                        )}
                                                    />
                                                </Col>

                                                <Col md={3} sm={2} xs={2}>
                                                    <Button
                                                        variant="primary"
                                                        className="me-1 mb-1 form-box"
                                                        onClick={handleSubmit(
                                                            (data) =>
                                                                search(
                                                                    data,
                                                                    false
                                                                )
                                                        )}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faSearch}
                                                        />
                                                    </Button>
                                                    <Button
                                                        variant="primary"
                                                        className="me-1 mb-1 form-box"
                                                        onClick={handleSubmit(
                                                            (data) =>
                                                                search(
                                                                    data,
                                                                    true
                                                                )
                                                        )}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faDownload}
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <DefectTable
                                        defects={defects.schools}
                                        type={tab.toLocaleLowerCase()}
                                        start_date={startDate}
                                        end_date={endDate}
                                    />
                                </>
                            ) : (
                                <UnderConstruction />
                            )}
                        </Card.Body>
                    </Card>
                </Container>
            </React.Fragment>
        </>
    );
};

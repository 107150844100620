import React, { useState, useContext, useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";
import { SchoolInformation } from "../components/SchoolInformation";
import * as schoolApi from "@api/schoolApi";
import NotyfContext from "contexts/NotyfContext";
import { DeleteModal } from "../../../../components/DeleteModal";

export const SchoolUpload = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const notyf = useContext(NotyfContext);

    //
    // States
    //
    const [upload, setUpload] = useState([]);
    const [document, setDocument] = useState([])
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [school, setSchool] = useState([]);
    const { handleSubmit } = useForm({
        mode: "onTouched",
    });

    let allowedExtensions = '.jpg,.png,.jpeg,';

    //
    // Functions
    //

    const schoolsApiCallUpload = async (data) => 
    {
        const filesData = upload.getFilesData();
       
        if (!filesData.length) {
            notyf.open({
                type: "warning",
                message: "Please upload a file to submit.",
            });
            return;
        }
        try {
             data = filesData;
            //  data.school_id = action;
             let file = filesData[0];
            //  file.school_id = action;
             const fd = new FormData();
             fd.append("school[]", JSON.stringify(data));
             fd.append("file[]", file.rawFile);
             fd.append("school_id", action);

             const response = await schoolApi.createTreeInspectionPhoto(fd);
           
            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully ${
                        file ? "uploaded" : "updated"
                    } tree inspection photo`,
                });

                // Refresh the preview after a successful upload
                await getTreeInspectionPhoto();
            } 
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong. Invalid File Type",
            });
        }
    };

    const getSchool = async () => {
        try {
            const response = await schoolApi.getSchool(action);

            setSchool(response.data.data);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getMimeType = (fileName) => {
        const extensionToMimeType = {
          '.png': 'image/png',
          '.jpg': 'image/jpeg',
          '.jpeg': 'image/jpeg',
        };
      
        const fileExtension = fileName.toLowerCase().match(/\.[0-9a-z]+$/);
      
        if (fileExtension && extensionToMimeType[fileExtension[0]]) {
          return extensionToMimeType[fileExtension[0]];
        } 
      };

    const getTreeInspectionPhoto = async () => {
        try {
            const response = await schoolApi.getTreeInspectionPhoto(action);

            if(!response.data.length > 0) {return}
            
            response.data[0].file_name = getFileName(response.data[0].photo_url);
            response.data[0].mime = getMimeType(response.data[0].photo_url);
            response.data[0].full_path = response.data[0].photo_url;

            setDocument(response.data);
           
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error,
            });
        }
    };

    const handleApiCall = async () => {
        try {
            const response = await schoolApi.deleteTreeInspectionPhoto(action);
            
            if(response.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully deleted tree inspection photo`,
                });
                setUpload([]);
                setDocument([]);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error,
            });
        }
    
       
      };

      const getFileName = (filePath) => {
        const pathSegments = filePath.split('/');
        const fileNameWithExtension = pathSegments.pop(); // Get the last segment
      
        // Check if the file name contains a timestamp
        if (/_\d+/.test(fileNameWithExtension)) {
          // Remove the timestamp part from the file name
          return fileNameWithExtension.replace(/_\d+/, '');
        }
      
        // If there is no timestamp, return the original file name
        return fileNameWithExtension;
      };

    //
    // UseEffects
    //

    useEffect(() => {
        getSchool(); getTreeInspectionPhoto ();
    }, [action]);

    return (
        <React.Fragment>
            <Helmet title={`File upload`} />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">File upload</h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate("/settings/schools")}
                            >
                                Schools
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                File upload
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <SchoolInformation school={school} />
                        <Form>
                            <Row>
                                <Col md={12}>
                                    <Form.Label className="font-weight-bold">
                                        Tree inspection photo
                                    </Form.Label>
                                    <FileUploaderSyncfusion
                                        action={action}
                                        document={document}
                                        canDelete={true}
                                        canUpload={true}
                                        multiple={false}
                                        setModalInfo={setModalInfo}
                                        setUpload={setUpload}
                                        loadedFileLabel="Uploaded tree inspection photo"
                                        labelBold
                                        allowedExtensions={allowedExtensions}
                                    />
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                <Col md={12} className="text-center">
                                    <Button
                                        className="me-2"
                                        variant="primary"
                                        type="submit"
                                        onClick={handleSubmit(
                                            schoolsApiCallUpload
                                        )}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate("/settings/schools")
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <DeleteModal modalInfo={modalInfo} setModalInfo={setModalInfo} 
           api={handleApiCall}
            />
        </React.Fragment>
    );
};

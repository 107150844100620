import api from "../utils/api";

export const getChecklists = (data) => {
    return api.get("/checklist", { params: data });
};

export const getChecklist = (data) => {
    return api.get(`/checklist/${data}`);
};

export const getChecklistConfig = (data) => {
    return api.get(`/config/checklist-bm-tp`, data);
};

import React, { useCallback, useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Container, Row, Button, Col, Breadcrumb } from "react-bootstrap";
import { Card, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import Select from "react-select";
import useAuth from "hooks/useAuth";
import { months, years, zones } from "config";
import { kpi_type } from "config";
import * as kpiApi from "@api/kpiApi";
import * as schoolApi from "@api/schoolApi";

export const KPIForm = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const create = action === "create";
    const notyf = useContext(NotyfContext);
    const { user } = useAuth();

    //
    // States
    //

    const [schoolsOriginal, setSchoolsOriginal] = useState([]);
    const [schools, setSchools] = useState([]);
    const schema = yup.object().shape({
        month: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        year: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        type: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        total_percentage: yup
            .string()
            .required("This field is required")
            .nullable(),
        zone: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const kpiApiCall = async (data) => {
        data.month = data.month.value;
        data.year = data.year.value;
        data.type = data.type.value;
        data.zone = data.zone.value;

        try {
            create
                ? await kpiApi.createKpi(data)
                : await kpiApi.updateKpi(action, data);

            notyf.open({
                type: "success",
                message: `Successfully ${create ? "created" : "updated"} KPI`,
            });
            navigate(`/cop/kpi`);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const schoolsMapped = response.data.data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchoolsOriginal(response.data.data);
            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    //
    // UseEffects
    //

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    useEffect(() => {
        const getKpi = async () => {
            try {
                const response = await kpiApi.getKpi(action);
                if (response.status === 200) {
                    const data = response.data.data;

                    reset({
                        zone: zones?.find((o) => o?.value === data.zone),
                        type: kpi_type?.find((o) => o?.value === data.type),
                        month: months?.find(
                            (o) => o?.value === data.month.toString()
                        ),
                        year: years?.find(
                            (o) => o?.value === data.year.toString()
                        ),
                        total_percentage: data.total_percentage,
                    });
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        };

        if (action !== "create" && schools.length) {
            getKpi();
        }
    }, [action, schools]);

    return (
        <React.Fragment>
            <Helmet title={`${create ? "Create" : "Edit"} KPI`} />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">
                            {create ? "Create" : "Edit"} KPI
                        </h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate("/cop/kpi")}
                            >
                                KPI
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {create ? "Create" : "Edit"}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Zone
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="zone"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={user?.zone_in_charge}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="top"
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="zone"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Type
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="type"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={kpi_type}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="top"
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="type"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Month
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="month"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select"
                                                options={months}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="bottom"
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="month"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Year
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="year"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select"
                                                options={years}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="bottom"
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="year"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Total percentage
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="total_percentage"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Form.Control
                                                onChange={onChange}
                                                value={value}
                                                type="number"
                                                className={
                                                    errors.header &&
                                                    "is-invalid"
                                                }
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="total_percentage"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col md={12} className="text-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    type="submit"
                                    onClick={handleSubmit(kpiApiCall)}
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={() => navigate(`/cop/kpi`)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

import React, { useState, useContext } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useCallback } from "react";

import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import NotyfContext from "contexts/NotyfContext";
import { LoadingContext } from "App";

import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import ListGroup from "react-bootstrap/ListGroup";

import { SignaturesForm } from "components/ui/SignatureForm";
import * as inspectionChecklistApi from "@api/inspectionChecklistApi";
import useAuth from "../../../hooks/useAuth";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { constructReport, errorMessage } from "utils/utilities";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { scrollToTop } from "utils/utilities";
import moment from "moment";
import * as yup from "yup";

export const InspectionChecklistForm = ({ status = false }) => {
  const baseUrlApi = "https://moe-ecms-api.intellisolution.tech";
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  // eslint-disable-next-line
  const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
  const { id } = useParams();
  const [inspectionChecklist, setInspectionChecklist] = useState([]);
  const { hasRole } = useAuth();
  const [modalConfirmation, setModalConfirmation] = useState({
    notifMsg: "",
    open: false,
    api: "",
  });
  let schema = yup.object().shape({
    inspected_at: yup.string().required("This field is required"),
  });
  const [validation, setValidation] = useState([]);

  const [isMobile, setIsMobile] = useState(false);
  const [rawInspectedAt, setRawInspectedAt] = useState("");
  const [defaultActiveKeys, setDefaultActiveKeys] = useState([]);
  const { state } = useLocation();
  let minTime = new Date();
  let maxTime = new Date();
  // if south zone
  let miT = minTime.setHours(17, 0, 0);
  let maT = maxTime.setHours(18, 0, 0);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const hasRoleCollection = {
    isManagingAgent: hasRole("MANAGING-AGENT"),
    isOM: hasRole("OPERATIONS-MANAGER"),
    isSCD: hasRole("SCD"),
    isTechnian: hasRole("TECHNICIAN"),
    isTermContractor: hasRole("TERM-CONTRACTOR"),
    isTermContractorQs: hasRole("TERM-CONTRACTOR-QS"),
    isAdmin: hasRole("ADMIN"),
    isHorticulturist: hasRole("HORTICULTURIST"),
    isArboristSouth: hasRole("ARBORIST-SOUTH"),
    isArboristNorthEast: hasRole("ARBORIST-NORTH-EAST"),
  };

  const canSubmitInspection =
    hasRoleCollection?.isTermContractor ||
    hasRoleCollection?.isTermContractorQs ||
    hasRoleCollection?.isTechnian ||
    hasRoleCollection?.isAdmin ||
    hasRoleCollection?.isHorticulturist ||
    hasRoleCollection?.isArboristSouth ||
    hasRoleCollection?.isArboristNorthEast
      ? true
      : false;

  const isEditableInspectionStatus = (status) => {
    const editableStatuses = ["DRAFT", "REJECTED"];

    return editableStatuses.includes(status);
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const updateInspectionSchedule = async (data, index, index2) => {
    try {
      const finalParams = {};
      finalParams.checklist_items = [
        index2 !== undefined
          ? data?.checklist_items[index]?.checklist_sub_items[index2]
          : data?.checklist_items[index],
      ];
      finalParams.checklist_id = data?.checklist_id;
      finalParams.remarks = data?.remarks;
      const response = await inspectionChecklistApi.updateInspectionChecklist(
        id,
        finalParams
      );

      if (response.status === 200) {
        //   notyf.open({
        //     type: "success",
        //     message: "Checklist successfully updated",
        //   });
      }
    } catch (error) {
      notyf.open({
        type: "danger",
        message: "Something went wrong with the server",
      });
    }
  };

  const saveTech = async (data, status) => {
    if (status !== "DRAFT") {
      if (!data.prepare_details.signature && status !== "REJECTED") {
        notyf.open({
          type: "warning",
          message: "Please provide signature to proceed.",
        });

        return;
      }
    }

    save(data, status, data.prepare_details);
  };

  const saveOm = async (data, status) => {
    if (status !== "DRAFT") {
        if (!data?.om_details?.signature && status !== "REJECTED") {
            notyf.open({
                type: "warning",
                message: "Please provide signature to proceed.",
            });

            return;
        }
    }

    save(data, status, data.om_details);
  };

  const saveMa = async (data, status) => {
    if (status !== "DRAFT") {
      if (!data?.ma_details?.signature && status !== "REJECTED") {
        notyf.open({
          type: "warning",
          message: "Please provide signature to proceed.",
        });

        return;
      }
    }

    save(data, status, data.ma_details);
  };

  const save = async (data, status, signature_data = null) => {
    try {
      setIsLoadingActive(true);

      let params = {};
      let error = false;

      if (status === "REJECTED" && data.remarks === "") {
        error = true;
        setError("remarks", {
          type: "custom",
          message: "Remarks is required.",
        });

        setIsLoadingActive(false);
      }

      if (!error) {
        if (signature_data !== null) {
          params.signature = signature_data.signature;
          params.signature_details = signature_data.signature_details;
        }

        params.remarks = data.remarks;
        params.status = status;

        if (status === "DRAFT" || status === "SUBMITTED") {
          if (getValues("check_out") != null) {
            params.check_out = new Date(getValues("check_out")).toISOString();
          } else {
            params.check_out = null;
          }
        }

        params.inspected_at = hasRoleCollection.isManagingAgent
          ? rawInspectedAt
          : new Date(getValues("inspected_at")).toLocaleDateString("en-CA");
        params.school_slug = data.school_slug;
        params.frequency = data.frequency;

        const response = await inspectionChecklistApi.updateInspectionChecklist(
          id,
          params
        );

        if (response.status === 200 && response.data.status === "VALIDATION") {
            setIsLoadingActive(false);
            let information = {};
            
            const validationArray = Object.values(response.data.data);
            information.items = validationArray;
            information.title = validationTitle(inspectionChecklist.frequency);

            setValidation(information);

            notyf.open({
                type: "warning",
                message: "Please complete checklist items",
              });
            scrollToTop();
            
            return;
        }

        if (response.status === 200) {
          setIsLoadingActive(false);
          notyf.open({
            type: "success",
            message: "Checklist successfully updated",
          });

          navigate(`/inspection-checklist/inspection-servicing`, {
            state: {
              previousSearchValue: state?.previousSearchValue,
            },
          });
        }
      }
    } catch (error) {
      notyf.open({
        type: "danger",
        message: error?.response?.data?.message,
      });
      setIsLoadingActive(false);
    }
  };

  const getInspectionChecklist = useCallback(async () => {
    try {
      setIsLoadingActive(true);
      const response = await inspectionChecklistApi.getInspectionChecklist(id);

      if (response.status === 200) {
        setIsLoadingActive(false);
        const data = response?.data?.data;

        setRawInspectedAt(data.inspected_at);

        data.inspected_at =
          response.data.data.inspected_at !== null
            ? new Date(response.data.data.inspected_at)
            : new Date();

        const d = new Date();
        //d.setHours(17);
        data.check_out =
          response.data.data.check_out !== null &&
          response.data.data.check_out !== undefined
            ? new Date(response.data.data.check_out)
            : d;

        const checklistItems = data?.checklist_items;
        const iterator = checklistItems.keys();
        for (const key of iterator) {
          setDefaultActiveKeys(defaultActiveKeys.push(key));
        }

        reset(data);
        setInspectionChecklist(data);
      }
    } catch (error) {
      setIsLoadingActive(false);
      notyf.open({
        type: "danger",
        message: "Something went wrong with the server",
      });
    }
  }, [
    id,
    notyf,
    reset,
    setIsLoadingActive,
    setDefaultActiveKeys,
    defaultActiveKeys,
  ]);

    const getUncheckedItems = useCallback(async (params) => {
        try {
            setIsLoadingActive(true);

            const response = await inspectionChecklistApi.getUncheckedItems(
                id,
                params
            );

            if (
                response.status === 200 &&
                response.data.status === "VALIDATION"
            ) {
                setIsLoadingActive(false);
                let information = {};

                const validationArray = Object.values(response.data.data);
                information.items = validationArray;
                information.title = validationTitle(inspectionChecklist.frequency);

                setValidation(information);
            } else {
                setIsLoadingActive(false);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [inspectionChecklist]);

    const validationTitle = (frequency = null) => {
        const lookup = {
            "MONTHLY" : `The following items need to be checked as Yes for the month of ${moment().format('MMMM')}`,
            "SEMIANNUAL": `The following items need to be checked as Yes for this semi-annual checklist`,
        }
        return lookup[frequency];
    }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    getInspectionChecklist();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.keys(inspectionChecklist).length && inspectionChecklist.frequency !== "SEMIANNUAL") {
        getUncheckedItems({
            school_slug: inspectionChecklist.school_slug,
            frequency: inspectionChecklist.frequency,
            status: "SUBMITTED",
        });
    }
  }, [inspectionChecklist])
  return (
    <React.Fragment>
      <Helmet
        title={inspectionChecklist?.school?.name + " | Inspection checklist"}
      />
      <Row>
        <Col md={6}>
          <h4 className="h4 mb-3">Inspection & Servicing</h4>
        </Col>
        <Col md={6}>
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                navigate(
                  `/inspection-checklist/inspection-servicing${
                    status === "PENDING_CONFIRMATION" ? "-pending" : ""
                  }`,
                  {
                    state: {
                      previousSearchValue: state?.previousSearchValue,
                    },
                  }
                );
              }}
            >
              Inspection & Servicings
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {inspectionChecklist?.school?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Container fluid className="p-0">
        <Card className="p-4">
          <Row style={{ marginBottom: "20px" }}>
            <Col md={6}>
              <h4 className="h4 mb-3">{inspectionChecklist?.school?.name}</h4>
            </Col>
            <Col md={4} style={{ textAlign: "right" }}></Col>
            <Col md={2} style={{ textAlign: "right" }}>
              <Form.Group>
                <Form.Label className="font-weight-bold" htmlFor="remarks">
                  Inspection date
                </Form.Label>
                <Controller
                  control={control}
                  name="inspected_at"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <>
                      {inspectionChecklist?.status !== "DRAFT" ? (
                        <div>{new Date(value).toLocaleDateString("en-CA")}</div>
                      ) : (
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={value}
                          onChange={onChange}
                          className={`form-control form-box ${
                            errors.inspected_at && "is-invalid"
                          }`}
                          value={value}
                          placeholderText="Inspection date"
                        />
                      )}
                    </>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          {validation?.items?.length ?
            <Row>
              <Col>       
                <Alert key="danger" variant="warning" className="alert-message">
                    <Row>
                        <h4>{validation.title}</h4>
                        <ul className="ps-4 my-0 py-0">
                            {validation.items.map((item, key) => {
                                return <li key={key}>{item}</li>
                            })}
                        </ul>
                    </Row>
                </Alert>
              </Col>
            </Row>
          : ""}
          {inspectionChecklist?.status === "REJECTED" && (
            <Row>
              <Col>
                <Alert key="danger" variant="danger" className="alert-message">
                  <h5>
                    {" "}
                    Rejection remarks:{" "}
                    <strong> {inspectionChecklist.remarks || "-"}</strong>
                  </h5>
                </Alert>
              </Col>
            </Row>
          )}
          {!isMobile && (
            <Row>
              <Col md={2}>
                <h5>Elements/Facilities</h5>
              </Col>
              <Col md={3}>
                <h5>Defects to check / observations</h5>
              </Col>
              <Col md={2}>
                <h5>Frequency</h5>
              </Col>
              <Col md={2}>
                <h5>Checked</h5>
              </Col>
              <Col md={2}>
                <h5>Remarks</h5>
              </Col>
              <Col md={1}>
                <h5>Details</h5>
              </Col>
            </Row>
          )}
          <Card className="p-0">
            <Accordion defaultActiveKey={defaultActiveKeys} alwaysOpen>
              {inspectionChecklist?.checklist_items?.map(
                (checklistItem, index) => {
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        {checklistItem?.element}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        {checklistItem?.checklist_sub_items &&
                          checklistItem?.checklist_sub_items?.map(
                            (checklistSubItem, index2) => {
                              if (checklistSubItem?.header === true) {
                                return (
                                  <Row
                                    style={{
                                      padding: "5px",
                                    }}
                                  >
                                    <Col>
                                      <div
                                        style={{
                                          paddingLeft:
                                            checklistSubItem?.serial_no
                                              ?.length +
                                            5 +
                                            "px",
                                        }}
                                      >
                                        {checklistSubItem?.element}
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              } else {
                                return (
                                  <ListGroup key={index2}>
                                    <ListGroup.Item>
                                      <Row>
                                        <Col
                                          md={
                                            checklistSubItem?.description !==
                                            null
                                              ? 2
                                              : 5
                                          }
                                        >
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="element"
                                                className="text-sm text-muted"
                                              >
                                                Elements/Facilities
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          <div>{checklistSubItem?.element}</div>
                                        </Col>
                                        {checklistSubItem?.description !==
                                          null && (
                                          <Col md={3}>
                                            {isMobile && (
                                              <Form.Group>
                                                <Form.Label
                                                  htmlFor="description"
                                                  className="text-sm text-muted mt-3"
                                                >
                                                  Defects to check /
                                                  observations
                                                </Form.Label>
                                              </Form.Group>
                                            )}
                                            {checklistSubItem?.description !==
                                            null
                                              ? checklistSubItem.description
                                                  .split("\\n")
                                                  .map((i, key) => {
                                                    return (
                                                      <div key={key}>{i}</div>
                                                    );
                                                  })
                                              : ""}
                                          </Col>
                                        )}

                                        <Col md={2}>
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="frequency"
                                                className="text-sm text-muted mt-3"
                                              >
                                                Frequency
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          {checklistSubItem?.frequency !==
                                          "NULL"
                                            ? checklistSubItem.frequency
                                            : ""}
                                        </Col>
                                        <Col md={2}>
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="checked"
                                                className="text-sm text-muted mt-3"
                                              >
                                                Checked
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          <Controller
                                            control={control}
                                            defaultValue=""
                                            name={`checklist_items[${index}].checklist_sub_items[${index2}].status`}
                                            render={({
                                              field: { onChange, value },
                                            }) => {
                                              return (
                                                <>
                                                  {!isEditableInspectionStatus(
                                                    inspectionChecklist?.status
                                                  ) ? (
                                                    <div>
                                                      {value === "yes"
                                                        ? value
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                          value.slice(1)
                                                        : value === "no"
                                                        ? "No (For follow up inspection)"
                                                        : value !== null
                                                        ? value.toUpperCase()
                                                        : "N/A"}
                                                    </div>
                                                  ) : (
                                                    <>
                                                      <Form.Check
                                                        label="Yes"
                                                        type="radio"
                                                        onChange={(e) => {
                                                          setValue(
                                                            `checklist_items[${index}].checklist_sub_items[${index2}].status`,
                                                            e.target.value
                                                          );
                                                          updateInspectionSchedule(
                                                            getValues(),
                                                            index,
                                                            index2
                                                          );
                                                        }}
                                                        value="yes"
                                                        checked={
                                                          value === "yes"
                                                        }
                                                      />
                                                      {inspectionChecklist
                                                        ?.school?.zone ===
                                                        "SOUTH" && (
                                                        <Form.Check
                                                          label="No (For follow up inspection)"
                                                          type="radio"
                                                          onChange={(e) => {
                                                            setValue(
                                                              `checklist_items[${index}].checklist_sub_items[${index2}].status`,
                                                              e.target.value
                                                            );
                                                            updateInspectionSchedule(
                                                              getValues(),
                                                              index,
                                                              index2
                                                            );
                                                          }}
                                                          value="no"
                                                          checked={
                                                            value === "no"
                                                          }
                                                        />
                                                      )}
                                                      <Form.Check
                                                        label="N/A"
                                                        type="radio"
                                                        onChange={(e) => {
                                                          setValue(
                                                            `checklist_items[${index}].checklist_sub_items[${index2}].status`,
                                                            e.target.value
                                                          );
                                                          updateInspectionSchedule(
                                                            getValues(),
                                                            index,
                                                            index2
                                                          );
                                                        }}
                                                        value={"n/a" || null}
                                                        checked={
                                                          value === "n/a" ||
                                                          value === null
                                                        }
                                                      />
                                                    </>
                                                  )}
                                                </>
                                              );
                                            }}
                                          />
                                        </Col>
                                        <Col md={2}>
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="remarks"
                                                className="text-sm text-muted mt-3"
                                              >
                                                Remarks
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          <Controller
                                            control={control}
                                            defaultValue=""
                                            name={`checklist_items[${index}].checklist_sub_items[${index2}].remarks`}
                                            render={({
                                              field: {
                                                onChange,
                                                value,
                                                onBlur,
                                              },
                                            }) => {
                                              return (
                                                <>
                                                  {!isEditableInspectionStatus(
                                                    inspectionChecklist?.status
                                                  ) ? (
                                                    <div>{value}</div>
                                                  ) : (
                                                    <AutoGrowTextarea
                                                      onBlur={() =>
                                                        updateInspectionSchedule(
                                                          getValues(),
                                                          index,
                                                          index2
                                                        )
                                                      }
                                                      onChange={onChange}
                                                      fieldValue={value || ""}
                                                    />
                                                  )}
                                                </>
                                              );
                                            }}
                                          />
                                        </Col>
                                        <Col md={1}>
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="details"
                                                className="text-sm text-muted mt-3"
                                              >
                                                Details
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          {checklistSubItem?.attachments
                                            ?.length ||
                                          checklistSubItem?.hasDefect ? (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>View evidence</Tooltip>
                                              }
                                            >
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={faEye}
                                                  size="lg"
                                                  className="align-middle me-1 cursor-pointer eye-icon"
                                                  onClick={() =>
                                                    navigate(
                                                      `/inspection-checklist/inspection-servicing/${id}/${checklistItem?.id}/${checklistSubItem?.id}/view-evidence`
                                                    )
                                                  }
                                                />
                                              </span>
                                            </OverlayTrigger>
                                          ) : (
                                            ""
                                          )}
                                          {isEditableInspectionStatus(
                                            inspectionChecklist?.status
                                          ) && (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>Add evidence</Tooltip>
                                              }
                                            >
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={faPlus}
                                                  size="lg"
                                                  className="align-middle me-1 cursor-pointer"
                                                  onClick={() =>
                                                    navigate(
                                                      `/inspection-checklist/inspection-servicing/${id}/${checklistItem?.id}/${checklistSubItem?.id}/create-evidence`,
                                                      {
                                                        state: {
                                                          previousSearchValue:
                                                            state?.previousSearchValue,
                                                        },
                                                      }
                                                    )
                                                  }
                                                />
                                              </span>
                                            </OverlayTrigger>
                                          )}
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  </ListGroup>
                                );
                              }
                            }
                          )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
              )}
            </Accordion>
          </Card>
          {hasRoleCollection.isManagingAgent && (
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label className="font-weight-bold" htmlFor="remarks">
                    Remarks
                  </Form.Label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="remarks"
                    render={({ field: { onBlur, onChange, value } }) => (
                      <>
                        {isEditableInspectionStatus(
                          inspectionChecklist?.status
                        ) ? (
                          <p>{value || "-"}</p>
                        ) : (
                          <AutoGrowTextarea
                            errors={errors?.remarks}
                            fieldValue={value}
                            onBlur={onBlur}
                            onChange={onChange}
                          />
                        )}
                      </>
                    )}
                  />
                </Form.Group>
                <ErrorMessage
                  errors={errors}
                  name="remarks"
                  render={({ message }) => errorMessage(message)}
                />
              </Col>
            </Row>
          )}
          {!hasRoleCollection.isManagingAgent &&
            !hasRoleCollection.isOM &&
            !hasRoleCollection.isSCD &&
            inspectionChecklist?.attendance_id !== null && (
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label className="font-weight-bold" htmlFor="checkOut">
                      Check-out
                    </Form.Label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="check_out"
                      render={({ field: { value, onChange, onBlur } }) => (
                        <>
                          {inspectionChecklist?.status !== "DRAFT" ? (
                            <div>{new Date(value).toLocaleString("en-CA")}</div>
                          ) : (
                            <>
                              <DatePicker
                                dateFormat="yyyy-MM-dd HH:mm"
                                showTimeSelect
                                className={`form-control form-box mb-4 ${
                                  errors.check_out && "is-invalid"
                                }`}
                                selected={value}
                                onChange={onChange}
                                value={value}
                                timeIntervals={1}
                                minTime={
                                  inspectionChecklist?.school?.zone === "SOUTH"
                                    ? miT
                                    : ""
                                }
                                maxTime={
                                  inspectionChecklist?.school?.zone === "SOUTH"
                                    ? maT
                                    : ""
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          <Row>
            <SignaturesForm
              control={control}
              canSign={
                canSubmitInspection &&
                (inspectionChecklist?.prepare_details?.length === 0 ||
                  isEditableInspectionStatus(inspectionChecklist?.status))
              }
              label="Prepared by"
              name="prepare_details"
              setValue={setValue}
            />
            {inspectionChecklist?.approval_flow?.includes("OM") && (
              <SignaturesForm
                control={control}
                canSign={
                  hasRoleCollection.isOM &&
                  inspectionChecklist?.om_details?.length === 0
                }
                label="Acknowledged by"
                name="om_details"
                setValue={setValue}
              />
            )}
            {inspectionChecklist?.approval_flow?.includes("MA") && (
              <SignaturesForm
                control={control}
                canSign={
                  hasRoleCollection.isManagingAgent &&
                  inspectionChecklist?.ma_details?.length === 0
                }
                label="Checked & certified correct by"
                name="ma_details"
                setValue={setValue}
              />
            )}
          </Row>
          <Row className="my-3">
            <Col md={12} className="text-center">
              {canSubmitInspection &&
                isEditableInspectionStatus(inspectionChecklist?.status) && (
                  <>
                    <Button
                      className="me-2"
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit((data) =>
                        setModalConfirmation({
                          notifMsg:
                            "Are you sure you want to submit this checklist?",
                          open: true,
                          api: () => saveTech(data, "SUBMITTED"),
                        })
                      )}
                    >
                      Submit
                    </Button>
                    <Button
                      className="me-2 btn-secondary"
                      variant="primary"
                      type="submit"
                      onClick={(data) => save(data, "DRAFT")}
                    >
                      Draft
                    </Button>
                  </>
                )}

              {hasRoleCollection.isOM &&
                inspectionChecklist?.om_details?.length === 0 && (
                  <Button
                    className="me-2"
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit((data) =>
                      saveOm(data, "ACKNOWLEDGED")
                    )}
                  >
                    Acknowledge
                  </Button>
                )}

              {hasRoleCollection.isManagingAgent &&
                (inspectionChecklist?.status === "ACKNOWLEDGED" ||
                  inspectionChecklist?.status === "SUBMITTED") && (
                  <>
                    <Button
                      className="me-2"
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit((data) => saveMa(data, "APPROVED"))}
                    >
                      Approve
                    </Button>
                    <Button
                      className="me-2 btn-secondary"
                      variant="danger"
                      type="submit"
                      onClick={handleSubmit((data) => save(data, "REJECTED"))}
                    >
                      Reject
                    </Button>
                  </>
                )}

              <Button
                variant="link"
                onClick={() =>
                  navigate(
                    `/inspection-checklist/inspection-servicing${
                      status === "PENDING_CONFIRMATION" ? "-pending" : ""
                    }`,
                    {
                      state: {
                        previousSearchValue: state?.previousSearchValue,
                      },
                    }
                  )
                }
              >
                Cancel
              </Button>
              {/* {canSubmitInspection && (
                                <Button
                                    variant="link"
                                    onClick={() =>
                                        navigate(
                                            `/inspection-checklist/inspection-servicing/${inspectionChecklist?.id}/preview`
                                        )
                                    }
                                >
                                    Preview
                                </Button>
                            )} */}
              {inspectionChecklist?.file_info?.bucket &&
                inspectionChecklist?.file_info?.file_path && (
                  <Button
                    variant="link"
                    onClick={() =>
                      window.open(
                        constructReport(
                          inspectionChecklist?.file_info?.bucket,
                          inspectionChecklist?.file_info?.file_path,
                          `${inspectionChecklist?.school?.name}-${inspectionChecklist?.inspection_category}-${inspectionChecklist?.inspected_at}`,
                          true
                        )
                      )
                    }
                  >
                    Preview
                  </Button>
                )}
            </Col>
          </Row>
        </Card>
      </Container>
      <ConfirmationModal
        modalInfo={modalConfirmation}
        setModalInfo={setModalConfirmation}
      />
    </React.Fragment>
  );
};

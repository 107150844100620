import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import { Badge, Card } from "react-bootstrap";
import { moneyFormatter } from "utils/utilities";

import usePalette from "../../../hooks/usePalette";

export const LineChart = ({ title, type, zone, quotationData }) => {
    const palette = usePalette();
    const [months, setMonths] = useState([]);
    const [status, setStatus] = useState({});

    const data = [
        {
            name: "Pending",
            data: status?.pending || [],
        },
        {
            name: "Approved",
            data: status?.approved || [],
        },
        {
            name: "Completed",
            data: status?.completed || [],
        },
        {
            name: "Paid",
            data: status?.paid || [],
        },
        {
            name: "Rejected",
            data: status?.rejected || [],
        },
    ];

    const options = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [5, 7, 5],
            curve: "straight",
            dashArray: [0, 8, 5],
        },
        markers: {
            size: 0,
            style: "hollow",
        },
        xaxis: {
            categories: months,
        },
        yaxis: {
            title: {
                text: "Total",
            },
            labels: {
                formatter: (val) => {
                    return `S$ ${moneyFormatter(val)}`;
                },
            },
        },
        tooltip: {
            y: {
                formatter: (val) => {
                    return `S$ ${moneyFormatter(val)}`;
                },
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        colors: [
            palette.warning,
            palette.success,
            palette.primary,
            palette.secondary,
            palette.danger,
        ],
    };

    useEffect(() => {
        let tempArray = [];

        if (quotationData) {
            Object.values(quotationData).map((data) => {
                tempArray.push(data.label);
            });
        }

        setMonths(tempArray);
    }, [quotationData]);

    useEffect(() => {
        let tempObject = {
            pending: [],
            approved: [],
            completed: [],
            paid: [],
            rejected: [],
        };

        if (quotationData) {
            Object.values(quotationData).map((data) => {
                tempObject["pending"] = [
                    ...tempObject["pending"],
                    data.pending,
                ];
                tempObject["approved"] = [
                    ...tempObject["approved"],
                    data.approved,
                ];
                tempObject["completed"] = [
                    ...tempObject["completed"],
                    data.completed,
                ];
                tempObject["paid"] = [...tempObject["paid"], data.paid];
                tempObject["rejected"] = [
                    ...tempObject["rejected"],
                    data.rejected,
                ];
            });
        }

        setStatus(tempObject);
    }, [quotationData]);

    return (
        <Card>
            <Card.Header>
                <h6 className="h6 mb-3">
                    {title}
                    <Badge pill bg="primary" className="ms-1 me-1">
                        {type}
                    </Badge>
                    <Badge pill bg="secondary" className="me-1">
                        {zone}
                    </Badge>
                </h6>
            </Card.Header>
            <Card.Body>
                <div className="chart">
                    <Chart
                        options={options}
                        series={data}
                        type="line"
                        height="350"
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";

import Select from "react-select";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import * as schoolApi from "@api/schoolApi";

import NotyfContext from "contexts/NotyfContext";
import { LoadingContext } from "App";
import { status_options_quotation, status_options_wso, modules } from "config";
import { errorMessage } from "utils/utilities";
import * as excelExportApi from "@api/excelExportApi";
import {
    export_current_status_options_claims,
    export_current_status_options_quotation,
    export_status_options_claims,
    export_status_options_inspection,
    export_status_options_quotation,
    export_status_options_wso,
} from "../components/status";

export const ExcelExportForm = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    let isMobile = width < 768;

    const schema = yup.object().shape({
        module: yup.object().required("This field is required").nullable(),
    });

    const {
        control,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const module = useWatch({
        control,
        name: "module",
    });

    let status_options = [];
    let current_status_options = [];

    switch (module?.value) {
        case "QUOTATION":
            status_options = export_status_options_quotation;
            current_status_options = export_current_status_options_quotation;
            break;
        case "WSO":
            status_options = export_status_options_wso;
            break;
        case "INSPECTION":
            status_options = export_status_options_inspection;
            break;
        case "CLAIM":
            status_options = export_status_options_claims;
            current_status_options = export_current_status_options_claims;
            break;
        default:
            status_options = [];
            break;
    }

    //
    // States
    //
    const [width] = useState(window.innerWidth);
    const [schools, setSchools] = useState([]);

    //
    // Function
    //
    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const schoolsMapped = response.data.data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const createExcelExport = async (data) => {
        try {
            setIsLoadingActive(true);

            const param = {
                module: "",
                properties: {
                    start_date: "",
                    end_date: "",
                    status: "",
                    current_status: [],
                    school: "",
                },
            };

            param.module = data.module.value;
            param.properties.school = data.school ? data.school.value : "";
            param.properties.start_date = data.start_date
                ? new Date(data.start_date).toLocaleDateString("en-CA")
                : "";
            param.properties.end_date = data.end_date
                ? new Date(data.end_date).toLocaleDateString("en-CA")
                : "";
            param.properties.status = data.status ?? [];
            param.properties.current_status = data.current_status ?? [];

            const response = await excelExportApi.createExcelExport(param);

            if (response.status === 201) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "success",
                    message: `Successfully created excel export`,
                });
                navigate(`/excel-export`);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    return (
        <React.Fragment>
            <Helmet title={`Create export excel`} />
            <Row>
                <Col md={6}>
                    <h4 className="tab-title mb-3">Create export document</h4>
                </Col>
                <Col md={6}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClick={() => navigate(`/excel-export`)}
                        >
                            Excel export list
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Card className="p-0">
                <Card.Body className="pb-0">
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Module
                                    <span> (*)</span>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="module"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Select
                                            className={`${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            classNamePrefix="react-select "
                                            options={modules}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={onChange}
                                            placeholder="Select module"
                                            value={value}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="module"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    School
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="school"
                                    render={({
                                        field: { value, onChange, ref },
                                    }) => (
                                        <Select
                                            inputRef={ref}
                                            className={`${isMobile && "w-100"}`}
                                            classNamePrefix="react-select"
                                            options={schools}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={value}
                                            onChange={onChange}
                                            placeholder="Select school"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="name"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Status
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="status"
                                    render={({
                                        field: { value, onChange, ref },
                                    }) => (
                                        <Select
                                            inputRef={ref}
                                            className={`${isMobile && "w-100"}`}
                                            classNamePrefix="react-select"
                                            options={status_options}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={value}
                                            isMulti
                                            onChange={onChange}
                                            placeholder="Select status"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="status"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        {(module?.value === "QUOTATION" || module?.value === "CLAIM") && 
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label
                                        htmlFor="taskName"
                                        className="font-weight-bold"
                                    >
                                        {module?.value === "QUOTATION" ? "WSO Status" : "Current Status"}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="current_status"
                                        render={({
                                            field: { value, onChange, ref },
                                        }) => (
                                            <Select
                                                inputRef={ref}
                                                className={`${isMobile && "w-100"}`}
                                                classNamePrefix="react-select"
                                                options={current_status_options}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={value}
                                                isMulti
                                                onChange={onChange}
                                                placeholder="Select status"
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="status"
                                        render={({ message }) =>
                                            errorMessage(message)
                                        }
                                    />
                                </Form.Group>
                            </Col> }
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Start date
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="start_date"
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={value}
                                                onChange={onChange}
                                                className={`form-box form-control ${
                                                    errors?.start_date &&
                                                    "is-invalid"
                                                }`}
                                                value={value || ""}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="start_date"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        End date
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="end_date"
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={value}
                                                onChange={onChange}
                                                className={`form-box form-control ${
                                                    errors?.end_date && "is-invalid"
                                                }`}
                                                value={value || ""}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="end_date"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                    </Row>
                    <Row className="my-3">
                        <Col md={12} className="text-center">
                            <Button
                                className="me-2"
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit(createExcelExport)}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="link"
                                onClick={() => navigate(`/excel-export`)}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
};

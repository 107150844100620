import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";

import DynamicTable from "components/ui/DynamicTable";
import * as usersApi from "@api/usersApi";
import NotyfContext from "contexts/NotyfContext";
import { DeleteModal } from "components/DeleteModal";
import { formatConfigFields } from "utils/utilities";
import { LoadingContext } from "App";
import {
    faPen,
    faSearch,
    faTrash,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as rolesApi from "@api/rolesApi";
import { contractors } from "./contactorConfig";
export const RerenderContextUsers = createContext();

export const Users = () => {
    const notyf = useContext(NotyfContext);
    const location = useLocation();
    const [width] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    //
    // States
    //

    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [searchValue, setSearchValue] = useState({
        full_name: "",
        email: "",
        role_id: "",
        contractor: "",
    });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [configColumn, setFieldColumn] = useState([]);
    let isDesktop = width > 1400;

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: (cell) => (
            <div>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit user</Tooltip>}
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faPen}
                            className="align-middle me-1"
                            onClick={() =>
                                navigate(
                                    location.pathname +
                                        "/" +
                                        cell.row.original.id
                                )
                            }
                            size="lg"
                        />
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Delete user</Tooltip>}
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="align-middle me-1"
                            onClick={() => {
                                openDeleteModal(cell.row.original.id);
                            }}
                            size="lg"
                        />
                    </span>
                </OverlayTrigger>
            </div>
        ),
    };

    //
    // Functions
    //

    const getUsers = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                param = { ...searchValue, ...param };
                const response = await usersApi.getUsers(param);

                setUsers(response.data);
                setIsLoadingActive(false);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
                setIsLoadingActive(false);
            }
        },
        // eslint-disable-next-line
        [notyf, setIsLoadingActive]
    );

    const deleteUser = useCallback(
        async (id) => {
            try {
                const response = await usersApi.deleteUser(id);
                if (response.status === 200) {
                    notyf.open({
                        type: "success",
                        message: response.data.message,
                    });
                    setRerender(!rerender);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, rerender]
    );

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    const search = () => {
        getUsers(searchValue);
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await usersApi.getUserConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getRoles = useCallback(
        async (param = null) => {
            try {
                const response = await rolesApi.getRoles(param);
                const rolesResponse = response.data.data;
                let roles = [];

                rolesResponse.forEach((element) => {
                    roles = [
                        ...roles,
                        {
                            value: element.id,
                            label: element.name,
                        },
                    ];
                });

                setRoles(roles);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setRoles]
    );
    //
    // UseEffects
    //

    useEffect(() => {
        getUsers();
        getConfig();
        getRoles();
    }, [getUsers, rerender, getConfig, getRoles]);

    return (
        <React.Fragment>
            <Helmet title="Users" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Users</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={10} sm={10} xs={10}>
                                <Row>
                                    <Col md={2} sm={5} xs={5}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block form-box"
                                            placeholder="Name"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    full_name: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={5} xs={5}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block form-box"
                                            placeholder="Email"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    email: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={5} xs={5}>
                                        <Select
                                            className="is-invalid react-select-container"
                                            classNamePrefix="react-select"
                                            options={roles}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    role_id: e?.value,
                                                })
                                            }
                                            menuPlacement="bottom"
                                        />
                                    </Col>
                                    <Col md={3} sm={5} xs={5}>
                                        <Select
                                            className="is-invalid react-select-container"
                                            classNamePrefix="react-select "
                                            options={contractors}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    contractor: e?.value,
                                                })
                                            }
                                            menuPlacement="bottom"
                                        />
                                    </Col>
                                    <Col md={1} sm={2} xs={2}>
                                        <Button
                                            variant="primary"
                                            className="me-1 mb-1 form-box"
                                            onClick={() => search()}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <h1 className="h3">
                                    <Button
                                        variant="primary"
                                        className={`${
                                            width >= 768 && "m-1"
                                        } float-end mb-2 form-box`}
                                        onClick={() =>
                                            navigate(`/settings/users/create`)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </h1>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && users.data && (
                            <DynamicTable
                                dataPagination={users}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                                api={getUsers}
                            />
                        )}
                        <DeleteModal
                            modalInfo={modalInfo}
                            setModalInfo={setModalInfo}
                            api={deleteUser}
                        />
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";

import { Badge, Col, Collapse, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomRouterLink = forwardRef((props, ref) => (
    <React.Fragment ref={ref}>
        <NavLink {...props} />
    </React.Fragment>
));

const SidebarNavListItem = (props) => {
    const {
        title,
        href,
        depth = 0,
        children,
        icon: Icon,
        badge,
        open: openProp = false,
        external,
    } = props;

    const [open, setOpen] = React.useState(openProp);

    const handleToggle = () => {
        setOpen((state) => !state);
    };

    if (children) {
        return (
            <li className={`sidebar-item ${open ? "active" : ""}`}>
                <a
                    className={`sidebar-link ${open ? "" : "collapsed"}`}
                    data-bs-toggle="collapse"
                    aria-expanded={open ? "true" : "false"}
                    depth={depth}
                    onClick={handleToggle}
                >
                    <Row>
                        <Col md={2} xs={2}>
                            {Icon && (
                                <span depth={depth}>
                                    <FontAwesomeIcon icon={Icon} size="lg" />
                                </span>
                            )}{" "}
                        </Col>
                        <Col md={10} xs={10}>
                            <span className="align-middle" depth={depth}>
                                {title}
                            </span>
                        </Col>
                    </Row>
                    {badge && (
                        <Badge
                            className="badge-sidebar-primary"
                            bg=""
                            size={18}
                        >
                            {badge}
                        </Badge>
                    )}
                    {open ? <div /> : <div />}
                </a>
                <Collapse in={open}>
                    <ul className="sidebar-dropdown list-unstyled">
                        {children}
                    </ul>
                </Collapse>
            </li>
        );
    }

    return (
        <>
            {!external ? (
                <li className="sidebar-item">
                    <CustomRouterLink
                        depth={depth}
                        to={href}
                        activeclassname="active"
                        className="sidebar-link"
                        target={external ? "_blank" : ""}
                    >
                        <Row>
                            <Col
                                md={depth === 1 ? 0 : 2}
                                xs={depth === 1 ? 0 : 2}
                                sm={depth === 1 ? 0 : 2}
                            >
                                {Icon && (
                                    <span depth={depth}>
                                        <FontAwesomeIcon
                                            icon={Icon}
                                            size="lg"
                                        />
                                    </span>
                                )}{" "}
                            </Col>
                            <Col
                                md={depth === 1 ? 12 : 10}
                                xs={depth === 1 ? 12 : 10}
                                sm={depth === 1 ? 12 : 10}
                            >
                                <span
                                    className={`align-middle ${
                                        depth === 1 ? "ms-2" : ""
                                    }`}
                                    depth={depth}
                                >
                                    {title}
                                </span>
                            </Col>
                        </Row>
                        {badge && (
                            <Badge
                                className="badge-sidebar-primary"
                                bg=""
                                size={18}
                            >
                                {badge}
                            </Badge>
                        )}
                    </CustomRouterLink>
                </li>
            ) : (
                <li className="sidebar-item">
                    <a
                        className="sidebar-link"
                        target="_blank"
                        href={href}
                        rel="noreferrer"
                    >
                        <Row>
                            <Col md={depth === 1 ? 1 : 2} xs={2}>
                                {Icon && (
                                    <span depth={depth}>
                                        <FontAwesomeIcon
                                            icon={Icon}
                                            size="lg"
                                        />
                                    </span>
                                )}{" "}
                            </Col>
                            <Col md={depth === 1 ? 11 : 10} xs={10}>
                                <span
                                    className={`align-middle ${
                                        depth === 1 ? "ms-2" : ""
                                    }`}
                                    depth={depth}
                                >
                                    {title}
                                </span>
                            </Col>
                        </Row>
                    </a>
                </li>
            )}
        </>
    );
};

export default SidebarNavListItem;

import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import * as deliverablesApi from "@api/deliverablesApi";
import { RerenderContextQuotation } from "../quotations/quotation-list/QuotationList";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";
import { LoadingContext } from "../../App";
import DynamicTable from "../../components/ui/DynamicTable";
import { formatConfigFields } from "../../utils/utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faSearch } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
export default function DeliverableList({ category }) {
    let pageTitle = "";
    let categoryApiRoute = "";
    // let listLink = "";
    if (category.toLowerCase() === "bm") {
        pageTitle = "Building maintenance";
        categoryApiRoute = "building-maintenance";
        // listLink = "building-maintenance";
    }
    if (category.toLowerCase() === "tp") {
        pageTitle = "Turf and plant";
        categoryApiRoute = "turf-and-plant";
        // listLink = "turf-and-plant";
    }

    const { hasRole } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const notyf = useContext(NotyfContext);

    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [searchValue, setSearchValue] = useState({});
    const [yearSearch] = useState([
        { value: "2022", label: "2022" },
        { value: "2023", label: "2023" },
        { value: "2024", label: "2024" },
        { value: "2025", label: "2025" },
        { value: "2026", label: "2026" },
    ]);

    const [monthSearch] = useState([
        { value: "1", label: "January" },
        { value: "2", label: "February" },
        { value: "3", label: "March" },
        { value: "4", label: "April" },
        { value: "5", label: "May" },
        { value: "6", label: "June" },
        { value: "7", label: "July" },
        { value: "8", label: "August" },
        { value: "9", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
    ]);
    const [zoneSearch] = useState([
        { value: "NORTH_EAST", label: "Noth east" },
        { value: "SOUTH", label: "South" },
    ]);

    const hasRoleCollection = {
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isQS: hasRole("MA-QS"),
        isAdmin: hasRole("ADMIN"),
        isSCD: hasRole("SCD"),
    };

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: "150px",
        Cell: ({ row, cell }) => {
            const status = row.original.status;
            const canTcEdit =
                (status === "DRAFT" || status === "REJECTED") &&
                hasRoleCollection.isTermContractor;

            const canTcSign =
                status === "PENDING" &&
                hasRoleCollection.isTermContractor &&
                !row.original?.pending_approval;

            const canTcView =
                hasRoleCollection.isTermContractor &&
                row.original?.pending_approval &&
                (status === "APPROVED" ||
                    status === "VOID" ||
                    status === "SUBMITTED");

            const canMaView =
                (status === "REJECTED" || status === "APPROVED") &&
                hasRoleCollection.isManagingAgent &&
                row.original?.approvals?.MA;

            const canMaSign =
                (status === "REJECTED" || status === "SUBMITTED") &&
                hasRoleCollection.isManagingAgent &&
                row.original?.pending_approval?.approval_type === "MA";

            const canScdView =
                (status === "APPROVED" || status === "PENDING") &&
                hasRoleCollection.isSCD;

            const canAdminView =
                (status === "REJECTED" ||
                    status === "SUBMITTED" ||
                    status === "DRAFT" ||
                    status === "APPROVED") &&
                hasRoleCollection.isAdmin;

            return (
                <>
                    {canTcEdit && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit Deliverable</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faPen}
                                    className="align-middle me-1"
                                    onClick={() =>
                                        navigate(
                                            location.pathname +
                                                "/" +
                                                cell.row.original.id
                                            // { state: { view: true } }
                                        )
                                    }
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                    {(canTcSign || canMaSign) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Sign</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="align-middle me-1"
                                    onClick={() =>
                                        navigate(
                                            location.pathname +
                                                "/" +
                                                cell.row.original.id,
                                            { state: { sign: true } }
                                        )
                                    }
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}

                    {(canMaView || canTcView || canAdminView || canScdView) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View Deliverable</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="align-middle me-1"
                                    onClick={() =>
                                        navigate(
                                            location.pathname +
                                                "/" +
                                                cell.row.original.id,
                                            { state: { view: true } }
                                        )
                                    }
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                </>
            );
        },
    };
    // States
    //
    const [rerender, setRerender] = useState([]);
    const [bmlists, setBmLists] = useState([]);
    const [configColumn, setFieldColumn] = useState([]);

    const getBmLists = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await deliverablesApi.getDeliverableList(
                    param,
                    categoryApiRoute
                );
                if (response.status === 200) {
                    setBmLists(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
                setIsLoadingActive(false);
            }
        },
        // eslint-disable-next-line
        [notyf, setIsLoadingActive]
    );

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await deliverablesApi.getDeliverableConfig(
                    param
                );
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        // eslint-disable-next-line
        [notyf]
    );

    const search = () => {
        getBmLists(searchValue);
    };
    //
    // UseEffects
    //

    useEffect(() => {
        getBmLists(searchValue);
        getConfig();
        // eslint-disable-next-line
    }, [getBmLists, getConfig, rerender]);
    return (
        <RerenderContextQuotation.Provider value={[rerender, setRerender]}>
            <Helmet title={pageTitle} />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">{pageTitle}</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={12} sm={10} xs={10}>
                                <Row>
                                    <Col md={3} sm={4} xs={4}>
                                        <Select
                                            isClearable={true}
                                            onChange={(data) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    year: data
                                                        ? data.value
                                                        : "",
                                                })
                                            }
                                            options={yearSearch}
                                            placeholder="Select year"
                                        />
                                    </Col>
                                    <Col md={3} sm={4} xs={4}>
                                        <Select
                                            isClearable={true}
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    month: data
                                                        ? data.value
                                                        : "",
                                                });
                                            }}
                                            options={monthSearch}
                                            placeholder="Select month"
                                        />
                                    </Col>
                                    <Col md={3} sm={4} xs={4}>
                                        <Select
                                            isClearable={true}
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    zone: data
                                                        ? data.value
                                                        : "",
                                                });
                                            }}
                                            options={zoneSearch}
                                            placeholder="Select zone"
                                        />
                                    </Col>
                                    <Col md={2} sm={2} xs={2}>
                                        <Button
                                            variant="primary"
                                            className="me-1 mb-1 form-box"
                                            onClick={search}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && bmlists.data && (
                            <DynamicTable
                                dataPagination={bmlists}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                                api={getBmLists}
                                searchValue={searchValue}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>

            {/* Modals */}
        </RerenderContextQuotation.Provider>
    );
}

import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button } from "react-bootstrap";

const Page403 = () => (
    <React.Fragment>
        <Helmet title="403 Error" />
        <div className="text-center">
            <h1 className="display-1 fw-bold">403</h1>
            <p className="h1">Page forbidden</p>
            <p className="h2 fw-normal mt-3 mb-4">
                We are sorry, but you do not have access to this page.
            </p>
            <Link to="/">
                <Button variant="primary" size="lg">
                    Return to home
                </Button>
            </Link>
        </div>
    </React.Fragment>
);

export default Page403;

import React, { useState, useCallback, useContext, useEffect } from "react";

import {
    Badge,
    Col,
    Form,
    ListGroup,
    ListGroupItem,
    Row,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { errorMessage } from "utils/utilities";
import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import * as schoolApi from "@api/schoolApi";

export const QuotationDetailsForm = ({
    active,
    control,
    errors,
    quotationData,
    saveToLocalStorage,
    setActive,
    setQuotationItemsStorage,
    setRates,
    setValue,
    view,
    computeGst,
    watch,
    getValues,
}) => {
    //
    // States
    //

    const { user, hasRole, config } = useAuth();
    const [schoolsOriginal, setSchoolsOriginal] = useState([]);
    const [schools, setSchools] = useState([]);
    const notyf = useContext(NotyfContext);
    const job_type_options = config.quotation_job_type;
    const watchDate = watch("date");
    //
    // Functions
    //

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const schoolsMapped = response.data.data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchoolsOriginal(response.data.data);
            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const populateOMInfo = (data) => {
        setValue("school", data);

        const getOMs = data.oms;

        const attentionOM = getOMs[0]?.full_name || "";
        let ccOM = "";
        const telephoneOM = data?.telephone || "";
        const faxOM = data?.fax || "";
        const addressOM = data?.address || "";

        getOMs.map((om, index) => {
            if (index === 0) return;

            if (index === 1) {
                ccOM = om.full_name;
            } else {
                ccOM = `${ccOM}, ${om.full_name}`;
            }
        });

        const contacts = {
            cc: ccOM,
            attention: attentionOM,
            tel: telephoneOM,
            fax: faxOM,
            location: addressOM,
            school: data,
        };

        setValue("contacts.cc", ccOM);
        setValue("contacts.attention", attentionOM);
        setValue("contacts.location", addressOM);
        setValue("contacts.tel", telephoneOM);
        setValue("contacts.fax", faxOM);

        saveToLocalStorage(contacts);

        setRates({
            less_percent: data.less_percent,
            star_rate: data.star_rate,
        });
    };

    const setActiveVersion = (item) => {
        setQuotationItemsStorage(item.items);
        setActive(item.version);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    useEffect(() => {
        if(getValues().date) computeGst(getValues().date);
    },[watchDate]);

    return (
        <>
            <Row>
                <Col md={9}>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    School
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="school"
                                    defaultValue=""
                                    render={({ field: { value, ref } }) => (
                                        <>
                                            {view && value && schools.length ? (
                                                <p>
                                                    {
                                                        schools?.find(
                                                            (o) =>
                                                                o.value ===
                                                                value
                                                        )?.label
                                                    }
                                                </p>
                                            ) : (
                                                <>
                                                    <Select
                                                        inputRef={ref}
                                                        onChange={(e) => {
                                                            e &&
                                                                populateOMInfo(
                                                                    schoolsOriginal.find(
                                                                        (o) =>
                                                                            o.id ===
                                                                            e.value
                                                                    )
                                                                );
                                                        }}
                                                        onBlur={(event) =>
                                                            event.preventDefault()
                                                        }
                                                        options={schools}
                                                        value={
                                                            value?.length
                                                                ? schools.find(
                                                                      (o) =>
                                                                          o.value ===
                                                                          value
                                                                  )
                                                                : schools.find(
                                                                      (o) =>
                                                                          o.value ===
                                                                          value.id
                                                                  )
                                                        }
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="school"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Attention
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="contacts.attention"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>{value}</p>
                                            ) : (
                                                <Form.Control
                                                    type="text"
                                                    value={value || ""}
                                                    onChange={onChange}
                                                    className={`form-box ${
                                                        errors?.contacts
                                                            ?.attention &&
                                                        "is-invalid"
                                                    }`}
                                                    onBlur={(e) =>
                                                        saveToLocalStorage({
                                                            attention:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.attention"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    cc
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="contacts.cc"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>{value || "-"}</p>
                                            ) : (
                                                <Form.Control
                                                    type="text"
                                                    value={value || ""}
                                                    onChange={onChange}
                                                    className={`form-box ${
                                                        errors?.contacts?.cc &&
                                                        "is-invalid"
                                                    }`}
                                                    onBlur={(e) =>
                                                        saveToLocalStorage({
                                                            cc: e.target.value,
                                                        })
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.cc"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Telephone
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="contacts.tel"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>{value}</p>
                                            ) : (
                                                <Form.Control
                                                    type="text"
                                                    value={value || ""}
                                                    onChange={onChange}
                                                    className={`form-box ${
                                                        errors?.contacts?.tel &&
                                                        "is-invalid"
                                                    }`}
                                                    onBlur={(e) =>
                                                        saveToLocalStorage({
                                                            tel: e.target.value,
                                                        })
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.tel"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Fax
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="contacts.fax"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>{value || "-"}</p>
                                            ) : (
                                                <Form.Control
                                                    type="text"
                                                    value={value || ""}
                                                    onChange={onChange}
                                                    className={`form-box ${
                                                        errors?.contacts?.fax &&
                                                        "is-invalid"
                                                    }`}
                                                    onBlur={(e) =>
                                                        saveToLocalStorage({
                                                            fax: e.target.value,
                                                        })
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.fax"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Date
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="date"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>
                                                    {new Date(
                                                        value
                                                    ).toLocaleDateString(
                                                        "en-CA"
                                                    )}
                                                </p>
                                            ) : (
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={value}
                                                    onChange={onChange}
                                                    className={`form-box form-control ${
                                                        errors?.contacts
                                                            ?.date &&
                                                        "is-invalid"
                                                    }`}
                                                    value={value}
                                                    onCalendarClose={(e) => {
                                                        saveToLocalStorage({
                                                            date: value,
                                                        })
                                                        computeGst(value)
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="date"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="location"
                                    className="font-weight-bold"
                                >
                                    Location
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="contacts.location"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>{value}</p>
                                            ) : (
                                                <AutoGrowTextarea
                                                    onChange={onChange}
                                                    fieldValue={value}
                                                    errors={
                                                        errors?.contacts
                                                            ?.location
                                                    }
                                                    onBlur={(e) =>
                                                        saveToLocalStorage({
                                                            location:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="contacts.location"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Job type
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="job_type"
                                    defaultValue=""
                                    render={({
                                        field: { value, onChange, ref },
                                    }) => (
                                        <>
                                            {view && value?.length ? (
                                                value?.map((element, key) => {
                                                    return (
                                                        <div key={key}>
                                                            <Badge
                                                                pill
                                                                bg="secondary"
                                                                className="me-2"
                                                            >
                                                                {element.label}
                                                            </Badge>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <Select
                                                    className={`react-select-container ${
                                                        errors.job_type &&
                                                        "is-invalid"
                                                    }`}
                                                    classNamePrefix="react-select"
                                                    isClearable={true}
                                                    inputRef={ref}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    onChange={(e) => {
                                                        setValue("job_type", e);
                                                        saveToLocalStorage({
                                                            job_type: e,
                                                        });
                                                    }}
                                                    onBlur={(event) =>
                                                        event.preventDefault()
                                                    }
                                                    options={job_type_options}
                                                    value={value}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="job_type"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Trade
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="trade"
                                    defaultValue=""
                                    render={({
                                        field: { value, onChange, ref },
                                    }) => (
                                        <>
                                            {view && value ? (
                                                <p>
                                                    {
                                                        user?.trade?.find(
                                                            (o) =>
                                                                o.value ===
                                                                value
                                                        ).label
                                                    }
                                                </p>
                                            ) : (
                                                <Select
                                                    isClearable={true}
                                                    inputRef={ref}
                                                    onChange={(e) => {
                                                        setValue("trade", e);
                                                        saveToLocalStorage({
                                                            trade: e,
                                                        });
                                                    }}
                                                    onBlur={(event) =>
                                                        event.preventDefault()
                                                    }
                                                    className={`react-select-container ${
                                                        errors?.trade &&
                                                        "is-invalid"
                                                    }`}
                                                    options={user?.trade}
                                                    value={user?.trade?.find(
                                                        (o) => o.value === value
                                                    )}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="trade"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="job"
                                    className="font-weight-bold"
                                >
                                    Job
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="job"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>{value}</p>
                                            ) : (
                                                <AutoGrowTextarea
                                                    onChange={onChange}
                                                    fieldValue={value}
                                                    errors={errors.job}
                                                    onBlur={(e) =>
                                                        saveToLocalStorage({
                                                            job: e.target.value,
                                                        })
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="job"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                {hasRole("TERM-CONTRACTOR") &&
                    !view &&
                    quotationData?.quotation_item_versions && (
                        <Col md={3}>
                            <Form.Label className="font-weight-bold">
                                Work flow history
                            </Form.Label>
                            <ListGroup>
                                {quotationData?.quotation_item_versions.map(
                                    (item, index) => {
                                        return (
                                            <ListGroupItem
                                                active={item.version === active}
                                                key={index}
                                                onClick={() =>
                                                    setActiveVersion(item)
                                                }
                                                style={{ cursor: "pointer" }}
                                            >
                                                Version {item.version + 1}
                                            </ListGroupItem>
                                        );
                                    }
                                )}
                            </ListGroup>
                        </Col>
                    )}
            </Row>
        </>
    );
};

import api from "../utils/api";

export const createDeliverable = (data) => {
    return api.post("/deliverable", data);
};

export const updateDeliverable = (data, id) => {
    return api.post("/deliverable/" + id, data);
};

export const getDeliverable = (id, data) => {
    return api.get("/deliverable/" + id, { data });
};

export const getDeliverableList = (data, category) => {
    return api.get(`/deliverable/${category}`, { params: data });
};

export const getDeliverableConfig = (data) => {
    return api.get(`/config/deliverable`, data);
};
export const getDeliverableSections = (category) => {
    return api.get(`/deliverable/config/${category}`, {});
};

export const approval = (id, data) => {
    return api.post(`/deliverable/${id}/final-approvals`, data);
};

export const pendingForDeliverableApproval = () => {
    return api.get(`/deliverable/pending-approvals`, {});
};

export const getDeliverableSummary = (id) => {
    return api.get(`/deliverable/${id}/inspection-summary`);
};

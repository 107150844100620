import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";
import NotyfContext from "contexts/NotyfContext";
import { useCallback } from "react";
import { DeleteModal } from "../../../components/DeleteModal";
import * as attachmentApi from "@api/attachmentApi";
import * as inspectionTreeChecklistApi from "@api/inspectionTreeChecklistApi";

export const TreeInspectionViewEvidenceForm = () => {
    const { tree_inspection_id, tree_checklist_item_id } = useParams();
    const { reset } = useForm({
        mode: "onTouched",
    });
    const navigate = useNavigate();

    const [cameraSupportingImages, setCameraSupportingImages] = useState([]);
    // eslint-disable-next-line
    const [supportingPhotos, setSupportingPhotos] = useState([]);
    const [supportingDocuments, setSupportingDocuments] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const notyf = useContext(NotyfContext);
    const [inspectionSchedule, setInspectionSchedule] = useState([]);
    const [rerender, setRerender] = useState(false);

    const getInspectionSchedule = useCallback(async () => {
        try {
            const response =
                await inspectionTreeChecklistApi.getInspectionTreeChecklist(
                    tree_inspection_id
                );

            if (response.status === 200) {
                const data = response.data.data;
                const attachments = [];

                const treeChecklistItems = data.tree_checklist_items.map(
                    (treeChecklistItem) => treeChecklistItem?.children
                );

                treeChecklistItems?.forEach((treeChecklistItem) => {
                    treeChecklistItem?.filter((tItem) => {
                        if (tItem?.sequence === tree_checklist_item_id) {
                            attachments.push(tItem?.attachments);
                        }

                        return tItem;
                    });
                });

                if (attachments[0].length) {
                    setSupportingDocuments(attachments[0]);
                } else {
                    setSupportingDocuments([]);
                }

                setInspectionSchedule(data);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [tree_inspection_id, notyf, tree_checklist_item_id]);

    const deleteApi = async (id) => {
        try {
            const response = await attachmentApi.deleteAttachment(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "Successfully deleted file",
                });
                setRerender(true);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    useEffect(() => {
        getInspectionSchedule();
    }, [getInspectionSchedule, tree_inspection_id, reset, rerender]);

    return (
        <React.Fragment>
            <Helmet title="Create evidence | Tree inspection checklist" />
            <Container fluid className="p-0">
                <Row>
                    <Row style={{ marginBottom: "20px" }}>
                        <Col md={12}>
                            <h3>Tree inspections</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "20px" }}>
                        <Col md={6}>
                            <h4 className="h4 mb-3">
                                {inspectionSchedule?.school?.name}
                            </h4>
                        </Col>
                        <Col md={6} style={{ textAlign: "right" }}>
                            <h4 className="h4 mb-3">
                                {inspectionSchedule?.tree_inspected_at}
                            </h4>
                        </Col>
                    </Row>
                </Row>

                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-4">
                                                <Form.Label className="font-weight-bold">
                                                    Inspection images
                                                </Form.Label>
                                                {inspectionSchedule?.status ===
                                                "Submitted" ? (
                                                    <FileUploaderSyncfusion
                                                        setUpload={
                                                            setSupportingPhotos
                                                        }
                                                        setModalInfo={
                                                            setModalInfo
                                                        }
                                                        document={
                                                            supportingDocuments
                                                        }
                                                        action={
                                                            tree_inspection_id
                                                        }
                                                        setCameraUpload={
                                                            setCameraSupportingImages
                                                        }
                                                        cameraPhotos={
                                                            cameraSupportingImages
                                                        }
                                                        canUpload={false}
                                                        canDelete={false}
                                                    />
                                                ) : (
                                                    <FileUploaderSyncfusion
                                                        setUpload={
                                                            setSupportingPhotos
                                                        }
                                                        setModalInfo={
                                                            setModalInfo
                                                        }
                                                        document={
                                                            supportingDocuments
                                                        }
                                                        action={
                                                            tree_inspection_id
                                                        }
                                                        setCameraUpload={
                                                            setCameraSupportingImages
                                                        }
                                                        cameraPhotos={
                                                            cameraSupportingImages
                                                        }
                                                        // canUpload={
                                                        //     !(
                                                        //         inspectionSchedule?.status !==
                                                        //         "Draft"
                                                        //     )
                                                        // }
                                                        canUpload={false}
                                                        canDelete={
                                                            !(
                                                                inspectionSchedule?.status !==
                                                                "Draft"
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row className="pt-4">
                            <Col className="text-end">
                                <Button
                                    variant="link"
                                    className="me-2"
                                    onClick={() =>
                                        navigate(
                                            `/inspection-checklist/tree-inspections/${tree_inspection_id}`
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Container>
            <DeleteModal
                api={deleteApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
        </React.Fragment>
    );
};

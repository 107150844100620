import React, { useCallback, useContext, useState } from "react";

import DatePicker from "react-datepicker";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import { Tabs } from "./Tabs";
import { SignaturesForm } from "components/ui/SignatureForm";
import { LoadingContext } from "App";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { moneyFormatter, toMonthName, toTitleCase } from "utils/utilities";
import * as copApi from "@api/copApi";

export const Cover = ({ information }) => {
    const { control, setValue, handleSubmit, reset } = useForm({
        mode: "onTouched",
    });
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    let { action } = useParams();
    const notyf = useContext(NotyfContext);
    const navigate = useNavigate();
    const { hasRole } = useAuth();
    const [rerender, setRerender] = useState(false);

    const hasRoleCollection = {
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isQS: hasRole("MA-QS"),
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isAdmin: hasRole("ADMIN"),
    };

    //
    // States
    //
    const [details, setDetails] = useState({});

    //
    // Functions
    //

    const getDetails = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await copApi.getCop(action, {
                    category: "cover",
                });

                if (response.status === 200) {
                    setDetails(response.data.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const submitCop = async (data) => {
        const formData = new FormData();

        data.prepare_details.signature_details &&
            formData.append(
                "signature_details",
                data.prepare_details.signature_details || false
            );
        formData.append("signature_options", "my_signature");
        formData.append("signature", data.prepare_details.signature);
        formData.append("status", "PENDING");
        formData.append(
            "claim_date",
            new Date(data.claim_date).toLocaleDateString("en-CA")
        );
        formData.append("payment", parseFloat(details?.payment).toFixed(2));
        formData.append("gst", details?.gst.replace("%", ""));
        formData.append(
            "gst_amount",
            parseFloat(details?.gst_amount).toFixed(2)
        );
        formData.append("sub_total", parseFloat(details?.sub_total).toFixed(2));
        formData.append(
            "liquidated_damages",
            parseFloat(details?.liquidated_damages).toFixed(2)
        );
        formData.append(
            "grand_total",
            parseFloat(details?.total_amount).toFixed(2)
        );
        formData.append(
            "kpi_percentage",
            details?.kpi_percentage
                ? parseFloat(details?.kpi_percentage).toFixed(2)
                : ""
        );
        formData.append(
            "kpi_percentage_amount",
            details?.kpi_percentage_amount
                ? parseFloat(details?.kpi_percentage_amount).toFixed(2)
                : ""
        );
        formData.append(
            "kpi_type",
            details?.kpi_type ? parseFloat(details?.kpi_type).toFixed(2) : ""
        );

        try {
            setIsLoadingActive(true);
            const response = await copApi.submitCop(action, formData);

            if (response.status === 200) {
                setDetails(response.data.data);
                setIsLoadingActive(false);
                setRerender(!rerender);
                notyf.open({
                    type: "success",
                    message: "Successfully approved COP",
                });
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const setReset = () => {
        reset({
            prepare_details: {
                signature_options:
                    information.prepare_details?.signature_options,
                signature_details:
                    information.prepare_details?.signature_details,
                signature: information?.prepare_signature,
                name: information.prepare_details?.name,
            },
            claim_date:
                information?.claim_date && new Date(information?.claim_date),

            // ma_details: information.approvals?.MA?.details
            //     ? {
            //           signature_options:
            //               information.approvals?.MA?.details?.signature_options,
            //           signature_details:
            //               information.approvals?.MA?.details?.signature_details,
            //           name: information.approvals?.MA?.details?.name,
            //           signature: information.approvals?.MA?.signature,
            //           status: information.approvals?.MA?.status,
            //           fund: information.approvals?.MA?.details?.source_fund,
            //           status_remarks: information.approvals?.MA?.remarks || "",
            //       }
            //     : {
            //           status: "VERIFIED",
            //       },
        });
    };

    const getKpiPercentage = () => {
        let type = "";
        type = details?.kpi_type === "ADDITIONAL" ? "+" : "-";
        return `${type}${details?.kpi_percentage}%`;
    };

    const getKpiPercentageAmount = () => {
        return details?.kpi_type === "ADDITIONAL"
            ? moneyFormatter(details?.kpi_percentage_amount)
            : `(${moneyFormatter(details?.kpi_percentage_amount)})`;
    };
    //
    // UseEffects
    //

    useEffect(() => {
        getDetails();
    }, [getDetails, rerender]);

    useEffect(() => {
        if (information?.status === "PENDING") setReset();
    }, [information]);

    return (
        <>
            <Tabs />
            <hr />
            <Row className="mt-4">
                <Col md={12}>
                    <Form.Label className="font-weight-bold">
                        Reference number:
                    </Form.Label>
                    <span className="ms-2">
                        {information?.reference_number}
                    </span>
                </Col>
                <Col md={12}>
                    <Form.Label className="font-weight-bold">
                        Contract number:
                    </Form.Label>
                    <span className="ms-2">MOE/SCD/LA/FM/2022/JUN/T14</span>
                </Col>
                <Col md={12} className="mt-3">
                    <Form.Label className="font-weight-bold">
                        Ministry of Education
                    </Form.Label>
                    <div>
                        <p className="mb-0">School Campus Department</p>
                        <p className="mb-0">285 Ghim Moh Road</p>
                        <p className="mb-0">Block C Level 3</p>
                        <p className="mb-0">Singapore 279622</p>
                    </div>
                </Col>
                <Col md={12} className="my-3">
                    <Form.Label className="font-weight-bold">
                        Attention:
                    </Form.Label>
                    <span className="ms-2">{details.scd_name}</span>
                </Col>
                <Col md={12}>
                    <p className="text-center px-3">
                        Term contract for provision of building, turfing &
                        planting maintenance works, additions & alterations
                        works and general repairs & redecoration works to sites{" "}
                        <span className="text-danger">
                            ({toTitleCase(details.zone)})
                        </span>{" "}
                        for a period of forty-eight (48) months{" "}
                        <span className="text-danger">
                            for the month of {toMonthName(information?.month)}
                        </span>
                    </p>
                </Col>
            </Row>
            <Table bordered className="mt-4" size="sm">
                <thead>
                    <tr>
                        <th colSpan={2} className="text-end">
                            Contract sum:
                        </th>
                        <th className="text-end currency" width="18%">
                            74,094,000.00
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={2} className="text-end">
                            Progress claim number:
                        </th>
                        <th className="text-end">
                            {information?.payment_claim_no || "-"}
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={2} className="text-end">
                            For the month:
                        </th>
                        <th className="text-end">
                            {toMonthName(information?.month)}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3}>
                            We refer to the above captioned and append below our
                            summary for progress claim:{" "}
                            {information?.payment_claim_no}
                        </td>
                    </tr>

                    {details?.schoolTypes &&
                        Object.entries(details?.schoolTypes).map(
                            ([key, value], index) => {
                                return (
                                    <tr key={index + 1}>
                                        <td className="text-end">
                                            {index + 1}
                                        </td>
                                        <td>{key}</td>
                                        <td className="text-end currency">
                                            {parseFloat(value?.payment)
                                                ? moneyFormatter(value?.payment)
                                                : "-"}
                                        </td>
                                    </tr>
                                );
                            }
                        )}

                    <tr style={{ borderTop: "2px solid #6c757d" }}>
                        <td colSpan={2} className="text-end">
                            Total for the month of September 2022
                        </td>
                        <td className="text-end currency">
                            {parseFloat(details?.total_month_amount)
                                ? moneyFormatter(details?.total_month_amount)
                                : "-"}
                        </td>
                    </tr>
                    <tr style={{ borderTop: "2px solid #6c757d" }}>
                        <td colSpan={2} className="text-end">
                            KPI percentage
                        </td>
                        <td className="text-end">
                            {details?.kpi_type && details?.kpi_percentage
                                ? getKpiPercentage()
                                : "-"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-end">
                            KPI percentage value
                        </td>
                        <td className="text-end currency">
                            {details?.kpi_type && details?.kpi_percentage
                                ? getKpiPercentageAmount()
                                : "-"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-end">
                            Deduct liquidated damages
                        </td>
                        <td className="text-end currency">
                            {parseFloat(details?.liquidated_damages)
                                ? moneyFormatter(details?.liquidated_damages)
                                : "-"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-end">
                            Sub-total
                        </td>
                        <td className="text-end currency">
                            {parseFloat(details?.sub_total)
                                ? moneyFormatter(details?.sub_total)
                                : "-"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-end">
                            Add GST {details?.gst}
                        </td>
                        <td className="text-end currency">
                            {parseFloat(details?.gst_amount)
                                ? moneyFormatter(details?.gst_amount)
                                : "-"}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-end">
                            Grand total
                        </td>
                        <td className="text-end currency">
                            {parseFloat(details?.total_amount)
                                ? moneyFormatter(details?.total_amount)
                                : "-"}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Row>
                <Col md={12} className="my-4">
                    We are pleased to furnish the following documents for your
                    action.
                </Col>
                <Col md={12} className="mb-2">
                    <Form.Label>
                        <u>Schedule maintenance work:</u>
                    </Form.Label>
                    <div>
                        <p className="mb-0">A) Monthly management report </p>
                        <p className="mb-0">
                            B) Monthly completion of contracted works
                        </p>
                    </div>
                </Col>
                <Col md={12} className="mb-2">
                    <Form.Label>
                        <u>Custodial repairs:</u>
                    </Form.Label>
                    <div>
                        <p className="mb-0">A) Monthly defect clearance </p>
                    </div>
                </Col>
                <Col md={12} className="mb-2">
                    <Form.Label>
                        <u>Schedule maintenance of turf and plants:</u>
                    </Form.Label>
                    <div>
                        <p className="mb-0">
                            A) Wei Shen Monthly management report{" "}
                        </p>
                        <p className="mb-0">
                            B) Monthly completion of contracted works
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label className="font-weight-bold">
                            Claim date
                        </Form.Label>
                        <Controller
                            control={control}
                            defaultValue=""
                            name="claim_date"
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <>
                                    {information?.status === "PENDING" ? (
                                        <p>
                                            {new Date(value).toLocaleDateString(
                                                "en-CA"
                                            )}
                                        </p>
                                    ) : (
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={value}
                                            onChange={onChange}
                                            className={`form-box form-control`}
                                            value={value}
                                        />
                                    )}
                                </>
                            )}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <SignaturesForm
                    control={control}
                    canSign={
                        hasRoleCollection?.isTermContractor &&
                        information?.status !== "PENDING"
                    }
                    label="Prepared by"
                    name="prepare_details"
                    setValue={setValue}
                />
                <SignaturesForm
                    control={control}
                    canSign={
                        hasRoleCollection.isManagingAgent &&
                        information?.status !== "PENDING"
                    }
                    label="Checked & certified correct by"
                    name="ma_details"
                    setValue={setValue}
                />
                <Col md={12} className="text-center">
                    {information?.status === "DRAFT" && (
                        <Button
                            className="me-2"
                            variant="primary"
                            type="submit"
                            onClick={handleSubmit((data) => submitCop(data))}
                        >
                            Submit
                        </Button>
                    )}
                    <Button
                        variant="link"
                        onClick={() => navigate(`/cop/contracts`)}
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
        </>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";

import { Col, Row, Table } from "react-bootstrap";

import { Tabs } from "./Tabs";
import { LoadingContext } from "App";
import { useParams } from "react-router-dom";
import { moneyFormatter } from "utils/utilities";
import { Information } from "./Information";
import * as copApi from "@api/copApi";
import NotyfContext from "contexts/NotyfContext";

export const Overall = ({ information }) => {
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    let { action, tab } = useParams();
    const notyf = useContext(NotyfContext);

    //
    // States
    //
    let totalScheduledMaintenance = 0;
    let totalGeneralRandR = 0;
    let totalCustodialRepairs = 0;
    let totalSchoolField = 0;
    let totalLandscapeExludeSchoolField = 0;
    let totalSyntheticField = 0;
    let totalLandscapePond = 0;
    let totalPayment = 0;
    let liquidatedDamages = 0;

    let totalAccumalitative = 0;
    let totalThisPayment = 0;

    const [details, setDetails] = useState({});
    const [accumulatativeDetails, setAccumulatativeDetails] = useState({});

    //
    // Functions
    //

    const getDetails = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await copApi.getCop(action, {
                    category: tab,
                });

                if (response.status === 200) {
                    setDetails(response.data.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const getAccumulatativeDetails = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await copApi.getCop(action, {
                    category: "cover",
                });

                if (response.status === 200) {
                    setAccumulatativeDetails(response.data.data.schoolTypes);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    //
    // UseEffects
    //

    useEffect(() => {
        getDetails();
        getAccumulatativeDetails();
    }, [getDetails, getAccumulatativeDetails]);

    return (
        <>
            <Tabs />
            <hr />
            <Information information={information} />
            <Table bordered responsive className="table-layout-fixed">
                <thead>
                    <tr>
                        <th width="250">Description</th>
                        <th className="text-end" width="150">
                            Schedule maintenance
                        </th>
                        <th className="text-end" width="150">
                            General R&R
                        </th>
                        <th className="text-center" width="150">
                            Custodial repairs
                        </th>
                        <th className="text-end" width="150">
                            School field
                        </th>
                        <th className="text-end" width="150">
                            Landscape area, side tables, planter beds etc
                        </th>
                        <th className="text-end" width="150">
                            Synthetic field
                        </th>
                        <th className="text-end" width="150">
                            Landscape pond
                        </th>
                        <th className="text-end" width="150">
                            Liquidated damages
                        </th>
                        <th className="text-end" width="150">
                            This payment
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(details)
                        ? Object.values(details).map((value, key, row) => {
                              totalScheduledMaintenance +=
                                  +value.SCHEDULE_MAINTENANCE;
                              totalGeneralRandR += +value.GENERAL_R_AND_R;
                              totalCustodialRepairs += +value.CUSTODIAL_REPAIRS;
                              totalSchoolField += +value.SCHOOL_FIELD;
                              totalLandscapeExludeSchoolField +=
                                  +value.LANDSCAPE_EXCLUDE_SCHOOL_FIELD;
                              totalSyntheticField += +value.SYNTHETIC_FIELD;
                              totalLandscapePond += +value.LANDSCAPE_POND;
                              totalPayment += +value.PAYMENT;
                              liquidatedDamages += +value.LD;

                              return (
                                  <React.Fragment key={key}>
                                      <tr>
                                          <td>{Object.keys(details)[key]}</td>
                                          <td className="text-end currency">
                                              {value.SCHEDULE_MAINTENANCE
                                                  ? moneyFormatter(
                                                        value.SCHEDULE_MAINTENANCE
                                                    )
                                                  : "-"}
                                          </td>
                                          <td className="text-end currency">
                                              {value.GENERAL_R_AND_R
                                                  ? moneyFormatter(
                                                        value.GENERAL_R_AND_R
                                                    )
                                                  : "-"}
                                          </td>
                                          <td className="text-end currency">
                                              {value.CUSTODIAL_REPAIRS
                                                  ? moneyFormatter(
                                                        value.CUSTODIAL_REPAIRS
                                                    )
                                                  : "-"}
                                          </td>
                                          <td className="text-end currency">
                                              {value.SCHOOL_FIELD
                                                  ? moneyFormatter(
                                                        value.SCHOOL_FIELD
                                                    )
                                                  : "-"}
                                          </td>
                                          <td className="text-end currency">
                                              {value.LANDSCAPE_EXCLUDE_SCHOOL_FIELD
                                                  ? moneyFormatter(
                                                        value.LANDSCAPE_EXCLUDE_SCHOOL_FIELD
                                                    )
                                                  : "-"}
                                          </td>
                                          <td className="text-end currency">
                                              {value.SYNTHETIC_FIELD
                                                  ? moneyFormatter(
                                                        value.SYNTHETIC_FIELD
                                                    )
                                                  : "-"}
                                          </td>
                                          <td className="text-end currency">
                                              {value.LANDSCAPE_POND
                                                  ? moneyFormatter(
                                                        value.LANDSCAPE_POND
                                                    )
                                                  : "-"}
                                          </td>
                                          <td className="text-end currency">
                                              {value.LD
                                                  ? moneyFormatter(value.LD)
                                                  : "-"}
                                          </td>
                                          <td className="text-end currency">
                                              {value.PAYMENT
                                                  ? moneyFormatter(
                                                        value.PAYMENT
                                                    )
                                                  : "-"}
                                          </td>
                                      </tr>
                                  </React.Fragment>
                              );
                          })
                        : ""}
                    <tr className="font-weight-bold">
                        <td className="text-end">Total</td>
                        <td className="text-end">
                            {totalScheduledMaintenance
                                ? moneyFormatter(totalScheduledMaintenance)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalGeneralRandR
                                ? moneyFormatter(totalGeneralRandR)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalCustodialRepairs
                                ? moneyFormatter(totalCustodialRepairs)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalSchoolField
                                ? moneyFormatter(totalSchoolField)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalLandscapeExludeSchoolField
                                ? moneyFormatter(
                                      totalLandscapeExludeSchoolField
                                  )
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalSyntheticField
                                ? moneyFormatter(totalSyntheticField)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalLandscapePond
                                ? moneyFormatter(totalLandscapePond)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {liquidatedDamages
                                ? moneyFormatter(liquidatedDamages)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalPayment ? moneyFormatter(totalPayment) : "-"}
                        </td>
                    </tr>
                </tbody>
            </Table>

            <Row className="mt-4">
                <Col md={8}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th className="text-end">
                                    Accumulative previously paid
                                </th>
                                <th className="text-end">This payment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(accumulatativeDetails)
                                ? Object.values(accumulatativeDetails).map(
                                      (value, key, row) => {
                                          totalAccumalitative +=
                                              +value?.cumulative_payment;
                                          totalThisPayment += +value?.payment;

                                          return (
                                              <React.Fragment key={key}>
                                                  <tr>
                                                      <td>
                                                          {
                                                              Object.keys(
                                                                  accumulatativeDetails
                                                              )[key]
                                                          }
                                                      </td>
                                                      <td className="text-end currency">
                                                          {parseFloat(
                                                              value?.cumulative_payment
                                                          )
                                                              ? moneyFormatter(
                                                                    value?.cumulative_payment
                                                                )
                                                              : "-"}
                                                      </td>
                                                      <td className="text-end currency">
                                                          {parseFloat(
                                                              value?.payment
                                                          )
                                                              ? moneyFormatter(
                                                                    value?.payment
                                                                )
                                                              : "-"}
                                                      </td>
                                                  </tr>
                                              </React.Fragment>
                                          );
                                      }
                                  )
                                : ""}
                            <tr className="font-weight-bold">
                                <td className="text-end">Total</td>
                                <td className="text-end currency">
                                    {totalAccumalitative
                                        ? moneyFormatter(totalAccumalitative)
                                        : "-"}
                                </td>
                                <td className="text-end currency">
                                    {totalThisPayment
                                        ? moneyFormatter(totalThisPayment)
                                        : "-"}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};

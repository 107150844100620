import api from "../utils/api";

export const logIn = (data) => {
    return api.post("/auth/login", data);
};

export const logOut = (data) => {
    return api.post("/auth/logout", data);
};

export const forgotPassword = (data) => {
    return api.post("/forgot-password", data);
};

export const resetPassword = (data) => {
    return api.post("/reset-password", data);
};

import { roundToTwoDecimal } from "./utilities";

const totalQuotaionComputation = (
    quotationItems,
    rates,
    isClaims = false,
    percent = 100,
    gstConfig = 8
) => {
    let sub_total = 0;
    let gst = 0;

    let custodial_total = 0;
    let custodial_count = 0;
    let fsr_total = 0;
    let fsr_less = 0;
    let fsr_sub_total = 0;

    let star_rate_total = 0;
    let star_rate_add = 0;
    let star_rate_sub_total = 0;

    let fsr_final_total = 0;

    let claim_sub_total = 0;
    let claim_gst = 0;

    quotationItems.forEach((row) => {
        if (!row.header) {
            if (row.subRows) {
                row.subRows.forEach((subRow) => {
                    custodial_count += parseFloat(subRow.quantity);
                    custodial_total += subRow.amount;
                });
            }

            if (row.is_star_rate) {
                star_rate_total += parseFloat(row.amount);
            }

            if (!row.is_star_rate) sub_total += parseFloat(row.amount);
        }
    });

    //
    // Intial sub total (Without star rate or custodial discount)
    //
    sub_total = parseFloatToFixed(sub_total);
    fsr_final_total = sub_total;

    //
    // Calculate for custodial discount sub total
    //
    custodial_total = parseFloatToFixed(custodial_total);

    fsr_total = custodial_total
        ? parseFloatToFixed(sub_total - custodial_total)
        : sub_total;

    const fsr_round_to_fourth_dec = +(
        (fsr_total / 100) *
        rates?.less_percent
    ).toFixed(4);

    fsr_less = roundToTwoDecimal(fsr_round_to_fourth_dec);

    fsr_sub_total = parseFloatToFixed(fsr_total - fsr_less);

    //
    // Calculate for star rate sub total
    //
    if (star_rate_total) {
        star_rate_add = parseFloatToFixed(
            (star_rate_total / 100) * rates?.star_rate
        );
        star_rate_sub_total = parseFloatToFixed(
            star_rate_total + star_rate_add
        );
    }

    //
    // Compute for final total and gst
    //

    if (star_rate_total && fsr_sub_total) {
        sub_total = parseFloatToFixed(star_rate_sub_total + fsr_sub_total);
    } else if (fsr_sub_total) {
        sub_total = parseFloatToFixed(fsr_sub_total);
    } else if (star_rate_total) {
        sub_total = parseFloatToFixed(star_rate_sub_total + sub_total);
    }

    //
    // Compute claims percentage
    //

    if (Boolean(isClaims && percent)) {
        claim_sub_total = 0;
        claim_sub_total = (parseFloat(percent) / 100) * sub_total;
        claim_gst = parseFloatToFixed((claim_sub_total / 100) * gstConfig);
    }

    parseFloatToFixed(sub_total);
    //Find the Third Decimal and check if it Greater than 4
    const rawGST = (sub_total / 100) * gstConfig;
    const isGreaterThan4 = Math.floor((rawGST * 1000) % 10) > 4;

    if (isGreaterThan4) {
        //Round Off
        gst = roundToTwoDecimal(parseFloatToFixed((sub_total / 100) * gstConfig));
    } else {
        //Same values but will be to two decimal
        gst = Math.trunc(rawGST * 100) / 100;
    }
    // gst = roundToTwoDecimal(parseFloatToFixed((sub_total / 100) * gstConfig));

    return {
        sub_total,
        gst,
        custodial_total,
        custodial_count,
        fsr_total,
        fsr_less,
        fsr_sub_total,
        fsr_final_total,
        star_rate_total,
        star_rate_add,
        star_rate_sub_total,
        claim_sub_total,
        claim_gst,
    };
};

const parseFloatToFixed = (data) => {
    return parseFloat(data.toFixed(3));
};

export { totalQuotaionComputation };

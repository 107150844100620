import React, { useCallback, useContext, useEffect, useState } from "react";

import { Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import { QuotationTable } from "./Components/QuotationTable";
import { ColumnChart } from "./Charts/Column";
import { LineChart } from "./Charts/Line";
import { UnderConstruction } from "components/UnderConstruction";
import { toTitleCase } from "../../utils/utilities";
import * as schoolApi from "@api/schoolApi";
import * as dashboardApi from "@api/dashboardApi";

export const DashboardQuotation = () => {
    const notyf = useContext(NotyfContext);
    const { hasRole } = useAuth();

    const isSCD = hasRole("SCD");
    const isManagingAgent = hasRole("MANAGING-AGENT");
    const isOM = hasRole("OPERATIONS-MANAGER");
    const isTC = hasRole("TERM-CONTRACTOR");

    // eslint-disable-next-line
    const [schools, setSchools] = useState([]);
    const [quotationSummaryZone, setQuotationSummaryZone] = useState({});
    const [quotationSummaryMonth, setQuotationSummaryMonth] = useState({});

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const getQuotationSummaryZone = useCallback(async () => {
        try {
            const response = await dashboardApi.getQuotationSummaryZone();
            const data = response.data.data;

            setQuotationSummaryZone(data);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const getQuotationSummaryMonth = useCallback(async () => {
        try {
            const response = await dashboardApi.getQuotationSummaryMonth();
            const data = response.data.data;

            setQuotationSummaryMonth(data);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    useEffect(() => {
        getQuotationSummaryZone();
        getQuotationSummaryMonth();
    }, []);

    return (
        <>
            <Helmet title="Dashboard - Quotation" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Dashboard - Quotation</h4>
                {isSCD || isManagingAgent || isOM || isTC ? (
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={12}>
                                    {quotationSummaryZone && (
                                        <QuotationTable
                                            title="Accumulated quotation"
                                            type="Zone"
                                            data={quotationSummaryZone}
                                        />
                                    )}
                                </Col>

                                {quotationSummaryZone?.north_east && (
                                    <Col md="6">
                                        <ColumnChart
                                            title="Summary"
                                            type="Zone"
                                            zone="North east"
                                            quotationData={
                                                quotationSummaryZone?.north_east
                                            }
                                        />
                                    </Col>
                                )}
                                {quotationSummaryZone?.south && (
                                    <Col md="6">
                                        <ColumnChart
                                            title="Summary"
                                            type="Zone"
                                            zone="South"
                                            quotationData={
                                                quotationSummaryZone?.south
                                            }
                                        />
                                    </Col>
                                )}
                                {isOM &&
                                    Object.values(quotationSummaryZone).map(
                                        (value, key) => {
                                            return (
                                                <Col md="6" key={key}>
                                                    <ColumnChart
                                                        title="Summary"
                                                        type="School"
                                                        zone={toTitleCase(
                                                            Object.keys(
                                                                quotationSummaryZone
                                                            )[key]
                                                        )}
                                                        quotationData={value}
                                                    />
                                                </Col>
                                            );
                                        }
                                    )}
                            </Row>
                            <Row>
                                <Col md={12} className="mt-2">
                                    {quotationSummaryMonth?.north_east && (
                                        <QuotationTable
                                            title="Accumulated quotation"
                                            type="Month"
                                            zone="North east"
                                            data={
                                                quotationSummaryMonth?.north_east
                                            }
                                        />
                                    )}
                                </Col>
                                <Col md={12} className="mt-2">
                                    {quotationSummaryMonth?.south && (
                                        <QuotationTable
                                            title="Accumulated quotation"
                                            type="Month"
                                            zone="South"
                                            data={quotationSummaryMonth?.south}
                                        />
                                    )}
                                </Col>
                                {quotationSummaryMonth?.north_east && (
                                    <Col md="6" className="mt-2">
                                        <LineChart
                                            title="Summary"
                                            type="Month"
                                            zone="North east"
                                            quotationData={
                                                quotationSummaryMonth?.north_east
                                            }
                                        />
                                    </Col>
                                )}

                                {quotationSummaryMonth?.south && (
                                    <Col md="6">
                                        <LineChart
                                            title="Summary"
                                            type="Month"
                                            zone="South"
                                            quotationData={
                                                quotationSummaryMonth?.south
                                            }
                                        />
                                    </Col>
                                )}
                                {isOM &&
                                    Object.values(quotationSummaryMonth).map(
                                        (value, key) => {
                                            return (
                                                <Col md="12" key={key}>
                                                    <QuotationTable
                                                        title="Accumulated quotation"
                                                        type="Month"
                                                        zone={toTitleCase(
                                                            Object.keys(
                                                                quotationSummaryMonth
                                                            )[key]
                                                        )}
                                                        data={value}
                                                    />
                                                </Col>
                                            );
                                        }
                                    )}

                                {isOM &&
                                    Object.values(quotationSummaryMonth).map(
                                        (value, key) => {
                                            return (
                                                <Col md="6" key={key}>
                                                    <LineChart
                                                        title="Summary"
                                                        type="School"
                                                        zone={toTitleCase(
                                                            Object.keys(
                                                                quotationSummaryMonth
                                                            )[key]
                                                        )}
                                                        quotationData={value}
                                                    />
                                                </Col>
                                            );
                                        }
                                    )}
                            </Row>
                        </Card.Body>
                    </Card>
                ) : (
                    <UnderConstruction />
                )}
            </Container>
        </>
    );
};

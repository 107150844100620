import React from "react";

import { Col, Row, Table } from "react-bootstrap";

export const ApvTable = ({ defects, type }) => {
    return (
        <>
            <Row>
                <Col md={6}>
                    <Table bordered size="md">
                        <tbody>
                            <tr>
                                <th className="bg-warning text-black">
                                    Total quotation amount
                                </th>
                                <td className="currency text-end">
                                    {defects?.[`${type.toUpperCase()}`]
                                        ?.total_quotation_amount || 0}
                                </td>
                            </tr>
                            <tr>
                                <th className="bg-warning text-black">
                                    Total claim amount
                                </th>
                                <td className="currency text-end">
                                    {defects?.[`${type.toUpperCase()}`]
                                        ?.total_claim_amount || 0}
                                </td>
                            </tr>
                            <tr>
                                <th className="bg-warning text-black">
                                    Total invoice amount
                                </th>
                                <td className="currency text-end">
                                    {defects?.[`${type.toUpperCase()}`]
                                        ?.total_invoice_amount || 0}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Table bordered responsive className="table-layout-fixed">
                <thead>
                    <tr>
                        <th className="text-end" width="80">
                            S/N
                        </th>
                        <th className="text-end" width="250">
                            Quotation reference number
                        </th>
                        {type.toUpperCase() === "MOE" && (
                            <>
                                <th className="text-end" width="150">
                                    EPI reference number
                                </th>
                                <th className="text-end" width="150">
                                    EPO reference number
                                </th>
                            </>
                        )}
                        <th className="text-end" width="150">
                            Quotation date
                        </th>
                        <th className="text-end" width="150">
                            Approved on
                        </th>
                        {type.toUpperCase() === "MOE" && (
                            <th className="text-end" width="150">
                                WSO date
                            </th>
                        )}
                        <th className="text-end" width="150">
                            Claim date
                        </th>
                        <th className="text-end" width="150">
                            Invoice date
                        </th>
                        <th width="250">School</th>
                        <th width="200">Job</th>
                        <th className="text-end" width="200">
                            Quotation amount
                        </th>
                        <th className="text-end" width="200">
                            Claim submit amount
                        </th>
                        <th className="text-end" width="200">
                            Invoice amount
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {defects[type.toUpperCase()] ? (
                        <>
                            {defects?.[`${type.toUpperCase()}`]?.data.map(
                                (data, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className="text-end">
                                                {key + 1}
                                            </td>
                                            <td className="text-end">
                                                {data.quotation_reference ||
                                                    "-"}
                                            </td>
                                            {type.toUpperCase() === "MOE" && (
                                                <>
                                                    <td className="text-end">
                                                        {data.epi_reference_no ||
                                                            "-"}
                                                    </td>
                                                    <td className="text-end">
                                                        {data.epo_reference_no ||
                                                            "-"}
                                                    </td>
                                                </>
                                            )}
                                            <td className="text-end">
                                                {data.quotation_date.split(
                                                    " "
                                                )[0] || "-"}
                                            </td>
                                            <td className="text-end">
                                                {data.approved_on
                                                    ? data.approved_on.split(
                                                          " "
                                                      )[0]
                                                    : "-"}
                                            </td>
                                            <td className="text-end">
                                                {data.wso_date || "-"}
                                            </td>
                                            <td className="text-end">
                                                {data.claim_date
                                                    ? data.claim_date.split(
                                                          " "
                                                      )[0]
                                                    : "-"}
                                            </td>
                                            <td className="text-end">
                                                {data.invoice_date
                                                    ? data.invoice_date.split(
                                                          " "
                                                      )[0]
                                                    : "-"}
                                            </td>
                                            <td>{data.school || "-"}</td>
                                            <td>{data.job || "-"}</td>
                                            <td className="currency text-end">
                                                {data.quotation_amount || "-"}
                                            </td>
                                            <td className="currency text-end">
                                                {data.claim_submit_amount ||
                                                    "-"}
                                            </td>
                                            <td className="currency text-end">
                                                {data.invoice_amount || "-"}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </>
                    ) : (
                        <tr>
                            <td colSpan="6">No data</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};

import React from "react";

import { Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export const Tabs = () => {
    const { action, tab } = useParams();

    return (
        <Row>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/cover`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "cover" ? "primary" : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Cover
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/overall`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "overall" ? "primary" : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Overall
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/summary`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "summary" ? "primary" : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Summary
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/schedule_maintenance`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "schedule_maintenance"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Maintenance
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/custodial_repairs`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "custodial_repairs"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Custodial repairs
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/general_r_and_r`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "general_r_and_r"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        GR&R
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/school_field`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "school_field"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        School field
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/synthetic_field`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "synthetic_field"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Synthetic field
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/landscape_exclude_school_field`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "landscape_exclude_school_field"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Landscape
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/landscape_pond`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "landscape_pond"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Landscape pond
                    </Button>
                </a>
            </Col>
            <Col md={2}>
                <a
                    href={`/cop/contracts/${action}/landscape_garden`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "landscape_garden"
                                ? "primary"
                                : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        Landscape garden
                    </Button>
                </a>
            </Col>
        </Row>
    );
};

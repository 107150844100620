import React, { useState } from "react";

import { Row } from "react-bootstrap";

import UnderConstructionImage from "../assets/img/under-construction.png";

export const UnderConstruction = () => {
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;

    return (
        <>
            <Row className="justify-content-md-center">
                {/* <img
                    src={UnderConstructionImage}
                    alt="Under contruction"
                    style={{
                        maxWidth: isMobile ? "80px" : "300px",
                    }}
                    className="my-3"
                /> */}
                <div className="text-center">
                    {/* <p
                        className="font-weight-bold mb-2"
                        style={{ fontSize: "18px" }}
                    >
                        This page is under construction.
                    </p> */}
                    <p>
                        If you have any questions, please contact our support
                        team by sending an email at
                        <br />
                        <a href="mailto:proj_moe@fondaglobal.com">
                            proj_moe@fondaglobal.com
                        </a>
                    </p>
                </div>
            </Row>
        </>
    );
};

import React, { useContext, useState, useEffect } from "react";

import {
    Button,
    Col,
    Container,
    Form,
    Image,
    Modal,
    Row,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import Select from "react-select";

import NotyfContext from "contexts/NotyfContext";
import Signature from "components/ui/Signature";
import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";
import * as usersApi from "@api/usersApi";
import { LoadingContext } from "App";
import { useParams } from "react-router-dom";
import { RerenderContextUser } from "../form/UsersForm";
import useAuth from "hooks/useAuth";

export const SignatureModal = ({ modalInfo, setModalInfo, view }) => {
    const notyf = useContext(NotyfContext);
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [rerender, setRerender] = useContext(RerenderContextUser);
    const { action } = useParams();
    const { initialize } = useAuth();

    //
    // States
    //

    const schema = yup.object().shape({
        name: yup.string().required("This field is required"),
        signature_details: yup.string().required("This field is required"),
    });
    const [signature, setSignature] = useState();
    const [uploadSignature, setuploadSignature] = useState();

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const signatureOptions = watch("signature_options", {});

    //
    // Functions
    //

    const closeModal = () => {
        setModalInfo({ open: false });
        setIsLoadingActive(false);
        setRerender(!rerender);
        reset({});
    };

    const signatureApiCall = async (data) => {
        if (signatureOptions?.value === "new_signature" && !signature) {
            notyf.open({
                type: "warning",
                message: "Please provide signature to proceed.",
            });
            return;
        }

        const formData = new FormData();

        const uploadedSignature =
            signatureOptions?.value === "upload_signature"
                ? uploadSignature.getFilesData()
                : [];

        uploadedSignature &&
            uploadedSignature.forEach((file) => {
                formData.append("signature[]", file.rawFile);
            });

        signature && formData.append("signature", signature);

        formData.append("name", data.name);
        formData.append("signature_options", data.signature_options.value);
        formData.append("signature_details", data.signature_details);

        try {
            setIsLoadingActive(true);
            const response = await usersApi.createUserSignature(
                action,
                formData
            );

            if (response.status === 200) {
                initialize();
                closeModal();
                notyf.open({
                    type: "success",
                    message: response.data.message,
                });
            }
        } catch (error) {
            closeModal();
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    useEffect(() => {
        reset({});
        setValue("signature_options", {
            value: "new_signature",
            label: "New signature",
        });
    }, [modalInfo.open, reset, setValue]);

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => closeModal()}
            size="md"
            centered
        >
            <Modal.Header closeButton>
                {modalInfo.signatureImage ? "View" : "Create"} signature
            </Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    {modalInfo?.signatureImage ? (
                        <Row>
                            <div>
                                <Image
                                    src={modalInfo.signatureImage}
                                    className="preview-signature signature-container"
                                />
                            </div>
                        </Row>
                    ) : (
                        <Row>
                            <Col md={12}>
                                <Form.Group className={`mb-2`}>
                                    <Form.Label
                                        htmlFor="name"
                                        className="font-weight-bold"
                                    >
                                        Signature name
                                    </Form.Label>

                                    <Controller
                                        control={control}
                                        name={`name`}
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Form.Control
                                                type="text"
                                                value={value || ""}
                                                onChange={onChange}
                                                className={`form-box ${
                                                    errors?.name && "is-invalid"
                                                }`}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="name"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>

                                <Form.Group className={`mb-3`}>
                                    <Form.Label
                                        htmlFor="signature_options"
                                        className="font-weight-bold"
                                    >
                                        Signature options
                                    </Form.Label>

                                    <Controller
                                        control={control}
                                        name={`signature_options`}
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Select
                                                onChange={onChange}
                                                options={[
                                                    {
                                                        value: "new_signature",
                                                        label: "New signature",
                                                    },
                                                    {
                                                        value: "upload_signature",
                                                        label: "Upload signature",
                                                    },
                                                ]}
                                                defaultValue={{
                                                    value: "new_signature",
                                                    label: "New signature",
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>

                                {signatureOptions?.value === "new_signature" ? (
                                    <Signature
                                        col="4"
                                        setSignature={setSignature}
                                        signatureURL=""
                                    />
                                ) : signatureOptions?.value ===
                                  "upload_signature" ? (
                                    <FileUploaderSyncfusion
                                        setUpload={setuploadSignature}
                                        setModalInfo={setModalInfo}
                                        document={[]}
                                        action={"create"}
                                    />
                                ) : (
                                    <div className="mb-4">
                                        <Image className="preview-signature" />
                                    </div>
                                )}

                                <Form.Group>
                                    <Form.Label
                                        htmlFor="name"
                                        className="font-weight-bold"
                                    >
                                        Signature details
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name={`signature_details`}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <>
                                                <AutoGrowTextarea
                                                    onChange={onChange}
                                                    fieldValue={value || ""}
                                                    errors={
                                                        errors?.signature_details
                                                    }
                                                />
                                            </>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="signature_details"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        {!modalInfo.signatureImage && (
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit(signatureApiCall)}
                                className="me-2"
                            >
                                Submit
                            </Button>
                        )}
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

import React from "react";

import Select from "react-select";
import {
    Accordion,
    Alert,
    Badge,
    Button,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import {
    faBell,
    faPlus,
    faRedo,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { toTitleCase } from "utils/utilities";
import { status } from "config";

export const ManagingAgents = ({
    control,
    managingAgents,
    addManagingAgent,
    managingAgentsFields,
    managingAgentRemove,
    categoryOptions,
    school,
    getOutstandingItems,
    outstandingItems,
}) => {
    return (
        <>
            <Row>
                <Form>
                    <Row className="font-weight-bold mb-2">
                        <h5>Managing agents</h5>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name="add_managing_agent"
                                    defaultValue=""
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Select
                                            classNamePrefix="react-select"
                                            options={managingAgents}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={onChange}
                                            placeholder="Select managing agent"
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Button
                                variant="primary"
                                className="mb-2 form-box"
                                onClick={() => {
                                    addManagingAgent();
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </Col>
                        <Col md={12}>
                            <Table
                                className="ma-user-org-table"
                                bordered
                                responsive
                                striped
                            >
                                <thead>
                                    <tr>
                                        <th>Actions</th>
                                        <th>User</th>
                                        <th
                                            style={{
                                                width: "350px",
                                            }}
                                        >
                                            Modules
                                        </th>
                                        <th
                                            style={{
                                                width: "100px",
                                            }}
                                        >
                                            Send email
                                        </th>
                                        <th>Last updated by</th>
                                        <th>Last updated at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {managingAgentsFields.length ? (
                                        managingAgentsFields.map(
                                            (item, index) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip>
                                                                        Delete
                                                                        claim
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                        className="align-middle me-1 text-danger"
                                                                        size="lg"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            managingAgentRemove(
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            {item.full_name}
                                                            <br />
                                                            <Badge
                                                                pill
                                                                bg="secondary"
                                                                className="text-wrap"
                                                            >
                                                                {item.email}
                                                            </Badge>
                                                        </td>

                                                        <td>
                                                            <Form.Group className="mb-3">
                                                                <Controller
                                                                    control={
                                                                        control
                                                                    }
                                                                    name={`managing_agents[${index}].category_options`}
                                                                    defaultValue=""
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <Select
                                                                            classNamePrefix="react-select"
                                                                            options={
                                                                                categoryOptions
                                                                            }
                                                                            value={
                                                                                value
                                                                            }
                                                                            isClearable
                                                                            onChange={
                                                                                onChange
                                                                            }
                                                                            placeholder="Select modules"
                                                                            isMulti
                                                                        />
                                                                    )}
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name={`managing_agents[${index}].is_send_email`}
                                                                defaultValue=""
                                                                render={({
                                                                    field: {
                                                                        value,
                                                                        onChange,
                                                                        onBlur,
                                                                    },
                                                                }) => (
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        value={
                                                                            value ||
                                                                            ""
                                                                        }
                                                                        checked={
                                                                            value ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </td>
                                                        <td>
                                                            {item.updated_by}
                                                        </td>
                                                        <td>
                                                            {item.updated_at}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={6}
                                                className="text-center"
                                            >
                                                No managing agents assigned
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Form>
            </Row>
            <Row>
                <Col md={12}>
                    <Alert variant="warning">
                        <div className="alert-message">
                            <Row>
                                <Col md={1} style={{ width: "30px" }}>
                                    <FontAwesomeIcon icon={faBell} />
                                </Col>
                                <Col md={10}>
                                    <strong>
                                        Pending MA outstanding items
                                    </strong>{" "}
                                    <br />
                                    <p className="mb-2">
                                        Please see and review below the
                                        following pending MA items of{" "}
                                        <strong>{school?.name}</strong> that
                                        will be affected by the change of
                                        configuration.
                                    </p>
                                </Col>
                                <Col className="text-end">
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Refresh pending items
                                            </Tooltip>
                                        }
                                        placement="top"
                                    >
                                        <Button
                                            variant="success"
                                            onClick={() =>
                                                getOutstandingItems()
                                            }
                                        >
                                            <FontAwesomeIcon icon={faRedo} />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row>
                                <div>
                                    {outstandingItems?.managing_agents &&
                                    !Object.values(
                                        outstandingItems?.managing_agents
                                    ).every(
                                        (arr) =>
                                            Array.isArray(arr) &&
                                            arr.length === 0
                                    ) ? (
                                        Object.keys(
                                            outstandingItems.managing_agents
                                        ).map((module) => {
                                            const moduleItems =
                                                outstandingItems
                                                    .managing_agents[module];

                                            return moduleItems.length ? (
                                                <React.Fragment key={module}>
                                                    <Accordion
                                                        defaultActiveKey={0}
                                                        className="mb-3 mt-3"
                                                    >
                                                        <Accordion.Item
                                                            eventKey={module}
                                                            key={module}
                                                        >
                                                            <Accordion.Header>
                                                                {module ===
                                                                "WSO"
                                                                    ? "Work service order"
                                                                    : toTitleCase(
                                                                          module
                                                                      )}
                                                                <Badge
                                                                    pill
                                                                    bg="primary"
                                                                    className="ms-1"
                                                                >
                                                                    {
                                                                        moduleItems.length
                                                                    }
                                                                </Badge>
                                                            </Accordion.Header>
                                                            <Accordion.Body className="bg-white">
                                                                <Table
                                                                    responsive
                                                                    className="table-layout-fixed"
                                                                    striped
                                                                    bordered
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="50">
                                                                                S/N
                                                                            </th>
                                                                            <th>
                                                                                Reference
                                                                                number
                                                                            </th>
                                                                            <th>
                                                                                Status
                                                                            </th>
                                                                            <th>
                                                                                Current
                                                                                pending
                                                                                managing
                                                                                agent(s)
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {moduleItems.map(
                                                                            (
                                                                                data,
                                                                                index
                                                                            ) => {
                                                                                let referenceLink =
                                                                                    "";

                                                                                switch (
                                                                                    module
                                                                                ) {
                                                                                    case "QUOTATIONS":
                                                                                        referenceLink = `/works-order/quotation-list/${data.id}`;
                                                                                        break;
                                                                                    case "CLAIMS":
                                                                                        referenceLink = `/works-order/claims/${data.id}`;
                                                                                        break;
                                                                                    case "WSO":
                                                                                        referenceLink = `/works-order/wso/${data.id}`;
                                                                                        break;
                                                                                    default:
                                                                                        break;
                                                                                }
                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        <td>
                                                                                            {index +
                                                                                                1}
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                href={
                                                                                                    referenceLink
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noreferrer"
                                                                                                className="btn-link pe-1"
                                                                                            >
                                                                                                {
                                                                                                    data.reference_number
                                                                                                }
                                                                                            </a>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Badge
                                                                                                pill
                                                                                                bg={status(
                                                                                                    data.status
                                                                                                )}
                                                                                            >
                                                                                                {
                                                                                                    data.status
                                                                                                }
                                                                                            </Badge>
                                                                                        </td>
                                                                                        <td>
                                                                                            {data
                                                                                                ?.pending_approvals
                                                                                                ?.length
                                                                                                ? data.pending_approvals.map(
                                                                                                      (
                                                                                                          item,
                                                                                                          index
                                                                                                      ) => {
                                                                                                          return (
                                                                                                              <React.Fragment
                                                                                                                  key={
                                                                                                                      index
                                                                                                                  }
                                                                                                              >
                                                                                                                  <Badge
                                                                                                                      pill
                                                                                                                      bg="primary"
                                                                                                                      className="text-center m-1"
                                                                                                                  >
                                                                                                                      {
                                                                                                                          item
                                                                                                                              ?.user
                                                                                                                              ?.full_name
                                                                                                                      }
                                                                                                                  </Badge>
                                                                                                              </React.Fragment>
                                                                                                          );
                                                                                                      }
                                                                                                  )
                                                                                                : ""}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tbody>
                                                                </Table>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </React.Fragment>
                                            ) : (
                                                ""
                                            );
                                        })
                                    ) : (
                                        <strong
                                            style={{
                                                marginLeft: "30px",
                                            }}
                                        >
                                            There are no pending items
                                        </strong>
                                    )}
                                </div>
                            </Row>
                        </div>
                    </Alert>
                </Col>
            </Row>
        </>
    );
};

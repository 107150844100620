import React, { useEffect } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ErrorMessage } from "@hookform/error-message";
import { errorMessage } from "utils/utilities";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";

export const DiscountModal = ({ modalInfo, setModalInfo, rates }) => {
    //
    // States
    //

    const schema = yup.object().shape({
        custodial_discount_quantity: yup
            .string()
            .required("This field is required"),
        custodial_discount_description: yup
            .string()
            .required("This field is required"),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const closeModal = () => {
        setModalInfo({ open: false });
    };

    const saveModal = (data) => {
        setModalInfo({
            open: false,
            discount_data: data,
            id: modalInfo.id,
            parent_id: modalInfo?.parent_id || false,
        });
    };

    useEffect(() => {
        reset({
            custodial_discount_quantity: modalInfo.custodial_discount_quantity,
            custodial_discount_description:
                modalInfo.custodial_discount_description,
        });
    }, [reset, modalInfo]);

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "add" })}
            size="lg"
            centered
        >
            <Modal.Header closeButton>Add discount</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="custodial_discount_quantity">
                                    Custodial discount quantity ($250)
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="custodial_discount_quantity"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            type="number"
                                            className={`form-box ${
                                                errors.custodial_discount_quantity &&
                                                "is-invalid"
                                            }`}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="custodial_discount_quantity"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="custodial_discount_description">
                                    Description
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="custodial_discount_description"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <AutoGrowTextarea
                                            onChange={onChange}
                                            fieldValue={value || ""}
                                            errors={
                                                errors.custodial_discount_description
                                            }
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="custodial_discount_description"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="description">
                                    Fixed Discount at {rates?.less_percent}%
                                </Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    defaultValue="selected_item"
                                    name="discount_application"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            <Form.Check
                                                name="discount_radio"
                                                label="Apply Fixed Discount to Selected Item"
                                                type="radio"
                                                onChange={onChange}
                                                value="selected_item"
                                                checked={
                                                    value === "selected_item"
                                                }
                                            />
                                            <Form.Check
                                                name="discount_radio"
                                                label="Apply Fixed Discount to all Quotation Items"
                                                type="radio"
                                                onChange={onChange}
                                                value="all"
                                                checked={value === "all"}
                                            />
                                        </>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={handleSubmit(saveModal)}
                        >
                            Submit
                        </Button>
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

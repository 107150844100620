import React from "react";

import { Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export const ApvTabs = ({ module, start_date, end_date, school }) => {
    const { tab } = useParams();

    return (
        <Row className="mb-3">
            <Col md={3}>
                <a
                    href={`/dashboard/${module}/school?start_date=${start_date}&end_date=${end_date}&school=${school}`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={
                            tab === "school" ? "primary" : "outline-primary"
                        }
                        className="mb-2 w-100 text-center"
                    >
                        School
                    </Button>
                </a>
            </Col>
            <Col md={3}>
                <a
                    href={`/dashboard/${module}/moe?start_date=${start_date}&end_date=${end_date}&school=${school}`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-link pe-1"
                >
                    <Button
                        variant={tab === "moe" ? "primary" : "outline-primary"}
                        className="mb-2 w-100 text-center"
                    >
                        MOE
                    </Button>
                </a>
            </Col>
        </Row>
    );
};

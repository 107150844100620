import React, {
    useState,
    useCallback,
    useContext,
    useEffect,
    createContext,
} from "react";

import Select from "react-select";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet-async";
import {
    Badge,
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignaturesForm } from "components/ui/SignatureForm";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { constructReport, errorMessage } from "utils/utilities";
import { StatusHistory } from "components/common/works-order/StatusHistory";
import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";
import { LoadingContext } from "App";
import { status } from "config";
import * as quotationsApi from "@api/quotationsApi";
import * as attachmentApi from "@api/attachmentApi";
import { DeleteModal } from "components/DeleteModal";

export const RerenderContextWorkService = createContext();

export const QuotationWorkServiceOrder = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const { user, hasRole } = useAuth();
    const { id, action } = useParams();

    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    const hasRoleCollection = {
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isSCD: hasRole("SCD"),
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isTechnician: hasRole("TECHNICIAN"),
        isAdmin: hasRole("ADMIN"),
    };

    let schema = yup.object().shape({
        date: yup.string().required("This field is required"),
        time_received: yup.string().required("This field is required"),
        fault_reference_number: yup.string().required("This field is required"),
        fault_call_location: yup.string().required("This field is required"),
        time_arrived: yup.string().required("This field is required"),
        completed_date: yup.string().required("This field is required"),
        completion_time: yup.string().required("This field is required"),
        work_description: yup.string().required("This field is required"),
        details: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .nullable()
            .required("This field is required."),
    });

    const signatureDetailsSchema = {
        status_remarks: yup.string().when("status", {
            is: "REJECTED",
            then: yup.string().required("This field is required"),
        }),
    };

    const maSchema = yup.object().shape({
        ma_details: yup.object().shape(signatureDetailsSchema),
    });

    const omSchema = yup.object().shape({
        om_details: yup.object().shape(signatureDetailsSchema),
    });

    switch (true) {
        case hasRoleCollection.isTermContractor ||
            hasRoleCollection.isTechnician:
            // eslint-disable-next-line
            schema = schema;
            break;
        case hasRoleCollection.isManagingAgent:
            schema = maSchema;
            break;
        case hasRoleCollection.isOM:
            schema = omSchema;
            break;
        default:
            break;
    }

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    const details = [
        {
            value: "work_completed",
            label: "Work completed satisfactorily",
        },
        {
            value: "not_complete",
            label: "Not complete, require quotation",
        },
    ];

    //
    // States
    //

    const [workSerivceOrderData, setWorkSerivceOrderData] = useState({});
    const [rerender, setRerender] = useState([]);
    const [schoolId, setSchoolId] = useState([]);
    // eslint-disable-next-line
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [beforePhotos, setBeforePhotos] = useState([]);
    const [afterPhotos, setAfterPhotos] = useState([]);

    //
    // Functions
    //

    const maWorkServiceApiCall = async ({ ma_details }) => {
        if (!ma_details.signature && ma_details.status !== "REJECTED") {
            notyf.open({
                type: "warning",
                message: "Please provide signature to proceed.",
            });
            return;
        }

        setIsLoadingActive(true);

        const formData = new FormData();

        formData.append("signature_details", ma_details.signature_details);
        formData.append("signature", ma_details.signature);
        formData.append("signature_options", "my_signature");

        formData.append("status", ma_details.status);
        formData.append("remarks", ma_details.status_remarks);

        try {
            const response = await quotationsApi.updateWorkServiceOrderMA(
                workSerivceOrderData?.id,
                formData
            );

            if (response.status === 201 || response.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully updated work service order`,
                });
                setIsLoadingActive(false);
                navigate("/works-order/quotation-list");
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setIsLoadingActive(false);
        }
    };

    const omWorkServiceApiCall = async ({ om_details }) => {
        if (!om_details.signature && om_details.status !== "REJECTED") {
            notyf.open({
                type: "warning",
                message: "Please provide signature to proceed.",
            });
            return;
        }

        setIsLoadingActive(true);

        const formData = new FormData();

        formData.append("signature_details", om_details.signature_details);
        formData.append("signature", om_details.signature);
        formData.append("signature_options", "my_signature");
        formData.append("user_type", "OM");

        formData.append("status", om_details.status);
        formData.append("remarks", om_details.status_remarks);

        try {
            const response = await quotationsApi.updateWorkServiceOrderOM(
                workSerivceOrderData?.id,
                formData
            );

            if (response.status === 201 || response.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully updated work service order`,
                });
                setIsLoadingActive(false);
                navigate("/works-order/quotation-list");
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setIsLoadingActive(false);
        }
    };

    const workServiceApiCall = async (data, status) => {
        if (!data.prepare_details.signature && status !== "DRAFT") {
            notyf.open({
                type: "warning",
                message: "Please provide signature to proceed.",
            });
            return;
        }

        const beforePhotosData = beforePhotos?.getFilesData() || [];
        const afterPhotosData = afterPhotos.getFilesData() || [];

        setIsLoadingActive(true);

        const formData = new FormData();

        formData.append(
            "date",
            new Date(data.date).toLocaleDateString("en-CA")
        );
        formData.append("contact_person", data.contact_person);
        formData.append("job_location", data.location || "");
        formData.append("category", "quotation");
        formData.append("time_received", data.time_received);
        formData.append("fault_call_ref_no", data.fault_reference_number);
        formData.append("fault_call_location", data.fault_call_location);
        formData.append("time_arrived_onsite", data.time_arrived);
        formData.append(
            "completed_on",
            new Date(data.completed_date).toLocaleDateString("en-CA")
        );
        formData.append("completion_time", data.completion_time);
        formData.append("work_description", data.work_description);
        formData.append("details", data.details.value);
        formData.append("remark", data.remarks);
        formData.append("status", status);
        formData.append("school_id", schoolId);

        beforePhotosData.forEach((file) => {
            formData.append("before_photos[]", file.rawFile);
        });

        afterPhotosData.forEach((file) => {
            formData.append("after_photos[]", file.rawFile);
        });

        if (status !== "DRAFT") {
            data.prepare_details.signature_details &&
                formData.append(
                    "signature_details",
                    data.prepare_details.signature_details || false
                );

            formData.append("signature_options", "my_signature");
            formData.append("signature", data.prepare_details.signature);
        }

        try {
            const response = await quotationsApi.updateWorkServiceOrder(
                id,
                formData
            );
            if (response.status === 201 || response.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully updated work service`,
                });
                setIsLoadingActive(false);
                navigate("/works-order/quotation-list");
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getWorkService = useCallback(async () => {
        try {
            setIsLoadingActive(true);
            const response = await quotationsApi.getWorkServiceOrder(id);
            const data = response.data.data;
            setWorkSerivceOrderData(data);
            setSchoolId(data?.quotation?.school_id);

            const canView =
                (!hasRoleCollection.isTermContractor ||
                    !hasRoleCollection.isTechnician) &&
                (data?.status === "PENDING" || data?.status === "APPROVED");

            if (response.status === 201 || response.status === 200) {
                reset({
                    date:
                        data?.date && !canView
                            ? new Date(data.date)
                            : data?.date && canView
                            ? data.date
                            : "",
                    time_received: data?.time_received || "",
                    fault_reference_number: data?.fault_call_ref_no || "",
                    fault_call_location: data?.fault_call_location || "",
                    time_arrived: data?.time_arrived_onsite || "",
                    completed_date:
                        data?.completed_on && !canView
                            ? new Date(data.completed_on)
                            : data?.completed_on && canView
                            ? data.completed_on
                            : "",
                    completion_time: data?.completion_time || "",
                    work_description:
                        data?.work_description || data?.quotation?.job,
                    details:
                        data?.status &&
                        (data?.status === "DRAFT" ||
                            data?.status === "REJECTED")
                            ? details.find((o) => o.value === data?.details)
                            : data?.status && data?.status !== "DRAFT"
                            ? details.find((o) => o.value === data?.details)
                                  .value
                            : "",
                    remarks: data?.remark || "",
                    school: data?.quotation?.school?.name,
                    address: data?.quotation?.school?.address,
                    quotation_reference_number:
                        data?.quotation?.reference_number,
                    status: data?.status || "-",
                    contact_person: data?.quotation?.contacts?.attention,
                    location: data?.quotation?.contacts?.location,
                    prepare_details: {
                        name: data?.prepare_details?.name,
                        signature: data?.prepare_signature,
                        signature_details:
                            data?.prepare_details?.signature_details,
                        signature_options:
                            data?.prepare_details?.signature_options,
                    },
                    ma_details: data?.approvals?.MA?.details
                        ? {
                              name: data?.approvals?.MA?.details.name,
                              signature: data?.approvals?.MA?.signature,
                              signature_details:
                                  data?.approvals?.MA?.details
                                      .signature_details,
                              signature_options:
                                  data?.approvals?.MA?.details
                                      .signature_options,
                              status: data?.approvals?.MA?.status,
                              status_remarks:
                                  data?.approvals?.MA?.remarks || "",
                          }
                        : {
                              status: "VERIFIED",
                          },
                    om_details: data?.approvals?.OM?.details
                        ? {
                              name: data?.approvals?.OM?.details.name,
                              signature: data?.approvals?.OM?.signature,
                              signature_details:
                                  data?.approvals?.OM?.details
                                      .signature_details,
                              signature_options:
                                  data?.approvals?.OM?.details
                                      .signature_options,
                              status: data?.approvals?.OM?.status || "",
                              status_remarks:
                                  data?.approvals?.OM?.remarks || "",
                          }
                        : {
                              status: "APPROVED",
                          },
                });
                setIsLoadingActive(false);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
        // eslint-disable-next-line
    }, [notyf]);

    const deleteApi = async (id) => {
        try {
            const response = await attachmentApi.deleteAttachment(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "Successfully deleted file",
                });
                setRerender(!rerender);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (action !== "create") {
            getWorkService();
        }
    }, [getWorkService, rerender]);

    //
    // Conditions
    //

    const canViewOnly =
        workSerivceOrderData &&
        (!hasRoleCollection.isTermContractor ||
            !hasRoleCollection.isTechnician) &&
        (workSerivceOrderData?.status === "PENDING" ||
            workSerivceOrderData?.status === "APPROVED");

    const canUpload =
        workSerivceOrderData &&
        (hasRoleCollection.isTermContractor ||
            hasRoleCollection.isTechnician) &&
        (!workSerivceOrderData?.status ||
            workSerivceOrderData?.status === "REJECTED" ||
            workSerivceOrderData?.status === "DRAFT");

    const canSign =
        workSerivceOrderData &&
        workSerivceOrderData?.status &&
        workSerivceOrderData?.status === "PENDING" &&
        workSerivceOrderData?.status !== "APPROVED";

    return (
        user &&
        workSerivceOrderData && (
            <RerenderContextWorkService.Provider
                value={[setRerender, rerender]}
            >
                <>
                    <Helmet
                        title={`${
                            action === "create"
                                ? "Add"
                                : canViewOnly
                                ? "View"
                                : canSign
                                ? "Sign"
                                : "Edit"
                        } work service order`}
                    />
                    <Container fluid className="p-0">
                        <Row>
                            <Col md={6}>
                                <h4 className="h4 mb-3">
                                    {action === "create"
                                        ? "Add"
                                        : canViewOnly
                                        ? "View"
                                        : canSign
                                        ? "Sign"
                                        : "Edit"}{" "}
                                    work service order
                                </h4>
                            </Col>
                            <Col md={6}>
                                <Breadcrumb>
                                    <Breadcrumb.Item
                                        onClick={() =>
                                            navigate(
                                                "/works-order/quotation-list"
                                            )
                                        }
                                    >
                                        Quotation list
                                    </Breadcrumb.Item>

                                    <Breadcrumb.Item active>
                                        Work service order
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Header className="pb-0 mt-2"></Card.Header>
                            <Card.Body>
                                <Col md={9}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="font-weight-bold">
                                                    School
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="school"
                                                    render={({
                                                        field: { value },
                                                    }) => (
                                                        <>
                                                            <p>
                                                                {value || "-"}
                                                            </p>
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="font-weight-bold">
                                                    WSO reference number
                                                </Form.Label>
                                                <p>
                                                    {workSerivceOrderData?.reference_number ||
                                                        "-"}
                                                </p>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="font-weight-bold">
                                                    Status
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="status"
                                                    render={({
                                                        field: { value },
                                                    }) => (
                                                        <>
                                                            <br />
                                                            {value === "-" ? (
                                                                value
                                                            ) : (
                                                                <Badge
                                                                    pill
                                                                    bg={status(
                                                                        value
                                                                    )}
                                                                >
                                                                    {value}
                                                                </Badge>
                                                            )}
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="font-weight-bold">
                                                    Contact person
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="contact_person"
                                                    render={({
                                                        field: { value },
                                                    }) => (
                                                        <>
                                                            <p>
                                                                {value || "-"}
                                                            </p>
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="font-weight-bold">
                                                    Address
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="address"
                                                    render={({
                                                        field: { value },
                                                    }) => (
                                                        <>
                                                            <p>
                                                                {value || "-"}
                                                            </p>
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="font-weight-bold">
                                                    Quotation reference number
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="quotation_reference_number"
                                                    render={({
                                                        field: { value },
                                                    }) => (
                                                        <>
                                                            <p>
                                                                {value || "-"}
                                                            </p>
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="font-weight-bold">
                                                    Job location
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="location"
                                                    render={({
                                                        field: { value },
                                                    }) => (
                                                        <>
                                                            <p>
                                                                {value || "-"}
                                                            </p>
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>

                                <Row>
                                    <Col md={9}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="font-weight-bold">
                                                        Date
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="date"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {value ||
                                                                            "-"}
                                                                    </p>
                                                                ) : (
                                                                    <DatePicker
                                                                        dateFormat="yyyy-MM-dd"
                                                                        selected={
                                                                            value
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        className={`form-box form-control ${
                                                                            errors?.date &&
                                                                            "is-invalid"
                                                                        }`}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="date"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="font-weight-bold">
                                                        Time received
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="time_received"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {value ||
                                                                            "-"}
                                                                    </p>
                                                                ) : (
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={
                                                                            value ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        className={`form-box ${
                                                                            errors.time_received &&
                                                                            "is-invalid"
                                                                        }`}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="time_received"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="font-weight-bold">
                                                        Fault call ref no
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="fault_reference_number"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {value}{" "}
                                                                    </p>
                                                                ) : (
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={
                                                                            value ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        className={`form-box ${
                                                                            errors.fault_reference_number &&
                                                                            "is-invalid"
                                                                        }`}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="fault_reference_number"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="font-weight-bold">
                                                        Fault call location
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="fault_call_location"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {value}{" "}
                                                                    </p>
                                                                ) : (
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={
                                                                            value ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        className={`form-box ${
                                                                            errors.fault_call_location &&
                                                                            "is-invalid"
                                                                        }`}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="fault_call_location"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="font-weight-bold">
                                                        Time arrived on site
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="time_arrived"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {value ||
                                                                            "-"}
                                                                    </p>
                                                                ) : (
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={
                                                                            value ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        className={`form-box ${
                                                                            errors.time_arrived &&
                                                                            "is-invalid"
                                                                        }`}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="time_arrived"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="font-weight-bold">
                                                        Completed date
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="completed_date"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {value ||
                                                                            "-"}
                                                                    </p>
                                                                ) : (
                                                                    <DatePicker
                                                                        dateFormat="yyyy-MM-dd"
                                                                        selected={
                                                                            value
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        className={`form-box form-control ${
                                                                            errors?.completed_date &&
                                                                            "is-invalid"
                                                                        }`}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="completed_date"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="font-weight-bold">
                                                        Completion time
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="completion_time"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {value ||
                                                                            "-"}
                                                                    </p>
                                                                ) : (
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={
                                                                            value ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        className={`form-box ${
                                                                            errors.completion_time &&
                                                                            "is-invalid"
                                                                        }`}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="completion_time"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label
                                                        htmlFor="job"
                                                        className="font-weight-bold"
                                                    >
                                                        Work description
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="work_description"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                                onBlur,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {value}{" "}
                                                                    </p>
                                                                ) : (
                                                                    <AutoGrowTextarea
                                                                        onBlur={
                                                                            onBlur
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        fieldValue={
                                                                            value
                                                                        }
                                                                        errors={
                                                                            errors.work_description
                                                                        }
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="work_description"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="font-weight-bold">
                                                        Details
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="details"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                                ref,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {
                                                                            details.find(
                                                                                (
                                                                                    o
                                                                                ) =>
                                                                                    o.value ===
                                                                                    value
                                                                            )
                                                                                ?.label
                                                                        }
                                                                    </p>
                                                                ) : (
                                                                    <Select
                                                                        isClearable={
                                                                            true
                                                                        }
                                                                        inputRef={
                                                                            ref
                                                                        }
                                                                        options={
                                                                            details
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="details"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className="my-3">
                                                    <Form.Label
                                                        htmlFor="remarks"
                                                        className="font-weight-bold"
                                                    >
                                                        Remarks
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="remarks"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                                onBlur,
                                                            },
                                                        }) => (
                                                            <>
                                                                {canViewOnly ? (
                                                                    <p>
                                                                        {value}{" "}
                                                                    </p>
                                                                ) : (
                                                                    <AutoGrowTextarea
                                                                        onBlur={
                                                                            onBlur
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        fieldValue={
                                                                            value
                                                                        }
                                                                        errors={
                                                                            errors.job
                                                                        }
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="remarks"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-4">
                                            <Form.Label className="font-weight-bold">
                                                Before photos
                                            </Form.Label>
                                            <FileUploaderSyncfusion
                                                action={id}
                                                document={
                                                    workSerivceOrderData
                                                        ?.attachements
                                                        ?.before_photos
                                                }
                                                setUpload={setBeforePhotos}
                                                setModalInfo={setModalInfo}
                                                canUpload={canUpload}
                                                canDelete={
                                                    hasRoleCollection.isTermContractor &&
                                                    (action === "create" ||
                                                        workSerivceOrderData?.status ===
                                                            "REJECTED" ||
                                                        workSerivceOrderData?.status ===
                                                            "DRAFT")
                                                }
                                                // setCameraUpload={setCameraBeforePhotos}
                                                // cameraPhotos={cameraBeforePhotos}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-4">
                                            <Form.Label className="font-weight-bold">
                                                After photos
                                            </Form.Label>
                                            <FileUploaderSyncfusion
                                                action={id}
                                                document={
                                                    workSerivceOrderData
                                                        ?.attachements
                                                        ?.after_photos
                                                }
                                                setUpload={setAfterPhotos}
                                                setModalInfo={setModalInfo}
                                                canUpload={canUpload}
                                                canDelete={
                                                    hasRoleCollection.isTermContractor &&
                                                    (action === "create" ||
                                                        workSerivceOrderData?.status ===
                                                            "REJECTED" ||
                                                        workSerivceOrderData?.status ===
                                                            "DRAFT")
                                                }
                                                // setCameraUpload={setCameraAfterPhotos}
                                                // cameraPhotos={cameraAfterPhotos}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Quotation history */}

                                <StatusHistory
                                    histories={
                                        workSerivceOrderData?.wso_histories
                                    }
                                    tcRemarks={workSerivceOrderData?.remark}
                                />

                                {Boolean(
                                    Object.keys(workSerivceOrderData).length &&
                                        hasRoleCollection.isManagingAgent &&
                                        workSerivceOrderData?.status ===
                                            "PENDING"
                                ) && (
                                    <>
                                        <Row className="mt-4">
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label
                                                        className="d-block font-weight-bold"
                                                        htmlFor="ma_details.status"
                                                    >
                                                        MA Status
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="ma_details.status"
                                                        render={({
                                                            field: { value },
                                                        }) => (
                                                            <>
                                                                {workSerivceOrderData
                                                                    ?.approvals
                                                                    ?.MA ? (
                                                                    <p>
                                                                        {value ||
                                                                            "-"}
                                                                    </p>
                                                                ) : (
                                                                    <>
                                                                        <Form.Check
                                                                            inline
                                                                            checked={
                                                                                value ===
                                                                                "VERIFIED"
                                                                            }
                                                                            label="Verified"
                                                                            name="status_check"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setValue(
                                                                                    "ma_details.status",
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            type="radio"
                                                                            value="VERIFIED"
                                                                        />
                                                                        <Form.Check
                                                                            checked={
                                                                                value ===
                                                                                "REJECTED"
                                                                            }
                                                                            inline
                                                                            label="Rejected"
                                                                            name="status_check"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setValue(
                                                                                    "ma_details.status",
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            type="radio"
                                                                            value="REJECTED"
                                                                        />
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="ma_details.status"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className="font-weight-bold"
                                                        htmlFor="remarks"
                                                    >
                                                        MA status remarks
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="ma_details.status_remarks"
                                                        render={({
                                                            field: {
                                                                onBlur,
                                                                onChange,
                                                                value,
                                                            },
                                                        }) => (
                                                            <>
                                                                {workSerivceOrderData
                                                                    ?.approvals
                                                                    ?.MA ? (
                                                                    <p>
                                                                        {value ||
                                                                            "-"}
                                                                    </p>
                                                                ) : (
                                                                    <AutoGrowTextarea
                                                                        errors={
                                                                            errors
                                                                                ?.ma_details
                                                                                ?.status_remarks
                                                                        }
                                                                        fieldValue={
                                                                            value
                                                                        }
                                                                        onBlur={
                                                                            onBlur
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Form.Group>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="ma_details.status_remarks"
                                                    render={({ message }) =>
                                                        errorMessage(message)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {Boolean(
                                    Object.keys(workSerivceOrderData)?.length &&
                                        hasRoleCollection?.isOM &&
                                        workSerivceOrderData?.status ===
                                            "PENDING" &&
                                        workSerivceOrderData?.approvals?.MA
                                ) && (
                                    <>
                                        <Row className="mt-4">
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label
                                                        className="d-block font-weight-bold"
                                                        htmlFor="status_check"
                                                    >
                                                        OM Status
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="om_details.status"
                                                        render={({
                                                            field: { value },
                                                        }) => (
                                                            <>
                                                                {workSerivceOrderData
                                                                    ?.approvals
                                                                    .OM ? (
                                                                    <p>
                                                                        {value ||
                                                                            "-"}
                                                                    </p>
                                                                ) : (
                                                                    <>
                                                                        <Form.Check
                                                                            inline
                                                                            checked={
                                                                                value ===
                                                                                "APPROVED"
                                                                            }
                                                                            label="Approved"
                                                                            name="status_check"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setValue(
                                                                                    "om_details.status",
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            type="radio"
                                                                            value="APPROVED"
                                                                        />
                                                                        <Form.Check
                                                                            checked={
                                                                                value ===
                                                                                "REJECTED"
                                                                            }
                                                                            inline
                                                                            label="Rejected"
                                                                            name="status_check"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setValue(
                                                                                    "om_details.status",
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            type="radio"
                                                                            value="REJECTED"
                                                                        />
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="om_details.status"
                                                        render={({ message }) =>
                                                            errorMessage(
                                                                message
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label
                                                        className="font-weight-bold"
                                                        htmlFor="remarks"
                                                    >
                                                        OM status remarks
                                                    </Form.Label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="om_details.status_remarks"
                                                        render={({
                                                            field: {
                                                                onBlur,
                                                                onChange,
                                                                value,
                                                            },
                                                        }) => (
                                                            <>
                                                                {workSerivceOrderData
                                                                    ?.approvals
                                                                    .OM ? (
                                                                    <p>
                                                                        {value ||
                                                                            "-"}
                                                                    </p>
                                                                ) : (
                                                                    <AutoGrowTextarea
                                                                        errors={
                                                                            errors
                                                                                ?.om_details
                                                                                ?.status_remarks
                                                                        }
                                                                        fieldValue={
                                                                            value
                                                                        }
                                                                        onBlur={
                                                                            onBlur
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Form.Group>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="om_details.status_remarks"
                                                    render={({ message }) =>
                                                        errorMessage(message)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                <Row className="mt-5">
                                    <SignaturesForm
                                        control={control}
                                        canSign={
                                            (!workSerivceOrderData?.status ||
                                                !workSerivceOrderData?.status ===
                                                    "PENDING" ||
                                                workSerivceOrderData?.status ===
                                                    "REJECTED" ||
                                                workSerivceOrderData?.status ===
                                                    "DRAFT") &&
                                            (hasRoleCollection.isTermContractor ||
                                                hasRoleCollection.isTechnician)
                                        }
                                        label="Prepared by"
                                        name="prepare_details"
                                        setValue={setValue}
                                    />

                                    <SignaturesForm
                                        control={control}
                                        canSign={Boolean(
                                            canSign &&
                                                hasRoleCollection.isManagingAgent &&
                                                !workSerivceOrderData?.approvals
                                                    ?.MA
                                        )}
                                        label="Checked & certified correct by"
                                        name="ma_details"
                                        setValue={setValue}
                                    />

                                    <SignaturesForm
                                        control={control}
                                        canSign={Boolean(
                                            canSign &&
                                                hasRoleCollection.isOM &&
                                                workSerivceOrderData?.approvals
                                                    ?.MA
                                        )}
                                        label="Acknowledged or accepted by"
                                        name="om_details"
                                        setValue={setValue}
                                    />
                                </Row>

                                <Row className="pt-4">
                                    <Col md={12} className="text-center">
                                        {hasRoleCollection.isManagingAgent &&
                                            workSerivceOrderData?.status ===
                                                "PENDING" &&
                                            !workSerivceOrderData?.approvals
                                                ?.MA && (
                                                <Button
                                                    className="me-2"
                                                    variant="primary"
                                                    type="submit"
                                                    onClick={handleSubmit(
                                                        (data) =>
                                                            maWorkServiceApiCall(
                                                                data
                                                            )
                                                    )}
                                                >
                                                    Submit
                                                </Button>
                                            )}
                                        {((hasRoleCollection.isOM &&
                                            workSerivceOrderData?.status ===
                                                "PENDING") ||
                                            !workSerivceOrderData?.status ===
                                                "APPROVED") &&
                                            workSerivceOrderData?.approvals
                                                ?.MA && (
                                                <Button
                                                    className="me-2"
                                                    variant="primary"
                                                    type="submit"
                                                    onClick={handleSubmit(
                                                        (data) =>
                                                            omWorkServiceApiCall(
                                                                data
                                                            )
                                                    )}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                        {(!workSerivceOrderData?.status ||
                                            workSerivceOrderData?.status ===
                                                "REJECTED" ||
                                            workSerivceOrderData?.status ===
                                                "DRAFT") &&
                                            (hasRoleCollection.isTermContractor ||
                                                hasRoleCollection.isTechnician) && (
                                                <Button
                                                    className="me-2"
                                                    variant="primary"
                                                    type="submit"
                                                    onClick={handleSubmit(
                                                        (data) =>
                                                            workServiceApiCall(
                                                                data,
                                                                "PENDING"
                                                            )
                                                    )}
                                                >
                                                    Submit
                                                </Button>
                                            )}

                                        {(hasRoleCollection.isTermContractor ||
                                            hasRoleCollection.isTechnician) &&
                                            (!workSerivceOrderData?.wso_histories ||
                                                workSerivceOrderData?.status ===
                                                    "DRAFT") && (
                                                <Button
                                                    className="me-2"
                                                    variant="secondary"
                                                    type="submit"
                                                    onClick={handleSubmit(
                                                        (data) =>
                                                            workServiceApiCall(
                                                                data,
                                                                "DRAFT"
                                                            )
                                                    )}
                                                >
                                                    Draft
                                                </Button>
                                            )}
                                        {workSerivceOrderData?.id !== "" &&
                                            workSerivceOrderData?.id !== null &&
                                            workSerivceOrderData?.id !==
                                                undefined &&
                                            workSerivceOrderData?.file_info
                                                ?.bucket &&
                                            workSerivceOrderData?.file_info
                                                ?.file_path && (
                                                <Button
                                                    variant="link"
                                                    // onClick={() => {
                                                    //     navigate(
                                                    //         `/works-order/wso/${workSerivceOrderData?.id}/preview`
                                                    //     );
                                                    // }}
                                                    onClick={() =>
                                                        window.open(
                                                            constructReport(
                                                                workSerivceOrderData
                                                                    ?.file_info
                                                                    ?.bucket,
                                                                workSerivceOrderData
                                                                    ?.file_info
                                                                    ?.file_path,
                                                                `${workSerivceOrderData?.reference_number}-${workSerivceOrderData?.date}`,
                                                                true
                                                            )
                                                        )
                                                    }
                                                >
                                                    Preview
                                                </Button>
                                            )}
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                navigate(
                                                    "/works-order/quotation-list"
                                                )
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>
                </>
                <DeleteModal
                    api={deleteApi}
                    modalInfo={modalInfo}
                    setModalInfo={setModalInfo}
                />
            </RerenderContextWorkService.Provider>
        )
    );
};

import React, { useState } from "react";

import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";

export const UploadDocumentModal = ({
    modalInfo,
    quotationItemsStorage,
    setMainStates,
    setModalInfo,
    view,
}) => {
    let { action } = useParams();

    //
    // States
    //

    const schema = yup.object().shape({});
    const [document] = useState([]);
    const [upload, setUpload] = useState([]);
    // eslint-disable-next-line
    const [showDeleteModal, setShowDeleteModal] = useState({
        id: null,
        open: false,
        severity: "danger",
    });

    const { handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const saveModal = () => {
        let attachments = upload.getFilesData();

        attachments = attachments.filter(({ rawFile }) => rawFile !== "");

        const parsedAttachments = parseAttachments(attachments);

        setModalInfo({
            open: false,
            documents: parsedAttachments,
            id: modalInfo.id,
        });
    };

    const parseAttachments = (attachments) => {
        attachments.forEach(async (file, index) => {
            if (file.rawFile) {
                const base64File = await toBase64(file.rawFile);
                attachments[index]["base64"] = base64File;
            }
        });

        return attachments;
    };

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const removeDocument = (id) => {
        let quotationItemsStorageClone = [...quotationItemsStorage];

        quotationItemsStorageClone[modalInfo.id].documents.splice(id, 1);

        setMainStates(quotationItemsStorageClone);
    };

    const closeModal = () => {
        setModalInfo({ open: false });
    };

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "add" })}
            size="lg"
            centered
        >
            <Modal.Header closeButton>Document upload</Modal.Header>
            <Modal.Body className="py-2">
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <FileUploaderSyncfusion
                                setUpload={setUpload}
                                setModalInfo={setShowDeleteModal}
                                document={document}
                                action={action}
                                preloadFiles={modalInfo.documents}
                                removeDocument={removeDocument}
                                canDelete={!view}
                                canUpload={!view}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        {!view && (
                            <Button
                                variant="primary"
                                onClick={handleSubmit(saveModal)}
                            >
                                Submit
                            </Button>
                        )}
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import {
    Col,
    Form,
    Image,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import Select from "react-select";

import { AutoGrowTextarea } from "../../../components/AutoGrowTextarea";
import { ViewImageModal } from "components/modal/ViewImageModal";
import * as inspectionTreeChecklistApi from "@api/inspectionTreeChecklistApi";

const TextAreaComponent = (props) => {
    const { fieldValue, parentsProps, itemIndex, isReadOnly } = props;
    const { updateState, parentIndex } = parentsProps;
    return (
        <AutoGrowTextarea
            fieldValue={fieldValue?.value || ""}
            placeholder={fieldValue?.label}
            onChange={(e) =>
                updateState(parentIndex, itemIndex, e.target.value)
            }
            disable={isReadOnly}
        />
    );
};

const TextComponent = (props) => {
    const {
        fieldValue,
        parentsProps,
        itemIndex,
        customInputIndex,
        isReadOnly,
    } = props;
    const { updateState, parentIndex } = parentsProps;
    return (
        <Form.Control
            type="text"
            value={fieldValue.value}
            className="form-control"
            placeholder={fieldValue?.label}
            onChange={(e) => {
                updateState(
                    parentIndex,
                    itemIndex,
                    e.target.value,
                    customInputIndex
                );
            }}
            disabled={isReadOnly}
        />
    );
};

const RadioComponent = (props) => {
    const {
        fieldValue,
        parentsProps,
        itemIndex,
        radioIndex,
        radioItemValue,
        isReadOnly,
    } = props;
    const { updateState, parentIndex } = parentsProps;
    return (
        <>
            <Form.Check
                id={`item_${parentIndex}_${itemIndex}_${radioIndex}`}
                className={`item_${parentIndex}_${itemIndex}_${radioIndex}`}
                label={radioItemValue}
                type="radio"
                onChange={(e) =>
                    updateState(parentIndex, itemIndex, e.target.value)
                }
                value={radioItemValue}
                checked={radioItemValue === fieldValue}
                disabled={isReadOnly}
            />
        </>
    );
};

const CheckBoxComponent = (props) => {
    const { fieldValue, parentsProps, itemIndex, isReadOnly } = props;
    const { updateState, parentIndex } = parentsProps;
    return (
        <>
            <Form.Check
                id={`item_${parentIndex}_${itemIndex}`}
                className={`item_${parentIndex}_${itemIndex}`}
                label={fieldValue?.label}
                type="checkbox"
                onChange={(e) => {
                    let value = e.target.checked ? "yes" : "";

                    updateState(parentIndex, itemIndex, value);
                }}
                value={fieldValue.value}
                checked={fieldValue.value === "yes" ? true : false}
                disabled={isReadOnly}
            />
        </>
    );
};

const DropDownComponent = (props) => {
    const { fieldValue, parentsProps, itemIndex, dropDownOptions, isReadOnly } =
        props;
    const { updateState, parentIndex } = parentsProps;

    const options = dropDownOptions?.map((item) => {
        return {
            label: item,
            value: item,
        };
    });

    return (
        <>
            <Select
                className=""
                classNamePrefix="react-select "
                options={options}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                placeholder="Select option"
                isDisabled={isReadOnly}
                onChange={(e) => {
                    updateState(parentIndex, itemIndex, e?.value);
                }}
                value={options?.filter((option) => option.value === fieldValue)}
            />
        </>
    );
};

const ImageComponent = ({ label, attachments }) => {
    const [modalView, setModalView] = useState({
        image: "",
        label: "",
        open: false,
    });

    return (
        <>
            <Form.Label className="d-block font-weight-bold">
                {label}
            </Form.Label>
            {attachments.map((item, key) => {
                return item?.mime === "image/png" ||
                    item?.mime === "image/jpeg" ? (
                    <Image
                        key={key}
                        className="me-2"
                        src={item?.thumbnail || item?.full_path}
                        style={{
                            maxWidth: "45%",
                        }}
                        onClick={() => {
                            setModalView({
                                image: item?.full_path,
                                open: true,
                                label: "View image",
                            });
                        }}
                    />
                ) : (
                    <a href={item?.full_path} target="_blank" rel="noreferrer">
                        Download
                    </a>
                );
            })}
            <ViewImageModal modalInfo={modalView} setModalInfo={setModalView} />
        </>
    );
};

export const TreeInspectionItemRow = (props) => {
    const {
        data,
        id,
        saveInspection,
        status,
        updateSequenceNumber,
        parentIndex,
        setTreeChecklistStorage,
        attachments,
        rerender,
        setRerender,
    } = props;
    const { fields: itemFields, sequence } = data;

    const navigate = useNavigate();
    const availableInputs = [
        "textarea",
        "text",
        "radio",
        "checkbox",
        "custom_input",
        "dropdown",
        "report",
    ];
    const isReadOnly = status?.toUpperCase() !== "DRAFT" ? true : false;
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    const attachmentSequence = `s${data?.id}`;
    const [modalConfirmation, setModalConfirmation] = useState({
        notifMsg: "",
        open: false,
        api: "",
    });

    const deleteApi = async (sequence) => {
        const response =
            await inspectionTreeChecklistApi.deleteTreeInspectionSubItem({
                inspection_id: id,
                tree_inspection_item_id: sequence,
            });

        if (response?.status) {
            setRerender(!rerender);
        }
    };

    return (
        <>
            <Row className="pt-3">
                {itemFields?.map((item, itemIndex, itemFields) => {
                    const className = item?.display?.class
                        ? item?.display?.class
                        : "col-md-1 col-12";
                    if (!availableInputs.includes(item.type)) {
                        return null;
                    }

                    return (
                        <Col
                            className={className}
                            key={`first-level-${itemIndex}`}
                        >
                            {item.type === "textarea" && (
                                <>
                                    {isMobile && (
                                        <Form.Label className="d-block font-weight-bold pt-2">
                                            {item?.label}
                                        </Form.Label>
                                    )}
                                    <TextAreaComponent
                                        fieldValue={item}
                                        parentsProps={props}
                                        itemIndex={itemIndex}
                                        isReadOnly={isReadOnly}
                                    />
                                </>
                            )}
                            {item.type === "text" && (
                                <>
                                    {isMobile && (
                                        <Form.Label className="d-block font-weight-bold pt-2">
                                            {item?.label}
                                        </Form.Label>
                                    )}
                                    <TextComponent
                                        fieldValue={item}
                                        parentsProps={props}
                                        itemIndex={itemIndex}
                                        customInputIndex={null}
                                        isReadOnly={isReadOnly}
                                    />
                                </>
                            )}
                            {item?.type === "radio" &&
                                item?.options?.length > 0 &&
                                item?.options.map((radioItem, radioIndex) => {
                                    return (
                                        <span key={radioIndex}>
                                            <RadioComponent
                                                fieldValue={item.value}
                                                parentsProps={props}
                                                itemIndex={itemIndex}
                                                radioIndex={radioIndex}
                                                radioItemValue={radioItem}
                                                isReadOnly={isReadOnly}
                                            />
                                        </span>
                                    );
                                })}
                            {item?.type === "dropdown" &&
                                item?.options?.length > 0 && (
                                    <>
                                        {isMobile && (
                                            <Form.Label className="d-block font-weight-bold pt-2">
                                                {item?.label}
                                            </Form.Label>
                                        )}
                                        <DropDownComponent
                                            fieldValue={item.value}
                                            parentsProps={props}
                                            itemIndex={itemIndex}
                                            dropDownOptions={item?.options}
                                            isReadOnly={isReadOnly}
                                        />
                                    </>
                                )}

                            {item?.type === "custom_input" &&
                                item?.inputs?.length > 0 &&
                                item?.inputs?.map(
                                    (customInputItems, customIndex) => {
                                        return (
                                            <Row
                                                className="pb-2"
                                                key={`custom-input-${customIndex}`}
                                            >
                                                <Col md={4}>
                                                    {customInputItems.label}
                                                </Col>
                                                <Col md={8}>
                                                    <TextComponent
                                                        fieldValue={
                                                            customInputItems
                                                        }
                                                        parentsProps={props}
                                                        itemIndex={itemIndex}
                                                        customInputIndex={
                                                            customIndex
                                                        }
                                                        isReadOnly={isReadOnly}
                                                    />
                                                </Col>
                                            </Row>
                                        );
                                    }
                                )}

                            {item?.type === "report" && (
                                <CheckBoxComponent
                                    fieldValue={item}
                                    parentsProps={props}
                                    itemIndex={itemIndex}
                                    customInputIndex={null}
                                    isReadOnly={isReadOnly}
                                />
                            )}
                            {itemFields.length === itemIndex + 1 && (
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                {!isReadOnly && "Add evidence"}
                                                {isReadOnly && "View evidence"}
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            {isMobile && (
                                                <Form.Label className="d-block font-weight-bold pt-2">
                                                    {item?.label}
                                                </Form.Label>
                                            )}

                                            {!isReadOnly && data?.id && (
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className="align-middle me-1 cursor-pointer"
                                                    onClick={async () => {
                                                        await saveInspection(
                                                            status,
                                                            false
                                                        );
                                                        navigate(
                                                            `/inspection-checklist/tree-inspections/${id}/${data?.id}/create-evidence`
                                                        );
                                                        setTreeChecklistStorage(
                                                            [parentIndex]
                                                        );
                                                    }}
                                                />
                                            )}
                                            {isReadOnly && data?.id && (
                                                <FontAwesomeIcon
                                                    icon={faEye}
                                                    className="align-middle me-1 cursor-pointer"
                                                    onClick={() => {
                                                        navigate(
                                                            `/inspection-checklist/tree-inspections/${id}/${data?.id}/create-evidence`
                                                        );
                                                    }}
                                                />
                                            )}
                                        </span>
                                    </OverlayTrigger>
                                    {status?.toUpperCase() === "DRAFT" && (
                                        <div>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Delete inspection
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        className="align-middle me-1 cursor-pointer display-block"
                                                        onClick={() => {
                                                            setModalConfirmation(
                                                                {
                                                                    notifMsg:
                                                                        "Are you sure you want to delete this item?",
                                                                    open: true,
                                                                    api: async () => {
                                                                        await saveInspection(
                                                                            status,
                                                                            false
                                                                        );
                                                                        deleteApi(
                                                                            data?.id
                                                                        );
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                    )}
                                </>
                            )}
                            {/*  update the sequence number  */}
                            {itemIndex === 0 && (
                                <>
                                    {isMobile && (
                                        <Form.Label className="d-block font-weight-bold pt-2">
                                            Sequence number
                                        </Form.Label>
                                    )}
                                    <div className="pt-2">
                                        <Form.Control
                                            type="text"
                                            value={sequence}
                                            className="form-control "
                                            placeholder="Sequence number"
                                            onChange={(e) => {
                                                updateSequenceNumber(
                                                    parentIndex,
                                                    e.target.value
                                                );
                                            }}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </>
                            )}
                        </Col>
                    );
                })}
            </Row>
            {attachments[attachmentSequence] && (
                <>
                    <hr />
                    <Row>
                        {attachments[attachmentSequence]["after_photos"] && (
                            <Col md={4}>
                                <ImageComponent
                                    label="After photos"
                                    attachments={
                                        attachments[attachmentSequence][
                                            "after_photos"
                                        ]
                                    }
                                />
                            </Col>
                        )}
                        {attachments[attachmentSequence]["before_photos"] && (
                            <Col md={4}>
                                <ImageComponent
                                    label="Before photos"
                                    attachments={
                                        attachments[attachmentSequence][
                                            "before_photos"
                                        ]
                                    }
                                />
                            </Col>
                        )}
                        {attachments[attachmentSequence]["inspect_photo"] && (
                            <Col md={4}>
                                <ImageComponent
                                    label="Inspection photos"
                                    attachments={
                                        attachments[attachmentSequence][
                                            "inspect_photo"
                                        ]
                                    }
                                />
                            </Col>
                        )}
                    </Row>
                </>
            )}
            <ConfirmationModal
                modalInfo={modalConfirmation}
                setModalInfo={setModalConfirmation}
            />
        </>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";

import { Table } from "react-bootstrap";

import { Tabs } from "./Tabs";
import { LoadingContext } from "App";
import { useParams } from "react-router-dom";
import { moneyFormatter } from "utils/utilities";
import { Information } from "./Information";
import NotyfContext from "contexts/NotyfContext";
import * as copApi from "@api/copApi";

export const Summary = ({ information }) => {
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    let { action, tab } = useParams();
    const notyf = useContext(NotyfContext);

    //
    // States
    //
    const [details, setDetails] = useState({});

    //
    // Functions
    //

    const getDetails = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await copApi.getCop(action, {
                    category: tab,
                });

                if (response.status === 200) {
                    setDetails(response.data.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    //
    // UseEffects
    //

    useEffect(() => {
        getDetails();
    }, [getDetails]);

    let totalAmount = 0;
    let totalMonthlyClaim = 0;
    let totalAddition = 0;
    let totalOmission = 0;
    let totalDeduction = 0;
    let totalPayment = 0;
    let totalCumulatativePayment = 0;
    let totalCumulatativeDeduction = 0;

    let subTotalAmount = 0;
    let subTotalMonthlyClaim = 0;
    let subTotalAddition = 0;
    let subTotalOmission = 0;
    let subTotalDeduction = 0;
    let subTotalPayment = 0;
    let subTotalCumulatativePayment = 0;
    let subTotalCumulatativeDeduction = 0;

    return (
        <>
            <Tabs />
            <hr />
            <Information information={information} />
            <Table bordered responsive className="table-layout-fixed">
                <thead>
                    <tr>
                        <th rowSpan={2} width="250">
                            Schools
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Contract amount
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Monthly claim
                        </th>
                        <th colSpan={2} className="text-center" width="300">
                            Variation
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Deduction
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Cumulatative payment
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Cumulatative deduction
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            This payment
                        </th>
                        <th rowSpan={2} width="150">
                            Reference
                        </th>
                    </tr>
                    <tr>
                        <th rowSpan={2} className="text-end" width="150px">
                            Addition
                        </th>
                        <th rowSpan={2} className="text-end" width="150px">
                            Omission
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(details).map((value, key, row) => {
                        let subTotalAmount = 0;
                        let subTotalMonthlyClaim = 0;
                        let subTotalAddition = 0;
                        let subTotalOmission = 0;
                        let subTotalDeduction = 0;
                        let subTotalPayment = 0;
                        let subTotalCumulatativePayment = 0;
                        let subTotalCumulatativeDeduction = 0;

                        return value.map((childValue, childKey, childRow) => {
                            subTotalAmount += +childValue.contract_amount;
                            subTotalMonthlyClaim += +childValue.monthly_claim;
                            subTotalAddition += +childValue.addition;
                            subTotalOmission += +childValue.omission;
                            subTotalDeduction += +childValue.deduction;
                            subTotalPayment += +childValue.payment;
                            subTotalCumulatativePayment +=
                                +childValue.summarypayment;
                            subTotalCumulatativeDeduction +=
                                +childValue.summarydeduction;

                            totalAmount += +childValue.contract_amount;
                            totalMonthlyClaim += +childValue.monthly_claim;
                            totalAddition += +childValue.addition;
                            totalOmission += +childValue.omission;
                            totalDeduction += +childValue.deduction;
                            totalPayment += +childValue.payment;
                            totalCumulatativePayment +=
                                +childValue.summarypayment;
                            totalCumulatativeDeduction +=
                                +childValue.summarydeduction;

                            return (
                                <React.Fragment key={childKey}>
                                    {childKey === 0 && (
                                        <tr className="bg-light font-weight-bold">
                                            <td colSpan={10}>
                                                {Object.keys(details)[key]}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            {childValue.details.school_name}
                                        </td>
                                        <td className="text-end currency">
                                            {parseFloat(
                                                childValue.contract_amount
                                            )
                                                ? moneyFormatter(
                                                      childValue.contract_amount
                                                  )
                                                : "-"}
                                        </td>
                                        <td className="text-end currency">
                                            {parseFloat(
                                                childValue.monthly_claim
                                            )
                                                ? moneyFormatter(
                                                      childValue.monthly_claim
                                                  )
                                                : "-"}
                                        </td>
                                        <td className="text-end currency">
                                            {parseFloat(childValue.addition)
                                                ? moneyFormatter(
                                                      childValue.addition
                                                  )
                                                : "-"}
                                        </td>
                                        <td className="text-end currency">
                                            {parseFloat(childValue.omission)
                                                ? moneyFormatter(
                                                      childValue.omission
                                                  )
                                                : "-"}
                                        </td>
                                        <td className="text-end currency">
                                            {parseFloat(childValue.deduction)
                                                ? moneyFormatter(
                                                      childValue.deduction
                                                  )
                                                : "-"}
                                        </td>
                                        <td className="text-end currency">
                                            {parseFloat(
                                                childValue.summarypayment
                                            )
                                                ? moneyFormatter(
                                                      childValue.summarypayment
                                                  )
                                                : "-"}
                                        </td>
                                        <td className="text-end currency">
                                            {parseFloat(
                                                childValue.summarydeduction
                                            )
                                                ? moneyFormatter(
                                                      childValue.summarydeduction
                                                  )
                                                : "-"}
                                        </td>
                                        <td className="text-end currency">
                                            {parseFloat(childValue.payment)
                                                ? moneyFormatter(
                                                      childValue.payment
                                                  )
                                                : "-"}
                                        </td>
                                        <td>-</td>
                                    </tr>
                                    {childKey + 1 === childRow.length && (
                                        <tr
                                            className="bg-warning text-black"
                                            style={{
                                                borderBottom:
                                                    "2px solid #6c757d",
                                            }}
                                        >
                                            <td>Sub-total</td>
                                            <td className="text-end currency">
                                                {subTotalAmount
                                                    ? moneyFormatter(
                                                          subTotalAmount
                                                      )
                                                    : "-"}
                                            </td>
                                            <td className="text-end currency">
                                                {subTotalMonthlyClaim
                                                    ? moneyFormatter(
                                                          subTotalMonthlyClaim
                                                      )
                                                    : "-"}
                                            </td>
                                            <td className="text-end currency">
                                                {subTotalAddition
                                                    ? moneyFormatter(
                                                          subTotalAddition
                                                      )
                                                    : "-"}
                                            </td>
                                            <td className="text-end currency">
                                                {subTotalOmission
                                                    ? moneyFormatter(
                                                          subTotalOmission
                                                      )
                                                    : "-"}
                                            </td>
                                            <td className="text-end currency">
                                                {subTotalDeduction
                                                    ? moneyFormatter(
                                                          subTotalDeduction
                                                      )
                                                    : "-"}
                                            </td>
                                            <td className="text-end currency">
                                                {subTotalCumulatativePayment
                                                    ? moneyFormatter(
                                                          subTotalCumulatativePayment
                                                      )
                                                    : "-"}
                                            </td>
                                            <td className="text-end currency">
                                                {subTotalCumulatativeDeduction
                                                    ? moneyFormatter(
                                                          subTotalCumulatativeDeduction
                                                      )
                                                    : "-"}
                                            </td>
                                            <td className="text-end currency">
                                                {subTotalPayment
                                                    ? moneyFormatter(
                                                          subTotalPayment
                                                      )
                                                    : "-"}
                                            </td>
                                            <td></td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        });
                    })}
                    <tr className="font-weight-bold">
                        <td className="text-end">Total</td>
                        <td className="text-end currency">
                            {totalAmount ? moneyFormatter(totalAmount) : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalMonthlyClaim
                                ? moneyFormatter(totalMonthlyClaim)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalAddition
                                ? moneyFormatter(totalAddition)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalOmission
                                ? moneyFormatter(totalOmission)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalDeduction
                                ? moneyFormatter(totalDeduction)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalCumulatativePayment
                                ? moneyFormatter(totalCumulatativePayment)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalCumulatativeDeduction
                                ? moneyFormatter(totalCumulatativeDeduction)
                                : "-"}
                        </td>
                        <td className="text-end currency">
                            {totalPayment ? moneyFormatter(totalPayment) : "-"}
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Container, Row, Button, Col, Breadcrumb } from "react-bootstrap";
import { Card, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import Select from "react-select";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { LoadingContext } from "App";
import { category, type } from "./options";
import * as soiApi from "@api/soiApi";
import * as schoolApi from "@api/schoolApi";

export const SOIForm = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const create = action === "create";
    const notyf = useContext(NotyfContext);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;

    //
    // States
    //

    const [schoolsOriginal, setSchoolsOriginal] = useState([]);
    const [schools, setSchools] = useState([]);
    const schema = yup.object().shape({
        school_id: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        category: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        start_date: yup.string().required("This field is required").nullable(),
        type: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
            })
            .nullable()
            .required("This field is required."),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const soiApiCall = async (data) => {
        data.category = data.category.value;
        data.school_id = data.school_id.value;
        data.type = data.type.value;
        data.start_date = data.start_date
            ? new Date(data.start_date).toLocaleDateString("en-CA")
            : "";
        data.end_date = data.end_date
            ? new Date(data.end_date).toLocaleDateString("en-CA")
            : "";

        try {
            setIsLoadingActive(true);
            const response = create
                ? await soiApi.createSoi(data)
                : await soiApi.updateSoi(action, data);

            if (response.status === 200) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "success",
                    message: `Successfully ${
                        create ? "created" : "updated"
                    } SOI`,
                });
                navigate(`/cop/soi`);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const schoolsMapped = response.data.data.map((field, index) => {
                const updatedName = field.name.replace(/former/gi, "");
                return { label: updatedName, value: field.id };
            });

            setSchoolsOriginal(response.data.data);
            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    //
    // UseEffects
    //

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    useEffect(() => {
        const getSoi = async () => {
            try {
                const response = await soiApi.getSoi(action);
                if (response.status === 200) {
                    const data = response.data.data;

                    reset({
                        school_id: schools?.find(
                            (o) => o?.value === data.school_id
                        ),
                        start_date: data.start_date
                            ? new Date(data.start_date)
                            : "",
                        end_date: data.end_date ? new Date(data.end_date) : "",
                        amount: data.amount,
                        type: type?.find((o) => o?.value === data.type),
                        category: category?.find(
                            (o) => o?.value === data.category
                        ),
                        remarks: data.remarks || "",
                    });
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        };

        if (action !== "create" && schools.length) {
            getSoi();
        }
    }, [action, schools]);

    return (
        <React.Fragment>
            <Helmet title={`${create ? "Create" : "Edit"} SOI`} />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">
                            {create ? "Create" : "Edit"} SOI
                        </h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate("/cop/soi")}
                            >
                                SOI
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {create ? "Create" : "Edit"}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        School
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="school_id"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <>
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={schools}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    value={value}
                                                    onChange={onChange}
                                                    menuPlacement="top"
                                                />
                                            </>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="school_id"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Category
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="category"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={category}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="top"
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="category"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Start date
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="start_date"
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={value}
                                                onChange={onChange}
                                                className={`form-box form-control mt-2 ${
                                                    isMobile && "w-100"
                                                }`}
                                                value={value}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="start_date"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        End date
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="end_date"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={value}
                                                onChange={onChange}
                                                className={`form-box form-control mt-2 ${
                                                    isMobile && "w-100"
                                                }`}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Amount
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="amount"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Form.Control
                                                onChange={onChange}
                                                value={value}
                                                type="number"
                                                className={
                                                    errors.header &&
                                                    "is-invalid"
                                                }
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Type
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="type"
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={type}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="top"
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="type"
                                        render={({ message }) => (
                                            <small className="text-danger">
                                                {message}
                                            </small>
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold">
                                        Remarks
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="remarks"
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <AutoGrowTextarea
                                                onChange={onChange}
                                                fieldValue={value || ""}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col md={12} className="text-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    type="submit"
                                    onClick={handleSubmit(soiApiCall)}
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={() => navigate(`/cop/soi`)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export const zones = [
    {
        label: "North",
        value: "NORTH",
    },
    {
        label: "North east",
        value: "NORTH_EAST",
    },
    {
        label: "East",
        value: "EAST",
    },
    {
        label: "South",
        value: "SOUTH",
    },
    {
        label: "South west",
        value: "SOUTH_WEST",
    },
    {
        label: "West",
        value: "WEST",
    },
];

export const kpi_type = [
    { value: "ADDITIONAL", label: "Additional" },
    { value: "DEDUCTIBLE", label: "Deductible" },
];

export const status = (status) => {
    const lookup = {
        APPROVED: "success",
        COMPLETED: "success",
        REPAIRED: "success",
        ENDORSED: "success",
        SUBMITTED: "info",
        PENDING: "info",
        INSPECTED: "info",
        "PENDING FOR MA APPROVAL": "info",
        "PENDING FOR SCD APPROVAL": "info",
        "PENDING FOR OM APPROVAL": "info",
        REJECTED: "danger",
        VOID: "danger",
        "FOLLOW UP": "danger",
        DRAFT: "secondary",
        "FOR APPROVAL": "warning",
        SUCCESS: "success",
        PROCESSING: "warning",
        FAILED: "danger",
    };

    return lookup[status];
};

export const category_option = [
    { value: "AD-HOC", label: "AD-HOC" },
    { value: "QUOTATION", label: "QUOTATION" },
];

export const zone_codes = (zone) => {
    const lookup = {
        WEST: [
            { value: "W1", label: "W1" },
            { value: "W2", label: "W2" },
        ],
        EAST: [
            { value: "E1", label: "E1" },
            { value: "E2", label: "E2" },
        ],
        SOUTH_WEST: [
            { value: "SW1", label: "SW1" },
            { value: "SW2", label: "SW2" },
        ],
        SOUTH: [{ value: "S", label: "S" }],
        NORTH_EAST: [
            { value: "NE1", label: "NE1" },
            { value: "NE2", label: "NE2" },
        ],
        NORTH: [
            { value: "N1", label: "N1" },
            { value: "N2", label: "N2" },
        ],
    };

    return lookup[zone];
};

export const userRoles = {
    Admin: "ADMIN",
    Technician: "TECHNICIAN",
    "Operations manager": "OPERATIONS-MANAGER",
    "Managing agent": "MANAGING-AGENT",
    "Term contractor": "TERM-CONTRACTOR",
    "School campus department": "SCD",
};

export const status_options = [
    { value: "DRAFT", label: "Draft" },
    { value: "SUBMITTED", label: "Submitted" },
    { value: "APPROVED", label: "Approved" },
    { value: "REJECTED", label: "Rejected" },
    { value: "ACKNOWLEDGED", label: "Acknowldged" },
    { value: "PENDING-APPROVAL", label: "Pending approval" },
];

export const status_options_quotation = [
    { value: "PENDING_MA", label: "Pending MA" },
    { value: "PENDING_TC", label: "Pending TC" },
    { value: "APPROVED", label: "Approved" },
    { value: "COMPLETED", label: "Completed" },
    { value: "REJECTED", label: "Rejected" },
    { value: "VOID", label: "Void" },
];

export const claim_status = [
    { value: "PENDING", label: "Pending" },
    { value: "PARTIAL-CLAIM", label: "Partial claim" },
    { value: "FULLY-CLAIM", label: "Fully claim" },
];

export const status_options_wso = [
    {
        value: "WSO_PENDING_CREATION",
        label: "Works completed (TC to create WSO)",
    },
    { value: "WSO_PENDING_MA", label: "Pending MA" },
    { value: "WSO_PENDING_OM", label: "Pending OM" },
    { value: "COMPLETED", label: "Approved" },
    { value: "WSO REJECTED", label: "Rejected" },
];

export const status_options_claims = [
    { value: "PENDING_MA", label: "Pending Ma" },
    { value: "PENDING_E_INVOICE", label: "Pending E-invoice" },
    { value: "APPROVED", label: "Approved" },
    { value: "COMPLETED", label: "Completed" },
    { value: "REJECTED", label: "Rejected" },
];

export const status_defects = [
    { value: "FOLLOW UP", label: "Follow up" },
    { value: "REPAIRED", label: "Repaired" },
];

export const modules = [
    { value: "QUOTATION", label: "Quotation" },
    { value: "WSO", label: "Work service order" },
    { value: "CLAIM", label: "Claims" },
    { value: "INSPECTION", label: "Inspections" },
];

export const designation = [
    {
        value: "ADMIN",
        label: "Admin",
    },
    {
        value: "ASSISTANT-FACILITY-MANAGER",
        label: "Assistant facility manager",
    },
    {
        value: "ASSISTANT-PROJECT-MANAGER",
        label: "Assistant project manager",
    },
    {
        value: "ARBORIST",
        label: "Arborist",
    },
    {
        value: "ASSITANT-ZONE-SUPERVISOR",
        label: "Assistant zone supervisor",
    },
    {
        value: "BUILDING-TECHNICIAN",
        label: "Building technician",
    },
    {
        value: "COORDINATOR",
        label: "Coordinator",
    },
    {
        value: "CUSTODIAL-TEAM",
        label: "Custodian team",
    },
    {
        value: "EXECUTIVE",
        label: "Executive",
    },
    {
        value: "FACILITY-MANAGER",
        label: "Facility manager",
    },
    {
        value: "ICT-MANAGER",
        label: "ICT manager",
    },
    {
        value: "HORTICULTURIST",
        label: "Horticulturist",
    },
    {
        value: "LEAD-MANAGER",
        label: "Lead manager",
    },
    {
        value: "MANAGER",
        label: "Manager",
    },
    {
        value: "MANAGING-AGENT",
        label: "Managing agent",
    },
    {
        value: "MANAGING-AGENT-QUANTITY-SURVEYOR",
        label: "Managing agent quantity surveyor",
    },
    {
        value: "MAINTENANCE-TEAM",
        label: "Maintenance team",
    },
    {
        value: "OPERATIONS-MANAGER",
        label: "Operations manager",
    },
    {
        value: "OPERATIONS-DIRECTOR",
        label: "Operations director",
    },
    {
        value: "PROJECT-TEAM",
        label: "Project team",
    },
    {
        value: "PROJECT-MANAGER",
        label: "Project manager",
    },
    {
        value: "SAFETY-OFFICER",
        label: "Safety officer",
    },
    {
        value: "SOUTH-1-TEAM",
        label: "South 1 Team",
    },
    {
        value: "SUPERVISOR",
        label: "Supervisor",
    },
    {
        value: "TECHNICIAN",
        label: "Technician",
    },
    {
        value: "TERM-CONTRACTOR",
        label: "Term contractor",
    },
    {
        value: "TERM-CONTRACTOR-QUANTITY-SURVEYOR",
        label: "Term contractor quantity surveyor",
    },
    {
        value: "VICE-PRINCIPAL",
        label: "Vice principal",
    },
    {
        value: "VICE-PRINCIPAL-ADMIN",
        label: "Vice principal admin",
    },
    {
        value: "ZONE-MANAGER",
        label: "Zone manager",
    },
    {
        value: "ZONE-SUPERVISOR",
        label: "Zone supervisor",
    },
];

export const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
];

export const years = [
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
    { value: "2028", label: "2028" },
    { value: "2029", label: "2029" },
    { value: "2030", label: "2030" },
];

export const maintenance_type = [
    { value: "BM", label: "Building Maintenance" },
    { value: "TP", label: "Turf & Plant" },
    { value: "SPECIALIST", label: "Specialist" },
];

export const emptyPagination = {
    data: [],
    meta: {
        current_page: 1,
        from: null,
        last_page: 1,
        links: [],
        path: "",
        per_page: 25,
        to: null,
        total: 0,
    },
    links: "",
};

import api from "../utils/api";

export const getInspectionTreeChecklists = (data) => {
    return api.get("/tree-inspection", { params: data });
};

export const getInspectionTreeChecklist = (id) => {
    return api.get(`/tree-inspection/${id}`);
};

export const updateInspectionTreeChecklist = (id, params) => {
    return api.post(`/tree-inspection/update-tree-inspection/${id}`, params);
};

export const updateInspectionTreePhotos = (id, params) => {
    return api.post(
        `/tree-inspection/update-tree-inspection-photos/${id}`,
        params
    );
};

export const createInspectionTreeChecklist = (data) => {
    return api.post("/tree-inspection/create-tree-inspection", data);
};

export const deleteInspectionTreeChecklist = (inspection_tree_id) => {
    return api.delete(`/tree-inspection/${inspection_tree_id}`);
};

export const getInspectionTreeChecklistConfig = (data) => {
    return api.get(`/config/inspection-tree`, data);
};

export const getChecklistTreeConfig = (data) => {
    return api.get(`/config/checklist-tree`, data);
};

export const getChecklistTreeManualConfig = (data) => {
    return api.get(`/tree-checklist/config`, data);
};

export const updateTreeChecklistSchool = (id, params) => {
    return api.post(
        `/tree-inspection/update-tree-inspection-school/${id}`,
        params
    );
};

export const addTreeInspectionSubItem = (params) => {
    return api.post(`/tree-inspection/add-tree-inspection-subitem`, params);
};

export const deleteTreeInspectionSubItem = (params) => {
    return api.post(`/tree-inspection/delete-tree-inspection-subitem`, params);
};

export const regenerateReport = (id) => {
    return api.get(`/tree-inspection/${id}/regenerate`);
};

import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Col, Modal, Row, Table, Form } from 'react-bootstrap';
import { AutoGrowTextarea } from '../../../components/AutoGrowTextarea';
import DatePicker from "react-datepicker";

export const InspectSchoolModal = ({
    modalInfo,
    setModalInfo,
    api = null,
    type,
    notyf
}) => {
    const [schoolSlug, setSchoolSlug] = useState('');
    const [location, setLocation] = useState('');
    const [purpose, setPurpose] = useState('Inspection/Custodial/Project');
    const [members, setMembers] = useState([]);
    
    const d = new Date();
    d.setHours(8);
    
    const [checkIn, setCheckIn] = useState(d);    
    const closeModal = () => {
        setSchoolSlug('');
        setMembers([]);
        setModalInfo({
            ...modalInfo,
            open: false,
        });
    };

    const callApi = () => {

        if (type === 'bmtp-checklist') {
            if(members.length < 1) {
                return modalInfo.notyf.open({
                    type: "warning",
                    message: "Select member(s) for this inspection",
                });
            }
            api({
                checklist_id: modalInfo.data.id,
                custom_callback: modalInfo.data?.custom_callback,
                school_slug: schoolSlug,
                location: location,
                purpose: purpose,
                members: members,
                check_in: new Date(checkIn).toISOString()
            });
        } else {
            api({
                tree_checklist_id: modalInfo.data.id,
                school_zone: modalInfo?.data?.school?.zone,
                school_slug: modalInfo?.data?.school?.slug,
                school_name: modalInfo?.data?.school?.name,
                school_id: modalInfo?.data?.school?.id,
            });
        }

        closeModal();
    };

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => closeModal()}
            size='lg'
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {type === 'bmtp-checklist'
                        ? modalInfo.notifMsg
                        : `Create inspection`}
                </Modal.Title>
            </Modal.Header>
            {type === 'bmtp-checklist' ? (
                <Modal.Body className='text-left'>
                    <Select
                        classNamePrefix='react-select'
                        options={modalInfo.schools}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={false}
                        onChange={(e) => setSchoolSlug(e?.value)}
                    />
                    <hr />
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <td>Inspection location</td>
                                <td>
                                    <AutoGrowTextarea
                                        onChange={(e) => {
                                            setLocation(e.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Purpose of visit</td>
                                <td>
                                    <Form.Control
                                        name='purpose'
                                        onChange={(e) => {                                           
                                          setPurpose(e.target.value)                                        
                                        }}
                                        type="text"
                                        size="lg"
                                        value={purpose}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Member(s)</td>
                                <td>
                                    <Select
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti
                                        options={modalInfo.technicianOptions}
                                        onChange={(e) => setMembers(e)}
                                    /> 
                                </td>
                            </tr>
                            <tr>
                                <td>Check in time</td>
                                <td>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        showTimeSelect
                                        className={`form-control form-box`}
                                        selected={checkIn}
                                        onChange={(e) => setCheckIn(e)}                                      
                                        value={checkIn}
                                        timeIntervals={1}
                                    />
                                </td>
                            </tr>    
                            <tr>
                                <td>Checklist name</td>
                                <td>{modalInfo.data?.name}</td>
                            </tr>
                            <tr>
                                <td>Frequency</td>
                                <td>{modalInfo.data?.frequency}</td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td>{modalInfo.data?.type}</td>
                            </tr>
                            <tr>
                                <td>Category</td>
                                <td>{modalInfo.data?.category}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>{modalInfo.data?.status}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
            ) : (
                <Modal.Body className='text-left'>
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <td>Checklist name</td>
                                <td>{modalInfo.data?.name}</td>
                            </tr>
                            <tr>
                                <td>School name</td>
                                <td>{modalInfo.data?.school?.name}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
            )}

            <Modal.Footer>
                <Row>
                    <Col md={12} className='text-center'>
                        <Button
                            disabled={!schoolSlug && type === 'bmtp-checklist'}
                            variant='primary'
                            onClick={() => callApi()}
                            className='me-2'
                        >
                            Submit
                        </Button>
                        <Button variant='link' onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

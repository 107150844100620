import { Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";

const Signature = (props) => {
    const [signature, setSignature] = useState({});
    const { disabled, signatureURL } = props;
    const [width] = useState(window.innerWidth);

    const getFinalSignature = (data) => {
        //   signature.getTrimmedCanvas().toDataURL("image/png");
        const finalSignature = signature.toDataURL("image/png");
        props.setSignature(finalSignature);
    };

    useEffect(() => {
        if (signatureURL !== undefined && signatureURL !== "") {
            // signature?.fromDataURL(signatureURL);
            props.setSignature(signatureURL);
        }
    }, [signatureURL, props, signature]);

    return (
        <>
            <Row className="signature-container">
                {disabled && (
                    <div className="sign-container">
                        {signatureURL !== "" ? (
                            <div className="preview-signature">
                                <img alt="" src={signatureURL} />
                            </div>
                        ) : (
                            <div className="not-allowed-sign"></div>
                        )}
                    </div>
                )}

                <ReactSignatureCanvas
                    ref={(ref) => setSignature(ref)}
                    onEnd={(e) => {
                        getFinalSignature(e);
                    }}
                    canvasProps={{
                        height: width >= 1920 ? 195 : 378,
                    }}
                    backgroundColor="transparent"
                />
            </Row>
            <Row>
                <Col md={12} style={{ textAlign: "center" }}>
                    {!disabled ? (
                        <Form.Label
                            onClick={() => {
                                signature.clear();
                                props.setSignature(null);
                            }}
                            style={{ cursor: "pointer", color: "#3F80EA" }}
                            className="mt-2"
                        >
                            Clear
                        </Form.Label>
                    ) : (
                        ""
                    )}
                </Col>
            </Row>
        </>
    );
};

export default Signature;

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingContext } from "../../App";
import NotyfContext from "../../contexts/NotyfContext";
import * as inspectionChecklistApi from "@api/inspectionChecklistApi";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
export const InspectionPreview = ({ status = false }) => {
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const notyf = useContext(NotyfContext);
    const navigate = useNavigate();
    const [inspection, setInspection] = useState();
    const { id } = useParams();

    const getInspections = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await inspectionChecklistApi.previewInspection(
                    id,
                    param
                );
                if (response.status === 200) {
                    setInspection(response?.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: error?.message,
                });
                setIsLoadingActive(false);
            }
        },
        // eslint-disable-next-line
        [notyf, setIsLoadingActive]
    );

    useEffect(() => {
        getInspections();
    }, [getInspections]);

    return (
        <>
            <Helmet title="Inspection servicing preview" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={12} className="text-end">
                        <Button
                            variant="link"
                            onClick={() => {
                                navigate(
                                    `/inspection-checklist/inspection-servicing/${id}`
                                );
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Row className="justify-content-center">
                            <Col
                                md={10}
                                dangerouslySetInnerHTML={{ __html: inspection }}
                            ></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
};

import React from 'react'
import { Badge, Col, FormLabel, Row } from 'react-bootstrap';
import { status } from '../../config';
 
export const DeliverableStatusHistory = ({histories}) => {
  return (
    <Row className='pb-3'>
    <Col md={12}>
        <FormLabel className='font-weight-bold'>
            Status history
        </FormLabel>
        <table className='table-layout-fixed table table-striped table-bordered '>
            <thead>
                <tr>
                    <th> Signatories </th>
                    <th> Status </th>
                    <th> Remarks </th>
                    <th> Updated on </th>
                </tr>
            </thead>
            <tbody>
                {histories?.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {item.approval_type}
                            </td>
                            <td>
                                <Badge
                                    pill
                                    bg={status(
                                        item.status ||
                                            'PENDING'
                                    )}
                                >
                                    {item.status ||
                                        'PENDING'}
                                </Badge>
                            </td>
                            <td>{item.remarks}</td>
                            <td>{item.updated_at}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </Col>
</Row>
  )
}

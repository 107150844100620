import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";
import CardList from "../../../components/ui/CardList";
import NotyfContext from "contexts/NotyfContext";
import { useCallback } from "react";
import * as inspectionChecklistApi from "@api/inspectionChecklistApi";
import * as defectApi from "@api/defectApi";
import { DeleteModal } from "../../../components/DeleteModal";
import * as attachmentApi from "@api/attachmentApi";
import * as yup from "yup";
import { toYMD } from "../../../utils/dateHelper";
import useAuth from "../../../hooks/useAuth";

export const EditEvidence = () => {
    const { hasRole } = useAuth();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const canSingleSendEmail = params.get("canSingleSendEmail");

    const hasRoleCollection = {
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isMaintenanceTeam: hasRole("MAINTENANCE-TEAM"),
        isTechnician: hasRole("TECHNICIAN"),
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isTermContractorQs: hasRole("TERM-CONTRACTOR-QS"),
        isAdmin: hasRole("ADMIN"),
    };

    const canUpdateStatus =
        hasRoleCollection?.isMaintenanceTeam ||
        hasRoleCollection?.isTechnician ||
        hasRoleCollection?.isTermContractor ||
        hasRoleCollection?.isTermContractorQs ||
        hasRoleCollection?.isAdmin
            ? true
            : false;

    const {
        inspection_schedule_id,
        checklist_item_id,
        checklist_sub_item_id,
        defect_id,
    } = useParams();
    const [showDefect, setShowDefect] = useState(false);
    const schema =
        defect_id || showDefect
            ? yup.object().shape({
                  location: yup.string().required("This field is required"),
                  description: yup.string().required("This field is required"),
                  status: yup.string().required("This field is required"),
              })
            : undefined;

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        resolver: schema !== undefined ? yupResolver(schema) : undefined,
    });
    const navigate = useNavigate();

    const [beforePhotos, setBeforePhotos] = useState([]);
    const [cameraBeforePhotos, setCameraBeforePhotos] = useState([]);
    const [afterPhotos, setAfterPhotos] = useState([]);
    const [cameraAfterPhotos, setCameraAfterPhotos] = useState([]);
    const [cameraSupportingImages, setCameraSupportingImages] = useState([]);
    const [supportingPhotos, setSupportingPhotos] = useState([]);
    const [beforeDocuments, setBeforeDocuments] = useState([]);
    const [afterDocuments, setAfterDocuments] = useState([]);
    const [supportingDocuments, setSupportingDocuments] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const notyf = useContext(NotyfContext);
    const [inspectionSchedule, setInspectionSchedule] = useState([]);
    const [defects, setDefects] = useState([]);
    const [currentDefect, setCurrentDefect] = useState([]);
    const [disabled, setDisabled] = useState(false);
    // eslint-disable-next-line
    const [rerender, setRerender] = useState(false);

    const statusOptions = [
        {
            id: 1,
            name: "Inspected",
        },
        {
            id: 2,
            name: "Follow up",
        },
        {
            id: 3,
            name: "Repaired",
        },
    ];

    const saveHandler = async (data) => {
        setDisabled(true);
        let beforePhotosData = [];
        let afterPhotosData = [];
        let supportingPhotosData = [];

        if (showDefect) {
            beforePhotosData = beforePhotos?.getFilesData()?.length
                ? beforePhotos?.getFilesData()
                : [];
            afterPhotosData = afterPhotos?.getFilesData()?.length
                ? afterPhotos?.getFilesData()
                : [];
        }

        supportingPhotosData = supportingPhotos?.getFilesData()?.length
            ? supportingPhotos?.getFilesData()
            : [];

        const formData = new FormData();
        const checklistItemId =
            checklist_sub_item_id !== "null"
                ? checklist_sub_item_id
                : checklist_item_id;
        formData.append("description", data?.description);
        formData.append("location", data?.location);

        formData.append("checklist_item_id", checklistItemId);
        // formData.append("defect", data?.defect || showDefect);

        if (showDefect) {
            formData.append("status", defect_id ? data?.status : "Follow up");
            formData.append("defect", true);

            if (beforePhotosData.length) {
                beforePhotosData.forEach((file) => {
                    formData.append("before_photos[]", file.rawFile);
                });
            }
            if (cameraBeforePhotos.length) {
                cameraBeforePhotos.forEach((file) => {
                    formData.append("before_photos[]", file);
                });
            }
            if (afterPhotosData.length) {
                afterPhotosData.forEach((file) => {
                    formData.append("after_photos[]", file.rawFile);
                });
            }
            if (cameraAfterPhotos.length) {
                cameraAfterPhotos.forEach((file) => {
                    formData.append("after_photos[]", file);
                });
            }
        } else {
            formData.append("status", defect_id ? data?.status : "Inspected");
        }

        if (supportingPhotosData.length) {
            supportingPhotosData.forEach((file) => {
                formData.append("supporting_photos[]", file.rawFile);
            });
        }
        if (cameraSupportingImages.length) {
            cameraSupportingImages.forEach((file) => {
                formData.append("supporting_photos[]", file);
            });
        }

        if (canSingleSendEmail === "true") {
            formData.append("can_single_send_email", true);
        }

        try {
            //defect api
            const result = !defect_id
                ? await defectApi.registerDefect(
                      formData,
                      inspection_schedule_id
                  )
                : await defectApi.updateDefect(formData, defect_id);
            if (result?.status === 200) {
                notyf.open({
                    type: "success",
                    message: !defect_id
                        ? `Successfully created`
                        : `Successfully updated inspection checklist`,
                });
                navigate(
                    `/inspection-checklist/inspection-servicing/${inspection_schedule_id}`
                );
                setDisabled(false);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setDisabled(false);
        }
    };

    const getInspectionSchedule = useCallback(async () => {
        try {
            const response =
                await inspectionChecklistApi.getInspectionChecklist(
                    inspection_schedule_id
                );
            if (response.status === 200) {
                const data = response.data.data;

                let attachments =
                    data.hasOwnProperty("checklist_items") &&
                    data?.checklist_items
                        .filter(
                            (checklistItem) =>
                                checklistItem?.id === checklist_item_id
                        )
                        .map((checklistItem) => {
                            if (
                                checklistItem?.checklist_sub_items !== undefined
                            ) {
                                return checklistItem.checklist_sub_items
                                    .filter(
                                        (checklist_sub_item) =>
                                            checklist_sub_item.id ===
                                            checklist_sub_item_id
                                    )
                                    .map(
                                        (checklistSubItem) =>
                                            checklistSubItem.attachments
                                    );
                            } else {
                                return [];
                            }
                        });

                if (
                    attachments.length &&
                    attachments[0][0] !== undefined &&
                    attachments[0][0].length
                ) {
                    setSupportingDocuments(attachments[0][0]);
                } else {
                    setSupportingDocuments([]);
                }

                setInspectionSchedule(data);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
        // eslint-disable-next-line
    }, [inspection_schedule_id, notyf]);

    const getInspectionDefects = useCallback(async () => {
        try {
            const response = await defectApi.getInspectionDefects(
                inspection_schedule_id
            );

            if (response.status === 200) {
                const data = response.data.data;
                const temp = data?.map((defect) => {
                    return {
                        title: defect?.description,
                        link: `/inspection-checklist/inspection-servicing/${defect?.id}/${inspection_schedule_id}/${checklist_item_id}/${checklist_sub_item_id}/edit-evidence`,
                    };
                });
                setDefects(temp);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [
        inspection_schedule_id,
        notyf,
        checklist_item_id,
        checklist_sub_item_id,
    ]);

    const getInspectionDefect = useCallback(async () => {
        try {
            const response = await defectApi.getDefect(defect_id);

            if (response.status === 200) {
                const data = response.data.data;

                reset({
                    location: data?.location,
                    description: data?.description,
                    status: data?.status,
                    defect: true,
                });

                setShowDefect(true);
                setCurrentDefect(data);

                const beforeDocs =
                    (data?.attachments !== undefined &&
                        data?.attachments.filter(
                            (attachment) => attachment?.remarks === "before"
                        )) ||
                    [];

                const afterDocs =
                    (data?.attachments !== undefined &&
                        data?.attachments.filter(
                            (attachment) => attachment?.remarks === "after"
                        )) ||
                    [];

                setBeforeDocuments(beforeDocs);
                setAfterDocuments(afterDocs);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [defect_id, notyf, reset]);

    const deleteApi = async (id) => {
        try {
            setRerender(false);

            const response = await attachmentApi.deleteAttachment(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "Successfully deleted file",
                });
                setRerender(true);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const handleDefectChange = (e) => {
        if (
            e.target.checked === false &&
            (beforeDocuments.length || afterDocuments.length)
        ) {
            notyf.open({
                type: "danger",
                message: "Remove the before and after pictures first.",
            });
        } else {
            setValue("defect", e.target.checked);
            setShowDefect(e.target.checked);
        }
    };

    useEffect(() => {
        getInspectionSchedule();
        getInspectionDefects();

        if (defect_id) {
            getInspectionDefect();
        } else {
            reset({
                status: "Follow up",
            });
        }
    }, [
        getInspectionSchedule,
        getInspectionDefects,
        getInspectionDefect,
        defect_id,
        reset,
    ]);

    return (
        <React.Fragment>
            <Helmet title="Edit defect | Inspection checklist" />
            <Container fluid className="p-0">
                <Row>
                    <Row style={{ marginBottom: "20px" }}>
                        <Col md={12}>
                            <h3>
                                {defect_id
                                    ? "Oustanding follow up"
                                    : inspectionSchedule?.inspection_category}
                            </h3>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "20px" }}>
                        <Col md={6}>
                            <h4 className="h4 mb-3">
                                {inspectionSchedule?.school?.name}
                            </h4>
                        </Col>
                        <Col md={6} style={{ textAlign: "right" }}>
                            <h4 className="h4 mb-3">
                                {toYMD(inspectionSchedule?.inspected_at)}
                            </h4>
                        </Col>
                    </Row>
                </Row>

                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={9}>
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-4">
                                                <Form.Label className="font-weight-bold">
                                                    Inspection Type
                                                </Form.Label>
                                                <Form.Label className="d-block">
                                                    {
                                                        inspectionSchedule?.inspection_type
                                                    }
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-4">
                                                <Form.Label className="font-weight-bold">
                                                    Frequency
                                                </Form.Label>
                                                <Form.Label className="d-block">
                                                    {
                                                        inspectionSchedule?.frequency
                                                    }
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-4">
                                                <Form.Label className="font-weight-bold">
                                                    Facilities
                                                </Form.Label>
                                                <Form.Label className="d-block">
                                                    {inspectionSchedule.hasOwnProperty(
                                                        "checklist_items"
                                                    ) &&
                                                        inspectionSchedule?.checklist_items
                                                            .filter(
                                                                (
                                                                    checklistItem
                                                                ) =>
                                                                    checklistItem?.id ===
                                                                    checklist_item_id
                                                            )
                                                            .map(
                                                                (
                                                                    checklistItem
                                                                ) =>
                                                                    checklistItem.element
                                                            )}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-4">
                                                <Form.Label className="font-weight-bold">
                                                    Elements
                                                </Form.Label>
                                                <Form.Label className="d-block">
                                                    {checklist_sub_item_id !==
                                                        "null" &&
                                                        inspectionSchedule.hasOwnProperty(
                                                            "checklist_items"
                                                        ) &&
                                                        inspectionSchedule?.checklist_items
                                                            .filter(
                                                                (
                                                                    checklistItem
                                                                ) =>
                                                                    checklistItem?.id ===
                                                                    checklist_item_id
                                                            )
                                                            .map(
                                                                (
                                                                    checklistItem
                                                                ) => {
                                                                    return checklistItem.checklist_sub_items
                                                                        .filter(
                                                                            (
                                                                                checklist_sub_item
                                                                            ) =>
                                                                                checklist_sub_item.id ===
                                                                                checklist_sub_item_id
                                                                        )
                                                                        .map(
                                                                            (
                                                                                checklistSubItem
                                                                            ) =>
                                                                                checklistSubItem.element
                                                                        );
                                                                }
                                                            )}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-4">
                                                <Form.Label className="font-weight-bold">
                                                    Defect
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="defect"
                                                    defaultValue=""
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                            onBlur,
                                                        },
                                                    }) => (
                                                        <Form.Check
                                                            label="Yes"
                                                            type="checkbox"
                                                            value={value || ""}
                                                            checked={
                                                                value || ""
                                                            }
                                                            onChange={
                                                                handleDefectChange
                                                            }
                                                            // disabled={
                                                            //     currentDefect?.status ===
                                                            //     "Repaired"
                                                            // }
                                                            disabled={true}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}></Col>
                                    </Row>
                                    <Row>
                                        {showDefect && (
                                            <>
                                                <Col md={6}>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label className="font-weight-bold">
                                                            Location
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="location"
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={
                                                                        errors.location &&
                                                                        "is-invalid"
                                                                    }
                                                                    // disabled={
                                                                    //     currentDefect?.status ===
                                                                    //         "Repaired" ||
                                                                    //     !canUpdateStatus
                                                                    // }

                                                                    disabled={
                                                                        true
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="location"
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label className="font-weight-bold">
                                                            Description
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="description"
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={
                                                                        errors.description &&
                                                                        "is-invalid"
                                                                    }
                                                                    // disabled={
                                                                    //     currentDefect?.status ===
                                                                    //         "Repaired" ||
                                                                    //     !canUpdateStatus
                                                                    // }
                                                                    disabled={
                                                                        true
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="description"
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    <Row>
                                        {showDefect && (
                                            <>
                                                <Col md={12}>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label className="font-weight-bold">
                                                            Images Before Repair
                                                        </Form.Label>
                                                        <FileUploaderSyncfusion
                                                            setUpload={
                                                                setBeforePhotos
                                                            }
                                                            setCameraUpload={
                                                                setCameraBeforePhotos
                                                            }
                                                            cameraPhotos={
                                                                cameraBeforePhotos
                                                            }
                                                            setModalInfo={
                                                                setModalInfo
                                                            }
                                                            document={
                                                                beforeDocuments
                                                            }
                                                            action={defect_id}
                                                            canUpload={
                                                                !(
                                                                    currentDefect?.status ===
                                                                        "Repaired" ||
                                                                    !canUpdateStatus
                                                                )
                                                            }
                                                            canDelete={
                                                                !(
                                                                    currentDefect?.status ===
                                                                    "Repaired"
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label className="font-weight-bold">
                                                            Images After Repair
                                                        </Form.Label>
                                                        <FileUploaderSyncfusion
                                                            setUpload={
                                                                setAfterPhotos
                                                            }
                                                            setModalInfo={
                                                                setModalInfo
                                                            }
                                                            document={
                                                                afterDocuments
                                                            }
                                                            action={defect_id}
                                                            setCameraUpload={
                                                                setCameraAfterPhotos
                                                            }
                                                            cameraPhotos={
                                                                cameraAfterPhotos
                                                            }
                                                            canUpload={
                                                                !(
                                                                    currentDefect?.status ===
                                                                        "Repaired" ||
                                                                    !canUpdateStatus
                                                                )
                                                            }
                                                            canDelete={
                                                                !(
                                                                    currentDefect?.status ===
                                                                    "Repaired"
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-4">
                                                <Form.Label className="font-weight-bold">
                                                    Inspection images
                                                </Form.Label>
                                                {inspectionSchedule?.status ===
                                                    "Approved" ||
                                                !canUpdateStatus ? (
                                                    <FileUploaderSyncfusion
                                                        setUpload={
                                                            setSupportingPhotos
                                                        }
                                                        setModalInfo={
                                                            setModalInfo
                                                        }
                                                        document={
                                                            supportingDocuments
                                                        }
                                                        action={defect_id}
                                                        setCameraUpload={
                                                            setCameraSupportingImages
                                                        }
                                                        cameraPhotos={
                                                            cameraSupportingImages
                                                        }
                                                        canUpload={false}
                                                        canDelete={false}
                                                    />
                                                ) : (
                                                    <FileUploaderSyncfusion
                                                        setUpload={
                                                            setSupportingPhotos
                                                        }
                                                        setModalInfo={
                                                            setModalInfo
                                                        }
                                                        document={
                                                            supportingDocuments
                                                        }
                                                        action={defect_id}
                                                        setCameraUpload={
                                                            setCameraSupportingImages
                                                        }
                                                        cameraPhotos={
                                                            cameraSupportingImages
                                                        }
                                                        canUpload={
                                                            !(
                                                                currentDefect?.status ===
                                                                "Repaired"
                                                            )
                                                        }
                                                        canDelete={
                                                            !(
                                                                currentDefect?.status ===
                                                                "Repaired"
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-4">
                                                {showDefect ? (
                                                    <>
                                                        <Form.Label className="font-weight-bold d-block">
                                                            Status
                                                        </Form.Label>
                                                        {defect_id ? (
                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name="status"
                                                                defaultValue=""
                                                                render={({
                                                                    field: {
                                                                        value,
                                                                        onChange,
                                                                        onBlur,
                                                                    },
                                                                }) => (
                                                                    <>
                                                                        {statusOptions.map(
                                                                            (
                                                                                status
                                                                            ) => {
                                                                                return (
                                                                                    <Form.Check
                                                                                        key={
                                                                                            status.id
                                                                                        }
                                                                                        type="radio"
                                                                                        id={
                                                                                            status.id
                                                                                        }
                                                                                        label={
                                                                                            status.name
                                                                                        }
                                                                                        name="status"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setValue(
                                                                                                "status",
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                        value={
                                                                                            status.name
                                                                                        }
                                                                                        checked={
                                                                                            value ===
                                                                                                status?.name ||
                                                                                            ""
                                                                                        }
                                                                                        inline
                                                                                        disabled={
                                                                                            currentDefect?.status ===
                                                                                                "Repaired" ||
                                                                                            !canUpdateStatus
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </>
                                                                )}
                                                            />
                                                        ) : (
                                                            <>
                                                                <label>
                                                                    Follow up
                                                                </label>
                                                            </>
                                                        )}

                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="status"
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Form.Label className="font-weight-bold d-block">
                                                            Status
                                                        </Form.Label>
                                                        <label>Inspected</label>
                                                    </>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col md={3}>
                                {defects.length > 0 && (
                                    <CardList
                                        title="Saved Defects"
                                        data={defects}
                                        canSingleSendEmail={canSingleSendEmail}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row className="pt-4">
                            <Col className="text-end">
                                {defect_id && (
                                    <>
                                        {currentDefect?.status !== "Repaired" &&
                                        canUpdateStatus ? (
                                            <Button
                                                disabled={disabled}
                                                variant="primary"
                                                className="me-2"
                                                onClick={handleSubmit(
                                                    saveHandler
                                                )}
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                navigate(
                                                    `/inspection-checklist/outstanding-defects`
                                                )
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Container>
            <DeleteModal
                api={deleteApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
        </React.Fragment>
    );
};

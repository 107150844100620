import React, {
    useState,
    useCallback,
    useContext,
    useEffect,
    createContext,
} from "react";

import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import DynamicTable from "components/ui/DynamicTable";
import { DeleteModal } from "components/DeleteModal";
import NotyfContext from "contexts/NotyfContext";
import * as permissionsApi from "@api/permissionsApi";
import { LoadingContext } from "App";
import { formatConfigFields } from "utils/utilities";
import {
    faPen,
    faPlus,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RerenderContextPermissions = createContext();

export const Permissions = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const notyf = useContext(NotyfContext);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    let isDesktop = width > 1400;

    //
    // States
    //

    const [permission, setPermissions] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [configColumn, setFieldColumn] = useState([]);
    const [searchValue, setSearchValue] = useState({
        name: "",
        slug: "",
        category: "",
    });

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: (cell) => (
            <div>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit permission</Tooltip>}
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faPen}
                            className="align-middle me-1"
                            onClick={() =>
                                navigate(
                                    location.pathname +
                                        "/" +
                                        cell.row.original.id
                                )
                            }
                            size="lg"
                        />
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Delete permission</Tooltip>}
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="align-middle me-1"
                            onClick={() => {
                                openDeleteModal(cell.row.original.id);
                            }}
                            size="lg"
                        />
                    </span>
                </OverlayTrigger>
            </div>
        ),
    };

    //
    // Functions
    //

    const deletePermission = useCallback(
        async (id) => {
            try {
                const response = await permissionsApi.deletePermission(id);
                if (response.status === 200) {
                    notyf.open({
                        type: "success",
                        message: response.data.message,
                    });
                    setRerender(!rerender);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, rerender]
    );

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    const getPermissions = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await permissionsApi.getPermissions(param);

                if (response.status === 200) {
                    setPermissions(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await permissionsApi.getPermissionsConfig(
                    param
                );
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const search = () => {
        getPermissions(searchValue);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getPermissions();
        getConfig();
    }, [getPermissions, rerender, getConfig]);

    return (
        <RerenderContextPermissions.Provider value={[setRerender, rerender]}>
            <Helmet title="Permissions" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Permissions</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={10} sm={10} xs={10}>
                                <Row>
                                    <Col md={3} sm={5} xs={5}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block form-box"
                                            placeholder="Name"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={5} xs={5}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block form-box"
                                            placeholder="Slug"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    slug: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={5} xs={5}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block form-box"
                                            placeholder="Category"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    category: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={2} xs={2}>
                                        <Button
                                            variant="primary"
                                            className="me-1 mb-1 form-box"
                                            onClick={search}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <h1 className="h3 mb-3">
                                    <Button
                                        variant="primary"
                                        className="float-end mb-2 form-box"
                                        onClick={() =>
                                            navigate(
                                                `/settings/permissions/create`
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </h1>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive &&
                            configColumn &&
                            permission.data && (
                                <DynamicTable
                                    dataPagination={permission}
                                    columns={formatConfigFields(
                                        configColumn,
                                        actionField
                                    )}
                                    className="table-layout-fixed"
                                    api={getPermissions}
                                />
                            )}
                        <DeleteModal
                            modalInfo={modalInfo}
                            setModalInfo={setModalInfo}
                            api={deletePermission}
                        />
                    </Card.Body>
                </Card>
            </Container>
        </RerenderContextPermissions.Provider>
    );
};

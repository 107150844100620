import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NotyfContext from "contexts/NotyfContext";
import { Row, Col, ListGroup } from "react-bootstrap";
import * as usersApi from "@api/usersApi";

const NavbarDropdownItem = ({
    icon,
    title,
    description,
    time,
    spacing,
    url = null,
    id,
    api = null,
}) => {
    const navigate = useNavigate();

    const notyf = useContext(NotyfContext);

    const markAsReadirection = async (url, id) => {
        try {
            const response = await usersApi.userReadNotification(id);
            if (response.status === 200) {
                api();
                navigate(url);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error,
            });
        }
    };

    return (
        <ListGroup.Item onClick={() => markAsReadirection(url, id)}>
            <Row className="align-items-center g-0">
                <Col xs={2}>{icon}</Col>
                <Col xs={10} className={spacing ? "pl-2" : null}>
                    <div className="text-dark">{title}</div>
                    <div className="text-muted small mt-1">{description}</div>
                    <div className="text-muted small mt-1">{time}</div>
                </Col>
            </Row>
        </ListGroup.Item>
    );
};

export default NavbarDropdownItem;

import React, { useContext, useEffect } from "react";

import { Button, Col, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import { LoadingContext } from "App";
import { useParams } from "react-router-dom";
import * as authApi from "@api/authApi";

const schema = yup.object().shape({
    password: yup
        .string()
        .required("This field is required")
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/,
            "Length must be at least 12 characters \nMust contain at least one uppercase letter \nMust contain at least one lowercase letter \nMust contain a number \nMust contain at least one special character"
        ),
    password_confirmation: yup
        .string()
        .required("This field is required")
        .oneOf([yup.ref("password")], "Password does not match"),
});

const ResetPasswordForm = () => {
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });
    const notyf = useContext(NotyfContext);
    const { email, token } = useParams();
    const navigate = useNavigate();

    //
    // Functions
    //

    const resetPassword = async (data) => {
        data.token = token;

        try {
            setIsLoadingActive(true);
            const response = await authApi.resetPassword(data);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: response.data.message,
                });
                setIsLoadingActive(false);
                navigate("/auth/sign-in");
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error?.message,
            });
            setIsLoadingActive(false);
        }
    };

    useEffect(() => {
        reset({
            email: email,
        });
    }, [email]);

    return (
        <Form>
            <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Controller
                    control={control}
                    name="email"
                    render={({ field: { value } }) => <p>{value}</p>}
                />
            </Form.Group>
            <Col md={12}>
                <Form.Group className="mb-3">
                    <Form.Label>New password</Form.Label>
                    <Controller
                        control={control}
                        name="password"
                        defaultValue=""
                        render={({ field: { value, onChange, onBlur } }) => (
                            <Form.Control
                                type="password"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                className={`form-box ${
                                    errors.new_password && "is-invalid"
                                }`}
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="password"
                        render={({ message }) => (
                            <small
                                className="text-danger"
                                style={{ whiteSpace: "pre-wrap" }}
                            >
                                {message}
                            </small>
                        )}
                    />
                </Form.Group>
            </Col>
            <Col md={12}>
                <Form.Group className="mb-1">
                    <Form.Label>Confirm new password</Form.Label>
                    <Controller
                        control={control}
                        name="password_confirmation"
                        defaultValue=""
                        render={({ field: { value, onChange, onBlur } }) => (
                            <Form.Control
                                type="password"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                className={`form-box ${
                                    errors.password_confirmation && "is-invalid"
                                }`}
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="password_confirmation"
                        render={({ message }) => (
                            <small className="text-danger">{message}</small>
                        )}
                    />
                </Form.Group>
            </Col>
            <Form.Group className="text-start">
                <Button
                    variant="link"
                    className="ps-0"
                    onClick={() => navigate("/auth/sign-in")}
                >
                    Back to sign in page
                </Button>
            </Form.Group>

            <div className="text-end">
                <Button
                    variant="primary"
                    size="lg"
                    onClick={handleSubmit(resetPassword)}
                >
                    Reset password
                </Button>
            </div>
        </Form>
    );
};

export default ResetPasswordForm;

import React, { useState, useContext, useEffect } from "react";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import { SchoolInformation } from "../../../settings/schools/components/SchoolInformation";
import { LoadingContext } from "App";
import { OperationManagers } from "./OperationManagers";
import { ManagingAgents } from "./ManagingAgents";
import * as userOrganizationApi from "@api/userOrganizationApi";

const schema = yup.object().shape({});

export const ManageUsers = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const notyf = useContext(NotyfContext);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    //
    // States
    //

    const [school, setSchool] = useState([]);
    const [managingAgents, setManagingAgents] = useState([]);
    const [operationManagers, setOperationManagers] = useState([]);
    const [outstandingItems, setOutstandingItems] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [rerender, setRerender] = useState(true);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        getValues,
        setValue,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    const {
        fields: operationManagerFields,
        remove: operationManagerRemove,
        append: operationManagerAppend,
    } = useFieldArray({
        control,
        name: "operation_managers",
        keyName: "key",
    });

    const {
        fields: managingAgentsFields,
        remove: managingAgentRemove,
        append: managingAgentAppend,
    } = useFieldArray({
        control,
        name: "managing_agents",
        keyName: "key",
    });

    const categoryOptions = [
        {
            label: "QUOTATIONS",
            value: "QUOTATIONS",
        },
        {
            label: "Inpections",
            value: "INSPECTIONS",
        },
        {
            label: "Claims",
            value: "CLAIMS",
        },
        { label: "Work service orders", value: "WORK-SERVICE-ORDERS" },
    ];

    //
    // Functions
    //

    const submitUserOrganization = async (data) => {
        try {
            setDisableSubmit(true);
            setIsLoadingActive(true);

            delete data["add_managing_agent"];
            delete data["add_operation_manager"];
            data["school"] = action;

            const response = await userOrganizationApi.updateOrganization(
                action,
                data
            );

            if (response.status === 200) {
                setRerender(!rerender);
                setDisableSubmit(false);
                notyf.open({
                    type: "success",
                    message: "Successfully updated user organization",
                });
            }
        } catch (error) {
            setIsLoadingActive(true);
            setDisableSubmit(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getSchoolUserOrganizations = async (action) => {
        try {
            const response = await userOrganizationApi.getUserOrganization(
                action
            );
            const data = response.data.data;

            if (response.status === 200) {
                setSchool(data.school);

                reset({
                    operation_managers: data.oms,
                    managing_agents: data.mas,
                });

                getUsers(
                    data.school,
                    "OPERATIONS-MANAGER",
                    setOperationManagers
                );
                getUsers(data.school, "MANAGING-AGENT", setManagingAgents);
                getOutstandingItems();
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getUsers = async (school, role, callback) => {
        try {
            const response = await userOrganizationApi.getUsers(action, {
                zone: school.zone,
                role: role,
            });

            if (response.status === 200) {
                let temp = {};

                temp = response?.data.data.map((user) => {
                    return {
                        value: user?.id,
                        label: user?.full_name,
                        email: user?.email,
                    };
                });
                temp.sort((a, b) => a.label.localeCompare(b.label));
                callback(temp);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getOutstandingItems = async () => {
        try {
            setIsLoadingActive(true);

            const response = await userOrganizationApi.getOutstandingItems(
                action
            );

            if (response.status === 200) {
                setIsLoadingActive(false);
                setOutstandingItems(response.data);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const addOperationManager = () => {
        const addOperationManager = getValues("add_operation_manager");

        if (!Object.keys(addOperationManager).length) return;

        if (
            operationManagerFields.find(
                (data) => data.id === addOperationManager.value
            )
        ) {
            notyf.open({
                type: "warning",
                message: `${addOperationManager.label} has already been added`,
            });
            return;
        }

        operationManagerAppend({
            id: addOperationManager.value,
            category_options: null,
            full_name: addOperationManager.label,
            email: addOperationManager.email,
            school_send_email: true,
            updated_at: null,
            updated_by: null,
        });

        setValue("add_operation_manager", null);
    };

    const addManagingAgent = () => {
        const addManagingAgent = getValues("add_managing_agent");

        if (!Object.keys(addManagingAgent).length) return;

        if (
            managingAgentsFields.find(
                (data) => data.id === addManagingAgent.value
            )
        ) {
            notyf.open({
                type: "warning",
                message: `${addManagingAgent.label} has already been added`,
            });
            return;
        }

        managingAgentAppend({
            id: addManagingAgent.value,
            category_options: categoryOptions,
            full_name: addManagingAgent.label,
            email: addManagingAgent.email,
            is_send_email: true,
            updated_at: null,
            updated_by: null,
        });

        setValue("add_managing_agent", null);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getSchoolUserOrganizations(action);
        // eslint-disable-next-line
    }, [action, rerender]);

    useEffect(() => {
        getOutstandingItems();
    }, []);

    return (
        <React.Fragment>
            <Helmet title={`Manager organization users`} />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">Edit organization users</h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() =>
                                    navigate("/tools/user-organization")
                                }
                            >
                                User organaztion
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Edit organization users
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <SchoolInformation school={school} />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <OperationManagers
                            control={control}
                            addOperationManager={addOperationManager}
                            operationManagerFields={operationManagerFields}
                            operationManagerRemove={operationManagerRemove}
                            school={school}
                            operationManagers={operationManagers}
                            getOutstandingItems={getOutstandingItems}
                            outstandingItems={outstandingItems}
                        />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <ManagingAgents
                            control={control}
                            managingAgents={managingAgents}
                            addManagingAgent={addManagingAgent}
                            managingAgentsFields={managingAgentsFields}
                            managingAgentRemove={managingAgentRemove}
                            categoryOptions={categoryOptions}
                            school={school}
                            getOutstandingItems={getOutstandingItems}
                            outstandingItems={outstandingItems}
                        />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={12} className="text-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    type="submit"
                                    onClick={handleSubmit(
                                        submitUserOrganization
                                    )}
                                    disabled={disableSubmit}
                                >
                                    {disableSubmit ? "Saving..." : "Submit"}
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={() =>
                                        navigate("/tools/user-organization")
                                    }
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

import React, { useState } from "react";

import {
    Button,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SignatureModal } from "../modal/SignatureModal";

export const SignatureTab = ({ userData }) => {
    //
    // States
    //

    const [modalAddSignature, setModalAddSignature] = useState({
        id: null,
        open: false,
        severity: "danger",
    });

    return (
        <>
            <Row>
                <Col md={10} sm={10} xs={10}>
                    <h6 className="pt-2 pb-3">Signature</h6>
                </Col>
                <Col md={2} sm={2} xs={2}>
                    <h1 className="h3">
                        <Button
                            variant="primary"
                            className={`float-end mb-2 form-box`}
                            onClick={() =>
                                setModalAddSignature({
                                    open: true,
                                    severity: "primary",
                                })
                            }
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Actions</th>
                                <th>Name</th>
                                <th>Detail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(userData).length &&
                            userData.signature ? (
                                userData.signature.map((data, id) => {
                                    return (
                                        <tr key={id}>
                                            <td style={{ width: "50px" }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            View signature
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span>
                                                        <FontAwesomeIcon
                                                            icon={faEye}
                                                            className="align-middle me-1"
                                                            onClick={() =>
                                                                setModalAddSignature(
                                                                    {
                                                                        view: true,
                                                                        open: true,
                                                                        severity:
                                                                            "primary",
                                                                        signatureImage:
                                                                            data?.path,
                                                                    }
                                                                )
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            size="lg"
                                                        />
                                                    </span>
                                                </OverlayTrigger>
                                                {/*  WIP */}
                                                {/* <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            Edit signature
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span>
                                                        <FontAwesomeIcon
                                                            icon={faPen}
                                                            className="align-middle me-1"
                                                            onClick={() =>
                                                                setModalAddSignature(
                                                                    {
                                                                        open: true,
                                                                        severity:
                                                                            "primary",
                                                                    }
                                                                )
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            size="lg"
                                                        />
                                                    </span>
                                                </OverlayTrigger> */}
                                            </td>
                                            <td>{data?.name}</td>
                                            <td>{data?.signature_details}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        No signatures created
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <SignatureModal
                modalInfo={modalAddSignature}
                setModalInfo={setModalAddSignature}
            />
        </>
    );
};

import React, {
    useState,
    useCallback,
    useEffect,
    useRef,
    useContext,
    useMemo,
} from "react";

import {
    Button,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import {
    faDollarSign,
    faMinus,
    faParachuteBox,
    faPlus,
    faSortNumericDown,
    faStickyNote,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import {
    checkSequenceDuplicates,
    commaSeparateNumber,
    errorMessage,
    roundToTwoDecimal,

} from "utils/utilities";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { DiscountModal } from "components/common/works-order/modal/DiscountModal";
import { AddNoteModal } from "components/common/works-order/modal/AddNoteModal";
import { UploadDocumentModal } from "components/common/works-order/modal/UploadDocumentModal";
import { totalQuotaionComputation } from "utils/totalQuotationComputation";
import { DeleteModalWorksOrder } from "components/common/works-order/modal/DeleteModalWorksOrder";
import { useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { TableInput } from "./TableInput";
import { AddHeaderModal } from "components/common/works-order/modal/AddHeaderModal";
import { InsertItemModal } from "components/common/works-order/modal/InsertItemModal";
import * as fsrsApi from "@api/fsrsApi";

const SortInput = (props) => {
    const { column, row, cell, updateSort, canViewRow, canSignRow } = props;

    const { action } = useParams();

    const onChange = (e) =>
        updateSort(
            row.index,
            column.id,
            e.target.value,
            row.original.parent_id
        );

    if (
        row.depth === 0 &&
        ((!canViewRow && canSignRow) || action === "create")
    ) {
        return (
            <Form.Control
                onChange={onChange}
                type="text"
                value={cell.value}
                style={{
                    textAlign: "right",
                }}
                // autoFocus={row.original.is_focus_sort}
            />
        );
    } else {
        return <div className="text-end">{cell.value || "-"}</div>;
    }
};

export const ItemsForm = ({
    control,
    errors,
    selectedRows,
    setValue,
    view,
    sign,
    gstConfig: preProcessedGST,
    setSelectedRows,
    setResetItemsFlag,
    resetItemsFlag,
    setClaimGst
}) => {
    const { action } = useParams();
    const asyncPaginateRef = useRef([]);

 
    const gstConfig = useMemo(() => {
        if (action) {
            // Process rows
            const processed = (selectedRows
                .map(row => ({
                    wso_year: new Date(row.completed_on).getFullYear(),
                    wso_gst: row.wso_completed_at_gst
                }))
                .sort((a, b) => (a.wso_year > b.wso_year ? 1 : b.wso_year > a.wso_year ? -1 : 0))
            )
            
            return processed[processed.length-1]?.wso_gst || preProcessedGST || 8
        } else {
            return preProcessedGST
        }
    }, [preProcessedGST, action, selectedRows])

    useEffect(() => {
        setClaimGst(gstConfig);
    }, [gstConfig])
    
    //
    // States
    //

    const [showImageUpload, setShowImageUpload] = useState({
        open: false,
        action: "add",
    });
    const [showDiscount, setShowDiscount] = useState({
        open: false,
        action: "add",
    });
    const [showNote, setShowNote] = useState({
        open: false,
        action: "add",
    });
    const [showDelete, setShowDelete] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
        delete: false,
    });
    const [showHeader, setShowHeader] = useState({
        open: false,
        action: "add",
    });
    const [showInsertItem, setShowInsertItem] = useState({
        open: false,
        action: "add",
    });
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    let isDesktop = width > 1400;

    const [rates] = useState({ less_percent: 31, star_rate: 5 });
    const [cloneQuotationItems, setCloneQuotationItems] = useState([]);
    const [finalTotal, setFinalTotal] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [option, setOption] = useState(true);
    const notyf = useContext(NotyfContext);

    const tableColumns = (parentId = null) => {
        return [
            {
                Header: "Actions",
                accessor: "actions",
                width: "70px",
                Cell: ({ row }) => {
                    return (
                        <>
                            {row.depth === 0 && !row.original.view && (
                                <>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Apply discounts
                                            </Tooltip>
                                        }
                                        placement="top"
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faDollarSign}
                                                className="me-1"
                                                onClick={() =>
                                                    setShowDiscount({
                                                        action: "add",
                                                        custodial_discount_quantity:
                                                            "",
                                                        custodial_discount_description:
                                                            "",
                                                        open: true,
                                                        id: row.id,
                                                        parent_id:
                                                            row.original
                                                                .parent_id,
                                                    })
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                className="me-1"
                                                icon={faTrash}
                                                onClick={() =>
                                                    openDeleteModal(
                                                        row,
                                                        parentId
                                                    )
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                {view ? "View" : "Add"} note
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faStickyNote}
                                                className="me-1"
                                                onClick={() =>
                                                    setShowNote({
                                                        action: "add",
                                                        id: row.id,
                                                        parent_id:
                                                            row.original
                                                                .parent_id,
                                                        note:
                                                            row.original
                                                                ?.note || "",
                                                        open: true,
                                                    })
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </>
                            )}

                            {row.depth === 1 && !row.original.view && !view && (
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                className="me-1"
                                                icon={faTrash}
                                                onClick={() =>
                                                    openDeleteModal(
                                                        row,
                                                        parentId
                                                    )
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </>
                            )}
                            {row.depth === 0 &&
                                !row.original.hasHeader &&
                                !view && (
                                    <OverlayTrigger
                                        overlay={<Tooltip>Add header</Tooltip>}
                                        placement="top"
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                className="me-1"
                                                onClick={() =>
                                                    setShowHeader({
                                                        open: true,
                                                        action: "add",
                                                        id: row.id,
                                                        parent_id:
                                                            row.original
                                                                .parent_id,
                                                        header: "",
                                                    })
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                )}
                            {row.depth === 0 &&
                                row.original.hasHeader &&
                                !view && (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>Remove header</Tooltip>
                                        }
                                        placement="top"
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faMinus}
                                                className="align-middle"
                                                onClick={() =>
                                                    constructRemoveHeaderRows(
                                                        row.original.parent_id,
                                                        row.id
                                                    )
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                )}
                            {row.depth === 0 && !view && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Insert FSR item
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faParachuteBox}
                                            className="me-1"
                                            onClick={() =>
                                                setShowInsertItem({
                                                    open: true,
                                                    row: row,
                                                })
                                            }
                                            size="lg"
                                        />
                                    </span>
                                </OverlayTrigger>
                            )}
                        </>
                    );
                },
            },
            {
                accessor: "serial_no",
                Cell: SortInput,
                Header: () => <div className="text-end">S/N</div>,
                width: isDesktop ? 60 : 120,
            },
            {
                accessor: "item_number",
                Cell: ({ row }) => {
                    return (
                        <div className="text-end">
                            {row.original.is_star_rate
                                ? "Star rate"
                                : row.original.item_number}
                        </div>
                    );
                },
                Header: () => <div className="text-end">Item number</div>,
                width: 60,
            },
            {
                Header: "Sub header",
                accessor: "item_group_description",
            },
            {
                Header: "Description",
                accessor: "item_description",
            },
            {
                accessor: "note",
                Cell: (row) => <div>{row.value || ""}</div>,
                Header: () => <div>Note</div>,
                width: 80,
            },
            {
                accessor: "quantity",
                Cell: TableInput,
                Header: () => <div className="text-end">Quantity</div>,
                width: 120,
            },
            {
                accessor: "unit",
                Cell: (row) => <div className="text-end">{row.value}</div>,
                Header: () => <div className="text-end">Unit</div>,
                width: 60,
            },
            {
                accessor: "rate",
                Cell: ({ row }) => {
                    return (
                        <div
                            style={{
                                textAlign: "right",
                            }}
                            className="currency"
                        >
                            {row.original.rate && row.original.is_active
                                ? commaSeparateNumber(row.original.rate)
                                : row.original.rate && !row.original.is_active
                                ? row.original.rate
                                : "-"}
                        </div>
                    );
                },
                Header: () => <div className="text-end">Rate</div>,
                width: 60,
            },
            {
                accessor: "amount",
                Header: () => <div className="text-end">Amount</div>,
                width: 60,

                Cell: ({ row }) => {
                    const parsedAmount =
                        typeof row.original.amount === "number"
                            ? roundToTwoDecimal(row.original.amount).toFixed(2)
                            : row.original.amount;

                    return (
                        <div
                            style={{
                                textAlign: "right",
                            }}
                            className="currency"
                        >
                            {row.original.amount
                                ? commaSeparateNumber(parsedAmount)
                                : "-"}
                        </div>
                    );
                },
            },
        ];
    };
    view && tableColumns().shift();

    //
    // Functions
    //

    const openDeleteModal = (row, parentId) => {
        setResetItemsFlag(false);
        setShowDelete({
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            row: row,
            parentId: parentId,
            severity: "primary",
        });
    };

    const updateData = (data, rowIndex, columnID, value) => {
        setResetItemsFlag(false);

        let quotationItemsClone = [...data];

        quotationItemsClone = quotationItemsClone.map((row, index) => {
            quotationItemsClone[index]["is_focus"] = false;

            if (index === rowIndex) {
                quotationItemsClone[rowIndex][columnID] = value;
                row.rate = row?.rate?.replace(/,/g, "");
                let totalRowAmount = 0;

                totalRowAmount = parseFloat(
                    roundToTwoDecimal(row?.rate * row.quantity)
                ).toFixed(2);

                if (row.minimum) {
                    row.minimum = parseInt(row.minimum);
                    totalRowAmount =
                        totalRowAmount < row.minimum
                            ? row.minimum
                            : totalRowAmount;
                }

                return {
                    ...quotationItemsClone[rowIndex],
                    [columnID]: value,
                    amount: totalRowAmount,
                    is_focus: true,
                };
            }
            return row;
        });

        const targetRow = selectedRows.find((x) => x.id === data[0].parent_id);
        targetRow.items = quotationItemsClone;

        computeFinalTotal(selectedRows);
    };

    const computeFinalTotal = useCallback(
        (selectedRows, index = null, percent) => {
            let total_amount = 0;
            let sub_total_amount = 0;
            let claim_total = 0;
            let total_gst = 0;
            let custodial_total = 0;
            let fsr_final_total = 0;
            let custodial_count = 0;

            if (selectedRows && selectedRows[index] && percent) {
                selectedRows[index]["claim_percentage"] = percent;
            }

            selectedRows.forEach((parent, index) => {
                const computedTotals = totalQuotaionComputation(
                    parent.items,
                    rates,
                    true,
                    parent.claim_percentage || 100,
                    gstConfig
                );

                const totalAmountParsed = parseFloat(
                    roundToTwoDecimal(
                        computedTotals.sub_total + computedTotals.gst
                    )
                ).toFixed(2);

                claim_total =
                    computedTotals.claim_sub_total + computedTotals.claim_gst;

                selectedRows[index]["final_total"] = computedTotals;
                selectedRows[index]["final_total"]["claim_total"] = claim_total;
                selectedRows[index]["final_total"]["total_amount"] =
                    totalAmountParsed;

                sub_total_amount += computedTotals.claim_sub_total;
                total_amount += computedTotals.claim_total;
                total_gst += computedTotals.claim_gst;

                custodial_total = computedTotals.custodial_total;
                custodial_count = computedTotals.custodial_count;
                fsr_final_total = computedTotals.fsr_final_total;

                parent.items.forEach((item, itemIndex) => {
                    if (
                        selectedRows[index]["claim_status"] === "PARTIAL" ||
                        selectedRows[index]["claim_status"] === "COMPLETED" ||
                        view
                    ) {
                        selectedRows[index]["items"][itemIndex]["view"] = true;
                    }
                });
            });

            setCloneQuotationItems(selectedRows);
            setValue("quotation_items", selectedRows);

            !resetItemsFlag && setSelectedRows(JSON.stringify(selectedRows));

            const finalTotals = {
                total_amount: parseFloat(
                    roundToTwoDecimal(total_amount)
                ).toFixed(2),
                sub_total_amount: parseFloat(
                    roundToTwoDecimal(sub_total_amount)
                ).toFixed(2),
                total_gst: parseFloat(roundToTwoDecimal(total_gst)).toFixed(2),
                custodial_total: parseFloat(
                    roundToTwoDecimal(custodial_total)
                ).toFixed(2),
                fsr_final_total: parseFloat(
                    roundToTwoDecimal(fsr_final_total)
                ).toFixed(2),
                custodial_count: custodial_count,
            };

            setFinalTotal(finalTotals);
            setValue("grand_totals", finalTotals);
        },
        // eslint-disable-next-line
        [rates, setValue, resetItemsFlag, gstConfig]
    );

    const constructDeletedRows = useCallback(() => {
        const row = showDelete.row;
        const reconstructedRows = [...selectedRows];

        let objToUpdate = reconstructedRows.find(
            (obj) => obj.id === row.original.parent_id
        );

        if (row && row.depth === 0) {
            if (objToUpdate["items"][row.index]["hasHeader"]) {
                objToUpdate["items"].splice(row.index - 1, 2);
            } else {
                objToUpdate["items"].splice(row.index, 1);
            }

            computeFinalTotal(reconstructedRows);
        } else if (row && row.depth === 1) {
            const parentRow = row.id.split(".")[0];

            reconstructedRows[showDelete.parentId]["items"][parentRow][
                "subRows"
            ].splice(row.index, 1);

            computeFinalTotal(reconstructedRows);
        }
    }, [computeFinalTotal, showDelete]);

    const handleLoadMore = async (search, { page }) => {
        const response = await fsrsApi.getFsrOptions({
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response.data;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const onChangeSelected = (selectedRows) => {
        setOption(selectedRows);
    };

    const addSelected = (index, parent_id) => {
        if (!option?.value) {
            notyf.open({
                type: "warning",
                message: "Please select an FSR item to proceed",
            });
            return;
        }

        let optionsClone = { ...option?.value };

        optionsClone["rate"] = optionsClone["rate"]?.replace(/,/g, "");

        optionsClone["amount"] = parseFloat(
            roundToTwoDecimal(optionsClone.rate * optionsClone.quantity)
        ).toFixed(2);
        optionsClone["minimum"] = false;
        optionsClone["parent_id"] = parent_id;

        selectedRows[index]["items"] = [
            ...selectedRows[index]["items"],
            optionsClone,
        ];

        setSelectedRows(JSON.stringify(selectedRows));
        setResetItemsFlag(false);

        asyncPaginateRef.current[index].clearValue();
        notyf.open({
            type: "success",
            message: "Successfully added FSR item",
        });
    };

    const constructRemoveHeaderRows = (parentId, childId) => {
        const reconstructedRows = [...selectedRows];
        let objToUpdate = reconstructedRows.find((obj) => obj.id === parentId);

        if (objToUpdate) {
            delete objToUpdate["items"][childId]["hasHeader"];
            objToUpdate["items"].splice(childId - 1, 1);

            setSelectedRows(JSON.stringify(reconstructedRows));
            setResetItemsFlag(false);
        }
    };

    const constructAddHeaderRows = useCallback(() => {
        const reconstructedRows = [...selectedRows];
        let objToUpdate = reconstructedRows.find(
            (obj) => obj.id === showHeader.parent_id
        );

        if (showHeader.header && objToUpdate) {
            objToUpdate["items"][showHeader.id]["hasHeader"] = true;
            objToUpdate["items"].splice(parseInt(showHeader.id, 10), 0, {
                header: showHeader.header,
            });

            setSelectedRows(JSON.stringify(reconstructedRows));
            setResetItemsFlag(false);
        }
    }, [showHeader]);

    const constructNoteRows = useCallback(() => {
        const note = showNote.note;
        const reconstructedRows = [...selectedRows];

        let objToUpdate = reconstructedRows.find(
            (obj) => obj.id === showNote.parent_id
        );

        if (objToUpdate) {
            objToUpdate["items"][showNote.id]["note"] = note;
            setSelectedRows(JSON.stringify(reconstructedRows));

            setResetItemsFlag(false);
        }
    }, [showNote]);

    const constructInsertItem = useCallback(() => {
        const { newValue, row, position } = showInsertItem;
        const reconstructedRows = [...selectedRows];

        if (!newValue) return;
        newValue.parent_id = row.original?.parent_id;

        let objToUpdate = reconstructedRows.find(
            (obj) => obj.id === row.original?.parent_id
        );

        // Define index identifier to insert new value
        let index = position === "UP" ? row?.index : row?.index + 1;
        if (position === "UP" && objToUpdate["items"][row.index]["hasHeader"])
            index--;

        // Insert new value to items storage
        objToUpdate["items"] = [
            ...objToUpdate["items"].slice(0, index),
            newValue,
            ...objToUpdate["items"].slice(index),
        ];

        // Re-align serial_no from 1 onwards
        let serialNoToFollow = 1;
        objToUpdate["items"] = objToUpdate["items"].map((row) => {
            if (row?.header) {
                return {
                    ...row,
                };
            }
            return {
                ...row,
                serial_no: serialNoToFollow++,
            };
        });

        setResetItemsFlag(false);
        setSelectedRows(JSON.stringify(reconstructedRows));
        computeFinalTotal(reconstructedRows);
    }, [computeFinalTotal, showInsertItem]);

    const updateJobDescription = (value, index) => {
        const reconstructedRows = [...selectedRows];

        reconstructedRows[index]["job"] = value;

        setSelectedRows(JSON.stringify(reconstructedRows));
        setResetItemsFlag(false);
    };

    const constructDiscountRows = useCallback(() => {
        const discount_data = showDiscount.discount_data;
        const reconstructedRows = [...selectedRows];

        let objToUpdate = reconstructedRows.find(
            (obj) => obj.id === showDiscount.parent_id
        );

        if (discount_data) {
            if (!objToUpdate["items"][showDiscount.id].subRows)
                objToUpdate["items"][showDiscount.id].subRows = [];

            const custodial_discount = {
                item_description: discount_data?.custodial_discount_description,
                quantity: discount_data?.custodial_discount_quantity,
                unit: "no",
                rate: "(250)",
                amount: 250.0 * discount_data?.custodial_discount_quantity,
            };

            discount_data.discount_application === "all"
                ? objToUpdate["items"].forEach((item) => {
                      if (item.item_number !== "Star rate") {
                          item.subRows = [];
                          item.subRows.push(custodial_discount);
                      }
                  })
                : objToUpdate["items"][showDiscount.id].subRows.push(
                      custodial_discount
                  );

            setResetItemsFlag(false);
            setSelectedRows(JSON.stringify(reconstructedRows));
        }
    }, [showDiscount]);

    const catchMissingAmount = (selectedRows) => {
        selectedRows.forEach((quotation) => {
            quotation["items"].forEach((item, index, array) => {
                if (!item?.amount && !item.header) {
                    array[index]["amount"] = parseFloat(
                        roundToTwoDecimal(item?.rate * item?.quantity)
                    ).toFixed(2);
                }
            });
        });

        return selectedRows;
    };

    const updateSort = (rowIndex, columnID, value, parentId) => {
        let reconstructedRows = [...selectedRows];
        let objToUpdate = reconstructedRows.find((obj) => obj.id === parentId);

        objToUpdate = objToUpdate["items"].map((row, index) => {
            if (!objToUpdate["items"][index]["header"]) {
                objToUpdate["items"][index]["is_focus_quantity"] = false;
                objToUpdate["items"][index]["is_focus_sort"] = false;

                if (index === rowIndex) {
                    return {
                        ...objToUpdate["items"][rowIndex],
                        [columnID]: value,
                        is_focus_sort: true,
                    };
                }
            }
            return row;
        });

        reconstructedRows = reconstructedRows.map((obj) => {
            if (obj.id === parentId) {
                return { ...obj, items: objToUpdate };
            }

            return obj;
        });
        setResetItemsFlag(false);
        computeFinalTotal(reconstructedRows);
    };

    const sortItems = (id) => {
        let reconstructedRows = [...selectedRows];
        let objToUpdate = reconstructedRows.find((obj) => obj.id === id);

        const hasDuplicates = checkSequenceDuplicates(objToUpdate["items"]);

        if (hasDuplicates) {
            notyf.open({
                type: "warning",
                message: "Sequence numbers should be unique",
            });
            return;
        }

        objToUpdate = objToUpdate["items"].map((row, index) => {
            if (row.header) {
                const parentOfHeader = objToUpdate["items"][index + 1];

                return {
                    ...objToUpdate["items"][index],
                    serial_no: parseFloat(parentOfHeader?.serial_no) - 0.5,
                };
            }
            return row;
        });

        let sortedItems = objToUpdate.sort((a, b) => a.serial_no - b.serial_no);

        sortedItems = sortedItems.map((row, index) => {
            return {
                ...sortedItems[index],
            };
        });

        reconstructedRows = reconstructedRows.map((obj) => {
            if (obj.id === id) {
                return { ...obj, items: sortedItems };
            }
            return obj;
        });

        setResetItemsFlag(false);
        setSelectedRows(JSON.stringify(reconstructedRows));
        notyf.open({
            type: "success",
            message: "Quotation items have been sorted.",
        });
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (selectedRows) {
            if (selectedRows.length) {
                selectedRows = catchMissingAmount(selectedRows);
            }

            selectedRows.forEach((data, index) => {
                if (!selectedRows[index]["claim_percentage"]) {
                    selectedRows[index]["claim_percentage"] =
                        selectedRows[index]["remaining_claim_percentage"];
                }

                if (action === "create") {
                    const max = data?.remaining_claim_percentage || 100;
                    let remainingClaim = parseInt(data?.claim_percentage, 10);
                    if (remainingClaim > max && remainingClaim !== undefined)
                        remainingClaim = max;
                    selectedRows[index]["claim_percentage"] = remainingClaim;
                }
            });

            setCloneQuotationItems(selectedRows);
            computeFinalTotal(selectedRows);
        }
        // eslint-disable-next-line
    }, [selectedRows]);

    useEffect(() => {
        if (showDelete.delete) constructDeletedRows();
        // eslint-disable-next-line
    }, [showDelete]);

    useEffect(() => {
        constructNoteRows();
        // eslint-disable-next-line
    }, [showNote]);

    useEffect(() => {
        constructDiscountRows();
        // eslint-disable-next-line
    }, [showDiscount]);

    useEffect(() => {
        constructAddHeaderRows();
        // eslint-disable-next-line
    }, [showHeader]);

    useEffect(() => {
        constructInsertItem();
        // eslint-disable-next-line
    }, [showInsertItem]);

    return (
        <>
            {cloneQuotationItems.length > 0 ? (
                cloneQuotationItems?.map((data, index) => {
                    const items =
                        typeof data?.items === "string"
                            ? JSON.parse(data?.items)
                            : data?.items;

                    return (
                        <React.Fragment key={index}>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <p className="mb-3 font-weight-bold">
                                        <a
                                            href={`${window.location.origin}/works-order/quotation-list/${data.id}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1"
                                            style={{
                                                color: "#3F80EA",
                                            }}
                                        >
                                            {data.reference_number}
                                        </a>
                                    </p>
                                </Col>
                                {!view && (
                                    <Col md={6}>
                                        <Row className="mb-3">
                                            <Form.Label>
                                                Add FSR item
                                            </Form.Label>
                                            <Col md={11}>
                                                <AsyncPaginate
                                                    selectRef={(ref) =>
                                                        (asyncPaginateRef.current[
                                                            index
                                                        ] = ref)
                                                    }
                                                    isClearable
                                                    loadOptions={handleLoadMore}
                                                    hasMore={hasMore}
                                                    placeholder="Search FSR"
                                                    additional={{
                                                        page: 1,
                                                    }}
                                                    onChange={(value) =>
                                                        onChangeSelected(value)
                                                    }
                                                />
                                            </Col>

                                            <Col md={1}>
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip
                                                            id={`tooltip-top`}
                                                        >
                                                            Add new fsr item
                                                        </Tooltip>
                                                    }
                                                    placement="top"
                                                >
                                                    <Button
                                                        className="form-box float-end"
                                                        variant="primary"
                                                        onClick={() => {
                                                            addSelected(
                                                                index,
                                                                data.id
                                                            );
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faPlus}
                                                        />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                                <Col md={12}>
                                    <Form.Label className="font-weight-bold">
                                        Quotation job description
                                    </Form.Label>
                                    <Row>
                                        <Col md={6}>
                                            {view ? (
                                                data?.job || "-"
                                            ) : (
                                                <AutoGrowTextarea
                                                    onChange={(e) =>
                                                        updateJobDescription(
                                                            e.target.value,
                                                            index
                                                        )
                                                    }
                                                    fieldValue={data?.job}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {!view && (
                                <Row>
                                    <Col md={12}>
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    Sort items
                                                </Tooltip>
                                            }
                                            placement="top"
                                        >
                                            <Button
                                                className={`form-box float-end mb-2 me-2 ${
                                                    isMobile && "w-100"
                                                }`}
                                                variant="primary"
                                                onClick={() =>
                                                    sortItems(data?.id)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faSortNumericDown}
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            )}
                            <DynamicTable
                                dataPagination={{ data: items || [] }}
                                columns={tableColumns(index)}
                                className="table-layout-fixed"
                                isExpanded
                                updateData={updateData}
                                updateSort={updateSort}
                                isPaginated={false}
                                isStriped={false}
                                canViewRow={view}
                                canSignRow={sign}
                                pageSize={1000}
                            />
                            <Row className="mb-4">
                                <Col md={6}></Col>
                                <Col md={6}>
                                    {data?.final_total ? (
                                        <>
                                            <Form.Group className={`mb-4`}>
                                                <Row className="mb-1">
                                                    <Col md={4}>
                                                        Less custodial count
                                                    </Col>
                                                    <Col md={8}>
                                                        <Controller
                                                            control={control}
                                                            defaultValue=""
                                                            name="custodial_count"
                                                            render={({
                                                                field: {
                                                                    value,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    style={{
                                                                        textAlign:
                                                                            "right",
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                    value={
                                                                        value ||
                                                                        "-"
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <Form.Control
                                                            disabled
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                            type="text"
                                                            value={
                                                                data
                                                                    ?.final_total
                                                                    ?.custodial_count ||
                                                                0
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="mb-4">
                                                <Row className="mb-1">
                                                    <Col
                                                        md={4}
                                                    >{`FSR total`}</Col>
                                                    <Col md={8}>
                                                        <Controller
                                                            control={control}
                                                            defaultValue=""
                                                            name="fsr_final_total"
                                                            render={({
                                                                field: {
                                                                    value,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    disabled
                                                                    style={{
                                                                        textAlign:
                                                                            "right",
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                    type="text"
                                                                    value={
                                                                        value ||
                                                                        "-"
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <Form.Control
                                                            disabled
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                            type="text"
                                                            value={`S$ ${commaSeparateNumber(
                                                                roundToTwoDecimal(
                                                                    data
                                                                        ?.final_total
                                                                        ?.fsr_final_total
                                                                ).toFixed(2)
                                                            )}`}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col
                                                        md={4}
                                                    >{`Less custodial`}</Col>
                                                    <Col md={8}>
                                                        <Controller
                                                            control={control}
                                                            defaultValue=""
                                                            name="custodial_total"
                                                            render={({
                                                                field: {
                                                                    value,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    disabled
                                                                    style={{
                                                                        textAlign:
                                                                            "right",
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                    type="text"
                                                                    value={
                                                                        value ||
                                                                        "-"
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <Form.Control
                                                            disabled
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                            type="text"
                                                            value={`S$ ${commaSeparateNumber(
                                                                roundToTwoDecimal(
                                                                    data
                                                                        ?.final_total
                                                                        ?.custodial_total
                                                                ).toFixed(2)
                                                            )}`}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col md={4}>
                                                        Less{" "}
                                                        {rates?.less_percent}%
                                                    </Col>
                                                    <Col md={8}>
                                                        <Form.Control
                                                            disabled
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                            type="text"
                                                            value={`S$ ${commaSeparateNumber(
                                                                roundToTwoDecimal(
                                                                    data
                                                                        ?.final_total
                                                                        ?.fsr_less
                                                                ).toFixed(2)
                                                            )}`}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col md={4}>
                                                        Sub total (FSR)
                                                    </Col>
                                                    <Col md={8}>
                                                        <Form.Control
                                                            disabled
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                            type="text"
                                                            value={`S$ ${commaSeparateNumber(
                                                                roundToTwoDecimal(
                                                                    data
                                                                        ?.final_total
                                                                        ?.fsr_sub_total
                                                                ).toFixed(2)
                                                            )}`}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {data?.final_total?.star_rate_total ? (
                                        <Form.Group className="mb-4">
                                            <Row className="mb-1">
                                                <Col md={4}>
                                                    Star rate total
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                        type="text"
                                                        value={`S$ ${commaSeparateNumber(
                                                            roundToTwoDecimal(
                                                                data
                                                                    ?.final_total
                                                                    ?.star_rate_total
                                                            ).toFixed(2)
                                                        )}`}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col md={4}>
                                                    Add {rates?.star_rate}%
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                        type="text"
                                                        value={`S$ ${commaSeparateNumber(
                                                            roundToTwoDecimal(
                                                                data.final_total
                                                                    ?.star_rate_add
                                                            ).toFixed(2)
                                                        )}`}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col md={4}>
                                                    Sub total (Star rate)
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                        type="text"
                                                        value={`S$ ${commaSeparateNumber(
                                                            roundToTwoDecimal(
                                                                data.final_total
                                                                    ?.star_rate_sub_total
                                                            ).toFixed(2)
                                                        )}`}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    ) : (
                                        ""
                                    )}
                                    {data?.final_total ? (
                                        <Form.Group className="mb-1">
                                            <Row className="mb-2">
                                                <Col md={4}>Claim %</Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        type="number"
                                                        max={100}
                                                        onChange={(e) => {
                                                            setResetItemsFlag(
                                                                false
                                                            );

                                                            const max =
                                                                data.remaining_claim_percentage ||
                                                                100;
                                                            var value =
                                                                parseInt(
                                                                    e.target
                                                                        .value,
                                                                    10
                                                                );

                                                            if (value > max)
                                                                value = max;

                                                            computeFinalTotal(
                                                                cloneQuotationItems,
                                                                index,
                                                                value
                                                            );
                                                        }}
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                        disabled={view}
                                                        value={
                                                            data?.claim_percentage ||
                                                            ""
                                                        }
                                                    />

                                                    {!view && (
                                                        <small className="text-warning float-end">
                                                            Remaining claim
                                                            percentage is{" "}
                                                            {data.remaining_claim_percentage ||
                                                                100}
                                                            %
                                                        </small>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col
                                                    md={4}
                                                    className="text-primary"
                                                >
                                                    Sub total
                                                </Col>

                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                        type="text"
                                                        value={`S$ ${commaSeparateNumber(
                                                            roundToTwoDecimal(
                                                                data
                                                                    ?.final_total
                                                                    ?.claim_sub_total
                                                            ).toFixed(2)
                                                        )}`}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-1">
                                                <Col
                                                    md={4}
                                                    className="text-primary"
                                                >
                                                    Add {parseInt(gstConfig)}%
                                                    GST
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                        type="text"
                                                        value={`S$ ${commaSeparateNumber(
                                                            roundToTwoDecimal(
                                                                data
                                                                    ?.final_total
                                                                    ?.claim_gst
                                                            ).toFixed(2)
                                                        )}`}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col
                                                    md={4}
                                                    className="text-primary"
                                                >
                                                    Total
                                                </Col>

                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                        type="text"
                                                        className="currency"
                                                        value={`S$ ${commaSeparateNumber(
                                                            roundToTwoDecimal(
                                                                data
                                                                    ?.final_total
                                                                    ?.claim_total
                                                            ).toFixed(2)
                                                        )}`}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    ) : (
                                        ""
                                    )}
                                </Col>
                            </Row>
                        </React.Fragment>
                    );
                })
            ) : (
                <DynamicTable
                    dataPagination={{
                        data: [],
                        links: "",
                        meta: "",
                    }}
                    columns={tableColumns(0)}
                    className="table-layout-fixed"
                    isExpanded
                    isPaginated={false}
                    isStriped={false}
                />
            )}
            <Row className="justify-content-between">
                <Col md={6}>
                    <Form.Group>
                        <Form.Label
                            htmlFor="remarks"
                            className="font-weight-bold"
                        >
                            Remarks
                        </Form.Label>
                        <Controller
                            control={control}
                            defaultValue=""
                            name="remarks"
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <>
                                    {view && value ? (
                                        <p>{value}</p>
                                    ) : (
                                        <AutoGrowTextarea
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            fieldValue={value}
                                            errors={errors.remarks}
                                        />
                                    )}
                                </>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="remarks"
                            render={({ message }) => errorMessage(message)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} className="mt-4">
                    {finalTotal && (
                        <>
                            <Row className="mb-1">
                                <Col md={4}>Grand sub total</Col>
                                <Col md={8}>
                                    <Form.Control
                                        disabled
                                        style={{
                                            textAlign: "right",
                                        }}
                                        type="text"
                                        value={`S$ ${commaSeparateNumber(
                                            finalTotal?.sub_total_amount
                                        )}`}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col md={4}>Grand total</Col>
                                <Col md={8}>
                                    <Form.Control
                                        disabled
                                        style={{
                                            textAlign: "right",
                                        }}
                                        type="text"
                                        value={`S$ ${commaSeparateNumber(
                                            finalTotal?.total_amount
                                        )}`}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
            </Row>
            <DiscountModal
                modalInfo={showDiscount}
                rates={rates}
                setModalInfo={setShowDiscount}
            />
            <UploadDocumentModal
                modalInfo={showImageUpload}
                setModalInfo={setShowImageUpload}
            />
            <AddNoteModal modalInfo={showNote} setModalInfo={setShowNote} />
            <DeleteModalWorksOrder
                modalInfo={showDelete}
                setModalInfo={setShowDelete}
            />
            <AddHeaderModal
                modalInfo={showHeader}
                setModalInfo={setShowHeader}
            />
            <InsertItemModal
                modalInfo={showInsertItem}
                setModalInfo={setShowInsertItem}
                view={view}
            />
        </>
    );
};

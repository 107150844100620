import React, { useContext } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import { errorMessage } from "utils/utilities";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { LoadingContext } from "App";
import { RerenderContextClaim } from "../claims-list/ClaimsList";
import * as claimsApi from "@api/claimsApi";

export const RejectClaimModal = ({ modalInfo, setModalInfo, view }) => {
    const notyf = useContext(NotyfContext);
    const [rerender, setRerender] = useContext(RerenderContextClaim);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    //
    // States
    //

    const schema = yup.object().shape({
        remarks: yup.string().required("This field is required"),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const closeModal = () => {
        setModalInfo({ open: false });
        reset({});
    };

    const rejectClaim = async (data) => {
        try {
            setIsLoadingActive(true);

            const response = await claimsApi.rejectClaim(modalInfo.id, data);

            if (response.status === 200) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "success",
                    message: "Successfully rejected claim",
                });
                closeModal();
                setRerender(!rerender);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "add" })}
            size="lg"
            centered
        >
            <Modal.Header closeButton>Reject claim</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <p>Are you sure you want to reject this item?</p>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="remarks"
                                    className="font-weight-bold"
                                >
                                    Remarks
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="remarks"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            <AutoGrowTextarea
                                                key={modalInfo.id}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                fieldValue={value}
                                                errors={errors.remarks}
                                            />
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="remarks"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={handleSubmit(rejectClaim)}
                        >
                            Submit
                        </Button>

                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

import api from "../utils/api";

export const getCops = (data) => {
    return api.get("/cops");
};

export const getCop = (id, data) => {
    return api.get(`/cops/${id}`, { params: data });
};

export const updateCop = (id, data) => {
    return api.post(`/cops/${id}`, data);
};

export const submitCop = (id, data) => {
    return api.post(`/cops/${id}/submit`, data);
};

export const getInformation = (id) => {
    return api.get(`/cops/${id}/find`);
};

export const getCopConfig = (data) => {
    return api.get(`/config/cop`, data);
};

import api from "../utils/api";

export const createWso = (data) => {
    return api.post("/work-service-order", data);
};

export const updateWso = (id, data) => {
    return api.post("/work-service-order/" + id, data);
};

export const getWsos = (data) => {
    return api.get("/work-service-order", { params: data });
};

export const getWso = (id) => {
    return api.get("/work-service-order/" + id);
};

export const deleteWso = (id) => {
    return api.delete("/work-service-order/" + id);
};

export const getWsoConfig = (id, data) => {
    return api.get(`/config/wso`, data);
};

export const maWsoApproval = (id, data) => {
    return api.post(`/work-service-order/${id}/ma-approval`, data);
};

export const omWsoApproval = (id, data) => {
    return api.post(`/work-service-order/${id}/final-approvals`, data);
};

export const previewWso = (id, data) => {
    return api.get(`/work-service-order/${id}/preview`, data);
};

export const regenerateReport = (id) => {
    return api.get(`/work-service-order/${id}/report-generation`);
};

export const rejectWso = (id, data) => {
    return api.post(`work-service-order/${id}/reject`, data);
};

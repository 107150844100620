import api from "../utils/api";

export const createUser = (data) => {
    return api.post("/user", data);
};

export const updateUser = (id, data) => {
    return api.put("/user/" + id, data);
};

export const getUsers = (data) => {
    return api.get("/user", { params: data });
};

export const getUser = (id) => {
    return api.get("/user/" + id);
};

export const deleteUser = (id) => {
    return api.delete("/user/" + id);
};

export const userUnreadNotifications = () => {
    return api.get("/notifications/unread/all", {});
};

export const userReadNotification = (id) => {
    return api.get("/notifications/markasread/single/" + id, {});
};

export const getUserConfig = () => {
    return api.get("/config/user", {});
};

export const getSignatureConfig = () => {
    return api.get("/config/signatures", {});
};

export const createUserSignature = (id, data) => {
    return api.post(`/user/create-signature/${id}`, data);
};

export const getStoredSignature = (id, data) => {
    return api.get(`/user/create-signature/${id}`, data);
};

export const changePassword = (id, data) => {
    return api.put(`/user/change-password/${id}`, data);
};

export const getMaUsers = () => {
    return api.get(`/user/ma-users`);
};

export const getInspectionMembers = () => {
    return api.get(`/user/attendance-members`);
};
import React from "react";

import { Badge } from "react-bootstrap";

import { status } from "config";
import { toMonthName, toTitleCase } from "utils/utilities";
import { ActionButtons } from "../components/ActionButtons";

const tableColumnsInspection = [
    {
        Header: "Actions",
        accessor: "actions",
        width: "70px",
        Cell: ({ row }) => {
            return (
                <ActionButtons
                    route={`/inspection-checklist/inspection-servicing/${row.original.id}`}
                />
            );
        },
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
            return (
                <Badge pill bg={status(row.original.status)}>
                    {row.original.status}
                </Badge>
            );
        },
    },
    {
        Header: "School",
        accessor: "school.name",
    },
    {
        Header: "Inspected at",
        Cell: ({ row }) => {
            return row.original.inspected_at || "-";
        },
    },
    {
        Header: "Inspection type",
        Cell: ({ row }) => {
            return row.original.inspection_type || "-";
        },
    },
    {
        Header: "Inspection category",
        Cell: ({ row }) => {
            return row.original.inspection_category || "-";
        },
    },
];

const tableColumnsQuotation = [
    {
        Header: "Actions",
        accessor: "actions",
        width: "70px",
        Cell: ({ row }) => {
            return (
                <ActionButtons
                    route={`/works-order/quotation-list/${row.original.id}`}
                />
            );
        },
    },
    {
        Header: "Reference number",
        accessor: "reference_number",
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
            return (
                <Badge pill bg={status(row.original.status)}>
                    {row.original.status}
                </Badge>
            );
        },
    },
    {
        Header: "Updated on",
        accessor: "status_date",
    },
    {
        Header: "Remarks",
        accessor: "status_remarks",
    },
    {
        Header: "School",
        accessor: "school",
        Cell: ({ row }) => {
            return JSON.parse(row.original.school)?.name;
        },
    },
];

const tableColumnsClaims = [
    {
        Header: "Actions",
        accessor: "actions",
        width: "70px",
        Cell: ({ row }) => {
            return (
                <ActionButtons
                    route={`/works-order/claims/${row.original?.id}`}
                />
            );
        },
    },
    {
        Header: "Reference number",
        accessor: "reference_number",
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
            return (
                <Badge pill bg={status(row.original?.status)}>
                    {row.original?.status}
                </Badge>
            );
        },
    },
    {
        Header: "Updated on",
        accessor: "status_date",
    },
    {
        Header: "Fund type",
        accessor: "source_fund",
        Cell: ({ row }) => {
            if (!row.original?.source_fund) return "-";

            return row.original?.source_fund === "SCHOOL"
                ? toTitleCase(row.original?.source_fund)
                : row.original?.source_fund;
        },
    },
    {
        Header: "Remarks",
        accessor: "status_remarks",
    },
    {
        Header: "School",
        accessor: "school",
        Cell: ({ row }) => {
            return row.original?.school
                ? JSON.parse(row.original?.school)?.name
                : "-";
        },
    },
];

const tableColumnsWorksOrder = [
    {
        Header: "Actions",
        accessor: "actions",
        width: "70px",
        Cell: ({ row }) => {
            return (
                <ActionButtons route={`/works-order/wso/${row.original.id}`} />
            );
        },
    },
    {
        Header: () => <div className="text-end">WSO reference number</div>,
        accessor: "reference_number",
        width: "200px",
        Cell: ({ row }) => {
            return (
                <p className="text-end">
                    {row.original?.reference_number || "-"}
                </p>
            );
        },
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
            return (
                <Badge pill bg={status(row.original.status)}>
                    {row.original.status}
                </Badge>
            );
        },
    },
    {
        Header: () => <div className="text-end">Updated on</div>,
        accessor: "status_date",
        Cell: ({ row }) => {
            return (
                <p className="text-end">{row.original?.status_date || "-"}</p>
            );
        },
    },
    {
        Header: () => <div className="text-end">Remarks</div>,
        accessor: "status_remarks",
        Cell: ({ row }) => {
            return (
                <p className="text-end">
                    {row.original?.status_remarks || "-"}
                </p>
            );
        },
    },
    {
        Header: "School",
        accessor: "school_name",
        width: "220px",
        Cell: ({ row }) => {
            return <p>{row.original?.school_name || "-"}</p>;
        },
    },
    {
        Header: () => (
            <div className="text-end">Quotation reference number</div>
        ),
        accessor: "quotation_reference_number",
        width: "230px",
        Cell: ({ row }) => {
            return (
                <p className="text-end">
                    {row.original?.quotation_reference_number ? (
                        <a
                            href={`${window.location.origin}/works-order/quotation-list/${row.original?.quotation_id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="btn-link pe-1"
                            style={{
                                color: "#3F80EA",
                            }}
                        >
                            {row.original?.quotation_reference_number}
                        </a>
                    ) : (
                        "-"
                    )}
                </p>
            );
        },
    },
    {
        Header: "Category",
        accessor: "category",
    },
];

const tableColumnsDelivarables = [
    {
        Header: "Actions",
        accessor: "actions",
        width: "70px",
        Cell: ({ row }) => {
            let path = "";

            if (row?.original?.category.toLowerCase() === "bm") {
                path = "building-maintenance";
            } else {
                path = "turf-and-plant";
            }

            return (
                <ActionButtons
                    route={`/deliverables/${path}/${row?.original?.id}`}
                />
            );
        },
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
            return (
                <Badge pill bg={status(row.original.status)}>
                    {row.original.status}
                </Badge>
            );
        },
    },
    {
        Header: "Zone",
        accessor: "zone",
    },
    {
        Header: "Title",
        accessor: "category",
        Cell: ({ row }) => {
            let title = "";

            if (row?.original?.category.toLowerCase() === "bm") {
                title = "Building maintenance";
            } else {
                title = "Turf and plant";
            }

            return title;
        },
    },
    {
        Header: "Date",
        accessor: "year",
        Cell: ({ row }) => {
            return `${row.original.year}-${toMonthName(row.original.month)}`;
        },
    },
];

export {
    tableColumnsInspection,
    tableColumnsQuotation,
    tableColumnsClaims,
    tableColumnsWorksOrder,
    tableColumnsDelivarables,
};

import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import Select from "react-select";

import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import * as treeChecklistApi from "@api/treeChecklistApi";
import { InspectSchoolModal } from "../inspection-checklist/components/InspectSchoolModal";
import * as inspectionTreeChecklistApi from "@api/inspectionTreeChecklistApi";
import { useNavigate } from "react-router-dom";
import { formatConfigFields } from "utils/utilities";
import * as schoolApi from "@api/schoolApi";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { LoadingContext } from "App";
import { tree_checklist_category } from "./checklist-config";

export const RerenderContextDocument = createContext();

export const TreeChecklist = () => {
    const notyf = useContext(NotyfContext);
    const navigate = useNavigate();
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    //
    // States
    //

    const [checklists, setChecklists] = useState([]);
    const [schools, setSchools] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [modalInfo, setModalInfo] = useState({
        data: null,
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [configColumn, setFieldColumn] = useState([]);
    const [filter, setFilter] = useState({});
    const { control, handleSubmit } = useForm({});
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    let isDesktop = width > 1400;

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: ({ row }) => (
            <>
                <div>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Create inspection</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                className="align-middle me-1"
                                onClick={() =>
                                    openSelectSchoolModal(row.original)
                                }
                                icon={faPlus}
                                size="lg"
                            />
                        </span>
                    </OverlayTrigger>
                </div>
            </>
        ),
    };

    //
    // Functions
    //

    const getChecklists = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await treeChecklistApi.getTreeChecklist({
                    ...param,
                    ...filter,
                });
                setChecklists(response.data);
                setIsLoadingActive(false);
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        // eslint-disable-next-line
        [notyf]
    );

    const openSelectSchoolModal = async (originalData) => {
        try {
            const response = await schoolApi.getSchools();

            if (response.status === 200) {
                const schools = response.data.data;

                // let schoolGroupBychecklistType = {};

                // schoolGroupBychecklistType = schools
                //     ?.filter(
                //         (school) =>
                //             school?.checklist_type === originalData?.status
                //     )
                //     .map((school) => school);

                setSchools(schools);

                const schoolsMapped = schools.map((field, index) => {
                    return { label: field.name, value: field.slug };
                });

                setModalInfo({
                    schools: schoolsMapped,
                    data: originalData,
                    notifMsg: "Select school to inspect",
                    open: true,
                    severity: "success",
                });
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const createInspectionApi = async (data) => {
        try {
            const response =
                await inspectionTreeChecklistApi.createInspectionTreeChecklist(
                    data
                );

            if (response.status === 201) {
                notyf.open({
                    type: "success",
                    message: response.data.message,
                });
                setRerender(!rerender);
                navigate(
                    `/inspection-checklist/tree-inspections/${response.data.data.id}`
                );
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response =
                    await inspectionTreeChecklistApi.getChecklistTreeConfig(
                        param
                    );
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            if (response.status === 200) {
                const data = response.data.data;
                let temp = {};
                temp = data.map((school) => {
                    return {
                        value: school?.id,
                        label: school?.name,
                    };
                });
                setSchools(temp);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const searchInspection = useCallback(async (data) => {
        const temp = {
            category: data?.category.value,
            name: data?.name,
            school: data?.school?.value,
        };

        setFilter(temp);
        getChecklists(temp);
        // eslint-disable-next-line
    }, []);

    //
    // UseEffects
    //

    useEffect(() => {
        getChecklists();
        getSchools();
        getConfig();
    }, [rerender, getChecklists, getConfig, getSchools]);

    return (
        <>
            <RerenderContextDocument.Provider value={[setRerender, rerender]}>
                <h4 className="h4 mb-3">Tree checklists</h4>
                <Helmet title="Tree checklists" />
                <Card className="p-0">
                    <Card.Header className="pb-2">
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col md={3} sm={12} xs={12}>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="category"
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <Select
                                                            className={`${
                                                                isMobile &&
                                                                "mt-2"
                                                            }`}
                                                            classNamePrefix="react-select "
                                                            options={
                                                                tree_checklist_category
                                                            }
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            onChange={onChange}
                                                            placeholder="Select category"
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col md={3} sm={12} xs={12}>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="name"
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                            ref,
                                                        },
                                                    }) => (
                                                        <Form.Control
                                                            type="text"
                                                            className={`d-inline-block form-box ${
                                                                isMobile &&
                                                                "w-100 mt-2"
                                                            }`}
                                                            placeholder="Search by name"
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col md={3} sm={12} xs={12}>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="school"
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,

                                                            ref,
                                                        },
                                                    }) => (
                                                        <Select
                                                            inputRef={ref}
                                                            className={`${
                                                                isMobile &&
                                                                "w-100 mt-2"
                                                            }`}
                                                            classNamePrefix="react-select "
                                                            options={schools}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            value={value}
                                                            onChange={onChange}
                                                            placeholder="Select school"
                                                        />
                                                    )}
                                                />
                                            </Col>

                                            <Col md={3} sm={12} xs={12}>
                                                <Button
                                                    variant="primary"
                                                    className={`me-1 mb-1 form-box ${
                                                        isMobile && "w-100 mt-2"
                                                    }`}
                                                    onClick={handleSubmit(
                                                        searchInspection
                                                    )}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-2">
                        {!isLoadingActive &&
                            configColumn &&
                            checklists?.data && (
                                <DynamicTable
                                    dataPagination={checklists}
                                    columns={formatConfigFields(
                                        configColumn,
                                        actionField
                                    )}
                                    className="table-layout-fixed"
                                    api={getChecklists}
                                    searchValue={filter}
                                />
                            )}
                    </Card.Body>
                </Card>
                <InspectSchoolModal
                    api={createInspectionApi}
                    modalInfo={modalInfo}
                    setModalInfo={setModalInfo}
                    type={`tree-checklist`}
                />
            </RerenderContextDocument.Provider>
        </>
    );
};

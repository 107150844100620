import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DynamicTable from "components/ui/DynamicTable";
import * as documentApi from "@api/documentApi";
import NotyfContext from "contexts/NotyfContext";
import { LoadingContext } from "App";
import { formatConfigFields } from "utils/utilities";
import { DeleteModal } from "components/DeleteModal";
import {
    faPen,
    faPlus,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RerenderContextDocument = createContext();

export const DocumentRepository = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const [width] = useState(window.innerWidth);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    //
    // States
    //

    const [documents, setDocuments] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [searchValue, setSearchValue] = useState({
        name: "",
    });
    const [configColumn, setFieldColumn] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    let isDesktop = width > 1400;

    const actionField = {
        Header: "Actions",
        width: isDesktop ? 70 : 120,
        Cell: ({ row }) => {
            return (
                <>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Edit repository</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faPen}
                                className="align-middle me-1"
                                onClick={() =>
                                    navigate(
                                        `/document-repository/${row.original.id}`
                                    )
                                }
                                size="lg"
                            />
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Delete repository</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faTrash}
                                className="align-middle me-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => openDeleteModal(row.original.id)}
                                size="lg"
                            />
                        </span>
                    </OverlayTrigger>
                </>
            );
        },
    };

    //
    // Functions
    //

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    const getDocumentRepositories = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await documentApi.getDocuments(param);

                setDocuments(response.data);
                setIsLoadingActive(false);
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await documentApi.getDocumentsConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const search = () => {
        getDocumentRepositories(searchValue);
    };

    const deleteApi = async (id) => {
        try {
            const response = await documentApi.deleteDocument(id);
            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: response.data.message,
                });
                setRerender(!rerender);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getDocumentRepositories();
        getConfig();
    }, [rerender, getDocumentRepositories, getConfig]);

    return (
        <>
            <RerenderContextDocument.Provider value={[setRerender, rerender]}>
                <h4 className="tab-title mb-3">Document repository</h4>
                <Helmet title="Document respository" />
                <Card className="p-0">
                    <Card.Header>
                        <Row>
                            <Col md={10} sm={10} xs={10}>
                                <Row>
                                    <Col md={3} sm={5} xs={5}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block form-box"
                                            placeholder="Search"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={2} xs={2}>
                                        <Button
                                            variant="primary"
                                            className="me-1 mb-1 form-box"
                                            onClick={() => search()}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <h1 className="h3 mb-3">
                                    <Button
                                        variant="primary"
                                        className={`${
                                            width >= 768 && "m-1"
                                        } float-end mb-2 form-box`}
                                        onClick={() =>
                                            navigate(
                                                `/document-repository/create`
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            className="feather"
                                        />
                                    </Button>
                                </h1>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && documents.data && (
                            <DynamicTable
                                dataPagination={documents}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                                api={getDocumentRepositories}
                            />
                        )}
                    </Card.Body>
                </Card>
            </RerenderContextDocument.Provider>

            <DeleteModal
                api={deleteApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
        </>
    );
};

import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Col, Form, Pagination, Row, Table } from "react-bootstrap";
import {
    useGlobalFilter,
    useTable,
    usePagination,
    useRowSelect,
    useExpanded,
    useMountedLayoutEffect,
} from "react-table";

const DynamicTable = (props) => {
    const {
        columns,
        dataPagination,
        api,
        className,
        withCheckbox,
        hiddenColumns,
        singleSelectCheckbox,
        setSelectedRows = false,
        isPaginated = true,
        isStriped = true,
        isExpanded = false,
        updateData = null,
        updateSort = null,
        canViewRow = null,
        canSignRow = null,
        defaultSelectedRows,
        getRowId,
        pageSize = 50,
        searchValue = false,
        searchGlobal = false,
        onChangeSelectedRowsId = false,
        hasTopHorizontalScroll = false,
    } = props;

    const { data, meta, links } = dataPagination;
    const tableCloneRef = useRef(null);
    const tableOriginalRef = useRef(null);

    const IndeterminateCheckbox = forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = useRef();
            const resolvedRef = ref || defaultRef;

            useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            return (
                <>
                    <Form.Check type="checkbox" ref={resolvedRef} {...rest} />
                </>
            );
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        toggleAllRowsExpanded,
        isAllRowsExpanded,
        selectedFlatRows,
        setGlobalFilter,
        state: {
            pageIndex,
            apiNextPage,
            apiPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
            selectedRowIds,
        },
    } = useTable(
        {
            columns: columns,
            data: data,
            getRowId,
            initialState: {
                pageIndex: 0,
                hiddenColumns: hiddenColumns ? hiddenColumns : [],
                autoResetExpanded: false,
                apiNextPage: links?.next !== null ? true : false,
                apiPreviousPage: links?.prev !== null ? true : false,
                pageSize: meta?.total ? meta?.total : pageSize,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
                selectedRowIds: defaultSelectedRows || {},
            },
            stateReducer: singleSelectCheckbox
                ? (newState, action) => {
                      if (action.type === "toggleRowSelected") {
                          newState.selectedRowIds = {
                              [action.id]: true,
                          };
                      }

                      return newState;
                  }
                : () => {},
            updateData: updateData,
            updateSort: updateSort,
            canViewRow: canViewRow,
            canSignRow: canSignRow,
        },
        useGlobalFilter,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            if (withCheckbox && data && data.length > 0) {
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllPageRowsSelectedProps }) =>
                            !singleSelectCheckbox && (
                                <div>
                                    <IndeterminateCheckbox
                                        {...getToggleAllPageRowsSelectedProps()}
                                    />
                                </div>
                            ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => {
                            return (
                                row.depth === 0 && (
                                    <div>
                                        <IndeterminateCheckbox
                                            {...row.getToggleRowSelectedProps()}
                                        />
                                    </div>
                                )
                            );
                        },
                        width: 40,
                    },
                    ...columns,
                ]);
            }
        }
    );

    const gotoPageApi = (page) => {
        if (searchValue) {
            api({
                ...searchValue,
                page,
            });
        } else {
            api({
                page,
            });
        }
    };

    const canPaginateApi = api && totalPage > 1;
    const canPaginateNoApi = !api && pageOptions.length > 1;

    useEffect(() => {
        if (isExpanded) toggleAllRowsExpanded(true);
    }, [isAllRowsExpanded, isExpanded, toggleAllRowsExpanded]);

    useEffect(() => {
        if (setSelectedRows) {
            const mappedSelectedFlatRows = selectedFlatRows.map(
                (row) => row.original
            );

            setSelectedRows(JSON.stringify(mappedSelectedFlatRows));
        }
    }, [setSelectedRows, selectedFlatRows]);

    useMountedLayoutEffect(() => {
        onChangeSelectedRowsId && onChangeSelectedRowsId(selectedRowIds);
    }, [selectedRowIds]);

    useEffect(() => {
        onChangeSelectedRowsId && setGlobalFilter(searchGlobal || "");
    }, [searchGlobal]);

    // Sync clone table and original table's horizontal scroll
    const handleTableScroll = (source, target) => {
        // Target sub class
        const sourceTable = document.querySelector(`.${source}`);
        const targetTable = document.querySelector(`.${target}`);

        // Target sub class' parent
        // Cause parentNode will have the scroll functionality
        const sourceParent = sourceTable?.parentNode;
        const targetParent = targetTable?.parentNode;

        if (sourceParent && targetParent) {
            // Add scroll listener to targetParent to sync with source
            targetParent.addEventListener("scroll", () => {
                sourceParent.scrollLeft = targetParent.scrollLeft;
            });
        }
    };

    // Syncing for top scroll
    useEffect(() => {
        hasTopHorizontalScroll &&
            handleTableScroll("tableOriginal", "tableClone");
    }, []);

    // Syncing for bottom scroll
    useEffect(() => {
        hasTopHorizontalScroll &&
            handleTableScroll("tableClone", "tableOriginal");
    }, []);

    return (
        <>
            {/* Do note this is a clone table only to achieve top horizontal scroll  */}
            {hasTopHorizontalScroll && (
                <Table
                    ref={tableCloneRef}
                    responsive
                    className={`${className} tableClone`}
                    {...getTableProps()}
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, key) => (
                                    <th width={column.width} key={key}></th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                </Table>
            )}

            {/* Original table */}
            <Table
                ref={tableOriginalRef}
                striped={isStriped}
                bordered
                responsive
                {...getTableProps()}
                className={`${className} tableOriginal`}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    width={column.width}
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {((data && data.length < 1) || !page.length) && (
                        <tr>
                            <td
                                colSpan={
                                    withCheckbox
                                        ? columns.length + 1
                                        : columns.length
                                }
                            >
                                No records found
                            </td>
                        </tr>
                    )}
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={i}>
                                {row.original.header ? (
                                    <tr>
                                        <td
                                            colSpan={columns.length}
                                            className="header"
                                        >
                                            {row.original.header}
                                        </td>
                                    </tr>
                                ) : (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    className={
                                                        cell.column.id ===
                                                        "actions"
                                                            ? "table-action"
                                                            : ""
                                                    }
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
            {isPaginated && (
                <Row className="mt-2">
                    <Col md="6">
                        <span className="me-2">
                            Page{" "}
                            <strong>
                                {api && `${currentPage} of ${totalPage}`}
                                {!api &&
                                    `${pageIndex + 1} of ${pageOptions.length}`}
                            </strong>
                        </span>
                        <span className="me-2">
                            | Total items: <strong>{meta?.total}</strong>
                        </span>
                        {(canPaginateApi || canPaginateNoApi) && (
                            <>
                                <span className="me-2">
                                    | Go to page:{" "}
                                    <input
                                        className="form-control"
                                        type="number"
                                        defaultValue={
                                            api ? currentPage : pageIndex + 1
                                        }
                                        onKeyDown={(event) => {
                                            const value = parseInt(
                                                event.target.value
                                            );
                                            const totalPageCondition = api
                                                ? totalPage
                                                : pageOptions.length;

                                            if (
                                                event.key === "Enter" &&
                                                value <= totalPageCondition
                                            ) {
                                                api
                                                    ? gotoPageApi(value)
                                                    : gotoPage(value - 1);
                                            }
                                        }}
                                        style={{
                                            width: "100px",
                                            display: "inline",
                                        }}
                                    />
                                </span>
                            </>
                        )}
                    </Col>

                    <Col md="6">
                        <Pagination className="float-end">
                            {canPaginateNoApi && (
                                <>
                                    <Pagination.First
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    />
                                </>
                            )}

                            {canPaginateApi && (
                                <>
                                    <Pagination.First
                                        onClick={() => gotoPageApi(firstPage)}
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPageApi(currentPage - 1)
                                        }
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPageApi(currentPage + 1)
                                        }
                                        disabled={!apiNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPageApi(lastPage)}
                                        disabled={!apiNextPage}
                                    />
                                </>
                            )}
                        </Pagination>
                    </Col>
                </Row>
            )}
        </>
    );
};
export default DynamicTable;

import React, { useContext, useState } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import NotyfContext from "contexts/NotyfContext";
import { InspectSchoolModal } from "../../inspection-checklist/components/InspectSchoolModal";
import * as inspectionChecklistApi from "@api/inspectionChecklistApi";
import * as schoolApi from "@api/schoolApi";
import * as usersApi from "@api/usersApi";
import { RerenderContextDocument } from "../BMTPChecklist";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ChecklistButtons = ({ row }) => {
    const [schools, setSchools] = useState([]);
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const [setRerender, rerender] = useContext(RerenderContextDocument);

    //
    // States
    //

    const [modalInfo, setModalInfo] = useState({
        data: null,
        notifMsg: "",
        open: false,
        severity: "success",
    });

    //
    // Functions
    //

    const openSelectSchoolModal = async (originalData) => {
        try {
            const memberResp = await usersApi.getInspectionMembers();

            const response = await schoolApi.getSchools();

            if (response.status === 200) {
                const schools = response.data.data;
                setSchools(schools);

                const schoolsMapped = schools.map((field, index) => {
                    return { label: field.name, value: field.slug };
                });

                setModalInfo({
                    schools: schoolsMapped,
                    technicianOptions: memberResp?.data?.data,
                    data: originalData,
                    notifMsg: "Select school to inspect",
                    open: true,
                    severity: "success",
                    notyf: notyf,
                });
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const createInspectionApi = async (data) => {
        let school = schools.find(function (el, i) {
            return el.slug === data.school_slug;
        });

        data["school_id"] = school.id;
        data["school_zone"] = school.zone;
        data["school_name"] = school.name;

        try {
            const response =
                await inspectionChecklistApi.createInspectionChecklist(data);

            if (response.status === 201) {
                notyf.open({
                    type: "success",
                    message: response.data.message,
                });

                setRerender(!rerender);
                navigate(
                    `/inspection-checklist/inspection-servicing/${response.data.data.id}`
                );
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Create inspection</Tooltip>}
            >
                <span>
                    <FontAwesomeIcon
                        icon={faPlus}
                        size="lg"
                        className="align-middle me-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => openSelectSchoolModal(row.original)}
                    />
                </span>
            </OverlayTrigger>

            {/*<OverlayTrigger
                placement="top"
                overlay={<Tooltip>View checklist</Tooltip>}
            >
                <Eye
                    className="align-middle"
                    size={16}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/checklist/${row.original.id}`)}
                />
    </OverlayTrigger>*/}

            {/* Modal Components */}

            <InspectSchoolModal
                api={createInspectionApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                type={`bmtp-checklist`}
            />
        </>
    );
};

import React, { useContext, useState } from "react";

import NotyfContext from "contexts/NotyfContext";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";

import { roundToTwoDecimal } from "utils/utilities";
import * as fsrsApi from "@api/fsrsApi";

export const ReplaceItemModal = ({ modalInfo, setModalInfo, view }) => {
    //
    // States
    //

    const [hasMore, setHasMore] = useState(true);
    const [option, setOption] = useState(true);
    const notyf = useContext(NotyfContext);

    //
    // Functions
    //

    const closeModal = () => {
        setModalInfo({ open: false });
    };

    const saveModal = () => {
        if (!option?.value) {
            notyf.open({
                type: "warning",
                message: "Please select an FSR item to proceed",
            });
            return;
        }

        let optionsClone = { ...option?.value };

        optionsClone["rate"] = optionsClone["rate"]?.replace(/,/g, "");
        optionsClone["amount"] = parseFloat(
            roundToTwoDecimal(optionsClone.rate * optionsClone.quantity)
        ).toFixed(2);
        optionsClone["serial_no"] = modalInfo?.row?.original?.serial_no;
        optionsClone["minimum"] = false;
        delete optionsClone["hasHeader"];

        setModalInfo({
            open: false,
            newValue: optionsClone,
            row: modalInfo?.row,
        });
    };

    const handleLoadMore = async (search, { page }) => {
        const response = await fsrsApi.getFsrOptions({
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response.data;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const onChangeSelected = (selectedRows) => {
        setOption(selectedRows);
    };

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "add" })}
            size="lg"
            centered
        >
            <Modal.Header closeButton>Replace FSR item</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        {!view && (
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="fsr">
                                        FSR items
                                    </Form.Label>
                                    <AsyncPaginate
                                        isClearable
                                        loadOptions={handleLoadMore}
                                        hasMore={hasMore}
                                        additional={{
                                            page: 1,
                                        }}
                                        onChange={(value) =>
                                            onChangeSelected(value)
                                        }
                                        menuPosition="fixed"
                                    />
                                </Form.Group>
                            </Col>
                        )}
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        {!view && (
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={() => saveModal()}
                            >
                                Submit
                            </Button>
                        )}
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import { formatConfigFields } from "utils/utilities";
import { LoadingContext } from "App";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fsrsApi from "@api/fsrsApi";

export const FsrList = () => {
    const notyf = useContext(NotyfContext);
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    const navigate = useNavigate();
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    //
    // States
    //

    const [fsrs, setFsrs] = useState([]);
    const [searchValue, setSearchValue] = useState({
        item_number: "",
        name: "",
        is_star_rate: false,
        all: 1,
    });
    const [configColumn, setFieldColumn] = useState([]);
    let isDesktop = width > 1400;

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: (cell) => (
            <div>
                {/* <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit user</Tooltip>}
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faPen}
                            className="align-middle me-1"
                            onClick={() =>
                                navigate(
                                    location.pathname +
                                        "/" +
                                        cell.row.original.id
                                )
                            }
                            size="lg"
                        />
                    </span>
                </OverlayTrigger> */}
            </div>
        ),
    };

    //
    // Functions
    //

    const getFsrs = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                param = { ...searchValue, ...param };
                const response = await fsrsApi.getFsrs(param);

                if (response.status === 200) {
                    setFsrs(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
                setIsLoadingActive(false);
            }
        },
        // eslint-disable-next-line
        [notyf, setIsLoadingActive]
    );

    const search = () => {
        getFsrs(searchValue);
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await fsrsApi.getFsrConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    //
    // UseEffects
    //

    useEffect(() => {
        getFsrs();
        getConfig();
    }, [getFsrs, getConfig]);

    return (
        <React.Fragment>
            <Helmet title="FSR" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">FSR</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={10}>
                                <Row>
                                    <Col md={3} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className={`d-inline-block form-box ${
                                                isMobile && "w-100"
                                            }`}
                                            placeholder="Category"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    category: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className={`d-inline-block form-box ${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            placeholder="Item number"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    item_number: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className={`d-inline-block form-box ${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            placeholder="Name"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={2} sm={12} xs={12}>
                                        <Form.Check
                                            className={`form-box ${
                                                isMobile && "w-100 mt-2 ms-3"
                                            }`}
                                            label="Is star rate"
                                            type="checkbox"
                                            onChange={(e) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    is_star_rate:
                                                        !searchValue.is_star_rate,
                                                });
                                            }}
                                        />
                                    </Col>
                                    <Col md={1} sm={12} xs={12}>
                                        <Button
                                            variant="primary"
                                            className={`me-1 mb-1 form-box ${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            onClick={search}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} sm={12} xs={12}>
                                <h1 className="h3 mb-3">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Create Fsr</Tooltip>}
                                    >
                                        <Button
                                            variant="primary"
                                            className={`float-end mb-2 form-box ${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            onClick={() =>
                                                navigate(`/works-order/fsr/add`)
                                            }
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </OverlayTrigger>
                                </h1>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && (
                            <DynamicTable
                                dataPagination={{
                                    data: fsrs?.result || [],
                                    meta: "",
                                    links: "",
                                }}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const ActionButtons = ({ route }) => {
    const navigate = useNavigate();

    return (
        <OverlayTrigger placement="top" overlay={<Tooltip>Review</Tooltip>}>
            <span>
                <FontAwesomeIcon
                    icon={faPenAlt}
                    className="align-middle me-1"
                    onClick={() => navigate(route)}
                    size="lg"
                />
            </span>
        </OverlayTrigger>
    );
};

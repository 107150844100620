import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "./axios";
import CookieService from "service/CookieService";

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   expiredTimer = window.setTimeout(() => {
//   }, timeLeft);
// };

const setSession = (accessToken) => {
    const expiration = 10800; // 3 hours

    if (accessToken) {
        CookieService.set("authToken", accessToken, expiration);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        // This function below will handle when token is expired
        // const { exp } = jwtDecode(accessToken);
        // handleTokenExpired(exp);
    } else {
        CookieService.remove("accessToken");
        delete axios.defaults.headers.common.Authorization;
    }
};

const setUID = (userId) => {
    if (userId) {
        CookieService.set("uid", userId);
    } else {
        CookieService.remove("uid");
    }
};

const setLoginId = (loginId) => {
    if (loginId) {
        CookieService.set("loginId", loginId);
    } else {
        CookieService.remove("loginId");
    }
};

export { verify, sign, isValidToken, setSession, setUID, setLoginId };

import React from "react";
import DeliverableForm from "./DeliverableForm";
// import {
//     Button,
//     Card,
//     Col,
//     Form,
//     InputGroup,
//     OverlayTrigger,
//     Row,
//     Tooltip,
// } from "react-bootstrap";
// import { Helmet } from "react-helmet-async";
// import { Controller, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
// import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
// import { ErrorMessage } from "@hookform/error-message";
// import * as deliverablesApi from "../../api/deliverablesApi";
// import NotyfContext from "@contexts/NotyfContext";
// import { useNavigate, useParams } from "react-router-dom";
// import {
//     formatFileName,
//     scrollToTop,
//     toTitleCase,
// } from "../../utils/utilities";
// import { useEffect } from "react";
// import { useCallback } from "react";
// import { toMMMMY } from "../../utils/dateHelper";
// import { default_tp_report } from "./deliverable-config";
// import { deleteAttachment } from "../../api/attachmentApi";
// import { DeleteModal } from "../../components/DeleteModal";
// import { SignatureForm } from "./SignatureForm";
// import useAuth from "../../hooks/useAuth";
// import Select from "react-select";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//     faDownload,
//     faFile,
//     faPlus,
//     faTrash,
// } from "@fortawesome/free-solid-svg-icons";

// const schema = yup.object().shape({
//     zone: yup.mixed().required("This field is required"),
//     month_year: yup.mixed().required("This field is required").nullable(),
// });

export const TurfPlant = () => {
    return <DeliverableForm category="TP" />
//     const { user } = useAuth();
//     const notyf = useContext(NotyfContext);
//     const navigate = useNavigate();
//     const { id } = useParams();
//     const create = id ? false : true;
//     const [loading, setLoading] = useState(false);
//     const [attachments, setAttachments] = useState(default_tp_report);
//     const [otherAttachments, setOtherAttachments] = useState([]);
//     const [customCount, setCustomCount] = useState(0);
//     const [deliverable, setDeliverable] = useState({});
//     const [preparedBySignature, setPreparedBySignature] = useState({});
//     const [certifiedBySignature, setCertifiedBySignature] = useState({});
//     const [disableField, setDisableField] = useState(false);
//     const [modalInfo, setModalInfo] = useState({
//         key: null,
//         id: null,
//         notifMsg: "",
//         open: false,
//         severity: "danger",
//     });
//     let incompleteAttachment = false;

//     const {
//         control,
//         formState: { errors },
//         handleSubmit,
//         reset,
//         setError,
//     } = useForm({
//         mode: "onTouched",
//         resolver: yupResolver(schema),
//     });

//     const createDeliverable = async (data, status) => {
//         if (status === "SUBMITTED") {
//             validateAttachment();
//         }

//         if (incompleteAttachment) {
//             scrollToTop();
//             notyf.open({
//                 type: "danger",
//                 message: "Incomplete attachment",
//             });
//             return;
//         }

//         setLoading(true);
//         const month_year = data.month_year.toLocaleDateString().split("/");
//         const formData = new FormData();
//         formData.append("zone", data.zone);
//         formData.append("month", month_year[0]);
//         formData.append("year", month_year[2]);
//         formData.append("status", status);
//         formData.append("category", "TP");

//         if (status === "DRAFT" || status === "SUBMITTED") {
//             formData.append("name", data.prepared_by.name);
//             formData.append("designation", data.prepared_by.designation);
//             formData.append("mobile_no", data.prepared_by.number);
//             formData.append("email", data.prepared_by.email);
//             formData.append("signature", preparedBySignature);
//         }

//         if (status === "APPROVED" || status === "REJECTED") {
//             formData.append("name", data.certified_by.name);
//             formData.append("designation", data.certified_by.designation);
//             formData.append("mobile_no", data.certified_by.number);
//             formData.append("email", data.certified_by.email);
//             formData.append("signature", certifiedBySignature);
//         }

//         attachments.forEach((item) => {
//             let files = item.upload_data.getFilesData();
//             if (files.length > 0) {
//                 files.forEach((file) => {
//                     formData.append(item.key + "[]", file.rawFile);
//                 });
//             }
//         });

//         otherAttachments.forEach((item) => {
//             let files = item.upload_data.getFilesData();
//             if (files.length > 0) {
//                 files.forEach((file) => {
//                     formData.append(
//                         "other_documents[" + item.key + "]",
//                         file.rawFile
//                     );
//                 });
//                 formData.append(
//                     "other_remarks[" + item.key + "]",
//                     item.remarks
//                 );
//             }
//         });

//         try {
//             const response = await deliverablesApi.createDeliverable(formData);
//             setLoading(false);
//             let action = status === "DRAFT" ? "saved" : "submitted";
//             if (response.data.status === "SUCCESS") {
//                 notyf.open({
//                     type: "success",
//                     message: "Deliverables has been " + action,
//                 });
//                 scrollToTop();
//                 navigate("/deliverables/turf-plant/" + response.data.data.id);
//             }
//         } catch (error) {
//             setLoading(false);
//             notyf.open({
//                 type: "danger",
//                 message:
//                     error.response.status + " " + error.response.statusText,
//             });
//         }
//     };

//     const updateDeliverable = async (data, status) => {
//         if (status === "SUBMITTED") {
//             validateAttachment();
//         }
//         //return
//         if (incompleteAttachment) {
//             scrollToTop();
//             notyf.open({
//                 type: "danger",
//                 message: "Incomplete attachment",
//             });
//             return;
//         }

//         setLoading(true);
//         //const month_year = data.month_year?.toLocaleDateString().split('/')
//         const formData = new FormData();
//         formData.append("zone", data.zone.value);
//         formData.append("month", deliverable.month);
//         formData.append("year", deliverable.year);
//         formData.append("status", status);

//         if (status === "DRAFT" || status === "SUBMITTED") {
//             formData.append("name", data.prepared_by.name);
//             formData.append("designation", data.prepared_by.designation);
//             formData.append("mobile_no", data.prepared_by.number);
//             formData.append("email", data.prepared_by.email);
//             formData.append("signature", preparedBySignature);
//         }

//         if (status === "APPROVED" || status === "REJECTED") {
//             formData.append("name", data.certified_by.name);
//             formData.append("designation", data.certified_by.designation);
//             formData.append("mobile_no", data.certified_by.number);
//             formData.append("email", data.certified_by.email);
//             formData.append("signature", certifiedBySignature);
//             formData.append("reject_remarks", data.reject_remarks);
//         }

//         if (status === "DRAFT" || status === "SUBMITTED") {
//             attachments.forEach((item) => {
//                 let files = item.upload_data.getFilesData();
//                 if (files.length > 0) {
//                     files.forEach((file) => {
//                         formData.append(item.key + "[]", file.rawFile);
//                     });
//                 }
//             });

//             otherAttachments.forEach((item) => {
//                 let files = item.upload_data.getFilesData();
//                 if (files.length > 0) {
//                     files.forEach((file) => {
//                         formData.append(
//                             "other_documents[" + item.key + "]",
//                             file.rawFile
//                         );
//                     });
//                     formData.append(
//                         "other_remarks[" + item.key + "]",
//                         item.remarks
//                     );
//                 }
//             });
//         }

//         try {
//             const response = await deliverablesApi.updateDeliverable(
//                 formData,
//                 id
//             );
//             setLoading(false);
//             if (response.data.status === "SUCCESS") {
//                 notyf.open({
//                     type: "success",
//                     message: "Deliverables has been updated.",
//                 });
//                 scrollToTop();
//                 //navigate("/deliverables/turf-plant/"+ id);
//                 window.location.reload();
//                 //getDeliverables()
//             }
//         } catch (error) {
//             setLoading(false);
//             notyf.open({
//                 type: "danger",
//                 message:
//                     error.response.status + " " + error.response.statusText,
//             });
//         }
//     };

//     const validateAttachment = () => {
//         attachments.forEach((item) => {
//             if (item.required) {
//                 let files = item.upload_data.getFilesData().length;
//                 let attachments = item.attachments.length;

//                 if (create) {
//                     item["file_count"] = files;
//                     if (files < 1) {
//                         incompleteAttachment = true;
//                     } else {
//                         incompleteAttachment = false;
//                     }
//                 } else {
//                     item["file_count"] = files + attachments;

//                     if (attachments < 1 && files < 1) {
//                         incompleteAttachment = true;
//                     }

//                     if (attachments > 1 && files < 1) {
//                         incompleteAttachment = false;
//                     }

//                     if (attachments < 1 && files > 1) {
//                         incompleteAttachment = false;
//                     }
//                 }
//             }
//         });
//     };

//     const addMore = () => {
//         setOtherAttachments((prevState) => [
//             ...prevState,
//             {
//                 key: "custom_" + customCount,
//                 remarks: "",
//                 required: false,
//                 custom: true,
//                 placeholder: "Enter report title ...",
//             },
//         ]);
//         setCustomCount((prev) => prev + 1);
//     };

//     const remove = (index) => {
//         let temp_other_attachment = [...otherAttachments];
//         temp_other_attachment.splice(index, 1);
//         setOtherAttachments(temp_other_attachment);
//     };

//     const draftHandler = (data) => {
//         if (create) {
//             createDeliverable(data, "DRAFT");
//         } else {
//             updateDeliverable(data, "DRAFT");
//         }
//     };

//     const submitHandler = (data) => {
//         if (!data.prepared_by.name) {
//             setError("prepared_by.name", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (!data.prepared_by.number) {
//             setError("prepared_by.number", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (!data.prepared_by.email) {
//             setError("prepared_by.email", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (!data.prepared_by.designation) {
//             setError("prepared_by.designation", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (!preparedBySignature) {
//             setError("prepared_by.signature", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (
//             !data.prepared_by.name ||
//             !data.prepared_by.number ||
//             !data.prepared_by.designation ||
//             !data.prepared_by.email ||
//             !preparedBySignature
//         ) {
//             return;
//         }

//         if (create) {
//             createDeliverable(data, "SUBMITTED");
//         } else {
//             updateDeliverable(data, "SUBMITTED");
//         }
//     };

//     const confirmHandler = (data) => {
//         if (!data.certified_by.name) {
//             setError("certified_by.name", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (!data.certified_by.number) {
//             setError("certified_by.number", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (!data.certified_by.email) {
//             setError("certified_by.email", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (!data.certified_by.designation) {
//             setError("certified_by.designation", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (!certifiedBySignature) {
//             setError("certified_by.signature", {
//                 type: "custom",
//                 message: "This field is required",
//             });
//         }

//         if (
//             !data.certified_by.name ||
//             !data.certified_by.number ||
//             !data.certified_by.designation ||
//             !data.certified_by.email ||
//             !certifiedBySignature
//         ) {
//             return;
//         }

//         updateDeliverable(data, data.approval_status);
//     };

//     const getDeliverables = useCallback(async () => {
//         const response = await deliverablesApi.getDeliverable(id, {
//             category: "TP",
//         });
//         const data = response.data.data;
//         const temp_attachments = [...attachments];

//         Object.keys(data.attachments).forEach((key) => {
//             data.attachments[key].forEach((item, index) => {
//                 temp_attachments.forEach((value, key2) => {
//                     if (key === value.key) {
//                         value["attachments"].push(item);
//                     }
//                 });
//             });
//         });
//         setAttachments(temp_attachments);

//         const temp_other_attachments = [...otherAttachments];
//         let custom_obj = {
//             key: "",
//             remarks: "",
//             custom: true,
//             required: false,
//             attachments: [],
//         };
//         Object.keys(data.attachments).forEach((key) => {
//             data.attachments[key].forEach((item, index) => {
//                 if (key.startsWith("custom")) {
//                     const found = temp_attachments.filter(
//                         (object) => object.key === key
//                     ).length;
//                     custom_obj["key"] = key;
//                     custom_obj["remarks"] = item.remarks;
//                     custom_obj["attachments"].push(item);
//                     if (found === 0) {
//                         temp_other_attachments.push(custom_obj);
//                     }
//                 }
//             });
//         });
//         setOtherAttachments(temp_other_attachments);

//         setDeliverable(data);
//         setDisableField(
//             data.status === "SUBMITTED" || data.status === "APPROVED"
//                 ? true
//                 : false
//         );
//         setPreparedBySignature();
//         reset({
//             zone: data.zone,
//             month_year: toMMMMY("1/" + data.month + "/" + data.year),
//             prepared_by: data.prepared_by?.details,
//             certified_by: data.certified_by?.details,
//         });
//     }, [id, reset]);

//     useEffect(() => {
//         if (!create) {
//             getDeliverables();
//         } else {
//             reset({
//                 zone: "",
//                 month_year: "",
//             });
//             setDisableField(false);
//             setAttachments([]);
//             setAttachments(default_tp_report);
//             setDeliverable({});
//         }
//     }, [create, reset, getDeliverables, setAttachments, setDeliverable]);

//     const openDeleteModal = (id, key) => {
//         setModalInfo({
//             key: key,
//             id: id,
//             notifMsg: "Are you sure you want to delete this file?",
//             open: true,
//             severity: "danger",
//         });
//     };

//     const removeFile = () => {
//         const temp_attachments = [...attachments];
//         const index = temp_attachments[modalInfo.key]["attachments"].findIndex(
//             (object) => object.id === modalInfo.id
//         );
//         if (index === -1) {
//             temp_attachments[modalInfo.key]["attachments"].splice(index, 1);
//         }

//         setAttachments(temp_attachments);
//     };

//     const deleteFile = async (id, key) => {
//         try {
//             const response = await deleteAttachment(id);
//             if (response.data.status === "SUCCESS") {
//                 notyf.open({
//                     type: "success",
//                     message: "Successfully deleted",
//                 });
//                 removeFile(id, key);
//                 //window.location.reload();
//             }
//         } catch (error) {}
//     };

//     const CreateComponent = useCallback(() => {
//         return (
//             <>
//                 {attachments.map((item, index) => {
//                     return (
//                         <Col md={6} key={index}>
//                             <Form.Group className="mb-5">
//                                 <Form.Label className="font-weight-bold mb-3">
//                                     {item?.remarks}
//                                 </Form.Label>
//                                 {(create || deliverable.status === "DRAFT") && (
//                                     <UploaderComponent
//                                         autoUpload={false}
//                                         ref={(uploadData) => {
//                                             if (uploadData) {
//                                                 item.upload_data = uploadData;
//                                             }
//                                         }}
//                                         cssClass={
//                                             item.required && item.file_count < 1
//                                                 ? "is-invalid"
//                                                 : ""
//                                         }
//                                     />
//                                 )}
//                                 {item.required && item.file_count < 1 && (
//                                     <small className="text-danger">
//                                         This field is required
//                                     </small>
//                                 )}
//                                 {item.attachments.map((file, index2) => {
//                                     return (
//                                         <div
//                                             className="border-dashed mb-2"
//                                             key={index2}
//                                         >
//                                             <Row className="cursor-pointer">
//                                                 <Col md={10}>
//                                                     <FontAwesomeIcon
//                                                         icon={faFile}
//                                                         className="d-inline-block me-2 text-primary"
//                                                     />
//                                                     <p
//                                                         className="mb-0 d-inline-block me-2 text-primary"
//                                                         onClick={() =>
//                                                             window.open(
//                                                                 file.full_path
//                                                             )
//                                                         }
//                                                     >
//                                                         {formatFileName(
//                                                             file.file_name
//                                                         )}
//                                                     </p>
//                                                 </Col>
//                                                 <Col
//                                                     md={2}
//                                                     className="text-end"
//                                                 >
//                                                     <OverlayTrigger
//                                                         placement="top"
//                                                         overlay={
//                                                             <Tooltip>
//                                                                 Download
//                                                             </Tooltip>
//                                                         }
//                                                     >
//                                                         <span>
//                                                             <FontAwesomeIcon
//                                                                 icon={
//                                                                     faDownload
//                                                                 }
//                                                                 className="me-2"
//                                                                 onClick={() =>
//                                                                     window.open(
//                                                                         file.full_path
//                                                                     )
//                                                                 }
//                                                             />
//                                                         </span>
//                                                     </OverlayTrigger>
//                                                     {deliverable.status !==
//                                                         "APPROVED" && (
//                                                         <OverlayTrigger
//                                                             placement="top"
//                                                             overlay={
//                                                                 <Tooltip>
//                                                                     Delete
//                                                                 </Tooltip>
//                                                             }
//                                                         >
//                                                             <span>
//                                                                 <FontAwesomeIcon
//                                                                     icon={
//                                                                         faTrash
//                                                                     }
//                                                                     onClick={() =>
//                                                                         openDeleteModal(
//                                                                             file.id,
//                                                                             item.key
//                                                                         )
//                                                                     }
//                                                                 />
//                                                             </span>
//                                                         </OverlayTrigger>
//                                                     )}
//                                                 </Col>
//                                             </Row>
//                                         </div>
//                                     );
//                                 })}
//                             </Form.Group>
//                         </Col>
//                     );
//                 })}
//             </>
//         );
//     }, [create, attachments, deliverable]);

//     const ApprovalComponent = () => {
//         if (deliverable.status === "SUBMITTED") {
//             return (
//                 <Row className="pt-4">
//                     <Col md={6}>
//                         <Form.Group className="mb-3">
//                             <Form.Label className="font-weight-bold d-block">
//                                 Status
//                             </Form.Label>
//                             <Controller
//                                 control={control}
//                                 name="approval_status"
//                                 defaultValue="APPROVED"
//                                 render={({ field: { value, onChange } }) => (
//                                     <>
//                                         <Form.Check
//                                             label="Approve"
//                                             type="radio"
//                                             value="APPROVED"
//                                             className="d-inline-block me-4"
//                                             onChange={onChange}
//                                             checked={value === "APPROVED"}
//                                         />
//                                         <Form.Check
//                                             label="Reject"
//                                             type="radio"
//                                             value="REJECTED"
//                                             className="d-inline-block me-4"
//                                             onChange={onChange}
//                                             checked={value === "REJECTED"}
//                                         />
//                                     </>
//                                 )}
//                             />

//                             <Controller
//                                 control={control}
//                                 name="reject_remarks"
//                                 defaultValue=""
//                                 render={({ field: { value, onChange } }) => (
//                                     <Form.Control
//                                         className="mt-3"
//                                         type="text"
//                                         as="textarea"
//                                         rows={4}
//                                         value={value}
//                                         onChange={onChange}
//                                     />
//                                 )}
//                             />
//                         </Form.Group>
//                     </Col>
//                 </Row>
//             );
//         } else {
//             return null;
//         }
//     };

//     return (
//         <React.Fragment>
//             <Helmet title="Turf and plant" />
//             <Row>
//                 <Col md={6}>
//                     <h4 className="mb-3">Turf and plant</h4>
//                 </Col>
//             </Row>
//             <Card>
//                 <Card.Body>
//                     <Form>
//                     <Row>
//                             <Col md={4}>
//                                 <Form.Group className="mb-3">
//                                 <h6>Zone</h6>
//                                 <p> {deliverable?.zone_name} </p>
                                   
//                                 </Form.Group>
//                             </Col>
//                             <Col md={4}>
//                                 <h6>Submission</h6>
//                                 <p>  {deliverable?.year}  - 
//                                 {deliverable?.month_name}  </p>
                                
//                             </Col>
                         
//                             <Col md={4}>
//                                 <h6>Status</h6>
//                                 <p
//                                     className={
//                                         deliverable?.status === "REJECTED"
//                                             ? "text-danger"
//                                             : ""
//                                     }
//                                 >
//                                     {create
//                                         ? "Draft"
//                                         : toTitleCase(deliverable?.status)}
//                                 </p>
//                             </Col>
//                             {deliverable?.status === "REJECTED" && (
//                                 <Col md={6}>
//                                     <h6>Rejection reason</h6>
//                                     <p>{deliverable.reject_remarks}</p>
//                                 </Col>
//                             )}
//                         </Row>

//                         <Row className="pt-4">
//                             <CreateComponent />
//                             {otherAttachments.map((item, index) => {
//                                 return (
//                                     <Col md={6} key={index}>
//                                         <Form.Group className="mb-5">
//                                             {create ||
//                                             deliverable.status === "DRAFT" ? (
//                                                 <>
//                                                     <InputGroup>
//                                                         <Form.Control
//                                                             name={item.key}
//                                                             type="text"
//                                                             className="font-weight-bold mb-1"
//                                                             placeholder={
//                                                                 item.placeholder
//                                                             }
//                                                             value={item.remarks}
//                                                             onChange={(e) => {
//                                                                 const temp_other_attachment =
//                                                                     [
//                                                                         ...otherAttachments,
//                                                                     ];
//                                                                 temp_other_attachment[
//                                                                     index
//                                                                 ]["remarks"] =
//                                                                     e.target.value;
//                                                                 setOtherAttachments(
//                                                                     temp_other_attachment
//                                                                 );
//                                                             }}
//                                                         />
//                                                         <InputGroup.Text
//                                                             className="cursor-pointer"
//                                                             onClick={() =>
//                                                                 remove(index)
//                                                             }
//                                                         >
//                                                             <FontAwesomeIcon
//                                                                 icon={faTrash}
//                                                             />
//                                                         </InputGroup.Text>
//                                                     </InputGroup>
//                                                     <UploaderComponent
//                                                         autoUpload={false}
//                                                         ref={(uploadData) => {
//                                                             if (uploadData) {
//                                                                 item.upload_data =
//                                                                     uploadData;
//                                                             }
//                                                         }}
//                                                         cssClass={
//                                                             item.required &&
//                                                             item.file_count < 1
//                                                                 ? "is-invalid"
//                                                                 : ""
//                                                         }
//                                                     />
//                                                 </>
//                                             ) : (
//                                                 <Form.Label className="font-weight-bold">
//                                                     {item.remarks}
//                                                 </Form.Label>
//                                             )}
//                                             {item.attachments?.map(
//                                                 (file, index2) => {
//                                                     return (
//                                                         <div
//                                                             className="border-dashed mb-2"
//                                                             key={index2}
//                                                         >
//                                                             <Row className="cursor-pointer">
//                                                                 <Col md={10}>
//                                                                     <FontAwesomeIcon
//                                                                         icon={
//                                                                             faFile
//                                                                         }
//                                                                         className="d-inline-block me-2 text-primary"
//                                                                     />
//                                                                     <p
//                                                                         className="mb-0 d-inline-block me-2 text-primary"
//                                                                         onClick={() =>
//                                                                             window.open(
//                                                                                 file.full_path
//                                                                             )
//                                                                         }
//                                                                     >
//                                                                         {formatFileName(
//                                                                             file.file_name
//                                                                         )}
//                                                                     </p>
//                                                                 </Col>
//                                                                 <Col
//                                                                     md={2}
//                                                                     className="text-end"
//                                                                 >
//                                                                     <OverlayTrigger
//                                                                         placement="top"
//                                                                         overlay={
//                                                                             <Tooltip>
//                                                                                 Download
//                                                                             </Tooltip>
//                                                                         }
//                                                                     >
//                                                                         <span>
//                                                                             <FontAwesomeIcon
//                                                                                 icon={
//                                                                                     faDownload
//                                                                                 }
//                                                                                 className="me-2"
//                                                                                 onClick={() =>
//                                                                                     window.open(
//                                                                                         file.full_path
//                                                                                     )
//                                                                                 }
//                                                                             />
//                                                                         </span>
//                                                                     </OverlayTrigger>
//                                                                     {deliverable.status !==
//                                                                         "APPROVED" && (
//                                                                         <OverlayTrigger
//                                                                             placement="top"
//                                                                             overlay={
//                                                                                 <Tooltip>
//                                                                                     Delete
//                                                                                 </Tooltip>
//                                                                             }
//                                                                         >
//                                                                             <span>
//                                                                                 <FontAwesomeIcon
//                                                                                     icon={
//                                                                                         faTrash
//                                                                                     }
//                                                                                     onClick={() =>
//                                                                                         openDeleteModal(
//                                                                                             file.id,
//                                                                                             item.key
//                                                                                         )
//                                                                                     }
//                                                                                 />
//                                                                             </span>
//                                                                         </OverlayTrigger>
//                                                                     )}
//                                                                 </Col>
//                                                             </Row>
//                                                         </div>
//                                                     );
//                                                 }
//                                             )}
//                                             {item.required &&
//                                                 item.file_count < 1 && (
//                                                     <small className="text-danger">
//                                                         This field is required
//                                                     </small>
//                                                 )}
//                                         </Form.Group>
//                                     </Col>
//                                 );
//                             })}
//                             {(create || deliverable.status === "DRAFT") && (
//                                 <Col md={6}>
//                                     <Form.Label></Form.Label>
//                                     <div className="mt-3 p-3 text-center border-dashed">
//                                         <Button
//                                             className="me-2"
//                                             variant="light"
//                                             size="sm"
//                                             onClick={() => addMore()}
//                                         >
//                                             <FontAwesomeIcon
//                                                 icon={faPlus}
//                                                 className="feather me-2"
//                                             />
//                                             Add more
//                                         </Button>
//                                     </div>
//                                 </Col>
//                             )}
//                         </Row>
//                         <ApprovalComponent />
//                         <SignatureForm
//                             create={create}
//                             control={control}
//                             errors={errors}
//                             disableField={disableField}
//                             deliverable={deliverable}
//                             setPreparedBySignature={setPreparedBySignature}
//                             setCertifiedBySignature={setCertifiedBySignature}
//                         />
//                         {deliverable.status !== "APPROVED" && (
//                             <Row className="pt-4">
//                                 <Col md={12} className="text-center">
//                                     {deliverable.status !== "SUBMITTED" ? (
//                                         <>
//                                             <Button
//                                                 className="me-2"
//                                                 variant="secondary"
//                                                 onClick={handleSubmit(
//                                                     draftHandler
//                                                 )}
//                                                 disabled={loading}
//                                             >
//                                                 {loading
//                                                     ? "Please wait ..."
//                                                     : "Draft"}
//                                             </Button>
//                                             <Button
//                                                 className="me-2"
//                                                 variant="primary"
//                                                 onClick={handleSubmit(
//                                                     submitHandler
//                                                 )}
//                                                 disabled={loading}
//                                             >
//                                                 {loading
//                                                     ? "Please wait ..."
//                                                     : "Save"}
//                                             </Button>
//                                             <Button
//                                                 className="me-2"
//                                                 variant="link"
//                                                 onClick={ () => {
//                                                     navigate("/deliverables/turf-plant")
//                                                     }
//                                                 }
                                                
                                                
//                                             >
//                                                 Cancel  
//                                             </Button>
//                                         </>
//                                     ) : (
//                                         <Button
//                                             className="me-2"
//                                             variant="info"
//                                             onClick={handleSubmit(
//                                                 confirmHandler
//                                             )}
//                                             disabled={loading}
//                                         >
//                                             {loading
//                                                 ? "Please wait ..."
//                                                 : "Confirm"}
//                                         </Button>
//                                     )}
//                                 </Col>
//                             </Row>
//                         )}
//                     </Form>
//                 </Card.Body>
//             </Card>
//             <DeleteModal
//                 api={deleteFile}
//                 modalInfo={modalInfo}
//                 setModalInfo={setModalInfo}
//             />
//         </React.Fragment>
//     );
 };

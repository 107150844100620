import api from "../utils/api";

export const getExcelExports = (data) => {
    return api.get("/tasks", { params: data });
};

export const getExcelExport = (data) => {
    return api.get(`/tasks/${data}`);
};

export const createExcelExport = (data) => {
    return api.post("/tasks", data);
};

export const deleteExcelExport = (id) => {
    return api.delete(`/tasks/${id}`);
};

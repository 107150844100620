import React, { useState, useContext, useEffect, useCallback } from "react";

import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import DatePicker from "react-datepicker";
import Select from "react-select";

import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { TreeInspectionItemRow } from "./TreeInspectionItemRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortNumericDown } from "@fortawesome/free-solid-svg-icons";
import { SignaturesForm } from "../../../components/ui/SignatureForm";
import { LoadingContext } from "../../../App";
import { constructReport } from "utils/utilities";
import * as inspectionTreeChecklistApi from "@api/inspectionTreeChecklistApi";
import useLocalStorage from "hooks/useLocalStorage";
import NotyfContext from "contexts/NotyfContext";

export const TreeInspectionChecklistForm = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const { id } = useParams();
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
    });
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const tcSignatureOption = watch("prepared_by.select_signature", "");
    const [checkListItems, setCheckListItems] = useState([]);
    const [filterName, setFilterName] = useState("");
    const [inspectionChecklist, setInspectionChecklist] = useState({
        items: [],
        tree_inspected_at: null,
        id: null,
        status: null,
        school: null,
    });
    const [headers, setHeader] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [modalConfirmation, setModalConfirmation] = useState({
        notifMsg: "",
        open: false,
        api: "",
    });
    const [treeChecklistStorage, setTreeChecklistStorage] = useLocalStorage(
        "treeChecklist",
        [0]
    );
    const [rerender, setRerender] = useState(false);

    const save = async (
        status,
        showAlert = true,
        data = null,
        addTree = false
    ) => {
        if (filterName !== "") {
            notyf.open({
                type: "danger",
                message: "Please reset the filter to submit",
            });
            return;
        }
        const formData = new FormData();
        formData.append(
            "tree_checklist_items",
            JSON.stringify(inspectionChecklist?.items)
        );
        formData.append(
            "tree_inspected_at",
            new Date(inspectionChecklist?.tree_inspected_at).toLocaleDateString(
                "en-CA"
            )
        );
        formData.append("status", status);

        let error = false;
        let message = "";

        if (status.toUpperCase() !== "DRAFT" && showAlert) {
            if (
                data?.prepared_by === undefined ||
                data?.prepared_by?.signature === null ||
                data?.prepared_by?.signature === ""
            ) {
                error = true;
                message += "Signature is required\n";
            }

            if (
                inspectionChecklist?.tree_inspected_at === null ||
                inspectionChecklist?.tree_inspected_at === "" ||
                inspectionChecklist?.tree_inspected_at === undefined
            ) {
                error = true;
                message += "Inspection date is required";
            }
        }
        if (error) {
            notyf.open({
                type: "danger",
                message: message,
            });
            return;
        }
        if (
            data?.prepared_by?.signature !== undefined &&
            data?.prepared_by?.signature !== ""
        ) {
            formData.append("signature", data?.prepared_by?.signature);
            formData.append(
                "signature_details",
                data?.prepared_by?.signature_details
            );
            formData.append(
                "signature_options",
                data?.prepared_by?.signature_options
            );
        }
        setIsLoadingActive(true);
        try {
            const response =
                await inspectionTreeChecklistApi.updateInspectionTreeChecklist(
                    id,
                    formData
                );
            if (response.status === 200 && showAlert) {
                notyf.open({
                    type: "success",
                    message: "Checklist successfully updated",
                });
                !addTree && navigate(`/inspection-checklist/tree-inspections`);
            }
            setIsLoadingActive(false);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error?.message,
            });

            setIsLoadingActive(false);
        }
    };

    const updateInspectionDate = (date) => {
        setInspectionChecklist({
            ...inspectionChecklist,
            tree_inspected_at: new Date(date).toLocaleDateString("en-CA"),
        });
        setValue("tree_inspected_at", date);
    };
    const updateInspectionState = (
        parentIndex,
        childIndex,
        values,
        customInputIndex = null
    ) => {
        const selectedRowState = [...inspectionChecklist?.items];

        if (customInputIndex === null) {
            selectedRowState[parentIndex]["fields"][childIndex]["value"] =
                values;
        } else {
            selectedRowState[parentIndex]["fields"][childIndex]["inputs"][
                customInputIndex
            ]["value"] = values;
        }

        setInspectionChecklist({
            ...inspectionChecklist,
            items: selectedRowState,
        });
        setCheckListItems({ ...checkListItems, selectedRowState });
    };
    const updateServiceReportCheckBox = (option) => {
        const selectedRowState = [...inspectionChecklist?.items];
        selectedRowState.map((item, index) => {
            item?.fields
                ?.filter((fieldItem) => {
                    return fieldItem.type === "report";
                })
                .forEach((item) => (item.value = option));

            return item;
        });

        setInspectionChecklist({
            ...inspectionChecklist,
            items: selectedRowState,
        });
        setCheckListItems({ ...checkListItems, selectedRowState });
    };

    const updateSequenceNumber = (parentIndex, values) => {
        const selectedRowState = [...inspectionChecklist?.items];
        selectedRowState[parentIndex]["sequence"] = values;
        setInspectionChecklist({
            ...inspectionChecklist,
            items: selectedRowState,
        });
        setCheckListItems({ ...checkListItems, selectedRowState });
    };

    const sortBySequence = () => {
        const selectedRowState = [...inspectionChecklist?.items];
        selectedRowState.sort((a, b) => a.sequence - b.sequence);
        setInspectionChecklist({
            ...inspectionChecklist,
            items: selectedRowState,
        });
        setCheckListItems({ ...checkListItems, selectedRowState });
    };
    const formatHeaders = (responseData) => {
        const header = responseData[0]?.fields
            ?.filter((item) => item?.display)
            .map((item) => {
                return {
                    label: item?.label,
                    class: item?.display?.class,
                };
            });
        setHeader(header);
    };
    const setFilterValue = (responseData, customFieldInput) => {
        const filterArray = [];

        // responseData?.map((filterItem, index) => {
        //     const filters = filterItem?.fields
        //         ?.map((item) => {
        //             if (item.type == 'custom_input') {
        //                 return item.inputs
        //                     .filter((findItem) => {
        //                         return findItem.name === customFieldInput;
        //                     })
        //                     .map((item) => item.value);
        //             }
        //         })
        //         .filter((element) => {
        //             return element !== undefined && element !== '';
        //         })
        //         .join(',');
        //     if (filters !== '') {
        //         const temp = {
        //             label: filters,
        //             value: filters,
        //         };
        //         filterArray.push(temp);
        //     }
        // });
        return filterArray;
    };

    const formatFilterArray = async (responseData, zone) => {
        let array = [];
        if (zone?.toUpperCase() === "NORTH_EAST") {
            array = setFilterValue(responseData, "tree_name:");
        } else if (zone?.toUpperCase() === "SOUTH") {
            array = setFilterValue(responseData, "tree_id");
        }
        setFilterList(array);
    };
    const filterInspectionList = (data, zone) => {
        const filterValue =
            data?.value !== "" && data?.value !== undefined ? data?.value : "";
        setFilterName(filterValue);
        if (zone?.toUpperCase() === "NORTH_EAST") {
            setFilterInspectionList(filterValue, "tree_name:");
        } else if (zone?.toUpperCase() === "SOUTH") {
            setFilterInspectionList(filterValue, "tree_id");
        }
    };

    const setSectionName = (data, zone) => {
        let name = "";

        if (zone?.toUpperCase() === "NORTH_EAST") {
            data?.map((item, index, allItems) => {
                const array = allItems.find(
                    (item) => item.type === "custom_input"
                );
                return (name = `${filterInput(
                    array,
                    "tree_id"
                )} - ${filterInput(array, "tree_name:")}`);
            });
        } else if (zone?.toUpperCase() === "SOUTH") {
            data?.map((item, index, allItems) => {
                const array = allItems.find(
                    (item) => item.type === "custom_input"
                );
                return (name = filterInput(array, "tree_name:"));
            });
        }

        if (name !== "") {
            return name;
        }
    };

    const filterInput = (array, name) => {
        return array?.inputs
            ?.filter((item) => {
                return item.name === name;
            })
            .map((item) => item.value);
    };

    const setFilterInspectionList = (selectedValues, filterFieldName) => {
        if (selectedValues === null || selectedValues === undefined) {
            setInspectionChecklist({
                ...inspectionChecklist,
                items: checkListItems,
            });
            return;
        }
        const currentStateItems = [...checkListItems];
        const filteredItems = currentStateItems?.filter((items) => {
            const filterFiels = items?.fields?.filter(
                (item, index, allItems) => {
                    const t = allItems.find(
                        (item) => item.type === "custom_input"
                    );
                    const result = t?.inputs.filter((item) => {
                        return (
                            item.name === filterFieldName &&
                            item.value === selectedValues
                        );
                    });
                    if (result.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            );
            if (filterFiels.length > 0) {
                return true;
            } else {
                return false;
            }
        });

        if (selectedValues === "") {
            setInspectionChecklist({
                ...inspectionChecklist,
                items: checkListItems,
            });
        } else {
            setInspectionChecklist({
                ...inspectionChecklist,
                items: filteredItems,
            });
        }
    };

    const getInspectionChecklist = useCallback(async () => {
        try {
            setTreeChecklistStorage([0]);
            setIsLoadingActive(true);
            const response =
                await inspectionTreeChecklistApi.getInspectionTreeChecklist(id);

            if (response.status === 200) {
                let data = response?.data?.data;
                setInspectionChecklist({
                    items: data?.tree_checklist_items,
                    id: id,
                    tree_inspected_at:
                        data?.tree_inspected_at !== null
                            ? new Date(data?.tree_inspected_at)
                            : new Date(),

                    status: data?.status,
                    school: data.school,
                    attachments: data?.attachments,
                    report_url: data?.report_url,
                });
                // sortBySequence(data?.tree_checklist_items);

                setCheckListItems(data?.tree_checklist_items);
                formatHeaders(data?.tree_checklist_items);
                // sortBySequence(data?.tree_checklist_items);
                formatFilterArray(
                    data?.tree_checklist_items,
                    data?.school?.zone
                );

                data.tree_inspected_at =
                    data?.tree_inspected_at !== null
                        ? new Date(data?.tree_inspected_at)
                        : new Date();

                data.prepared_by = {
                    signature_options: data.signatories?.signature_options,
                    signature_details: data.signatories?.signature_details,
                    signature: data.signatories?.url,
                    name: data.signatories?.name,
                };

                reset(data);
                setIsLoadingActive(false);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setIsLoadingActive(false);
        }
        // eslint-disable-next-line
    }, [id, notyf, reset]);

    const addTree = async () => {
        const response =
            await inspectionTreeChecklistApi.addTreeInspectionSubItem({
                inspection_id: id,
            });

        if (response?.status) {
            setRerender(!rerender);
        }
    };

    useEffect(() => {
        getInspectionChecklist();
    }, [getInspectionChecklist, rerender]);

    return (
        <React.Fragment>
            <Helmet
                title={
                    inspectionChecklist?.school?.name +
                    " | Inspection checklist"
                }
            />
            <Row>
                <Col md={6}>
                    <h4 className="h4 mb-3">Tree Inspections</h4>
                </Col>
                <Col md={6}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClick={() => {
                                navigate(
                                    `/inspection-checklist/tree-inspections`
                                );
                                setTreeChecklistStorage([0]);
                            }}
                        >
                            Tree Inspections
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {inspectionChecklist?.school?.name}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Container fluid className="p-0">
                <Card className="p-4">
                    <Form>
                        <Row style={{ marginBottom: "20px" }}>
                            <Col md={7}>
                                <h4 className="h4 pt-1">
                                    {inspectionChecklist?.school?.name}
                                </h4>
                            </Col>
                            <Col md={2}>
                                {filterList?.length > 0 && (
                                    <>
                                        <span>Filter by section</span>
                                        <Select
                                            className=""
                                            classNamePrefix="react-select "
                                            options={filterList}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={false}
                                            placeholder="Select tree"
                                            // disabled={canEdit}
                                            onChange={(e) => {
                                                filterInspectionList(
                                                    e,
                                                    inspectionChecklist?.school
                                                        ?.zone
                                                );
                                            }}
                                        />
                                    </>
                                )}
                            </Col>

                            <Col md={2}>
                                <span>Inspection date</span>

                                <Controller
                                    control={control}
                                    name="tree_inspected_at"
                                    defaultValue=""
                                    render={({ field: { value } }) =>
                                        inspectionChecklist?.status?.toUpperCase() !==
                                        "DRAFT" ? (
                                            <div>
                                                {new Date(
                                                    inspectionChecklist?.tree_inspected_at
                                                ).toLocaleDateString("en-CA")}
                                            </div>
                                        ) : (
                                            <>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={value}
                                                    onChange={(e) => {
                                                        updateInspectionDate(e);
                                                    }}
                                                    className={`form-control form-box ${
                                                        errors.inspected_at &&
                                                        "is-invalid"
                                                    }`}
                                                    value={value}
                                                    placeholderText="Inspection date"
                                                />
                                            </>
                                        )
                                    }
                                />
                            </Col>
                            <Col md={1}>
                                <span>Sort</span> <br />
                                <Button
                                    type="button"
                                    onClick={() => sortBySequence()}
                                    className={`${isMobile && "w-100 mt-2"}`}
                                >
                                    <FontAwesomeIcon
                                        icon={faSortNumericDown}
                                        className="align-middle me-1 cursor-pointer"
                                    />
                                </Button>
                            </Col>
                        </Row>

                        <Card>
                            {!isMobile && (
                                <Row className="py-3 ps-2">
                                    {headers?.length > 0 &&
                                        headers?.map(
                                            (headerItem, headerIndex) => {
                                                return (
                                                    <Col
                                                        className={
                                                            headerItem?.class
                                                        }
                                                        key={`header-${headerIndex}`}
                                                    >
                                                        <b>
                                                            {headerItem?.label}
                                                        </b>
                                                    </Col>
                                                );
                                            }
                                        )}
                                    <Col>
                                        <Form.Check
                                            id="check-all-field"
                                            label="Check all"
                                            type="checkbox"
                                            onChange={(e) => {
                                                updateServiceReportCheckBox(
                                                    e.target.checked
                                                        ? "yes"
                                                        : "no"
                                                );
                                            }}
                                            value="yes"
                                        />
                                    </Col>
                                </Row>
                            )}

                            <Accordion
                                defaultActiveKey={treeChecklistStorage}
                                alwaysOpen
                            >
                                {inspectionChecklist?.items?.length > 0 &&
                                    inspectionChecklist?.items?.map(
                                        (items, index) => {
                                            return (
                                                <Accordion.Item
                                                    eventKey={index}
                                                    key={`outer-${index}`}
                                                >
                                                    <Accordion.Header>
                                                        {setSectionName(
                                                            items?.fields,
                                                            inspectionChecklist
                                                                ?.school?.zone
                                                        )}
                                                    </Accordion.Header>
                                                    <Accordion.Body className="p-0">
                                                        <div className="p-2">
                                                            <TreeInspectionItemRow
                                                                id={
                                                                    inspectionChecklist?.id
                                                                }
                                                                data={items}
                                                                control={
                                                                    control
                                                                }
                                                                errors={errors}
                                                                updateState={
                                                                    updateInspectionState
                                                                }
                                                                saveInspection={
                                                                    save
                                                                }
                                                                parentIndex={
                                                                    index
                                                                }
                                                                status={
                                                                    inspectionChecklist?.status
                                                                }
                                                                updateSequenceNumber={
                                                                    updateSequenceNumber
                                                                }
                                                                setTreeChecklistStorage={
                                                                    setTreeChecklistStorage
                                                                }
                                                                attachments={
                                                                    inspectionChecklist?.attachments
                                                                }
                                                                rerender={
                                                                    rerender
                                                                }
                                                                setRerender={
                                                                    setRerender
                                                                }
                                                            />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            );
                                        }
                                    )}
                            </Accordion>
                        </Card>
                        {inspectionChecklist?.status?.toUpperCase() ===
                            "DRAFT" && (
                            <Row>
                                <Col md="10"></Col>
                                <Col md="2" className="text-end">
                                    <Button
                                        onClick={async () => {
                                            await save(
                                                inspectionChecklist?.status,
                                                true,
                                                null,
                                                true
                                            );
                                            addTree();
                                        }}
                                    >
                                        Add tree
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <SignaturesForm
                                control={control}
                                canSign={
                                    inspectionChecklist?.status?.toUpperCase() ===
                                    "DRAFT"
                                }
                                label="Certificate by"
                                name="prepared_by"
                                selectedSignatureOption={tcSignatureOption}
                                setValue={setValue}
                            />
                        </Row>
                        <Row className="my-3">
                            <Col md={12} className="text-center">
                                {inspectionChecklist?.status?.toUpperCase() ===
                                    "DRAFT" && (
                                    <>
                                        <Button
                                            className="me-2"
                                            variant="primary"
                                            type="submit"
                                            onClick={handleSubmit((data) =>
                                                setModalConfirmation({
                                                    notifMsg:
                                                        "Are you sure you want to submit this checklist?",
                                                    open: true,
                                                    api: () => {
                                                        setInspectionChecklist({
                                                            ...inspectionChecklist,
                                                            items: checkListItems,
                                                        });
                                                        save(
                                                            "Submitted",
                                                            true,
                                                            data
                                                        );
                                                    },
                                                })
                                            )}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            className="me-2"
                                            variant="secondary"
                                            type="submit"
                                            onClick={handleSubmit(
                                                async (data) => {
                                                    save("Draft", true, data);
                                                }
                                            )}
                                        >
                                            Draft
                                        </Button>
                                    </>
                                )}
                                {inspectionChecklist?.file_info?.bucket &&
                                    inspectionChecklist?.file_info
                                        ?.file_path && (
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                window.open(
                                                    constructReport(
                                                        inspectionChecklist
                                                            ?.file_info?.bucket,
                                                        inspectionChecklist
                                                            ?.file_info
                                                            ?.file_path,
                                                        `${inspectionChecklist?.school?.name}-${inspectionChecklist?.inspection_category}-${inspectionChecklist?.inspected_at}`,
                                                        true
                                                    )
                                                )
                                            }
                                        >
                                            Preview
                                        </Button>
                                    )}

                                <Button
                                    variant="link"
                                    onClick={() => {
                                        navigate(
                                            "/inspection-checklist/tree-inspections"
                                        );
                                        setTreeChecklistStorage([0]);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Container>
            <ConfirmationModal
                modalInfo={modalConfirmation}
                setModalInfo={setModalConfirmation}
            />
        </React.Fragment>
    );
};

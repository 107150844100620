import React, { useEffect } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ErrorMessage } from "@hookform/error-message";
import { errorMessage } from "utils/utilities";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";

export const AddNoteModal = ({ modalInfo, setModalInfo, view }) => {
    //
    // States
    //

    const schema = yup.object().shape({
        note: yup.string().required("This field is required"),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const closeModal = () => {
        setModalInfo({ open: false });
    };

    const saveModal = (data) => {
        setModalInfo({
            open: false,
            note: data.note,
            id: modalInfo.id,
            parent_id: modalInfo?.parent_id || false,
        });
    };

    useEffect(() => {
        reset({
            note: modalInfo.note,
        });
    }, [reset, modalInfo]);

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "add" })}
            size="lg"
            centered
        >
            <Modal.Header closeButton>Add note</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="description"
                                    className="font-weight-bold"
                                >
                                    Note
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="note"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            {view ? (
                                                <p>
                                                    {value || "No note added"}
                                                </p>
                                            ) : (
                                                <AutoGrowTextarea
                                                    key={modalInfo.id}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    fieldValue={value}
                                                    errors={errors.note}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="note"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        {!view && (
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit(saveModal)}
                            >
                                Submit
                            </Button>
                        )}
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

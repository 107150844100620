import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { Helmet } from "react-helmet-async";
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { DeleteModal } from "components/DeleteModal";
import { StarRateModal } from "../quotation-list/modal/StarRateModal";
import NotyfContext from "contexts/NotyfContext";
import DynamicTable from "components/ui/DynamicTable";
import useLocalStorage from "hooks/useLocalStorage";
import * as fsrsApi from "@api/fsrsApi";
import { formatConfigFields } from "utils/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSearch } from "@fortawesome/free-solid-svg-icons";

export const RerenderContextQuotationItems = createContext();

export const QuotationItemsAdd = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    let { action } = useParams();

    //
    // States
    //

    const [value, setValue] = useLocalStorage("quotationItems", []);
    const [rerender, setRerender] = useState([]);
    const [searchValue, setSearchValue] = useState({
        item_number: "",
        name: "",
        is_star_rate: false,
        all: 1,
    });
    const [searchGlobal, setSearchGlobal] = useState("");
    const [forceSelectionIdxs, setForceSelectionIdxs] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [showDeleteModal, setShowDeleteModal] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [showStarRate, setShowStarRate] = useState({
        open: false,
        action: "add",
        edit: false,
        item: {},
    });
    const [quotationItems, setQuotationItems] = useState([]);
    const [configColumn, setFieldColumn] = useState([]);
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    let isDesktop = width > 1400;

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: ({ row }) => (
            <>
                {row.original.is_star_rate && (
                    <div>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit star rate</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    className="align-middle me-1"
                                    onClick={() =>
                                        setShowStarRate({
                                            open: true,
                                            action: "add",
                                            edit: row.original.id,
                                            item: row.original,
                                        })
                                    }
                                    icon={faPen}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    </div>
                )}
            </>
        ),
    };

    //
    // Functions
    //

    const deletedefect = useCallback(
        async (id) => {
            try {
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getFsrs = useCallback(
        async (param = searchValue) => {
            try {
                const response = await fsrsApi.getFsrs(param);
                if (response.status === 200) {
                    setQuotationItems(response.data);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        // eslint-disable-next-line
        [notyf]
    );

    const addSelected = (selectedRows) => {
        if (!selectedRows.length) return selectedRows;

        let filteredItems = value.filter(
            (obj) =>
                Object.keys(obj).includes("serial_no") &&
                obj?.header === undefined
        );

        let itemCount = filteredItems.length;

        selectedRows = selectedRows.map((row, index) => {
            itemCount++;
            return {
                ...selectedRows[index],
                serial_no: itemCount.toString(),
            };
        });

        const mergedRows = value ? [...value, ...selectedRows] : selectedRows;

        const mergedRowsWithQuotationId = mergedRows.map((item) => {
            return { ...item, quotation_id: action || "" };
        });

        setValue(mergedRowsWithQuotationId);
        navigate(`/works-order/quotation-list/${action}`);
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await fsrsApi.getFsrConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    useEffect(() => {
        getFsrs();
        getConfig();
    }, [getFsrs, rerender, getConfig]);

    useEffect(() => {
        if (!quotationItems.result) return;

        const selected = quotationItems.result.filter((idxn, idx) => {
            return Object.keys(forceSelectionIdxs).some((id) => {
                return idx === Number(id);
            });
        });

        setSelectedRows(selected);
    }, [forceSelectionIdxs]);

    return (
        <RerenderContextQuotationItems.Provider value={[setRerender, rerender]}>
            <Helmet title="Add quotation items" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">Add quotation items</h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() =>
                                    navigate("/works-order/quotation-list")
                                }
                            >
                                Quotation list
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Add quotation items
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Header className="pb-0 mt-2">
                        <Row>
                            <Col md={10}>
                                <Row>
                                    <Col md={6} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className={`d-inline-block form-box ${
                                                isMobile && "w-100"
                                            }`}
                                            placeholder="Search filter by any value of a quotation item"
                                            onChange={(e) =>
                                                setSearchGlobal(e.target.value)
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} sm={12} xs={12}>
                                <h1 className="h3 mb-3">
                                    <Button
                                        variant="primary"
                                        className={`float-end mb-2 form-box ${
                                            isMobile && "w-100 mt-2"
                                        }`}
                                        onClick={() =>
                                            setShowStarRate({
                                                open: true,
                                                action: "add",
                                            })
                                        }
                                    >
                                        Add star rate
                                    </Button>
                                </h1>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <h5 className="mb-3">List of items</h5>

                        {quotationItems?.result &&
                            quotationItems?.result?.length > 0 && (
                                <DynamicTable
                                    dataPagination={{
                                        data: quotationItems.result,
                                        meta: "",
                                        links: "",
                                    }}
                                    columns={formatConfigFields(
                                        configColumn,
                                        actionField
                                    )}
                                    className="table-layout-fixed"
                                    isPaginated
                                    withCheckbox
                                    searchGlobal={searchGlobal}
                                    onChangeSelectedRowsId={(selectedIds) => {
                                        setForceSelectionIdxs(selectedIds);
                                    }}
                                />
                            )}

                        <Row className="pt-4">
                            <Col md={12} className="text-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    onClick={() => addSelected(selectedRows)}
                                >
                                    Add selected
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={() =>
                                        navigate(
                                            `/works-order/quotation-list/${action}`
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <DeleteModal
                modalInfo={showDeleteModal}
                setModalInfo={setShowDeleteModal}
                api={deletedefect}
            />
            <StarRateModal
                modalInfo={showStarRate}
                setModalInfo={setShowStarRate}
                rerender={rerender}
                setRerender={setRerender}
            />
        </RerenderContextQuotationItems.Provider>
    );
};

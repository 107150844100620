import React, { useCallback, useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";

import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import NotyfContext from "contexts/NotyfContext";
import { errorMessage } from "utils/utilities";
import * as documentApi from "@api/documentApi";
import * as attachmentApi from "@api/attachmentApi";
import { DeleteModal } from "components/DeleteModal";
import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";

export const DocumentRepositoryForm = () => {
    const navigate = useNavigate();
    let { action } = useParams();
    const notyf = useContext(NotyfContext);

    //
    // States
    //
    const [upload, setUpload] = useState([]);
    const [document, setDocument] = useState([]);
    const schema = yup.object().shape({
        name: yup.string().required("This field is required"),
        category: yup.array().min(1, "This field is required"),
    });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [rerender, setRerender] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const options = [
        { value: "north", label: "North" },
        { value: "north-east", label: "North east" },
        { value: "east", label: "East" },
        { value: "south", label: "South" },
        { value: "south-west", label: "South west" },
        { value: "west", label: "West" },
    ];

    //
    // Functions
    //

    const documentRepositoryApi = async (data) => {
        setDisabled(true);
        const attachments = upload.getFilesData() || [];

        if (action === "create" && !attachments.length) {
            notyf.open({
                type: "danger",
                message: `File upload is required`,
            });
            return;
        }

        const formData = new FormData();
        const categoryJson = JSON.stringify(data.category);

        formData.append("name", data.name);
        formData.append("category", categoryJson);
        formData.append("description", data.description);
        formData.append("bucket", "north_east");

        attachments.forEach((file) => {
            formData.append("file[]", file.rawFile);
        });

        try {
            action === "create"
                ? await documentApi.createDocument(formData)
                : await documentApi.updateDocument(action, formData);

            notyf.open({
                type: "success",
                message: `Successfully ${
                    action === "create" ? "created" : "updated"
                } document repository`,
            });
            navigate(`/document-repository`);
            setDisabled(false);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setDisabled(false);
        }
    };

    const getDocumentRepository = useCallback(async () => {
        try {
            const response = await documentApi.getDocument(action);
            if (response.status === 200) {
                const data = response.data.data;
                reset({
                    name: data.name,
                    description: data.description,
                    category: JSON.parse(data.category),
                });

                setDocument(data.attachments);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [action, notyf, reset]);

    const deleteApi = async (id) => {
        try {
            const response = await attachmentApi.deleteAttachment(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "Successfully deleted file",
                });
                setRerender(!rerender);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        action !== "create" && getDocumentRepository();
    }, [action, getDocumentRepository, rerender]);

    useEffect(() => {
        reset({
            category: [],
        });
    }, [reset]);

    return (
        <React.Fragment>
            <Helmet
                title={`${action === "create" ? "Create" : "Edit"} document`}
            />
            <Row>
                <Col md={6}>
                    <h4 className="tab-title mb-3">
                        {action === "create" ? "Create" : "Edit"} document
                    </h4>
                </Col>
                <Col md={6}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClick={() => navigate(`/document-repository`)}
                        >
                            Document list
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {action === "create" ? "Create" : "Edit"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Card className="p-0">
                <Card.Body className="pb-0">
                    <Row className="mb-1">
                        <Col md={12}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="taskName">
                                            Document name
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="name"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                },
                                            }) => (
                                                <Form.Control
                                                    className={`form-box ${
                                                        errors.name &&
                                                        "is-invalid"
                                                    }`}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    type="text"
                                                    value={value}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="name"
                                            render={({ message }) =>
                                                errorMessage(message)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="category">
                                            Category
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="category"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                    ref,
                                                },
                                            }) => (
                                                <Select
                                                    inputRef={ref}
                                                    className={`react-select-container ${
                                                        errors.category &&
                                                        "is-invalid"
                                                    }`}
                                                    classNamePrefix="react-select "
                                                    options={options}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    value={value}
                                                    onChange={onChange}
                                                    // disabled={canEdit}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="category"
                                            render={({ message }) =>
                                                errorMessage(message)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="description">
                                            Description *
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="description"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                },
                                            }) => (
                                                <AutoGrowTextarea
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    fieldValue={value}
                                                    errors={errors.description}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="description"
                                            render={({ message }) =>
                                                errorMessage(message)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <FileUploaderSyncfusion
                        setUpload={setUpload}
                        setModalInfo={setModalInfo}
                        document={document}
                        action={action}
                    />
                    <Row>
                        <Col md={12}>
                            <small>* Optional fields</small>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col md={12} className="text-center">
                            <Button
                                className="me-2"
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit(documentRepositoryApi)}
                                disabled={disabled}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="link"
                                onClick={() => navigate(`/document-repository`)}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <DeleteModal
                api={deleteApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
        </React.Fragment>
    );
};

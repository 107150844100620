import api from "../utils/api";

export const createPermission = (data) => {
    return api.post("/permission", data);
};

export const updatePermission = (id, data) => {
    return api.put("/permission/" + id, data);
};

export const getPermissions = (data) => {
    return api.get("/permission", { params: data });
};

export const getPermission = (id) => {
    return api.get("/permission/" + id);
};

export const deletePermission = (id) => {
    return api.delete("/permission/" + id);
};

export const getPermissionsConfig = (data) => {
    return api.get(`/config/permission`, data);
};

export const getCategoryConfig = (data) => {
    return api.get(`/permission/config`, data);
};

import React, { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import { LoadingContext } from "../../../App";
import * as wsoApi from "@api/wsoApi";
import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import NotyfContext from "contexts/NotyfContext";

export const AdhocWorkServiceOrderPreview = () => {
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const notyf = useContext(NotyfContext);
    const navigate = useNavigate();
    const [quotation, setQuotations] = useState();
    const { id } = useParams();

    const getQuotations = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await wsoApi.previewWso(id, param);
                if (response.status === 200) {
                    setQuotations(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: error?.message,
                });
                setIsLoadingActive(false);
            }
        },
        // eslint-disable-next-line
        [notyf, setIsLoadingActive]
    );

    useEffect(() => {
        getQuotations();
    }, [getQuotations]);

    return (
        <>
            <Helmet title="Quotation preview" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">Add work service order</h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate(-1)}>
                                Work service order
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Preview</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Row className="justify-content-center">
                            <Col
                                md={10}
                                dangerouslySetInnerHTML={{ __html: quotation }}
                            ></Col>
                        </Row>

                        <Row className="pt-4">
                            <Col md={12} className="text-center">
                                <Button
                                    variant="link"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
};

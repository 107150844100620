import React, { useCallback, useContext, useEffect } from "react";

import { Col, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useLocation } from "react-router-dom";

import NotyfContext from "contexts/NotyfContext";
import * as rolesApi from "@api/rolesApi";

export const RolesPermissionsTab = ({ control, roles, setRoles }) => {
    const notyf = useContext(NotyfContext);
    const { state } = useLocation();

    const getRoles = useCallback(
        async (param = null) => {
            try {
                const response = await rolesApi.getRoles(param);
                const rolesResponse = response.data.data;
                let roles = [];

                rolesResponse.forEach((element) => {
                    roles = [
                        ...roles,
                        {
                            value: element.id,
                            label: element.name,
                        },
                    ];
                });

                setRoles(roles);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setRoles]
    );

    //
    // UseEffects
    //

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    return (
        <Col md={4}>
            <Form.Group>
                <Form.Label>Roles</Form.Label>
                <Controller
                    control={control}
                    name="role_id"
                    defaultValue=""
                    render={({ field: { value, onBlur, onChange } }) => (
                        <>
                            {state?.profile ? (
                                <>
                                    <br />
                                    {
                                        roles?.find(
                                            (o) => o.value === value?.value
                                        )?.label
                                    }
                                </>
                            ) : (
                                <Select
                                    className="is-invalid react-select-container"
                                    classNamePrefix="react-select"
                                    options={roles}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={roles?.find(
                                        (o) => o.value === value?.value
                                    )}
                                    onChange={onChange}
                                    menuPlacement="top"
                                />
                            )}
                        </>
                    )}
                />
            </Form.Group>
        </Col>
    );
};

import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toMonthName } from "utils/utilities";

export const Information = ({ information }) => {
    return (
        <Row className="my-4">
            <Col md={6}>
                <Form.Label className="font-weight-bold">Zone:</Form.Label>
                <span className="ms-2">{information?.zone}</span>
            </Col>
            <Col md={6}>
                <Form.Label className="font-weight-bold">
                    Payment claim date:
                </Form.Label>
                <span className="ms-2">
                    {information?.created_at
                        ? information?.created_at.split(" ")[0]
                        : "-"}
                </span>
            </Col>
            <Col md={6}>
                <Form.Label className="font-weight-bold">
                    Payment claim number:
                </Form.Label>
                <span className="ms-2">
                    {information?.payment_claim_no || "-"}
                </span>
            </Col>
            <Col md={6}>
                <Form.Label className="font-weight-bold">
                    For the value of work carried out to the end of the
                    reference month of:
                </Form.Label>
                <span className="ms-2">
                    {toMonthName(information?.month)} {information?.year}
                </span>
            </Col>
            <Col md={6}>
                <Form.Label className="font-weight-bold">
                    Reference number:
                </Form.Label>
                <span className="ms-2">
                    {information?.reference_number || "-"}
                </span>
            </Col>
        </Row>
    );
};

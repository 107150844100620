import React, { useCallback, useContext, useState, useEffect } from "react";

import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import { Cover } from "../components/Cover";
import { Summary } from "../components/Summary";
import { toTitleCase } from "utils/utilities";
import { CommonTabs } from "../components/CommonTabs";
import { Overall } from "../components/Overall";
import NotyfContext from "contexts/NotyfContext";
import * as copApi from "@api/copApi";
import { GRR } from "../components/GRR";
import { SyntheticField } from "../components/SyntheticField";

export const CopItem = () => {
    const navigate = useNavigate();
    const { action, tab } = useParams();

    const [information, setInformation] = useState({});
    const notyf = useContext(NotyfContext);

    const getInformation = useCallback(
        async (param = null) => {
            try {
                const response = await copApi.getInformation(action);

                if (response.status === 200) {
                    setInformation(response.data.data);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    let selectedTab = (
        <>
            <CommonTabs tab={tab} information={information} />
        </>
    );
    switch (true) {
        case tab === "cover":
            selectedTab = <Cover information={information} />;
            break;
        case tab === "overall":
            selectedTab = <Overall information={information} />;
            break;
        case tab === "summary":
            selectedTab = <Summary information={information} />;
            break;
        case tab === "general_r_and_r":
            selectedTab = <GRR information={information} />;
            break;
        case tab === "synthetic_field":
            selectedTab = <SyntheticField information={information} />;
            break;
        default:
            break;
    }

    useEffect(() => {
        getInformation();
    }, [getInformation]);

    return (
        <React.Fragment>
            <Helmet title="COP" />
            <Row>
                <Col md={6}>
                    <h4 className="h4 mb-3">COP</h4>
                </Col>
                <Col md={6}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClick={() => navigate("/cop/contracts")}
                        >
                            Cop list
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {toTitleCase(tab)}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Card>
                <Card.Body>{selectedTab}</Card.Body>
            </Card>
        </React.Fragment>
    );
};

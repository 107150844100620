import React, { useState, useContext } from "react";

import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import DynamicTable from "components/ui/DynamicTable";
import { useEffect, useCallback } from "react";
import NotyfContext from "contexts/NotyfContext";
import * as inspectionChecklistApi from "@api/inspectionChecklistApi";
import { DeleteModal } from "components/DeleteModal";
import Select from "react-select";
import * as schoolApi from "@api/schoolApi";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { constructReport, formatConfigFields } from "utils/utilities";
import { LoadingContext } from "App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faEraser,
    faEye,
    faFilePdf,
    faList,
    faPen,
    faRecycle,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import {
    checklist_category,
    checklist_frequency,
    checklist_type,
} from "../checklist/checklist-config";
import { EditDetailsModal } from "./components/EditDetailsModal";
import { status_options } from "config";
import { ConfirmationModal } from "components/modal/ConfirmationModal";

export const InspectionChecklist = ({ status = false }) => {
    //
    // States
    //

    const [inspectionChecklist, setInspectionChecklist] = useState([]);
    const navigate = useNavigate();
    const { pathname, state } = useLocation();

    const notyf = useContext(NotyfContext);
    const [rerender, setRerender] = useState([]);
    const [schools, setSchools] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [modalEditDetails, setModalEditDetails] = useState({
        id: null,
        open: false,
        severity: "danger",
        schools: [],
        selectedSchool: {},
    });
    const [modalConfirmation, setModalConfirmation] = useState({
        notifMsg: "",
        open: false,
        api: "",
    });
    const { control, handleSubmit, reset } = useForm({});
    const [filter, setFilter] = useState(state?.previousSearchValue || {});
    const [configColumn, setFieldColumn] = useState([]);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const { hasRole, user } = useAuth();
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    let isDesktop = width > 1400;

    const hasRoleCollection = {
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isTechnician: hasRole("TECHNICIAN"),
        isAdmin: hasRole("ADMIN"),
        isOM: hasRole("OPERATIONS-MANAGER"),
    };

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: (cell) => {
            const canDelete =
                status !== "PENDING_CONFIRMATION" &&
                hasRoleCollection?.isTermContractor;

            const canEditDetails =
                status !== "PENDING_CONFIRMATION" &&
                (hasRoleCollection?.isTermContractor ||
                    hasRoleCollection?.isTechnician ||
                    hasRoleCollection?.isAdmin) &&
                (cell.row.original?.status.toUpperCase() === "DRAFT" ||
                    cell.row.original?.status.toUpperCase() === "REJECTED");

            let downloadUrl = constructReport(
                cell.row.original.file_info?.bucket,
                cell.row.original.file_info?.file_path,
                `${cell.row.original?.school?.name}-${cell.row.original?.inspection_category}-${cell.row.original?.inspected_at}`,
                false
            );

            let previewUrl = constructReport(
                cell.row.original.file_info?.bucket,
                cell.row.original.file_info?.file_path,
                `${cell.row.original?.school?.name}-${cell.row.original?.inspection_category}-${cell.row.original?.inspected_at}`,
                true
            );

            return (
                <div>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {status === "PENDING_CONFIRMATION"
                                    ? "View"
                                    : "Edit"}{" "}
                                checklist
                            </Tooltip>
                        }
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={
                                    status === "PENDING_CONFIRMATION"
                                        ? faEye
                                        : faPen
                                }
                                className="align-middle me-1"
                                onClick={() =>
                                    navigate(
                                        pathname + "/" + cell.row.original.id,
                                        {
                                            state: {
                                                previousSearchValue: filter,
                                            },
                                        }
                                    )
                                }
                                size="lg"
                            />
                        </span>
                    </OverlayTrigger>
                    {canDelete && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete checklist</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        openDeleteModal(cell.row.original.id);
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                    {canEditDetails && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit details</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faList}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        setModalEditDetails({
                                            id: cell.row.original.id,
                                            open: true,
                                            severity: "primary",
                                            schools: schools,
                                            selectedSchool:
                                                cell?.row?.original?.school,
                                            selectedLocation:
                                                cell?.row?.original?.location,
                                        });
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}

                    {cell.row.original.file_info?.bucket &&
                    cell.row.original.file_info?.file_path ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Download service report</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        downloadServiceReport(downloadUrl);
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    ) : (
                        ""
                    )}

                    {cell.row.original.file_info?.bucket &&
                    cell.row.original.file_info?.file_path ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Preview service report</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        downloadServiceReport(previewUrl);
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    ) : (
                        ""
                    )}
                    {(hasRoleCollection.isTermContractor ||
                        hasRoleCollection?.isAdmin) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Regenerate report</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faRecycle}
                                    className="align-middle me-1"
                                    onClick={() =>
                                        setModalConfirmation({
                                            notifMsg:
                                                "Are you sure you want to generate this report?",
                                            subNotifMsg:
                                                "Note: Report will take an estimate of 3 minutes to be generated.",
                                            open: true,
                                            api: () =>
                                                regenerateReportApi(
                                                    cell?.row?.original?.id
                                                ),
                                        })
                                    }
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                </div>
            );
        },
    };

    //
    // Functions
    //
    const getInspectionChecklists = useCallback(
        async (param = null) => {
            try {
                if (!schools.length) return;

                if (!filter.isNewFilter) {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);

                    // Main params from url
                    const statusParam = urlParams.get("status");
                    const schoolParam = urlParams.get("school");
                    const inspectionTypeParam =
                        urlParams.get("inspection_type");
                    const categoryParam = urlParams.get("category");
                    const startDateParam = urlParams.get("start_date");
                    const endDateParam = urlParams.get("end_date");
                    const checklistNameParam = urlParams.get("checklist_name");

                    // Parsing of params
                    const schoolParse = schools?.find(
                        (o) => o?.label === schoolParam
                    );
                    const statusMap = {
                        PENDINGTC: ["DRAFT", "REJECTED"],
                    };
                    const statusParamUpper = statusParam?.toUpperCase();
                    const foundStatus = status_options.find(
                        (o) => o?.label?.toUpperCase() === statusParamUpper
                    );
                    const statusParse = foundStatus
                        ? [foundStatus.value]
                        : statusMap[statusParamUpper] || "";

                    const pendingTCStatusParam = [
                        { value: "DRAFT", label: "Draft" },
                        { value: "REJECTED", label: "Rejected" },
                    ];

                    // Mapping to filter state
                    filter.status = statusParse ? statusParse : "";
                    filter.school_slug = schoolParse
                        ? [schoolParse?.value]
                        : "";
                    filter.inspection_type = inspectionTypeParam;
                    filter.inspection_category = categoryParam
                        ? categoryParam.toUpperCase()
                        : "";
                    filter.inspected_at_start = startDateParam
                        ? new Date(startDateParam)
                        : "";
                    filter.inspected_at_end = endDateParam
                        ? new Date(endDateParam)
                        : "";
                    filter.checklist_name = checklistNameParam || "";

                    reset({
                        status:
                            statusParam === "pendingTC"
                                ? pendingTCStatusParam
                                : statusParam
                                ? [
                                      status_options?.find(
                                          (o) => o?.value === statusParam
                                      ),
                                  ]
                                : "",
                        school: schoolParam
                            ? [schools?.find((o) => o?.label === schoolParam)]
                            : hasRoleCollection.isOM && user.school_id
                            ? [
                                  {
                                      label: user?.school?.details?.school_name,
                                      value: user?.school_id,
                                  },
                              ]
                            : "",
                        inspection_type: inspectionTypeParam
                            ? [
                                  checklist_type?.find(
                                      (o) => o?.value === inspectionTypeParam
                                  ),
                              ]
                            : "",
                        inspection_category: categoryParam
                            ? checklist_category.find(
                                  (o) => o.value === categoryParam.toUpperCase()
                              )
                            : "",
                        inspection_date_start: startDateParam
                            ? new Date(startDateParam)
                            : "",
                        inspection_date_end: startDateParam
                            ? new Date(endDateParam)
                            : "",
                        checklist_name: checklistNameParam || "",
                    });
                }

                setIsLoadingActive(true);
                const response =
                    await inspectionChecklistApi.getInspectionChecklists({
                        ...param,
                        ...filter,
                    });
                if (response.status === 200) {
                    const data = response.data;
                    setInspectionChecklist(data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);

                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, filter, setIsLoadingActive, schools]
    );

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    const deleteDefect = useCallback(
        async (id) => {
            try {
                const response =
                    await inspectionChecklistApi.deleteInspectionChecklist(id);
                if (response.status === 200) {
                    notyf.open({
                        type: "success",
                        message: response.data.message,
                    });
                    setRerender(!rerender);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, rerender]
    );

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            if (response.status === 200) {
                const data = response.data.data;
                let temp = {};
                temp = data.map((school) => {
                    return {
                        value: school?.id,
                        label: school?.name,
                    };
                });
                setSchools(temp);

                if (state?.previousSearchValue) {
                    resetFilter(temp);
                }
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const resetFilter = (temp) => {
        const previousSearchValue = state?.previousSearchValue;

        reset({
            school:
                state?.previousSearchValue?.school_slug &&
                temp?.filter((item) =>
                    state?.previousSearchValue?.school_slug?.includes(
                        item?.value
                    )
                ),
            inspection_type:
                previousSearchValue?.inspection_type &&
                checklist_type.find(
                    (o) => o.value === previousSearchValue?.inspection_type
                ),
            inspection_category:
                previousSearchValue?.inspection_category &&
                checklist_category.find(
                    (o) => o.value === previousSearchValue?.inspection_category
                ),
            frequency:
                previousSearchValue?.frequency &&
                checklist_frequency.find(
                    (o) => o.value === previousSearchValue?.frequency
                ),
            status:
                previousSearchValue?.status &&
                status_options.find(
                    (o) => o.value === previousSearchValue?.status
                ),
            inspection_date_start:
                previousSearchValue?.inspected_at_start || "",
            inspection_date_end: previousSearchValue?.inspected_at_end || "",
        });
    };

    const searchInspection = useCallback(async (data) => {
        let schoolSlugs = [];
        let status = [];

        data?.school !== "" &&
            data?.school?.forEach((school) => {
                schoolSlugs.push(school?.value);
            });
        data?.status &&
            data?.status?.forEach((statusData) => {
                status.push(statusData?.value);
            });

        setFilter({
            isNewFilter: true,
            school_slug: schoolSlugs,
            inspection_category: data?.inspection_category?.value,
            inspection_type: data?.inspection_type?.value,
            inspected_at_start: data?.inspection_date_start,
            inspected_at_end: data?.inspection_date_end,
            frequency: data?.frequency?.value,
            status: status,
            checklist_name: data?.checklist_name,
        });
    }, []);

    const downloadServiceReport = (attachments) => {
        window.open(attachments, "Download");
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response =
                    await inspectionChecklistApi.getInspectionChecklistConfig(
                        param
                    );
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const regenerateReportApi = async (id) => {
        try {
            setIsLoadingActive(true);
            const response = await inspectionChecklistApi.regenerateReport(id);

            if (response.status === 200) {
                setRerender(!rerender);
                setIsLoadingActive(false);

                notyf.open({
                    type: "success",
                    message:
                        "Successfully regenerated report. Please wait for 3 minutes.",
                });
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: error,
            });
        }
    };

    //
    // UseEffects
    //
    useEffect(() => {
        getInspectionChecklists();
        getConfig();
    }, [getInspectionChecklists, rerender, getConfig]);

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    return (
        <React.Fragment>
            <Helmet title="Inspection checklist" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Inspection & servicing</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Form>
                                <Col md={12} sm={12} xs={12}>
                                    <Row>
                                        <Col md={4} sm={12} xs={12}>
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="school"
                                                render={({
                                                    field: {
                                                        value,
                                                        onChange,
                                                        ref,
                                                    },
                                                }) => (
                                                    <Select
                                                        inputRef={ref}
                                                        className={`${
                                                            isMobile && "w-100"
                                                        }`}
                                                        classNamePrefix="react-select "
                                                        options={schools}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        isMulti
                                                        value={value}
                                                        onChange={onChange}
                                                        placeholder="Select school"
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={4} sm={12} xs={12}>
                                            <Controller
                                                control={control}
                                                name="inspection_type"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Select
                                                        className={`${
                                                            isMobile &&
                                                            "w-100 mt-2"
                                                        }`}
                                                        classNamePrefix="react-select "
                                                        options={checklist_type}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={onChange}
                                                        placeholder="Select type"
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={4} sm={12} xs={12}>
                                            <Controller
                                                control={control}
                                                name="inspection_category"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Select
                                                        className={`${
                                                            isMobile &&
                                                            "w-100 mt-2"
                                                        }`}
                                                        classNamePrefix="react-select "
                                                        options={
                                                            checklist_category
                                                        }
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={onChange}
                                                        placeholder="Select category"
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={4} sm={12} xs={12}>
                                            <Controller
                                                control={control}
                                                name="frequency"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Select
                                                        className={`mt-2 ${
                                                            isMobile && "w-100"
                                                        }`}
                                                        classNamePrefix="react-select "
                                                        options={
                                                            checklist_frequency
                                                        }
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={onChange}
                                                        placeholder="Select frequency"
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Col>

                                        <Col md={4} sm={12} xs={12}>
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="inspection_date_start"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={value}
                                                        onChange={onChange}
                                                        className={`form-box form-control mt-2 ${
                                                            isMobile && "w-100"
                                                        }`}
                                                        placeholderText="Start date"
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={4} sm={12} xs={12}>
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="inspection_date_end"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={value}
                                                        onChange={onChange}
                                                        className={`form-box form-control form-box mt-2 ${
                                                            isMobile && "w-100"
                                                        }`}
                                                        placeholderText="End date"
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={4} sm={12} xs={12}>
                                            <Controller
                                                control={control}
                                                name="status"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Select
                                                        isClearable={true}
                                                        onChange={onChange}
                                                        options={status_options}
                                                        placeholder="Select status"
                                                        className={`mt-2 ${
                                                            isMobile && "w-100"
                                                        }`}
                                                        value={value}
                                                        isMulti
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={4} sm={3} xs={3}>
                                            <Controller
                                                control={control}
                                                name="checklist_name"
                                                defaultValue=""
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Form.Control
                                                        type="text"
                                                        value={value}
                                                        className={`form-box form-control form-box mt-2 ${
                                                            isMobile && "w-100"
                                                        }`}
                                                        onChange={onChange}
                                                        placeholder="Checklist name"
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={2} sm={12} xs={12}>
                                            <Button
                                                variant="primary"
                                                className={`me-1 mb-1 form-box mt-2 ${
                                                    isMobile && "w-100"
                                                }`}
                                                onClick={handleSubmit(
                                                    searchInspection
                                                )}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                />
                                            </Button>
                                            <Button
                                                variant="primary"
                                                className={`me-1 mb-1 form-box mt-2 ${
                                                    isMobile && "w-100"
                                                }`}
                                                onClick={() => {
                                                    reset({
                                                        school: "",
                                                        inspection_type: "",
                                                        inspection_category: "",
                                                        frequency: "",
                                                        status: "",
                                                        inspection_date_start:
                                                            "",
                                                        inspection_date_end: "",
                                                    });
                                                    setFilter({});
                                                    window.history.replaceState(
                                                        null,
                                                        ""
                                                    );
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEraser}
                                                />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Form>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive &&
                            configColumn &&
                            inspectionChecklist.data && (
                                <DynamicTable
                                    dataPagination={inspectionChecklist}
                                    columns={formatConfigFields(
                                        configColumn,
                                        actionField
                                    )}
                                    api={getInspectionChecklists}
                                    searchValue={filter}
                                />
                            )}

                        <DeleteModal
                            modalInfo={modalInfo}
                            setModalInfo={setModalInfo}
                            api={deleteDefect}
                        />
                        <EditDetailsModal
                            modalInfo={modalEditDetails}
                            setModalInfo={setModalEditDetails}
                        />

                        <ConfirmationModal
                            modalInfo={modalConfirmation}
                            setModalInfo={setModalConfirmation}
                        />
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

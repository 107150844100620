import React, { useState, useContext } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Breadcrumb,
  Accordion,
  ListGroup,
  Form,
  Alert,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { LoadingContext } from "App";

import NotyfContext from "contexts/NotyfContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import useAuth from "../../../hooks/useAuth";
import { errorMessage } from "utils/utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";

import * as inspectionChecklistApi from "@api/inspectionChecklistApi";
import { debounce } from 'lodash';
import { ErrorMessage } from "@hookform/error-message";
export const InspectionForm = ({ status = false }) => {
  const baseUrlApi = "https://moe-ecms-api.intellisolution.tech";
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { hasRole } = useAuth();
  const [isMobile, setIsMobile] = useState(false);
  const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
  const [inspectionChecklist, setInspectionChecklist] = useState([]);
  const [rawInspectedAt, setRawInspectedAt] = useState("");
  const [rawSignatories, setRawSignatories] = useState({});
  const [school, setSchool] = useState({});
  const [rawUser, setRawUser] = useState({});
  const [rawSignatureRemarks, setRawSignatureRemarks] = useState("");
  const { state, pathname } = useLocation();
  const [defaultActiveKeys, setDefaultActiveKeys] = useState([]);
  const [modalConfirmation, setModalConfirmation] = useState({
    notifMsg: "",
    open: false,
    api: "",
  });

  let schema = yup.object().shape({
    inspected_at: yup.string().required("This field is required"),
  });

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const hasRoleCollection = {
    isManagingAgent: hasRole("MANAGING-AGENT"),
    isOM: hasRole("OPERATIONS-MANAGER"),
    isSCD: hasRole("SCD"),
    isTechnian: hasRole("TECHNICIAN"),
    isTermContractor: hasRole("TERM-CONTRACTOR"),
    isTermContractorQs: hasRole("TERM-CONTRACTOR-QS"),
    isAdmin: hasRole("ADMIN"),
    isHorticulturist: hasRole("HORTICULTURIST"),
    isArboristSouth: hasRole("ARBORIST-SOUTH"),
    isArboristNorthEast: hasRole("ARBORIST-NORTH-EAST"),
  };

  const canSubmitInspection =
    hasRoleCollection?.isTermContractor ||
    hasRoleCollection?.isTermContractorQs ||
    hasRoleCollection?.isTechnian ||
    hasRoleCollection?.isAdmin ||
    hasRoleCollection?.isHorticulturist ||
    hasRoleCollection?.isArboristSouth ||
    hasRoleCollection?.isArboristNorthEast
      ? true
      : false;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const updateInspectionSchedule = async (
    data,
    index = null,
    index2 = null
  ) => {
    try {
      const finalParams = {};

      if (index !== null && index2 !== null) {
        finalParams.checklist_items = [
          index2 !== undefined
            ? data?.checklist_items[index]?.checklist_sub_items[index2]
            : data?.checklist_items[index],
        ];
      }

      finalParams.checklist_id = data?.checklist_id;
      finalParams.remarks = data?.remarks;
      finalParams.inspected_at = new Date(data.inspected_at).toLocaleDateString(
        "en-CA"
      );

      if (pathname.includes("inspection-servicing-form")) {
        finalParams.is_form = true;
        
         // Find the signatory with status "SUBMITTED"
        const submittedSignatoryKey = Object.keys(rawSignatories).find(key => rawSignatories[key].status === "SUBMITTED");
        if (submittedSignatoryKey) {
          const submittedSignatory = rawSignatories[submittedSignatoryKey];
          const signatureRemarks = data.signatory_remarks.replace(/<[^>]*>/g, '').trim();

          if(!data.signatories || data.signatories === ''){
            setError("signatories", { type: "manual", message: "Signature Date is required."});
            notyf.open({ type: "danger", message: 'Signature Date is required.' });
            return
          }
          //optional remarks for NORTH EAST
          if((!signatureRemarks || signatureRemarks === '' || signatureRemarks === null) && school.zone !== 'NORTH_EAST') {
            setError("signatory_remarks", { type: "manual", message: "Signatory Remarks is required when updating this form" });
            notyf.open({ type: "danger", message: 'Signatory Remarks is required when updating this form' });
            return;
          }
          
          const selectedDate = new Date(data.signatories.getTime() - (data.signatories.getTimezoneOffset() * 60000)).toISOString();
          // Create a copy of rawSignatories and update the created_at property of the submitted signatory
          const updatedSignatories = {
            ...rawSignatories,
            [submittedSignatoryKey]: {
              ...rawSignatories[submittedSignatoryKey],
              created_at: selectedDate,
              remarks: signatureRemarks
            }
          };

          finalParams.signatories = updatedSignatories;
        }
      }

      const response = await inspectionChecklistApi.updateInspectionChecklist(
        id,
        finalParams
      );

      if (response.status === 200) {
        notyf.open({
          type: "success",
          message: "Checklist successfully updated",
        });
        if(pathname.includes("inspection-servicing-form")){
          setRawSignatories(finalParams.signatories)
        }
      }
    } catch (error) {
      notyf.open({
        type: "danger",
        message: error?.response?.data?.message,
      });
    }
  };

  const getInspectionChecklist = useCallback(async () => {
    try {
      const response = await inspectionChecklistApi.getInspectionChecklist(id);

      if (response.status === 200) {
        setIsLoadingActive(false);
        const data = response?.data?.data;
        setRawInspectedAt(data.inspected_at);
        setRawSignatories(data.signatories)
        setRawUser(data.signatories_user);
        setSchool(data.school);

        data.inspected_at =
          response.data.data.inspected_at !== null
            ? new Date(response.data.data.inspected_at)
            : new Date();

        const submittedSignatory = response.data.data.signatories.find(
          (item) => item.status === "SUBMITTED"
        );

        if(submittedSignatory) {
          data.signatories = submittedSignatory?.created_at ? new Date(response.data.data.signatories
            .find(item => item.status === "SUBMITTED").created_at) : null;

          data.signatory_remarks = submittedSignatory.remarks ?? ''
          setRawSignatureRemarks(data.signatory_remarks)
        }
       
        const checklistItems = data?.checklist_items;
        const iterator = checklistItems.keys();
        for (const key of iterator) {
          setDefaultActiveKeys(defaultActiveKeys.push(key));
        }

        reset(data);
        setInspectionChecklist(data);
      }
    } catch (error) {
      setIsLoadingActive(false);
      notyf.open({
        type: "danger",
        message: error?.response?.data?.message,
      });
    }
  }, [id, notyf]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    getInspectionChecklist();
  }, [getInspectionChecklist]);

  return (
    <React.Fragment>
      <Helmet
        title={inspectionChecklist?.school?.name + " | Inspection checklist"}
      />
      <Row style={{ marginBottom: "20px" }}>
        <Col md={6}>
          <h3>Inspection & Servicing</h3>
        </Col>
        <Col md={6}>
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                navigate(
                  `/inspection-checklist/inspection-servicing${
                    status === "PENDING_CONFIRMATION" ? "-pending" : ""
                  }`,
                  {
                    state: {
                      previousSearchValue: state?.previousSearchValue,
                    },
                  }
                );
              }}
            >
              Inspection & Servicings
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {inspectionChecklist?.school?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Container fluid className="p-0">
        <Card className="p-4">
          <Row style={{ marginBottom: "20px" }}>
            <Col md={6}>
              <h4 className="h4 mb-3">{inspectionChecklist?.school?.name}</h4>
            </Col>
            <Col md={4} style={{ textAlign: "right" }}></Col>
            <Col md={2} style={{ textAlign: "right" }}>
              <Form.Group>
                <Form.Label className="font-weight-bold" htmlFor="remarks">
                  Inspection date
                </Form.Label>
                <Controller
                  control={control}
                  name="inspected_at"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={value}
                        onChange={(data) => {
                          setValue(`inspected_at`, data);
                          clearErrors("inspected_at");
                          trigger().then((isValid) => {
                            if (!isValid) {
                              setError("inspected_at", {
                                type: "manual",
                                message: "Inspection date is required.",
                              });
                            } else {
                              updateInspectionSchedule(getValues());
                            }
                          });
                        
                        }}
                        className={`form-control form-box ${
                          errors.inspected_at && "is-invalid"
                        }`}
                        value={value}
                        placeholderText="Inspection date"
                      />
                    </>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          {inspectionChecklist?.status === "REJECTED" && (
            <Row>
              <Col>
                <Alert key="danger" variant="danger" className="alert-message">
                  <h5>
                    {" "}
                    Rejection remarks:{" "}
                    <strong> {inspectionChecklist.remarks || "-"}</strong>
                  </h5>
                </Alert>
              </Col>
            </Row>
          )}
          {!isMobile && (
            <Row>
              <Col md={2}>
                <h5>Elements/Facilities</h5>
              </Col>
              <Col md={3}>
                <h5>Defects to check / observations</h5>
              </Col>
              <Col md={2}>
                <h5>Frequency</h5>
              </Col>
              <Col md={1}>
                <h5>Checked</h5>
              </Col>
              <Col md={2}>
                <h5>Remarks</h5>
              </Col>
              <Col md={1}>
                <h5>Details</h5>
              </Col>
            </Row>
          )}
          <Card className="p-0">
            <Accordion defaultActiveKey={defaultActiveKeys} alwaysOpen>
              {inspectionChecklist?.checklist_items?.map(
                (checklistItem, index) => {
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        {checklistItem?.element}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        {checklistItem?.checklist_sub_items &&
                          checklistItem?.checklist_sub_items?.map(
                            (checklistSubItem, index2) => {
                              if (checklistSubItem?.header === true) {
                                return (
                                  <Row
                                    style={{
                                      padding: "5px",
                                    }}
                                  >
                                    <Col>
                                      <div
                                        style={{
                                          paddingLeft:
                                            checklistSubItem?.serial_no
                                              ?.length +
                                            5 +
                                            "px",
                                        }}
                                      >
                                        {checklistSubItem?.element}
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              } else {
                                return (
                                  <ListGroup key={index2}>
                                    <ListGroup.Item>
                                      <Row>
                                        <Col
                                          md={
                                            checklistSubItem?.description !==
                                            null
                                              ? 2
                                              : 5
                                          }
                                        >
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="element"
                                                className="text-sm text-muted"
                                              >
                                                Elements/Facilities
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          <div>{checklistSubItem?.element}</div>
                                        </Col>
                                        {checklistSubItem?.description !==
                                          null && (
                                          <Col md={3}>
                                            {isMobile && (
                                              <Form.Group>
                                                <Form.Label
                                                  htmlFor="description"
                                                  className="text-sm text-muted mt-3"
                                                >
                                                  Defects to check /
                                                  observations
                                                </Form.Label>
                                              </Form.Group>
                                            )}
                                            {checklistSubItem?.description !==
                                            null
                                              ? checklistSubItem.description
                                                  .split("\\n")
                                                  .map((i, key) => {
                                                    return (
                                                      <div key={key}>{i}</div>
                                                    );
                                                  })
                                              : ""}
                                          </Col>
                                        )}

                                        <Col md={2}>
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="frequency"
                                                className="text-sm text-muted mt-3"
                                              >
                                                Frequency
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          {checklistSubItem?.frequency !==
                                          "NULL"
                                            ? checklistSubItem.frequency
                                            : ""}
                                        </Col>
                                        <Col md={1}>
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="checked"
                                                className="text-sm text-muted mt-3"
                                              >
                                                Checked
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          <Controller
                                            control={control}
                                            defaultValue=""
                                            name={`checklist_items[${index}].checklist_sub_items[${index2}].status`}
                                            render={({
                                              field: { onChange, value },
                                            }) => {
                                              return (
                                                <>
                                                  <Form.Check
                                                    label="Yes"
                                                    type="radio"
                                                    onChange={(e) => {
                                                      setValue(
                                                        `checklist_items[${index}].checklist_sub_items[${index2}].status`,
                                                        e.target.value
                                                      );
                                                      updateInspectionSchedule(
                                                        getValues(),
                                                        index,
                                                        index2
                                                      );
                                                    }}
                                                    value="yes"
                                                    checked={value === "yes"}
                                                  />
                                                  {inspectionChecklist?.school
                                                    ?.zone === "SOUTH" && (
                                                    <Form.Check
                                                      label="No (For follow up inspection)"
                                                      type="radio"
                                                      onChange={(e) => {
                                                        setValue(
                                                          `checklist_items[${index}].checklist_sub_items[${index2}].status`,
                                                          e.target.value
                                                        );
                                                        updateInspectionSchedule(
                                                          getValues(),
                                                          index,
                                                          index2
                                                        );
                                                      }}
                                                      value="no"
                                                      checked={value === "no"}
                                                    />
                                                  )}
                                                  <Form.Check
                                                    label="N/A"
                                                    type="radio"
                                                    onChange={(e) => {
                                                      setValue(
                                                        `checklist_items[${index}].checklist_sub_items[${index2}].status`,
                                                        e.target.value
                                                      );
                                                      updateInspectionSchedule(
                                                        getValues(),
                                                        index,
                                                        index2
                                                      );
                                                    }}
                                                    value={"n/a" || null}
                                                    checked={
                                                      value === "n/a" ||
                                                      value === null
                                                    }
                                                  />
                                                </>
                                              );
                                            }}
                                          />
                                        </Col>
                                        <Col md={2}>
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="remarks"
                                                className="text-sm text-muted mt-3"
                                              >
                                                Remarks
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          <Controller
                                            control={control}
                                            defaultValue=""
                                            name={`checklist_items[${index}].checklist_sub_items[${index2}].remarks`}
                                            render={({
                                              field: {
                                                onChange,
                                                value,
                                                onBlur,
                                              },
                                            }) => {
                                              return (
                                                <>
                                                  <AutoGrowTextarea
                                                    onBlur={() =>
                                                      updateInspectionSchedule(
                                                        getValues(),
                                                        index,
                                                        index2
                                                      )
                                                    }
                                                    onChange={onChange}
                                                    fieldValue={value || ""}
                                                  />
                                                </>
                                              );
                                            }}
                                          />
                                        </Col>
                                        <Col md={2}>
                                          {isMobile && (
                                            <Form.Group>
                                              <Form.Label
                                                htmlFor="details"
                                                className="text-sm text-muted mt-3"
                                              >
                                                Details
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          {checklistSubItem?.attachments
                                            ?.length ||
                                          checklistSubItem?.hasDefect ? (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>View evidence</Tooltip>
                                              }
                                            >
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={faEye}
                                                  size="lg"
                                                  className="align-middle me-1 cursor-pointer eye-icon"
                                                  onClick={() =>
                                                    navigate(
                                                      `/inspection-checklist/inspection-servicing/${id}/${checklistItem?.id}/${checklistSubItem?.id}/view-evidence`
                                                    )
                                                  }
                                                />
                                              </span>
                                            </OverlayTrigger>
                                          ) : (
                                            ""
                                          )}

                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>Add evidence</Tooltip>
                                            }
                                          >
                                            <span>
                                              <FontAwesomeIcon
                                                icon={faPlus}
                                                size="lg"
                                                className="align-middle me-1 cursor-pointer"
                                                onClick={() =>
                                                  navigate(
                                                    `/inspection-checklist/inspection-servicing/${id}/${checklistItem?.id}/${checklistSubItem?.id}/create-evidence`,
                                                    {
                                                      state: {
                                                        previousSearchValue:
                                                          state?.previousSearchValue,
                                                      },
                                                    }
                                                  )
                                                }
                                              />
                                            </span>
                                          </OverlayTrigger>
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  </ListGroup>
                                );
                              }
                            }
                          )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
              )}
            </Accordion>
            {canSubmitInspection && pathname.includes("inspection-servicing-form") && (
                <>
                  {Object.values(rawUser).map((signatory) => {
                    if (signatory.status === "SUBMITTED") {
                      return (
                        <>
                        <Row key={signatory.id} style={{  marginTop: "70px", marginLeft: "40px" }}>
                        <h3 style={{marginLeft: "-30px"}}>Signature Details</h3>
                          <Col md={3} style={{ textAlign: "left" }}>
                            <Form.Label className="font-weight-bold" htmlFor="remarks">
                              Submitted by
                            </Form.Label>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={signatory.created_by}
                                disabled
                                className="form-control form-box"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row key={signatory.id} style={{ marginTop: "10px", marginLeft: "40px" }}>
                          <Col md={3} style={{ textAlign: "left" }}>
                            <Form.Label className="font-weight-bold" htmlFor="remarks">
                              Status
                            </Form.Label>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                value={signatory.status}
                                disabled
                                className="form-control form-box"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        </>
                      );
                    } else {
                      return null; // Do not render for other statuses
                    }
                  })}
                  {Object.values(rawUser).some(signatory => signatory.status === "SUBMITTED") && (
                    <>
                      <Row style={{ marginTop: "10px", marginLeft: "40px" }}>
                        <Col md={3} style={{ textAlign: "left" }}>
                          <Form.Group>
                            <Form.Label className="font-weight-bold" htmlFor="remarks">
                              Signature Date
                            </Form.Label>
                            <Controller
                              control={control}
                              name="signatories"
                              render={({ field: { value, onChange, onBlur } }) => (
                                <>
                                  <DatePicker
                                    showTimeSelect
                                    showTimeInput
                                    dateFormat="yyyy-MM-dd h:mm aa"
                                    selected={value}
                                    onChange={debounce((data) => {
                                      setValue(`signatories`, data);
                                      clearErrors("signatories");
                                      updateInspectionSchedule(getValues());
                                    }, 1000)} 
                                    className={`form-control form-box ${
                                      errors.signatories && "is-invalid"
                                    }`}
                                    value={value}
                                    placeholderText="Signatory Date"
                                  />
                                </>
                              )}
                            />
                          </Form.Group>
                          <ErrorMessage
                            errors={errors}
                            name="signatories"
                            render={({ message }) => errorMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "10px", marginLeft: "40px" }}>
                        <Col md={3} style={{ textAlign: "left" }}>
                          <Form.Group>
                            <Form.Label className="font-weight-bold" htmlFor="signatory_remarks">
                              Remarks
                            </Form.Label>
                            <Controller
                              control={control}
                              name="signatory_remarks"
                              render={({ field: { value, onChange, onBlur } }) => (
                                <AutoGrowTextarea
                                  fieldValue={value}
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                  }}
                                  onBlur={(e) => {
                                    setValue('signatory_remarks', e.target.value);
                                    clearErrors("signatory_remarks");
                                    updateInspectionSchedule(getValues());
                                  }}
                                  className={`form-control form-box ${
                                    errors.signatory_remarks && "is-invalid"
                                  }`}
                                />
                              )}
                            />
                          </Form.Group>
                          <ErrorMessage
                            errors={errors}
                            name="signatory_remarks"
                            render={({ message }) => errorMessage(message)}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
          </Card>
          <Row className="my-3">
            <Col md={12} className="text-center">
              {/* {canSubmitInspection && (
                                    <>
                                        <Button
                                            className="me-2"
                                            variant="primary"
                                            type="submit"
                                            onClick={handleSubmit((data) =>
                                                setModalConfirmation({
                                                    notifMsg:
                                                        "Are you sure you want to submit this checklist?",
                                                    open: true,
                                                    api: () =>
                                                        save(
                                                            data,
                                                            "SUBMITTED"
                                                        ),
                                                })
                                            )}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            className="me-2 btn-secondary"
                                            variant="primary"
                                            type="submit"
                                            onClick={(data) =>
                                                save(data, "DRAFT")
                                            }
                                        >
                                            Draft
                                        </Button>
                                    </>
                                )} */}

              <Button
                variant="link"
                onClick={() =>
                  navigate(
                    `/inspection-checklist/inspection-servicing${
                      status === "PENDING_CONFIRMATION" ? "-pending" : ""
                    }`,
                    {
                      state: {
                        previousSearchValue: state?.previousSearchValue,
                      },
                    }
                  )
                }
              >
                Cancel
              </Button>

              {inspectionChecklist?.report_url ? (
                <Button
                  variant="link"
                  onClick={() => window.open(inspectionChecklist?.report_url)}
                >
                  Preview
                </Button>
              ) : (
                <a
                  href={`${baseUrlApi}/preview/inspection/${id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-link pe-1"
                >
                  <Button variant="link">Preview</Button>
                </a>
              )}
            </Col>
          </Row>
        </Card>
      </Container>
    </React.Fragment>
  );
};

import React from "react";

import {
    Button,
    Col,
    Container,
    Form,
    Image,
    Modal,
    Row,
} from "react-bootstrap";

export const ViewImageModal = ({ modalInfo, setModalInfo }) => {
    //
    // Functions
    //

    const closeModal = () => {
        setModalInfo({ open: false });
    };

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "add" })}
            size="md"
            centered
        >
            <Modal.Header closeButton>{modalInfo.label}</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Image
                                    src={modalInfo.image}
                                    className="preview-modal signature-container"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

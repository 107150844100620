import React, { useCallback, useContext, useEffect, useState } from "react";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Helmet } from "react-helmet-async";
import {
    Alert,
    Badge,
    Button,
    Card,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import useLocalStorage from "hooks/useLocalStorage";
import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import DynamicTable from "components/ui/DynamicTable";
import { DefectsListForm } from "./sub-components/DefectsListForm";
import { QuotationDetailsForm } from "./sub-components/QuotationDetailsForm";
import { SignaturesForm } from "components/ui/SignatureForm";
import { OtherDetails } from "components/common/works-order/OtherDetails";
import { QuotationItemsForm } from "./sub-components/QuotationItemsForm";
import { DeleteModal } from "components/DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { status } from "config";
import { LoadingContext } from "App";
import { AlertModal } from "components/AlertModal";
import {
    calculateQuotationItemAmounts,
    constructReport,
    roundToTwoDecimal,
} from "utils/utilities";
import * as quotationsApi from "@api/quotationsApi";
import * as attachmentApi from "@api/attachmentApi";

export const QuotationForm = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const create = action === "create";
    const notyf = useContext(NotyfContext);
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const { hasRole, user, config } = useAuth();

    const hasRoleCollection = {
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isQS: hasRole("MA-QS"),
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isAdmin: hasRole("ADMIN"),
    };

    let schema = yup.object().shape({
        contacts: yup.object().shape({
            tel: yup.string().required("This field is required"),
            attention: yup.string().required("This field is required"),
        }),
        date: yup.string().required("This field is required"),
        job: yup.string().required("This field is required"),
        school: yup.mixed().required("This field is required").nullable(),
        trade: yup.mixed().required("This field is required").nullable(),
        job_type: yup
            .array()
            .min(1, "This field is required")
            .required("This field is required")
            .nullable(),
    });

    const signatureDetailsSchema = {
        status_remarks: yup.string().when("status", {
            is: "REJECTED",
            then: yup.string().required("This field is required"),
        }),
    };

    const tcSchema = yup.object().shape({
        prepare_details: yup.object().shape({}),
    });

    const maSchema = yup.object().shape({
        ma_details: yup.object().shape(signatureDetailsSchema),
    });

    switch (true) {
        case hasRoleCollection.isTermContractor:
            schema = schema.concat(tcSchema);
            break;
        case hasRoleCollection.isManagingAgent:
            schema = maSchema;
            break;
        default:
            break;
    }

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        watch,
        getValues,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    const defects = watch("defects", "Yes");
    const maStatus = watch("ma_details.status", "");
    const tcSignatureOption = watch("prepare_details.select_signature", "");
    const maSignatureOption = watch("ma_details.select_signature", "");

    //
    // Local storage
    //

    const [quotationItemsStorage, setQuotationItemsStorage] = useLocalStorage(
        "quotationItems",
        []
    );
    const [quotationDetailsStorage, setQuotationDetailsStorage] =
        useLocalStorage("quotationDetails", []);

    //
    // States
    //

    const [rerender, setRerender] = useState(false);
    const [quotationData, setQuotationData] = useState({});
    const [finalTotal, setFinalTotal] = useState({});
    const [active, setActive] = useState([]);
    const [rates, setRates] = useState({ less_percent: 31, star_rate: 5 });
    const [supportingDocuments, setSupportingDocuments] = useState([]);
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    let isDesktop = width > 1400;

    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [modalInfoCustom, setModalInfoCustom] = useState({
        notifMsg: "",
        open: false,
    });

    const tableColumns = [
        {
            Header: () => <div className="text-end">Actions</div>,
            accessor: "actions",
        },
        {
            accessor: "serial_no",
            Header: () => <div className="text-end">S/N</div>,
            width: isDesktop ? 60 : 120,
        },
        {
            accessor: "item_number",
            Header: () => <div className="text-end">Item number</div>,
            width: isDesktop ? 100 : 120,
        },
        {
            Header: "Sub header",
            accessor: "item_group_description",
        },
        {
            Header: "Description",
            accessor: "item_description",
        },
        {
            accessor: "note",
            Header: () => <div>Note</div>,
            width: isDesktop ? 80 : 120,
        },
        {
            accessor: "quantity",
            Header: () => <div className="text-end">Quantity</div>,
            width: isDesktop ? 80 : 120,
        },
        {
            accessor: "unit",
            Header: () => <div className="text-end">Unit</div>,
            width: isDesktop ? 60 : 120,
        },
        {
            accessor: "rate",
            Header: () => <div className="text-end">Rate</div>,
            width: isDesktop ? 100 : 120,
        },
        {
            accessor: "amount",
            Header: () => <div className="text-end">Amount</div>,
            width: isDesktop ? 100 : 120,
        },
    ];

    //
    // Functions
    //

    const maApprovalApi = async ({ ma_details }) => {
        if (!ma_details.signature && maStatus !== "REJECTED") {
            notyf.open({
                type: "warning",
                message: "Please provide signature to proceed.",
            });
            return;
        }

        setIsLoadingActive(true);

        const formData = new FormData();

        const supportingDocumentsData =
            supportingDocuments?.getFilesData() || [];

        formData.append("signature_details", ma_details.signature_details);
        formData.append("signature", ma_details.signature);
        formData.append("signature_options", "my_signature");

        formData.append("source_fund", ma_details.fund);
        formData.append("status", ma_details.status);
        formData.append("remarks", ma_details.status_remarks);
        ma_details?.next_approval &&
            formData.append("next_approval", ma_details?.next_approval?.value);
        formData.append(
            "approval_level",
            quotationData?.pending_approval[user?.id]?.approval_level
        );

        supportingDocumentsData.forEach((file) => {
            formData.append("attachments[]", file.rawFile);
        });

        try {
            const response = await quotationsApi.maQuotationApproval(
                action,
                formData
            );

            if (response.status === 201 || response.status === 200) {
                cancel();
                notyf.open({
                    type: "success",
                    message: `Successfully ${
                        ma_details.status === "REJECTED"
                            ? "rejected"
                            : "verified"
                    } quotation`,
                });
                setIsLoadingActive(false);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setIsLoadingActive(false);
        }
    };

    const quotationApiCall = async (data, status) => {
        if (!user?.contractor) {
            notyf.open({
                type: "Danger",
                message: `Please contact administrator to give your account a contractor role.`,
            });
            return;
        }

        if (!quotationItemsStorage.length && status !== "DRAFT") {
            notyf.open({
                type: "warning",
                message: "Quotation items can't be empty",
            });
            return;
        }

        if (!data.prepare_details.signature && status !== "DRAFT") {
            notyf.open({
                type: "warning",
                message: "Please provide signature to proceed.",
            });
            return;
        }

        let cleanedQuotationItems = quotationItemsStorage.map(
            ({ is_focus_sort, is_focus_quantity, ...rest }) => rest
        );

        cleanedQuotationItems = catchMissingAmount(cleanedQuotationItems);

        setIsLoadingActive(true);

        const formData = new FormData();

        const supportingDocumentsData =
            supportingDocuments.getFilesData() || [];

        const contacts = JSON.stringify(data.contacts);
        const job_type = JSON.stringify(data.job_type);
        const quotation_items = JSON.stringify(cleanedQuotationItems);

        const fsr_totals = JSON.stringify({
            percent: finalTotal.less_percent,
            custodial_raw_total: finalTotal.custodial_total,
            fsr_raw_total: finalTotal.fsr_final_total,
            total: data.fsr_total,
            less: data.fsr_less,
            sub_total: data.fsr_sub_total,
        });

        const star_rate = JSON.stringify({
            percent: finalTotal.star_rate,
            total: data.star_rate_total,
            less: data.star_rate_add,
            sub_total: data.star_rate_sub_total,
        });

        formData.append("school_id", data.school.id || data.school);
        formData.append("contacts", contacts);
        formData.append(
            "date",
            new Date(data.date).toLocaleDateString("en-CA")
        );
        formData.append("job", data.job);
        job_type && formData.append("job_type", job_type);
        formData.append("remarks", data.remarks || "");
        formData.append(
            "gst",
            quotationData?.gst
        );
        formData.append("quotation_items", quotation_items);
        formData.append("status", status);
        formData.append("total_gst", data.total_gst || 0);
        formData.append("sub_total_amount", data.sub_total_amount || 0);
        formData.append("total_amount", data.total_amount || 0);
        formData.append("fsr_totals", fsr_totals);
        formData.append("star_rate", star_rate);

        supportingDocumentsData.forEach((file) => {
            formData.append("supporting_documents[]", file.rawFile);
        });

        if (status !== "DRAFT") {
            data.prepare_details.signature_details &&
                formData.append(
                    "signature_details",
                    data.prepare_details.signature_details || false
                );

            formData.append("signature_options", "my_signature");
            formData.append("signature", data.prepare_details.signature);
        }

        formData.append("contractor", user?.contractor);
        formData.append("trade", data?.trade?.value || data?.trade);

        try {
            const response =
                action === "create"
                    ? await quotationsApi.createQuotation(formData)
                    : await quotationsApi.updateQuotation(action, formData);

            if (response.status === 201 || response.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully ${
                        action === "create" ? "created" : "updated"
                    } quotation`,
                });
                cancel();
                setIsLoadingActive(false);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error.message,
            });
            setIsLoadingActive(false);
        }
    };

    const catchMissingAmount = (cleanedQuotationItems) => {
        cleanedQuotationItems.forEach((item, index, array) => {
            if (!item?.amount && !item.header) {
                array[index]["amount"] = parseFloat(
                    roundToTwoDecimal(item?.rate * item?.quantity)
                ).toFixed(2);
            }
        });

        return cleanedQuotationItems;
    };

    const getQuotation = useCallback(async () => {
        try {
            setIsLoadingActive(true);
            const response = await quotationsApi.getQuotation(action);
            if (response.status === 200) {
                const data = response.data.data;
                const storage = quotationDetailsStorage;

                setReset(data, storage);
                const YEAR = new Date(data?.date).getFullYear();
                data.gst = 7.00;
        
                if(YEAR === 2023) data.gst = 8;
                if(YEAR >= 2024) data.gst = 9;

                setQuotationData(data);

                // Check if quotation id exists in items. If true - can reuse.
                const hasQuotationId = Boolean(
                    quotationItemsStorage.length &&
                        quotationItemsStorage.every((item) => {
                            return item.quotation_id === action;
                        })
                );

                if (
                    (data.status === "DRAFT" || data.status === "REJECTED") &&
                    hasQuotationId
                ) {
                    let quotationItemsStorageClone = [...quotationItemsStorage];

                    const calculatedItems = calculateQuotationItemAmounts(
                        quotationItemsStorageClone
                    );

                    setQuotationItemsStorage(calculatedItems);
                } else {
                    setQuotationItemsStorage([]);
                    setQuotationDetailsStorage({});
                    setQuotationItemsStorage(data.quotation_items);
                }

                setIsLoadingActive(false);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setIsLoadingActive(false);
        }
        // eslint-disable-next-line
    }, [action, notyf, reset]);

    const setReset = (data, storage) => {
        const reuseSignatures = !(
            data?.status === "DRAFT" || data?.status === "REJECTED"
        );

        reset({
            school: storage.school || data.school_id,

            contacts: {
                attention: storage.attention || data.contacts.attention,
                cc: storage.cc || data.contacts.cc,
                fax: storage.fax || data.contacts.fax,
                location: storage.location || data.contacts.location,
                tel: storage.tel || data.contacts.tel,
            },

            date: storage.date ? new Date(storage.date) : new Date(data.date),
            location: storage.location || data.location,
            job: storage.job || data.job,
            job_type: storage.job_type || data.job_type,
            remarks: storage.remarks || data.remarks,
            trade: storage?.trade?.value || data.trade,

            prepare_details: {
                signature_options:
                    reuseSignatures && data.prepare_details?.signature_options,
                signature_details:
                    reuseSignatures && data.prepare_details?.signature_details,
                signature: reuseSignatures && data?.prepare_signature,
                name: reuseSignatures && data.prepare_details?.name,
            },

            ma_details: data.approvals?.MA?.details
                ? {
                      signature_options:
                          data.approvals?.MA?.details?.signature_options,
                      signature_details:
                          data.approvals?.MA?.details?.signature_details,
                      name: data.approvals?.MA?.details?.name,
                      signature: data.approvals?.MA?.signature,
                      status: data.approvals?.MA?.status,
                      fund: data.approvals?.MA?.details?.source_fund,
                      status_remarks: data.approvals?.MA?.remarks || "",
                  }
                : {
                      status: "VERIFIED",
                  },
        });
    };

    const cancel = () => {
        navigate("/works-order/quotation-list");
        setQuotationItemsStorage([]);
        setQuotationDetailsStorage({});
    };

    const saveToLocalStorage = (data) => {
        let clone = { ...quotationDetailsStorage };
        setQuotationDetailsStorage({ ...clone, ...data });
    };

    const computeGst = (date) => {
        const YEAR = new Date(date).getFullYear();
        let computedGst = 7.00;
        
        if(YEAR === 2023) computedGst = 8;
        if(YEAR >= 2024) computedGst = 9;

        setQuotationData({ ...quotationData, gst: computedGst })
    }

    const deleteApi = async (id) => {
        try {
            const response = await attachmentApi.deleteAttachment(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "Successfully deleted file",
                });
                setRerender(!rerender);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const parseAttachments = (attachments) => {
        let parsedAttachments = [];

        Object.entries(attachments).forEach(([key, value]) => {
            if (value) {
                const valueData = value.map((v) => ({
                    ...v,
                    uploader: key.toUpperCase(),
                }));
                parsedAttachments = [...parsedAttachments, ...valueData];
            }
        });

        return parsedAttachments;
    };

    const deleteAttachmentQuotation = (id) => {
        const cloneQuotationData = { ...quotationData };
        const documents = quotationData?.documents || {};

        for (let key in documents) {
            if (Array.isArray(documents[key])) {
                documents[key] = documents[key].filter(
                    (item) => item.id !== id
                );
            }
        }

        quotationData.documents = documents;
        setQuotationData(cloneQuotationData);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (action !== "create") getQuotation();
    }, [action, getQuotation, rerender]);

    useEffect(() => {
        const storage = quotationDetailsStorage;
        const defaultOption = {
            value: "new_signature",
            label: "New signature",
        };

        const storageData = [
            { name: "contacts.attention", value: storage.attention },
            { name: "contacts.cc", value: storage.cc },
            { name: "contacts.tel", value: storage.tel },
            { name: "contacts.fax", value: storage.fax },
            { name: "school", value: storage.school },
            { name: "trade", value: storage?.trade?.value },
            { name: "date", value: storage.date ? new Date(storage.date) : "" },
            { name: "contacts.location", value: storage.location },
            { name: "job", value: storage.job },
            { name: "job_type", value: storage.job_type },
            { name: "remarks", value: storage.remarks },
        ];

        storageData.forEach(({ name, value }) => {
            setValue(name, value);
        });

        setValue(`prepare_details.signature_options`, defaultOption);
        setValue(`ma_details.signature_options`, defaultOption);

        // eslint-disable-next-line
    }, []);

    //
    // Conditions
    //
    const maUserValidation = Boolean(
        quotationData?.pending_approval &&
            quotationData?.pending_approval[user?.id]
    );

    const canView =
        // TC condition
        (((hasRoleCollection.isTermContractor &&
            (["PENDING", "APPROVED", "COMPLETED", "VOID"].includes(
                quotationData?.status
            ) ||
                !quotationData?.status)) ||
            // MA condition
            (hasRoleCollection.isManagingAgent &&
                (quotationData?.status === "REJECTED" ||
                    quotationData?.status === "APPROVED" ||
                    quotationData?.status === "COMPLETED" ||
                    quotationData?.approvals?.MA))) &&
            action !== "create") ||
        hasRoleCollection.isAdmin ||
        hasRoleCollection.isOM;

    const canSign =
        // TC condition
        ((hasRoleCollection.isTermContractor &&
            ["REJECTED", "DRAFT", "APPROVED"].includes(
                quotationData?.status
            )) ||
            // MA condition
            (hasRoleCollection.isManagingAgent &&
                (["PENDING"].includes(quotationData?.status) ||
                    !quotationData?.status))) &&
        action !== "create";

    const canUpload =
        // TC condition
        (hasRoleCollection.isTermContractor &&
            (action === "create" ||
                ["REJECTED", "DRAFT"].includes(quotationData?.status))) ||
        // MA condition
        (hasRoleCollection.isManagingAgent &&
            ["PENDING"].includes(quotationData?.status) &&
            maUserValidation);

    return (
        <React.Fragment>
            <Helmet
                title={`${
                    create
                        ? "Create"
                        : canView
                        ? "View"
                        : canSign
                        ? "Sign"
                        : "Edit"
                } quotation`}
            />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">
                            {create
                                ? "Create"
                                : canView
                                ? "View"
                                : canSign
                                ? "Sign"
                                : "Edit"}{" "}
                            quotation
                        </h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={cancel}>
                                Quotation list
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {create
                                    ? "Create"
                                    : canView
                                    ? "View"
                                    : canSign
                                    ? "Sign"
                                    : "Edit"}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        {action !== "create" && (
                            <Alert
                                key="primary"
                                variant="primary"
                                className="p-3 w-100"
                                style={{ display: "block" }}
                            >
                                <Alert.Heading
                                    style={{ fontSize: "15px" }}
                                    className="mb-3"
                                >
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        size="lg"
                                        className="me-2"
                                        color="gray"
                                    />
                                    Work completion information
                                </Alert.Heading>
                                <Row>
                                    <Col md={4}>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Reference number
                                            </span>

                                            <div className="mb-2">
                                                {
                                                    quotationData?.reference_number
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Quotation status
                                            </span>

                                            <div className="mb-2">
                                                <Badge
                                                    pill
                                                    bg={status(
                                                        quotationData?.status
                                                    )}
                                                >
                                                    {quotationData?.status}
                                                </Badge>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Quotation SLA
                                            </span>

                                            <p style={{ fontWeight: "300px" }}>
                                                {quotationData.sla_rejection ||
                                                    "-"}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Quotation claim status
                                            </span>

                                            <div className="mb-2">
                                                {quotationData?.claim_status ? (
                                                    <Badge
                                                        pill
                                                        bg={status(
                                                            quotationData.claim_status
                                                        )}
                                                    >
                                                        {
                                                            quotationData?.claim_status
                                                        }
                                                    </Badge>
                                                ) : (
                                                    "-"
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Claim SLA
                                            </span>

                                            <p style={{ fontWeight: "300px" }}>
                                                {quotationData.sla_claim_submission ||
                                                    "-"}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                EPO
                                            </span>
                                            <p style={{ fontWeight: "300px" }}>
                                                {quotationData.epo_reference_no
                                                    ? quotationData.epo_reference_no
                                                    : "-"}
                                            </p>
                                        </div>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                EPI
                                            </span>
                                            <p style={{ fontWeight: "300px" }}>
                                                {quotationData.epi_reference_no
                                                    ? quotationData.epi_reference_no
                                                    : "-"}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Alert>
                        )}
                        <Form>
                            <QuotationDetailsForm
                                active={active}
                                control={control}
                                errors={errors}
                                inspectionStatus={quotationData?.status}
                                quotationData={quotationData}
                                referenceNumber={
                                    quotationData?.reference_number
                                }
                                saveToLocalStorage={saveToLocalStorage}
                                setActive={setActive}
                                setRates={setRates}
                                setQuotationItemsStorage={
                                    setQuotationItemsStorage
                                }
                                setValue={setValue}
                                view={
                                    canView || hasRoleCollection.isManagingAgent
                                }
                                watch={watch}
                                getValues={getValues}
                                computeGst={computeGst}
                            />
                            {defects === "Yes" && (
                                <>
                                    <Row className="mt-5">
                                        <Col md={12}>
                                            <h5>Defects list</h5>
                                        </Col>
                                    </Row>
                                    <DefectsListForm />
                                </>
                            )}
                            <Row className={` ${!isMobile ? "mt-3" : "mt-2"}`}>
                                <Col md={12}>
                                    <h5>Quotation items</h5>
                                </Col>
                            </Row>

                            {Boolean(
                                quotationItemsStorage.length ||
                                    action === "create" ||
                                    (quotationItemsStorage.length &&
                                        [
                                            "REJECTED",
                                            "DRAFT",
                                            "PENDING",
                                            "COMPLETED",
                                            "VOID",
                                        ].includes(quotationData?.status))
                            ) ? (
                                <>
                                    <QuotationItemsForm
                                        active={active}
                                        control={control}
                                        errors={errors}
                                        finalTotal={finalTotal}
                                        quotationItemsStorage={
                                            quotationItemsStorage
                                        }
                                        rates={rates}
                                        saveToLocalStorage={saveToLocalStorage}
                                        setFinalTotal={setFinalTotal}
                                        setQuotationItemsStorage={
                                            setQuotationItemsStorage
                                        }
                                        setValueParent={setValue}
                                        view={
                                            canView ||
                                            hasRoleCollection.isManagingAgent
                                        }
                                        sign={
                                            canSign ||
                                            hasRoleCollection.isManagingAgent
                                        }
                                        gstConfig={
                                            quotationData?.gst
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <Row>
                                        <Col>
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        Add item
                                                    </Tooltip>
                                                }
                                                placement="top"
                                            >
                                                <Button
                                                    className={`form-box float-end mb-2 me-2 ${
                                                        isMobile && "w-100"
                                                    }`}
                                                    onClick={() =>
                                                        navigate(
                                                            `/works-order/quotation-list/${action}/add-quotation-items`
                                                        )
                                                    }
                                                    variant="primary"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                    />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>

                                    <DynamicTable
                                        className="table-layout-fixed"
                                        columns={tableColumns}
                                        dataPagination={{
                                            data: [],
                                        }}
                                    />
                                </>
                            )}

                            <OtherDetails
                                isQuotation
                                control={control}
                                document={
                                    quotationData?.documents
                                        ? parseAttachments(
                                              quotationData?.documents
                                          )
                                        : []
                                }
                                errors={errors}
                                hasRoleCollection={hasRoleCollection}
                                histories={quotationData?.histories}
                                setModalInfo={setModalInfo}
                                setUpload={setSupportingDocuments}
                                setValue={setValue}
                                view={canView}
                                canUpload={canUpload}
                                canDelete={
                                    hasRoleCollection.isTermContractor &&
                                    (action === "create" ||
                                        ["REJECTED", "DRAFT"].includes(
                                            quotationData?.status
                                        ))
                                }
                                maUserValidation={maUserValidation}
                                deleteAttachmentCallback={
                                    deleteAttachmentQuotation
                                }
                                tcRemarks={quotationData?.remarks}
                            />
                            {(Object.keys(quotationData).length ||
                                action === "create") && (
                                <Row className="mt-5">
                                    <SignaturesForm
                                        control={control}
                                        canSign={
                                            !canView &&
                                            hasRoleCollection?.isTermContractor
                                        }
                                        label="Prepared by"
                                        name="prepare_details"
                                        selectedSignatureOption={
                                            tcSignatureOption
                                        }
                                        setValue={setValue}
                                    />
                                    <SignaturesForm
                                        control={control}
                                        canSign={
                                            !canView &&
                                            hasRoleCollection.isManagingAgent &&
                                            maUserValidation
                                        }
                                        label={
                                            maStatus === "ACKNOWLEDGE"
                                                ? "Acknowledge"
                                                : "Checked & certified correct by"
                                        }
                                        name="ma_details"
                                        selectedSignatureOption={
                                            maSignatureOption
                                        }
                                        setValue={setValue}
                                    />
                                </Row>
                            )}
                            <Row className="pt-4">
                                <Col md={12} className="text-center">
                                    {hasRoleCollection.isTermContractor &&
                                        (action === "create" ||
                                            ["REJECTED", "DRAFT"].includes(
                                                quotationData?.status
                                            )) && (
                                            <Button
                                                className="me-2"
                                                onClick={handleSubmit((data) =>
                                                    quotationApiCall(
                                                        data,
                                                        "PENDING"
                                                    )
                                                )}
                                                type="submit"
                                                variant="primary"
                                            >
                                                Submit
                                            </Button>
                                        )}

                                    {maUserValidation &&
                                        quotationData?.pending_approval[
                                            user?.id
                                        ]?.approval_type === "MA" &&
                                        ["PENDING"].includes(
                                            quotationData?.status
                                        ) && (
                                            <Button
                                                className="me-2"
                                                onClick={handleSubmit(
                                                    (data) => {
                                                        if (
                                                            data.ma_details
                                                                .status ===
                                                                "VERIFIED" &&
                                                            quotationData?.zone ===
                                                                "SOUTH"
                                                        ) {
                                                            setModalInfoCustom({
                                                                notifMsg: (
                                                                    <span>
                                                                        Are you
                                                                        sure you
                                                                        want to
                                                                        <strong>
                                                                            {" "}
                                                                            VERIFY{" "}
                                                                        </strong>
                                                                        this
                                                                        quotation?{" "}
                                                                        <br />
                                                                        If yes,
                                                                        this
                                                                        will be
                                                                        verified
                                                                        and
                                                                        forwarded
                                                                        to TC
                                                                        for
                                                                        approval.
                                                                    </span>
                                                                ),
                                                                open: true,
                                                            });
                                                            return;
                                                        }

                                                        maApprovalApi(data);
                                                    }
                                                )}
                                                type="submit"
                                                variant="primary"
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    {(action === "create" ||
                                        ["REJECTED", "DRAFT"].includes(
                                            quotationData?.status
                                        )) &&
                                        hasRoleCollection.isTermContractor && (
                                            <Button
                                                className="me-2"
                                                onClick={handleSubmit((data) =>
                                                    quotationApiCall(
                                                        data,
                                                        "DRAFT"
                                                    )
                                                )}
                                                type="submit"
                                                variant="secondary"
                                            >
                                                Draft
                                            </Button>
                                        )}
                                    {quotationData?.id !== "" &&
                                        quotationData?.id !== null &&
                                        quotationData?.id !== undefined &&
                                        quotationData?.file_info?.bucket &&
                                        quotationData?.file_info?.file_path && (
                                            <Button
                                                variant="link"
                                                onClick={() =>
                                                    window.open(
                                                        constructReport(
                                                            quotationData
                                                                ?.file_info
                                                                ?.bucket,
                                                            quotationData
                                                                ?.file_info
                                                                ?.file_path,
                                                            `${quotationData?.reference_number}-${quotationData?.date}`,
                                                            true
                                                        )
                                                    )
                                                }
                                            >
                                                Preview
                                            </Button>
                                        )}
                                    <Button variant="link" onClick={cancel}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <DeleteModal
                api={deleteApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
            <AlertModal
                api={handleSubmit((data) => maApprovalApi(data))}
                modalInfo={modalInfoCustom}
                setModalInfo={setModalInfoCustom}
                size="md"
                confirmText="Yes"
                cancelText="No"
            />
        </React.Fragment>
    );
};

import React, {
    useState,
    useCallback,
    useContext,
    useEffect,
    createContext,
} from "react";

import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Container,
    Row,
    OverlayTrigger,
    Tooltip,
    Form,
} from "react-bootstrap";

import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import { LoadingContext } from "App";
import { formatConfigFields } from "utils/utilities";
import { faPen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import { months } from "config";
import { years } from "config";
import { kpi_type } from "config";
import * as kpiApi from "@api/kpiApi";
import * as schoolApi from "@api/schoolApi";

export const RerenderContextKpi = createContext();

export const KPI = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const notyf = useContext(NotyfContext);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    let isDesktop = width > 1400;

    const { handleSubmit, control } = useForm({
        mode: "onTouched",
    });

    const type = [
        { value: "ADDITIONAL", label: "Additional" },
        { value: "DEDUCTIBLE", label: "Deductible" },
    ];

    //
    // States
    //

    const [kpi, setKpi] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [configColumn, setFieldColumn] = useState([]);
    const [schools, setSchools] = useState([]);
    const [filter, setFilter] = useState({});

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: (cell) => (
            <div>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit KPI</Tooltip>}
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faPen}
                            className="align-middle me-1"
                            onClick={() =>
                                navigate(
                                    location.pathname +
                                        "/" +
                                        cell.row.original.id
                                )
                            }
                            size="lg"
                        />
                    </span>
                </OverlayTrigger>
            </div>
        ),
    };

    //
    // Functions
    //

    const getKpis = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await kpiApi.getKpis(param);

                if (response.status === 200) {
                    setKpi(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const search = (data) => {
        const searchValue = {
            type: data.type
                ? kpi_type?.find((o) => o?.value === data.type.value)?.value
                : "",
            mo: data.mo
                ? months?.find((o) => o?.value === data.mo.value)?.value
                : "",
            yr: data.yr
                ? years?.find((o) => o?.value === data.yr.value)?.value
                : "",
        };

        setFilter(searchValue);
        getKpis(searchValue);
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await kpiApi.getKpiConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    //
    // UseEffects
    //

    useEffect(() => {
        getKpis();
        getConfig();
    }, [getKpis, rerender, getConfig]);

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    return (
        <RerenderContextKpi.Provider value={[setRerender, rerender]}>
            <Helmet title="KPI" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">KPI</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={10} sm={10} xs={10}>
                                <Row>
                                    <Col md={3} sm={4} xs={4}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="type"
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select "
                                                    options={kpi_type}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    value={value}
                                                    onChange={onChange}
                                                    menuPlacement="top"
                                                />
                                            )}
                                        />
                                    </Col>

                                    <Col md={3} sm={3} xs={3}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="mo"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={months}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    value={value}
                                                    onChange={onChange}
                                                    menuPlacement="bottom"
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={3} xs={3}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="yr"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={years}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    value={value}
                                                    onChange={onChange}
                                                    menuPlacement="bottom"
                                                />
                                            )}
                                        />
                                    </Col>

                                    <Col md={3} sm={2} xs={2}>
                                        <Button
                                            variant="primary"
                                            className="me-1 mb-1 form-box"
                                            onClick={handleSubmit(search)}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <h1 className="h3 mb-3">
                                    <Button
                                        variant="primary"
                                        className="float-end mb-2 form-box"
                                        onClick={() =>
                                            navigate(`/cop/kpi/create`)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </h1>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && kpi.data && (
                            <DynamicTable
                                dataPagination={kpi}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                                api={getKpis}
                                searchValue={filter}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </RerenderContextKpi.Provider>
    );
};

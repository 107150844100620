import React, { useState, useContext } from "react";

import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import { RolesPermissionsTab } from "./RolesPermissionsTab";
import { zones } from "config";
import NotyfContext from "contexts/NotyfContext";
import * as usersApi from "@api/usersApi";
import { contractors } from "../contactorConfig";
import { designation } from "config";

export const BasicInformationTab = ({
    control,
    errors,
    handleSubmit,
    schools,
}) => {
    const notyf = useContext(NotyfContext);
    const { action } = useParams();
    const create = action === "create";
    const navigate = useNavigate();
    const { state } = useLocation();

    const selectedRole = useWatch({ control, name: "role_id" });

    //
    // States
    //

    const [roles, setRoles] = useState([]);

    //
    // Functions
    //

    const userApiCall = async (data) => {
        data.role_id = data.role_id.value;
        data.school_id = data?.school_id?.value;
        data.contractor = data?.contractor?.value;
        data.designation = data?.designation?.value || "";

        try {
            create
                ? await usersApi.createUser(data)
                : await usersApi.updateUser(action, data);

            notyf.open({
                type: "success",
                message: `Successfully ${create ? "created" : "updated"} user`,
            });

            // navigate(`/settings/users`);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    //
    // UseEffects
    //

    return (
        <Form>
            <Row>
                <Col md={12}>
                    <h6 className="pt-2 pb-3">Basic Information</h6>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Full name</Form.Label>
                        <Controller
                            control={control}
                            name="full_name"
                            defaultValue=""
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <Form.Control
                                    type="text"
                                    value={value ? value : ""}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    className={`form-box ${
                                        errors.full_name && "is-invalid"
                                    }`}
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="full_name"
                            render={({ message }) => (
                                <small className="text-danger">{message}</small>
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <Form.Control
                                    type="text"
                                    placeholder="email@address.com"
                                    value={value ? value : ""}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    className={`form-box ${
                                        errors.email && "is-invalid"
                                    }`}
                                    disabled={state?.profile}
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => (
                                <small className="text-danger">{message}</small>
                            )}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {create && (
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Controller
                                control={control}
                                name="password"
                                defaultValue=""
                                render={({
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <Form.Control
                                        type="password"
                                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        className={`form-box ${
                                            errors.password && "is-invalid"
                                        }`}
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="password"
                                render={({ message }) => (
                                    <small
                                        className="text-danger"
                                        style={{ whiteSpace: "pre-wrap" }}
                                    >
                                        {message}
                                    </small>
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
            <Row className="pt-2">
                <Col md={12}>
                    <h6 className="pt-2 pb-2">Other Details</h6>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Designation</Form.Label>
                        <Controller
                            control={control}
                            name="designation"
                            defaultValue=""
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    className="is-invalid react-select-container"
                                    classNamePrefix="react-select"
                                    options={designation}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={value}
                                    onChange={onChange}
                                    menuPlacement="top"
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="designation"
                            render={({ message }) => (
                                <small className="text-danger">{message}</small>
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Mobile no.</Form.Label>
                        <Controller
                            control={control}
                            name="mobile_no"
                            defaultValue=""
                            render={({ field: { value, onChange } }) => (
                                <Form.Control
                                    type="number"
                                    value={value}
                                    onChange={onChange}
                                    className={`form-box ${
                                        errors.mobile_no && "is-invalid"
                                    }`}
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="mobile_no"
                            render={({ message }) => (
                                <small className="text-danger">{message}</small>
                            )}
                        />
                    </Form.Group>
                </Col>

                <RolesPermissionsTab
                    roles={roles}
                    setRoles={setRoles}
                    control={control}
                />
                <Col md={4}>
                    {(selectedRole?.label === "Technician" ||
                        selectedRole?.label === "Term contractor" ||
                        selectedRole?.label === "Term contractor QS" ||
                        selectedRole?.label === "Horticulturist" ||
                        selectedRole?.label === "Managing agent" ||
                        selectedRole?.label === "School campus department" ||
                        selectedRole?.label === "MA-QS") && (
                        <Form.Group className="mb-3">
                            <Form.Label>Zone in charge</Form.Label>
                            <Controller
                                control={control}
                                name="zone_in_charge"
                                defaultValue=""
                                render={({ field: { value, onChange } }) => (
                                    <>
                                        <br />
                                        {state?.profile && value?.length ? (
                                            value?.map((element, key) => {
                                                return (
                                                    <React.Fragment key={key}>
                                                        <Badge
                                                            pill
                                                            bg="secondary"
                                                            className="me-1"
                                                        >
                                                            {element.label}
                                                        </Badge>
                                                    </React.Fragment>
                                                );
                                            })
                                        ) : (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={zones}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="top"
                                            />
                                        )}
                                    </>
                                )}
                            />
                        </Form.Group>
                    )}

                    {selectedRole?.label === "Operations manager" && (
                        <Form.Group className="mb-3">
                            <Form.Label>School</Form.Label>
                            <Controller
                                control={control}
                                name="school_id"
                                defaultValue=""
                                render={({ field: { value, onChange } }) => (
                                    <>
                                        <br />
                                        {state?.profile && value ? (
                                            schools?.find(
                                                (o) => o?.value === value
                                            )?.label
                                        ) : (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={schools}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                value={schools?.find(
                                                    (o) => o?.value === value
                                                )}
                                                onChange={onChange}
                                                menuPlacement="top"
                                            />
                                        )}
                                    </>
                                )}
                            />
                        </Form.Group>
                    )}
                </Col>
                {(selectedRole?.label === "Technician" ||
                    selectedRole?.label === "Term contractor" ||
                    selectedRole?.label === "Term contractor QS" ||
                    selectedRole?.label === "Horticulturist") && (
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Contractor</Form.Label>
                            <Controller
                                control={control}
                                name="contractor"
                                defaultValue=""
                                render={({ field: { value, onChange } }) => (
                                    <>
                                        {state?.profile && value ? (
                                            contractors?.find(
                                                (o) => o?.value === value
                                            )?.label
                                        ) : (
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={contractors}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={value}
                                                onChange={onChange}
                                                menuPlacement="top"
                                            />
                                        )}
                                    </>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="contractor"
                                render={({ message }) => (
                                    <small className="text-danger">
                                        {message}
                                    </small>
                                )}
                            />
                        </Form.Group>
                    </Col>
                )}
            </Row>
            <Row className="pt-4">
                <Col md={12} className="text-center">
                    <Button
                        className="me-2"
                        variant="primary"
                        type="submit"
                        onClick={handleSubmit(userApiCall)}
                    >
                        Submit
                    </Button>
                    {!state?.profile && (
                        <Button
                            variant="link"
                            onClick={() => navigate("/settings/users")}
                        >
                            Cancel
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

import React from "react";

import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

export const DefectTable = ({ defects, start_date, end_date }) => {
    const { tab } = useParams();

    const status = {
        followup: "FOLLOW UP",
        repaired: "REPAIRED",
    };

    let followup = 0;
    let repaired = 0;
    let summary = 0;

    return (
        <>
            <Table bordered responsive className="table-layout-fixed">
                <thead>
                    <tr>
                        <th width="150">School</th>
                        <th width="150">Checklist type</th>
                        <th className="text-end" width="150">
                            Follow up
                        </th>
                        <th className="text-end" width="150">
                            Repaired
                        </th>
                        <th className="text-end" width="150">
                            Summary
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(defects).map((type, keyParent) => {
                        let typeParsed = type[tab.toUpperCase()];

                        if (!typeParsed) {
                            typeParsed = {
                                "FOLLOW UP": 0,
                                REPAIRED: 0,
                                SUMMARY: 0,
                                CHECKLIST_TYPE: "N/A",
                            };
                        }

                        followup += +typeParsed["FOLLOW UP"];
                        repaired += +typeParsed["REPAIRED"];
                        summary += +typeParsed["SUMMARY"];

                        return (
                            <React.Fragment key={keyParent}>
                                <tr>
                                    <td>{Object.keys(defects)[keyParent]}</td>
                                    <td>{typeParsed["CHECKLIST_TYPE"]}</td>
                                    <td className="text-end">
                                        <a
                                            href={`/inspection-checklist/outstanding-defects?status=${
                                                status.followup
                                            }&school=${
                                                Object.keys(defects)[keyParent]
                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {typeParsed["FOLLOW UP"]}
                                        </a>
                                    </td>

                                    <td className="text-end">
                                        <a
                                            href={`/inspection-checklist/outstanding-defects?status=${
                                                status.repaired
                                            }&school=${
                                                Object.keys(defects)[keyParent]
                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {typeParsed["REPAIRED"]}
                                        </a>
                                    </td>

                                    <td className="text-end">
                                        <a
                                            href={`/inspection-checklist/outstanding-defects?school=${
                                                Object.keys(defects)[keyParent]
                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {typeParsed["SUMMARY"]}
                                        </a>
                                    </td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                    {false && (
                        <tr className="bg-warning font-weight-bold">
                            <td className="text-end">Summary</td>
                            <td className="text-end">
                                <a
                                    href={`/inspection-checklist/outstanding-defects?status=${status.followup}&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link pe-1 text-dark"
                                >
                                    {followup}
                                </a>
                            </td>
                            <td className="text-end">
                                <a
                                    href={`/inspection-checklist/outstanding-defects?status=${status.repaired}&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link pe-1 text-dark"
                                >
                                    {repaired}
                                </a>
                            </td>
                            <td className="text-end">
                                <a
                                    href={`/inspection-checklist/outstanding-defects?start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-link pe-1 text-dark"
                                >
                                    {summary}
                                </a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};

import Cookie from "universal-cookie";

export const cookie = new Cookie();

class CookieService {
  get = (key) => {
    return cookie.get(key);
  };

  set = (key, value, options) => {
    cookie.set(key, value, {"path" : "/", "maxAge": options});
  };

  remove = (key, value) => {
    cookie.remove(key, { path: '/' });
  };
}

export default new CookieService();

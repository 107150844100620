import React, { useState, useEffect } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ErrorMessage } from "@hookform/error-message";
import { errorMessage } from "utils/utilities";
import Select from "react-select";
import { maintenance_type } from "config";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { useCallback } from "react";

export const MaintenanceScheduleModal = ({ modalInfo, setModalInfo, api = null, schools, deleteApi = null }) => {
    //
    // States
    //
    const [modalConfirmation, setModalConfirmation] = useState({
        notifMsg: "",
        open: false,
    });
    const [oldSched, setOldSched] = useState();
    const schema = yup.object().shape({
        title: yup.string().required("This field is required"),
        school: yup.object().required("This field is required").nullable(),
        category: yup.object().required("This field is required").nullable(),
        date: yup.string().required("This field is required"),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const color_codes = {
        BM: "#5bc0de",
        TP: "#4bbf73",
        SPECIALIST: "#f0ad4e"
    }
        

    //
    // Functions
    //

    const confirmationModalHandler = () => {
        closeModal();
        setModalConfirmation({
            notifMsg:
                "Are you sure you want to delete?",
            subNotifMsg:
                "Note: This action cannot be undone.",
            open: true,
            api: () => api.delete({sched: oldSched})
        })
    }

    const closeModal = () => {
        setModalInfo({ open: false });
    };

    const callApi = (data) => {
        let date = data.date.split('-');
        if (modalInfo.action == "Add"){
            api.createOrUpdate({
                school_id: data.school.value,
                type: data.category.value,
                month: date[1],
                year: date[0],
                sched : {
                    start: data.date,
                    title: data.title,
                    color: color_codes[data.category.value]
                }
            });
        } else if (modalInfo.action == "Edit") {
            api.createOrUpdate({
                school_id: data.school.value,
                type: data.category.value,
                month: date[1],
                year: date[0],
                sched : {
                    start: data.date,
                    title: data.title,
                    color: color_codes[data.category.value]
                },
                old_sched : oldSched
            });
        }
        
        closeModal();
    };

    //
    // UseEffects
    //
    
    useEffect(() => {
        let code = Object.keys(color_codes).find(key => color_codes[key] === modalInfo.color);
        let type = maintenance_type.find(v => v.value == code)

        setOldSched({
            start: modalInfo.date,
            title: modalInfo.title,
            color: modalInfo.color
        });

        reset({
            title: modalInfo.title,
            school: modalInfo.school,
            category: type,
            date: modalInfo.date,
        });
    }, [reset, modalInfo]);

    return (
        <>
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "Add" })}
            size="lg"
            centered
        >
            <Modal.Header closeButton>{modalInfo.action} maintenance</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="title">
                                    Title
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="title"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            type="text"
                                            className={
                                                errors.title && "is-invalid"
                                            }
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="title"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="school">
                                    School
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="school"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <Select
                                        classNamePrefix="react-select "
                                        options={schools}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder="Select school"
                                        value={value}
                                        onChange={onChange}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="school"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="category">
                                    Category
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="category"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <Select
                                        classNamePrefix="react-select "
                                        options={maintenance_type}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder="Select category"
                                        value={value}
                                        onChange={onChange}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="category"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="date">
                                    Date
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="date"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            value={value}
                                            type="date"
                                            className={
                                                errors.title && "is-invalid"
                                            }
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="date"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container fluid className="p-0">
                    <Row className="d-flex flex-row justify-content-between">
                        <Col >
                            {modalInfo.action == "Edit" ?
                            
                            <Button
                                variant="danger"
                                type="submit"
                                onClick={confirmationModalHandler}
                            >
                                Delete
                            </Button> : null}
                            
                        </Col>
                        <Col className="text-end" >
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit(callApi)}
                            >
                                Submit
                            </Button>
                            <Button variant="link" onClick={() => closeModal()}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
        <ConfirmationModal
        modalInfo={modalConfirmation}
        setModalInfo={setModalConfirmation}
        />
    </>
    );
};

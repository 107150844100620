import api from "../utils/api";

/**
 * @returns Defect
 */

export const registerDefect = (data, inspection_schedule_id) => {
    return api.post(
        `inspection-schedule/${inspection_schedule_id}/defect`,
        data
    );
};

export const getInspectionDefects = (inspection_schedule_id) => {
    return api.post(`inspection-schedule/${inspection_schedule_id}/defects`);
};

export const getDefects = (filter) => {
    return api.get(`/defect`, { params: filter });
};

export const getDefect = (defect_id) => {
    return api.get(`/defect/${defect_id}`);
};

export const updateDefect = (data, defect_id) => {
    return api.post(`/defect/${defect_id}`, data);
};

export const deleteDefect = (defect_id) => {
    return api.delete(`/defect/${defect_id}`);
};

export const getDefectConfig = (data) => {
    return api.get(`/config/defect`, data);
};

import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import useAuth from "hooks/useAuth";
import NotyfContext from "contexts/NotyfContext";
import { HelperButtons } from "components/HelperButtons";

const PrivateLayout = ({ children }) => {
    const { isAuthenticated, isInitialized, user, menus } = useAuth();
    const notyf = useContext(NotyfContext);

    if (isInitialized && !isAuthenticated) {
        notyf.open({
            type: "danger",
            message:
                "Unauthorized: Access is denied, please login again with valid credentials.",
        });
        return (
            <Navigate
                to="/auth/sign-in"
                state={{
                    page_redirect: window.location.href,
                }}
            />
        );
    }

    let dynamicNavItems;
    if (menus) {
        dynamicNavItems = [
            {
                title: "Pages",
                pages: menus,
            },
        ];
    }

    return (
        <React.Fragment>
            {isAuthenticated && (
                <>
                    <Wrapper>
                        {/* <Sidebar items={navItems} /> */}
                        {dynamicNavItems && <Sidebar items={dynamicNavItems} />}
                        <Main>
                            <Navbar user={user} />
                            <Content>
                                {children}
                                <Outlet />
                            </Content>
                            <Footer />
                        </Main>
                    </Wrapper>
                    <Settings />
                    <HelperButtons />
                </>
            )}
        </React.Fragment>
    );
};

export default PrivateLayout;

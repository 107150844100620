import api from "../utils/api";

export const getUserOrganization = (id) => {
    return api.get(`/user-organization/${id}`);
};

export const getUsers = (id, data) => {
    return api.get(`/user-organization/${id}/users`, {
        params: data,
    });
};

export const updateOrganization = (id, data) => {
    return api.put(`/user-organization/${id}/update`, data);
};

export const getOutstandingItems = (id) => {
    return api.get(`/user-organization/${id}/display-outstanding-items`);
};

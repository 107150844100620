import api from "../utils/api";

export const createClaim = (data) => {
    return api.post("/claims", data);
};

export const updateClaim = (id, data) => {
    return api.post("/claims/" + id, data);
};

export const getClaims = (data) => {
    return api.get("/claims", { params: data });
};

export const getClaim = (id) => {
    return api.get("/claims/" + id);
};

export const deleteClaim = (id) => {
    return api.delete("/claims/" + id);
};

export const getClaimsConfig = (id, data) => {
    return api.get(`/config/claim`, data);
};

export const maClaimsApproval = (id, data) => {
    return api.post(`/claims/${id}/ma-approval`, data);
};

export const voidClaim = (id, data) => {
    return api.post(`/claims/${id}/void`, data);
};

export const uploadClaimDocuments = (id, data) => {
    return api.post(`/claims/${id}/upload-quotation-documents`, data);
};

export const createWorkCompletionClaims = (id, data) => {
    return api.post(`/claims/${id}/update-work-completion`, data);
};

export const updateFinalClaimStatus = (id, data) => {
    return api.post(`claims/${id}/update-final-claim-status`, data);
};

export const pendingForClaimsApproval = (data) => {
    return api.get(`claims/pending-approvals`, data);
};

export const updatePaymentStatus = (id, data) => {
    return api.post(`claims/${id}/update-payment-status`, data);
};

export const updateAttachments = (id, data) => {
    return api.post(`claims/${id}/update-attachments`, data);
};

export const regenerateReport = (id) => {
    return api.get(`claims/${id}/report-generation`);
};

export const updateInProgress = (id, data) => {
    return api.post(`claims/${id}/update-in-progress`, data);
};

export const rejectClaim = (id, data) => {
    return api.post(`claims/${id}/reject`, data);
};

export const invoiceUpdate = (id, data) => {
    return api.post(`claims/${id}/invoice-update`, data);
};

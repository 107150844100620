import React, { useState, useContext, useEffect } from "react";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import { SchoolInformation } from "../../../settings/schools/components/SchoolInformation";
import { LoadingContext } from "App";
import * as userOrganizationApi from "@api/userOrganizationApi";
import * as schoolApi from "@api/schoolApi";
import { ManagingAgentsAssignments } from "./ManagingAgentsAssignments";

const schema = yup.object().shape({});

export const ManageAssignments = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const notyf = useContext(NotyfContext);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    //
    // States
    //

    const [school, setSchool] = useState([]);
    const [managingAgents, setManagingAgents] = useState([]);
    const [rerender, setRerender] = useState(true);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        getValues,
        setValue,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });


    const {
        fields: managingAgentsFields,
        remove: managingAgentRemove,
    } = useFieldArray({
        control,
        name: "managing_agents",
        keyName: "key",
    });

    //
    // Functions
    //

    const getSchoolUserOrganizations = async (action) => {
        try {
            const response = await userOrganizationApi.getUserOrganization(
                action
            );
            const data = response.data.data;

            if (response.status === 200) {
                setSchool(data.school);

                getUsers(data.school, setManagingAgents);
                reset({
                    operation_managers: data.oms,
                });
                
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getUsers = async (school,  callback) => {
        try {
            const response = await schoolApi.getAssignmentsBySchoolId(action);

            if (response.status === 200) {
                let temp = {};
                console.log(response?.data.data)
                temp = response?.data.data.map((user) => {
                    return {
                        value: user?.id,
                        label: user?.full_name,
                        email: user?.email,
                    };
                });

                reset({
                    managing_agents: response?.data.data,
                });

                callback(temp);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getSchoolUserOrganizations(action);
        // eslint-disable-next-line
    }, [action, rerender]);



    return (
        <React.Fragment>
            <Helmet title={`Manager organization users`} />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">Edit organization users assignments</h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() =>
                                    navigate("/tools/user-organization")
                                }
                            >
                                User organaztion
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Edit organization users assignments
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <SchoolInformation school={school} />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <ManagingAgentsAssignments
                            control={control}
                            managingAgentsFields={managingAgentsFields}
                            managingAgentRemove={managingAgentRemove}
                            rerender={rerender}
                            setRerender={setRerender}
                            setIsLoadingActive={setIsLoadingActive}
                        />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={12} className="text-center">
                                
                                <Button
                                    variant="link"
                                    onClick={() =>
                                        navigate("/tools/user-organization")
                                    }
                                >
                                    Go back
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

import api from "../utils/api";

export const createFsr = (data) => {
    return api.post("/fsrs", data);
};

export const updateFsr = (id, data) => {
    return api.patch("/fsrs/" + id, data);
};

export const getFsrs = (data) => {
    return api.post("/fsrs", data);
};

export const getFsr = (id) => {
    return api.get("/fsrs/" + id);
};

export const deleteFsr = (id) => {
    return api.delete("/fsrs/" + id);
};

export const createStarRate = (data) => {
    return api.post("/fsrs/create-star-rate", data);
};

export const getFsrConfig = (data) => {
    return api.get(`/config/fsr`, data);
};

export const getFsrOptions = (data) => {
    return api.get(`/fsrs/options/fsr`, { params: data });
};

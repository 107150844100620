import React from "react";

import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { toTitleCase } from "utils/utilities";

export const InspectionTable = ({ inspections, start_date, end_date }) => {
    const { tab } = useParams();

    const status = {
        draft: "DRAFT",
        submitted: "SUBMITTED",
        approved: "APPROVED",
        rejected: "REJECTED",
        acknowledged: "ACKNOWLEDGED",
        "pending-approval": "PENDING-APPROVAL",
    };

    return (
        <>
            <Table bordered responsive className="table-layout-fixed">
                <thead>
                    <tr>
                        <th width="150">Type</th>
                        <th className="text-end" width="150">
                            Submitted
                        </th>
                        <th className="text-end" width="150">
                            Acknowledged
                        </th>
                        <th className="text-end" width="150">
                            Approved
                        </th>
                        <th className="text-end" width="150">
                            Rejected
                        </th>
                        <th className="text-end" width="150">
                            Summary
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(inspections).map((schools, keyParent) => {
                        let approved = 0;
                        let submitted = 0;
                        let acknowledged = 0;
                        let rejected = 0;
                        let summary = 0;

                        return (
                            <React.Fragment key={keyParent}>
                                <tr className="bg-light font-weight-bold">
                                    <td>
                                        {Object.keys(inspections)[keyParent]}
                                    </td>
                                    <th className="text-end" width="150">
                                        Submitted
                                    </th>
                                    <th className="text-end" width="150">
                                        Acknowledged
                                    </th>
                                    <th className="text-end" width="150">
                                        Approved
                                    </th>
                                    <th className="text-end" width="150">
                                        Rejected
                                    </th>
                                    <th className="text-end" width="150">
                                        Summary
                                    </th>
                                </tr>

                                {schools[tab.toUpperCase()] &&
                                    Object.values(
                                        schools[tab.toUpperCase()]
                                    ).map((type, keyChild) => {
                                        submitted += type["SUBMITTED"] || 0;
                                        acknowledged +=
                                            type["ACKNOWLEDGED"] || 0;
                                        approved += type["APPROVED"] || 0;
                                        rejected += type["REJECTED"] || 0;
                                        summary += type["SUMMARY"] || 0;

                                        return (
                                            <React.Fragment key={keyChild}>
                                                <tr>
                                                    <td>
                                                        {toTitleCase(
                                                            Object.keys(
                                                                schools[
                                                                    tab.toUpperCase()
                                                                ]
                                                            )[keyChild]
                                                        )}
                                                    </td>
                                                    <td className="text-end">
                                                        <a
                                                            href={`/inspection-checklist/inspection-servicing?status=${
                                                                status.submitted
                                                            }&school=${
                                                                Object.keys(
                                                                    inspections
                                                                )[keyParent]
                                                            }&inspection_type=${
                                                                Object.keys(
                                                                    schools[
                                                                        tab.toUpperCase()
                                                                    ]
                                                                )[keyChild]
                                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="btn-link pe-1 text-dark"
                                                        >
                                                            {type["SUBMITTED"]}
                                                        </a>
                                                    </td>
                                                    <td className="text-end">
                                                        <a
                                                            href={`/inspection-checklist/inspection-servicing?status=${
                                                                status.acknowledged
                                                            }&school=${
                                                                Object.keys(
                                                                    inspections
                                                                )[keyParent]
                                                            }&inspection_type=${
                                                                Object.keys(
                                                                    schools[
                                                                        tab.toUpperCase()
                                                                    ]
                                                                )[keyChild]
                                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="btn-link pe-1 text-dark"
                                                        >
                                                            {
                                                                type[
                                                                    "ACKNOWLEDGED"
                                                                ]
                                                            }
                                                        </a>
                                                    </td>
                                                    <td className="text-end">
                                                        <a
                                                            href={`/inspection-checklist/inspection-servicing?status=${
                                                                status.approved
                                                            }&school=${
                                                                Object.keys(
                                                                    inspections
                                                                )[keyParent]
                                                            }&inspection_type=${
                                                                Object.keys(
                                                                    schools[
                                                                        tab.toUpperCase()
                                                                    ]
                                                                )[keyChild]
                                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="btn-link pe-1 text-dark"
                                                        >
                                                            {type["APPROVED"]}
                                                        </a>
                                                    </td>
                                                    <td className="text-end">
                                                        <a
                                                            href={`/inspection-checklist/inspection-servicing?status=${
                                                                status.rejected
                                                            }&school=${
                                                                Object.keys(
                                                                    inspections
                                                                )[keyParent]
                                                            }&inspection_type=${
                                                                Object.keys(
                                                                    schools[
                                                                        tab.toUpperCase()
                                                                    ]
                                                                )[keyChild]
                                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="btn-link pe-1 text-dark"
                                                        >
                                                            {type["REJECTED"]}
                                                        </a>
                                                    </td>
                                                    <td className="text-end">
                                                        <a
                                                            href={`/inspection-checklist/inspection-servicing?school=${
                                                                Object.keys(
                                                                    inspections
                                                                )[keyParent]
                                                            }&inspection_type=${
                                                                Object.keys(
                                                                    schools[
                                                                        tab.toUpperCase()
                                                                    ]
                                                                )[keyChild]
                                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="btn-link pe-1 text-dark"
                                                        >
                                                            {type["SUMMARY"]}
                                                        </a>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                <tr className="bg-warning font-weight-bold">
                                    <td className="text-end">Summary</td>
                                    <td className="text-end">
                                        <a
                                            href={`/inspection-checklist/inspection-servicing?status=${
                                                status.submitted
                                            }&school=${
                                                Object.keys(inspections)[
                                                    keyParent
                                                ]
                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {submitted}
                                        </a>
                                    </td>
                                    <td className="text-end">
                                        <a
                                            href={`/inspection-checklist/inspection-servicing?status=${
                                                status.acknowledged
                                            }&school=${
                                                Object.keys(inspections)[
                                                    keyParent
                                                ]
                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {acknowledged}
                                        </a>
                                    </td>
                                    <td className="text-end">
                                        <a
                                            href={`/inspection-checklist/inspection-servicing?status=${
                                                status.approved
                                            }&school=${
                                                Object.keys(inspections)[
                                                    keyParent
                                                ]
                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {approved}
                                        </a>
                                    </td>
                                    <td className="text-end">
                                        <a
                                            href={`/inspection-checklist/inspection-servicing?status=${
                                                status.rejected
                                            }&school=${
                                                Object.keys(inspections)[
                                                    keyParent
                                                ]
                                            }&start_date=${start_date}&end_date=${end_date}&category=${tab}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {rejected}
                                        </a>
                                    </td>
                                    <td className="text-end">
                                        <a
                                            href={`/inspection-checklist/inspection-servicing?school=${
                                                Object.keys(inspections)[
                                                    keyParent
                                                ]
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn-link pe-1 text-dark"
                                        >
                                            {summary}
                                        </a>
                                    </td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
};

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import { errorMessage } from "utils/utilities";

export const FsrFormItems = ({ nestIndex, control, errors }) => {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `fsrs[${nestIndex}].items`,
    });

    return (
        <React.Fragment>
            {fields.map((item, key) => {
                return (
                    <tr key={item.id}>
                        <td colSpan={2}>
                            <Row>
                                <Col md={6}>
                                    <Form.Label>Item number</Form.Label>
                                    <Controller
                                        control={control}
                                        name={`fsrs[${nestIndex}].items[${key}].item_number`}
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange, onBlur },
                                        }) => (
                                            <Form.Control
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                className={`form-box ${
                                                    errors.fsrs !== undefined &&
                                                    errors.fsrs[nestIndex] !==
                                                        undefined &&
                                                    errors.fsrs[nestIndex]
                                                        .items[key] &&
                                                    errors.fsrs[nestIndex]
                                                        .items[key]
                                                        .item_number &&
                                                    "is-invalid"
                                                }`}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`fsrs[${nestIndex}].items[${key}].item_number`}
                                        render={({ message }) =>
                                            errorMessage(message)
                                        }
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>
                                        Item group description
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name={`fsrs[${nestIndex}].items[${key}].item_group_description`}
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange, onBlur },
                                        }) => (
                                            <Form.Control
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                className={`form-box ${
                                                    errors.fsrs !== undefined &&
                                                    errors.fsrs[nestIndex] !==
                                                        undefined &&
                                                    errors.fsrs[nestIndex]
                                                        .items[key] &&
                                                    errors.fsrs[nestIndex]
                                                        .items[key]
                                                        .item_group_description &&
                                                    "is-invalid"
                                                }`}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`fsrs[${nestIndex}].items[${key}].item_group_description`}
                                        render={({ message }) =>
                                            errorMessage(message)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Form.Label className="mt-2">
                                Item description
                            </Form.Label>
                            <Controller
                                control={control}
                                name={`fsrs[${nestIndex}].items[${key}].item_description`}
                                defaultValue=""
                                render={({
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <Form.Control
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        className={`form-box ${
                                            errors.fsrs !== undefined &&
                                            errors.fsrs[nestIndex] !==
                                                undefined &&
                                            errors.fsrs[nestIndex].items[key] &&
                                            errors.fsrs[nestIndex].items[key]
                                                .item_description &&
                                            "is-invalid"
                                        }`}
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`fsrs[${nestIndex}].items[${key}].item_description`}
                                render={({ message }) => errorMessage(message)}
                            />
                        </td>
                        <td>
                            <Row>
                                <Col md={12}>
                                    <Form.Label>Unit</Form.Label>
                                    <Controller
                                        control={control}
                                        name={`fsrs[${nestIndex}].items[${key}].unit`}
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange, onBlur },
                                        }) => (
                                            <Form.Control
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                className={`form-box ${
                                                    errors.fsrs !== undefined &&
                                                    errors.fsrs[nestIndex] !==
                                                        undefined &&
                                                    errors.fsrs[nestIndex]
                                                        .items[key] &&
                                                    errors.fsrs[nestIndex]
                                                        .items[key].unit &&
                                                    "is-invalid"
                                                }`}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`fsrs[${nestIndex}].items[${key}].unit`}
                                        render={({ message }) =>
                                            errorMessage(message)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Label className="mt-2">
                                        Rate (S$)
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name={`fsrs[${nestIndex}].items[${key}].rate`}
                                        defaultValue=""
                                        render={({
                                            field: { value, onChange, onBlur },
                                        }) => (
                                            <Form.Control
                                                type="number"
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                className={`form-box ${
                                                    errors.fsrs !== undefined &&
                                                    errors.fsrs[nestIndex] !==
                                                        undefined &&
                                                    errors.fsrs[nestIndex]
                                                        .items[key] &&
                                                    errors.fsrs[nestIndex]
                                                        .items[key].rate &&
                                                    "is-invalid"
                                                }`}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`fsrs[${nestIndex}].items[${key}].rate`}
                                        render={({ message }) =>
                                            errorMessage(message)
                                        }
                                    />
                                </Col>
                            </Row>
                        </td>
                        <td>
                            {fields.length > 1 && (
                                <FontAwesomeIcon
                                    icon={faMinus}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className="align-middle me-1"
                                    onClick={() => remove(key)}
                                />
                            )}
                            <FontAwesomeIcon
                                icon={faPlus}
                                style={{
                                    cursor: "pointer",
                                }}
                                className="align-middle me-1"
                                onClick={() =>
                                    append({
                                        item_group_description: "",
                                        item_description: "",
                                        item_number: "",
                                        unit: "",
                                        rate: "",
                                    })
                                }
                            />
                        </td>
                    </tr>
                );
            })}
        </React.Fragment>
    );
};

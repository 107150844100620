import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Container, Nav, Breadcrumb, Tab } from "react-bootstrap";

import { SignatureTab } from "../components/SignatureTab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BasicInformationTab } from "../components/BasicInformationTab";
import NotyfContext from "contexts/NotyfContext";
import * as yup from "yup";
import * as usersApi from "@api/usersApi";
import * as schoolApi from "@api/schoolApi";
import "react-datepicker/dist/react-datepicker.css";
import { PrivacySecurityTab } from "../components/PrivacySecurityTab";
import { designation } from "config";

export const RerenderContextUser = createContext();

export const UsersForm = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const { action } = useParams();
    const create = action === "create";
    const { state } = useLocation();

    const schema = yup.object().shape({
        full_name: yup.string().required("This field is required"),
        email: yup
            .string()
            .email("Invalid email address")
            .required("This field is required"),
        password: create
            ? yup
                  .string()
                  .required("This field is required")
                  .matches(
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/,
                      "Length must be at least 12 characters \nMust contain at least one uppercase letter \nMust contain at least one lowercase letter \nMust contain a number \nMust contain at least one special character"
                  )
            : null,
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    //
    // States
    //

    const [userData, setUserData] = useState({});
    const [initSignature, setInitSignature] = useState(false);
    const [rerender, setRerender] = useState([]);
    const [schools, setSchools] = useState([]);
    //
    // Functions
    //

    const getUser = useCallback(async () => {
        try {
            const response = await usersApi.getUser(action);

            if (response.status === 200) {
                const data = response.data.data;
                setUserData(data);
                reset({
                    full_name: data.full_name,
                    email: data.email,
                    designation: designation?.filter((item) => {
                        return item.value === data.designation;
                    }),
                    mobile_no: data.mobile_no,
                    zone_in_charge: data.zone_in_charge,
                    role_id: {
                        value: data?.role?.id,
                        label: data?.role.name,
                    },
                    school_id: data?.school_id,
                    contractor: {
                        label: data?.contractor,
                        value: data?.contractor,
                    },
                });
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the serverrr",
            });
        }
    }, [action, notyf, reset]);

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools(true);
            if (response.status === 200) {
                const data = response.data.data;
                let temp = {};
                temp = data.map((school) => {
                    return {
                        value: school?.id,
                        label: school?.name,
                    };
                });
                setSchools(temp);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
        // eslint-disable-next-line
    }, []);

    //
    // UseEffects
    //

    useEffect(() => {
        action !== "create" && getUser();
        getSchools();
    }, [action, getUser, rerender, getSchools]);

    return (
        <RerenderContextUser.Provider value={[rerender, setRerender]}>
            <Helmet title={`${create ? "Create" : "Edit"} user`} />
            <Container fluid className="p-0">
                {!state?.profile && (
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClick={() => navigate("/settings/users")}
                        >
                            Users
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {create ? "Add" : "Edit"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                )}
                <div className="tab custom-tab tab-vertical">
                    <Tab.Container defaultActiveKey="basic_information">
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="basic_information"
                                    onClick={() => null}
                                >
                                    Basic Information
                                </Nav.Link>
                                {state?.profile && (
                                    <Nav.Link
                                        eventKey="signature"
                                        onClick={() => setInitSignature(true)}
                                    >
                                        Signature
                                    </Nav.Link>
                                )}
                                {state?.profile && (
                                    <Nav.Link
                                        eventKey="change_password"
                                        onClick={() => null}
                                    >
                                        Change password
                                    </Nav.Link>
                                )}
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="basic_information">
                                <BasicInformationTab
                                    control={control}
                                    errors={errors}
                                    handleSubmit={handleSubmit}
                                    schools={schools}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="signature">
                                {userData && (
                                    <SignatureTab
                                        initSignature={initSignature}
                                        userData={userData}
                                    />
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="change_password">
                                {userData && <PrivacySecurityTab />}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Container>
        </RerenderContextUser.Provider>
    );
};

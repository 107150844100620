import React, { useCallback, useContext, useEffect, useState } from "react";

import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { moneyFormatter } from "utils/utilities";
import { Tabs } from "./Tabs";
import { LoadingContext } from "App";
import { Information } from "./Information";
import NotyfContext from "contexts/NotyfContext";
import * as copApi from "@api/copApi";

export const GRR = ({ tab = false, information }) => {
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    let { action } = useParams();
    const notyf = useContext(NotyfContext);

    //
    // States
    //
    let totalAmount = 0;
    let totalMonthlyClaim = 0;
    let totalAddition = 0;
    let totalOmission = 0;
    let totalDeduction = 0;
    let totalPayment = 0;
    let totalCumulatativePayment = 0;
    let totalCumulatativeDeduction = 0;

    const [details, setDetails] = useState({});
    const [postParams, setPostParams] = useState([]);
    const [rerender, setRerender] = useState(false);

    //
    // Functions
    //

    const getDetails = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await copApi.getCop(action, {
                    category: "general_r_and_r",
                });

                if (response.status === 200) {
                    setDetails(response.data.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const additionCalculation = (mainKey, childKey, childValue, addition) => {
        let cloneDetails = { ...details };
        let finalPayment =
            parseFloat(childValue?.monthly_claim) +
            parseFloat(addition) -
            parseFloat(childValue?.omission) -
            parseFloat(childValue?.deduction);

        cloneDetails[mainKey][childKey]["payment"] = finalPayment;
        cloneDetails[mainKey][childKey]["addition"] = addition;

        pushToPostParams({
            school_id: childValue?.school_id,
            id: childValue?.id,
            addition: parseFloat(addition).toFixed(2),
            omission: parseFloat(childValue?.omission).toFixed(2),
            deduction: parseFloat(childValue?.deduction).toFixed(2),
            grn_percentage: parseFloat(childValue?.grn_percentage),
            monthly_claim: parseFloat(childValue?.monthly_claim),
            reference: childValue?.reference,
        });
        setDetails(cloneDetails);
    };

    const omissionCalculation = (mainKey, childKey, childValue, omission) => {
        let cloneDetails = { ...details };

        let finalPayment =
            parseFloat(childValue?.monthly_claim) +
            parseFloat(childValue?.addition) -
            parseFloat(omission) -
            parseFloat(childValue?.deduction);

        cloneDetails[mainKey][childKey]["payment"] = finalPayment;
        cloneDetails[mainKey][childKey]["omission"] = omission;

        pushToPostParams({
            school_id: childValue?.school_id,
            id: childValue?.id,
            addition: parseFloat(childValue?.addition).toFixed(2),
            omission: parseFloat(omission).toFixed(2),
            deduction: parseFloat(childValue?.deduction).toFixed(2),
            grn_percentage: parseFloat(childValue?.grn_percentage),
            monthly_claim: parseFloat(childValue?.monthly_claim),
            reference: childValue?.reference,
        });
        setDetails(cloneDetails);
    };

    const deductionCalculation = (mainKey, childKey, childValue, deduction) => {
        let cloneDetails = { ...details };
        let finalPayment =
            parseFloat(childValue?.monthly_claim) +
            parseFloat(childValue?.addition) -
            parseFloat(childValue?.omission) -
            parseFloat(deduction);

        cloneDetails[mainKey][childKey]["payment"] = finalPayment;
        cloneDetails[mainKey][childKey]["deduction"] = deduction;

        pushToPostParams({
            school_id: childValue?.school_id,
            id: childValue?.id,
            addition: parseFloat(childValue?.addition).toFixed(2),
            omission: parseFloat(childValue?.omission).toFixed(2),
            deduction: parseFloat(deduction).toFixed(2),
            grn_percentage: parseFloat(childValue?.grn_percentage),
            monthly_claim: parseFloat(childValue?.monthly_claim),
            reference: childValue?.reference,
        });
        setDetails(cloneDetails);
    };

    const percentageCalculation = (
        mainKey,
        childKey,
        childValue,
        percentage
    ) => {
        let cloneDetails = { ...details };
        let monthly_claim = (childValue?.contract_amount / 100) * percentage;

        cloneDetails[mainKey][childKey]["grn_percentage"] = percentage;
        cloneDetails[mainKey][childKey]["monthly_claim"] = monthly_claim;

        pushToPostParams({
            school_id: childValue?.school_id,
            id: childValue?.id,
            addition: parseFloat(childValue?.addition).toFixed(2),
            omission: parseFloat(childValue?.omission).toFixed(2),
            deduction: parseFloat(childValue?.deduction).toFixed(2),
            grn_percentage: parseFloat(percentage),
            monthly_claim: parseFloat(monthly_claim),
            reference: childValue?.reference,
        });
        setDetails(cloneDetails);
    };

    const addReferenceNumber = (mainKey, childKey, childValue, reference) => {
        let cloneDetails = { ...details };

        cloneDetails[mainKey][childKey]["reference"] = reference;

        pushToPostParams({
            school_id: childValue?.school_id,
            id: childValue?.id,
            addition: parseFloat(childValue?.addition).toFixed(2),
            omission: parseFloat(childValue?.omission).toFixed(2),
            deduction: parseFloat(childValue?.deduction).toFixed(2),
            monthly_claim: parseFloat(childValue?.monthly_claim),
            grn_percentage: parseFloat(childValue?.grn_percentage),
            reference: reference,
        });
    };

    const pushToPostParams = (object) => {
        let params = postParams;

        // append new object if school_id and id does not exist, replace if exists
        let found = false;
        for (let i = 0; i < params.length; i++) {
            if (
                params[i].school_id === object.school_id &&
                params[i].id === object.id
            ) {
                params[i] = object;
                found = true;
                break;
            }
        }
        if (!found) {
            params.push(object);
        }

        setPostParams(params);
    };

    const submitChanges = async () => {
        try {
            setIsLoadingActive(true);
            const response = await copApi.updateCop(action, {
                cop_detail_school: postParams,
                category: "general_r_and_r",
            });

            if (response.status === 200) {
                setRerender(!rerender);
                setIsLoadingActive(false);
                notyf.open({
                    type: "success",
                    message: "Update successful",
                });
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        const getDetails = async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await copApi.getCop(action, {
                    category: "general_r_and_r",
                });

                if (response.status === 200) {
                    setDetails(response.data.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        };

        getDetails();
    }, [getDetails, rerender]);

    return (
        <>
            <Tabs />
            <hr />
            <Information information={information} />
            <Table bordered responsive className="table-layout-fixed">
                <thead>
                    <tr>
                        <th rowSpan={2} width="250">
                            Schools
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Contract amount
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Percentage of completion (%)
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Cumulative percentage (%)
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Monthly claim
                        </th>
                        <th colSpan={2} className="text-center" width="300">
                            Variation
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Deduction
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Cumulatative payment
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            Cumulatative deduction
                        </th>
                        <th rowSpan={2} className="text-end" width="150">
                            This payment
                        </th>
                        <th rowSpan={2} width="150">
                            Reference
                        </th>
                    </tr>
                    <tr>
                        <th rowSpan={2} className="text-end" width="150px">
                            Addition
                        </th>
                        <th rowSpan={2} className="text-end" width="150px">
                            Omission
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(details).length ? (
                        Object.values(details).map((value, key) => {
                            let subTotalAmount = 0;
                            let subTotalMonthlyClaim = 0;
                            let subTotalAddition = 0;
                            let subTotalOmission = 0;
                            let subTotalDeduction = 0;
                            let subTotalPayment = 0;
                            let subTotalCumulatativePayment = 0;
                            let subTotalCumulatativeDeduction = 0;

                            let mainKey = Object.keys(details).find(
                                (key) => details[key] === value
                            );

                            return value.map(
                                (childValue, childKey, childRow) => {
                                    subTotalAmount +=
                                        +childValue.contract_amount;

                                    if (childValue.grn_percentage) {
                                        subTotalMonthlyClaim +=
                                            +childValue.monthly_claim;
                                    }

                                    subTotalAddition += +childValue.addition;
                                    subTotalOmission += +childValue.omission;
                                    subTotalDeduction += +childValue.deduction;
                                    subTotalPayment += +childValue.payment;
                                    subTotalCumulatativePayment +=
                                        +childValue.summarypayment;
                                    subTotalCumulatativeDeduction +=
                                        +childValue.summarydeduction;

                                    totalAmount += +childValue.contract_amount;
                                    totalMonthlyClaim +=
                                        +childValue.monthly_claim;
                                    totalAddition += +childValue.addition;
                                    totalOmission += +childValue.omission;
                                    totalDeduction += +childValue.deduction;
                                    totalPayment += +childValue.payment;
                                    totalCumulatativePayment +=
                                        +childValue.summarypayment;
                                    totalCumulatativeDeduction +=
                                        +childValue.summarydeduction;

                                    return (
                                        <React.Fragment key={childKey}>
                                            {childKey === 0 && (
                                                <tr className="bg-light font-weight-bold">
                                                    <td colSpan={12}>
                                                        {
                                                            Object.keys(
                                                                details
                                                            )[key]
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>
                                                    {
                                                        childValue.details
                                                            .school_name
                                                    }
                                                </td>
                                                <td className="text-end currency">
                                                    {parseFloat(
                                                        childValue.contract_amount
                                                    )
                                                        ? moneyFormatter(
                                                              childValue.contract_amount
                                                          )
                                                        : "-"}
                                                </td>
                                                {parseFloat(
                                                    childValue.contract_amount
                                                ) ? (
                                                    <td
                                                        className={`text-end ${
                                                            information?.status ===
                                                                "PENDING" &&
                                                            "currency"
                                                        }`}
                                                    >
                                                        {information?.status ===
                                                        "DRAFT" ? (
                                                            <Form.Control
                                                                type="number"
                                                                className="form-box"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const max =
                                                                        100 -
                                                                        parseFloat(
                                                                            childValue.summarygrnpercentage
                                                                        );
                                                                    if (
                                                                        parseFloat(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        ) >= max
                                                                    ) {
                                                                        e.target.value =
                                                                            max;
                                                                    }

                                                                    percentageCalculation(
                                                                        mainKey,
                                                                        childKey,
                                                                        childValue,
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                defaultValue={
                                                                    (!childValue.summarygrnpercentage ||
                                                                        !parseFloat(
                                                                            childValue.summarygrnpercentage
                                                                        )) &&
                                                                    !childValue.grn_percentage
                                                                        ? 0
                                                                        : childValue.grn_percentage
                                                                }
                                                            />
                                                        ) : parseFloat(
                                                              childValue.grn_percentage
                                                          ) ? (
                                                            parseFloat(
                                                                childValue.grn_percentage
                                                            ).toFixed(2)
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                ) : (
                                                    <td className="text-end">
                                                        -
                                                    </td>
                                                )}
                                                <td className="text-end">
                                                    {childValue.summarygrnpercentage ||
                                                        "-"}
                                                </td>
                                                <td className="text-end currency">
                                                    {parseFloat(
                                                        childValue.monthly_claim
                                                    ) &&
                                                    childValue.grn_percentage
                                                        ? moneyFormatter(
                                                              childValue.monthly_claim
                                                          )
                                                        : "-"}
                                                </td>
                                                {parseFloat(
                                                    childValue.monthly_claim
                                                ) &&
                                                childValue.grn_percentage ? (
                                                    <td
                                                        className={`text-end ${
                                                            information?.status ===
                                                                "PENDING" &&
                                                            "currency"
                                                        }`}
                                                    >
                                                        {information?.status ===
                                                        "DRAFT" ? (
                                                            <Form.Control
                                                                type="number"
                                                                className="form-box"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    additionCalculation(
                                                                        mainKey,
                                                                        childKey,
                                                                        childValue,
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                min="0"
                                                                defaultValue={
                                                                    parseFloat(
                                                                        childValue.addition
                                                                    )
                                                                        ? parseFloat(
                                                                              childValue.addition
                                                                          ).toFixed(
                                                                              2
                                                                          )
                                                                        : ""
                                                                }
                                                            />
                                                        ) : parseFloat(
                                                              childValue.addition
                                                          ) ? (
                                                            parseFloat(
                                                                childValue.addition
                                                            ).toFixed(2)
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                ) : (
                                                    <td className="text-end currency">
                                                        -
                                                    </td>
                                                )}
                                                {parseFloat(
                                                    childValue.monthly_claim
                                                ) &&
                                                childValue.grn_percentage ? (
                                                    <td
                                                        className={`text-end ${
                                                            information?.status ===
                                                                "PENDING" &&
                                                            "currency"
                                                        }`}
                                                    >
                                                        {information?.status ===
                                                        "DRAFT" ? (
                                                            <Form.Control
                                                                type="number"
                                                                className="form-box"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    omissionCalculation(
                                                                        mainKey,
                                                                        childKey,
                                                                        childValue,
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                defaultValue={
                                                                    parseFloat(
                                                                        childValue.omission
                                                                    )
                                                                        ? parseFloat(
                                                                              childValue.omission
                                                                          ).toFixed(
                                                                              2
                                                                          )
                                                                        : ""
                                                                }
                                                            />
                                                        ) : parseFloat(
                                                              childValue.omission
                                                          ) ? (
                                                            parseFloat(
                                                                childValue.omission
                                                            ).toFixed(2)
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                ) : (
                                                    <td className="text-end currency">
                                                        -
                                                    </td>
                                                )}
                                                {parseFloat(
                                                    childValue.monthly_claim
                                                ) &&
                                                childValue.grn_percentage ? (
                                                    <td
                                                        className={`text-end ${
                                                            information?.status ===
                                                                "PENDING" &&
                                                            "currency"
                                                        }`}
                                                    >
                                                        {information?.status ===
                                                        "DRAFT" ? (
                                                            <Form.Control
                                                                type="number"
                                                                className="form-box"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    deductionCalculation(
                                                                        mainKey,
                                                                        childKey,
                                                                        childValue,
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                defaultValue={
                                                                    parseFloat(
                                                                        childValue.deduction
                                                                    )
                                                                        ? parseFloat(
                                                                              childValue.deduction
                                                                          ).toFixed(
                                                                              2
                                                                          )
                                                                        : ""
                                                                }
                                                            />
                                                        ) : parseFloat(
                                                              childValue.deduction
                                                          ) ? (
                                                            parseFloat(
                                                                childValue.deduction
                                                            ).toFixed(2)
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                ) : (
                                                    <td className="text-end currency">
                                                        -
                                                    </td>
                                                )}
                                                <td className="text-end currency">
                                                    {parseFloat(
                                                        childValue.summarypayment
                                                    )
                                                        ? moneyFormatter(
                                                              childValue.summarypayment
                                                          )
                                                        : "-"}
                                                </td>
                                                <td className="text-end currency">
                                                    {parseFloat(
                                                        childValue.summarydeduction
                                                    )
                                                        ? moneyFormatter(
                                                              childValue.summarydeduction
                                                          )
                                                        : "-"}
                                                </td>
                                                <td className="text-end currency">
                                                    {parseFloat(
                                                        childValue.payment
                                                    )
                                                        ? moneyFormatter(
                                                              childValue.payment
                                                          )
                                                        : "-"}
                                                </td>
                                                <td>
                                                    {parseFloat(
                                                        childValue.reference
                                                    ) ? (
                                                        information?.status ===
                                                        "DRAFT" ? (
                                                            <Form.Control
                                                                type="text"
                                                                className="form-box"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    addReferenceNumber(
                                                                        mainKey,
                                                                        childKey,
                                                                        childValue,
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                defaultValue={
                                                                    childValue?.reference
                                                                }
                                                            />
                                                        ) : (
                                                            childValue?.reference
                                                        )
                                                    ) : (
                                                        "-"
                                                    )}
                                                </td>
                                            </tr>
                                            {childKey + 1 ===
                                                childRow.length && (
                                                <tr
                                                    className="text-end bg-warning text-black"
                                                    style={{
                                                        borderBottom:
                                                            "2px solid #6c757d",
                                                    }}
                                                >
                                                    <td>Sub-total</td>
                                                    <td className="text-end currency">
                                                        {subTotalAmount
                                                            ? moneyFormatter(
                                                                  subTotalAmount
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td className="text-end">
                                                        -
                                                    </td>
                                                    <td className="text-end">
                                                        -
                                                    </td>
                                                    <td className="text-end currency">
                                                        {subTotalMonthlyClaim
                                                            ? moneyFormatter(
                                                                  subTotalMonthlyClaim
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td className="text-end currency">
                                                        {subTotalAddition
                                                            ? moneyFormatter(
                                                                  subTotalAddition
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td className="text-end currency">
                                                        {subTotalOmission
                                                            ? moneyFormatter(
                                                                  subTotalOmission
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td className="text-end currency">
                                                        {subTotalDeduction
                                                            ? moneyFormatter(
                                                                  subTotalDeduction
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td className="text-end currency">
                                                        {subTotalCumulatativePayment
                                                            ? moneyFormatter(
                                                                  subTotalCumulatativePayment
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td className="text-end currency">
                                                        {subTotalCumulatativeDeduction
                                                            ? moneyFormatter(
                                                                  subTotalCumulatativeDeduction
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td className="text-end currency">
                                                        {subTotalPayment
                                                            ? moneyFormatter(
                                                                  subTotalPayment
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                            );
                        })
                    ) : (
                        <>
                            <tr className="bg-light font-weight-bold">
                                <td colSpan={12} className="text-center">
                                    No data
                                </td>
                            </tr>
                        </>
                    )}
                    {Boolean(Object.keys(details).length) && (
                        <tr className="font-weight-bold">
                            <td className="text-end">Total</td>
                            <td className="text-end currency">
                                {totalAmount
                                    ? moneyFormatter(totalAmount)
                                    : "-"}
                            </td>
                            <td className="text-end">-</td>
                            <td className="text-end">-</td>
                            <td className="text-end currency">
                                {totalMonthlyClaim
                                    ? moneyFormatter(totalMonthlyClaim)
                                    : "-"}
                            </td>
                            <td className="text-end currency">
                                {totalAddition
                                    ? moneyFormatter(totalAddition)
                                    : "-"}
                            </td>
                            <td className="text-end currency">
                                {totalOmission
                                    ? moneyFormatter(totalOmission)
                                    : "-"}
                            </td>
                            <td className="text-end currency">
                                {totalDeduction
                                    ? moneyFormatter(totalDeduction)
                                    : "-"}
                            </td>
                            <td className="text-end currency">
                                {totalCumulatativePayment
                                    ? moneyFormatter(totalCumulatativePayment)
                                    : "-"}
                            </td>
                            <td className="text-end currency">
                                {totalCumulatativeDeduction
                                    ? moneyFormatter(totalCumulatativeDeduction)
                                    : "-"}
                            </td>
                            <td className="text-end currency">
                                {totalPayment
                                    ? moneyFormatter(totalPayment)
                                    : "-"}
                            </td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {Boolean(Object.keys(details).length) &&
                information?.status === "DRAFT" && (
                    <>
                        <Row className="mt-4">
                            <Col md={12} className="text-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    type="submit"
                                    onClick={() => submitChanges()}
                                >
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
        </>
    );
};

import axios from "axios";
import CookieService from "service/CookieService";
import { objectToQueryString } from "./url";

const requestHandler = (request) => {
    const accessToken = CookieService.get("authToken");
    if (accessToken != null) {
        request.headers.Authorization = "Bearer " + accessToken;
    }
    return request;
};

const responseHandler = (response) => {
    return response;
};

const errorHandler = (error) => {
    let message = error?.message;
    let code = error?.code;
    if (
        error?.response?.data?.message !== "" &&
        error?.response?.data?.message !== undefined
    ) {
        message = error?.response?.data?.message;
        code = error?.response?.data.status;
    }

    const errorMessage = new Error(message);
    error.status = code;
    throw errorMessage;

    // return Promise.reject(error);
};

const api = () => {
    let _baseURL = "";

    var base_url = window.location.origin;

    if (base_url.includes("localhost")) {
        _baseURL = process.env.REACT_APP_DEV_API;
    } else if (base_url.includes("staging")) {
        _baseURL = process.env.REACT_APP_DEV_API;
    } else if (base_url.includes("app")) {
        _baseURL = process.env.REACT_APP_PROD2_API;
    } else {
        _baseURL = process.env.REACT_APP_PROD_API;
    }

    const instance = axios.create({
        baseURL: _baseURL,
    });

    instance.interceptors.request.use(
        (request) => requestHandler(request),
        (error) => errorHandler(error)
    );

    instance.interceptors.response.use(
        (response) => responseHandler(response),
        (error) => errorHandler(error)
    );

    return instance;
};

// Excel API
export const getexcel = async (url, params) => {
    let _baseURL = "";

    var base_url = window.location.origin;

    if (base_url.includes("localhost")) {
        _baseURL = process.env.REACT_APP_DEV_API;
    } else if (base_url.includes("staging")) {
        _baseURL = process.env.REACT_APP_DEV_API;
    } else if (base_url.includes("app")) {
        _baseURL = process.env.REACT_APP_PROD2_API;
    } else {
        _baseURL = process.env.REACT_APP_PROD_API;
    }

    let defaults = {
        apiURL: _baseURL,
    };
    const forbiddenError = 403;

    const options = {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: CookieService.get("authToken")
                ? `Bearer ${CookieService.get("authToken")}`
                : "",
        },
        paramsSerializer: objectToQueryString,
        params: params,
        responseType: "blob",
    };

    if (!CookieService.get("authToken")) {
        CookieService.remove("authToken");
        window.location.href = "/auth/sign-in";
    }

    return await axios
        .get(`${defaults.apiURL}${url}`, options)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.response.data.error_code === forbiddenError) {
                CookieService.remove("authToken");
                window.location.href = "/auth/sign-in";
            }

            throw error;
        });
};

export default api();

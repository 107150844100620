import React, { useEffect, useState } from "react";

import { Badge, Card } from "react-bootstrap";
import Chart from "react-apexcharts";

import { moneyFormatter } from "utils/utilities";
import usePalette from "../../../hooks/usePalette";

export const ColumnChart = ({ title, type, zone, quotationData }) => {
    const palette = usePalette();
    const [quotationBreakdown, setQuotationBreakdown] = useState([]);

    const data = [
        {
            name: "Pending",
            data: quotationBreakdown,
        },
    ];

    const options = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: "rounded",
                columnWidth: "55%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        xaxis: {
            categories: [
                "Pending",
                "Approved",
                "Completed",
                "Paid",
                "Rejected",
            ],
        },
        yaxis: {
            title: {
                text: "Total",
            },
            labels: {
                formatter: (val) => {
                    return `S$ ${moneyFormatter(val)}`;
                },
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: (val) => {
                    return `S$ ${moneyFormatter(val)}`;
                },
            },
        },
        colors: [
            palette.primary,
            palette.success,
            palette.warning,
            palette.danger,
            palette.info,
        ],
    };

    useEffect(() => {
        let tempArray = [];

        for (var key in quotationData) {
            tempArray.push(quotationData[key]);
        }

        setQuotationBreakdown(tempArray);
    }, [quotationData]);

    return (
        <Card>
            <Card.Header>
                <h6 className="h6 mb-3">
                    {title}
                    <Badge pill bg="primary" className="ms-1 me-1">
                        {type}
                    </Badge>
                    <Badge pill bg="secondary" className="me-1">
                        {zone}
                    </Badge>
                </h6>
            </Card.Header>
            <Card.Body>
                <div className="chart">
                    <Chart
                        options={options}
                        series={data}
                        type="bar"
                        height="350"
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

export default ColumnChart;

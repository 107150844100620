import React, { useState, useCallback, useEffect, useContext } from "react";

import {
    Button,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate, useParams } from "react-router-dom";
import {
    faDollarSign,
    faFile,
    faMinus,
    faParachuteBox,
    faPlus,
    faRecycle,
    faSortNumericDown,
    faStar,
    faStickyNote,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    calculateQuotationItemAmounts,
    checkSequenceDuplicates,
    commaSeparateNumber,
    errorMessage,
    roundToTwoDecimal,
    roundToThreeDecimal,
} from "utils/utilities";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { AddHeaderModal } from "components/common/works-order/modal/AddHeaderModal";
import { DeleteModalWorksOrder } from "components/common/works-order/modal/DeleteModalWorksOrder";
import { AddNoteModal } from "components/common/works-order/modal/AddNoteModal";
import { UploadDocumentModal } from "components/common/works-order/modal/UploadDocumentModal";
import { DiscountModal } from "components/common/works-order/modal/DiscountModal";
import { totalQuotaionComputation } from "utils/totalQuotationComputation";
import { ReplaceItemModal } from "../../modal/ReplaceItemModal";
import { InsertItemModal } from "components/common/works-order/modal/InsertItemModal";
import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";

const TableInput = (props) => {
    const { column, row, cell, updateData, canViewRow, canSignRow } = props;
    const { action } = useParams();

    const onChange = (e) => updateData(row.index, column.id, e.target.value);

    if (
        ((!canViewRow && canSignRow) || action === "create") &&
        row.depth === 0
    ) {
        return (
            <>
                <Form.Control
                    min={0}
                    onChange={onChange}
                    type="number"
                    value={cell.value}
                    // style={{
                    //     textAlign: "right",
                    // }}
                    step=".01"
                    autoFocus={row.original.is_focus_quantity}
                />
                {Boolean(row.original.minimum) && (
                    <small className="text-warning mt-2">
                        Minimum charge is S$ {row.original.minimum}
                    </small>
                )}
            </>
        );
    } else if (row.depth === 1 || canViewRow || !canSignRow) {
        const totalRowAmount = parseFloat(
            roundToTwoDecimal(row.original.rate * row.original.quantity)
        ).toFixed(2);

        const value =
            row.original.minimum &&
            totalRowAmount < parseInt(row.original.minimum)
                ? "Minimum"
                : cell.value;

        return <div className="text-end">{value}</div>;
    }
};

const SortInput = (props) => {
    const { column, row, cell, updateSort, canViewRow, canSignRow } = props;

    const { action } = useParams();

    const onChange = (e) => updateSort(row.index, column.id, e.target.value);

    if (
        row.depth === 0 &&
        ((!canViewRow && canSignRow) || action === "create")
    ) {
        return (
            <Form.Control
                onChange={onChange}
                type="text"
                value={cell.value}
                style={{
                    textAlign: "right",
                }}
                autoFocus={row.original.is_focus_sort}
            />
        );
    } else {
        return <div className="text-end">{cell.value || "-"}</div>;
    }
};

export const QuotationItemsForm = ({
    active,
    control,
    errors,
    finalTotal,
    isClaims = false,
    isMulti = false,
    quotationItemsStorage,
    rates,
    setFinalTotal,
    setQuotationItemsStorage,
    setValueParent = false,
    saveToLocalStorage,
    view,
    sign,
    gstConfig,
}) => {
    const navigate = useNavigate();
    const { action } = useParams();
    const notyf = useContext(NotyfContext);

    //
    // States
    //gst

    const [showDelete, setShowDelete] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
        delete: false,
    });
    const [showDocumentUpload, setShowDocumentUpload] = useState({
        open: false,
        action: "add",
    });
    const [showDiscount, setShowDiscount] = useState({
        open: false,
        action: "add",
    });
    const [showNote, setShowNote] = useState({
        open: false,
        action: "add",
    });
    const [showHeader, setShowHeader] = useState({
        open: false,
        action: "add",
    });
    const [showReplaceItem, setShowReplaceItem] = useState({
        open: false,
        action: "add",
    });
    const [showInsertItem, setShowInsertItem] = useState({
        open: false,
        action: "add",
    });
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    let isDesktop = width > 1400;

    const tableColumns = [
        {
            Header: () => <div className="text-end">Actions</div>,
            accessor: "actions",
            width: isDesktop ? 90 : 120,
            Cell: ({ row }) => {
                return (
                    <>
                        {row.depth === 0 && (
                            <div className="text-end">
                                {!row.original.is_star_rate && !view && (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Apply discounts
                                            </Tooltip>
                                        }
                                        placement="top"
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faDollarSign}
                                                className="me-1"
                                                onClick={() =>
                                                    setShowDiscount({
                                                        action: "add",
                                                        custodial_discount_quantity:
                                                            "",
                                                        custodial_discount_description:
                                                            "",
                                                        open: true,
                                                        id: row.id,
                                                    })
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                )}

                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            {view ? "View" : "Add"} document
                                        </Tooltip>
                                    }
                                >
                                    <span className="position-relative">
                                        {row?.original?.documents?.length >
                                            0 && (
                                            <span className="count-items">
                                                {
                                                    row?.original?.documents.filter(
                                                        (item) => item !== null
                                                    ).length
                                                }
                                            </span>
                                        )}
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faFile}
                                                className="me-1"
                                                onClick={() =>
                                                    setShowDocumentUpload({
                                                        action: "add",
                                                        documents:
                                                            quotationItemsStorage[
                                                                row.id
                                                            ]?.documents || "",
                                                        id: row.id,
                                                        open: true,
                                                    })
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            {view ? "View" : "Add"} note
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faStickyNote}
                                            className="me-1"
                                            onClick={() =>
                                                setShowNote({
                                                    action: "add",
                                                    id: row.id,
                                                    note:
                                                        quotationItemsStorage[
                                                            row.id
                                                        ]?.note || "",
                                                    open: true,
                                                })
                                            }
                                            size="lg"
                                        />
                                    </span>
                                </OverlayTrigger>
                                {row.depth === 0 &&
                                    !row.original.hasHeader &&
                                    !view && (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip>Add header</Tooltip>
                                            }
                                            placement="top"
                                        >
                                            <span>
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className="me-1"
                                                    onClick={() =>
                                                        setShowHeader({
                                                            open: true,
                                                            action: "add",
                                                            id: row.id,
                                                            header: "",
                                                        })
                                                    }
                                                    size="lg"
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    )}
                                {row.depth === 0 &&
                                    row.original.hasHeader &&
                                    !view && (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip>Remove header</Tooltip>
                                            }
                                            placement="top"
                                        >
                                            <span>
                                                <FontAwesomeIcon
                                                    icon={faMinus}
                                                    className="align-middle me-1"
                                                    onClick={() =>
                                                        constructRemoveHeaderRows(
                                                            row.id
                                                        )
                                                    }
                                                    size="lg"
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    )}
                                {row.depth === 0 && !view && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Insert FSR item
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faParachuteBox}
                                                className="me-1"
                                                onClick={() =>
                                                    setShowInsertItem({
                                                        open: true,
                                                        row: row,
                                                    })
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                )}
                                {row.depth === 0 && !view && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Replace FSR item
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faRecycle}
                                                className="me-1"
                                                onClick={() =>
                                                    setShowReplaceItem({
                                                        action: "add",
                                                        open: true,
                                                        row: row,
                                                    })
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                )}
                                {!view && (
                                    <OverlayTrigger
                                        overlay={<Tooltip>Remove item</Tooltip>}
                                        placement="top"
                                    >
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="me-1"
                                                onClick={() =>
                                                    openDeleteModal(row)
                                                }
                                                size="lg"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                )}
                            </div>
                        )}
                        {row.depth === 1 && !view && (
                            <div className="text-end">
                                <OverlayTrigger
                                    overlay={<Tooltip>Remove</Tooltip>}
                                    placement="top"
                                >
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            onClick={() => openDeleteModal(row)}
                                            size="lg"
                                        />
                                    </span>
                                </OverlayTrigger>
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            accessor: "serial_no",
            Cell: SortInput,
            Header: () => <div className="text-end">S/N</div>,
            width: isDesktop ? 60 : 120,
        },
        {
            accessor: "item_number",
            Cell: ({ row }) => {
                return (
                    <div className="text-end">
                        {row.original.is_star_rate ? (
                            <p className="font-weight-bold">
                                <FontAwesomeIcon
                                    icon={faStar}
                                    size="lg"
                                    className="me-2"
                                />
                                Star rate
                            </p>
                        ) : (
                            row.original.item_number
                        )}
                    </div>
                );
            },
            Header: () => <div className="text-end">Item number</div>,
            width: isDesktop ? 100 : 120,
        },
        {
            Header: "Sub header",
            accessor: "item_group_description",
        },
        {
            Header: "Description",
            accessor: "item_description",
        },
        {
            accessor: "note",
            Cell: (row) => <div>{row.value || ""}</div>,
            Header: () => <div>Note</div>,
            width: isDesktop ? 80 : 120,
        },
        {
            accessor: "quantity",
            Cell: TableInput,
            Header: () => <div className="text-end">Quantity</div>,
            width: 120,
        },
        {
            accessor: "unit",
            Cell: (row) => <div className="text-end">{row.value}</div>,
            Header: () => <div className="text-end">Unit</div>,
            width: isDesktop ? 60 : 120,
        },
        {
            accessor: "rate",
            Cell: ({ row }) => {
                return (
                    <div
                        style={{
                            textAlign: "right",
                        }}
                        className="currency"
                    >
                        {row.original.rate && row.original.is_active
                            ? commaSeparateNumber(row.original.rate)
                            : row.original.rate && !row.original.is_active
                            ? row.original.rate
                            : "-"}
                    </div>
                );
            },
            Header: () => <div className="text-end">Rate</div>,
            width: isDesktop ? 100 : 120,
        },
        {
            accessor: "amount",
            Header: () => <div className="text-end">Amount</div>,
            width: isDesktop ? 100 : 120,
            Cell: ({ row }) => {
                const parsedAmount =
                    typeof row.original.amount === "number"
                        ? roundToTwoDecimal(row.original.amount).toFixed(2)
                        : row.original.amount;

                return (
                    <div
                        style={{
                            textAlign: "right",
                        }}
                        className="currency"
                    >
                        {row.original.amount
                            ? commaSeparateNumber(parsedAmount)
                            : "-"}
                    </div>
                );
            },
        },
    ];

    //
    // Functions
    //

    const openDeleteModal = (row) => {
        setShowDelete({
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            row: row,
            severity: "primary",
        });
    };

    const updateSort = (rowIndex, columnID, value) => {
        let quotationItemsStorageClone = [...quotationItemsStorage];

        quotationItemsStorageClone = quotationItemsStorageClone.map(
            (row, index) => {
                quotationItemsStorageClone[index]["is_focus_quantity"] = false;
                quotationItemsStorageClone[index]["is_focus_sort"] = false;

                if (index === rowIndex) {
                    return {
                        ...quotationItemsStorageClone[rowIndex],
                        [columnID]: value,
                        is_focus_sort: true,
                    };
                }
                return row;
            }
        );

        computeFinalTotal(quotationItemsStorageClone);
    };

    const sortItems = () => {
        let quotationItemsStorageClone = [...quotationItemsStorage];

        const hasDuplicates = checkSequenceDuplicates(
            quotationItemsStorageClone
        );

        if (hasDuplicates) {
            notyf.open({
                type: "warning",
                message: "Sequence numbers should be unique",
            });
            return;
        }

        quotationItemsStorageClone = quotationItemsStorageClone.map(
            (row, index) => {
                if (row.header) {
                    const parentOfHeader =
                        quotationItemsStorageClone[index + 1];

                    return {
                        ...quotationItemsStorageClone[index],
                        serial_no: parseFloat(parentOfHeader?.serial_no) - 0.5,
                    };
                }
                return row;
            }
        );

        let sortedItems = quotationItemsStorageClone.sort(
            (a, b) => a.serial_no - b.serial_no
        );

        sortedItems = sortedItems.map((row, index) => {
            return {
                ...sortedItems[index],
            };
        });

        computeFinalTotal(sortedItems);
        notyf.open({
            type: "success",
            message: "Quotation items have been sorted.",
        });
    };

    const updateData = (rowIndex, columnID, value) => {
        let quotationItemsStorageClone = [...quotationItemsStorage];

        quotationItemsStorageClone = quotationItemsStorageClone.map(
            (row, index) => {
                quotationItemsStorageClone[index]["is_focus_quantity"] = false;
                quotationItemsStorageClone[index]["is_focus_sort"] = false;

                if (index === rowIndex) {
                    quotationItemsStorageClone[rowIndex][columnID] = value;
                    row.rate = row?.rate?.replace(/,/g, "");
                    let totalRowAmount = 0;

                    totalRowAmount = parseFloat(
                        roundToTwoDecimal(row?.rate * row.quantity)
                    ).toFixed(2);

                    if (row.minimum) {
                        row.minimum = parseInt(row.minimum);
                        totalRowAmount =
                            totalRowAmount < row.minimum
                                ? row.minimum
                                : totalRowAmount;
                    }

                    return {
                        ...quotationItemsStorageClone[rowIndex],
                        [columnID]: value,
                        amount: totalRowAmount,
                        is_focus_quantity: true,
                    };
                }
                return row;
            }
        );

        computeFinalTotal(quotationItemsStorageClone);
    };

    const setMainStates = useCallback(
        (persistedQuotationItems) => {
            setQuotationItemsStorage(persistedQuotationItems);
        },
        [setQuotationItemsStorage]
    );

    const computeFinalTotal = useCallback(
        (quotationItemsStorage) => {
            const finalComputed = totalQuotaionComputation(
                quotationItemsStorage,
                rates,
                false,
                100,
                gstConfig
            );

            setValueParent("fsr_total", finalComputed.fsr_total);
            setValueParent("fsr_less", finalComputed.fsr_less);
            setValueParent("fsr_sub_total", finalComputed.fsr_sub_total);

            setValueParent("star_rate_total", finalComputed.star_rate_total);
            setValueParent("star_rate_add", finalComputed.star_rate_add);
            setValueParent(
                "star_rate_sub_total",
                finalComputed.star_rate_sub_total
            );

            setValueParent("sub_total_amount", finalComputed.sub_total);
            setValueParent("total_gst", finalComputed.gst);
            setValueParent(
                "total_amount",
                parseFloat(
                    roundToTwoDecimal(
                        finalComputed.sub_total + finalComputed.gst
                    )
                ).toFixed(2)
            );

            setFinalTotal({
                fsr_total: finalComputed.fsr_total,
                fsr_less: finalComputed.fsr_less,
                fsr_sub_total: finalComputed.fsr_sub_total,

                star_rate_total: finalComputed.star_rate_total,
                star_rate_add: finalComputed.star_rate_add,
                star_rate_sub_total: finalComputed.star_rate_sub_total,

                sub_total: finalComputed.sub_total,
                gst: finalComputed.gst,
                total_amount: parseFloat(
                    roundToTwoDecimal(
                        finalComputed.sub_total + finalComputed.gst
                    )
                ).toFixed(2),

                custodial_count: finalComputed.custodial_count,
                custodial_total: finalComputed.custodial_total,
                fsr_final_total: finalComputed.fsr_final_total,
                less_percent: rates?.less_percent,
                star_rate: rates?.star_rate,
            });

            setMainStates(quotationItemsStorage);
        },
        // eslint-disable-next-line
        [setMainStates, setValueParent, rates, setFinalTotal]
    );

    const updateRowAmount = useCallback(() => {
        let quotationItemsStorageClone = [...quotationItemsStorage];

        const calculatedItems = calculateQuotationItemAmounts(
            quotationItemsStorageClone
        );

        computeFinalTotal(calculatedItems);
    }, [computeFinalTotal, quotationItemsStorage]);

    const constructDiscountRows = useCallback(() => {
        const discount_data = showDiscount.discount_data;
        const reconstructedRows = [...quotationItemsStorage];

        if (discount_data) {
            if (!reconstructedRows[showDiscount.id].subRows)
                reconstructedRows[showDiscount.id].subRows = [];

            const custodial_discount = {
                item_description: discount_data?.custodial_discount_description,
                quantity: discount_data?.custodial_discount_quantity,
                unit: "no",
                rate: "(250)",
                amount: 250.0 * discount_data?.custodial_discount_quantity,
            };

            discount_data.discount_application === "all"
                ? reconstructedRows.forEach((item) => {
                      if (item.item_number !== "Star rate") {
                          item.subRows = [];
                          item.subRows.push(custodial_discount);
                      }
                  })
                : reconstructedRows[showDiscount.id].subRows.push(
                      custodial_discount
                  );

            computeFinalTotal(reconstructedRows);
        }
    }, [computeFinalTotal, quotationItemsStorage, showDiscount]);

    const constructNoteRows = useCallback(() => {
        const note = showNote.note;
        const reconstructedRows = [...quotationItemsStorage];

        if (note) {
            reconstructedRows[showNote.id].note = note;

            setMainStates(reconstructedRows);
        }
    }, [quotationItemsStorage, setMainStates, showNote]);

    const constructDeletedRows = useCallback(() => {
        const row = showDelete.row;
        const reconstructedRows = [...quotationItemsStorage];

        if (row && row.depth === 0) {
            if (reconstructedRows[row.index]["hasHeader"]) {
                reconstructedRows.splice(row.index - 1, 2);
            } else {
                reconstructedRows.splice(row.index, 1);
            }

            computeFinalTotal(reconstructedRows);
        } else if (row && row.depth === 1) {
            const parentRow = row.id.split(".")[0];

            reconstructedRows[parentRow]["subRows"].splice(row.index, 1);

            computeFinalTotal(reconstructedRows);
        }
    }, [computeFinalTotal, quotationItemsStorage, showDelete]);

    const constructAddHeaderRows = useCallback(() => {
        const reconstructedRows = [...quotationItemsStorage];

        if (showHeader.header) {
            reconstructedRows[showHeader.id].hasHeader = true;
            reconstructedRows.splice(parseInt(showHeader.id, 10), 0, {
                header: showHeader.header,
            });

            setMainStates(reconstructedRows);
        }
    }, [quotationItemsStorage, setMainStates, showHeader]);

    const constructRemoveHeaderRows = (id) => {
        const reconstructedRows = [...quotationItemsStorage];

        delete reconstructedRows[id]["hasHeader"];
        reconstructedRows.splice(id - 1, 1);

        setMainStates(reconstructedRows);
    };

    const constructAddDocumentRows = useCallback(() => {
        const documents = showDocumentUpload.documents;
        const reconstructedRows = [...quotationItemsStorage];

        if (documents && showDocumentUpload.open === false) {
            if (reconstructedRows[showDocumentUpload.id].documents) {
                reconstructedRows[showDocumentUpload.id].documents = [
                    ...reconstructedRows[showDocumentUpload.id].documents,
                    ...documents,
                ];
            } else {
                reconstructedRows[showDocumentUpload.id].documents = documents;
            }

            setMainStates(reconstructedRows);
        }
    }, [quotationItemsStorage, setMainStates, showDocumentUpload]);

    const constructReplaceItem = useCallback(() => {
        const { newValue, row } = showReplaceItem;

        if (!newValue) return;

        const reconstructedRows = [...quotationItemsStorage];

        if (row && row.depth === 0) {
            if (reconstructedRows[row.id]["hasHeader"]) {
                reconstructedRows.splice(row.id - 1, 1);
                reconstructedRows[row.id - 1] = newValue;
            } else {
                reconstructedRows[row.id] = newValue;
            }
        } else if (row && row.depth === 1) {
            const parentRow = row.id.split(".")[0];
            reconstructedRows[parentRow]["subRows"].splice(row.id, 1);
            reconstructedRows[parentRow] = newValue;
        }

        computeFinalTotal(reconstructedRows);
    }, [computeFinalTotal, quotationItemsStorage, showReplaceItem]);

    const constructInsertItem = useCallback(() => {
        const { newValue, row, position } = showInsertItem;
        let clonedQuotationItemsStorage = [...quotationItemsStorage];

        if (!newValue) return;

        // Define index identifier to insert new value
        let index = position === "UP" ? row?.index : row?.index + 1;
        if (
            position === "UP" &&
            clonedQuotationItemsStorage[row.index]["hasHeader"]
        )
            index--;

        // Insert new value to items storage
        clonedQuotationItemsStorage = [
            ...clonedQuotationItemsStorage.slice(0, index),
            newValue,
            ...clonedQuotationItemsStorage.slice(index),
        ];

        // Re-align serial_no from 1 onwards
        let serialNoToFollow = 1;
        clonedQuotationItemsStorage = clonedQuotationItemsStorage.map((row) => {
            if (row?.header) {
                return {
                    ...row,
                };
            }
            return {
                ...row,
                serial_no: serialNoToFollow++,
            };
        });

        computeFinalTotal(clonedQuotationItemsStorage);
    }, [computeFinalTotal, quotationItemsStorage, showInsertItem]);

    const setNewVersion = useCallback(() => {
        const quotationItemsStorageClone = [...quotationItemsStorage];

        computeFinalTotal(quotationItemsStorageClone);
    }, [quotationItemsStorage, computeFinalTotal]);

    const QuotationButtons = () => {
        return (
            <>
                <OverlayTrigger
                    overlay={<Tooltip id={`tooltip-top`}>Add item</Tooltip>}
                    placement="top"
                >
                    <Button
                        className={`form-box float-end mb-2 me-2 ${
                            isMobile && "w-100"
                        }`}
                        onClick={() =>
                            navigate(
                                `/works-order/quotation-list/${action}/add-quotation-items`
                            )
                        }
                        variant="primary"
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    overlay={<Tooltip id={`tooltip-top`}>Sort items</Tooltip>}
                    placement="top"
                >
                    <Button
                        className={`form-box float-end mb-2 me-2 ${
                            isMobile && "w-100"
                        }`}
                        variant="primary"
                        onClick={() => sortItems()}
                    >
                        <FontAwesomeIcon icon={faSortNumericDown} />
                    </Button>
                </OverlayTrigger>
            </>
        );
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (quotationItemsStorage) constructDiscountRows();
        // eslint-disable-next-line
    }, [showDiscount]);

    useEffect(() => {
        if (quotationItemsStorage) constructNoteRows();
        // eslint-disable-next-line
    }, [showNote]);

    useEffect(() => {
        if (quotationItemsStorage && showDelete.delete) constructDeletedRows();
        // eslint-disable-next-line
    }, [showDelete]);

    useEffect(() => {
        if (quotationItemsStorage) constructAddHeaderRows();
        // eslint-disable-next-line
    }, [showHeader]);

    useEffect(() => {
        if (quotationItemsStorage) constructAddDocumentRows();
        // eslint-disable-next-line
    }, [showDocumentUpload]);

    useEffect(() => {
        if (quotationItemsStorage) constructReplaceItem();
        // eslint-disable-next-line
    }, [showReplaceItem]);

    useEffect(() => {
        if (quotationItemsStorage) constructInsertItem();
        // eslint-disable-next-line
    }, [showInsertItem]);

    useEffect(() => {
        if (quotationItemsStorage) setNewVersion();
        // eslint-disable-next-line
    }, [active]);

    useEffect(() => {
        updateRowAmount();
        // eslint-disable-next-line
    }, [gstConfig]);

    return (
        <>
            {!isMulti && !isClaims && (
                <Row>
                    <Col md={10} sm={12} xs={12}></Col>
                    <Col md={2} sm={12} xs={12}>
                        {!view && <QuotationButtons />}
                    </Col>
                </Row>
            )}

            <DynamicTable
                className="table-layout-fixed"
                columns={tableColumns}
                dataPagination={{ data: quotationItemsStorage || [] }}
                isExpanded
                isPaginated={false}
                isStriped={false}
                updateData={updateData}
                updateSort={updateSort}
                canViewRow={view}
                canSignRow={sign}
                withCheckbox={isClaims}
                pageSize={1000}
            />
            {!view && (
                <Row className="mb-2">
                    <Col>
                        <QuotationButtons />
                    </Col>
                </Row>
            )}
            <Row className="justify-content-between">
                <Col md={6}>
                    <Form.Group>
                        <Form.Label
                            htmlFor="remarks"
                            className="font-weight-bold"
                        >
                            Remarks
                        </Form.Label>
                        <Controller
                            control={control}
                            defaultValue=""
                            name="remarks"
                            render={({ field: { value, onChange } }) => (
                                <>
                                    {view ? (
                                        <p>{value}</p>
                                    ) : (
                                        <AutoGrowTextarea
                                            errors={errors.remarks}
                                            fieldValue={value || ""}
                                            onBlur={(e) =>
                                                saveToLocalStorage({
                                                    remarks: value,
                                                })
                                            }
                                            onChange={onChange}
                                        />
                                    )}
                                </>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="remarks"
                            render={({ message }) => errorMessage(message)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    {finalTotal.fsr_total ? (
                        <>
                            <Form.Group
                                className={`mb-4 ${isMobile && "mt-2"}`}
                            >
                                <Row className="mb-1">
                                    <Col md={4}>Less custodial count</Col>
                                    <Col md={8}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="custodial_count"
                                            render={({ field: { value } }) => (
                                                <Form.Control
                                                    disabled
                                                    type="text"
                                                    style={{
                                                        textAlign: "right",
                                                        display: "none",
                                                    }}
                                                    value={value || "-"}
                                                />
                                            )}
                                        />
                                        <Form.Control
                                            disabled
                                            style={{
                                                textAlign: "right",
                                            }}
                                            type="text"
                                            value={
                                                finalTotal?.custodial_count || 0
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Row className="mb-1">
                                    <Col md={4}>{`FSR total`}</Col>
                                    <Col md={8}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="fsr_final_total"
                                            render={({ field: { value } }) => (
                                                <Form.Control
                                                    disabled
                                                    style={{
                                                        textAlign: "right",
                                                        display: "none",
                                                    }}
                                                    type="text"
                                                    value={value || "-"}
                                                />
                                            )}
                                        />
                                        <Form.Control
                                            disabled
                                            style={{
                                                textAlign: "right",
                                            }}
                                            type="text"
                                            value={`S$ ${commaSeparateNumber(
                                                roundToTwoDecimal(
                                                    finalTotal?.fsr_final_total
                                                ).toFixed(2)
                                            )}`}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-1">
                                    <Col md={4}>{`Less custodial`}</Col>
                                    <Col md={8}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="custodial_total"
                                            render={({ field: { value } }) => (
                                                <Form.Control
                                                    disabled
                                                    style={{
                                                        textAlign: "right",
                                                        display: "none",
                                                    }}
                                                    type="text"
                                                    value={value || "-"}
                                                />
                                            )}
                                        />
                                        <Form.Control
                                            disabled
                                            style={{
                                                textAlign: "right",
                                            }}
                                            type="text"
                                            value={`S$ ${commaSeparateNumber(
                                                roundToTwoDecimal(
                                                    finalTotal?.custodial_total
                                                ).toFixed(2)
                                            )}`}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-1">
                                    <Col md={4}>
                                        Less {rates?.less_percent}%
                                    </Col>
                                    <Col md={8}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="fsr_less"
                                            render={({ field: { value } }) => (
                                                <Form.Control
                                                    disabled
                                                    style={{
                                                        textAlign: "right",
                                                        display: "none",
                                                    }}
                                                    type="text"
                                                    value={value || "-"}
                                                />
                                            )}
                                        />
                                        <Form.Control
                                            disabled
                                            style={{
                                                textAlign: "right",
                                            }}
                                            type="text"
                                            value={`S$ ${commaSeparateNumber(
                                                roundToTwoDecimal(
                                                    finalTotal?.fsr_less
                                                ).toFixed(2)
                                            )}`}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-1">
                                    <Col md={4}>Sub total (FSR)</Col>
                                    <Col md={8}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="fsr_sub_total"
                                            render={({ field: { value } }) => (
                                                <Form.Control
                                                    disabled
                                                    style={{
                                                        textAlign: "right",
                                                        display: "none",
                                                    }}
                                                    type="text"
                                                    value={value || "-"}
                                                />
                                            )}
                                        />
                                        <Form.Control
                                            disabled
                                            style={{
                                                textAlign: "right",
                                            }}
                                            type="text"
                                            value={`S$ ${commaSeparateNumber(
                                                roundToTwoDecimal(
                                                    finalTotal?.fsr_sub_total
                                                ).toFixed(2)
                                            )}`}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}
                    {finalTotal.star_rate_total ? (
                        <Form.Group className="mb-4">
                            <Row className="mb-1">
                                <Col md={4}>Star rate total</Col>
                                <Col md={8}>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="star_rate_total"
                                        render={({ field: { value } }) => (
                                            <Form.Control
                                                disabled
                                                type="text"
                                                style={{
                                                    textAlign: "right",
                                                    display: "none",
                                                }}
                                                value={value || "-"}
                                            />
                                        )}
                                    />
                                    <Form.Control
                                        disabled
                                        style={{
                                            textAlign: "right",
                                        }}
                                        type="text"
                                        value={`S$ ${commaSeparateNumber(
                                            roundToTwoDecimal(
                                                finalTotal?.star_rate_total
                                            ).toFixed(2)
                                        )}`}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col md={4}>Add {rates?.star_rate}%</Col>
                                <Col md={8}>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="star_rate_add"
                                        render={({ field: { value } }) => (
                                            <Form.Control
                                                disabled
                                                style={{
                                                    textAlign: "right",
                                                    display: "none",
                                                }}
                                                type="text"
                                                value={value || "-"}
                                            />
                                        )}
                                    />
                                    <Form.Control
                                        disabled
                                        style={{
                                            textAlign: "right",
                                        }}
                                        type="text"
                                        value={`S$ ${commaSeparateNumber(
                                            roundToTwoDecimal(
                                                finalTotal?.star_rate_add
                                            ).toFixed(2)
                                        )}`}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col md={4}>Sub total (Star rate)</Col>
                                <Col md={8}>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="star_rate_sub_total"
                                        render={({ field: { value } }) => (
                                            <Form.Control
                                                disabled
                                                style={{
                                                    textAlign: "right",
                                                    display: "none",
                                                }}
                                                type="text"
                                                value={value || "-"}
                                            />
                                        )}
                                    />
                                    <Form.Control
                                        disabled
                                        style={{
                                            textAlign: "right",
                                        }}
                                        type="text"
                                        value={`S$ ${commaSeparateNumber(
                                            roundToTwoDecimal(
                                                finalTotal?.star_rate_sub_total
                                            ).toFixed(2)
                                        )}`}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    ) : (
                        ""
                    )}
                    {finalTotal.sub_total ? (
                        <Form.Group className="mb-1">
                            <Row className="mb-1">
                                <Col md={4} className="text-primary">
                                    Sub total
                                </Col>
                                <Col md={8}>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="sub_total_amount"
                                        render={({ field: { value } }) => (
                                            <Form.Control
                                                disabled
                                                style={{
                                                    textAlign: "right",
                                                    display: "none",
                                                }}
                                                type="text"
                                                value={value || "-"}
                                            />
                                        )}
                                    />
                                    <Form.Control
                                        disabled
                                        style={{
                                            textAlign: "right",
                                        }}
                                        type="text"
                                        value={`S$ ${commaSeparateNumber(
                                            roundToTwoDecimal(
                                                finalTotal?.sub_total
                                            ).toFixed(2)
                                        )}`}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col md={4} className="text-primary">
                                    Add {gstConfig}% GST
                                </Col>
                                <Col md={8}>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="total_gst"
                                        render={({ field: { value } }) => (
                                            <Form.Control
                                                disabled
                                                style={{
                                                    textAlign: "right",
                                                    display: "none",
                                                }}
                                                type="text"
                                                value={value || "-"}
                                            />
                                        )}
                                    />
                                    <Form.Control
                                        disabled
                                        style={{
                                            textAlign: "right",
                                        }}
                                        type="text"
                                        value={`S$ ${commaSeparateNumber(
                                            roundToThreeDecimal(
                                                finalTotal?.gst
                                            ).toFixed(2)
                                        )}`}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col md={4} className="text-primary">
                                    Total
                                </Col>
                                <Col md={8}>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="total_amount"
                                        render={({ field: { value } }) => (
                                            <Form.Control
                                                disabled
                                                style={{
                                                    textAlign: "right",
                                                    display: "none",
                                                }}
                                                type="text"
                                                value={value || "-"}
                                            />
                                        )}
                                    />

                                    <Form.Control
                                        disabled
                                        style={{
                                            textAlign: "right",
                                        }}
                                        type="text"
                                        className="currency"
                                        value={`S$ ${commaSeparateNumber(
                                            finalTotal?.total_amount
                                        )}`}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    ) : (
                        ""
                    )}
                </Col>
            </Row>

            {/* Modal components */}

            <DiscountModal
                modalInfo={showDiscount}
                rates={rates}
                setModalInfo={setShowDiscount}
            />
            <UploadDocumentModal
                modalInfo={showDocumentUpload}
                quotationItemsStorage={quotationItemsStorage}
                setMainStates={setMainStates}
                setModalInfo={setShowDocumentUpload}
                view={view}
            />
            <AddNoteModal
                modalInfo={showNote}
                setModalInfo={setShowNote}
                view={view}
            />
            <DeleteModalWorksOrder
                modalInfo={showDelete}
                setModalInfo={setShowDelete}
            />
            <AddHeaderModal
                modalInfo={showHeader}
                setModalInfo={setShowHeader}
            />
            <ReplaceItemModal
                modalInfo={showReplaceItem}
                setModalInfo={setShowReplaceItem}
                view={view}
            />
            <InsertItemModal
                modalInfo={showInsertItem}
                setModalInfo={setShowInsertItem}
                view={view}
            />
        </>
    );
};

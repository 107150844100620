import React from "react";

// Layouts
import AuthLayout from "@layouts/AuthLayout";
import PrivateLayout from "@layouts/PrivateLayout";

// Auth
import Page500 from "@auth/Page500";
import Page404 from "@auth/Page404";
import SignInPage from "pages/auth/SignInPage";
import ForgotPasswordPage from "pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "pages/auth/ResetPasswordPage";

// Settings
import { DocumentRepository } from "pages/document-repository/DocumentRespository";
import { DocumentRepositoryForm } from "pages/document-repository/form/DocumentRepositoryForm";
import { Users } from "pages/settings/users/Users";
import { UsersForm } from "pages/settings/users/form/UsersForm";
import { Roles } from "pages/settings/roles/Roles";
import { RolesForm } from "pages/settings/roles/form/RolesForm";
import { PermissionsForm } from "pages/settings/permissions/form/PermissionsForm";
import { Permissions } from "pages/settings/permissions/Permissions";

//Maintenance Schedule
import { Calendar } from "pages/maintenance-schedule/Calendar";

// Inspection checklist
import { InspectionChecklist } from "pages/inspection-checklist/InspectionChecklist";
import { InspectionChecklistForm } from "pages/inspection-checklist/form/InspectionChecklistForm";
import { InspectionForm } from "pages/inspection-checklist/form/InspectionForm";
import { QuotationForm } from "pages/quotations/quotation-list/form/QuotationForm";
import { CreateEvidence } from "pages/inspection-checklist/form/CreateEvidence";
import { EditEvidence } from "pages/inspection-checklist/form/EditEvidence";
import { ViewEvidence } from "pages/inspection-checklist/form/ViewEvidence";

// Checklists
import { BMTPChecklist } from "pages/checklist/BMTPChecklist";
import { DefectsAdd } from "pages/quotations/defects-add/DefectsAdd";
import { DefectsView } from "pages/quotations/defects-add/DefectsView";
import { ClaimsList } from "pages/claims/claims-list/ClaimsList";
import { ClaimsForm } from "pages/claims/form/ClaimsForm";
import { TreeChecklist } from "pages/checklist/TreeChecklist";
import { TreeInspections } from "./pages/inspection-checklist/TreeInspections";
import { TreeInspectionChecklistForm } from "./pages/inspection-checklist/form/TreeInspectionChecklistForm";
import { TreeInspectionCreateEvidenceForm } from "./pages/inspection-checklist/form/TreeInspectionCreateEvidenceForm";
import { TreeInspectionViewEvidenceForm } from "./pages/inspection-checklist/form/TreeInspectionViewEvidenceForm";
import { InspectionPreview } from "./pages/inspection-checklist/InspectionPreview";
import BuildingMaintenanceList from "./pages/deliverables/BuildingMaintenanceList";
import TurfPlantList from "./pages/deliverables/TurfPlantList";

import { OutstandingDefects } from "./pages/inspection-checklist/OutstandingDefects";

// To Do List
import { ToDoList } from "./pages/to-do-list/ToDoList";

// Deliverables
import { BuildingMaintenance } from "./pages/deliverables/BuildingMaintenance";
import { TurfPlant } from "./pages/deliverables/TurfPlant";
import { FsrForm } from "./pages/fsr/form/FsrForm";

// Dashboard
import { DashboardQuotation } from "pages/dashboard/DashboardQuotation";
import { DashboardInspection } from "pages/dashboard/DashboardInspection";
import { DashboardDefect } from "pages/dashboard/DashboardDefects";
import { DashboardAPV } from "pages/dashboard/DashboardAPV";
import { DashboardChecklistSummary } from "pages/dashboard/DashboardChecklistSummary";
import { DashboardChecklistStatus } from "pages/dashboard/DashboardChecklistStatus";
import { DashboardMonthlySummary } from "pages/dashboard/DashboardMonthlySummary";

// COP
import { CopItem } from "pages/cop/cop-item/CopItem";
import { CopList } from "pages/cop/cop-list/CopList";
import { SOI } from "pages/soi/SOI";
import { SOIForm } from "pages/soi/form/SOIForm";
import { LiquidatedDamages } from "pages/liquidated-damages/LiquidatedDamages";
import { LiquidatedDamagesForm } from "pages/liquidated-damages/form/LiquidatedDamagesForm";
import { KPI } from "pages/kpi/KPI";
import { KPIForm } from "pages/kpi/form/KPIForm";

// FSR
import { FsrList } from "pages/fsr/FsrList";

// Schools
import { Schools } from "pages/settings/schools/Schools";
import {
    ManageUsers,
    SchoolManageUsers,
} from "pages/tools/user-organizations/components/ManageUsers";
import { ManageAssignments } from "pages/tools/user-organizations/components/ManageAssignments";

import { SchoolContract } from "pages/settings/schools/form/SchoolContract";

// Quotations
import { QuotationList } from "pages/quotations/quotation-list/QuotationList";
import { QuotationItemsAdd } from "pages/quotations/quotation-items-add/QuotationItemsAdd";
import { QuotationWorkServiceOrder } from "pages/quotations/quotation-list/work-service-order/QuotationWorkServiceOrder";
import { QuotationPreview } from "./pages/quotations/quotation-list/QuotationPreview";
import { WsoList } from "pages/wso/wso-list/WsoList";
import { AdhocWorkServiceOrderPreview } from "pages/wso/work-service-order/AdhocWorkServiceOrderPreview";
import { AdhocWorkServiceOrder } from "pages/wso/work-service-order/AdhocWorkServiceOrder";

// Export excel
import { ExcelExport } from "pages/excel-export/ExcelExport";
import { ExcelExportForm } from "pages/excel-export/form/ExcelExportForm";

// Others
import Page403 from "pages/auth/Page403";
import { SchoolUpload } from "pages/settings/schools/form/SchoolUpload";

// Tools
import { UserOrganization } from "pages/tools/user-organizations/UserOrganization";

const routes = [
    {
        path: "auth",
        element: <AuthLayout />,
        children: [
            {
                path: "sign-in",
                element: <SignInPage />,
            },
            {
                path: "404",
                element: <Page404 />,
            },
            {
                path: "403",
                element: <Page403 />,
            },
            {
                path: "500",
                element: <Page500 />,
            },
        ],
    },
    {
        path: "/",
        element: <PrivateLayout />,
        children: [
            {
                path: "/",
                element: <DashboardQuotation />,
            },
            {
                path: "/dashboard/inspection/:tab",
                element: <DashboardInspection />,
            },
            {
                path: "/dashboard/defect/:tab",
                element: <DashboardDefect />,
            },
            {
                path: "/dashboard/apv/:tab",
                element: <DashboardAPV />,
            },
            {
                path: "/dashboard/monthly-summary/:tab",
                element: <DashboardMonthlySummary />,
            },
            {
                path: "/dashboard/checklist-summary/:tab",
                element: <DashboardChecklistSummary />,
            },
            {
                path: "/dashboard/checklist-status/:tab",
                element: <DashboardChecklistStatus />,
            },
        ],
    },
    {
        path: "to-do",
        element: <PrivateLayout />,
        children: [
            {
                path: "",
                element: <ToDoList />,
            },
        ],
    },
    {
        path: "maintenance-schedules",
        element: <PrivateLayout />,
        children: [
            {
                path: "",
                element: <Calendar />,
            },
        ],
    },
    {
        path: "checklist",
        element: <PrivateLayout />,
        children: [
            {
                path: "bm-tp-checklist",
                element: <BMTPChecklist />,
            },
            {
                path: "tree-checklists",
                element: <TreeChecklist />,
            },
        ],
    },
    {
        path: "inspection-checklist",
        element: <PrivateLayout />,
        children: [
            {
                path: "inspection-servicing",
                element: <InspectionChecklist />,
            },
            {
                path: "inspection-servicing/:id",
                element: <InspectionChecklistForm />,
            },
            {
                path: "inspection-servicing/:id/preview",
                element: <InspectionPreview />,
            },
            {
                path: "inspection-servicing-form/:id",
                element: <InspectionForm />,
            },
            {
                path: "inspection-servicing/:inspection_schedule_id/:checklist_item_id/:checklist_sub_item_id/create-evidence",
                element: <CreateEvidence />,
            },
            {
                path: "inspection-servicing/:defect_id/:inspection_schedule_id/:checklist_item_id/:checklist_sub_item_id/edit-evidence",
                element: <EditEvidence />,
            },
            {
                path: "inspection-servicing/:inspection_schedule_id/:checklist_item_id/:checklist_sub_item_id/view-evidence",
                element: <ViewEvidence />,
            },

            {
                path: "inspection-servicing-pending",
                element: <InspectionChecklist status="PENDING_CONFIRMATION" />,
            },
            {
                path: "inspection-servicing-pending/:id",
                element: (
                    <InspectionChecklistForm status="PENDING_CONFIRMATION" />
                ),
            },
            {
                path: "inspection-servicing-pending/:id/preview",
                element: <InspectionPreview status="PENDING_CONFIRMATION" />,
            },
            {
                path: "inspection-servicing-pending/:inspection_schedule_id/:checklist_item_id/:checklist_sub_item_id/view-evidence",
                element: <ViewEvidence status="PENDING_CONFIRMATION" />,
            },

            {
                path: "tree-inspections",
                element: <TreeInspections />,
            },
            {
                path: "tree-inspections/:id",
                element: <TreeInspectionChecklistForm />,
            },
            {
                path: "tree-inspections/:tree_inspection_id/:tree_checklist_item_id/create-evidence",
                element: <TreeInspectionCreateEvidenceForm />,
            },
            {
                path: "tree-inspections/:tree_inspection_id/:tree_checklist_item_id/view-evidence",
                element: <TreeInspectionViewEvidenceForm />,
            },
            {
                path: "outstanding-defects",
                element: <OutstandingDefects />,
            },
        ],
    },
    {
        path: "works-order",
        element: <PrivateLayout />,
        children: [
            {
                path: "quotation-list",
                element: <QuotationList />,
            },
            {
                path: "quotation-list/:action/add-defect",
                element: <DefectsAdd />,
            },
            {
                path: "quotation-list/:action/add-defect/view",
                element: <DefectsView />,
            },
            {
                path: "quotation-list/:action/add-quotation-items",
                element: <QuotationItemsAdd />,
            },
            {
                path: "quotation-list/:id/work-service-order",
                element: <QuotationWorkServiceOrder />,
            },
            {
                path: "quotation-list/:id/preview",
                element: <QuotationPreview />,
            },
            {
                path: "quotation-list/:action",
                element: <QuotationForm />,
            },
            {
                path: "claims",
                element: <ClaimsList />,
            },
            {
                path: "claims/:action",
                element: <ClaimsForm />,
            },
            {
                path: "claims/:action/:invoiceEdit",
                element: <ClaimsForm />,
            },
            {
                path: "fsr",
                element: <FsrList />,
            },
            {
                path: "fsr/:id",
                element: <FsrForm />,
            },
            {
                path: "wso",
                element: <WsoList />,
            },
            {
                path: "wso/:id/preview",
                element: <AdhocWorkServiceOrderPreview />,
            },
            {
                path: "wso/:action",
                element: <AdhocWorkServiceOrder />,
            },
            // {
            //     path: "wso/:action",
            //     element: <ClaimsForm />,
            // },
        ],
    },
    {
        path: "settings",
        element: <PrivateLayout />,
        children: [
            {
                path: "users",
                element: <Users />,
            },
            {
                path: "users/:action",
                element: <UsersForm />,
            },
            {
                path: "roles",
                element: <Roles />,
            },
            {
                path: "roles/:action",
                element: <RolesForm />,
            },
            {
                path: "permissions",
                element: <Permissions />,
            },
            {
                path: "permissions/:action",
                element: <PermissionsForm />,
            },
            {
                path: "schools",
                element: <Schools />,
            },
            {
                path: "schools/contract/:action",
                element: <SchoolContract />,
            },
            {
                path: "schools/file-upload/:action",
                element: <SchoolUpload />,
            },
        ],
    },
    {
        path: "deliverables",
        element: <PrivateLayout />,
        children: [
            {
                path: "building-maintenance",
                element: <BuildingMaintenanceList />,
            },
            {
                path: "building-maintenance/:id",
                element: <BuildingMaintenance />,
            },
            {
                path: "turf-and-plant",
                element: <TurfPlantList />,
            },
            {
                path: "turf-and-plant/:id",
                element: <TurfPlant />,
            },
        ],
    },
    {
        path: "document-repository",
        element: <PrivateLayout />,
        children: [
            {
                path: "",
                element: <DocumentRepository />,
            },
            {
                path: ":action",
                element: <DocumentRepositoryForm />,
            },
        ],
    },
    {
        path: "excel-export",
        element: <PrivateLayout />,
        children: [
            {
                path: "",
                element: <ExcelExport />,
            },
            {
                path: ":action",
                element: <ExcelExportForm />,
            },
        ],
    },
    {
        path: "cop",
        element: <PrivateLayout />,
        children: [
            {
                path: "soi",
                element: <SOI />,
            },
            {
                path: "soi/:action",
                element: <SOIForm />,
            },
            {
                path: "ld",
                element: <LiquidatedDamages />,
            },
            {
                path: "ld/:action",
                element: <LiquidatedDamagesForm />,
            },
            {
                path: "kpi",
                element: <KPI />,
            },
            {
                path: "kpi/:action",
                element: <KPIForm />,
            },
            {
                path: "contracts",
                element: <CopList />,
            },
            {
                path: "contracts/:action/:tab",
                element: <CopItem />,
            },
        ],
    },
    {
        path: "tools",
        element: <PrivateLayout />,
        children: [
            {
                path: "user-organization",
                element: <UserOrganization />,
            },
            {
                path: "user-organization/manage-users/:action",
                element: <ManageUsers />,
            },
            {
                path: "user-organization/manage-assignments/:action",
                element: <ManageAssignments />,
            },
        ],
    },
    {
        path: "/",
        element: <AuthLayout />,
        children: [
            {
                path: "reset-password/:token/:email",
                element: <ResetPasswordPage />,
            },
            {
                path: "forgot-password",
                element: <ForgotPasswordPage />,
            },
        ],
    },
    {
        path: "*",
        element: <AuthLayout />,
        children: [
            {
                path: "*",
                element: <Page404 />,
            },
        ],
    },
];

export default routes;

import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import NotyfContext from "contexts/NotyfContext";
import Select from "react-select";
import DynamicTable from "components/ui/DynamicTable";
import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Container,
    Col,
    OverlayTrigger,
    Row,
    Tooltip,
    Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faEject,
    faFilePdf,
    faFlagCheckered,
    faPen,
    faPlus,
    faRecycle,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "hooks/useAuth";
import { DeleteModal } from "components/DeleteModal";
import { LoadingContext } from "App";
import { constructReport, formatConfigFields } from "utils/utilities";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { category_option } from "config";
import { RejectWorkServiceOrderModal } from "../components/RejectWorkServiceOrderModal";
import * as schoolApi from "@api/schoolApi";
import * as wsoApi from "@api/wsoApi";

export const RerenderContextWorkServiceOrder = createContext();

export const WsoList = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const location = useLocation();
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;

    const { hasRole, user } = useAuth();

    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    const hasRoleCollection = {
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isTechnician: hasRole("TECHNICIAN"),
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isQS: hasRole("MA-QS"),
        isAdmin: hasRole("ADMIN"),
    };

    //
    // States
    //

    const [configColumn, setConfigColumn] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [searchValue, setSearchValue] = useState({
        reference_number: "",
        school: "",
        status: "",
    });
    const [schools, setSchools] = useState([]);
    const [statusSearch] = useState([
        { value: "PENDING", label: "Pending" },
        { value: "APPROVED", label: "Approved" },
        { value: "REJECTED", label: "Rejected" },
    ]);
    // eslint-disable-next-line
    const [modalSupportingDocument, setModalSupportingDocument] = useState({
        id: null,
        open: false,
        severity: "danger",
    });
    const [wso, setWso] = useState([]);
    const [modalConfirmation, setModalConfirmation] = useState({
        notifMsg: "",
        open: false,
        api: "",
    });
    const [modalReject, setModalReject] = useState({
        id: null,
        open: false,
        severity: "danger",
    });

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: "150px",
        Cell: ({ row, cell }) => {
            const canTechnicianEdit =
                ["REJECTED", "DRAFT"].includes(row.original.status) &&
                hasRoleCollection.isTechnician;

            const canTechnicianSign =
                hasRoleCollection.isTechnician &&
                row.original.status === "PENDING" &&
                !row.original?.pending_approval &&
                !row.original?.approvals?.MA?.status === "ENDORSED";

            const canTechnicianView =
                (hasRoleCollection.isTechnician &&
                    row.original.status !== "REJECTED" &&
                    row.original.status !== "DRAFT" &&
                    row.original.status === "PENDING") ||
                ["COMPLETED", "VOID"].includes(row.original.status);

            const canTcEdit =
                ["REJECTED", "DRAFT"].includes(row.original.status) &&
                hasRoleCollection.isTermContractor;

            const canTcSign =
                row.original.status === "PENDING" &&
                !row.original?.pending_approval &&
                !row.original?.approvals?.MA?.status === "ENDORSED";

            const canTcView =
                (row.original.status !== "REJECTED" &&
                    row.original.status !== "DRAFT" &&
                    row.original.status === "PENDING" &&
                    hasRoleCollection.isTermContractor) ||
                ["COMPLETED", "VOID"].includes(row.original.status);

            const canMaView =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isManagingAgent &&
                row.original?.pending_approval?.approval_type !== "MA";

            const canOMView =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isOM &&
                row.original?.pending_approval?.approval_type !== "OM";

            const canMaSign =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isManagingAgent &&
                row.original?.pending_approval?.approval_type === "MA";

            const canOMSign =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isOM &&
                row.original?.pending_approval?.approval_type === "OM";

            const canAdminView =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isAdmin;

            const isApproved = row.original.status === "APPROVED";

            const wsoLink =
                cell.row.original.category === "QUOTATION" &&
                cell.row.original.status === "DRAFT" &&
                cell.row.original.quotation_id
                    ? `${window.location.origin}/works-order/quotation-list/${row.original.quotation_id}/work-service-order`
                    : `${location.pathname}/${cell.row.original.id}`;

            let downloadUrl = constructReport(
                row.original.file_info?.bucket,
                row.original.file_info?.file_path,
                `${row.original?.reference_number}-${row.original?.date}`,
                false
            );

            let previewUrl = constructReport(
                row.original.file_info?.bucket,
                row.original.file_info?.file_path,
                `${row.original?.reference_number}-${row.original?.date}`,
                true
            );

            return (
                <>
                    {(canTcView ||
                        canMaView ||
                        isApproved ||
                        canAdminView ||
                        canOMView ||
                        canTechnicianView) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View Work service order</Tooltip>}
                        >
                            <a
                                href={wsoLink}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                                style={{
                                    color: "#3F80EA",
                                }}
                            >
                                View
                            </a>
                        </OverlayTrigger>
                    )}

                    {(canTcSign ||
                        canMaSign ||
                        canOMSign ||
                        canTechnicianSign) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Sign Work service order</Tooltip>}
                        >
                            <a
                                href={wsoLink}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faPen}
                                        className="align-middle me-1"
                                        size="lg"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                    )}
                    {(canTcEdit || canTechnicianEdit) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit Work service order</Tooltip>}
                        >
                            <a
                                href={wsoLink}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faPen}
                                        className="align-middle me-1"
                                        size="lg"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                    )}
                    {/* PENDING DELETE API */}
                    {row.original.status === "DRAFT" &&
                        (hasRoleCollection.isTermContractor ||
                            hasRoleCollection.isAdmin) && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip>Delete Work service order</Tooltip>
                                }
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            openDeleteModal(
                                                cell.row.original.id,
                                                "delete"
                                            );
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {row.original.status !== "DRAFT" &&
                        row.original.file_info?.bucket &&
                        row.original.file_info?.file_path && (
                            <OverlayTrigger
                                overlay={<Tooltip>Download report</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        className="align-middle me-1"
                                        onClick={() => window.open(downloadUrl)}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}

                    {row.original.file_info?.bucket &&
                        row.original.file_info?.file_path && (
                            <OverlayTrigger
                                overlay={<Tooltip>Preview report</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        className="align-middle me-1"
                                        onClick={() => window.open(previewUrl)}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}

                    {(hasRoleCollection.isTermContractor ||
                        hasRoleCollection.isTechnician) &&
                        row?.original?.pending_approval?.approval_type ===
                            "TC" && (
                            <OverlayTrigger
                                overlay={<Tooltip>Post approval</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faFlagCheckered}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            setModalSupportingDocument({
                                                id: cell.row.original.id,
                                                open: true,
                                                severity: "primary",
                                            });
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {row.original.status !== "DRAFT" &&
                        (hasRoleCollection.isTermContractor ||
                            hasRoleCollection.isAdmin) && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Regenerate report</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faRecycle}
                                        className="align-middle me-1"
                                        onClick={() =>
                                            setModalConfirmation({
                                                notifMsg:
                                                    "Are you sure you want to generate this report?",
                                                subNotifMsg:
                                                    "Note: Report will take an estimate of 3 minutes to be generated.",
                                                open: true,
                                                api: () =>
                                                    regenerateReportApi(
                                                        cell?.row?.original?.id
                                                    ),
                                            })
                                        }
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}

                    {row.original.display_status ===
                        "PENDING FOR MA APPROVAL" &&
                        (hasRoleCollection.isTermContractor ||
                            hasRoleCollection.isAdmin) && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip>Reject work service order</Tooltip>
                                }
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faEject}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            setModalReject({
                                                id: cell.row.original.id,
                                                open: true,
                                                severity: "primary",
                                            });
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                </>
            );
        },
    };

    //
    // Functions
    //

    const search = () => {
        getWso(searchValue);
    };

    const deleteClaim = useCallback(
        async (id) => {
            try {
                const response = await wsoApi.deleteWso(id);

                if (response.status === 200) {
                    notyf.open({
                        type: "success",
                        message: response.data.message,
                    });
                    setRerender(!rerender);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        // eslint-disable-next-line
        [notyf]
    );

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const getWso = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await wsoApi.getWsos(param);
                if (response.status === 200) {
                    setWso(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await wsoApi.getWsoConfig(param);
                setConfigColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const regenerateReportApi = async (id) => {
        try {
            setIsLoadingActive(true);
            const response = await wsoApi.regenerateReport(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message:
                        "Successfully regenerated report. Please wait for 3 minutes.",
                });

                setRerender(!rerender);
                setIsLoadingActive(false);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: error,
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getWso();
    }, [getWso, rerender]);

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    useEffect(() => {
        if (hasRoleCollection.isOM && user?.school_id) {
            setSearchValue({
                ...searchValue,
                school: user?.school_id,
            });
        }
    }, []);

    return (
        <RerenderContextWorkServiceOrder.Provider
            value={[rerender, setRerender]}
        >
            <Helmet title="WSO" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Work service order</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <Row>
                                    <Col md={2} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className={`form-box ${
                                                isMobile && "w-100"
                                            }`}
                                            placeholder="Reference number"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    reference_number:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Select
                                            isClearable={true}
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    school: data
                                                        ? data.value
                                                        : "",
                                                });
                                            }}
                                            options={schools}
                                            placeholder="Select school"
                                            className={`${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            defaultValue={
                                                hasRoleCollection.isOM &&
                                                user?.school_id
                                                    ? {
                                                          label: user?.school
                                                              ?.details
                                                              ?.school_name,
                                                          value: user?.school_id,
                                                      }
                                                    : ""
                                            }
                                        />
                                    </Col>
                                    <Col md={2} sm={12} xs={12}>
                                        <Select
                                            isClearable={true}
                                            onChange={(data) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    status: data
                                                        ? data.value
                                                        : "",
                                                })
                                            }
                                            options={statusSearch}
                                            placeholder="Select status"
                                            className={`${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Select
                                            isClearable={true}
                                            onChange={(data) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    category: data
                                                        ? data.value
                                                        : "",
                                                })
                                            }
                                            options={category_option}
                                            placeholder="Select category"
                                            className={`${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                        />
                                    </Col>
                                    <Col md={1} sm={12} xs={12}>
                                        <Button
                                            variant="primary"
                                            className={`me-1 mb-1 form-box ${
                                                isMobile && "w-100 mt-2"
                                            }`}
                                            onClick={search}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                    {(hasRoleCollection.isTermContractor ||
                                        hasRoleCollection.isTechnician) && (
                                        <Col md={1} sm={12} xs={21}>
                                            <h1 className="h3 mb-3">
                                                <Button
                                                    variant="primary"
                                                    className={`float-end form-box ${
                                                        isMobile && "w-100 mt-2"
                                                    }`}
                                                    onClick={() =>
                                                        navigate(
                                                            `/works-order/wso/create`
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                    />
                                                </Button>
                                            </h1>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && wso?.data && (
                            <DynamicTable
                                dataPagination={wso}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                                api={getWso}
                                searchValue={searchValue}
                                hasTopHorizontalScroll
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteClaim}
            />
            <ConfirmationModal
                modalInfo={modalConfirmation}
                setModalInfo={setModalConfirmation}
            />
            <RejectWorkServiceOrderModal
                modalInfo={modalReject}
                setModalInfo={setModalReject}
            />
        </RerenderContextWorkServiceOrder.Provider>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Container, Row, Button, Col, Breadcrumb } from "react-bootstrap";
import { Card, Form } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";

import * as permissionsApi from "@api/permissionsApi";
import NotyfContext from "contexts/NotyfContext";

export const PermissionsForm = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const create = action === "create";
    const notyf = useContext(NotyfContext);

    //
    // States
    //

    const [options, setOptions] = useState([]);

    const schema = yup.object().shape({
        name: yup.string().required("This field is required"),
        slug: yup.string().required("This field is required"),
        category: yup.object().required("This field is required").nullable(),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const permissionApiCall = async (data) => {
        data.category = data.category.value;

        try {
            create
                ? await permissionsApi.createPermission(data)
                : await permissionsApi.updatePermission(action, data);

            notyf.open({
                type: "success",
                message: `Successfully ${
                    create ? "created" : "updated"
                } permission`,
            });
            navigate(`/settings/permissions`);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getPermission = useCallback(async () => {
        try {
            const response = await permissionsApi.getPermission(action);
            if (response.status === 200) {
                const data = response.data.data;
                reset({
                    name: data.name,
                    slug: data.slug,
                    category: data.category,
                    description: data.description,
                });
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [action, notyf, reset]);

    const getCategoryConfig = useCallback(
        async (param = null) => {
            try {
                const response = await permissionsApi.getCategoryConfig(param);

                setOptions(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    //
    // UseEffects
    //

    useEffect(() => {
        action !== "create" && getPermission();
        getCategoryConfig();
    }, [action, getPermission, getCategoryConfig]);

    return (
        <React.Fragment>
            <Helmet title={`${create ? "Create" : "Edit"} permissions`} />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">
                            {create ? "Create" : "Edit"} permission
                        </h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate("/settings/roles")}
                            >
                                Permissions
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {create ? "Create" : "Edit"}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Row>
                            <Form className="col-md-4 col-sm-12 mb-2">
                                <Form.Label>Name</Form.Label>
                                <Controller
                                    control={control}
                                    name="name"
                                    defaultValue=""
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <Form.Control
                                            type="text"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            className={`form-box ${
                                                errors.name && "is-invalid"
                                            }`}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="name"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form>
                            <Form className="col-md-4 col-sm-12 mb-2">
                                <Form.Label>Slug</Form.Label>
                                <Controller
                                    control={control}
                                    name="slug"
                                    defaultValue=""
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <Form.Control
                                            type="text"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            className={`form-box ${
                                                errors.slug && "is-invalid"
                                            }`}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="slug"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form>
                            <Form className="col-md-4 col-sm-12 mb-2">
                                <Form.Label>Category</Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="category"
                                    render={({
                                        field: { onBlur, onChange, value },
                                    }) => (
                                        <Select
                                            className="is-invalid react-select-container"
                                            classNamePrefix="react-select"
                                            options={options}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={options?.find(
                                                (o) => o.value === value
                                            )}
                                            onChange={onChange}
                                            menuPlacement="top"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="category"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form>
                        </Row>
                        <Row>
                            <Form className="col-md-12 col-sm-12 mb-2">
                                <Form.Label>Description</Form.Label>
                                <Controller
                                    control={control}
                                    name="description"
                                    defaultValue=""
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <Form.Control
                                            as="textarea"
                                            value={value == null ? "" : value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            className={
                                                errors.description &&
                                                "is-invalid"
                                            }
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="description"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form>
                        </Row>
                        <Row className="my-3">
                            <Col md={12} className="text-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    type="submit"
                                    onClick={handleSubmit(permissionApiCall)}
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={() =>
                                        navigate("/settings/permissions")
                                    }
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

import React, { useState, useContext, useEffect } from "react";

import { Helmet } from "react-helmet-async";
import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import DatePicker from "react-datepicker";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import * as schoolApi from "@api/schoolApi";

const schema = yup.object().shape({});

export const SchoolContract = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const create = action === "create";
    const notyf = useContext(NotyfContext);

    //
    // States
    //

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });
    const { fields } = useFieldArray({
        control,
        name: "contracts",
    });
    const [school, setSchool] = useState([]);

    //
    // Functions
    //

    const submitSchoolContract = async (data) => {
        try {
            let updatedContract = data.contracts.map((item) => {
                return {
                    ...item,
                    from_date: new Date(item.from_date).toLocaleDateString(
                        "en-CA"
                    ),
                    to_date: new Date(item.to_date).toLocaleDateString("en-CA"),
                };
            });

            data.school_id = action;
            data.contracts = updatedContract;

            const response = await schoolApi.updateSchoolContract(data);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "School contract updated",
                });
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getSchoolContract = async () => {
        try {
            const response = await schoolApi.getSchoolContract(action);

            let updatedData = response.data.data.map((item) => {
                return {
                    ...item,
                    from_date: new Date(item.from_date),
                    to_date: new Date(item.to_date),
                };
            });

            reset({
                contracts: updatedData,
            });
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getSchool = async () => {
        try {
            const response = await schoolApi.getSchool(action);

            setSchool(response.data.data);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const yearLookup = (year) => {
        const lookup = {
            year1: "Year 1",
            year2: "Year 2",
            year3: "Year 3",
            year4: "Year 4",
            year5: "Year 5",
        };
        return lookup[year];
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getSchoolContract();
        getSchool();
    }, [action]);

    return (
        <React.Fragment>
            <Helmet title={`${create ? "Create" : "Edit"} schools`} />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">
                            {create ? "Create" : "Edit"} school contract
                        </h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate("/settings/schools")}
                            >
                                Schools
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Edit school contract
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Header className="pb-1">
                        <h4>{school?.name}</h4>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            {fields.length ? (
                                fields.map((item, key) => {
                                    return (
                                        <>
                                            <Row className="mb-2">
                                                <Col md={2}>
                                                    <h4>
                                                        <Badge>
                                                            {yearLookup(
                                                                item.contract_year
                                                            )}
                                                        </Badge>
                                                    </h4>
                                                </Col>
                                            </Row>
                                            <Row key={key} className="mb-4">
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            From
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.from_date`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <DatePicker
                                                                    dateFormat="yyyy-MM-dd"
                                                                    selected={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    className="form-box form-control"
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="from"
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            To
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.to_date`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <DatePicker
                                                                    dateFormat="yyyy-MM-dd"
                                                                    selected={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    className="form-box form-control"
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="to_date"
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            Scheduled
                                                            maintenance
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.schedule_maintenance`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    type="number"
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={`form-box ${
                                                                        errors.schedule_maintenance &&
                                                                        "is-invalid"
                                                                    }`}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`contracts.${key}.schedule_maintenance`}
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            General R&R
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.general_r_and_r`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    type="number"
                                                                    value={
                                                                        value ||
                                                                        ""
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={`form-box ${
                                                                        errors.general_r_and_r &&
                                                                        "is-invalid"
                                                                    }`}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`contracts.${key}.general_r_and_r`}
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            Custodial repairs
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.custodial_repairs`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    type="number"
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={`form-box ${
                                                                        errors.custodial_repairs &&
                                                                        "is-invalid"
                                                                    }`}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`contracts.${key}.custodial_repairs`}
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            School field
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.school_field`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    type="number"
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={`form-box ${
                                                                        errors.school_field &&
                                                                        "is-invalid"
                                                                    }`}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`contracts.${key}.school_field`}
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            Landscape exclude
                                                            school field
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.landscape_exclude_school_field`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    type="number"
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={`form-box ${
                                                                        errors.landscape_exclude_school_field &&
                                                                        "is-invalid"
                                                                    }`}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`contracts.${key}.landscape_exclude_school_field`}
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            Synthetic field
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.school_field`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    type="number"
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={`form-box ${
                                                                        errors.synthetic_field &&
                                                                        "is-invalid"
                                                                    }`}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`contracts.${key}.school_field`}
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            Landscape pond
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.landscape_pond`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    type="number"
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={`form-box ${
                                                                        errors.landscape_pond &&
                                                                        "is-invalid"
                                                                    }`}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`contracts.${key}.landscape_pond`}
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="font-weight-bold">
                                                            Landscape garden
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`contracts.${key}.landscape_garden`}
                                                            defaultValue=""
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    onBlur,
                                                                },
                                                            }) => (
                                                                <Form.Control
                                                                    type="number"
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    className={`form-box ${
                                                                        errors.landscape_garden &&
                                                                        "is-invalid"
                                                                    }`}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`contracts.${key}.landscape_garden`}
                                                            render={({
                                                                message,
                                                            }) => (
                                                                <small className="text-danger">
                                                                    {message}
                                                                </small>
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                    );
                                })
                            ) : (
                                <Row>
                                    <Col md="12" className="text-center">
                                        <p>
                                            No school contract for{" "}
                                            {school?.name}
                                        </p>
                                    </Col>
                                </Row>
                            )}
                            <Row className="pt-4">
                                <Col md={12} className="text-center">
                                    {Boolean(fields.length) && (
                                        <Button
                                            className="me-2"
                                            variant="primary"
                                            type="submit"
                                            onClick={handleSubmit(
                                                submitSchoolContract
                                            )}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate("/settings/schools")
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

import React, { useContext, useState, useEffect } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Select from "react-select";
import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import { ErrorMessage } from "@hookform/error-message";
import { errorMessage } from "utils/utilities";
import { RerenderContextQuotation } from "../QuotationList";
import { LoadingContext } from "App";
import * as quotationsApi from "@api/quotationsApi";

export const CloneQuotationModal = ({ modalInfo, setModalInfo, schools }) => {
    const notyf = useContext(NotyfContext);
    const [rerender, setRerender] = useContext(RerenderContextQuotation);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    const { user } = useAuth();

    //
    // States
    //

    const schema = yup.object().shape({
        school: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        trade: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .nullable()
            .required("This field is required."),
    });

    const [schoolsMapped, setSchoolsMapped] = useState([]);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const closeModal = () => {
        setModalInfo({ open: false });
        reset({});
    };

    const cloneQuotation = async (data) => {
        data.school_id = data.school.value;
        data.trade = data.trade.value;

        try {
            setIsLoadingActive(true);
            const response = await quotationsApi.cloneQuotation(
                modalInfo.id,
                data
            );

            if (response.status === 201) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "success",
                    message: "Successfully cloned quotation",
                });
                closeModal();
                setRerender(!rerender);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    useEffect(() => {
        const schoolsMapped = schools.map((field, index) => {
            return { label: field.name, value: field.id };
        });

        setSchoolsMapped(schoolsMapped);
    }, []);

    useEffect(() => {
        reset({
            school: schoolsMapped?.filter((item) => {
                return item.value === modalInfo?.params?.school_id;
            })[0],
            trade: user?.trade?.filter((item) => {
                return item.value === modalInfo?.params?.trade;
            })[0],
        });
    }, [modalInfo]);

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "add" })}
            size="lg"
            centered
        >
            <Modal.Header closeButton>Clone quotation</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <p>
                                Please select the following details to proceed.
                            </p>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="remarks"
                                    className="font-weight-bold"
                                >
                                    School
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="school"
                                    render={({
                                        field: { value, onChange, onBlur, ref },
                                    }) => (
                                        <Select
                                            inputRef={ref}
                                            className={`${isMobile && "w-100"}`}
                                            classNamePrefix="react-select"
                                            options={schools}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={value}
                                            onChange={onChange}
                                            placeholder="Select school"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="school"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="remarks"
                                    className="font-weight-bold"
                                >
                                    Trade
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="trade"
                                    render={({
                                        field: { value, onChange, ref },
                                    }) => (
                                        <Select
                                            isClearable={true}
                                            inputRef={ref}
                                            onChange={onChange}
                                            className={`react-select-container ${
                                                errors?.trade && "is-invalid"
                                            }`}
                                            options={user?.trade}
                                            value={value}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="trade"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={handleSubmit(cloneQuotation)}
                        >
                            Submit
                        </Button>

                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

import React, { useState, useEffect } from "react";

import { Col, Form, Image } from "react-bootstrap";
import { Controller, useWatch } from "react-hook-form";
import Select from "react-select";

import useAuth from "hooks/useAuth";

export const SignaturesForm = ({
    control,
    canSign = false,
    label,
    name,
    setValue,
}) => {
    const { user } = useAuth();

    const selectedSignature = useWatch({
        control,
        name: `${name}.select_signature`,
    });

    //
    // States
    //

    const [signatureOptions, setSignatureOptions] = useState();

    //
    // Functions
    //

    const appendId = (userSignatures) => {
        userSignatures.forEach((item, i) => {
            item.id = i + 1;
        });

        return userSignatures;
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (user?.signature) {
            let options = [];

            const userSignatures = appendId(user?.signature);

            userSignatures?.forEach((element, index) => {
                options = [
                    ...options,
                    {
                        value: element.id,
                        label: element.name,
                    },
                ];
            });

            setSignatureOptions(options);
        }
    }, [user]);

    useEffect(() => {
        if (user?.signature) {
            const userSignatures = appendId(user?.signature);

            const selectedSingature = userSignatures.find(
                (item) => item.id === selectedSignature?.value
            );

            setValue(`${name}.signature`, selectedSingature?.path);
            setValue(
                `${name}.signature_details`,
                selectedSingature?.signature_details
            );
        }
        // eslint-disable-next-line
    }, [selectedSignature]);

    return (
        <>
            <Col md={4}>
                <Form.Group>
                    <Form.Label
                        htmlFor="prepared_by"
                        className="font-weight-bold"
                    >
                        {label}
                    </Form.Label>
                </Form.Group>

                {canSign ? (
                    <Controller
                        control={control}
                        name={`${name}.select_signature`}
                        defaultValue=""
                        render={({ field: { onChange } }) => (
                            <Select
                                onChange={onChange}
                                options={signatureOptions}
                                placeholder="Select signature"
                                className="mb-3"
                            />
                        )}
                    />
                ) : (
                    <Controller
                        control={control}
                        name={`${name}.name`}
                        defaultValue=""
                        render={({ field: { value } }) => (
                            <p style={{ marginBottom: "36px" }}>
                                {value || "-"}
                            </p>
                        )}
                    />
                )}

                <Form.Group className="mb-3">
                    <Form.Label
                        className="font-weight-bold"
                        htmlFor="checked_by"
                    >
                        Signature
                    </Form.Label>
                    <Controller
                        control={control}
                        name={`${name}.signature`}
                        defaultValue=""
                        render={({ field: { value } }) =>
                            value?.length ? (
                                <div className="mb-4">
                                    <Image
                                        src={`${value}`}
                                        className="preview-signature signature-container"
                                    />
                                </div>
                            ) : (
                                <>
                                    <br /> -
                                </>
                            )
                        }
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label
                        className="font-weight-bold"
                        htmlFor="checked_by"
                    >
                        Signature details
                    </Form.Label>
                    <Controller
                        control={control}
                        name={`${name}.signature_details`}
                        defaultValue=""
                        render={({ field: { value } }) =>
                            value?.length ? (
                                <p className="new-line">{value}</p>
                            ) : (
                                <>
                                    <br /> -
                                </>
                            )
                        }
                    />
                </Form.Group>
            </Col>
        </>
    );
};

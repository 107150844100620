import React, { useContext, useState, useCallback, useEffect } from "react";

import { status } from "config";
import {
    Badge,
    Button,
    Col,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { DeleteModal } from "components/DeleteModal";
import NotyfContext from "contexts/NotyfContext";
import DynamicTableNoPagination from "components/ui/DynamicTableNoPagination";
import { faEye, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DefectsListForm = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const location = useLocation();

    //
    // States
    //

    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const tableColumns = [
        {
            Header: "Actions",
            accessor: "actions",
            width: "70px",
            Cell: ({ cell }) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="align-middle me-1"
                                    onClick={() =>
                                        navigate(
                                            location.pathname +
                                                "/" +
                                                cell.row.original.id
                                        )
                                    }
                                />
                            </span>
                        </OverlayTrigger>
                    </>
                );
            },
        },
        {
            Header: "Category",
            accessor: "category",
        },
        {
            Header: "Faculty",
            accessor: "faculty",
        },
        {
            Header: "Element",
            accessor: "element",
        },
        {
            Header: "Data found",
            accessor: "data_found",
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ row }) => {
                return (
                    <Badge pill bg={status(row.original.status)}>
                        {row.original.status}
                    </Badge>
                );
            },
        },
    ];

    const data = [
        {
            category: "Building maintenance",
            faculty: "External work",
            element: "Drainage system",
            data_found: "10/10/2022",
            status: "Approved",
        },
        {
            category: "Building maintenance",
            faculty: "External work",
            element: "Drainage system",
            data_found: "10/10/2022",
            status: "Approved",
        },
    ];

    //
    // Functions
    //

    const deleteQuotation = useCallback(
        async (id) => {
            try {
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getQuotations = useCallback(
        async (param = null) => {
            try {
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    //
    // UseEffects
    //

    useEffect(() => {
        getQuotations();
    }, [getQuotations]);

    return (
        <>
            <Row>
                <Col md={10} sm={10} xs={10}></Col>
                <Col md={2} sm={2} xs={2}>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>Export cs</Tooltip>
                        }
                    >
                        <Button
                            variant="primary"
                            className="float-end mb-2"
                            onClick={() => {}}
                        >
                            <FontAwesomeIcon icon={faFile} />
                        </Button>
                    </OverlayTrigger>
                    {/* <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-top`}>Add evidence</Tooltip>}
          >
            <Button
              variant="primary"
              className="float-end mb-2 me-2"
              onClick={() =>
                navigate(`/works-order/quotation-list/create/add-defect`)
              }
            >
              <PlusCircle className="feather" />
            </Button>
          </OverlayTrigger> */}
                </Col>
            </Row>
            <DynamicTableNoPagination
                data={data}
                columns={tableColumns}
                className="table-layout-fixed"
            />
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteQuotation}
            />
        </>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";

import Select from "react-select";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faHourglass,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";

import NotyfContext from "contexts/NotyfContext";
import DynamicTable from "components/ui/DynamicTable";
import { Tabs } from "./Components/Tabs";
import { LoadingContext } from "App";
import { UnderConstruction } from "components/UnderConstruction";
import { months, years } from "config";
import { emptyPagination } from "config";
import * as schoolApi from "@api/schoolApi";
import * as dashboardApi from "@api/dashboardApi";
import { ChecklistSummaryLegend } from "./Components/ChecklistSummaryLegend";

export const DashboardChecklistStatus = () => {
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const notyf = useContext(NotyfContext);
    const { tab } = useParams();
    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
    });

    const actionFields = [
        {
            Header: "Checklist",
            accessor: "checklist",
        },
        {
            Header: "Frequency",
            accessor: "frequency",
        },
        {
            Header: "School",
            accessor: "school_id",
            Cell: ({ row }) => {
                return (
                    schools?.find((o) => o?.value === row.original.school_id)
                        ?.label || "ALL"
                );
            },
        },
        {
            Header: "Month",
            accessor: "month",
            Cell: ({ row }) => {
                return (
                    months?.find(
                        (o) =>
                            parseInt(o?.value) === parseInt(row.original.month)
                    )?.label || "-"
                );
            },
        },
        {
            Header: "Year",
            accessor: "year",
        },
        {
            Header: () => (
                <FontAwesomeIcon
                    icon={faHourglass}
                    className="align-middle me-1"
                    size="lg"
                />
            ),
            accessor: "breakdown.PENDING_TC",
            Cell: ({ row }) => {
                const schoolId = selectedSchoolLabel
                    ? `&school=${selectedSchoolLabel}`
                    : "";

                return (
                    <a
                        href={`/inspection-checklist/inspection-servicing?status=pendingTC${schoolId}&checklist_name=${row.original.checklist}&start_date=${startDate}&end_date=${endDate}&category=${tab}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn-link pe-1 text-dark"
                    >
                        {row.original.break_down?.PENDING_TC || 0}
                    </a>
                );
            },
        },
        {
            Header: () => (
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="align-middle me-1"
                    size="lg"
                />
            ),
            accessor: "breakdown.PENDING_MA",
            Cell: ({ row }) => {
                const schoolId = selectedSchoolLabel
                    ? `&school=${selectedSchoolLabel}`
                    : "";

                return (
                    <a
                        href={`/inspection-checklist/inspection-servicing?status=SUBMITTED${schoolId}&checklist_name=${row.original.checklist}&start_date=${startDate}&end_date=${endDate}&category=${tab}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn-link pe-1 text-dark"
                    >
                        {row.original.break_down?.PENDING_MA || 0}
                    </a>
                );
            },
        },
        {
            Header: "Total",
            accessor: "completed",
            Cell: ({ row }) => {
                const schoolId = selectedSchoolLabel
                    ? `&school=${selectedSchoolLabel}`
                    : "";

                return (
                    <a
                        href={`/inspection-checklist/inspection-servicing?status=APPROVED${schoolId}&checklist_name=${row.original.checklist}&start_date=${startDate}&end_date=${endDate}&category=${tab}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn-link pe-1 text-dark"
                    >
                        {row.original.break_down?.APPROVED || 0}
                    </a>
                );
            },
        },
    ];

    //
    // States
    //

    const [checklistStatus, setChecklistStatus] = useState([]);
    const [schools, setSchools] = useState([]);
    const [searchValue, setSearchValue] = useState({
        school: "",
        month: "",
        year: "",
    });
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedSchoolLabel, setSelectedSchoolLabel] = useState("");

    //
    // Functions
    //

    const getDashboardChecklistStatus = async (param = null) => {
        try {
            setIsLoadingActive(true);

            const getLastDayOfMonth = daysInMonth(param.month, param.year);

            setStartDate(`${param.month}-01-${param.year}`);
            setEndDate(`${param.month}-${getLastDayOfMonth}-${param.year}`);

            const response = await dashboardApi.getChecklistStatus(param);

            if (response.status === 200) {
                setIsLoadingActive(false);
                setChecklistStatus(response.data);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.slug, id: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const search = (data) => {
        const searchValue = {
            school_id: data.school ? data.school.value : "",
            month: data.mo
                ? months?.find((o) => o?.value === data.mo.value)?.value
                : "",
            year: data.yr
                ? years?.find((o) => o?.value === data.yr.value)?.value
                : "",
            inspection_category: tab.toUpperCase(),
        };

        getDashboardChecklistStatus(searchValue);
        setSearchValue(searchValue);
    };

    const lookUpType = (type) => {
        const lookup = {
            "building-maintenance": "Building maintenance",
            "safety-audit-inspection": "Safety audit inspection",
            "turfing-and-planting-maintenance":
                "Turfing and planting maintenance",
        };

        return lookup[type];
    };

    const daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    };

    //
    // UseEffects
    //

    useEffect(() => {
        const d = new Date();
        let month = d.getMonth() + 1;

        const getMonth = months?.find(
            (o) => parseInt(o?.value) === parseInt(month)
        );
        const getYear = years?.find(
            (o) => parseInt(o?.value) === parseInt(new Date().getFullYear())
        );

        reset({
            mo: getMonth,
            yr: getYear,
        });

        getDashboardChecklistStatus({
            year: getYear.value,
            month: getMonth.value,
            inspection_category: tab.toUpperCase(),
        });
        getSchools();
    }, []);

    return (
        <React.Fragment>
            <Helmet title="Dashboard - Checklist Status" />
            <Container fluid className="p-0">
                <h4 className="h4">Dashboard - Checklist Status</h4>
                <Card>
                    <Card.Body>
                        <Tabs module="checklist-status" />
                        <hr />

                        {checklistStatus ? (
                            <>
                                <Row>
                                    <Col md={10}>
                                        <h5 className="mb-4">
                                            {lookUpType(tab)}
                                        </h5>
                                        <Row className="mb-4">
                                            <Col md={12} sm={10} xs={10}>
                                                <Row>
                                                    <Col md={3} sm={4} xs={4}>
                                                        <Controller
                                                            control={control}
                                                            defaultValue=""
                                                            name="school"
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <Select
                                                                    inputRef={
                                                                        ref
                                                                    }
                                                                    classNamePrefix="react-select"
                                                                    options={
                                                                        schools
                                                                    }
                                                                    isSearchable={
                                                                        true
                                                                    }
                                                                    isClearable={
                                                                        true
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={(
                                                                        data
                                                                    ) => {
                                                                        onChange(
                                                                            data
                                                                        );
                                                                        setSelectedSchoolLabel(
                                                                            data?.label ||
                                                                                ""
                                                                        );
                                                                    }}
                                                                    placeholder="Select school"
                                                                />
                                                            )}
                                                        />
                                                    </Col>

                                                    <Col md={3} sm={3} xs={3}>
                                                        <Controller
                                                            control={control}
                                                            defaultValue=""
                                                            name="mo"
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                },
                                                            }) => (
                                                                <Select
                                                                    className="is-invalid react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    options={
                                                                        months
                                                                    }
                                                                    isSearchable={
                                                                        true
                                                                    }
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    menuPlacement="bottom"
                                                                    placeholder="Select month"
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col md={3} sm={3} xs={3}>
                                                        <Controller
                                                            control={control}
                                                            defaultValue=""
                                                            name="yr"
                                                            render={({
                                                                field: {
                                                                    value,
                                                                    onChange,
                                                                },
                                                            }) => (
                                                                <Select
                                                                    className="is-invalid react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    options={
                                                                        years
                                                                    }
                                                                    isSearchable={
                                                                        true
                                                                    }
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    menuPlacement="bottom"
                                                                    placeholder="Select year"
                                                                />
                                                            )}
                                                        />
                                                    </Col>

                                                    <Col md={3} sm={2} xs={2}>
                                                        <Button
                                                            variant="primary"
                                                            className="me-1 mb-1 form-box"
                                                            onClick={handleSubmit(
                                                                search
                                                            )}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faSearch}
                                                            />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={2}>
                                        <ChecklistSummaryLegend
                                            hasApproved={false}
                                        />
                                    </Col>
                                </Row>
                                <DynamicTable
                                    dataPagination={
                                        checklistStatus?.data
                                            ? checklistStatus
                                            : emptyPagination
                                    }
                                    columns={actionFields}
                                    className="table-layout-fixed"
                                    api={getDashboardChecklistStatus}
                                    searchValue={searchValue}
                                    hasTopHorizontalScroll
                                />
                            </>
                        ) : (
                            <UnderConstruction />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import Select from "react-select";
import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Container,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadingContext } from "App";
import { formatConfigFields } from "utils/utilities";
import {
    faCalendarAlt,
    faPen,
    faSearch,
    faUpload,
    faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as schoolApi from "@api/schoolApi";
import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import { zones } from "config";
import { faUser } from "@fortawesome/free-regular-svg-icons";

export const RerenderContextSchools = createContext();

export const Schools = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const location = useLocation();
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    let isDesktop = width > 1400;
    let isMobile = width < 768;

    //
    // States
    //

    const [schools, setSchools] = useState([]);
    const [schoolsMapped, setSchoolsMapped] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [searchValue, setSearchValue] = useState({
        school: "",
        zone: "",
    });
    const [configColumn, setFieldColumn] = useState([]);

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: ({ cell }) => {
            return (
                <>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Edit OM</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faUser}
                                className="align-middle me-1"
                                onClick={() =>
                                    navigate(
                                        location.pathname +
                                            "/om/" +
                                            cell.row.original.id
                                    )
                                }
                                size="lg"
                            />
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Edit MA</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faUserAlt}
                                className="align-middle me-1"
                                onClick={() =>
                                    navigate(
                                        location.pathname +
                                            "/ma/" +
                                            cell.row.original.id
                                    )
                                }
                                size="lg"
                            />
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>File upload</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faUpload}
                                className="align-middle me-1"
                                onClick={() =>
                                    navigate(
                                        location.pathname +
                                            "/file-upload/" +
                                            cell.row.original.id
                                    )
                                }
                                size="lg"
                            />
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Edit school contract</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faCalendarAlt}
                                className="align-middle me-1"
                                onClick={() =>
                                    navigate(
                                        location.pathname +
                                            "/contract/" +
                                            cell.row.original.id
                                    )
                                }
                                size="lg"
                            />
                        </span>
                    </OverlayTrigger>
                </>
            );
        },
    };

    //
    // Functions
    //

    const getSchools = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await schoolApi.getSchools(false, param);

                if (response.status === 200) {
                    setSchools(response.data);
                    setIsLoadingActive(false);

                    const schoolsMapped = response.data.data.map(
                        (field, index) => {
                            return { label: field.name, value: field.id };
                        }
                    );

                    setSchoolsMapped(schoolsMapped);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await schoolApi.getSchoolsConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const search = () => {
        getSchools(searchValue);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getSchools();
        getConfig();
    }, [getSchools, getConfig, rerender]);

    return (
        <RerenderContextSchools.Provider value={[setRerender, rerender]}>
            <Helmet title="Schools" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Schools</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={10} sm={10} xs={10}>
                                <Row>
                                    <Col md={4} sm={12} xs={12}>
                                        <Select
                                            className={`${isMobile && "mt-2"}`}
                                            isClearable={true}
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    school: data
                                                        ? data.value
                                                        : "",
                                                });
                                            }}
                                            options={schoolsMapped}
                                            placeholder="Select school"
                                        />
                                    </Col>
                                    <Col md={4} sm={5} xs={5}>
                                        <Select
                                            isClearable={true}
                                            onChange={(data) => {
                                                setSearchValue({
                                                    ...searchValue,
                                                    zone: data
                                                        ? data.value
                                                        : "",
                                                });
                                            }}
                                            options={zones}
                                            placeholder="Select zone"
                                        />
                                    </Col>

                                    <Col md={3} sm={2} xs={2}>
                                        <Button
                                            variant="primary"
                                            className="me-1 mb-1 form-box"
                                            onClick={search}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && schools.data && (
                            <DynamicTable
                                dataPagination={{
                                    data: schools.data,
                                    links: "",
                                    meta: "",
                                }}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </RerenderContextSchools.Provider>
    );
};

import React, { useState, useContext } from "react";

import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import DynamicTable from "components/ui/DynamicTable";
import { useEffect, useCallback } from "react";
import NotyfContext from "contexts/NotyfContext";
import * as inspectionTreeChecklistApi from "@api/inspectionTreeChecklistApi";
import { DeleteModal } from "components/DeleteModal";
import * as schoolApi from "@api/schoolApi";
import { Controller, useForm } from "react-hook-form";
import { constructReport, formatConfigFields } from "utils/utilities";
import { LoadingContext } from "App";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPen,
    faSearch,
    faTrash,
    faDownload,
    faList,
    faRecycle,
    faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { tree_checklist_category } from "pages/checklist/checklist-config";
import { EditDetailsModal } from "./components/EditDetailsModal";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import CookieService from "service/CookieService";

export const TreeInspections = () => {
    //
    // States
    //

    const [inspectionChecklist, setInspectionChecklist] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const notyf = useContext(NotyfContext);
    const [rerender, setRerender] = useState([]);
    const [schools, setSchools] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const { control, handleSubmit } = useForm({});
    const [filter, setFilter] = useState({});
    const [configColumn, setFieldColumn] = useState([]);
    const [modalConfirmation, setModalConfirmation] = useState({
        notifMsg: "",
        open: false,
        api: "",
    });
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const { hasRole } = useAuth();
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    let isDesktop = width > 1400;

    const [modalEditDetails, setModalEditDetails] = useState({
        id: null,
        open: false,
        severity: "danger",
        schools: [],
        selectedSchool: {},
    });

    const hasRoleCollection = {
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isAdmin: hasRole("ADMIN"),
        isArboristNorthEast: hasRole("ARBORIST-NORTH-EAST"),
        isArboristSouth: hasRole("ARBORIST-SOUTH"),
        isHorticulturist: hasRole("HORTICULTURIST"),
    };
    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: (cell) => {
            const canView =
                cell.row.original?.status.toUpperCase() === "SUBMITTED"
                    ? true
                    : false;

            const canDelete =
                    hasRoleCollection?.isTermContractor || (hasRoleCollection?.isArboristNorthEast ||
                    hasRoleCollection?.isArboristSouth ||
                    hasRoleCollection?.isHorticulturist) &&
                cell.row.original?.status.toUpperCase() === "DRAFT"
                    ? true
                    : false;

            const canEditDetails =
                (hasRoleCollection?.isTermContractor ||
                    hasRoleCollection?.isTechnician ||
                    hasRoleCollection?.isAdmin) &&
                (cell.row.original?.status.toUpperCase() === "DRAFT" ||
                    cell.row.original?.status.toUpperCase() === "REJECTED");

            const reportTimeout = CookieService.get(
                `report-${cell.row.original.id}`
            );

            let downloadUrl = constructReport(
                cell.row.original.file_info?.bucket,
                cell.row.original.file_info?.file_path,
                `${cell.row.original?.school?.name}-${cell.row.original?.inspection_category}-${cell.row.original?.inspected_at}`,
                false
            );

            let previewUrl = constructReport(
                cell.row.original.file_info?.bucket,
                cell.row.original.file_info?.file_path,
                `${cell.row.original?.school?.name}-${cell.row.original?.inspection_category}-${cell.row.original?.inspected_at}`,
                true
            );

            return (
                <>
                    {canView && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View checklist</Tooltip>}
                        >
                            <Button
                                variant="link"
                                className="px-1"
                                onClick={() =>
                                    navigate(
                                        location.pathname +
                                            "/" +
                                            cell.row.original.id,
                                        { state: { resetState: true } }
                                    )
                                }
                            >
                                View
                            </Button>
                        </OverlayTrigger>
                    )}
                    {!canView && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit checklist</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faPen}
                                    className="align-middle me-1"
                                    onClick={() =>
                                        navigate(
                                            location.pathname +
                                                "/" +
                                                cell.row.original.id,
                                            { state: { resetState: true } }
                                        )
                                    }
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                    {canDelete && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete checklist</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        openDeleteModal(cell.row.original.id);
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                    {canEditDetails && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit details</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faList}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        setModalEditDetails({
                                            id: cell.row.original.id,
                                            open: true,
                                            severity: "primary",
                                            schools: schools,
                                            selectedSchool:
                                                cell?.row?.original?.school,
                                            selectedLocation:
                                                cell?.row?.original?.location,
                                        });
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                    {cell?.row?.original?.status === "Submitted" &&
                    cell.row.original.file_info?.bucket &&
                    cell.row.original.file_info?.file_path ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Download service report</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        downloadServiceReport(downloadUrl);
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    ) : (
                        ""
                    )}

                    {cell?.row?.original?.status === "Submitted" &&
                    cell.row.original.file_info?.bucket &&
                    cell.row.original.file_info?.file_path ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Preview service report</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        downloadServiceReport(previewUrl);
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    ) : (
                        ""
                    )}

                    {cell?.row?.original?.status === "Submitted" &&
                        !reportTimeout && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Regenerate report</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faRecycle}
                                        className="align-middle me-1"
                                        onClick={() =>
                                            setModalConfirmation({
                                                notifMsg:
                                                    "Are you sure you want to generate this report?",
                                                subNotifMsg:
                                                    "Note: Report will take an estimate of 3 minutes to be generated.",
                                                open: true,
                                                api: () =>
                                                    regenerateReportApi(
                                                        cell?.row?.original?.id
                                                    ),
                                            })
                                        }
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                </>
            );
        },
    };

    //
    // Functions
    //

    const downloadServiceReport = (serviceReport) => {
        window.open(serviceReport, "Download");
    };

    const getInspectionChecklists = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response =
                    await inspectionTreeChecklistApi.getInspectionTreeChecklists(
                        {
                            ...param,
                            ...filter,
                        }
                    );
                if (response.status === 200) {
                    const data = response.data;
                    setInspectionChecklist(data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, filter, setIsLoadingActive]
    );

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    const deleteDefect = useCallback(
        async (id) => {
            try {
                const response =
                    await inspectionTreeChecklistApi.deleteInspectionTreeChecklist(
                        id
                    );
                if (response.status === 200) {
                    notyf.open({
                        type: "success",
                        message: response.data.message,
                    });
                    setRerender(!rerender);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, rerender]
    );

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            if (response.status === 200) {
                const data = response.data.data;
                let temp = {};
                temp = data.map((school) => {
                    return {
                        value: school?.id,
                        label: school?.name,
                    };
                });
                setSchools(temp);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const regenerateReportApi = async (id) => {
        try {
            const response = await inspectionTreeChecklistApi.regenerateReport(
                id
            );

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message:
                        "Successfully regenerated report. Please wait for 3 minutes.",
                });
                CookieService.set(`report-${id}`, id, 180);
                setRerender(!rerender);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error,
            });
        }
    };

    const searchInspection = useCallback(async (data) => {
        let schoolSlugs = [];

        data?.school !== "" &&
            data?.school?.forEach((school) => {
                schoolSlugs.push(school?.value);
            });

        setFilter({
            school_slug: schoolSlugs,
            inspection_category: data?.inspection_category,
            inspected_at: data?.inspection_date,
        });
    }, []);

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response =
                    await inspectionTreeChecklistApi.getInspectionTreeChecklistConfig(
                        param
                    );
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    //
    // UseEffects
    //
    useEffect(() => {
        getInspectionChecklists();
        getSchools();
        getConfig();
    }, [getInspectionChecklists, getSchools, rerender, getConfig]);

    return (
        <React.Fragment>
            <Helmet title="Tree inspections" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Tree Inspections</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Form>
                                <Col md={12} sm={12} xs={12}>
                                    <Row>
                                        <Col md={3} sm={12} xs={12}>
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="category"
                                                render={({
                                                    field: {
                                                        value,
                                                        onChange,
                                                        ref,
                                                    },
                                                }) => (
                                                    <Select
                                                        className={`${
                                                            isMobile && "mt-2"
                                                        }`}
                                                        classNamePrefix="react-select "
                                                        options={
                                                            tree_checklist_category
                                                        }
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={onChange}
                                                        placeholder="Select category"
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={4} sm={12} xs={12}>
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="school"
                                                render={({
                                                    field: {
                                                        value,
                                                        onChange,
                                                        ref,
                                                    },
                                                }) => (
                                                    <Select
                                                        inputRef={ref}
                                                        className={`${
                                                            isMobile && "mt-2"
                                                        }`}
                                                        classNamePrefix="react-select "
                                                        options={schools}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        isMulti={true}
                                                        value={value}
                                                        onChange={onChange}
                                                        placeholder="Select school"
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col md={3} sm={12} xs={12}>
                                            <Button
                                                variant="primary"
                                                className={`me-1 mb-1 form-box ${
                                                    isMobile && "mt-2 w-100"
                                                }`}
                                                onClick={handleSubmit(
                                                    searchInspection
                                                )}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Form>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive &&
                            configColumn &&
                            inspectionChecklist.data && (
                                <DynamicTable
                                    dataPagination={inspectionChecklist}
                                    columns={formatConfigFields(
                                        configColumn,
                                        actionField
                                    )}
                                    api={getInspectionChecklists}
                                    searchValue={filter}
                                />
                            )}
                    </Card.Body>
                </Card>
            </Container>
            <EditDetailsModal
                modalInfo={modalEditDetails}
                setModalInfo={setModalEditDetails}
                isTreeInspection={true}
            />
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteDefect}
            />
            <ConfirmationModal
                modalInfo={modalConfirmation}
                setModalInfo={setModalConfirmation}
            />
        </React.Fragment>
    );
};

import React from "react";

import useSidebar from "hooks/useSidebar";
import { faArrowUp, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { scrollToTop } from "utils/utilities";

export const HelperButtons = () => {
    const { isOpen, setIsOpen } = useSidebar();
    return (
        <>
            <span
                style={{
                    position: "fixed",
                    bottom: 60,
                    right: 55,
                    cursor: "pointer",
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <FontAwesomeIcon icon={faBars} size="2x" />
            </span>
            <span
                style={{
                    position: "fixed",
                    bottom: 60,
                    right: 10,
                    cursor: "pointer",
                }}
                onClick={() => scrollToTop()}
            >
                <FontAwesomeIcon icon={faArrowUp} size="2x" />
            </span>
        </>
    );
};

import api from "../utils/api";

export const createSoi = (data) => {
    return api.post("/cop-soi", data);
};

export const updateSoi = (id, data) => {
    return api.post("/cop-soi/" + id, data);
};

export const getSois = (data) => {
    return api.get("/cop-soi", { params: data });
};

export const getSoi = (id) => {
    return api.get("/cop-soi/" + id);
};

export const deleteSoi = (id) => {
    return api.delete(`/cop-soi/${id}`);
};

export const getSoiConfig = (data) => {
    return api.get(`/config/soi`, data);
};

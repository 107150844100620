import React, { useState, useEffect, useContext } from "react";

import { Helmet } from "react-helmet-async";
import { Nav, Row, Tab } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import DynamicTable from "components/ui/DynamicTable";
import { getInspectionChecklistsTodo } from "../../api/inspectionChecklistApi";
import {
    pendingForApproval,
    pendingForWorkOrderApproval,
} from "../../api/quotationsApi";
import { useNavigate } from "react-router-dom";
import { pendingForDeliverableApproval } from "../../api/deliverablesApi";
import { pendingForClaimsApproval } from "../../api/claimsApi";
import {
    tableColumnsClaims,
    tableColumnsDelivarables,
    tableColumnsInspection,
    tableColumnsQuotation,
    tableColumnsWorksOrder,
} from "./table-columns/tableColumns";
import { LoadingContext } from "App";

export const ToDoList = () => {
    const [tabs, setTabs] = useState([]);
    const [inspections, setInspections] = useState([]);
    const [quotations, setQuotations] = useState([]);
    const [deliverables, setDeliverables] = useState([]);
    const [workorders, setWorkOrders] = useState([]);
    const [claims, setClaims] = useState([]);
    const emptyData = "You don't have tasks to review.";
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    const { hasRole } = useAuth();

    const hasRoleCollection = {
        isOM: hasRole("OPERATIONS-MANAGER"),
        isTechnician: hasRole("TECHNICIAN"),
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isQS: hasRole("MA-QS"),
        isAdmin: hasRole("ADMIN"),
    };

    const getTabs = () => {
        let data = [
            {
                key: "inspections",
                label: "Inspections",
            },
            {
                key: "quotations",
                label: "Quotations",
            },
            {
                key: "claims",
                label: "Claims",
            },
            {
                key: "workorders",
                label: "Work service orders(WSO)",
            },
            {
                key: "deliverables",
                label: "Deliverables",
            },
        ];
        // if (hasRoleCollection.isOM) {
        //     const findItemIndex = data.findIndex(
        //         (item) => item.key === "workorders"
        //     );
        //     data?.splice(findItemIndex, 1);
        // }

        if (hasRoleCollection.isTechnician) {
            data = data.filter((item) => item.key === "inspections");
        }

        setTabs(data);
    };

    const getInspections = async (param) => {
        try {
            setIsLoadingActive(true);
            const response = await getInspectionChecklistsTodo(param);

            if (response.status === 200) {
                setInspections(response?.data);
                setIsLoadingActive(false);
            }
        } catch {
            setIsLoadingActive(false);
        }
    };

    const getQuotations = async (param) => {
        const response = await pendingForApproval(param);
        setQuotations(response?.data);
    };

    const getDeliverables = async () => {
        const response = await pendingForDeliverableApproval();
        setDeliverables(response?.data);
    };
    const getWorkOrders = async (param) => {
        const response = await pendingForWorkOrderApproval(param);
        setWorkOrders(response?.data);
    };
    const getClaims = async (param) => {
        const response = await pendingForClaimsApproval(param);
        setClaims(response?.data);
    };

    const getCountBadge = (tab) => {
        if (tab === "inspections") {
            return inspections?.data?.length;
        }

        if (tab === "quotations") {
            return quotations?.data?.length;
        }
        if (tab === "deliverables" && !hasRoleCollection?.isOM) {
            return deliverables?.data?.length;
        }
        if (tab === "workorders") {
            return workorders?.data?.length;
        }
        if (tab === "claims") {
            return claims?.data?.length;
        }
    };

    useEffect(() => {
        getTabs();
        getInspections();

        if (hasRoleCollection.isOM) {
            getWorkOrders();
        }
        if (
            hasRoleCollection.isAdmin ||
            hasRoleCollection.isTermContractor ||
            hasRoleCollection.isManagingAgent
        ) {
            getQuotations();
            getDeliverables();
            getClaims();
            getWorkOrders();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Helmet title="To-do" />

            <div className="tab">
                <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="inspections"
                >
                    <Nav variant="tabs">
                        {tabs?.length > 0 &&
                            tabs.map((item, index) => (
                                <Nav.Item
                                    key={index}
                                    className="position-relative"
                                >
                                    {getCountBadge(item.key) > 0 && (
                                        <span className="count-badge">
                                            {getCountBadge(item.key)}
                                        </span>
                                    )}
                                    <Nav.Link eventKey={item.key}>
                                        {item.label}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="inspections">
                            {!isLoadingActive && inspections?.data ? (
                                <DynamicTable
                                    className="table-layout-fixed"
                                    dataPagination={inspections}
                                    columns={tableColumnsInspection}
                                    api={getInspections}
                                />
                            ) : (
                                <p>{emptyData}</p>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="quotations">
                            {quotations?.data?.length > 0 ? (
                                <DynamicTable
                                    className="table-layout-fixed"
                                    dataPagination={{
                                        data: quotations?.data,
                                        links: "",
                                        meta: "",
                                    }}
                                    api={getQuotations}
                                    columns={tableColumnsQuotation}
                                    isPaginated={false}
                                />
                            ) : (
                                <p>{emptyData}</p>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="claims">
                            {claims?.data?.length > 0 ? (
                                <DynamicTable
                                    className="table-layout-fixed"
                                    dataPagination={{
                                        data: claims?.data,
                                        links: "",
                                        meta: "",
                                    }}
                                    api={getClaims}
                                    columns={tableColumnsClaims}
                                    isPaginated={false}
                                />
                            ) : (
                                <p>{emptyData}</p>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="workorders">
                            {workorders?.data?.length > 0 ? (
                                <DynamicTable
                                    className="table-layout-fixed"
                                    dataPagination={{
                                        data: workorders?.data,
                                        links: "",
                                        meta: "",
                                    }}
                                    api={getWorkOrders}
                                    columns={tableColumnsWorksOrder}
                                    isPaginated={false}
                                />
                            ) : (
                                <p>{emptyData}</p>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="deliverables">
                            {deliverables?.data?.length > 0 ? (
                                <DynamicTable
                                    className="table-layout-fixed"
                                    dataPagination={{
                                        data: deliverables?.data,
                                        links: "",
                                        meta: "",
                                    }}
                                    api={getDeliverables}
                                    columns={tableColumnsDelivarables}
                                    isPaginated={false}
                                />
                            ) : (
                                <p>{emptyData}</p>
                            )}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </React.Fragment>
    );
};

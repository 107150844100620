import React from "react";

import { Button, Col, Modal, Row } from "react-bootstrap";

export const DeleteModalWorksOrder = ({ modalInfo, setModalInfo }) => {
    const closeModal = () => {
        setModalInfo({
            ...modalInfo,
            open: false,
            delete: false,
        });
    };

    const deleteItem = () => {
        setModalInfo({
            ...modalInfo,
            open: false,
            delete: true,
        });
    };

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => closeModal()}
            size="sm"
            centered
        >
            <Modal.Body className="text-center my-2">
                <p className="mb-0">{modalInfo.notifMsg}</p>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        <Button
                            className="me-2"
                            variant="danger"
                            type="submit"
                            onClick={deleteItem}
                        >
                            Confirm
                        </Button>
                        <Button variant="link" onClick={closeModal}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

import api from "../utils/api";

export const getMaintenanceSchedule = (filter) => {
    return api.get("/maintenance-schedule");
};

export const createMaintenanceSchedule = (params) => {
    return api.post("/maintenance-schedule", params);
};

export const updateMaintenanceSchedule = (id, params) => {
    return api.patch("/maintenance-schedule/" + id, params);
};

export const deleteMaintenanceSchedule = (id, params) => {
    return api.delete("/maintenance-schedule/delete-schedule/" + id, {data: params});
};
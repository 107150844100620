import React from "react";

import {
    faCheckCircle,
    faCheckDouble,
    faHourglass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";

export const ChecklistSummaryLegend = ({ hasApproved = true }) => {
    return (
        <>
            <Row className="mb-1">
                <Col md={3}>
                    <span>
                        <FontAwesomeIcon
                            icon={faHourglass}
                            className="align-middle me-1"
                            size="lg"
                        />
                    </span>
                </Col>
                <Col md={7}>Pending TC</Col>
            </Row>
            <Row className="mb-1">
                <Col md={3}>
                    <span>
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="align-middle me-1"
                            size="lg"
                        />
                    </span>
                </Col>
                <Col md={7}>Pending MA</Col>
            </Row>
            {hasApproved && (
                <Row className="mb-1">
                    <Col md={3}>
                        <span>
                            <FontAwesomeIcon
                                icon={faCheckDouble}
                                className="align-middle me-1"
                                size="lg"
                            />
                        </span>
                    </Col>
                    <Col md={7}>Approved</Col>
                </Row>
            )}
        </>
    );
};

import React, {
    useState,
    useCallback,
    useContext,
    useEffect,
    createContext,
} from "react";

import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Container,
    Row,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import DatePicker from "react-datepicker";
import useAuth from "hooks/useAuth";
import { LoadingContext } from "App";
import { formatConfigFields } from "utils/utilities";
import {
    faPen,
    faPlus,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import { DeleteModal } from "components/DeleteModal";
import { category, type } from "./form/options";
import * as soiApi from "@api/soiApi";
import * as schoolApi from "@api/schoolApi";

export const RerenderContextSoi = createContext();

export const SOI = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const notyf = useContext(NotyfContext);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [width] = useState(window.innerWidth);
    let isDesktop = width > 1400;
    let isMobile = width < 768;
    const { hasRole } = useAuth();

    const { handleSubmit, control } = useForm({
        mode: "onTouched",
    });

    //
    // States
    //

    const [soi, setSoi] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [configColumn, setFieldColumn] = useState([]);
    const [schools, setSchools] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 100 : 120,
        Cell: (cell) => (
            <>
                <div>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Edit SOI</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                icon={faPen}
                                className="align-middle me-1"
                                onClick={() =>
                                    navigate(
                                        location.pathname +
                                            "/" +
                                            cell.row.original.id
                                    )
                                }
                                size="lg"
                            />
                        </span>
                    </OverlayTrigger>
                    {hasRole("TERM-CONTRACTOR") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete repository</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="align-middle me-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        openDeleteModal(cell.row.original.id)
                                    }
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                </div>
            </>
        ),
    };

    //
    // Functions
    //

    const getSOIs = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await soiApi.getSois(param);

                if (response.status === 200) {
                    setSoi(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const search = (data) => {
        const searchValue = {
            school_id: data?.school?.value || "",
            category: data?.category?.value || "",
            type: data?.type?.value || "",
            start_date: data.start_date
                ? new Date(data.start_date).toLocaleDateString("en-CA")
                : "",
            end_date: data.end_date
                ? new Date(data.end_date).toLocaleDateString("en-CA")
                : "",
        };

        getSOIs(searchValue);
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await soiApi.getSoiConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                const updatedName = field.name.replace(/former/gi, "");
                return { label: updatedName, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const deleteApi = async (id) => {
        try {
            setIsLoadingActive(true);
            const response = await soiApi.deleteSoi(id);
            if (response.status === 200) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "success",
                    message: response.data.message,
                });
                setRerender(!rerender);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getSOIs();
        getConfig();
    }, [getSOIs, rerender, getConfig]);

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    return (
        <RerenderContextSoi.Provider value={[setRerender, rerender]}>
            <Helmet title="SOI" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">SOI</h4>
                <Card>
                    <Card.Header className="pb-0">
                        <Row>
                            <Col md={11} sm={10} xs={10}>
                                <Row>
                                    <Col md={4} sm={4} xs={4}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="school"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                    ref,
                                                },
                                            }) => (
                                                <Select
                                                    className={`${
                                                        isMobile && "mt-2"
                                                    }`}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    options={schools}
                                                    placeholder="Select school"
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="category"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select "
                                                    placeholder="Select category"
                                                    options={category}
                                                    value={value}
                                                    onChange={onChange}
                                                    isSearchable
                                                    isClearable
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="type"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select "
                                                    placeholder="Select type"
                                                    options={type}
                                                    value={value}
                                                    onChange={onChange}
                                                    isSearchable
                                                    isClearable
                                                />
                                            )}
                                        />
                                    </Col>

                                    <Col md={4} sm={3} xs={3}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="start_date"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                    ref,
                                                },
                                            }) => (
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    onChange={onChange}
                                                    className={`form-box form-control mt-2 ${
                                                        isMobile && "w-100"
                                                    }`}
                                                    value={value}
                                                    selected={value}
                                                    placeholderText="Start date"
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={4} sm={3} xs={3}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="end_date"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                    ref,
                                                },
                                            }) => (
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    onChange={onChange}
                                                    className={`form-box form-control mt-2 ${
                                                        isMobile && "w-100"
                                                    }`}
                                                    value={value}
                                                    selected={value}
                                                    placeholderText="End date"
                                                />
                                            )}
                                        />
                                    </Col>

                                    <Col md={1} sm={2} xs={2}>
                                        <Button
                                            variant="primary"
                                            className="me-1 my-2 form-box"
                                            onClick={handleSubmit(search)}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={12} sm={12} xs={12}>
                                <h1 className="h3 mb-3">
                                    <Button
                                        variant="primary"
                                        className="float-end mb-2 form-box"
                                        onClick={() =>
                                            navigate(`/cop/soi/create`)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </h1>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && soi.data && (
                            <DynamicTable
                                dataPagination={soi}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                                api={getSOIs}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>

            <DeleteModal
                api={deleteApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
        </RerenderContextSoi.Provider>
    );
};

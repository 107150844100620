import React, { useState, useCallback, useContext, useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";

import * as permissionsApi from "@api/permissionsApi";
import * as rolesApi from "@api/rolesApi";
import NotyfContext from "contexts/NotyfContext";

const schema = yup.object().shape({
    name: yup.string().required("This field is required"),
    slug: yup.string().required("This field is required"),
});

export const RolesForm = () => {
    const navigate = useNavigate();
    const { action } = useParams();
    const create = action === "create";
    const notyf = useContext(NotyfContext);

    //
    // States
    //

    const [permissions, setPermissions] = useState([]);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const roleApiCall = async (data) => {
        data.permissions = getAllCheckedPermissions();

        try {
            create
                ? await rolesApi.createRole(data)
                : await rolesApi.updateRole(action, data);

            notyf.open({
                type: "success",
                message: `Successfully ${create ? "created" : "updated"} role`,
            });

            navigate(`/settings/roles`);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const populatePermissions = useCallback(
        (permissionsResponse, jsonData) => {
            const categories = {};

            permissionsResponse.forEach((element) => {
                if (!categories[element.category]) {
                    categories[element.category] = [];
                }

                if (create) {
                    categories[element.category] = [
                        ...categories[element.category],
                        {
                            id: element.id,
                            name: element.name,
                            slug: element.slug,
                            category: element.category.code,
                            checked: false,
                        },
                    ];
                } else {
                    const found = jsonData?.find(
                        (item) => item.id === element.id
                    );

                    categories[element.category] = [
                        ...categories[element.category],
                        {
                            id: element.id,
                            name: element.name,
                            slug: element.slug,
                            checked: found ? true : false,
                        },
                    ];
                }
            });

            setPermissions(categories);
        },
        [create]
    );

    const getPermissions = useCallback(async () => {
        try {
            const response = await permissionsApi.getPermissions({ all: 1 });

            const permissionsResponse = response.data.data;
            let permissions = [];

            if (!create) {
                const response = await rolesApi.getRole(action);
                const data = response.data.data;
                permissions = data?.permissions;

                reset({
                    name: data.name,
                    slug: data.slug,
                    description: data.description || "",
                });
            }

            populatePermissions(permissionsResponse, permissions);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf, action, create, populatePermissions, reset]);

    const permissionChangeHandler = (e, category, index) => {
        let checkedValue = e.target.checked;
        let temp_permissions = { ...permissions };
        temp_permissions[category][index].checked = checkedValue;

        setPermissions(temp_permissions);
    };

    const getAllCheckedPermissions = () => {
        const permissions_object = [];
        Object.keys(permissions).forEach((key) => {
            permissions[key].filter((permission) => {
                if (permission.checked) {
                    permissions_object.push(permission);
                }
                return true;
            });
        });

        return permissions_object;
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getPermissions();
    }, [getPermissions]);

    return (
        <React.Fragment>
            <Helmet title={`${create ? "Create" : "Edit"} roles`} />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">
                            {create ? "Create" : "Edit"} roles
                        </h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate("/settings/roles")}
                            >
                                Roles
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {create ? "Create" : "Edit"}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Controller
                                            control={control}
                                            name="name"
                                            defaultValue=""
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                },
                                            }) => (
                                                <Form.Control
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    className={`form-box ${
                                                        errors.name &&
                                                        "is-invalid"
                                                    }`}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="name"
                                            render={({ message }) => (
                                                <small className="text-danger">
                                                    {message}
                                                </small>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            Slug{" "}
                                            <small>
                                                <em>(Ex: GROUP_SLUG)</em>
                                            </small>
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="slug"
                                            defaultValue=""
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                },
                                            }) => (
                                                <Form.Control
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    className={`form-box ${
                                                        errors.slug &&
                                                        "is-invalid"
                                                    }`}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="slug"
                                            render={({ message }) => (
                                                <small className="text-danger">
                                                    {message}
                                                </small>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <Controller
                                            control={control}
                                            name="description"
                                            defaultValue=""
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                },
                                            }) => (
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    rows={3}
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                <Form.Label className="pb-2">
                                    Permissions
                                </Form.Label>

                                {Object.keys(permissions).map(
                                    (category, index) => {
                                        return (
                                            <Col md={3} key={index}>
                                                <Form.Group className="mb-3">
                                                    {permissions[category]
                                                        .length > 0 && (
                                                        <h5>
                                                            {category
                                                                .replace(
                                                                    /_/g,
                                                                    " "
                                                                )
                                                                .toUpperCase()}
                                                        </h5>
                                                    )}
                                                    {permissions[category].map(
                                                        (permission, i) => {
                                                            return (
                                                                <Controller
                                                                    key={
                                                                        permission.id
                                                                    }
                                                                    control={
                                                                        control
                                                                    }
                                                                    name="permission_ids"
                                                                    defaultValue=""
                                                                    render={({
                                                                        field: {
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={
                                                                                permission.id
                                                                            }
                                                                            label={
                                                                                permission?.name
                                                                            }
                                                                            value={
                                                                                value
                                                                            }
                                                                            checked={
                                                                                permission.checked
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                permissionChangeHandler(
                                                                                    e,
                                                                                    category,
                                                                                    i
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        );
                                    }
                                )}
                            </Row>
                            <Row className="pt-4">
                                <Col md={12} className="text-center">
                                    <Button
                                        className="me-2"
                                        variant="primary"
                                        type="submit"
                                        onClick={handleSubmit(roleApiCall)}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate("/settings/roles")
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

import React, { useContext, useState } from "react";

import DatePicker from "react-datepicker";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import Select from "react-select";
import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";
import { ErrorMessage } from "@hookform/error-message";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { useEffect } from "react";
import { LoadingContext } from "App";
import { RerenderContextQuotation } from "../../../../pages/quotations/quotation-list/QuotationList";
import { RerenderContextClaim } from "pages/claims/claims-list/ClaimsList";
import * as quotationsApi from "@api/quotationsApi";
import * as claimsApi from "@api/claimsApi";

export const PostApproval = ({ modalInfo, setModalInfo, view, module }) => {
    const notyf = useContext(NotyfContext);
    const context =
        module === "quotation"
            ? RerenderContextQuotation
            : RerenderContextClaim;

    const [rerender, setRerender] = useContext(context);
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    let schema = yup.object().shape({
        status: yup.object().required("This field is required").nullable(),
        document_type: yup
            .object()
            .when("status.value", {
                is: "APPROVED",
                then: yup
                    .object()
                    .required("This field is required.")
                    .nullable(),
            })
            .nullable(),
    });

    const tcSchema = yup.object().shape({
        source_fund: yup
            .object()
            .when("status.value", {
                is: "APPROVED",
                then: yup
                    .object()
                    .required("This field is required.")
                    .nullable(),
            })
            .nullable(),
    });

    const claimSchema = yup.object().shape({
        invoice_number: yup.string().when(["status.value"], {
            is: (status) => {
                return status === "APPROVED";
            },
            then: yup.string().nullable().required("This field is required."),
        }),
        invoice_date: yup.date().when(["status.value"], {
            is: (status) => {
                return status === "APPROVED";
            },
            then: yup.date().nullable().required("This field is required."),
        }),
    });

    schema = module === "quotation" ? schema.concat(tcSchema) : schema;
    schema = module === "claims" ? schema.concat(claimSchema) : schema;

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const selectedDocumentType = useWatch({ control, name: "document_type" });
    const selectedStatus = useWatch({
        control,
        name: "status",
        defaultValue: { value: "APPROVED", label: "Approved" },
    });

    //
    // States
    //

    const documentTypes = [
        { value: "General", label: "General" },
        { value: "EPO-EPI", label: "EPO/EPI" },
    ];

    const statusOptions = [
        { value: "APPROVED", label: "Approved" },
        // { value: "REJECTED", label: "Rejected" },
    ];

    const sourceFund = [
        { value: "SCHOOL", label: "School" },
        { value: "MOE", label: "MOE" },
        { value: "95-5", label: "95-5" },
    ];

    const [upload, setUpload] = useState([]);

    //
    // Functions
    //

    const closeModal = () => {
        reset({
            status: { value: "APPROVED", label: "Approved" },
            source_fund: "",
            document_type: "",
            remarks: "",
        });

        setModalInfo({ open: false });
    };

    const saveModal = async (data) => {
        const formData = new FormData();

        if (data.status.value === "APPROVED") {
            let supportingDocuments = upload.getFilesData();

            if (!supportingDocuments.length) {
                return notyf.open({
                    type: "warning",
                    message: "Please upload a file to proceed.",
                });
            }

            module === "quotation" &&
                formData.append("source_fund", data.source_fund.value);
            module === "claims" &&
                selectedStatus?.value === "APPROVED" &&
                formData.append("invoice_number", data.invoice_number);
            module === "claims" &&
                selectedStatus?.value === "APPROVED" &&
                formData.append(
                    "invoice_date",
                    new Date(data.invoice_date).toLocaleDateString("en-CA")
                );
            formData.append("document_type", data.document_type.value);
            data.epo_reference_number &&
                formData.append("epo_reference_no", data.epo_reference_number);
            data.epi_reference_number &&
                formData.append("epi_reference_no", data.epi_reference_number);
            supportingDocuments.forEach((file) => {
                formData.append("supporting_documents[]", file.rawFile);
            });
        }

        formData.append("status", data.status.value);
        formData.append("remarks", data.remarks);

        try {
            setIsLoadingActive(true);
            let response;

            switch (true) {
                case module === "quotation" && !modalInfo.is_moe_fund_only:
                    response = await quotationsApi.updateFinalQuotationStatus(
                        modalInfo.id,
                        formData
                    );
                    break;
                case modalInfo.is_moe_fund_only:
                    response = await quotationsApi.moePostApproval(
                        modalInfo.id,
                        formData
                    );
                    break;
                default:
                    response = await claimsApi.updateFinalClaimStatus(
                        modalInfo.id,
                        formData
                    );
                    break;
            }

            if (response.status === 200) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "success",
                    message: `Successfully updated ${
                        module === "quotation" ? "quotation" : "claim"
                    }.`,
                });
                setRerender(!rerender);
                closeModal();
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    useEffect(() => {
        reset({
            status: { value: "APPROVED", label: "Approved" },
        });
    }, [reset]);

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => closeModal()}
            size="md"
            centered
        >
            <Modal.Header closeButton>
                Post approval (
                {modalInfo.is_moe_fund_only
                    ? "Pending for MA Approval"
                    : "Pending for TC Update"}
                )
            </Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Status</Form.Label>
                                <Controller
                                    control={control}
                                    name="status"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Select
                                            isClearable={true}
                                            onChange={onChange}
                                            options={statusOptions}
                                            value={value}
                                            className={`react-select-container ${
                                                errors.status && "is-invalid"
                                            }`}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="status"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        {module === "claims" &&
                            selectedStatus?.value === "APPROVED" && (
                                <>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold">
                                                Invoice number
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="invoice_number"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Form.Control
                                                        className={`form-box form-control ${
                                                            errors?.invoice_number &&
                                                            "is-invalid"
                                                        }`}
                                                        type="text"
                                                        value={value || ""}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="invoice_number"
                                                render={({ message }) => (
                                                    <small className="text-danger">
                                                        {message}
                                                    </small>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold">
                                                Invoice date
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="invoice_date"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={value}
                                                        onChange={onChange}
                                                        className={`form-box form-control ${
                                                            errors?.invoice_date &&
                                                            "is-invalid"
                                                        }`}
                                                        value={value || ""}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="invoice_date"
                                                render={({ message }) => (
                                                    <small className="text-danger">
                                                        {message}
                                                    </small>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                        {selectedStatus?.value === "APPROVED" && (
                            <>
                                {module === "quotation" && (
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Source fund</Form.Label>

                                            <Controller
                                                control={control}
                                                name="source_fund"
                                                defaultValue=""
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Select
                                                        className={`react-select-container ${
                                                            errors.source_fund &&
                                                            "is-invalid"
                                                        }`}
                                                        classNamePrefix="react-select"
                                                        options={
                                                            modalInfo.is_moe_fund_only
                                                                ? [
                                                                      sourceFund.find(
                                                                          (
                                                                              data
                                                                          ) => {
                                                                              return (
                                                                                  data.value ===
                                                                                  "MOE"
                                                                              );
                                                                          }
                                                                      ),
                                                                  ]
                                                                : sourceFund
                                                        }
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        value={value}
                                                        onChange={onChange}
                                                        menuPlacement="top"
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="source_fund"
                                                render={({ message }) => (
                                                    <small className="text-danger">
                                                        {message}
                                                    </small>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Document type</Form.Label>
                                        <Controller
                                            control={control}
                                            name="document_type"
                                            defaultValue=""
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className={`react-select-container ${
                                                        errors.document_type &&
                                                        "is-invalid"
                                                    }`}
                                                    classNamePrefix="react-select"
                                                    options={
                                                        module === "quotation"
                                                            ? documentTypes
                                                            : [
                                                                  {
                                                                      value: "General",
                                                                      label: "General",
                                                                  },
                                                              ]
                                                    }
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={value}
                                                    onChange={onChange}
                                                    menuPlacement="top"
                                                    defaultValue={{
                                                        value: "General",
                                                        label: "General",
                                                    }}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="document_type"
                                            render={({ message }) => (
                                                <small className="text-danger">
                                                    {message}
                                                </small>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>

                                {selectedDocumentType?.value === "EPO-EPI" && (
                                    <>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    EPO reference number
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="epo_reference_number"
                                                    defaultValue=""
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <Form.Control
                                                            className={`form-box form-control ${
                                                                errors?.epo_reference_number &&
                                                                "is-invalid"
                                                            }`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="epo_reference_number"
                                                    render={({ message }) => (
                                                        <small className="text-danger">
                                                            {message}
                                                        </small>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    EPI reference number
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="epi_reference_number"
                                                    defaultValue=""
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <Form.Control
                                                            className={`form-box form-control ${
                                                                errors?.epi_reference_number &&
                                                                "is-invalid"
                                                            }`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="epi_reference_number"
                                                    render={({ message }) => (
                                                        <small className="text-danger">
                                                            {message}
                                                        </small>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                )}
                                <Col md={12}>
                                    <Form.Label>
                                        Supporting documents
                                    </Form.Label>
                                    <FileUploaderSyncfusion
                                        action="create"
                                        document={document}
                                        canDelete={false}
                                        canUpload={true}
                                        label="Supporting documents"
                                        labelBold
                                        setUpload={setUpload}
                                        isQuotation
                                    />
                                </Col>
                            </>
                        )}
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="remarks">
                                    Remarks
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="remarks"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            <AutoGrowTextarea
                                                key={modalInfo.id}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                fieldValue={value}
                                            />
                                        </>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        {!view && (
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit(saveModal)}
                            >
                                Submit
                            </Button>
                        )}
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => (
    <footer className="footer">
        <Container fluid>
            <Row className="text-muted">
                <Col xs="0" className="text-start"></Col>
                <Col xs="12" className="text-end">
                    <p className="mb-0">
                        &copy; {new Date().getFullYear()} -{" "}
                        <span href="/" className="text-muted">
                            Fonda Global Engineering Pte. Ltd.
                        </span>
                    </p>
                </Col>
            </Row>
        </Container>
    </footer>
);

export default Footer;

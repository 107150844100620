import React, { useContext, useState, useEffect } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { errorMessage } from "utils/utilities";
import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";
import NotyfContext from "contexts/NotyfContext";
import * as yup from "yup";

import Select from "react-select";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { RerenderContextClaim } from "../claims-list/ClaimsList";
import { LoadingContext } from "App";
import * as claimsApi from "@api/claimsApi";

export const PaymentStatusModal = ({ modalInfo, setModalInfo, view }) => {
    const notyf = useContext(NotyfContext);
    const [rerender, setRerender] = useContext(RerenderContextClaim);
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const [upload, setUpload] = useState([]);
    //
    // States
    //

    const schema = yup.object().shape({
        payment_received: yup.object().required("This field is required"),
        total_payment_amount: yup
            .string()
            .required("This field is required")
            .nullable(),
        remarks: yup.string().required("This field is required"),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // Functions
    //

    const closeModal = () => {
        setModalInfo({ open: false });
        reset({});
    };

    const saveModal = async (data) => {
        const formData = new FormData();

        let supportingDocuments = upload.getFilesData();

        formData.append("payment_status", data.payment_received.value);
        formData.append("payment_amount", data.total_payment_amount);
        formData.append("remarks", data.remarks);
        supportingDocuments.forEach((file) => {
            formData.append("supporting_documents[]", file.rawFile);
        });

        try {
            setIsLoadingActive(true);
            const response = await claimsApi.updatePaymentStatus(
                modalInfo.id,
                formData
            );

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "Successfully created work completion",
                });
                closeModal();
                setRerender(!rerender);
                setIsLoadingActive(false);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: error?.message,
            });
        }
    };

    useEffect(() => {
        if (modalInfo.item) {
            reset({
                sub_total: modalInfo.item.sub_total_amount,
                gst_amount: modalInfo.item.total_gst,
                total_amount: modalInfo.item.total_amount,
                total_payment_amount: modalInfo.item.sub_total_amount,
            });
        }
    }, [modalInfo, reset]);

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => setModalInfo({ open: false, action: "add" })}
            size="lg"
            centered
        >
            <Modal.Header closeButton>Update payment status</Modal.Header>
            <Modal.Body className="m-1">
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Payment received
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="payment_received"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Select
                                            isClearable={true}
                                            onChange={onChange}
                                            options={[
                                                { value: "YES", label: "Yes" },
                                            ]}
                                            value={value}
                                            className={`react-select-container ${
                                                errors.status && "is-invalid"
                                            }`}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="payment_received"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Subtotal
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="sub_total"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            <br />
                                            S$ {value || "-"}
                                        </>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    GST amount
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="gst_amount"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            <br />
                                            S$ {value || "-"}
                                        </>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Total
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="total_amount"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <>
                                            <br />
                                            S$ {value || "-"}
                                        </>
                                    )}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label className="font-weight-bold">
                                    Total payment amount
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="total_payment_amount"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Form.Control
                                            className={`form-box form-control ${
                                                errors?.total_payment_amount &&
                                                "is-invalid"
                                            }`}
                                            type="number"
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="total_payment_amount"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="remarks"
                                    className="font-weight-bold"
                                >
                                    Remarks
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="remarks"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <>
                                            <AutoGrowTextarea
                                                key={modalInfo.id}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                fieldValue={value}
                                                errors={errors.remarks}
                                            />
                                        </>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="remarks"
                                    render={({ message }) =>
                                        errorMessage(message)
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Label className="font-weight-bold">
                                Supporting documents
                            </Form.Label>
                            <FileUploaderSyncfusion
                                action="create"
                                document={[]}
                                canDelete={false}
                                canUpload={true}
                                labelBold={true}
                                setUpload={setUpload}
                                isQuotation
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        {!view && (
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit(saveModal)}
                            >
                                Submit
                            </Button>
                        )}
                        <Button variant="link" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";

import Select from "react-select";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import NotyfContext from "contexts/NotyfContext";
import { Tabs } from "./Components/Tabs";
import { LoadingContext } from "App";
import { UnderConstruction } from "components/UnderConstruction";
import { months, years } from "config";
import * as schoolApi from "@api/schoolApi";
import * as dashboardApi from "@api/dashboardApi";

export const DashboardMonthlySummary = () => {
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const notyf = useContext(NotyfContext);
    const { tab } = useParams();
    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
    });

    //
    // States
    //

    const [monthlySummary, setMonthlySummary] = useState([]);
    const [schools, setSchools] = useState([]);
    const [searchValue, setSearchValue] = useState({
        school: "",
        month: "",
        year: "",
    });

    //
    // Functions
    //

    const getDashboardMonthlySummary = async (param = {}) => {
        try {
            setIsLoadingActive(true);
            const response = await dashboardApi.getMonthlySummaryReport(param);

            if (response.data.data) {
                setIsLoadingActive(false);
                return window.open(response.data.data);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const search = (data) => {
        const searchValue = {
            inspection_category: tab.toUpperCase(),
            month: data.mo
                ? months?.find((o) => o?.value === data.mo.value)?.value
                : "",
            year: data.yr
                ? years?.find((o) => o?.value === data.yr.value)?.value
                : "",
        };

        getDashboardMonthlySummary(searchValue);
        setSearchValue(searchValue);
    };

    const lookUpType = (type) => {
        const lookup = {
            "building-maintenance": "Building maintenance",
            "safety-audit-inspection": "Safety audit inspection",
            "turfing-and-planting-maintenance":
                "Turfing and planting maintenance",
        };

        return lookup[type];
    };

    //
    // UseEffects
    //

    useEffect(() => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        reset({
            mo: months?.find(
                (o) => parseInt(o?.value) === parseInt(currentMonth)
            ),
            yr: years?.find(
                (o) => parseInt(o?.value) === parseInt(currentYear)
            ),
        });

        getSchools();
    }, []);

    return (
        <React.Fragment>
            <Helmet title="Dashboard - Monthly Summary Report" />
            <Container fluid className="p-0">
                <h4 className="h4">Monthly Summary Report</h4>
                <Card>
                    <Card.Body>
                        <Tabs module="monthly-summary" />
                        <hr />

                        {monthlySummary ? (
                            <>
                                <h5 className="mb-4">{lookUpType(tab)}</h5>
                                <Row className="mb-4">
                                    <Col md={12} sm={10} xs={10}>
                                        <Row>
                                            <Col md={3} sm={3} xs={3}>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="mo"
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <Select
                                                            className="is-invalid react-select-container"
                                                            classNamePrefix="react-select"
                                                            options={months}
                                                            isSearchable
                                                            isMulti={false}
                                                            value={value}
                                                            onChange={onChange}
                                                            menuPlacement="bottom"
                                                            placeholder="Select month"
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col md={3} sm={3} xs={3}>
                                                <Controller
                                                    control={control}
                                                    defaultValue=""
                                                    name="yr"
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange,
                                                        },
                                                    }) => (
                                                        <Select
                                                            className="is-invalid react-select-container"
                                                            classNamePrefix="react-select"
                                                            options={years}
                                                            isSearchable
                                                            isMulti={false}
                                                            value={value}
                                                            onChange={onChange}
                                                            menuPlacement="bottom"
                                                            placeholder="Select year"
                                                        />
                                                    )}
                                                />
                                            </Col>

                                            <Col md={3} sm={2} xs={2}>
                                                <Button
                                                    variant="primary"
                                                    className="me-1 mb-1 form-box"
                                                    onClick={handleSubmit(
                                                        search
                                                    )}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                    />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <UnderConstruction />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

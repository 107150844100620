import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import Select from "react-select";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
    faBan,
    faCheckDouble,
    faClone,
    faDownload,
    faEject,
    faFilePdf,
    faFlagCheckered,
    faLayerGroup,
    faPen,
    faPlus,
    faRecycle,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";

import NotyfContext from "contexts/NotyfContext";
import DynamicTable from "components/ui/DynamicTable";
import useAuth from "hooks/useAuth";
import useLocalStorage from "hooks/useLocalStorage";
import { DeleteModal } from "components/DeleteModal";
import { LoadingContext } from "../../../App";
import { constructReport, formatConfigFields } from "utils/utilities";
import { PostApproval } from "../../../components/common/works-order/modal/PostApproval";
import { VoidQuotationModal } from "./modal/VoidQuotationModal";
import { CloneQuotationModal } from "./modal/CloneQuotationModal";
import {
    status_options_quotation,
    status_options_wso,
    claim_status,
} from "config";
import { RejectQuotationModal } from "./modal/RejectQuotationModal";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import * as quotationsApi from "@api/quotationsApi";
import * as schoolApi from "@api/schoolApi";

export const RerenderContextQuotation = createContext();

export const QuotationList = () => {
    const { hasRole, user } = useAuth();
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const location = useLocation();

    const hasRoleCollection = {
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isTechnician: hasRole("TECHNICIAN"),
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isQS: hasRole("MA-QS"),
        isAdmin: hasRole("ADMIN"),
    };

    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    // eslint-disable-next-line
    const [quotationItemsStorage, setQuotationItemsStorage] = useLocalStorage(
        "quotationItems",
        []
    );
    // eslint-disable-next-line
    const [quotationDetailsStorage, setQuotationDetailsStorage] =
        useLocalStorage("quotationDetails", []);

    //
    // States
    //

    const { control, handleSubmit, reset } = useForm({});
    const [rerender, setRerender] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [modalSupportingDocument, setModalSupportingDocument] = useState({
        id: null,
        open: false,
        severity: "danger",
    });
    const [modalVoid, setModalVoid] = useState({
        id: null,
        open: false,
        severity: "danger",
    });
    const [modalReject, setModalReject] = useState({
        id: null,
        open: false,
        severity: "danger",
    });
    const [modalCloneQuotation, setModalCloneQuotation] = useState({
        id: null,
        open: false,
        severity: "danger",
        params: {},
    });
    const [modalConfirmation, setModalConfirmation] = useState({
        notifMsg: "",
        open: false,
        api: "",
    });

    const [quotations, setQuotations] = useState([]);
    const [searchValue, setSearchValue] = useState({
        reference_number: "",
        school: "",
        status: "",
        job: "",
        start_date: null,
        end_date: null,
    });
    const [schools, setSchools] = useState([]);
    const [configColumn, setFieldColumn] = useState([]);
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: "150px",
        Cell: ({ row, cell }) => {
            const maUserValidation = Boolean(
                row.original?.pending_approval &&
                    row.original?.pending_approval[user?.id]
            );

            const canTechnicianView =
                (hasRoleCollection.isTechnician &&
                    row.original.status !== "REJECTED" &&
                    row.original.status !== "DRAFT" &&
                    row.original.status === "PENDING") ||
                ["COMPLETED", "VOID"].includes(row.original.status);

            const canTcEdit =
                ["REJECTED", "DRAFT"].includes(row.original.status) &&
                hasRoleCollection.isTermContractor;

            const canTcSign =
                row.original.status === "PENDING" &&
                hasRoleCollection.isTermContractor &&
                !row.original?.pending_approval;

            const canTcView =
                (row.original.status === "PENDING" &&
                    hasRoleCollection.isTermContractor &&
                    row.original?.pending_approval) ||
                ["COMPLETED", "VOID"].includes(row.original.status);

            const canMaView =
                (["REJECTED", "PENDING", "ACKNOWLEDGE"].includes(
                    row.original.status
                ) &&
                    row.original?.approvals?.MA) ||
                (!maUserValidation && hasRoleCollection.isManagingAgent);

            const canMaSign =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isManagingAgent &&
                row.original?.pending_approval[user?.id]?.approval_type ===
                    "MA" &&
                maUserValidation;

            const canQsView =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isQS &&
                row.original?.approvals?.QS;

            const canAdminView =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isAdmin;

            const canQsSign =
                ["REJECTED", "PENDING"].includes(row.original.status) &&
                hasRoleCollection.isQS &&
                row.original?.pending_approval[user?.id]?.approval_type ===
                    "MA-QS";

            const canOmView =
                row.original.status === "PENDING" && hasRoleCollection.isOM;

            const isApproved = row.original.status === "APPROVED";

            let downloadUrl = constructReport(
                row.original.file_info?.bucket,
                row.original.file_info?.file_path,
                `${row.original?.reference_number}-${row.original?.date}`,
                false
            );

            let previewUrl = constructReport(
                row.original.file_info?.bucket,
                row.original.file_info?.file_path,
                `${row.original?.reference_number}-${row.original?.date}`,
                true
            );

            return (
                <>
                    {(canTcView ||
                        canMaView ||
                        canQsView ||
                        isApproved ||
                        canAdminView ||
                        canTechnicianView ||
                        canOmView) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View quotation</Tooltip>}
                        >
                            <a
                                href={`${location.pathname}/${cell.row.original.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                                style={{
                                    color: "#3F80EA",
                                }}
                                onClick={() => resetStorage()}
                            >
                                View
                            </a>
                        </OverlayTrigger>
                    )}

                    {(canTcSign || canMaSign || canQsSign) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Sign quotation</Tooltip>}
                        >
                            <a
                                href={`${location.pathname}/${cell.row.original.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                                onClick={() => resetStorage()}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faPen}
                                        className="align-middle me-1"
                                        size="lg"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                    )}
                    {canTcEdit && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit quotation</Tooltip>}
                        >
                            <a
                                href={`${location.pathname}/${cell.row.original.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                                onClick={() => resetStorage()}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faPen}
                                        className="align-middle me-1"
                                        size="lg"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                    )}
                    {row.original.status === "DRAFT" &&
                        (hasRoleCollection.isTermContractor ||
                            hasRoleCollection.isAdmin) && (
                            <OverlayTrigger
                                overlay={<Tooltip>Delete quotation</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            openDeleteModal(
                                                cell.row.original.id,
                                                "delete"
                                            );
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {row.original.file_info?.bucket &&
                        row.original.file_info?.file_path && (
                            <OverlayTrigger
                                overlay={<Tooltip>Download report</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        className="align-middle me-1"
                                        onClick={() => window.open(downloadUrl)}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {row.original.file_info?.bucket &&
                        row.original.file_info?.file_path && (
                            <OverlayTrigger
                                overlay={<Tooltip>Preview report</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        className="align-middle me-1"
                                        onClick={() => window.open(previewUrl)}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {row.original.status !== "COMPLETED" &&
                        row.original.status !== "VOID" &&
                        hasRoleCollection.isTermContractor && (
                            <OverlayTrigger
                                overlay={<Tooltip>Void quotation</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faBan}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            setModalVoid({
                                                id: cell.row.original.id,
                                                open: true,
                                                severity: "primary",
                                            });
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {(row.original.status === "APPROVED" ||
                        row.original.current_status === "PENDING_TC" ||
                        row.original.current_status === "PENDING_MA") &&
                        (hasRoleCollection.isTermContractor ||
                            hasRoleCollection.isAdmin) && (
                            <OverlayTrigger
                                overlay={<Tooltip>Reject quotation</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faEject}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            setModalReject({
                                                id: cell.row.original.id,
                                                open: true,
                                                severity: "primary",
                                            });
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {(row.original.status === "APPROVED" ||
                        row.original.status === "COMPLETED") && (
                        <OverlayTrigger
                            overlay={<Tooltip>Work service order</Tooltip>}
                        >
                            <a
                                href={`${location.pathname}/${cell.row.original.id}/work-service-order`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-link pe-1"
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faCheckDouble}
                                        className="align-middle me-1"
                                        size="lg"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                    )}

                    {hasRoleCollection.isTermContractor &&
                        (row?.original?.current_status?.toUpperCase() ===
                            "PENDING_TC" ||
                            row?.original?.current_status?.toUpperCase() ===
                                "PENDING_MA") && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip>
                                        Post approval (
                                        {row?.original?.current_status})
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faFlagCheckered}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            setModalSupportingDocument({
                                                id: cell.row.original.id,
                                                open: true,
                                                severity: "primary",
                                                is_moe_fund_only:
                                                    row?.original?.current_status?.toUpperCase() ===
                                                    "PENDING_MA" && row?.original?.source_fund === "MOE",
                                            });
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    {hasRoleCollection.isTermContractor && (
                        <OverlayTrigger
                            overlay={<Tooltip>Clone quotation</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faClone}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        setModalCloneQuotation({
                                            id: cell.row.original.id,
                                            open: true,
                                            severity: "primary",
                                            params: {
                                                school_id:
                                                    row.original.school_id,
                                                trade: row.original.trade,
                                            },
                                        });
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}

                    {(hasRoleCollection.isTermContractor ||
                        hasRoleCollection?.isAdmin) && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Regenerate report</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faRecycle}
                                    className="align-middle me-1"
                                    onClick={() =>
                                        setModalConfirmation({
                                            notifMsg:
                                                "Are you sure you want to generate this report?",
                                            subNotifMsg:
                                                "Note: Report will take an estimate of 3 minutes to be generated.",
                                            open: true,
                                            api: () =>
                                                regenerateReportApi(
                                                    cell?.row?.original?.id
                                                ),
                                        })
                                    }
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                    )}

                    {hasRoleCollection.isTermContractor &&
                        row.original.status === "COMPLETED" &&
                        row.original.claim_status === "PENDING" && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Create claim</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faLayerGroup}
                                        className="align-middle me-1"
                                        onClick={() =>
                                            navigate(
                                                `/works-order/claims/create`,
                                                {
                                                    state: {
                                                        fund_type:
                                                            row.original
                                                                .source_fund,
                                                        trade: row.original
                                                            .trade,
                                                        job_type:
                                                            row.original
                                                                .job_type,
                                                        job: row.original.job,
                                                        school_id:
                                                            row.original
                                                                .school_id,
                                                        id: row.original.id,
                                                    },
                                                }
                                            )
                                        }
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                </>
            );
        },
    };

    //
    // Functions
    //

    const resetStorage = () => {
        setQuotationItemsStorage([]);
        setQuotationDetailsStorage({});
    };

    const deleteQuotation = useCallback(
        async (id) => {
            try {
                const response = await quotationsApi.deleteQuotation(id);

                if (response.status === 200) {
                    notyf.open({
                        type: "success",
                        message: response.data.message,
                    });
                    setRerender(!rerender);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, rerender]
    );

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    const getQuotations = useCallback(
        async (param = null) => {
            if (!schools.length) return;

            setQuotationDetailsStorage({});

            try {
                setIsLoadingActive(true);

                if (!param?.is_new_filter) {
                    param = handleFilter(param);
                }

                const response = await quotationsApi.getQuotations(param);

                if (response.status === 200) {
                    setQuotations(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
                setIsLoadingActive(false);
            }
        },
        // eslint-disable-next-line
        [notyf, setIsLoadingActive, schools]
    );

    const handleFilter = (param) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let statusParam = urlParams.get("status");
        let schoolParam = urlParams.get("school");
        let dateParam = urlParams.get("date");

        const start_date = new Date(`${dateParam}-01`);
        const lastDayOfMonth = new Date(
            start_date.getFullYear(),
            start_date.getMonth() + 1,
            0
        ).getDate();

        const end_date = new Date(`${dateParam}-${lastDayOfMonth}`);
        schoolParam = schoolParam
            ? schools?.find((o) => o?.label === schoolParam)
            : hasRoleCollection.isOM && user?.school_id
            ? {
                  label: user?.school?.details?.school_name,
                  value: user?.school_id,
              }
            : "";
        statusParam = statusParam
            ? status_options_quotation?.filter((o) => {
                  if (statusParam === "PENDING") {
                      return (
                          o?.value === "PENDING_TC" || o?.value === "PENDING_MA"
                      );
                  } else {
                      return o?.value === statusParam;
                  }
              })
            : "";

        const statusValue = statusParam ? formatStatus(statusParam) : "";

        reset({
            status_quotation: statusParam || [],
            status_wso: [],
            school: schoolParam,
            start_date: dateParam ? new Date(start_date) : "",
            end_date: dateParam ? new Date(end_date) : "",
        });

        return {
            ...param,
            status: statusValue || "",
            school: schoolParam ? schoolParam?.value : "",
            start_date: dateParam ? start_date : "",
            end_date: dateParam ? end_date : "",
        };
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, []);

    const search = (data) => {
        const status = [...data?.status_quotation, ...data?.status_wso];

        let statusValues = status && formatStatus(status);

        const param = {
            is_new_filter: true,
            reference_number: data?.reference_number,
            school: data?.school
                ? schools?.find((o) => o?.value === data?.school?.value).value
                : "",
            status: status ? statusValues : "",
            claim_status: data?.claim_status
                ? formatStatus(data?.claim_status)
                : "",
            job: data?.job,
            start_date: data?.start_date
                ? new Date(data?.start_date).toLocaleDateString("en-CA")
                : "",
            end_date: data?.end_date
                ? new Date(data?.end_date).toLocaleDateString("en-CA")
                : "",
        };

        getQuotations(param);
        setSearchValue(param);
    };

    const formatStatus = (data) => {
        let statusValues = [];

        data?.forEach((status) => {
            statusValues.push(status?.value);
        });

        return statusValues;
    };

    const getConfig = useCallback(async (param = null) => {
        try {
            const response = await quotationsApi.getQuotationConfig(param);
            setFieldColumn(response.data?.data);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, []);

    const regenerateReportApi = async (id) => {
        try {
            setIsLoadingActive(true);
            const response = await quotationsApi.regenerateReport(id);

            if (response.status === 200) {
                setIsLoadingActive(false);
                setRerender(!rerender);

                notyf.open({
                    type: "success",
                    message:
                        "Successfully regenerated report. Please wait for 3 minutes.",
                });
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: error,
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getQuotations();
    }, [getQuotations, rerender]);

    useEffect(() => {
        if (!schools.length) {
            getSchools();
        }
    }, [getSchools]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <RerenderContextQuotation.Provider value={[rerender, setRerender]}>
            <Helmet title="Quotations" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Quotation</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <Row>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="reference_number"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Form.Control
                                                    type="text"
                                                    className={`form-box ${
                                                        isMobile && "mt-2"
                                                    }`}
                                                    placeholder="Reference number"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="school"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className={`${
                                                        isMobile && "mt-2"
                                                    }`}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    options={schools}
                                                    placeholder="Select school"
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="status_quotation"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className={`${
                                                        isMobile && "mt-2"
                                                    }`}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    options={
                                                        status_options_quotation
                                                    }
                                                    placeholder="Select quotation status"
                                                    value={value}
                                                    isMulti={true}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="status_wso"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className={`${
                                                        isMobile && "mt-2"
                                                    }`}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    options={status_options_wso}
                                                    placeholder="Select WSO status"
                                                    value={value}
                                                    isMulti={true}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="claim_status"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className={`mt-2`}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    options={claim_status}
                                                    placeholder="Select pending claim"
                                                    value={value}
                                                    isMulti={true}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="job"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Form.Control
                                                    type="text"
                                                    className={`form-box mt-2`}
                                                    placeholder="Search job"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={2} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="start_date"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    onChange={onChange}
                                                    className={`form-box form-control mt-2 ${
                                                        isMobile && "w-100"
                                                    }`}
                                                    placeholderText="Start date"
                                                    selected={value}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={2} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="end_date"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    onChange={onChange}
                                                    className={`form-box form-control mt-2 ${
                                                        isMobile && "w-100"
                                                    }`}
                                                    placeholderText="End date"
                                                    selected={value}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={1} sm={12} xs={12}>
                                        <Button
                                            variant="primary"
                                            className={`me-1 form-box mt-2 ${
                                                isMobile && "w-100"
                                            }`}
                                            onClick={handleSubmit(search)}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                    {hasRoleCollection.isTermContractor && (
                                        <Col md={12} sm={12} xs={12}>
                                            <h1 className="h3 mb-3">
                                                <Button
                                                    variant="primary"
                                                    className={`float-end form-box mt-2 ${
                                                        isMobile && "w-100"
                                                    }`}
                                                    onClick={() => {
                                                        resetStorage();
                                                        navigate(
                                                            `/works-order/quotation-list/create`
                                                        );
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                    />
                                                </Button>
                                            </h1>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive &&
                            configColumn &&
                            quotations.data && (
                                <DynamicTable
                                    dataPagination={quotations}
                                    columns={formatConfigFields(
                                        configColumn,
                                        actionField
                                    )}
                                    className="table-layout-fixed"
                                    api={getQuotations}
                                    searchValue={searchValue}
                                    hasTopHorizontalScroll
                                />
                            )}
                    </Card.Body>
                </Card>
            </Container>

            {/* Modals */}

            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteQuotation}
            />

            <PostApproval
                modalInfo={modalSupportingDocument}
                setModalInfo={setModalSupportingDocument}
                module="quotation"
            />

            <VoidQuotationModal
                modalInfo={modalVoid}
                setModalInfo={setModalVoid}
            />

            <RejectQuotationModal
                modalInfo={modalReject}
                setModalInfo={setModalReject}
            />

            <CloneQuotationModal
                modalInfo={modalCloneQuotation}
                setModalInfo={setModalCloneQuotation}
                schools={schools}
            />

            <ConfirmationModal
                modalInfo={modalConfirmation}
                setModalInfo={setModalConfirmation}
            />
        </RerenderContextQuotation.Provider>
    );
};

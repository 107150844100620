import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import DynamicTable from "components/ui/DynamicTable";
import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as copApi from "@api/copApi";
import { LoadingContext } from "App";
import NotyfContext from "contexts/NotyfContext";
import { formatConfigFields } from "utils/utilities";

export const RerenderContextCop = createContext();

export const CopList = () => {
    const navigate = useNavigate();
    const location = useLocation();

    //
    // States
    //

    const [rerender, setRerender] = useState([]);
    const [searchValue, setSearchValue] = useState({
        name: "",
        slug: "",
        description: "",
    });
    const [cops, setCops] = useState([]);
    const [configColumn, setFieldColumn] = useState([]);
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const notyf = useContext(NotyfContext);
    const [width] = useState(window.innerWidth);
    let isDesktop = width > 1400;

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 80 : 120,
        Cell: ({ cell }) => (
            <div>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>View cop</Tooltip>}
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faEye}
                            className="align-middle me-1"
                            onClick={() =>
                                navigate(
                                    `${location.pathname}/${cell.row.original.id}/cover`
                                )
                            }
                            size="lg"
                        />
                    </span>
                </OverlayTrigger>
            </div>
        ),
    };

    const getCops = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await copApi.getCops(param);

                if (response.status === 200) {
                    setCops(response.data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, setIsLoadingActive]
    );

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await copApi.getCopConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    // const search = () => {
    //     getRoles(searchValue);
    // };

    //
    // UseEffects
    //

    useEffect(() => {
        getCops();
        getConfig();
    }, [getCops, rerender, getConfig]);

    return (
        <RerenderContextCop.Provider value={[setRerender, rerender]}>
            <Helmet title="COP" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">COP</h4>
                <Card>
                    <Card.Header>
                        {/* <Row>
                            <Col md={10} sm={12} xs={12}>
                                <Row>
                                    <Col md={3} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block form-box"
                                            placeholder="Zone"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block form-box"
                                            placeholder="Month"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    slug: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block form-box"
                                            placeholder="Year"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    description: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Button
                                            variant="primary"
                                            className="me-1 mb-1 form-box"
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {Boolean(!isLoadingActive && configColumn) && (
                            <DynamicTable
                                dataPagination={{
                                    data: cops || "",
                                    links: "",
                                    meta: "",
                                }}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </RerenderContextCop.Provider>
    );
};

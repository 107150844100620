import React, { useState } from "react";

import { Badge, Col, FormLabel, Row } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { status } from "config";
import { ViewImageModal } from "../../modal/ViewImageModal";

export const StatusHistory = ({ histories, tcRemarks = "" }) => {
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;
    const [modalInfo, setModalInfo] = useState({
        image: "",
        open: false,
        label: "",
    });

    return (
        <Row className="mt-3">
            <Col md={12}>
                <FormLabel className="font-weight-bold">
                    Status history
                </FormLabel>
                <div style={{ border: "1px dashed #adb5bd" }} className="p-3">
                    {!isMobile && (
                        <>
                            <Row>
                                <Col md={10}>
                                    <Row>
                                        <Col
                                            md={2}
                                            className="font-weight-bold"
                                        >
                                            User
                                        </Col>
                                        <Col
                                            md={2}
                                            className="font-weight-bold"
                                        >
                                            Role
                                        </Col>
                                        <Col
                                            md={2}
                                            className="font-weight-bold"
                                        >
                                            Status
                                        </Col>
                                        <Col
                                            md={1}
                                            className="font-weight-bold"
                                        >
                                            Sign
                                        </Col>
                                        <Col
                                            md={2}
                                            className="font-weight-bold text-end"
                                        >
                                            Updated at
                                        </Col>
                                        <Col
                                            md={3}
                                            className="font-weight-bold"
                                        >
                                            Remarks
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={2}>
                                    <Row>
                                        <Col
                                            md={12}
                                            className="font-weight-bold"
                                        >
                                            TC remarks
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                        </>
                    )}
                    <Row>
                        <Col md={10}>
                            {histories ? (
                                histories.map((data, key, row) => {
                                    return isMobile ? (
                                        <React.Fragment key={key}>
                                            <Row>
                                                <Col md={2}>
                                                    User:{" "}
                                                    {data?.user?.full_name ||
                                                        "-"}
                                                </Col>
                                                <Col md={2}>
                                                    Role: {data.approval_type}
                                                </Col>
                                                <Col md={2}>
                                                    Status:{" "}
                                                    <Badge
                                                        pill
                                                        bg={status(
                                                            data.status ||
                                                                "PENDING"
                                                        )}
                                                    >
                                                        {data.status ||
                                                            "PENDING"}
                                                    </Badge>
                                                </Col>
                                                <Col md={2}>
                                                    Remarks:{" "}
                                                    {data.remarks || "-"}
                                                </Col>
                                                <Col md={2}>
                                                    Signature:{" "}
                                                    {data.signature && (
                                                        <FontAwesomeIcon
                                                            icon={faEye}
                                                            className="align-middle me-1"
                                                            onClick={() =>
                                                                setModalInfo({
                                                                    image: data.signature,
                                                                    open: true,
                                                                    label: "View signature",
                                                                })
                                                            }
                                                            size="lg"
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    )}
                                                </Col>
                                                <Col md={2}>
                                                    Updated at:{" "}
                                                    {data.status
                                                        ? data.updated_at
                                                        : "-"}
                                                </Col>
                                            </Row>
                                            {key + 1 !== row.length && <hr />}
                                        </React.Fragment>
                                    ) : (
                                        <Row
                                            className={
                                                key + 1 !== row.length && "mb-3"
                                            }
                                            key={key}
                                        >
                                            <Col md={2}>
                                                {data?.user?.full_name || "-"}
                                            </Col>
                                            <Col md={2}>
                                                {data.approval_type}
                                            </Col>
                                            <Col md={2}>
                                                <Badge
                                                    pill
                                                    bg={status(
                                                        data.status || "PENDING"
                                                    )}
                                                >
                                                    {data.status || "PENDING"}
                                                </Badge>
                                            </Col>
                                            <Col md={1}>
                                                {data.signature && (
                                                    <FontAwesomeIcon
                                                        icon={faEye}
                                                        className="align-middle me-1"
                                                        onClick={() =>
                                                            setModalInfo({
                                                                image: data.signature,
                                                                open: true,
                                                                label: "View signature",
                                                            })
                                                        }
                                                        size="lg"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                )}
                                            </Col>
                                            <Col md={2} className="text-end">
                                                {data.status
                                                    ? data.updated_at
                                                    : "-"}
                                            </Col>
                                            <Col md={3}>
                                                {data.remarks || "-"}
                                            </Col>
                                        </Row>
                                    );
                                })
                            ) : (
                                <Row>
                                    <Col className="text-center">
                                        No status history available
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        {!isMobile ? (
                            <Col md={2}>
                                <Row>
                                    <Col md={12}>{tcRemarks}</Col>
                                </Row>
                            </Col>
                        ) : (
                            <>
                                <hr className="mt-4" />
                                <Row>
                                    <Col md={12}>TC remarks: {tcRemarks}</Col>
                                </Row>
                            </>
                        )}
                    </Row>
                </div>
            </Col>
            <ViewImageModal modalInfo={modalInfo} setModalInfo={setModalInfo} />
        </Row>
    );
};

import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from "react";
import {
    faChartLine,
    faClipboard,
    faCalendarAlt,
    faLayerGroup,
    faList,
    faSearch,
    faDollarSign,
    faBook,
    faSwatchbook,
    faFolderOpen,
    faFileAlt,
    faCog,
    faFileExport,
    faWrench,
} from "@fortawesome/free-solid-svg-icons";
import api from "../utils/api";
import { isValidToken, setLoginId, setSession, setUID } from "../utils/jwt";
import * as authApi from "@api/authApi";
import NotyfContext from "../contexts/NotyfContext";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingContext } from "App";
import useLocalStorage from "hooks/useLocalStorage";
import * as menuApi from "@api/menuApi";
import * as usersApi from "@api/usersApi";
import * as commonApi from "@api/commonApi";
import CookieService from "service/CookieService";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    uid: null,
    user: null,
    menus: null,
};

const icons = {
    faChartLine,
    faClipboard,
    faCalendarAlt,
    faLayerGroup,
    faList,
    faSearch,
    faDollarSign,
    faBook,
    faSwatchbook,
    faFolderOpen,
    faFileAlt,
    faCog,
    faFileExport,
    faWrench,
};

const JWTReducer = (state, action) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user,
                uid: action.payload.uid,
                menus: action.payload.menus,
                config: action.payload.config,
            };
        case SIGN_IN:
            return {
                ...state,
                isAuthenticated: true,
                uid: action.payload.uid,
            };
        case SIGN_OUT:
            return {
                ...state,
                isAuthenticated: false,
                uid: null,
                user: null,
                menus: null,
                config: null,
                isInitialized: false,
            };

        case SIGN_UP:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };

        default:
            return state;
    }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
    const location = useLocation();

    const [state, dispatch] = useReducer(JWTReducer, initialState);
    const notyf = useContext(NotyfContext);
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    // eslint-disable-next-line
    const [quotationItemsStorage, setQuotationItemsStorage] = useLocalStorage(
        "quotationItems",
        []
    );
    // eslint-disable-next-line
    const [quotationDetailsStorage, setQuotationDetailsStorage] =
        useLocalStorage("quotationDetails", []);

    // functions

    const formatIcon = (data) => {
        let formatMenu = [];
        data.map((item) => {
            let temp = item;
            temp.icon = icons[item.icon];
            formatMenu.push(temp);
            return "";
        });
        return formatMenu;
    };

    const initialize = useCallback(async () => {
        try {
            const accessToken = CookieService.get("authToken");
            const uid = CookieService.get("uid");

            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken);

                const menuResponse = await menuApi.getMenus();
                const configResponse = await commonApi.getConfig();
                const response = await usersApi.getUser(uid);

                const user = response.data.data;

                dispatch({
                    type: INITIALIZE,
                    payload: {
                        isAuthenticated: true,
                        user: user,
                        menus: formatIcon(menuResponse?.data?.data),
                        config: configResponse?.data?.data,
                    },
                });
            } else {
                CookieService.remove("authToken");
                dispatch({
                    type: INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        menus: [],
                        config: [],
                    },
                });
                //navigate('/auth/sign-in')
            }
        } catch (err) {
            CookieService.remove("authToken");
            dispatch({
                type: INITIALIZE,
                payload: {
                    isAuthenticated: false,
                    user: null,
                    config: [],
                },
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const signIn = async (data) => {
        const accessToken = CookieService.get("authToken");
        setIsLoadingActive(true);

        const response = await authApi.logIn(data);

        if (response.data.status === "SUCCESS") {
            if (!accessToken) setSession(response.data.data.token);
            setUID(response.data.data.uid);
            setLoginId(response.data.data?.login_id);
            const uid = response.data.data.uid;

            // const menuResponse = await menuApi.getMenus();
            // const configResponse = await commonApi.getConfig();
            // const userResponse = await usersApi.getUser(uid);

            // const user = userResponse.data.data;
            dispatch({
                type: SIGN_IN,
                payload: {
                    uid,
                },
            });
            // dispatch({
            //     type: INITIALIZE,
            //     payload: {
            //         isAuthenticated: true,
            //         user: user,
            //         menus: formatIcon(menuResponse?.data?.data),
            //         config: configResponse?.data?.data,
            //     },
            // });
            initialize();

            if (
                location?.state?.page_redirect !== "" &&
                location?.state?.page_redirect !== undefined
            ) {
                const navUrl = location?.state?.page_redirect.replace(
                    origin,
                    ""
                );

                navigate(navUrl);
                // window.location.href = location?.state?.page_redirect;
                // return null;
            } else {
                navigate("/");
            }

            setIsLoadingActive(false);
        }

        if (response.data.status === "ERROR") {
            notyf.open({
                type: "danger",
                message: response.data.message,
            });
            setIsLoadingActive(false);
        }
    };

    const signOut = async () => {
        try {
            await authApi.logOut({
                login_id: CookieService.get("loginId"),
            });
            setSession(null);
            setUID(null);
            setLoginId(null);
            dispatch({ type: SIGN_OUT });

            setQuotationItemsStorage([]);
            setQuotationDetailsStorage({});

            CookieService.remove("authToken");

            const accessToken = CookieService.get("authToken");

            if (!accessToken) {
                navigate("/auth/sign-in");
                notyf.open({
                    type: "success",
                    message: "You've successfully logout",
                });
            }
        } catch (error) {}
    };

    const signUp = async (email, password, firstName, lastName) => {
        const response = await api.post("/api/auth/sign-up", {
            email,
            password,
            firstName,
            lastName,
        });
        const { accessToken, user } = response.data;

        CookieService.set("accessToken", accessToken);
        dispatch({
            type: SIGN_UP,
            payload: {
                user,
            },
        });
    };

    const resetPassword = (email) => console.log(email);

    const hasRole = (role) => {
        if (state.isAuthenticated) {
            const user_role = state?.user?.role?.slug;
            if (role === user_role) {
                return true;
            } else {
                return false;
            }
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "jwt",
                signIn,
                signOut,
                signUp,
                resetPassword,
                hasRole,
                initialize,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };

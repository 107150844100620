import React, { useCallback, useContext, useEffect, useState } from "react";

import Select from "react-select";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import { InspectionTable } from "./Components/InspectionTable";
import { Tabs } from "./Components/Tabs";
import { UnderConstruction } from "components/UnderConstruction";
import { months } from "config";
import { years } from "config";
import { LoadingContext } from "App";
import * as dashboardApi from "@api/dashboardApi";
import * as schoolApi from "@api/schoolApi";

export const DashboardInspection = () => {
    const notyf = useContext(NotyfContext);
    const { tab } = useParams();
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
    });
    const { hasRole, user } = useAuth();

    //
    // States
    //

    const [inspections, setInpsections] = useState({});
    const [schools, setSchools] = useState([]);
    const [deliverableSummary, setDeliverableSummary] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    //
    // Functions
    //

    const getInspection = useCallback(
        async (param = null) => {
            try {
                setIsLoadingActive(true);
                const response = await dashboardApi.getInspection(param);

                const data = response.data.data;

                setInpsections(data);

                if (param) {
                    const getLastDayOfMonth = daysInMonth(
                        param.month,
                        param.year
                    );

                    setStartDate(`${param.month}-01-${param.year}`);
                    setEndDate(
                        `${param.month}-${getLastDayOfMonth}-${param.year}`
                    );
                } else {
                    const getLastDayOfMonth = daysInMonth(
                        data.month,
                        new Date().getFullYear()
                    );

                    setStartDate(
                        `${data.month}-01-${new Date().getFullYear()}`
                    );
                    setEndDate(
                        `${
                            data.month
                        }-${getLastDayOfMonth}-${new Date().getFullYear()}`
                    );

                    reset({
                        mo: months?.find(
                            (o) => parseInt(o?.value) === parseInt(data.month)
                        ),
                        yr: years?.find(
                            (o) =>
                                parseInt(o?.value) ===
                                parseInt(new Date().getFullYear())
                        ),
                        school:
                            hasRole("OPERATIONS-MANAGER") && user?.school_id
                                ? {
                                      label: user?.school?.details?.school_name,
                                      value: user?.school_id,
                                  }
                                : "",
                    });
                }

                setIsLoadingActive(false);
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    };

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            const data = response.data.data;
            const schoolsMapped = data.map((field, index) => {
                return { label: field.name, value: field.id };
            });

            setSchools(schoolsMapped);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const lookUpType = (type) => {
        const lookup = {
            "building-maintenance": "Building maintenance",
            "safety-audit-inspection": "Safety audit inspection",
            "turfing-and-planting-maintenance":
                "Turfing and planting maintenance",
        };

        return lookup[type];
    };

    const search = (data) => {
        const searchValue = {
            school_id: data.school ? data.school.value : "",
            month: data.mo
                ? months?.find((o) => o?.value === data.mo.value)?.value
                : "",
            year: data.yr
                ? years?.find((o) => o?.value === data.yr.value)?.value
                : "",
        };

        getInspection(searchValue);
    };

    const getDeliverableSummary = useCallback(
        async (param = null) => {
            // try {
            //     const response = await deliverablesApi.getDeliverableSummary(
            //         id
            //     );
            //     if (response.status === 200) {
            //         setDeliverableSummary(response.data.data);
            //     }
            // } catch (error) {
            //     notyf.open({
            //         type: "danger",
            //         message: "Something went wrong with the server",
            //     });
            // }
        },
        [notyf]
    );

    //
    // UseEffects
    //

    useEffect(() => {
        getInspection();
        getSchools();
    }, []);

    return (
        <>
            <React.Fragment>
                <Helmet title="Dashboard - Inspection" />
                <Container fluid className="p-0">
                    <h4 className="h4">Dashboard - Inspection</h4>
                    <Card>
                        <Card.Body>
                            <Tabs module="inspection" />
                            <hr />

                            {inspections?.schools &&
                            Object.keys(inspections?.schools).length ? (
                                <>
                                    <h5 className="mb-4">{lookUpType(tab)}</h5>
                                    <Row className="mb-4">
                                        <Col md={12} sm={10} xs={10}>
                                            <Row>
                                                <Col md={3} sm={4} xs={4}>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="school"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                                ref,
                                                            },
                                                        }) => (
                                                            <Select
                                                                inputRef={ref}
                                                                classNamePrefix="react-select"
                                                                options={
                                                                    schools
                                                                }
                                                                isSearchable={
                                                                    true
                                                                }
                                                                isClearable={
                                                                    true
                                                                }
                                                                value={value}
                                                                onChange={
                                                                    onChange
                                                                }
                                                                placeholder="Select school"
                                                            />
                                                        )}
                                                    />
                                                </Col>

                                                <Col md={3} sm={3} xs={3}>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="mo"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <Select
                                                                className="is-invalid react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={months}
                                                                isSearchable={
                                                                    true
                                                                }
                                                                isClearable={
                                                                    true
                                                                }
                                                                isMulti={false}
                                                                value={value}
                                                                onChange={
                                                                    onChange
                                                                }
                                                                menuPlacement="bottom"
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                                <Col md={3} sm={3} xs={3}>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="yr"
                                                        render={({
                                                            field: {
                                                                value,
                                                                onChange,
                                                            },
                                                        }) => (
                                                            <Select
                                                                className="is-invalid react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={years}
                                                                isSearchable={
                                                                    true
                                                                }
                                                                isClearable={
                                                                    true
                                                                }
                                                                isMulti={false}
                                                                value={value}
                                                                onChange={
                                                                    onChange
                                                                }
                                                                menuPlacement="bottom"
                                                            />
                                                        )}
                                                    />
                                                </Col>

                                                <Col md={3} sm={2} xs={2}>
                                                    <Button
                                                        variant="primary"
                                                        className="me-1 mb-1 form-box"
                                                        onClick={handleSubmit(
                                                            search
                                                        )}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faSearch}
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {false && (
                                        <Row>
                                            <Col md={6}>
                                                <Form.Label className="font-weight-bold">
                                                    Inspection summary details
                                                </Form.Label>
                                                <Table bordered size="md">
                                                    <tbody>
                                                        <tr>
                                                            <th>Submitted</th>
                                                            <td className="text-end">
                                                                {deliverableSummary?.submitted ||
                                                                    0}
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip>
                                                                            View
                                                                            submitted
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <a
                                                                        href={`/inspection-checklist/inspection-servicing
                                                                        ?status=SUBMITTED
                                                                        &category=${deliverableSummary?.category}
                                                                        &start_date=${deliverableSummary?.start_date}
                                                                        &end_date=${deliverableSummary?.end_date}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className="btn-link pe-1"
                                                                    >
                                                                        View
                                                                    </a>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Acknowledged
                                                            </th>
                                                            <td className="text-end">
                                                                {deliverableSummary?.acknowledged ||
                                                                    0}
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            View
                                                                            acknowledged
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <a
                                                                        href={`/inspection-checklist/inspection-servicing?
                                                                        status=ACKNOWLEDGED
                                                                        &category=${deliverableSummary?.category}
                                                                        &start_date=${deliverableSummary?.start_date}
                                                                        &end_date=${deliverableSummary?.end_date}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className="btn-link pe-1"
                                                                    >
                                                                        View
                                                                    </a>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Approved</th>
                                                            <td className="text-end">
                                                                {deliverableSummary?.approved ||
                                                                    0}
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            View
                                                                            approved
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <a
                                                                        href={`/inspection-checklist/inspection-servicing
                                                                        ?status=APPROVED
                                                                        &category=${deliverableSummary?.category}
                                                                        &start_date=${deliverableSummary?.start_date}
                                                                        &end_date=${deliverableSummary?.end_date}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className="btn-link pe-1"
                                                                    >
                                                                        View
                                                                    </a>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Pending
                                                                (Submitted +
                                                                Acknowledged)
                                                            </th>
                                                            <td className="text-end">
                                                                {deliverableSummary?.pending_approval ||
                                                                    0}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    )}

                                    <InspectionTable
                                        inspections={inspections.schools}
                                        type={tab.toLocaleLowerCase()}
                                        start_date={startDate}
                                        end_date={endDate}
                                    />
                                </>
                            ) : (
                                <UnderConstruction />
                            )}
                        </Card.Body>
                    </Card>
                </Container>
            </React.Fragment>
        </>
    );
};

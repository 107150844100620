import React from "react";

import { Button, Col, Modal, Row } from "react-bootstrap";

export const ConfirmationModal = ({ modalInfo, setModalInfo }) => {
    const closeModal = () => {
        setModalInfo({
            open: false,
        });
    };

    const callApi = () => {
        modalInfo.api();
        setModalInfo({
            open: false,
        });
    };

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => closeModal()}
            size="sm"
            centered
        >
            <Modal.Body className="text-center my-2">
                <p className="mb-0">{modalInfo.notifMsg}</p>
                {modalInfo.subNotifMsg && (
                    <>
                        <br />
                        <i style={{ fontSize: "10px" }}>
                            {modalInfo.subNotifMsg}
                        </i>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12} className="text-center">
                        <Button
                            className="me-2"
                            variant="primary"
                            type="submit"
                            onClick={() => callApi()}
                        >
                            Confirm
                        </Button>
                        <Button variant="link" onClick={closeModal}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

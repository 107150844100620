import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { Helmet } from "react-helmet-async";
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { DeleteModal } from "components/DeleteModal";
import NotyfContext from "contexts/NotyfContext";
import DynamicTable from "components/ui/DynamicTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons";

export const RerenderContextDefectsAdd = createContext();

export const DefectsAdd = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);

    //
    // States
    //

    const [rerender, setRerender] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [searchValue, setSearchValue] = useState({
        name: "",
    });
    const [selectedFlatRows] = useState([]);

    const tableColumns = [
        {
            Header: "Actions",
            accessor: "actions",
            width: "70px",
            Cell: () => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View defect</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="align-middle me-1"
                                    size={16}
                                    onClick={() =>
                                        navigate(
                                            "/works-order/quotation-list/create/add-defect/view"
                                        )
                                    }
                                />
                            </span>
                        </OverlayTrigger>
                    </>
                );
            },
        },
        {
            Header: "Category",
            accessor: "category",
        },
        {
            Header: "Facility",
            accessor: "facility",
        },
        {
            Header: "Element",
            accessor: "element",
        },
        {
            Header: "Date found",
            accessor: "date_found",
        },
    ];

    const data = [
        {
            category: "Building Maintenance",
            facility: "Plumbling & Sanitation",
            element: "Drainage System",
            date_found: "24-Aug-22 12:00",
        },
        {
            category: "Building Maintenance",
            facility: "Plumbling & Sanitation",
            element: "Drainage System",
            date_found: "24-Aug-22 12:00",
        },
        {
            category: "Building Maintenance",
            facility: "Plumbling & Sanitation",
            element: "Drainage System",
            date_found: "24-Aug-22 12:00",
        },
    ];

    //
    // Functions
    //

    const deletedefect = useCallback(
        async (id) => {
            try {
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getdefects = useCallback(
        async (param = null) => {
            try {
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const search = () => {
        getdefects(searchValue);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        getdefects();
    }, [getdefects, rerender]);

    return (
        <RerenderContextDefectsAdd.Provider value={[setRerender, rerender]}>
            <Helmet title="Add evidence" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">Add evidence</h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() =>
                                    navigate("/works-order/quotation-list")
                                }
                            >
                                Quotation list
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                onClick={() =>
                                    navigate(
                                        "/works-order/quotation-list/create"
                                    )
                                }
                            >
                                Create
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Add evidence
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Header className="pb-0 mt-2">
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={3}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block"
                                            placeholder="Category"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    category: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block"
                                            placeholder="Facility"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    facility: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Control
                                            type="text"
                                            className="d-inline-block"
                                            placeholder="Element"
                                            onChange={(e) =>
                                                setSearchValue({
                                                    ...searchValue,
                                                    element: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Button
                                            variant="primary"
                                            className="me-1 mb-1"
                                            onClick={search}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <h5 className="mb-3">List of defects</h5>
                        <DynamicTable
                            dataPagination={{
                                data: data,
                                links: "",
                                meta: "",
                            }}
                            columns={tableColumns}
                            className="table-layout-fixed"
                            selectedFlatRows={selectedFlatRows}
                            withCheckbox
                        />
                        <Row className="pt-4">
                            <Col md={12} className="text-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    type="submit"
                                >
                                    Add selected
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={() =>
                                        navigate(
                                            "/works-order/quotation-list/create"
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <DeleteModal
                            modalInfo={modalInfo}
                            setModalInfo={setModalInfo}
                            api={deletedefect}
                        />
                    </Card.Body>
                </Card>
            </Container>
        </RerenderContextDefectsAdd.Provider>
    );
};

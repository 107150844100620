import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import Select from "react-select";
import { Helmet } from "react-helmet-async";
import {
  Badge,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import { LoadingContext } from "App";
import { toTitleCase } from "utils/utilities";
import { DeleteModal } from "components/DeleteModal";
import { status, modules } from "config";
import * as excelExportApi from "@api/excelExportApi";
import * as schoolApi from "@api/schoolApi";

export const RerenderContextExport = createContext();

export const ExcelExport = () => {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

  const { control, handleSubmit } = useForm({});

  const tableColumns = [
    {
      Header: "Actions",
      width: isDesktop ? 70 : 120,
      Cell: ({ row }) => {
        return (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Delete export</Tooltip>}
            >
              <span>
                <FontAwesomeIcon
                  icon={faTrash}
                  className="align-middle me-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => openDeleteModal(row.original.id)}
                  size="lg"
                />
              </span>
            </OverlayTrigger>
          </>
        );
      },
    },
    {
      Header: "Module",
      accessor: "module",
      Cell: ({ row }) => {
        return row.original.module;
      },
    },
    {
      Header: () => <div>Filter items</div>,
      accessor: "properties",
      width: "250",
      Cell: ({ row }) => {
        let index = 0;
        return (
          row.original?.properties &&
          Object.entries(row.original?.properties).map(([key, value]) => {
            if (!value) return;

            if (key === "school") {
              const school = schools.find((data) => data.value === value);
              index++;
              return (
                <div key={index}>{`${toTitleCase(key)}: ${school?.label}`}</div>
              );
            }
            if (key === "status") {
              if (typeof value !== "string") {
                if(value.length < 1) return <span></span>
                return (
                  <div className="flex">
                    Status:{" "}
                    {value.map((status) => {
                      return (
                        <Badge pill bg="primary" className="me-1">
                          {status.value}
                        </Badge>
                      );
                    })}
                  </div>
                );
              } else {
                if(!value) return <span></span>
                return (
                  <div className="flex">
                    Status:{" "}
                    <Badge pill bg="primary" className="me-1">
                      {value}
                    </Badge>
                  </div>
                );
              }
            }
            if (key === "current_status") {
              if (value.length < 1) return <span></span>;
              else
                return (
                  <div className="flex">
                    Current Status:{" "}
                    {value.map((status) => {
                      return (
                        <Badge pill bg="primary" className="me-1">
                          {status.value}
                        </Badge>
                      );
                    })}
                  </div>
                );
            }
            index++;
            return (
              <div className="mb-1" key={index}>{`${toTitleCase(
                key
              )}: ${value}`}</div>
            );
          })
        );
      },
    },
    {
      Header: "Job status",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <Badge pill bg={status(row.original.status)}>
            {row.original.status}
          </Badge>
        );
      },
    },
    {
      Header: "Report",
      accessor: "report_url",
      Cell: ({ row }) => {
        return row.original.status === "SUCCESS" ? (
          <a href={row.original.report_url} target="_blank" rel="noreferrer">
            Download
          </a>
        ) : (
          "-"
        );
      },
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      Cell: ({ row }) => {
        return row.original.remarks || "-";
      },
    },
    {
      Header: "Created at",
      accessor: "created_at",
      Cell: ({ row }) => {
        return row.original.created_at || "-";
      },
    },
  ];

  //
  // States
  //

  const [exports, setExports] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [schools, setSchools] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    id: null,
    notifMsg: "",
    open: false,
    severity: "danger",
  });
  const [width] = useState(window.innerWidth);
  let isDesktop = width > 1400;

  //
  // Functions
  //

  const openDeleteModal = (originalId) => {
    setModalInfo({
      id: originalId,
      notifMsg: "Are you sure you want to delete this item?",
      open: true,
      severity: "primary",
    });
  };

  const getExcelExports = useCallback(
    async (param = null) => {
      try {
        setIsLoadingActive(true);
        const response = await excelExportApi.getExcelExports(param);

        setExports(response.data);
        setIsLoadingActive(false);
      } catch (error) {
        setIsLoadingActive(false);
        notyf.open({
          type: "danger",
          message: "Something went wrong with the server",
        });
      }
    },
    [notyf, setIsLoadingActive]
  );

  const deleteApi = async (id) => {
    try {
      const response = await excelExportApi.deleteExcelExport(id);
      if (response.status === 200) {
        notyf.open({
          type: "success",
          message: response.data.message,
        });
        setRerender(!rerender);
      }
    } catch (error) {
      notyf.open({
        type: "danger",
        message: "Something went wrong with the server",
      });
    }
  };

  const getSchools = useCallback(async () => {
    try {
      const response = await schoolApi.getSchools();
      const schoolsMapped = response.data.data.map((field, index) => {
        return { label: field.name, value: field.id };
      });

      setSchools(schoolsMapped);
    } catch (error) {
      notyf.open({
        type: "danger",
        message: "Something went wrong with the server",
      });
    }
  }, [notyf]);

  const search = (data) => {
    data.module = data?.module?.value || "";
    getExcelExports(data);
  };

  //
  // UseEffects
  //

  useEffect(() => {
    getExcelExports();
    getSchools();
  }, [rerender, getExcelExports]);

  return (
    <>
      <RerenderContextExport.Provider value={[setRerender, rerender]}>
        <h4 className="tab-title mb-3">Excel export</h4>
        <Helmet title="Excel export" />
        <Card className="p-0">
          <Card.Header>
            <Row>
              <Col md={10} sm={10} xs={10}>
                <Row>
                  <Col md={4} sm={5} xs={5}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="module"
                      render={({ field: { onChange } }) => (
                        <Select
                          classNamePrefix="react-select "
                          options={modules}
                          isSearchable={true}
                          isClearable={true}
                          onChange={onChange}
                          placeholder="Select module"
                        />
                      )}
                    />
                  </Col>
                  <Col md={3} sm={2} xs={2}>
                    <Button
                      variant="primary"
                      className="me-1 mb-1 form-box"
                      onClick={handleSubmit(search)}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={2} sm={2} xs={2}>
                <h1 className="h3 mb-3">
                  <Button
                    variant="primary"
                    className={`${
                      width >= 768 && "m-1"
                    } float-end mb-2 form-box`}
                    onClick={() => navigate(`/excel-export/create`)}
                  >
                    <FontAwesomeIcon icon={faPlus} className="feather" />
                  </Button>
                </h1>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="pt-1">
            {!isLoadingActive && exports.data && (
              <DynamicTable
                dataPagination={exports}
                columns={tableColumns}
                className="table-layout-fixed"
                api={getExcelExports}
              />
            )}
          </Card.Body>
        </Card>
      </RerenderContextExport.Provider>

      <DeleteModal
        api={deleteApi}
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
      />
    </>
  );
};

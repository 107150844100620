import api from "../utils/api";

export const getDocuments = (data) => {
    return api.get("/documents", { params: data });
};

export const getDocument = (data) => {
    return api.get(`/documents/${data}`);
};

export const createDocument = (data) => {
    return api.post("/documents", data);
};

export const updateDocument = (id, data) => {
    return api.post(`/documents/${id}`, data);
};

export const deleteDocument = (id) => {
    return api.delete(`/documents/${id}`);
};

export const getDocumentsConfig = (data) => {
    return api.get(`/config/document`, data);
};

import React from "react";

import Select from "react-select";
import {
    Accordion,
    Alert,
    Badge,
    Button,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import {
    faBell,
    faPlus,
    faRedo,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { status } from "config";

export const OperationManagers = ({
    control,
    addOperationManager,
    operationManagerFields,
    operationManagerRemove,
    school,
    operationManagers,
    getOutstandingItems,
    outstandingItems,
}) => {
    return (
        <>
            <Row>
                <Form>
                    <Row className="font-weight-bold mb-2">
                        <h5>Operations managers</h5>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name="add_operation_manager"
                                    defaultValue=""
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Select
                                            classNamePrefix="react-select"
                                            options={operationManagers}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={onChange}
                                            placeholder="Select operation manager"
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Button
                                variant="primary"
                                className="mb-2 form-box"
                                onClick={() => {
                                    addOperationManager();
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </Col>
                        <Col md={12}>
                            <Table bordered responsive striped>
                                <thead>
                                    <tr>
                                        <th>Actions</th>
                                        <th>User</th>
                                        <th
                                            style={{
                                                width: "100px",
                                            }}
                                        >
                                            Send email
                                        </th>
                                        <th>Last updated by</th>
                                        <th>Last updated at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {operationManagerFields.length ? (
                                        operationManagerFields.map(
                                            (item, index) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip>
                                                                        Delete
                                                                        claim
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                        className="align-middle me-1 text-danger"
                                                                        size="lg"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            operationManagerRemove(
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            {item.full_name}
                                                            <br />
                                                            <Badge
                                                                pill
                                                                bg="secondary"
                                                                className="text-wrap"
                                                            >
                                                                {item.email}
                                                            </Badge>
                                                        </td>

                                                        <td>
                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name={`operation_managers[${index}].school_send_email`}
                                                                defaultValue=""
                                                                render={({
                                                                    field: {
                                                                        value,
                                                                        onChange,
                                                                        onBlur,
                                                                    },
                                                                }) => (
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        value={
                                                                            value ||
                                                                            false
                                                                        }
                                                                        checked={
                                                                            value ||
                                                                            false
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </td>
                                                        <td>
                                                            {
                                                                item.school_updated_by
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item.school_updated_at
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={6}
                                                className="text-center"
                                            >
                                                No operation managers assigned
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Form>
            </Row>
            <Row>
                <Col md={12}>
                    <Alert variant="warning">
                        <div className="alert-message">
                            <Row>
                                <Col md={1} style={{ width: "30px" }}>
                                    <FontAwesomeIcon icon={faBell} />
                                </Col>
                                <Col md={10}>
                                    <strong>
                                        Pending OM outstanding items
                                    </strong>{" "}
                                    <br />
                                    <p className="mb-2">
                                        Please see and review below the
                                        following pending OM items of{" "}
                                        <strong>{school?.name}</strong> that
                                        will be affected by the change of
                                        configuration.
                                    </p>
                                </Col>
                                <Col className="text-end">
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Refresh pending items
                                            </Tooltip>
                                        }
                                        placement="top"
                                    >
                                        <Button
                                            variant="success"
                                            onClick={() =>
                                                getOutstandingItems()
                                            }
                                        >
                                            <FontAwesomeIcon icon={faRedo} />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row>
                                <div>
                                    {outstandingItems?.operation_managers
                                        ?.length ? (
                                        <React.Fragment>
                                            <Accordion
                                                defaultActiveKey={0}
                                                className="mb-3 mt-3"
                                            >
                                                <Accordion.Item>
                                                    <Accordion.Header>
                                                        Work service order
                                                        <Badge
                                                            pill
                                                            bg="primary"
                                                            className="ms-1"
                                                        >
                                                            {
                                                                outstandingItems
                                                                    .operation_managers
                                                                    .length
                                                            }
                                                        </Badge>
                                                    </Accordion.Header>
                                                    <Accordion.Body className="bg-white">
                                                        <Table
                                                            responsive
                                                            className="table-layout-fixed"
                                                            striped
                                                            bordered
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th width="50">
                                                                        S/N
                                                                    </th>
                                                                    <th>
                                                                        Reference
                                                                        number
                                                                    </th>
                                                                    <th>
                                                                        Status
                                                                    </th>
                                                                    <th>
                                                                        Current
                                                                        pending
                                                                        operation
                                                                        manager(s)
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {outstandingItems.operation_managers.map(
                                                                    (
                                                                        data,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <tr
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <td>
                                                                                    {index +
                                                                                        1}
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href={`/works-order/wso/${data.id}`}
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                        className="btn-link pe-1"
                                                                                    >
                                                                                        {
                                                                                            data.reference_number
                                                                                        }
                                                                                    </a>
                                                                                </td>
                                                                                <td>
                                                                                    <Badge
                                                                                        pill
                                                                                        bg={status(
                                                                                            data.status
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            data.status
                                                                                        }
                                                                                    </Badge>
                                                                                </td>
                                                                                <td>
                                                                                    {operationManagerFields.length ? operationManagerFields.map(
                                                                                        (item, index) => {
                                                                                            return (
                                                                                                <Badge
                                                                                                    key={item.id}
                                                                                                    className="text-center m-1"
                                                                                                >
                                                                                                    {item.full_name}
                                                                                                </Badge>
                                                                                            );
                                                                                        }
                                                                                    ) : ""}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </React.Fragment>
                                    ) : (
                                        <strong
                                            style={{
                                                marginLeft: "30px",
                                            }}
                                        >
                                            There are no pending items
                                        </strong>
                                    )}
                                </div>
                            </Row>
                        </div>
                    </Alert>
                </Col>
            </Row>
        </>
    );
};

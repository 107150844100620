import api from "../utils/api";

export const getInspectionChecklists = (data) => {
    return api.get("/inspection-schedule", { params: data });
};

export const getInspectionChecklistsTodo = (data) => {
    return api.get("/inspection-schedule/to/do", { params: data });
};

export const getInspectionChecklist = (id) => {
    return api.get(`/inspection-schedule/${id}`);
};

export const updateInspectionChecklist = (id, params) => {
    return api.put(`/inspection-schedule/${id}`, params);
};

export const createInspectionChecklist = (data) => {
    return api.post("/inspection-schedule", data);
};

export const deleteInspectionChecklist = (inspection_schedule_id) => {
    return api.delete(`/inspection-schedule/${inspection_schedule_id}`);
};

export const getInspectionChecklistConfig = (data) => {
    return api.get(`/config/inspection-schedule`, data);
};

export const previewInspection = (id, params) => {
    return api.get(`/inspection-schedule/${id}/preview`, params);
};

export const updateInspectionSchool = (id, params) => {
    return api.post(`/inspection-schedule/${id}/update-school`, params);
};

export const regenerateReport = (id) => {
    return api.get(`/inspection-schedule/${id}/report-generation`);
};

export const getUncheckedItems = (id, data) => {
    return api.get(`/inspection-schedule/get-unchecked-items/${id}`, { params: data });
};

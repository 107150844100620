import React, { useState, useContext, useEffect, useCallback } from "react";

import DatePicker from "react-datepicker";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import {
    Button,
    Card,
    Col,
    Container,
    Row,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { faEye, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DynamicTable from "components/ui/DynamicTable";
import NotyfContext from "contexts/NotyfContext";
import useAuth from "../../hooks/useAuth";
import { DeleteModal } from "components/DeleteModal";
import { formatConfigFields } from "utils/utilities";
import { LoadingContext } from "App";
import {
    checklist_category,
    checklist_type,
} from "../checklist/checklist-config";
import { status_defects } from "config";
import * as schoolApi from "@api/schoolApi";
import * as defectApi from "@api/defectApi";

export const OutstandingDefects = () => {
    //
    // States
    //
    const { hasRole } = useAuth();
    const { state } = useLocation();
    const hasRoleCollection = {
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isTechnian: hasRole("TECHNICIAN"),
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isTermContractorQs: hasRole("TERM-CONTRACTOR-QS"),
        isAdmin: hasRole("ADMIN"),
    };
    const [width] = useState(window.innerWidth);
    let isMobile = width < 768;

    const canDelete =
        hasRoleCollection?.isTermContractor ||
        hasRoleCollection?.isTermContractorQs ||
        hasRoleCollection?.isAdmin
            ? true
            : false;

    const [defects, setDefects] = useState([]);
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const { user } = useAuth();
    const [filter, setFilter] = useState(
        state?.previousSearchValue || {
            created_by: user?.id,
        }
    );
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [rerender, setRerender] = useState([]);
    const [configColumn, setFieldColumn] = useState([]);
    const [schools, setSchools] = useState([]);
    const { control, handleSubmit, reset } = useForm({});
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);
    let isDesktop = width > 1400;

    const actionField = {
        Header: "Actions",
        accessor: "actions",
        width: isDesktop ? 70 : 120,
        Cell: ({ row }) => {
            return (
                <>
                    <div>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View checklist</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="align-middle me-1"
                                    onClick={() => {
                                        const parentChecklistID =
                                            row?.original?.inspection_schedule?.checklist_items
                                                .filter(
                                                    (checklistItem) =>
                                                        checklistItem.sequence ===
                                                        1
                                                )
                                                .map(
                                                    (checklistItem) =>
                                                        checklistItem.id
                                                ) ?? null;

                                        return navigate(
                                            `/inspection-checklist/inspection-servicing/${row?.original?.id}/${row?.original?.inspection_schedule_id}/${parentChecklistID}/${row?.original?.checklist_item_id}/edit-evidence?canSingleSendEmail=true`
                                        );
                                    }}
                                    size="lg"
                                />
                            </span>
                        </OverlayTrigger>
                        {canDelete && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete checklist</Tooltip>}
                            >
                                <span>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="align-middle me-1"
                                        onClick={() => {
                                            openDeleteModal(row.original.id);
                                        }}
                                        size="lg"
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                    </div>
                </>
            );
        },
    };

    //
    // Functions
    //
    const getDefects = useCallback(
        async (param) => {
            try {
                if (!schools.length) return;

                if (!filter.isNewFilter) {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);

                    const statusParam = urlParams.get("status");
                    const schoolParam = urlParams.get("school");
                    const inspectionTypeParam =
                        urlParams.get("inspection_type");
                    const categoryParam = urlParams.get("category");
                    const startDateParam = urlParams.get("start_date");
                    const endDateParam = urlParams.get("end_date");

                    const schoolParse = schools?.find(
                        (o) => o?.label === schoolParam
                    );

                    filter.status = statusParam;
                    filter.school_slug = schoolParse
                        ? [schoolParse?.value]
                        : "";
                    filter.inspection_type = inspectionTypeParam;
                    filter.inspection_category = categoryParam
                        ? categoryParam.toUpperCase()
                        : "";
                    filter.inspected_at_start = startDateParam
                        ? new Date(startDateParam)
                        : "";
                    filter.inspected_at_end = endDateParam
                        ? new Date(endDateParam)
                        : "";

                    reset({
                        school: schoolParam
                            ? [schools?.find((o) => o?.label === schoolParam)]
                            : hasRoleCollection.isOM && user.school_id
                            ? [
                                  {
                                      label: user?.school?.details?.school_name,
                                      value: user?.school?.slug,
                                  },
                              ]
                            : "",
                        inspection_category: categoryParam
                            ? checklist_category.find(
                                  (o) => o.value === categoryParam.toUpperCase()
                              )
                            : "",
                        status: statusParam
                            ? status_defects?.find(
                                  (o) =>
                                      o?.value ===
                                      statusParam.toLocaleUpperCase()
                              )
                            : "",
                        inspection_date_start: startDateParam
                            ? new Date(startDateParam)
                            : "",
                        inspection_date_end: startDateParam
                            ? new Date(endDateParam)
                            : "",
                    });
                }

                setIsLoadingActive(true);
                const response = await defectApi.getDefects({
                    ...param,
                    ...filter,
                });

                if (response.status === 200) {
                    const data = response.data;
                    setDefects(data);
                    setIsLoadingActive(false);
                }
            } catch (error) {
                setIsLoadingActive(false);
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, filter, setIsLoadingActive, schools]
    );

    const deleteDefect = useCallback(
        async (id) => {
            try {
                const response = await defectApi.deleteDefect(id);
                if (response.status === 200) {
                    notyf.open({
                        type: "success",
                        message: response.data.message,
                    });
                    setRerender(!rerender);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf, rerender]
    );

    const openDeleteModal = (originalId) => {
        setModalInfo({
            id: originalId,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "primary",
        });
    };

    const getConfig = useCallback(
        async (param = null) => {
            try {
                const response = await defectApi.getDefectConfig(param);
                setFieldColumn(response.data?.data);
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    const getSchools = useCallback(async () => {
        try {
            const response = await schoolApi.getSchools();
            if (response.status === 200) {
                const data = response.data.data;
                let temp = {};
                temp = data.map((school) => {
                    return {
                        value: school?.slug,
                        label: school?.name,
                    };
                });
                setSchools(temp);

                if (state?.previousSearchValue) {
                    resetFilter(temp);
                }
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, [notyf]);

    const resetFilter = (temp) => {
        const previousSearchValue = state?.previousSearchValue;

        reset({
            school:
                state?.previousSearchValue?.school_slug &&
                temp?.filter((item) =>
                    state?.previousSearchValue?.school_slug?.includes(
                        item?.value
                    )
                ),
            inspection_type:
                previousSearchValue?.inspection_type &&
                status_defects.find(
                    (o) => o.value === previousSearchValue?.inspection_type
                ),
            inspection_category:
                previousSearchValue?.inspection_category &&
                checklist_category.find(
                    (o) => o.value === previousSearchValue?.inspection_category
                ),
            status:
                previousSearchValue?.status &&
                status_defects.find(
                    (o) => o.value === previousSearchValue?.status
                ),
            inspection_date_start:
                previousSearchValue?.inspected_at_start || "",
            inspection_date_end: previousSearchValue?.inspected_at_end || "",
        });
    };

    const searchInspection = useCallback(async (data) => {
        let schoolSlugs = [];

        data?.school !== "" &&
            data?.school?.forEach((school) => {
                schoolSlugs.push(school?.value);
            });

        setFilter({
            isNewFilter: true,
            school_slug: schoolSlugs,
            inspection_category: data?.inspection_category?.value,
            inspection_type: data?.inspection_type,
            inspected_at: data?.inspection_date,
            status: data?.status?.value,
            inspected_at_start: data?.inspection_date_start,
            inspected_at_end: data?.inspection_date_end,
        });
    }, []);

    useEffect(() => {
        getDefects();
        getConfig();
    }, [getConfig, getDefects, rerender]);

    useEffect(() => {
        getSchools();
    }, [getSchools]);

    return (
        <React.Fragment>
            <Helmet title="Outstanding follow up" />
            <Container fluid className="p-0">
                <h4 className="h4 mb-3">Outstanding follow up</h4>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <Row>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="school"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,

                                                    ref,
                                                },
                                            }) => (
                                                <Select
                                                    inputRef={ref}
                                                    className={`${
                                                        isMobile && "w-100"
                                                    }`}
                                                    classNamePrefix="react-select "
                                                    options={schools}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="Select school"
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            name="inspection_category"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className={`${
                                                        isMobile && "w-100"
                                                    }`}
                                                    classNamePrefix="react-select "
                                                    options={checklist_category}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    placeholder="Select category"
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            name="inspection_type"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    className={`${
                                                        isMobile && "w-100"
                                                    }`}
                                                    classNamePrefix="react-select "
                                                    options={checklist_type}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    placeholder="Select type"
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            name="status"
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <Select
                                                    isClearable={true}
                                                    onChange={onChange}
                                                    options={status_defects}
                                                    placeholder="Select status"
                                                    className={`${
                                                        isMobile && "w-100"
                                                    }`}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="inspection_date_start"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                },
                                            }) => (
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={value}
                                                    onChange={onChange}
                                                    className={`form-box form-control mt-2 ${
                                                        isMobile && "w-100"
                                                    }`}
                                                    placeholderText="Start date"
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="inspection_date_end"
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                },
                                            }) => (
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={value}
                                                    onChange={onChange}
                                                    className={`form-box form-control form-box mt-2 ${
                                                        isMobile && "w-100"
                                                    }`}
                                                    placeholderText="End date"
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={3} sm={12} xs={12}>
                                        <Button
                                            variant="primary"
                                            className={`me-1 mb-1 mt-2 form-box ${
                                                isMobile && "w-100"
                                            }`}
                                            onClick={handleSubmit(
                                                searchInspection
                                            )}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} sm={2} xs={2}></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-1">
                        {!isLoadingActive && configColumn && defects.data && (
                            <DynamicTable
                                dataPagination={defects}
                                columns={formatConfigFields(
                                    configColumn,
                                    actionField
                                )}
                                className="table-layout-fixed"
                                api={getDefects}
                            />
                        )}
                        <DeleteModal
                            modalInfo={modalInfo}
                            setModalInfo={setModalInfo}
                            api={deleteDefect}
                        />
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

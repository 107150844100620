import api from "../utils/api";

export const createRole = (data) => {
    return api.post("/role", data);
};

export const updateRole = (id, data) => {
    return api.patch("/role/" + id, data);
};

export const getRoles = (data) => {
    return api.get("/role", { params: data });
};

export const getRole = (id) => {
    return api.get("/role/" + id);
};

export const deleteRole = (id) => {
    return api.delete("/role/" + id);
};

export const getRolesConfig = (data) => {
    return api.get(`/config/role`, data);
};

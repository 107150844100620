import React, { useContext, useState } from "react";

import { Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Controller, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";

import NotyfContext from "contexts/NotyfContext";
import useAuth from "hooks/useAuth";
import { AutoGrowTextarea } from "components/AutoGrowTextarea";
import { FileUploaderSyncfusion } from "components/ui/FileUploaderSyncfusion";
import { errorMessage } from "utils/utilities";
import { StatusHistory } from "./StatusHistory";
import { useEffect } from "react";
import { DeleteModal } from "components/DeleteModal";
import * as usersApi from "@api/usersApi";
import * as attachmentApi from "@api/attachmentApi";

export const OtherDetails = ({
    control,
    document,
    errors,
    hasRoleCollection,
    histories = null,
    setUpload,
    setValue,
    view,
    canUpload,
    canDelete,
    maUserValidation,
    deleteAttachmentCallback,
    isClaims = false,
    isQuotation = false,
    canVerify = true,
    tcRemarks = "",
    canEditInvoice = false,
}) => {
    const [users, setUsers] = useState([]);
    let { action } = useParams();
    const notyf = useContext(NotyfContext);
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const { user } = useAuth();
    const { isTermContractor, isManagingAgent } = hasRoleCollection;
    const maStatus = useWatch({
        control,
        name: "ma_details.status",
    });

    const getStatusHint = (status) => {
        const lookup = {
            VERIFIED: `${
                isClaims ? "Claim" : "Quotation"
            } will be verified and forwarded to TC for approval.`,
            REJECTED: `${
                isClaims ? "Claim" : "Quotation"
            } will be rejected and forwarded back to the term contractor.`,
            ACKNOWLEDGE: `${
                isClaims ? "Claim" : "Quotation"
            } will be acknowledged and forwarded to the next approver.`,
        };

        return lookup[status] || "";
    };

    const getUsers = async () => {
        try {
            const response = await usersApi.getMaUsers();
            if (response.status === 200) {
                const formattedOptions = convertObject(response.data);
                setUsers(formattedOptions);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    const convertObject = (object) => {
        // create an empty result array
        const result = [];

        // iterate over the object
        for (const item of object) {
            // find the index of the label in the result array, or add it if it doesn't exist
            const labelIndex = result.findIndex(
                (label) => label.label === item.designation
            );
            if (labelIndex === -1) {
                result.push({
                    label: item.designation,
                    options: [
                        {
                            value: item.id,
                            label: item.full_name,
                        },
                    ],
                });
            } else {
                result[labelIndex].options.push({
                    value: item.id,
                    label: item.full_name,
                });
            }
        }

        return result;
    };

    const deleteApi = async (id) => {
        try {
            const response = await attachmentApi.deleteAttachment(id);

            if (response.status === 200) {
                notyf.open({
                    type: "success",
                    message: "Successfully deleted file",
                });
                deleteAttachmentCallback(id);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    };

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, []);

    //
    // Conditions
    //

    const canAcknowledge =
        user?.designation !== "MANAGING-AGENT-QUANTITY-SURVEYOR";

    return (
        <>
            <Row>
                <Col md={12}>
                    <Row className="mt-5">
                        <Col md={12}>
                            <FileUploaderSyncfusion
                                action={action}
                                document={document}
                                canDelete={canDelete}
                                canUpload={canUpload}
                                label="Supporting documents"
                                labelBold
                                setModalInfo={setModalInfo}
                                setUpload={setUpload}
                                isQuotation
                                loadedFileLabel="Supporting documents"
                                canEditInvoice={canEditInvoice}
                            />
                        </Col>
                    </Row>
                    {/* Quotation history */}

                    {histories &&
                        (view || isManagingAgent || isTermContractor) && (
                            <StatusHistory
                                histories={histories}
                                tcRemarks={tcRemarks}
                            />
                        )}
                    {/* MA approvals */}
                    {action !== "create" && maUserValidation && (
                        <>
                            <Row className="mt-4">
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label
                                            className="d-block font-weight-bold"
                                            htmlFor="status_check"
                                        >
                                            Status
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="ma_details.status"
                                            render={({ field: { value } }) => (
                                                <>
                                                    {view ||
                                                    isTermContractor ? (
                                                        <p>{value || "-"}</p>
                                                    ) : (
                                                        <>
                                                            {(canVerify ||
                                                                isQuotation) && (
                                                                <Form.Check
                                                                    inline
                                                                    checked={
                                                                        value ===
                                                                        "VERIFIED"
                                                                    }
                                                                    label="Verified"
                                                                    name="status_check"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setValue(
                                                                            "ma_details.status",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    type="radio"
                                                                    value="VERIFIED"
                                                                />
                                                            )}
                                                            <Form.Check
                                                                checked={
                                                                    value ===
                                                                    "REJECTED"
                                                                }
                                                                inline
                                                                label="Rejected"
                                                                name="status_check"
                                                                onChange={(e) =>
                                                                    setValue(
                                                                        "ma_details.status",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                type="radio"
                                                                value="REJECTED"
                                                            />
                                                            {canAcknowledge && (
                                                                <Form.Check
                                                                    checked={
                                                                        value ===
                                                                        "ACKNOWLEDGE"
                                                                    }
                                                                    inline
                                                                    label="Acknowledged and route"
                                                                    name="status_check"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setValue(
                                                                            "ma_details.status",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    type="radio"
                                                                    value="ACKNOWLEDGE"
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        />
                                        <br />
                                        <small>{getStatusHint(maStatus)}</small>
                                        <ErrorMessage
                                            errors={errors}
                                            name="ma_details.status"
                                            render={({ message }) =>
                                                errorMessage(message)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {maStatus === "ACKNOWLEDGE" && canAcknowledge && (
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label
                                                className="d-block font-weight-bold"
                                                htmlFor="ma_details.next_approval"
                                            >
                                                Route to
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                defaultValue=""
                                                name="ma_details.next_approval"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <>
                                                        <Select
                                                            options={users}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="ma_details.next_approval"
                                                render={({ message }) =>
                                                    errorMessage(message)
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label
                                            className="font-weight-bold"
                                            htmlFor="remarks"
                                        >
                                            Status remarks
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="ma_details.status_remarks"
                                            render={({
                                                field: {
                                                    onBlur,
                                                    onChange,
                                                    value,
                                                },
                                            }) => (
                                                <>
                                                    {view ||
                                                    isTermContractor ? (
                                                        <p>{value || "-"}</p>
                                                    ) : (
                                                        <AutoGrowTextarea
                                                            errors={
                                                                errors
                                                                    ?.ma_details
                                                                    ?.status_remarks
                                                            }
                                                            fieldValue={value}
                                                            onBlur={onBlur}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Form.Group>
                                    <ErrorMessage
                                        errors={errors}
                                        name="ma_details.status_remarks"
                                        render={({ message }) =>
                                            errorMessage(message)
                                        }
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
            </Row>
            <DeleteModal
                api={deleteApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
        </>
    );
};

export const checklist_category = [
    {
        label: "Building maintenance",
        value: "BUILDING-MAINTENANCE",
    },
    {
        label: "Safety audit inspection",
        value: "SAFETY-AUDIT-INSPECTION",
    },
    {
        label: "Turfing and planting maintenance",
        value: "TURFING-AND-PLANTING-MAINTENANCE",
    },
];

export const tree_checklist_category = [
    {
        label: "Adhoc",
        value: "ADHOC",
    },
    {
        label: "Regular",
        value: "REGULAR",
    },
];

export const checklist_frequency = [
    {
        label: "Adhoc",
        value: "ADHOC",
    },
    {
        label: "Alternate-days",
        value: "ALTERNATE-DAYS",
    },

    {
        label: "Biannual",
        value: "BIANNUAL",
    },
    {
        label: "Monthly",
        value: "MONTHLY",
    },
    {
        label: "Bimonthly",
        value: "BIMONTHLY",
    },
    {
        label: "Quarterly",
        value: "QUARTERLY",
    },
    {
        label: "Fortnightly",
        value: "FORTNIGHTLY",
    },
    {
        label: "Semiannual",
        value: "SEMIANNUAL",
    },
    {
        label: "Weekly",
        value: "WEEKLY",
    },
    {
        label: "Half-yearly",
        value: "HALF-YEARLY",
    },
    {
        label: "Yearly",
        value: "YEARLY",
    },
];

export const checklist_type = [
    {
        label: "Physical maintenance",
        value: "PHYSICAL-MAINTENANCE",
    },
    {
        label: "Visual inspection",
        value: "VISUAL-INSPECTION",
    },
];

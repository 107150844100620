import React, { useContext } from "react";

import { Helmet } from "react-helmet-async";
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
    Table,
} from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NotyfContext from "contexts/NotyfContext";
import { FsrFormItems } from "./FsrFormItems";

import * as fsrImportApi from "@api/fsrImportApi";
import { errorMessage } from "utils/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

export const FsrForm = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);

    //
    // States
    //

    const schema = yup.object().shape({
        fsrs: yup.array(
            yup.object().shape({
                category: yup.string().required("This item is required"),
                section: yup.string().required("This item is required"),
                section_description: yup
                    .string()
                    .required("This item is required"),
                items: yup.array(
                    yup.object().shape({
                        item_number: yup
                            .string()
                            .required("This item is required"),
                        item_description: yup
                            .string()
                            .required("This item is required"),
                        item_group_description: yup
                            .string()
                            .required("This item is required"),
                        unit: yup.string().required("This item is required"),
                        rate: yup.string().required("This item is required"),
                    })
                ),
            })
        ),
    });

    const defaultValues = {
        fsrs: [
            {
                category: "",
                section: "",
                section_description: "",
                items: [
                    {
                        item_number: "",
                        item_description: "",
                        item_group_description: "",
                        unit: "",
                        rate: "",
                    },
                ],
            },
        ],
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "fsrs",
    });

    //
    // Functions
    //

    const ImportFsr = async (data) => {
        try {
            await fsrImportApi.createFsrs(data);

            notyf.open({
                type: "success",
                message: "Successfully created site",
            });
            navigate(`/works-order/fsr`);
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error.response.data.message,
            });
        }
    };

    //
    // UseEffects
    //

    return (
        <React.Fragment>
            <Helmet title="Create Fsr" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="tab-title mb-3">Create FSR</h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate(`/works-order/fsr`)}
                            >
                                FSR list
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Create</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Card>
                        {fields.length
                            ? fields.map((item, index) => {
                                  return (
                                      <Table bordered responsive key={item.id}>
                                          <thead>
                                              <tr>
                                                  <th style={{ width: "30%" }}>
                                                      Category
                                                  </th>
                                                  <th style={{ width: "25%" }}>
                                                      Section
                                                  </th>
                                                  <th style={{ width: "25%" }}>
                                                      Section description
                                                  </th>
                                                  <th style={{ width: "5%" }}>
                                                      Actions
                                                  </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <>
                                                  <tr className="table-primary">
                                                      <td colSpan={1}>
                                                          <Controller
                                                              control={control}
                                                              name={`fsrs[${index}].category`}
                                                              defaultValue=""
                                                              render={({
                                                                  field: {
                                                                      value,
                                                                      onChange,
                                                                      onBlur,
                                                                  },
                                                              }) => (
                                                                  <Form.Control
                                                                      type="text"
                                                                      value={
                                                                          value
                                                                      }
                                                                      onChange={
                                                                          onChange
                                                                      }
                                                                      onBlur={
                                                                          onBlur
                                                                      }
                                                                      className={`form-box ${
                                                                          errors.fsrs !==
                                                                              undefined &&
                                                                          errors
                                                                              .fsrs[
                                                                              index
                                                                          ] &&
                                                                          errors
                                                                              .fsrs[
                                                                              index
                                                                          ]
                                                                              .category &&
                                                                          "is-invalid"
                                                                      }`}
                                                                  />
                                                              )}
                                                          />
                                                          <ErrorMessage
                                                              errors={errors}
                                                              name={`fsrs[${index}].category`}
                                                              render={({
                                                                  message,
                                                              }) =>
                                                                  errorMessage(
                                                                      message
                                                                  )
                                                              }
                                                          />
                                                      </td>
                                                      <td colSpan={1}>
                                                          <Controller
                                                              control={control}
                                                              name={`fsrs[${index}].section`}
                                                              defaultValue=""
                                                              render={({
                                                                  field: {
                                                                      value,
                                                                      onChange,
                                                                      onBlur,
                                                                  },
                                                              }) => (
                                                                  <Form.Control
                                                                      type="text"
                                                                      value={
                                                                          value
                                                                      }
                                                                      onChange={
                                                                          onChange
                                                                      }
                                                                      onBlur={
                                                                          onBlur
                                                                      }
                                                                      className={`form-box ${
                                                                          errors.fsrs !==
                                                                              undefined &&
                                                                          errors
                                                                              .fsrs[
                                                                              index
                                                                          ] &&
                                                                          errors
                                                                              .fsrs[
                                                                              index
                                                                          ]
                                                                              .section &&
                                                                          "is-invalid"
                                                                      }`}
                                                                  />
                                                              )}
                                                          />
                                                          <ErrorMessage
                                                              errors={errors}
                                                              name={`fsrs[${index}].section`}
                                                              render={({
                                                                  message,
                                                              }) =>
                                                                  errorMessage(
                                                                      message
                                                                  )
                                                              }
                                                          />
                                                      </td>
                                                      <td colSpan={1}>
                                                          <Controller
                                                              control={control}
                                                              name={`fsrs[${index}].section_description`}
                                                              defaultValue=""
                                                              render={({
                                                                  field: {
                                                                      value,
                                                                      onChange,
                                                                      onBlur,
                                                                  },
                                                              }) => (
                                                                  <Form.Control
                                                                      type="text"
                                                                      value={
                                                                          value
                                                                      }
                                                                      onChange={
                                                                          onChange
                                                                      }
                                                                      onBlur={
                                                                          onBlur
                                                                      }
                                                                      className={`form-box ${
                                                                          errors.fsrs !==
                                                                              undefined &&
                                                                          errors
                                                                              .fsrs[
                                                                              index
                                                                          ] &&
                                                                          errors
                                                                              .fsrs[
                                                                              index
                                                                          ]
                                                                              .section_description &&
                                                                          "is-invalid"
                                                                      }`}
                                                                  />
                                                              )}
                                                          />
                                                          <ErrorMessage
                                                              errors={errors}
                                                              name={`fsrs[${index}].section_description`}
                                                              render={({
                                                                  message,
                                                              }) =>
                                                                  errorMessage(
                                                                      message
                                                                  )
                                                              }
                                                          />
                                                      </td>
                                                      <td>
                                                          {fields.length >
                                                              1 && (
                                                              <FontAwesomeIcon
                                                                  icon={faMinus}
                                                                  style={{
                                                                      cursor: "pointer",
                                                                  }}
                                                                  className="align-middle me-1"
                                                                  onClick={() =>
                                                                      remove(
                                                                          index
                                                                      )
                                                                  }
                                                              />
                                                          )}
                                                          <FontAwesomeIcon
                                                              icon={faPlus}
                                                              style={{
                                                                  cursor: "pointer",
                                                              }}
                                                              className="align-middle me-1"
                                                              onClick={() => {
                                                                  append({
                                                                      category:
                                                                          "",
                                                                      section:
                                                                          "",
                                                                      items: [
                                                                          {
                                                                              item_group_description:
                                                                                  "",
                                                                              item_description:
                                                                                  "",
                                                                              item_number:
                                                                                  "",
                                                                              unit: "",
                                                                              rate: "",
                                                                          },
                                                                      ],
                                                                  });
                                                              }}
                                                          />
                                                      </td>
                                                  </tr>
                                                  <FsrFormItems
                                                      nestIndex={index}
                                                      {...{
                                                          control,
                                                          errors,
                                                      }}
                                                  />
                                              </>
                                          </tbody>
                                      </Table>
                                  );
                              })
                            : ""}
                    </Card>
                </Row>
                <Row className="mt-3">
                    <Col md={12} className="text-center">
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={handleSubmit(ImportFsr)}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="link"
                            onClick={() => navigate(`/works-order/fsr`)}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

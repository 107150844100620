import api, { getexcel } from "../utils/api";

export const getQuotationSummaryZone = () => {
    return api.get("/dashboard/quotation-zone-summary", {});
};

export const getQuotationSummaryMonth = () => {
    return api.get("/dashboard/quotation-month-summary", {});
};

export const getInspection = (data) => {
    return api.get("/dashboard/inspection-summary", { params: data });
};

export const getDefects = (data) => {
    return api.get("/dashboard/defects-summary", { params: data });
};

export const getApv = (data) => {
    return api.get("/dashboard/apv-monthly", { params: data });
};

export const getChecklistStatus = (data) => {
    return api.get("/dashboard/checklist-status", { params: data });
};

export const getChecklistSummary = (data) => {
    return api.get("/dashboard/checklist-summary", { params: data });
};

export const getMonthlySummaryReport = (data) => {
    return api.get("monthly-summary-report", { params: data });
};

export const exportApv = (data) => {
    return getexcel("dashboard/export-apv-monthly", data);
};

import React from "react";

import { Alert, Col, Row } from "react-bootstrap";
import { toTitleCase } from "utils/utilities";

export const SchoolInformation = ({ school }) => {
    return (
        <Alert
            key="primary"
            variant="primary"
            className="p-3 mb-0 w-100"
            style={{ display: "block" }}
        >
            <Row>
                <Col md={6}>
                    <div>
                        <span className="font-weight-bold me-2">
                            School name
                        </span>

                        <div className="mb-2">{school?.name || "-"}</div>
                    </div>

                    <div>
                        <span className="font-weight-bold me-2">Zone</span>

                        <p>{school?.zone ? toTitleCase(school?.zone) : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div>
                        <span className="font-weight-bold me-2">Address</span>
                        <p>{school?.address || "-"}</p>
                    </div>

                    <div>
                        <span className="font-weight-bold me-2">
                            School type
                        </span>
                        <p>{school?.school_type || "-"}</p>
                    </div>
                </Col>
            </Row>
        </Alert>
    );
};

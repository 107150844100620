import api from "../utils/api";

export const createKpi = (data) => {
    return api.post("/cop-kpi", data);
};

export const updateKpi = (id, data) => {
    return api.post("/cop-kpi/" + id, data);
};

export const getKpis = (data) => {
    return api.get("/cop-kpi", { params: data });
};

export const getKpi = (id) => {
    return api.get("/cop-kpi/" + id);
};

export const getKpiConfig = (data) => {
    return api.get(`/config/kpi`, data);
};
